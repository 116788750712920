import { editAnnotationAssignment } from 'api/annotationAssignments';
import { AnnotationAssignment } from 'interfaces/annotation';
import { first } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import queryClient from 'utils/queryClient';
import { useAnnotationAssignments } from 'utils/useAnnotationAssignments';
import { useCasesParams } from 'utils/useCasesParams';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import AnnotationAssignmentDialog, { AssignmentFormValues } from './AnnotationAssignmentDialog';
import { DEFAULT_TODO, DEFAULT_TODO_TYPE } from './CreateAnnotationAssignmentDialog';

interface EditAssignmentDialogProps {
  open: boolean;
  onClose: () => void;
  annotationAssignmentId: number;
}

export const EditAnnotationAssignmentDialog: React.FC<EditAssignmentDialogProps> = ({
  open,
  onClose,
  annotationAssignmentId,
}) => {
  const { casesParams } = useCasesParams();
  const { data: annotationAssignments } = useAnnotationAssignments(
    casesParams?.studyId || casesParams?.filters?.studyId
  );

  const currentAssignment = annotationAssignments?.find(
    (assignment) => assignment.annotationAssignmentId === annotationAssignmentId
  );
  const initialValues = {
    assignmentName: currentAssignment?.name,
    classesToAnnotate: first(currentAssignment?.todos)?.options,
  };

  const editAnnotationAssignmentMutation = useMutationWithErrorSnackbar({
    mutationDescription: 'Edit annotation assignment',
    mutationFn: editAnnotationAssignment,
    onSuccess: () => {
      enqueueSnackbar('Annotation assignment edited successfully', { variant: 'success' });
      queryClient.invalidateQueries(['annotationAssignments']);
      onClose();
    },
  });

  const handleApply: SubmitHandler<AssignmentFormValues> = async (data) => {
    const newAnnotationAssignment: Pick<AnnotationAssignment, 'name' | 'todos'> = {
      name: data.assignmentName,
      todos: [
        {
          todo: first(currentAssignment?.todos)?.todo ?? DEFAULT_TODO,
          type: first(currentAssignment?.todos)?.type ?? DEFAULT_TODO_TYPE,
          options: data.classesToAnnotate,
        },
      ],
    };

    editAnnotationAssignmentMutation.mutate({
      annotationAssignmentId,
      data: { ...newAnnotationAssignment, ...casesParams },
    });
  };

  return (
    <AnnotationAssignmentDialog
      open={open}
      onClose={onClose}
      title={`Edit Annotation Assignment ${annotationAssignmentId}`}
      buttonText="Save"
      isLoadingButtonText="Saving..."
      handleApply={handleApply}
      isLoading={editAnnotationAssignmentMutation.isLoading}
      initialValues={initialValues}
    />
  );
};
