import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const updateStudyMarkers = (args: { studyId: string; markerTypes: { [channelId: string]: string } }) => {
  return apiRequestHandlerPromise({
    url: `multiplex_marker_types/study/${args.studyId}`,
    method: 'PUT',
    data: JSON.stringify(args.markerTypes),
  });
};

export const updateSlideChannelMarkers = (args: { slideId: string; markerTypes: { [channelId: string]: string } }) => {
  return apiRequestHandlerPromise({
    url: `multiplex_marker_types/slide/${args.slideId}`,
    method: 'PUT',
    data: JSON.stringify(args.markerTypes),
  });
};

export interface GetChannelMarkerTypesResponse {
  channelMarkerTypes: string[];
  channelAppearance: { [channelId: string]: number }; // The number of slides that have this channel
  totalSlidesCount: number;
  doAllSlidesHaveSameChannels: boolean;
}

export const getChannelMarkers = (stringParams: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<GetChannelMarkerTypesResponse>({
    url: `multiplex_marker_types?${stringParams}`,
    method: 'GET',
    signal,
  });
};
