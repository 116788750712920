import { Autocomplete, Chip, InputBaseComponentProps, TextField } from '@mui/material';
import { map } from 'lodash';
import React, { useState } from 'react';

interface AutocompleteNumbersProps {
  value: number[];
  onChange: (value: number[]) => void;
  label: string;
  inputProps?: InputBaseComponentProps;
  isError?: boolean;
  required?: boolean;
  limitTags?: number;
}

export const AutocompleteNumbers: React.FunctionComponent<React.PropsWithChildren<AutocompleteNumbersProps>> = ({
  value,
  onChange,
  inputProps,
  isError,
  required,
  label,
  limitTags,
}) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      multiple
      limitTags={limitTags ?? 2}
      options={[]}
      freeSolo
      value={value ?? []}
      renderTags={(values: number[], getTagProps) =>
        map(values, (option, optionIndex) => (
          <Chip
            variant="outlined"
            key={optionIndex}
            label={option.toString()}
            {...getTagProps({ index: optionIndex })}
          />
        ))
      }
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={isError}
          required={required}
          id="outlined-number"
          label={label}
          type="number"
          onBlur={() => {
            if (inputValue !== '') {
              onChange([...(value ?? []), Number(inputValue)]);
              setInputValue('');
            }
          }}
          inputProps={{
            ...params.inputProps,
            ...inputProps,
          }}
        />
      )}
      onChange={(event, selectedNumbers) => {
        onChange(map(selectedNumbers, (selectedNumber) => Number(selectedNumber)));
      }}
    />
  );
};
