import { useQuery } from '@tanstack/react-query';
import { getCasesOrchestrations, Orchestration, OrchestrationResponse } from 'api/experimentResults';
import { DropdownOption } from 'components/atoms/Dropdown/Dropdown';
import { ResultsMode } from 'interfaces/experimentResults';
import { Permission } from 'interfaces/permissionOption';
import { map } from 'lodash';
import moment from 'moment';
import { usePermissions } from 'utils/usePermissions';
import useMainFilters from './useMainFilters';

const useOrchestrationFilter = (
  studyId: string,
  resultsMode: ResultsMode,
  onSuccessGetExperimentDefinitions?: (data: OrchestrationResponse) => void
) => {
  const { hasPermission } = usePermissions();
  const canViewUnpublishedResults = hasPermission(Permission.ViewUnpublishedResults);
  const { isStudyIdSpecific } = useMainFilters();
  const studyIdIsSpecific = isStudyIdSpecific(studyId);

  const getOrchestrationIdOption = (orchestration: Orchestration) => ({
    text: `${orchestration.orchestrationId}, Created at ${moment(orchestration.createdAtMinimum).format('LL')}`,
    value: orchestration.orchestrationId,
  });

  const { data: orchestrationsData, isInitialLoading: orchestrationsLoading } = useQuery({
    queryKey: ['orchestrations', studyId, resultsMode],
    queryFn: () =>
      getCasesOrchestrations({
        filters: {
          studyId,
        },
        resultsMode: resultsMode,
        shouldOnlyGetByStudyIdAndResultsMode: true,
      }),
    enabled: studyIdIsSpecific && canViewUnpublishedResults,
    onSuccess: onSuccessGetExperimentDefinitions,
  });

  const orchestrations: Orchestration[] = orchestrationsData?.orchestrations || [];

  const orchestrationIdOptions: DropdownOption[] = map(orchestrations, (orchestration) =>
    getOrchestrationIdOption(orchestration)
  );

  return {
    orchestrationIdOptions,
    orchestrationsLoading,
    orchestrationsData,
    orchestrations,
  };
};

export default useOrchestrationFilter;
