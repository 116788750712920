import { useQuery } from '@tanstack/react-query';
import { getUserLabPermissions } from 'api/userCredentials';
import { Permission, PermissionSubOptions } from 'interfaces/permissionOption';
import { every, includes, some, startsWith } from 'lodash';
import { useCurrentLabId } from './useCurrentLab';

export const usePermissions = () => {
  const { labId } = useCurrentLabId();
  const {
    data: permissions,
    isLoading,
    isError,
  } = useQuery(['permissions', labId], () => getUserLabPermissions(labId));

  const hasPermission = (permission: Permission) => includes(permissions, permission);
  const hasSubPermission = <T extends keyof PermissionSubOptions>(
    permission: T,
    subPermission: PermissionSubOptions[T][number]
  ) => includes(permissions, `${permission}.${subPermission}`) || includes(permissions, `${permission}.*`);

  /**
   * Check if the user has any of the sub-permissions of a given permission
   */
  const hasSomeSubPermission = <T extends keyof PermissionSubOptions>(permission: T) => {
    const permissionWithDot = `${permission}.`;
    return some(permissions, (userPermission) => startsWith(userPermission, permissionWithDot));
  };

  const hasSomePermissions = (requiredPermissions: Permission[]) => some(requiredPermissions, hasPermission);
  const hasEveryPermission = (requiredPermissions: Permission[]) => every(requiredPermissions, hasPermission);

  const hasSomeEditingPermissions = hasSomePermissions([
    Permission.AddAndEditClinicalDataToAnyStudies,
    Permission.AddAndEditClinicalDataToOwnStudies,
    Permission.AddAndEditMetadataToAnyStudies,
    Permission.AddAndEditMetadataToOwnStudies,
  ]);

  const hasMetadataEditingPermissions = hasSomePermissions([
    Permission.AddAndEditMetadataToAnyStudies,
    Permission.AddAndEditMetadataToOwnStudies,
  ]);

  const hasClinicalDataEditingPermissions = hasSomePermissions([
    Permission.AddAndEditClinicalDataToAnyStudies,
    Permission.AddAndEditClinicalDataToOwnStudies,
  ]);

  return {
    hasPermission,
    hasSubPermission,
    hasSomeSubPermission,
    hasEveryPermission,
    hasSomePermissions,
    hasSomeEditingPermissions,
    hasMetadataEditingPermissions,
    hasClinicalDataEditingPermissions,
    permissions,
    isLoading,
    isError,
  } as const;
};
