import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { getAccessions } from 'api/accessions';
import { TableEditingContextProvider } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { RowSelectionContextProvider } from 'components/atoms/RowSelectionContext';
import wrapPage from 'components/atoms/wrapPage/wrapPage';
import DashboardTabs from 'components/DashboardTabs';
import EmptyPage from 'components/EmptyPage/EmptyPage';
import { useProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { noop } from 'lodash';
import React, { useMemo } from 'react';
import { useFiltersForAccessions } from 'utils/useEncodedFilters';
import AccessionCards from './AccessionCards';
import AccessionFilters from './AccessionFilters';

export const RightMenuButton = styled(Button)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '14px',
  borderRadius: '100px',
  height: '40px',
  whiteSpace: 'nowrap',
  textTransform: 'none',
}));

const dashboardTabs = ['Cases'];

const AccessionDashboard: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme();

  const { encodedFilters } = useFiltersForAccessions();
  const proceduresFieldsContext = useProceduresFieldsContext();

  const { data, isFetching, isError, error, refetch } = useQuery(['accessions', encodedFilters], () =>
    getAccessions(encodedFilters)
  );

  const pageTitle = 'Accession Dashboard';

  const casesCount = useMemo((): string => {
    if (data?.totalAccessions) return `(${data?.totalAccessions})`;

    return '';
  }, [data?.totalAccessions]);

  const dashboardTabDisplayTexts = [`Cases ${casesCount}`];

  if (isError)
    return (
      <div>
        <>an Error has occurred ({error})</>
      </div>
    );

  return (
    <RowSelectionContextProvider>
      <TableEditingContextProvider fieldsContext={proceduresFieldsContext}>
        <Box my={5}>
          <Typography variant="h1" sx={{ fontWeight: theme.typography.fontWeightBold }} color="text.primary">
            {pageTitle}
          </Typography>
        </Box>
        <AccessionFilters onRefetch={refetch} />
        <DashboardTabs tabsDisplayTexts={dashboardTabDisplayTexts} tabs={dashboardTabs} changeTab={noop} />
        {data?.totalAccessions > 0 || isFetching ? (
          <AccessionCards accessions={data?.accessions} isLoading={isFetching} layout={'medium'} />
        ) : (
          <EmptyPage page="accessions" />
        )}
      </TableEditingContextProvider>
    </RowSelectionContextProvider>
  );
};

export default wrapPage(AccessionDashboard);
