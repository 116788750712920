import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getChannelMarkers } from 'api/markerTypes';
import { runMultiplexCellSegmentation } from 'api/platform';
import { modelTypesOptions } from 'components/Pages/Jobs/MultiplexFieldsOptions';
import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import { filter, includes, map } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { humanize } from 'utils/helpers';
import { encodeQueryParamsUsingSchema } from 'utils/useEncodedFilters';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import * as yup from 'yup';
import { useCasesParams } from '../../../../../utils/useCasesParams';
import { PlatformStepper } from '../PlatformStepper';

const SNACK_BAR_KEY_RUN_MULTIPLEX_CELL_SEGMENTATION = 'RUN_MULTIPLEX_CELL_SEGMENTATION';

export const RunMultiplexCellSegmentation = (props: IFormProps): ReactElement => {
  const { onClose } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();
  const { casesParams, schema, options } = useCasesParams();

  const getEncodedParams = () => encodeQueryParamsUsingSchema(casesParams, schema, options);

  const { data: channelMarkersResponse, isLoading: isLoadingChannelMarkers } = useQuery({
    queryKey: ['multiplex_marker_types', getEncodedParams()],
    queryFn: ({ signal }) => getChannelMarkers(getEncodedParams(), signal),
  });

  const channelMarkers = channelMarkersResponse?.channelMarkerTypes;
  const channelMarkersInAllSlides = filter(channelMarkers, (channelMarker) => {
    return channelMarkersResponse?.channelAppearance[channelMarker] === channelMarkersResponse?.totalSlidesCount;
  });

  const channelSelectSchema = useMemo(() => {
    return yup
      .array()
      .of(yup.string())
      .min(1, 'At least one channel is required')
      .test('is-valid-channel', 'Partial Cohort Channel', function (value) {
        const { path, createError } = this;

        if (!value) {
          return true; // if no value, pass validation
        }

        for (let i = 0; i < value.length; i++) {
          if (!includes(channelMarkersInAllSlides, value[i])) {
            return createError({ path, message: `Channel: ${value[i]} does not appear in all slides` });
          }
        }

        return true; // All channels are valid
      })
      .required();
  }, [channelMarkersInAllSlides]);

  const validationSchema = useMemo(
    () => [
      yup.object({
        jobName: yup.string(),
        jobDescription: yup.string(),
      }),
      yup.object({
        modelType: yup.string().required(),
        nuclearChannelNames: channelSelectSchema,
        nonNuclearChannelNames: channelSelectSchema,
      }),
    ],
    [channelSelectSchema]
  );

  const currentValidationSchema = validationSchema[activeStep];

  const methods = useForm<IFormValues>({
    mode: 'onChange',
    resolver: yupResolver(currentValidationSchema),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { errors },
  } = methods;

  const channelMarkersOptions = map(channelMarkers, (channelMarker) => ({
    text: stainTypeIdToDisplayName(channelMarker) ?? channelMarker,
    value: channelMarker,
  }));

  const runMultiplexCellSegmentationMutation = useMutation(runMultiplexCellSegmentation, {
    onError: () => {
      enqueueSnackbar('Error occurred, Multiplex Cell Segmentation failed', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Multiplex Cell Segmentation Started', { variant: 'success' });
    },
    onSettled() {
      closeSnackbar(SNACK_BAR_KEY_RUN_MULTIPLEX_CELL_SEGMENTATION);
    },
  });

  const onSubmit: SubmitHandler<IFormValues> = async (data) => {
    runMultiplexCellSegmentationMutation.mutate({
      ...casesParams,
      configParams: { ...data },
    });

    enqueueSnackbar({
      variant: 'success',
      message: (
        <Grid container>
          <Grid item>
            <Typography>Waiting for Multiplex Cell Segmentation to start</Typography>
          </Grid>
          <Grid item>
            <CircularProgress sx={{ marginLeft: 10 }} color="inherit" size={20} />
          </Grid>
        </Grid>
      ),
      key: SNACK_BAR_KEY_RUN_MULTIPLEX_CELL_SEGMENTATION,
      autoHideDuration: null,
    });

    onClose();
  };

  const [isStepFailed, setIsStepFailed] = useState<Record<number, boolean>>();

  const checkValidationAndSetIsStepFailed = (stepIndex: number, objectToValidate: Record<string, any>) => {
    validationSchema[stepIndex]
      .validate(objectToValidate)
      .then(() => {
        setIsStepFailed((prev) => ({
          ...prev,
          [stepIndex]: false,
        }));
      })
      .catch(() => {
        setIsStepFailed((prev) => ({
          ...prev,
          [stepIndex]: true,
        }));
      });
  };

  const steps = [
    {
      label: 'Name and Description',
      subLabel: activeStep > 0 && watch('jobName'),
      optional: true,
      content: (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Controller
              control={control}
              name="jobName"
              render={({ field: { onChange } }) => (
                <TextField
                  label="Job Name"
                  {...register('jobName')}
                  onChange={onChange}
                  placeholder="Type Here"
                  error={Boolean(errors['jobName'])}
                  helperText={humanize(errors['jobName']?.message)}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="jobDescription"
              render={({ field: { onChange } }) => (
                <TextField
                  label="Job Description"
                  {...register('jobDescription')}
                  onChange={onChange}
                  placeholder="Type Here"
                  error={Boolean(errors['jobDescription'])}
                  helperText={humanize(errors['jobDescription']?.message)}
                  multiline
                  minRows={4}
                />
              )}
            />
          </Grid>
        </Grid>
      ),
      onNextOrBackClick: () =>
        checkValidationAndSetIsStepFailed(1, {
          modelType: watch('modelType'),
          nuclearChannelNames: watch('nuclearChannelNames'),
          nonNuclearChannelNames: watch('nonNuclearChannelNames'),
        }),
    },
    {
      label: 'Configuration',
      content: (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Controller
              control={control}
              name="modelType"
              render={({ field: { onChange, value } }) => (
                <LabelledDropdown
                  required
                  label="model Type"
                  options={modelTypesOptions}
                  value={value ?? []}
                  onOptionSelected={(optionValue) => {
                    onChange(optionValue);
                  }}
                  error={Boolean(errors['modelType'])}
                  helperText={humanize(errors['modelType']?.message)}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="nuclearChannelNames"
              render={({ field: { onChange, value } }) => (
                <LabelledDropdown
                  required
                  multiple
                  label="Nuclear Channel Names"
                  options={channelMarkersOptions}
                  value={value ?? []}
                  onOptionSelected={(optionValue) => {
                    onChange(optionValue);
                    checkValidationAndSetIsStepFailed(1, {
                      modelType: watch('modelType'),
                      nuclearChannelNames: optionValue,
                      nonNuclearChannelNames: watch('nonNuclearChannelNames'),
                    });
                  }}
                  loading={isLoadingChannelMarkers || isLoadingStainTypeOptions}
                  error={Boolean(errors['nuclearChannelNames'])}
                  helperText={humanize(errors['nuclearChannelNames']?.message)}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="nonNuclearChannelNames"
              render={({ field: { onChange, value } }) => (
                <LabelledDropdown
                  required
                  multiple
                  label="Non Nuclear Channel Names"
                  options={channelMarkersOptions}
                  value={value ?? []}
                  onOptionSelected={(optionValue) => {
                    onChange(optionValue);
                    checkValidationAndSetIsStepFailed(1, {
                      modelType: watch('modelType'),
                      nuclearChannelNames: watch('nuclearChannelNames'),
                      nonNuclearChannelNames: optionValue,
                    });
                  }}
                  loading={isLoadingChannelMarkers}
                  error={Boolean(errors['nonNuclearChannelNames'])}
                  helperText={humanize(errors['nonNuclearChannelNames']?.message)}
                />
              )}
            />
          </Grid>
        </Grid>
      ),
    },
  ];

  return (
    <PlatformStepper
      handleSubmit={handleSubmit(onSubmit)}
      steps={steps}
      setActiveStepForValidation={setActiveStep}
      isStepFailed={isStepFailed}
    />
  );
};

export interface IFormProps {
  onClose: () => void;
}

export interface IFormValues {
  jobName: string;
  jobDescription: string;
  modelType: string;
  nuclearChannelNames: string[];
  nonNuclearChannelNames: string[];
}
