import SaveIcon from '@mui/icons-material/Bookmark';
import SaveOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChartType } from 'interfaces/chart';
import { CohortWithSelectedFeatures, PartialCohort } from 'interfaces/cohort_old';
import { compact, filter, find, first, flatMap, get, isEmpty, map, union } from 'lodash';
import React from 'react';

import { useProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { splitCohortBy } from 'utils/cohort.util';
import { getOptionsFromFeatureName } from 'utils/features';
import {
  useGetFeatureDisplayNameWithContext,
  useGetNameOverrideOrDisplayNameWithContext,
} from 'utils/features/contextHooks';
import Chart from './Chart';
import {
  ChartKey,
  ChartKeyType,
  convertToChartKey,
  CountBy,
  isCategoricalChartKey,
  isNumericalChartKey,
  isSlideCategoricalChartKey,
  isSlideCategoricalKey,
} from './chart.util';
import { SurvivalAnalysisType, survivalAnalysisTypes } from './charts/kaplanMeier.util';
import ChartKeySelect from './KeySelect/ChartKeySelect';
import KeySelect from './KeySelect/KeySelect';

interface Props {
  cohorts: CohortWithSelectedFeatures[];
  id: number;
  availableKeys: ChartKey[];
  loading: boolean;
  onRemove: () => void;
  chartOptions: ControlledChartOptions;
  chartTypes?: { key: ChartType; name: React.ReactNode }[];
  onChangeChartOptions: (chartOptions: ControlledChartOptions) => void;
  layout?: ControlledChartLayout;
  expandEditSectionOnRender?: boolean;
}

export interface ControlledChartOptions {
  type: ChartType;
  horizontalKey?: ChartKey;
  verticalKey?: ChartKey;
  categoricalKey?: ChartKey;
  splittingKey?: ChartKey;
  kaplanMeierParameter?: SurvivalAnalysisType;
  userSelectedSplittingKey?: boolean;
  countBy?: CountBy;
  filteredCaseIds?: number[];
  useMultipleCasesFilter?: boolean;
}

export type ChartOptionField = keyof ControlledChartOptions;

const typeUsesHorizontalKey = (type: ChartType) =>
  type === ChartType.Scatter || type == ChartType.Histogram || type == ChartType.DistanceBased;
const typeUsesVerticalKey = (type: ChartType) => type === ChartType.Scatter || type == ChartType.Box;
const typeUsesCategoricalKey = (type: ChartType) => type === ChartType.Box || type == ChartType.Pie;
const typeUsesKaplanMeierParameter = (type: ChartType) => type === ChartType.KaplanMeier;
const typeUsesCaseFilter = (type: ChartType) => type === ChartType.DistanceBased;
const typeUsesOnlyNumericalVerticalKey = typeUsesVerticalKey; // vertical key might not be numerical in the future but it's currently only numerical

export const getChartTitle = ({
  chartOptions,
  getNameOverrideOrDisplayNameWithContext,
}: {
  chartOptions: ControlledChartOptions;
  getNameOverrideOrDisplayNameWithContext: (featureKey: string) => string;
}) => {
  const { type } = chartOptions;
  const usesHorizontalKey = typeUsesHorizontalKey(type);
  const usesVerticalKey = typeUsesVerticalKey(type);
  const usesCategoricalKey = typeUsesCategoricalKey(type);
  let featureOverrideNameOrDisplayName = '';

  if (usesCategoricalKey) {
    featureOverrideNameOrDisplayName = getNameOverrideOrDisplayNameWithContext(chartOptions.categoricalKey?.name);
  } else if (usesHorizontalKey) {
    featureOverrideNameOrDisplayName = getNameOverrideOrDisplayNameWithContext(chartOptions.horizontalKey?.name);
  } else if (usesVerticalKey) {
    featureOverrideNameOrDisplayName = getNameOverrideOrDisplayNameWithContext(chartOptions.verticalKey?.name);
  }
  return featureOverrideNameOrDisplayName;
};

export type ControlledChartLayout = 'small' | 'medium' | 'large';

const defaultChartTypes: { key: ChartType; name: React.ReactNode }[] = [
  { key: ChartType.Scatter, name: 'Scatter' },
  { key: ChartType.Box, name: 'Box' },
  { key: ChartType.Histogram, name: 'Histogram' },
  { key: ChartType.KaplanMeier, name: 'Kaplan Meier' },
];

const ControlledChart: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  id,
  cohorts,
  availableKeys: allAvailableKeys,
  loading: loadingData,
  onRemove,
  chartOptions = {} as ControlledChartOptions,
  chartTypes = defaultChartTypes,
  onChangeChartOptions,
  layout = 'medium',
  expandEditSectionOnRender,
}) => {
  const { type, splittingKey, kaplanMeierParameter } = chartOptions;

  const availableKeys =
    type === ChartType.DistanceBased
      ? filter(allAvailableKeys, (key) => key.type === ChartKeyType.DistanceBased)
      : filter(allAvailableKeys, (key) => key.type !== ChartKeyType.DistanceBased);

  const availableNumericalKeys = filter(availableKeys, {
    type: ChartKeyType.Numerical,
  });

  const allCategoricalChartKeys = filter(availableKeys, {
    type: ChartKeyType.Categorical,
  });

  const splittingKeys: ChartKey[] = filter(availableKeys, (key) => {
    if (isCategoricalChartKey(key)) {
      return !isSlideCategoricalChartKey(key);
    }
    return isNumericalChartKey(key);
  });

  const countByKeys: CountBy[] = ['Slides', 'Cases'];
  const usesCasesFilter = typeUsesCaseFilter(type);
  const useMultipleCasesFilter = chartOptions.useMultipleCasesFilter;
  const useAllowFilteringByMultipleCases = false; // TODO: examine if we want this option
  const usesHorizontalKey = typeUsesHorizontalKey(type);
  const usesVerticalKey = typeUsesVerticalKey(type);
  const usesOnlyNumericalVerticalKey = typeUsesOnlyNumericalVerticalKey(type);
  const usesCategoricalKey = typeUsesCategoricalKey(type);
  const usesKaplanMeierParameter = typeUsesKaplanMeierParameter(type);
  const usesCountBy =
    (type === ChartType.Histogram &&
      chartOptions.horizontalKey &&
      chartOptions.horizontalKey.type === ChartKeyType.Categorical &&
      isSlideCategoricalKey(chartOptions.horizontalKey?.name)) ||
    (type === ChartType.Pie && chartOptions.categoricalKey && isSlideCategoricalKey(chartOptions.categoricalKey?.name));

  const loading = loadingData || (useMultipleCasesFilter && isEmpty(chartOptions.filteredCaseIds));

  const categoricalChartKeys = type === ChartType.Box ? allCategoricalChartKeys : availableKeys;

  const [saved, setSaved] = React.useState<boolean>();

  const removeHandler = () => {
    handleSetChartOptions(null);
    onRemove();
  };

  let cohortsToDisplay: PartialCohort[] = cohorts;

  const cohort = first(cohorts);
  if (splittingKey && splittingKey.name !== 'None' && !loading && !isEmpty(cohort?.procedures)) {
    cohortsToDisplay = splitCohortBy(cohort, splittingKey);
  }

  const logrankPValue = kaplanMeierParameter
    ? find(get(cohort?.survivalAnalysis, kaplanMeierParameter), { feature: splittingKey?.name })?.logrankPValue
    : null;

  const headerSelectorsCount = compact([
    usesHorizontalKey,
    usesVerticalKey,
    usesCategoricalKey,
    usesKaplanMeierParameter,
    usesCountBy,
  ]).length;

  const { stainTypesInStudy } = useProceduresFieldsContext();
  const addStainToFeatureName = stainTypesInStudy && stainTypesInStudy.length > 1;

  const { getNameOverrideOrDisplayNameWithContext } = useGetNameOverrideOrDisplayNameWithContext(addStainToFeatureName);
  const chartTitleForSmallMode = getChartTitle({ chartOptions, getNameOverrideOrDisplayNameWithContext });

  const { getFeatureDisplayNameWithContext } = useGetFeatureDisplayNameWithContext(addStainToFeatureName);
  const chartSubTitleForSmallMode =
    chartOptions.splittingKey?.name && chartOptions.splittingKey?.name !== 'None'
      ? `Split by: ${getFeatureDisplayNameWithContext(chartOptions.splittingKey?.name)}`
      : '';

  const handleSetChartOptions =
    <K extends keyof ControlledChartOptions>(optionKey: K) =>
    (value: ControlledChartOptions[K]) => {
      onChangeChartOptions({
        ...chartOptions,
        [optionKey]: value,
      });
    };

  const handleSetChartOptionsChartKey =
    <K extends keyof ControlledChartOptions>(optionKey: K) =>
    (value: ChartKey) => {
      onChangeChartOptions({
        ...chartOptions,
        [optionKey]: value,
        ...(optionKey === 'splittingKey' && { userSelectedSplittingKey: true }),
      });
    };

  const gridItemSize: number =
    layout === 'small' ? 12 : layout === 'large' ? 12 / headerSelectorsCount : headerSelectorsCount > 1 ? 6 : 12;

  const expandByDefault = type === ChartType.DistanceBased || expandEditSectionOnRender; // TODO: make this smarter
  const [expanded, setExpanded] = React.useState(expandByDefault);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const bottomChartConfigurations = (
    <Grid item container spacing={1}>
      {!usesCasesFilter || useMultipleCasesFilter ? (
        <Grid item xs={layout === 'small' ? 12 : 9}>
          <ChartKeySelect
            size={'small'}
            keys={union(splittingKeys, [convertToChartKey('None')])}
            selectedKey={isEmpty(availableKeys) ? convertToChartKey('') : chartOptions.splittingKey}
            updateSelectedKey={handleSetChartOptionsChartKey('splittingKey')}
            name={'Split by'}
            addStainToFeatureName={addStainToFeatureName}
            enableNullValue={true}
            variant={layout === 'small' ? 'outlined' : 'standard'}
          />
        </Grid>
      ) : null}
      {useAllowFilteringByMultipleCases ? (
        <Grid item xs={3}>
          <KeySelect
            size={'small'}
            keys={['true', 'false']}
            selectedKey={useMultipleCasesFilter ? 'true' : 'false'}
            updateSelectedKey={(value) => handleSetChartOptions('useMultipleCasesFilter')(value === 'true')}
            name="Filter by cases"
            variant={layout === 'small' ? 'outlined' : 'standard'}
          />
        </Grid>
      ) : null}
      <Grid item xs={layout === 'small' ? 6 : 3}>
        <KeySelect
          size={'small'}
          keys={map(chartTypes, 'key')}
          selectedKey={type}
          updateSelectedKey={handleSetChartOptions('type')}
          name={'Plot type'}
          variant={layout === 'small' ? 'outlined' : 'standard'}
        />
      </Grid>
    </Grid>
  );

  return (
    <Card
      elevation={layout === 'small' ? 0 : 1}
      sx={{ background: layout === 'small' ? 'transparent' : 'auto', height: '100%', width: '100%' }}
    >
      <CardHeader
        sx={{ paddingBottom: 0 }}
        title={
          <>
            {layout === 'small' && !expanded && chartTitleForSmallMode}
            <Collapse in={layout === 'small' ? expanded : true} timeout="auto" unmountOnExit>
              <Grid container spacing={1}>
                {usesCasesFilter && (
                  <Grid item xs={gridItemSize}>
                    {useMultipleCasesFilter ? (
                      <KeySelect
                        multiple
                        size={'small'}
                        keys={flatMap(cohorts, ({ procedures }) => map(procedures, 'id'))}
                        selectedKey={chartOptions.filteredCaseIds}
                        updateSelectedKey={handleSetChartOptions('filteredCaseIds')}
                        name={'Select Case'}
                        getOptionDisplayName={(key) => {
                          if (key === -1) {
                            return 'All Cases';
                          }
                          const procedure = find(cohort?.procedures, { id: key });
                          return `${procedure ? procedure.label : key}`;
                        }}
                      />
                    ) : (
                      <KeySelect
                        size={'small'}
                        keys={flatMap(cohorts, ({ procedures }) => map(procedures, 'id'))}
                        selectedKey={first(chartOptions.filteredCaseIds) || -1}
                        updateSelectedKey={(value) =>
                          handleSetChartOptions('filteredCaseIds')(!isNaN(value) ? [value] : [])
                        }
                        name={'Select Case'}
                        getOptionDisplayName={(key) => {
                          if (key === -1) {
                            return 'Select a case...';
                          }
                          const procedure = find(cohort?.procedures, { id: key });
                          return `${procedure ? procedure.label : key}`;
                        }}
                      />
                    )}
                  </Grid>
                )}
                {usesHorizontalKey && (
                  <Grid item xs={gridItemSize}>
                    <ChartKeySelect
                      size={'small'}
                      keys={availableKeys}
                      selectedKey={chartOptions.horizontalKey}
                      updateSelectedKey={handleSetChartOptionsChartKey('horizontalKey')}
                      name={'Horizontal'}
                      addStainToFeatureName={addStainToFeatureName}
                    />
                  </Grid>
                )}
                {usesVerticalKey && (
                  <Grid item xs={gridItemSize}>
                    <ChartKeySelect
                      sx={{ fontSize: '0.5rem' }}
                      size={'small'}
                      keys={usesOnlyNumericalVerticalKey ? availableNumericalKeys : availableKeys}
                      selectedKey={chartOptions.verticalKey}
                      updateSelectedKey={handleSetChartOptionsChartKey('verticalKey')}
                      name={'Vertical'}
                      addStainToFeatureName={addStainToFeatureName}
                    />
                  </Grid>
                )}
                {usesCategoricalKey && (
                  <Grid item xs={gridItemSize}>
                    <ChartKeySelect
                      size={'small'}
                      keys={categoricalChartKeys}
                      selectedKey={chartOptions.categoricalKey}
                      updateSelectedKey={handleSetChartOptionsChartKey('categoricalKey')}
                      name={'Categorical'}
                      addStainToFeatureName={addStainToFeatureName}
                    />
                  </Grid>
                )}
                {usesKaplanMeierParameter && (
                  <Grid item xs={gridItemSize}>
                    <KeySelect
                      size={'small'}
                      keys={survivalAnalysisTypes}
                      selectedKey={chartOptions.kaplanMeierParameter}
                      updateSelectedKey={handleSetChartOptions('kaplanMeierParameter')}
                      name={'Analysis Type'}
                    />
                  </Grid>
                )}
                {usesCountBy && (
                  <Grid item xs={gridItemSize}>
                    <KeySelect
                      size={'small'}
                      keys={countByKeys}
                      selectedKey={chartOptions.countBy || 'Cases'}
                      updateSelectedKey={handleSetChartOptions('countBy')}
                      name={'Count by'}
                    />
                  </Grid>
                )}
                {layout === 'small' && (
                  <Grid item xs={gridItemSize}>
                    {bottomChartConfigurations}
                  </Grid>
                )}
              </Grid>
            </Collapse>
          </>
        }
        subheader={
          layout === 'small' ? (
            expanded ? null : (
              chartSubTitleForSmallMode
            )
          ) : (
            <Typography variant="caption">{logrankPValue ? `Log-Rank P - Value: ${logrankPValue}` : `-`}</Typography>
          )
        }
        action={
          layout === 'small'
            ? expanded
              ? [
                  <IconButton onClick={handleExpandClick} size="small" key="Minimize Chart Configurations">
                    <KeyboardArrowUpIcon />
                  </IconButton>,
                ]
              : [
                  <IconButton
                    id="basic-button"
                    key="Chart Configurations"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    disabled={isEmpty(first(cohorts)?.procedures)}
                  >
                    <MoreVertIcon />
                  </IconButton>,
                  <Popover
                    id="main-chart-menu"
                    key="Chart Configurations Popover"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    onClick={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <MenuList
                      autoFocusItem
                      sx={(theme) => ({
                        padding: '4px 0',
                        minWidth: 150,
                        ['& .MuiMenuItem-root']: {
                          ['& .MuiSvgIcon-root']: {
                            marginRight: theme.spacing(1.5),
                          },
                        },
                      })}
                    >
                      <MenuItem onClick={handleExpandClick}>
                        <TuneIcon fontSize="small" />
                        Edit
                      </MenuItem>
                      <MenuItem onClick={removeHandler}>
                        <DeleteIcon fontSize="small" />
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Popover>,
                ]
            : [
                <Tooltip title={saved ? 'Saved Chart' : 'Save Chart'} key={saved ? 'Saved Chart' : 'Save Chart'}>
                  <IconButton onClick={() => setSaved(!saved)} size="small">
                    {saved ? <SaveIcon color="primary" /> : <SaveOutlinedIcon />}
                  </IconButton>
                </Tooltip>,
                <Tooltip title="Remove Chart" key="Remove Chart">
                  <IconButton onClick={removeHandler} size="small">
                    <CloseIcon />
                  </IconButton>
                </Tooltip>,
              ]
        }
      ></CardHeader>
      <CardContent sx={{ padding: 2 }}>
        <Grid container direction="column">
          <Grid item sx={{ height: layout === 'small' ? 250 : 300, width: '100%' }}>
            <Chart
              cohorts={cohortsToDisplay}
              categoricalKey={chartOptions.categoricalKey}
              horizontalKey={chartOptions.horizontalKey}
              kaplanMeierParameter={chartOptions.kaplanMeierParameter}
              loading={loading}
              verticalKey={chartOptions.verticalKey}
              chartType={type}
              countBy={usesCountBy && chartOptions.countBy ? chartOptions.countBy : 'Cases'}
              layout={layout}
              filteredCaseIds={chartOptions.filteredCaseIds}
              featureOptions={getOptionsFromFeatureName(chartOptions.horizontalKey?.name)}
              chartIndex={id}
            />
          </Grid>
          {layout != 'small' && bottomChartConfigurations}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ControlledChart;
