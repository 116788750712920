import { Card, Grid, styled, Typography, useTheme } from '@mui/material';
import { QueryFunctionContext, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { getFeatureKeysByStudyAndOrchestration } from 'api/features';
import { getProcedures } from 'api/procedures';
import { DEFAULT_PAGE_SIZE } from 'components/StudyDashboard/ProceduresPage/ProcedurePagination';
import { CohortWithSelectedFeatures } from 'interfaces/cohort_old';
import { Study } from 'interfaces/study';
import { every, filter, flatMap, isEmpty, isEqual, times } from 'lodash';
import { stringify } from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { encodeQueryParams } from 'use-query-params';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import QualityControlSection from './QualityControl/QualityControlSection';
import TopFeaturesSection from './TopFeaturesSection';

interface Props {
  study: Study;
  isLoading: boolean;
}

const OverviewTabContent: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ study, isLoading }) => {
  const queryClient = useQueryClient();
  const theme = useTheme();

  const { labId } = useCurrentLabId();
  const { queryParamsSchema, queryParams } = useEncodedFilters();
  const pageSize = DEFAULT_PAGE_SIZE;

  const getEncodedParams = React.useCallback(
    ({ page, featureSelection }: { page?: number; featureSelection?: string[] }) => {
      return stringify(
        encodeQueryParams(queryParamsSchema, {
          filters: queryParams.filters,
          features: queryParams.features,
          clinicalData: queryParams.clinicalData,
          labId,
          experimentResultsSelection: ExperimentResultsSelection.FeatureValues,
          featuresSelection: featureSelection,
          pageSize: pageSize,
          page: page,
        })
      );
    },
    [queryParams, labId, pageSize, queryParamsSchema]
  );

  const { data: featureKeys, isLoading: isLoadingFeatures } = useQuery({
    queryKey: [
      'featureKeys',
      {
        studyId: study?.id,
        orchestrationId: queryParams.filters?.orchestrationId,
        includeInferredFeatures: false,
        resultsMode: queryParams.resultsMode,
      },
    ],
    queryFn: () =>
      getFeatureKeysByStudyAndOrchestration(
        study?.id,
        queryParams.filters?.orchestrationId,
        false,
        false,
        queryParams.resultsMode
      ),
    enabled: Boolean(study),
  });

  const allFeatureKeys: string[] = featureKeys?.featuresKeys || [];

  const [cohort, setCohort] = useState<CohortWithSelectedFeatures>({
    id: `cohort${study?.id}`,
    labId: study?.labId,
    name: study?.name,
    procedures: [],
    survivalAnalysis: undefined,
    dendrogram: undefined,
    highlightedFeatures: study?.highlightedFeatures,
    inferredFeaturesConfig: study?.inferredFeaturesConfig,
    qcLabelsConfig: study?.qcLabelsConfig,
  });

  const cohortsUsableData = useMemo(() => {
    return {
      ...cohort,
      procedures: filter(cohort?.procedures, (procedure) => every(procedure?.slides, { qcFailed: false })),
    };
  }, [cohort]);

  useEffect(() => {
    setCohort((prevCohort) => ({
      ...prevCohort,
      id: `cohort${study?.id}`,
      labId: study?.labId,
      name: study?.name,
      highlightedFeatures: study?.highlightedFeatures,
      qcLabelsConfig: study?.qcLabelsConfig,
      inferredFeaturesConfig: study?.inferredFeaturesConfig,
    }));
  }, [
    study?.id,
    study?.labId,
    study?.name,
    study?.highlightedFeatures,
    study?.qcLabelsConfig,
    study?.inferredFeaturesConfig,
  ]);

  const totalPages = useMemo(() => {
    return study?.casesCount ? Math.ceil(study?.casesCount / pageSize) : 0;
  }, [study?.casesCount]);

  const proceduresQueries = useQueries({
    queries: times(totalPages, (index) => {
      const encodedFilters = getEncodedParams({ page: index + 1, featureSelection: study?.highlightedFeatures || [] });

      return {
        queryKey: ['procedures', encodedFilters],
        queryFn: ({ signal }: QueryFunctionContext) => getProcedures(encodedFilters, signal),
        enabled: Boolean(study),
      };
    }),
  });

  useEffect(() => {
    return () => {
      if (totalPages) {
        times(totalPages, (index) => {
          const encodedFilters = getEncodedParams({
            page: index + 1,
            featureSelection: study?.highlightedFeatures || [],
          });
          queryClient.cancelQueries(['procedures', encodedFilters]);
        });
      }
    };
  }, [totalPages]);

  const finishLoadingBasicData =
    Boolean(study) && !isEmpty(proceduresQueries) && every(proceduresQueries, (query) => query.isFetched);

  // TODO - this should not be neccesary, but without the equality check the useEffect is run infinitely.
  // This is because setCohort causes a re-render. Rather than debug this, we should refactor this useState for cohorts to useMemo.
  const prevNewProcedures = React.useRef(cohort?.procedures);

  useEffect(() => {
    if (finishLoadingBasicData) {
      const procedures = flatMap(proceduresQueries, (query) => query?.data?.procedures);
      if (!isEqual(prevNewProcedures.current, procedures)) {
        prevNewProcedures.current = procedures;
        setCohort((prevCohort) => ({ ...prevCohort, procedures }));
      }
    }
  }, [finishLoadingBasicData, proceduresQueries]);

  return (
    <Grid container direction="column" spacing={4} py={3} sx={{ backgroundColor: theme.palette.background.default }}>
      <Grid item>
        <QualityControlSection cohort={cohort} isLoading={isLoading || !finishLoadingBasicData} />
      </Grid>
      <Grid item>
        <TopFeaturesSection
          cohort={cohortsUsableData}
          setCohort={setCohort}
          cohortAllFeatures={allFeatureKeys}
          isLoading={isLoading || isLoadingFeatures || !finishLoadingBasicData}
        />
      </Grid>
    </Grid>
  );
};

export const CardWithBorder = styled(Card)(({ theme }) => ({
  borderColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.softContrast.contrastText,
  backgroundColor: theme.palette.softContrast.light,
  height: 278,
  '& .MuiCardContent-root': {
    paddingTop: 0,
  },
}));

export const OverviewSubSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.grey[500],
  textTransform: 'uppercase',
}));

export default OverviewTabContent;
