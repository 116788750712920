import { useQuery } from '@tanstack/react-query';
import { fetchStudies } from 'api/study';
import deepEqual from 'deep-equal';
import { find, isEmpty, omit } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { useProceduresWithQAExperimentResultsOnly } from 'utils/useProceduresWithQAExperimentResultsOnly';

export const useStableProcedureStatistics = ({
  enabled = true,
}: {
  enabled?: boolean;
} = {}) => {
  const { queryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const { data } = useProceduresWithQAExperimentResultsOnly({ enabled });

  const [totalCases, setTotalCases] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const [slidesInPreviousPages, setSlidesInPreviousPages] = useState(0);
  const [proceduresInPreviousPages, setProceduresInPreviousPages] = useState(0);

  const previousProcedureTotalParams = useRef(omit(queryParams, ['page', 'pageSize', 'sortBy']));

  const { labId } = useCurrentLabId();
  const { isLoading: studiesLoading, data: studies } = useQuery(
    ['studies', labId, 'withStatistics'],
    fetchStudies(labId, true),
    { enabled }
  );

  useEffect(() => {
    const { studyId, ...filtersWithoutStudyId } = queryParams.filters || {};
    const study = !studiesLoading && find(studies, { id: studyId });
    const studyIdTotalCases =
      isEmpty(filtersWithoutStudyId) && !isNaN(Number(study?.casesCount)) ? Number(study?.casesCount) : undefined;
    const studyIdTotalSlides =
      isEmpty(filtersWithoutStudyId) && !isNaN(Number(study?.slidesCount)) ? Number(study?.slidesCount) : undefined;
    const procedureTotalParams = omit(queryParams, ['page', 'sortBy']);

    if (
      !isNaN(Number(data?.totalProcedures ?? studyIdTotalCases)) ||
      !deepEqual(procedureTotalParams, previousProcedureTotalParams.current)
    ) {
      previousProcedureTotalParams.current = procedureTotalParams;
      setTotalCases(data?.totalProcedures ?? studyIdTotalCases ?? 0);
      setTotalSlides(data?.totalSlides ?? studyIdTotalSlides ?? 0);
    }

    if (!isNaN(Number(data?.slidesInPreviousPages))) {
      setSlidesInPreviousPages(data?.slidesInPreviousPages ?? undefined);
    }

    if (!isNaN(Number(data?.proceduresInPreviousPages))) {
      setProceduresInPreviousPages(data?.proceduresInPreviousPages ?? undefined);
    }
  }, [
    data?.slidesInPreviousPages,
    data?.proceduresInPreviousPages,
    data?.totalSlides,
    data?.totalProcedures,
    queryParams,
    studiesLoading,
    studies,
  ]);

  return {
    totalCases,
    totalSlides,
    slidesInPreviousPages,
    proceduresInPreviousPages,
  };
};
