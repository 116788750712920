import { Grid, Input, Paper, Slider, Typography } from '@mui/material';
import { PrecisionSpecificNormalizationParams } from 'interfaces/jobs/multiplex/normalizationParams';
import { cloneDeep } from 'lodash';
import { sort } from 'mathjs';
import React from 'react';

interface PrecisionSpecificFormProps {
  precisionSpecificNormalizationParams: PrecisionSpecificNormalizationParams;
  setPrecisionSpecificNormalizationParams: (
    precisionSpecificNormalizationParams: PrecisionSpecificNormalizationParams
  ) => void;
  label?: string;
  maxValue?: number;
}

const PrecisionSpecificForm: React.FC<React.PropsWithChildren<PrecisionSpecificFormProps>> = ({
  precisionSpecificNormalizationParams,
  setPrecisionSpecificNormalizationParams,
  label,
  maxValue = 255,
}) => {
  const minCRange = [
    precisionSpecificNormalizationParams.startingHistValue ?? 0,
    precisionSpecificNormalizationParams.maxMinC ?? Math.floor(maxValue / 2),
  ];
  const maxCRange = [
    precisionSpecificNormalizationParams.minMaxC ?? 0,
    precisionSpecificNormalizationParams.maxMaxC ?? maxValue,
  ];

  const handleMaxCChange = (newMaxCRange: number[]) => {
    setPrecisionSpecificNormalizationParams({
      ...precisionSpecificNormalizationParams,
      minMaxC: newMaxCRange[0],
      maxMaxC: newMaxCRange[1],
    });
  };

  const handleMinCChange = (newMinCRange: number[]) => {
    setPrecisionSpecificNormalizationParams({
      ...precisionSpecificNormalizationParams,
      startingHistValue: newMinCRange[0],
      maxMinC: newMinCRange[1],
    });
  };

  const fixRangeValues = (range: number[]) => {
    const minValue = 0;

    if (range[0] < minValue) {
      range[0] = minValue;
    }
    if (range[1] < minValue) {
      range[1] = minValue;
    }
    if (range[0] > maxValue) {
      range[0] = maxValue;
    }
    if (range[1] > maxValue) {
      range[1] = maxValue;
    }
  };

  const handleBlur = () => {
    const newMaxCRange = cloneDeep(maxCRange);
    fixRangeValues(newMaxCRange);
    handleMaxCChange(sort(newMaxCRange, 'asc'));
  };

  const handleMinBlur = () => {
    const newMinCRange = cloneDeep(minCRange);
    fixRangeValues(newMinCRange);
    handleMinCChange(sort(newMinCRange, 'asc'));
  };

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: maxValue,
      label: maxValue,
    },
  ];

  // if minMaxC is smaller then startingHistValue, show an error message
  const errorMessage = maxCRange[0] < minCRange[0] ? 'minMaxC must be greater than startingHistValue' : null;

  return (
    <Paper sx={{ p: 2, maxWidth: 500 }}>
      <Grid container direction="column" sx={{ width: '100%', p: 1 }}>
        <Grid item xs={12}>
          <Typography variant="h6">{label || 'Precision Specific Params'}</Typography>
        </Grid>
        {errorMessage && (
          <Grid item xs={12}>
            <Typography color="error">{errorMessage}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Slider
            max={maxValue}
            sx={{ mt: 3 }}
            value={minCRange}
            onChange={(event, newValue) => handleMinCChange(newValue as number[])}
            valueLabelDisplay="auto"
            marks={marks}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="space-between">
          <Grid item>
            <Typography>Starting Hist Value</Typography>
            <Input
              value={minCRange[0]}
              size="small"
              onChange={(event) => {
                const newValue = Number(event.target.value);
                handleMinCChange([newValue, minCRange[1]]);
              }}
              onBlur={handleMinBlur}
              inputProps={{
                step: 1,
                min: 0,
                max: maxValue,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
          </Grid>
          <Grid item>
            <Typography>Max Min C</Typography>
            <Input
              value={minCRange[1]}
              size="small"
              onChange={(event) => {
                const newValue = Number(event.target.value);
                handleMinCChange([minCRange[0], newValue]);
              }}
              onBlur={handleMinBlur}
              inputProps={{
                step: 1,
                min: 0,
                max: maxValue,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Slider
            max={maxValue}
            sx={{ mt: 3 }}
            value={maxCRange}
            onChange={(event, newValue) => handleMaxCChange(newValue as number[])}
            valueLabelDisplay="auto"
            marks={marks}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="space-between">
          <Grid item>
            <Typography>Min Max C</Typography>
            <Input
              value={maxCRange[0]}
              size="small"
              onChange={(event) => {
                const newValue = Number(event.target.value);
                handleMaxCChange([newValue, maxCRange[1]]);
              }}
              onBlur={handleBlur}
              inputProps={{
                step: 1,
                min: 0,
                max: maxValue,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
          </Grid>
          <Grid item>
            <Typography>Max Max C</Typography>
            <Input
              value={maxCRange[1]}
              size="small"
              onChange={(event) => {
                const newValue = Number(event.target.value);
                handleMaxCChange([maxCRange[0], newValue]);
              }}
              onBlur={handleBlur}
              inputProps={{
                step: 1,
                min: 0,
                max: maxValue,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PrecisionSpecificForm;
