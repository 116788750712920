import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { deleteCohort } from 'api/cohorts';
import Loader from 'components/Loader';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import CohortDetails from 'components/StudyDashboard/Cohort/CohortDetails';
import { Cohort } from 'interfaces/cohort';
import { Permission } from 'interfaces/permissionOption';
import { filter, map } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import queryClient from 'utils/queryClient';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { usePermissions } from 'utils/usePermissions';
import useCohortFilters from './hooks/useCohortFilters';

export interface EditCohortsAndQueriesProps {
  open: boolean;
  onClose?: () => void;
}

const EditCohortsAndQueries: React.FunctionComponent<React.PropsWithChildren<EditCohortsAndQueriesProps>> = ({
  open,
  onClose,
}) => {
  const { labId } = useCurrentLabId();
  const { queries, cohorts, cohortsLoading } = useCohortFilters();
  const [selectedCohort, setSelectedCohort] = useState<Cohort | null>(null);
  const [deletingCohortId, setDeletingCohortId] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const { hasPermission } = usePermissions();

  const canEditAndDeleteOwnCohorts = hasPermission(Permission.EditAndDeleteOwnCohorts);
  const canEditAndDeleteAllCohorts = hasPermission(Permission.EditAndDeleteAnyCohort);

  const { userId } = useAppSelector((state) => state.auth.profile);

  const confirmWithModal = useConfirmation();

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleRowClick = (event: React.MouseEvent<HTMLDivElement>, cohort: Cohort) => {
    setAnchorEl(event.currentTarget);
    setSelectedCohort(cohort);
  };

  const deleteMutation = useMutationWithErrorSnackbar({
    mutationFn: deleteCohort,
    mutationDescription: 'delete cohort',
    onSuccess(data) {
      queryClient.setQueryData(['cohorts', labId], (oldData: Cohort[]) =>
        filter(oldData, (cohort) => cohort.id !== data)
      );
      enqueueSnackbar('Cohort deleted successfully', { variant: 'success' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['cohorts', labId]);
      setDeletingCohortId(null);
    },
  });

  const handleCohortDelete = async (cohortId: string, type: CohortType) => {
    if (
      await confirmWithModal({
        title: `Delete ${type === CohortType.Cohorts ? 'Cohort' : 'Query'}`,
        text: `Are you sure you want to delete this ${type === CohortType.Cohorts ? 'cohort' : 'query'}?`,
      })
    ) {
      setDeletingCohortId(cohortId);
      deleteMutation.mutate(cohortId);
      return true;
    } else {
      return false;
    }
  };

  const canDeleteCohort = (cohort: Cohort) =>
    canEditAndDeleteAllCohorts || (canEditAndDeleteOwnCohorts && cohort.createdBy === userId);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box justifyContent="space-between" display="flex">
          Cohorts and Queries
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {cohortsLoading ? (
          <Loader />
        ) : (
          <Grid container direction="row" spacing={1}>
            <Grid item xs={6}>
              <CohortList
                type={CohortType.Cohorts}
                cohorts={cohorts}
                deleteMutation={deleteMutation}
                deletingCohortId={deletingCohortId}
                handleCohortDelete={handleCohortDelete}
                handleRowClick={handleRowClick}
                canDeleteCohort={canDeleteCohort}
              />
            </Grid>
            <Grid item xs={6}>
              <CohortList
                type={CohortType.Queries}
                cohorts={queries}
                deleteMutation={deleteMutation}
                deletingCohortId={deletingCohortId}
                handleCohortDelete={handleCohortDelete}
                handleRowClick={handleRowClick}
                canDeleteCohort={canDeleteCohort}
              />
            </Grid>
          </Grid>
        )}

        {selectedCohort && (
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <CohortDetails cohort={selectedCohort} />
          </Popover>
        )}
      </DialogContent>
    </Dialog>
  );
};

enum CohortType {
  Cohorts = 'Cohorts',
  Queries = 'Queries',
}

interface CohortListProps {
  type: CohortType;
  cohorts: Cohort[];
  deleteMutation: ReturnType<typeof useMutationWithErrorSnackbar>;
  deletingCohortId: string | null;
  handleCohortDelete: (cohortId: string, type: CohortType) => void;
  handleRowClick: (event: React.MouseEvent<HTMLDivElement>, cohort: Cohort) => void;
  canDeleteCohort: (cohort: Cohort) => boolean;
}

const CohortList: React.FunctionComponent<CohortListProps> = ({
  type,
  cohorts,
  deleteMutation,
  deletingCohortId,
  handleCohortDelete,
  handleRowClick,
  canDeleteCohort,
}) => {
  return (
    <>
      <Typography variant="subtitle1">{type}</Typography>

      <Box sx={{ maxHeight: 300, overflowY: 'scroll' }}>
        <List>
          {map(cohorts, (cohort, index) => (
            <React.Fragment key={cohort.id}>
              <ListItemButton onClick={(event) => handleRowClick(event, cohort)}>
                <ListItemText primary={cohort.name} />
                <ListItemSecondaryAction>
                  <Tooltip
                    title={
                      canDeleteCohort(cohort)
                        ? 'Delete'
                        : `Can't delete other user's ${type === CohortType.Cohorts ? 'cohort' : 'query'}`
                    }
                  >
                    {/* this is to make the tooltip work an disabled icons */}
                    <span>
                      <IconButton
                        disabled={deleteMutation.isLoading || !canDeleteCohort(cohort)}
                        edge="end"
                        aria-label="delete"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleCohortDelete(cohort.id, type);
                        }}
                      >
                        {deletingCohortId === cohort.id ? <CircularProgress size={20} /> : <DeleteIcon />}
                      </IconButton>
                    </span>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItemButton>
              {index < cohorts.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </>
  );
};

export default EditCohortsAndQueries;
