import { Annotation, TodoDefinition } from 'interfaces/annotation';
import { first, map, values } from 'lodash';

export const getAnnotationLabel = (annotation: Annotation) =>
  `${annotation.annotationAssignment.annotationAssignmentId} - ${annotation.annotationAssignment.name} (${annotation.workingState})`;

export const getAnnotationSettingsKey = (annotation: Annotation, optionName: string) =>
  `${getAnnotationLabel(annotation)}-${optionName}`;

export const getAnnotationTodoNameAndDefinition = (
  annotation: Annotation
): {
  name: string;
  todo: TodoDefinition;
} => {
  const annotationAssignmentTodos = values(annotation?.annotationAssignment?.todos);
  const todoNames = map(annotation?.annotationsData, 'todo');

  if (annotationAssignmentTodos.length > 1) {
    console.warn("Found multiple todos for annotation, shouldn't happen", {
      annotation,
      todoNames,
      annotationAssignmentTodos,
    });
  }
  const todoName = todoNames.length > 0 ? first(todoNames) : 'Unnamed';
  return { name: todoName, todo: first(annotationAssignmentTodos) };
};
