import BackIcon from '@mui/icons-material/ArrowBack';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ScienceIcon from '@mui/icons-material/Science';
import { AppBar, Button, Grid, IconButton, ToggleButton, Toolbar } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

import ToggleSwitch from 'components/atoms/ToggleSwitch';
import NavigationPanel from 'components/Procedure/Header/NavigationPanel';
import SlideMagnification from 'components/Procedure/SlideMagnification';
import SlidesViewerSelector from 'components/Procedure/SlidesViewerSelector';
import SupportMenu from 'components/SiteHeader/SupportMenu';
import { Accession } from 'interfaces/accession';
import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import { getProcedureIdentifier } from 'utils/helpers';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import { useColorMode } from '../../ColorModeContext';
import { headerHeight } from '../constants';
import CaseDataPopover from './CaseDataPopover';
import AccessionDataPopover from './CaseDataPopover/AccessionDataPopover';
import { JobsStatus } from './JobsStatus';
import { SlideInteractionMenu } from './SlideInteractionMenu';
import { ToggleFullScreen } from './ToggleFullScreen';

interface Props {
  procedure?: Procedure | Accession;
  isAccession?: boolean;
  isPlaceholderData?: boolean;
  isLoadingCaseData?: boolean;
  magnificationValue: number;
  showNavigation: boolean;
  onChangeNavigationVisibility(showNavigation: boolean): void;
  onChangeMagnification(newMagnification: number): void;
  onShowNavigatorChange(showNavigator: boolean): void;
}

const Header: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const hideMagnificationBar = props.procedure?.presentationInfo?.hideMagnificationBar;

  const hideProcedureNavigationPanel = props.procedure?.presentationInfo?.hideProcedureNavigationPanel;

  const showProcedureNavigationPanel = !hideProcedureNavigationPanel && !props.isAccession;
  const navigate = useNavigate();
  const colorMode = useColorMode();
  const { mode, toggleColorMode } = colorMode;

  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);
  const [searchTerm] = useQueryParam('searchTerm', StringParam);

  const { queryParams, generateEncodedParams } = useEncodedFilters();
  const [fromStudyFilter] = useQueryParam('fromStudyFilter', StringParam);
  const queryParamsForBackButton = { ...queryParams };
  if (fromStudyFilter) {
    queryParamsForBackButton.filters = { ...queryParams?.filters, studyId: fromStudyFilter };
  }
  const encodedFiltersForBackButton = generateEncodedParams(
    queryParamsForBackButton,
    { pendingSlidesMode: pendingSlidesMode, searchTerm: searchTerm },
    { pendingSlidesMode: BooleanParam, searchTerm: StringParam }
  );

  const backToDashboard = () => navigate(`/procedures?${encodedFiltersForBackButton}`);

  const procedureLabel = getProcedureIdentifier(props.procedure);

  const handleToggleNavigation = () => {
    const showNavigation = !props.showNavigation;
    props.onChangeNavigationVisibility(showNavigation);
    props.onShowNavigatorChange(showNavigation);
  };

  const { hasPermission } = usePermissions();
  const canToggleWebGLViewer = hasPermission(Permission.CanUseBetaViewer);
  const [useDeckGL, setUseDeckGl] = useQueryParam('useDeckGL', BooleanParam);

  return (
    <AppBar position="fixed" color="inherit" sx={{ maxHeight: headerHeight }}>
      <Toolbar variant="dense" disableGutters>
        <Grid container justifyContent="space-between">
          <Grid item container alignItems="center" xs="auto">
            <Grid item ml={1}>
              <Button color="inherit" key="back" startIcon={<BackIcon />} onClick={backToDashboard}>
                Back
              </Button>
            </Grid>
            {showProcedureNavigationPanel && (
              <Grid item>
                <NavigationPanel
                  currentCase={props.procedure}
                  studyId={props.procedure?.studyId}
                  isLoadingCaseData={props.isLoadingCaseData}
                />
              </Grid>
            )}
            {props.isAccession && <AccessionDataPopover accession={props.procedure as Accession} />}
            {!props.isAccession && procedureLabel && <CaseDataPopover procedure={props.procedure} />}
            {!props.isAccession && (
              <Grid item>
                <SlidesViewerSelector
                  key={props.procedure?.id}
                  procedure={props.procedure}
                  isPlaceholderData={props.isPlaceholderData}
                />
              </Grid>
            )}
          </Grid>
          <Grid item container justifyContent="space-between" xs="auto">
            <Grid item container alignItems="center" xs="auto">
              <JobsStatus procedure={props.procedure} />
            </Grid>
          </Grid>
          <Grid item container justifyContent="flex-end" spacing={1} alignItems="center" xs="auto">
            <Grid item>
              <SlideInteractionMenu />
            </Grid>
            <Grid item>
              <ToggleSwitch onChange={handleToggleNavigation} checked={props.showNavigation} title="Inset map" />
            </Grid>
            {!hideMagnificationBar && (
              <SlideMagnification
                magnificationValue={props.magnificationValue}
                onChangeMagnification={props.onChangeMagnification}
              />
            )}
            <Grid item display="flex">
              {canToggleWebGLViewer && (
                <Grid item>
                  <ToggleButton
                    value="useDeckGL"
                    onChange={() => setUseDeckGl(!useDeckGL)}
                    selected={useDeckGL}
                    title="Web GL viewer"
                  >
                    <ScienceIcon color={useDeckGL ? 'primary' : 'inherit'} />
                  </ToggleButton>
                </Grid>
              )}
              <ToggleFullScreen />
              <SupportMenu />
              <IconButton onClick={toggleColorMode} color="inherit">
                {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
