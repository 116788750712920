import { Button, Grid, Switch, Typography } from '@mui/material';
import { Taxonomy, TaxonomyTree } from 'interfaces/taxonomy';
import { compact, filter, flatMap, includes, isEmpty, map, split } from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useEffect, useMemo, useState } from 'react';
import { buildTaxonomyTree } from 'utils/taxonomy';
import TaxonomySearch from './TaxonomySearch';

function getParentPathComponents(item: string) {
  const components = split(item, '.');
  const paths = [];

  // Build the paths step by step
  for (let i = 1; i <= components.length; i++) {
    paths.push(components.slice(0, i).join('.'));
  }

  return paths;
}

function trimTreeToSearchResults(tree: TaxonomyTree, matchedItemPaths: string[]): TaxonomyTree {
  const children = filter(tree.children, (child) => includes(matchedItemPaths, child.path));
  if (!includes(matchedItemPaths, tree.path)) return null;
  if (isEmpty(children)) return { ...tree, children: [] };
  return {
    ...tree,
    children: map(children, (child) => trimTreeToSearchResults(child, matchedItemPaths)),
  };
}

interface TaxonomySearchPanelProps {
  searchValue: string;
  setSearchValue: (value: React.SetStateAction<string>) => void;
  taxonomies: Taxonomy[];
  onSearch: (matchedItems: string[]) => void;
  expandedItems: string[];
  setExpandedItems: (value: React.SetStateAction<string[]>) => void;
  setTaxonomyTreesFiltered: (value: React.SetStateAction<TaxonomyTree[]>) => void;
}

const TaxonomySearchPanel: React.FC<TaxonomySearchPanelProps> = ({
  searchValue,
  setSearchValue,
  taxonomies,
  onSearch,
  expandedItems,
  setExpandedItems,
  setTaxonomyTreesFiltered,
}) => {
  const [filterSearchResults, setFilterSearchResults] = useState(true);
  const [taxonomyFilteredItems, setTaxonomyFilteredItems] = useState<string[]>([]);

  const allPaths = useMemo(() => map(taxonomies, 'path'), [taxonomies]);
  const taxonomyTrees: TaxonomyTree[] = useMemo(() => {
    if (!taxonomies) return [];
    return buildTaxonomyTree(taxonomies);
  }, [taxonomies]);

  useEffect(() => {
    setTaxonomyTreesFiltered(taxonomyTrees);
  }, [taxonomyTrees]);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterSearchResults(event.target.checked);
    if (!event.target.checked || isEmpty(taxonomyFilteredItems)) setTaxonomyTreesFiltered(taxonomyTrees);
    else
      setTaxonomyTreesFiltered(
        compact(map(taxonomyTrees, (tree) => trimTreeToSearchResults(tree, taxonomyFilteredItems)))
      );
  };

  const handleExpandClick = () => {
    if (isEmpty(expandedItems)) {
      setExpandedItems(allPaths);
    } else {
      setExpandedItems([]);
    }
  };

  const applySearch = (value: string) => {
    if (!value) {
      setTaxonomyFilteredItems([]);
      setTaxonomyTreesFiltered(taxonomyTrees);
      onSearch([]);
      return;
    }
    const matchedItems: Taxonomy[] = matchSorter(taxonomies, value, {
      keys: ['name'],
      threshold: matchSorter.rankings.CONTAINS,
    });
    const matchedItemsWithParents = flatMap(map(matchedItems, 'path'), (matchedPath) =>
      getParentPathComponents(matchedPath)
    );
    setTaxonomyFilteredItems(matchedItemsWithParents);
    if (filterSearchResults) {
      setTaxonomyTreesFiltered(
        compact(map(taxonomyTrees, (tree) => trimTreeToSearchResults(tree, matchedItemsWithParents)))
      );
    }
    onSearch(matchedItemsWithParents);
  };

  return (
    <Grid item container width="100%" justifyContent="space-between" direction="row" spacing={1} alignItems="center">
      <Grid item xs={4}>
        <TaxonomySearch value={searchValue} setValue={setSearchValue} onSearch={applySearch} />
      </Grid>
      <Grid item ml={2}>
        <Typography variant="body2">filter search results</Typography>
      </Grid>
      <Grid item>
        <Switch checked={filterSearchResults} onChange={handleSwitchChange} />
      </Grid>
      <Grid item xs={true}></Grid>

      <Grid item>
        <Button onClick={handleExpandClick}>{isEmpty(expandedItems) ? 'Expand all' : 'Collapse all'}</Button>
      </Grid>
    </Grid>
  );
};

export default TaxonomySearchPanel;
