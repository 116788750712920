import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

interface Props {
  disabled?: boolean;
}

const CasesOverviewButton = ({ disabled = false }: Props) => {
  const theme = useTheme();

  const [, setCasesOverviewDrawerOpen] = useQueryParam('casesOverviewDrawerOpen', BooleanParam);

  const onHandleDrawerToggleButton = () => {
    setCasesOverviewDrawerOpen((current) => !current, 'replaceIn');
  };

  // The span is needed to make the tooltip work when the button is disabled
  return (
    <Tooltip title={disabled ? 'Select study to view the overview board' : 'Overview'} placement="top">
      <span>
        <IconButton
          sx={{
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
          }}
          color="primary"
          onClick={onHandleDrawerToggleButton}
          disabled={disabled}
        >
          <BarChartOutlinedIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default CasesOverviewButton;
