/**
 * Calculate the intensity of a channel based on contrast limits, gamma, and opacity.
 * This is done by:
 * 1. Normalizing the intensity to the contrast limits by subtracting the minimum contrast limit and dividing by the range.
 * 2. Scaling the intensity to the range 0-255 for gamma correction (which operates on the color space range).
 * 3. Applying gamma correction to the intensity by raising it to the power of the gamma value.
 * 4. Scaling the intensity back to the range 0-1.
 * 5. Multiplying the intensity by the opacity.
 * All of these steps are done in a way that avoids division by zero and clamps the intensity to the range [0, 1].
 * @param {number} intensity - The intensity of the channel (input) in the range [0, MAX_UINT16].
 * @param {[number, number]} contrastLimits - The contrast limits of the channel. Each value is in the range [0, MAX_UINT16].
 * @param {number} gamma - The gamma value of the channel (no range limit defined imposed by the algorithm, but likely to be [0, 2]).
 * @param {number} opacity - The opacity of the channel, in the range [0, 1].
 * @returns {number} The intensity of the channel in the range [0-1].
 */
const fs = `\
float apply_contrast_limits_gamma_and_opacity(float intensity, vec2 contrastLimits, float gamma, float opacity) {
  return min(
    pow(
      max(0., (intensity - contrastLimits[0]) / max(0.0005, (contrastLimits[1] - contrastLimits[0]))) * 255.,
      max(0.00005, gamma)
    ),
    255.
  ) / 255. * opacity;
}
`;

export default {
  name: 'channel-intensity',
  defines: {
    SAMPLER_TYPE: 'usampler2D',
    COLORMAP_FUNCTION: '',
  },
  fs,
};
