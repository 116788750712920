import { Button, Grid } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { getSlideAnnotationsQueryKey, publishAnnotationVersion, saveAnnotationDraft } from 'api/annotations';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { viewerAnnotationData } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/EditAnnotationLayers/useActiveAnnotationDraft';
import { useSlideAnnotationLayersData } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/useSlideAnnotationLayersData';
import { Annotation } from 'interfaces/annotation';
import { cloneDeep, find, isEqual, isNil, map, pick } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';
import markerAnnotationService from 'services/annotations/markerAnnotationService';
import { useActiveAnnotationAssignmentForViewer } from 'services/annotations/useAnnotationQueryParams';
import queryClient from 'utils/queryClient';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';

export interface AnnotationChangesControlsProps {
  viewerIndex: number;
  slideId: string;
}

export const AnnotationChangesControls: React.FC<React.PropsWithChildren<AnnotationChangesControlsProps>> = ({
  viewerIndex,
  slideId,
}) => {
  useSignals();
  const confirmWithModal = useConfirmation();

  const { slideAnnotations } = useSlideAnnotationLayersData({ slideId });

  const [activeAnnotationAssignmentId] = useActiveAnnotationAssignmentForViewer(viewerIndex);

  const savedAnnotation = useMemo(
    () =>
      find(slideAnnotations, {
        annotationAssignment: { annotationAssignmentId: activeAnnotationAssignmentId },
      }),
    [slideAnnotations, activeAnnotationAssignmentId]
  );

  const getFeaturesCollectionForComparison = useCallback(
    ({ draft = false }: { draft?: boolean } = {}) => {
      const featureCollection = markerAnnotationService.convertAnnotationToGeoJson({
        annotation: cloneDeep(savedAnnotation),
        sortFeatures: true,
        draft,
      });

      return {
        ...featureCollection,
        properties: getPropertiesForComparison(featureCollection.properties),
      };
    },
    [savedAnnotation]
  );

  const savedAnnotationDraftFeatureCollection = useMemo(
    () => getFeaturesCollectionForComparison({ draft: true }),
    [getFeaturesCollectionForComparison]
  );
  const publishedAnnotationVersionFeatureCollection = useMemo(
    () => getFeaturesCollectionForComparison(),
    [getFeaturesCollectionForComparison]
  );

  const sortedViewerFeatureCollectionForComparison = {
    ...viewerAnnotationData[viewerIndex]?.value,
    properties: getPropertiesForComparison(viewerAnnotationData[viewerIndex]?.value?.properties),
    features: viewerAnnotationData[viewerIndex]?.value?.features?.sort(markerAnnotationService.compareFeatures),
  };

  const draftDataSaved =
    isNil(viewerAnnotationData[viewerIndex]?.value) ||
    isEqual(sortedViewerFeatureCollectionForComparison, savedAnnotationDraftFeatureCollection);

  const publishedVersionSaved = useMemo(
    () => isEqual(savedAnnotationDraftFeatureCollection, publishedAnnotationVersionFeatureCollection),
    [savedAnnotationDraftFeatureCollection, publishedAnnotationVersionFeatureCollection]
  );

  const saveDraftMutation = useMutationWithErrorSnackbar({
    mutationFn: saveAnnotationDraft,
    mutationDescription: 'save annotation draft',
    onMutate: ({ annotationAssignmentId, slideId: updatedSlideId, annotationsData }) => {
      queryClient.setQueryData(
        getSlideAnnotationsQueryKey({ slideId: updatedSlideId, includeEmpty: true }),
        (oldData: Annotation[]) => {
          if (oldData) {
            return map(oldData, (annotation) => {
              if (annotation.annotationAssignment.annotationAssignmentId === annotationAssignmentId) {
                return {
                  ...annotation,
                  draftAnnotationsData: annotationsData,
                };
              }
              return annotation;
            });
          }
          return oldData;
        }
      );
    },
    onSuccess: () => {
      enqueueSnackbar('Annotation saved', { variant: 'success' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(getSlideAnnotationsQueryKey({ slideId: slideId, includeEmpty: true }));
    },
  });

  const publishVersionMutation = useMutationWithErrorSnackbar({
    mutationFn: publishAnnotationVersion,
    mutationDescription: 'publish annotation version',
    onMutate: ({ annotationAssignmentId, slideId: updatedSlideId }) => {
      queryClient.setQueryData(
        getSlideAnnotationsQueryKey({ slideId: updatedSlideId, includeEmpty: true }),
        (oldData: Annotation[]) => {
          if (oldData) {
            return map(oldData, (annotation) => {
              if (annotation.annotationAssignment.annotationAssignmentId === annotationAssignmentId) {
                return {
                  ...annotation,
                  annotationsData: annotation.draftAnnotationsData,
                };
              }
              return annotation;
            });
          }
          return oldData;
        }
      );
    },
    onSuccess: () => {
      enqueueSnackbar('Version published', { variant: 'success' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(getSlideAnnotationsQueryKey({ slideId: slideId, includeEmpty: true }));
    },
  });

  const publishVersion = async () => {
    if (
      await confirmWithModal({
        title: 'Publish annotation version',
        text: 'Are you sure you want to publish this annotation version?',
        confirmButtonProps: { title: 'Publish' },
        cancelButtonProps: { title: 'Cancel' },
      })
    ) {
      publishVersionMutation.mutate({
        annotationAssignmentId: activeAnnotationAssignmentId,
        slideId,
      });
    }
  };

  const saveDraft = async () => {
    if (
      await confirmWithModal({
        title: 'Save annotation draft',
        text: 'Are you sure you want to save the annotation?',
        confirmButtonProps: { title: 'Save' },
        cancelButtonProps: { title: 'Cancel' },
      })
    ) {
      saveDraftMutation.mutate({
        annotationAssignmentId: activeAnnotationAssignmentId,
        slideId,
        annotationsData: [
          markerAnnotationService.convertGeoJsonToAnnotationsData(viewerAnnotationData[viewerIndex].value),
        ],
      });
      if (viewerAnnotationData[viewerIndex]) {
        viewerAnnotationData[viewerIndex].value = null;
      }
    }
  };

  const cancelEdit = async () => {
    if (
      await confirmWithModal({
        title: 'Cancel annotation changes',
        text: 'Are you sure you want to cancel?',
        confirmButtonProps: { title: 'Cancel' },
        cancelButtonProps: { title: 'Continue' },
      })
    ) {
      if (viewerAnnotationData[viewerIndex]) {
        viewerAnnotationData[viewerIndex].value = null;
      }
    }
  };

  const disablePublishButton =
    publishedVersionSaved || !draftDataSaved || publishVersionMutation.isLoading || saveDraftMutation.isLoading;

  return (
    activeAnnotationAssignmentId && (
      <>
        <Grid item container justifyContent="end" py={2} spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              value="save"
              onClick={publishVersion}
              disabled={disablePublishButton}
            >
              {publishVersionMutation.isLoading ? 'Publishing...' : 'Publish'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              value="save"
              onClick={saveDraft}
              disabled={draftDataSaved || saveDraftMutation.isLoading}
            >
              {saveDraftMutation.isLoading ? 'Saving...' : 'Save as draft'}
            </Button>
          </Grid>
          <Grid item>
            <Button color="secondary" value="cancel" onClick={cancelEdit} disabled={draftDataSaved}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </>
    )
  );
};

// if old annotations are saved with more data than needed for comparison, this function will remove the extra data
const getPropertiesForComparison = (properties: any) => {
  return pick(properties, ['diagnosis', 'markerType', 'shapeSubType']);
};
