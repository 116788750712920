import { MenuItem, Select } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { CellRule, CellRuleValue } from 'interfaces/cellRule';
import pattern from 'patternomaly';
import React, { useState } from 'react';
import { useUpdateCellRuleMutation } from 'utils/queryHooks/cellRule/useCellRuleMutations';
import CellRuleDisplay from './CellRuleDisplay';

interface CellRuleDropdownProps {
  cellRule: CellRule;
  studyId: string;
}

export const cellRuleOptions = [
  CellRuleValue.Indifferent,
  CellRuleValue.MustBeNegative,
  CellRuleValue.MustBePositive,
  CellRuleValue.OneOfTwoMustBePositive,
];

export const cellRuleColorMap: Record<CellRuleValue, CanvasPattern | string> = {
  [CellRuleValue.Indifferent]: 'transparent',
  [CellRuleValue.MustBePositive]: '#A6DFE0',
  [CellRuleValue.OneOfTwoMustBePositive]: pattern.draw('diagonal-right-left', '#A6DFE0', '#7DC8C9', 10),
  [CellRuleValue.MustBeNegative]: '#E5C0DB',
};

const CellRuleDropdown: React.FC<CellRuleDropdownProps> = ({ cellRule, studyId }) => {
  const [open, setOpen] = useState(true);
  const apiRef = useGridApiContext();

  const updateMutation = useUpdateCellRuleMutation();

  const handleOptionChange = (option: CellRuleValue) => {
    updateMutation.mutate({
      panelId: cellRule.cellRulePanelId,
      updateModel: {
        cellTypeId: cellRule.cellTypeId,
        stainTypeId: cellRule.stainTypeId,
        ruleValue: option ?? CellRuleValue.Indifferent,
      },
      studyId,
    });
    apiRef?.current.setEditCellValue({
      id: cellRule.cellTypeId,
      field: cellRule.stainTypeId,
      value: option ?? CellRuleValue.Indifferent,
    });

    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
    apiRef?.current.stopCellEditMode({ id: cellRule.cellTypeId, field: cellRule.stainTypeId });
  };

  return (
    !updateMutation.isLoading && (
      <Select open={open} onClose={handleClose}>
        {cellRuleOptions.map((option) => (
          <MenuItem key={option} value={option} onClick={() => handleOptionChange(option)}>
            <CellRuleDisplay ruleValue={option} />
          </MenuItem>
        ))}
      </Select>
    )
  );
};

export default CellRuleDropdown;
