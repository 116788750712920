import {
  Button,
  CircularProgress,
  DialogActions,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import { filter, includes, join, map, reduce } from 'lodash';
import React, { ReactElement, useState } from 'react';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';

export interface PresetStainModalProps {
  currentStains: string[];
  presetStains: string[];
  onFinish: (stainsActions: StainsActions) => void;
}

export enum Action {
  KEEP = 'Keep',
  DELETE = 'Delete',
  REASSIGN = 'Reassign',
}

const actions = [Action.KEEP, Action.DELETE, Action.REASSIGN];

export interface StainsActions {
  [stain: string]: { action: Action; reassignTo?: string };
}

const PresetStainModal = ({ currentStains, presetStains, onFinish }: PresetStainModalProps): ReactElement => {
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  const stainsInPresetNotInCurrent = filter(presetStains, (stain) => !includes(currentStains, stain));

  const [stainsActions, setStainsActions] = useState<StainsActions>(() =>
    reduce(
      stainsInPresetNotInCurrent,
      (acc, stain) => {
        acc[stain] = { action: Action.DELETE };
        return acc;
      },
      {} as StainsActions
    )
  );

  return (
    <Dialog
      open={true}
      onClick={(e) => e.stopPropagation()}
      aria-labelledby="responsive-dialog-title"
      data-testid="confirmation-modal"
      data-cy="confirmation-modal"
    >
      <DialogTitle id="responsive-dialog-title">Select Action For Mismatched Stains In Preset</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            {'Current Stains: '}
            {isLoadingStainTypeOptions ? (
              <CircularProgress />
            ) : (
              join(
                map(currentStains, (stain) => stainTypeIdToDisplayName(stain)),
                ', '
              )
            )}
          </Typography>
          <Typography>
            {'Preset Stains: '}
            {isLoadingStainTypeOptions ? (
              <CircularProgress />
            ) : (
              join(
                map(presetStains, (stain) => stainTypeIdToDisplayName(stain)),
                ', '
              )
            )}
          </Typography>
          <Typography variant="h4">
            {'The mismatched stains: '}
            {isLoadingStainTypeOptions ? (
              <CircularProgress />
            ) : (
              join(
                map(stainsInPresetNotInCurrent, (stain) => stainTypeIdToDisplayName(stain)),
                ', '
              )
            )}
          </Typography>
        </DialogContentText>
        {
          <DialogContentText>
            <Typography variant="h4" mt={2}>
              Choose action for each stain:
            </Typography>

            <Grid container spacing={2} p={2}>
              {map(stainsInPresetNotInCurrent, (stain) => (
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={3}>
                    {isLoadingStainTypeOptions ? (
                      <CircularProgress />
                    ) : (
                      <Typography> {stainTypeIdToDisplayName(stain)}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <RadioGroup
                      row
                      value={stainsActions[stain]?.action}
                      onChange={(event) => {
                        setStainsActions((prev) => ({
                          ...prev,
                          [stain]: {
                            action: event.target.value as Action,
                          },
                        }));
                      }}
                    >
                      {map(actions, (action) => {
                        return <FormControlLabel value={action} control={<Radio />} label={action} />;
                      })}
                    </RadioGroup>
                  </Grid>
                  {stainsActions[stain]?.action === Action.REASSIGN && (
                    <Grid item xs={2}>
                      <LabelledDropdown
                        size="small"
                        label="Stain Type"
                        options={map(currentStains, (currentStain) => ({
                          value: currentStain,
                          text: stainTypeIdToDisplayName(currentStain),
                        }))}
                        loading={isLoadingStainTypeOptions}
                        value={stainsActions[stain]?.reassignTo || ''}
                        onOptionSelected={(optionValue) => {
                          setStainsActions((prev) => ({
                            ...prev,
                            [stain]: {
                              action: Action.REASSIGN,
                              reassignTo: optionValue,
                            },
                          }));
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
          </DialogContentText>
        }
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onFinish(stainsActions)}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PresetStainModal;
