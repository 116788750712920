import { FieldSource, FieldSourceId } from 'interfaces/reviewForm';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getFormSource = (sourceId: FieldSourceId, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<{ source: FieldSource }>({
    url: `forms/sources/${encodeURIComponent(sourceId)}`,
    method: 'GET',
    signal,
  });
};
