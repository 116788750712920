import { Grid, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import React from 'react';

import { viewerHoverData } from '../viewerDataSignals';

interface Props {
  viewerIndex: number;
}

const MousePositionOverlay: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ viewerIndex }) => {
  useSignals();
  const [pickingInfo, pickingEvent] = viewerHoverData[viewerIndex].value || [];
  const [imagePoint, setImagePoint] = React.useState<{ x: number; y: number }>(null);

  React.useEffect(() => {
    if (pickingInfo?.coordinate && pickingInfo?.coordinate.length >= 2) {
      setImagePoint({ x: pickingInfo.coordinate[0], y: pickingInfo.coordinate[1] });
    }
  }, [pickingInfo?.coordinate]);

  const showCoordinates = Boolean(pickingInfo) && Boolean(imagePoint);

  return (
    showCoordinates && (
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignContent="space-between"
        sx={{
          width: 140,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: 2,
          color: '#fff',
        }}
      >
        <Grid item xs={3} pl={2} justifySelf="flex-start">
          <Typography variant="body2">X:</Typography>
        </Grid>
        <Grid item xs={3} pr={1} justifySelf="flex-end">
          <Typography variant="body2">{imagePoint.x.toFixed(0)}</Typography>
        </Grid>
        <Grid item xs={3} pl={1} justifySelf="flex-start">
          <Typography variant="body2">Y:</Typography>
        </Grid>
        <Grid item xs={3} pr={2} justifySelf="flex-end">
          <Typography variant="body2">{imagePoint.y.toFixed(0)}</Typography>
        </Grid>
      </Grid>
    )
  );
};

export default MousePositionOverlay;
