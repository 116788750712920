import React from 'react';

import { RowsChangesSummary } from 'components/atoms/EditableDataGrid/TableEditingContext/ChangesSummary';
import { ExternalLabel } from 'interfaces/externalLabel';
import { externalLabelFields } from 'interfaces/externalLabel/externalLabelFields';
import { find } from 'lodash';

export const ExternalLabelRowChangesSummary: React.FC<
  React.PropsWithChildren<{
    externalLabelDisplayString?: string;
    changes: Partial<ExternalLabel>;
  }>
> = ({ changes, externalLabelDisplayString }) => {
  return (
    <RowsChangesSummary
      rowsChanges={{ changes }}
      rowToDisplayString={() => externalLabelDisplayString || `External Label "${changes?.text}"`}
      fieldToDisplayString={(fieldId) => find(externalLabelFields, (f) => f.dataKey === fieldId)?.label || fieldId}
      valueToDisplayString={(value, fieldId) => {
        const field = find(externalLabelFields, (f) => f.dataKey === fieldId);
        return field?.valueFormatter?.({ value }) ?? `${value}`;
      }}
    />
  );
};
