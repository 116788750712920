import { Accession } from 'interfaces/accession';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getAccessions = (queryParams: string) => {
  return apiRequestHandlerPromise<AccessionResponse>({
    url: `accessions?${queryParams}`,
    method: 'GET',
  });
};

export const getAccession = (caseId: number) => {
  return apiRequestHandlerPromise<Accession>({
    url: `cases/${caseId}/accession`,
    method: 'GET',
  });
};

type AccessionResponse = {
  totalAccessions: number;
  accessions: Accession[];
  page: number;
};
