import GL from '@luma.gl/constants';
import { isWebGL2 } from '@luma.gl/gltools';
import { FEATURES, hasFeature } from '@luma.gl/webgl';
import { DecodedPng } from 'fast-png';

import { getDtypeValues } from '../utils';
import generateChannelIntensityFragmentShader from './xr-layer-fragment.glsl';
import generateLayerVertexShaders from './xr-layer-vertex.glsl';

function validateWebGL2Filter(gl: WebGLRenderingContext, interpolation: number) {
  const canShowFloat = hasFeature(gl, FEATURES.TEXTURE_FLOAT);
  const canShowLinear = hasFeature(gl, FEATURES.TEXTURE_FILTER_LINEAR_FLOAT);

  if (!canShowFloat) {
    throw new Error('WebGL1 context does not support floating point textures.  Unable to display raster data.');
  }

  if (!canShowLinear && interpolation === GL.LINEAR) {
    console.warn('LINEAR filtering not supported in WebGL1 context.  Falling back to NEAREST.');
    return GL.NEAREST;
  }

  return interpolation;
}

export function rgbaToRedOnlyUint8(data: Uint8ClampedArray | DecodedPng['data']): Uint8ClampedArray {
  const redOnlyArray = new Uint8ClampedArray(data.length / 4);
  for (let i = 0, j = 0; i < data.length; i += 4, j++) {
    redOnlyArray[j] = data[i];
  }
  return redOnlyArray;
}

export function rgbaToRedOnlyUint16(data: Uint8ClampedArray | DecodedPng['data']) {
  const redOnlyArray = new Uint16Array(data.length / 4);
  for (let i = 0, j = 0; i < data.length; i += 4, j++) {
    redOnlyArray[j] = data[i];
  }
  return redOnlyArray;
}

export function rgbaToRedOnlyFloat32(data: Uint8ClampedArray): Float32Array {
  const redOnlyArray = new Float32Array(data.length / 4);
  for (let i = 0, j = 0; i < data.length; i += 4, j++) {
    redOnlyArray[j] = data[i];
  }
  return redOnlyArray;
}

export function getRenderingAttrs(
  gl: WebGLRenderingContext,
  {
    maxChannels,
    maxTextures,
    interpolation,
    redOnlyOnCPU = false,
    is16Bit = false,
  }: { maxChannels: number; interpolation: number; maxTextures?: number; redOnlyOnCPU?: boolean; is16Bit?: boolean }
) {
  const coreShaderModule = {
    fs: generateChannelIntensityFragmentShader(maxChannels, maxTextures),
    vs: generateLayerVertexShaders(maxChannels, maxTextures),
  };

  if (!isWebGL2(gl)) {
    return {
      format: GL.LUMINANCE,
      dataFormat: GL.LUMINANCE,
      type: GL.FLOAT,
      sampler: 'sampler2D',
      shaderModule: coreShaderModule,
      filter: validateWebGL2Filter(gl, interpolation),
    };
  }
  // Linear filtering only works when the data type is cast to Float32.
  const isLinear = interpolation === GL.LINEAR;
  // Need to add es version tag so that shaders work in WebGL2 since the tag is needed for using usampler2d with WebGL2.
  // Very cursed!
  const upgradedShaderModule = { ...coreShaderModule };
  const version300str = '#version 300 es\n';
  upgradedShaderModule.fs = version300str.concat(upgradedShaderModule.fs);
  upgradedShaderModule.vs = version300str.concat(upgradedShaderModule.vs);
  const values = getDtypeValues(isLinear ? 'Float32' : is16Bit ? 'Uint16' : 'Uint8', redOnlyOnCPU);
  return {
    shaderModule: upgradedShaderModule,
    filter: interpolation,
    ...values,
  };
}
