import { Grid } from '@mui/material';
import { cohortToSlides } from 'components/FeaturesDashboard/chart.util';
import { CohortWithSelectedFeatures } from 'interfaces/cohort_old';
import { filter, flatMap, isEmpty, map } from 'lodash';
import React from 'react';
import {
  getCohortExcludedLabelsText,
  getCohortNoneExcludedLabelsText,
  getLabelsWithoutErrorText,
} from 'utils/qcLabels';
import CohortSlideLabelSummary from './CohortSlideLabelSummary';

interface Props {
  cohort: CohortWithSelectedFeatures;
  type: 'excluded' | 'flagged';
}

const FlaggedSlidesSummary: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ cohort, type }) => {
  const cohortLabels: string[] =
    type === 'flagged' ? getCohortNoneExcludedLabelsText(cohort) : getCohortExcludedLabelsText(cohort);

  const labelsWithData = filter(cohortLabels, (label) =>
    getLabelsWithoutErrorText(flatMap(cohortToSlides(cohort), (slide) => slide.qcLabels)).includes(label)
  );

  return (
    <Grid item container direction="row" spacing={3}>
      {!isEmpty(labelsWithData) ? (
        map(labelsWithData, (label) => (
          <Grid key={label} item container>
            <CohortSlideLabelSummary cohort={cohort} label={label} />
          </Grid>
        ))
      ) : (
        <Grid
          sx={{
            color: 'text.secondary',
            textAlign: 'center',
            width: '100%',
          }}
          item
        >
          No {type} slides
        </Grid>
      )}
    </Grid>
  );
};

export default FlaggedSlidesSummary;
