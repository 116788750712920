import AWS from 'aws-sdk';
import apiRequestHandler, { apiRequestHandlerPromise } from 'utils/apiRequestHandler';
export interface AWSCredentials {
  AccessKeyId: string;
  SecretAccessKey: string;
  UserName: string;
  CreateDate: string;
  Status: string;
}

export const saveStorageToSecret = (
  labId: string,
  storageData: { region: string; slideDirectory: string; accessKeyId: string; secretAccessKey: string },
  onFailure: () => void,
  onSuccess: () => void
) => {
  apiRequestHandler({
    url: `saveStorageToSecret?labId=${labId}`,
    data: JSON.stringify(storageData),
    onSuccess: async () => {
      onSuccess();
    },
    onFailure: (error: any) => {
      onFailure();
      console.error('Got an error saveStorageToSecret', error);
    },
    method: 'POST',
  });
};

export const getAwsTemporaryCredentials = async (labId: string) => {
  const res = await apiRequestHandlerPromise({
    url: `createAwsTemporaryCredentials?labId=${labId}`,
    method: 'GET',
  });
  await new Promise((resolve) => setTimeout(resolve, 10000)); // TODO get rid of this;
  return res;
};

export const deleteAwsTemporaryCredentials = async (credentials: Partial<AWSCredentials>) => {
  const { AccessKeyId, UserName } = credentials;

  return apiRequestHandlerPromise({
    url: 'awsTemporaryCredentials',
    method: 'DELETE',
    data: JSON.stringify({
      AccessKeyId,
      UserName,
    }),
  });
};

export const getS3UploadObject = (creds: AWSCredentials, labId: string, studyId: string, file: File) => {
  const credObj = {
    region: 'us-east-2',
    credentials: new AWS.Credentials(creds.AccessKeyId, creds.SecretAccessKey),
  };

  AWS.config.update({ region: 'us-east-2' });
  const s3 = new AWS.S3({ ...credObj, httpOptions: { timeout: 2000000 } });

  const params = {
    Bucket: `nuc-cst-us-east-2-${labId}`,
    Key: `ATOM/${studyId}/Incoming/${file.name}`,
    Body: file,
  };

  const upload = new AWS.S3.ManagedUpload({
    service: s3,
    params,
  });

  return upload;
};
