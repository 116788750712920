import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import React from 'react';
import { ResponseError } from 'superagent';
import { SnackbarCloseButton } from './SnackbarCloseButton';

interface Props {
  errorMessageGetter?: (error: ResponseError) => string;
}

export const useErrorSnackbarWithRetry = ({
  errorMessageGetter = (error: ResponseError) => error?.response?.body?.message || error?.message,
}: Props = {}) => {
  const enqueueErrorSnackbar = (error: ResponseError, actionDescription: string, retryFunc?: () => void) =>
    enqueueSnackbar(
      <Accordion
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          backgroundImage: 'none',
          color: 'white',
          width: '20vw',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon data-cy="expand-button" sx={{ color: 'white' }} />}
          sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
        >
          <Typography variant="body1">Failed to {actionDescription}</Typography>
        </AccordionSummary>
        <AccordionDetails>{errorMessageGetter(error)}</AccordionDetails>
      </Accordion>,
      {
        variant: 'error',
        action: (key) =>
          retryFunc ? (
            <Button
              disabled={!retryFunc}
              color="primary"
              onClick={() => {
                closeSnackbar(key);
                retryFunc();
              }}
            >
              Try again
            </Button>
          ) : (
            <SnackbarCloseButton snackbarKey={key} />
          ),
      }
    );

  return { enqueueErrorSnackbar };
};
