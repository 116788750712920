import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { CircularProgress, Tooltip, Typography } from '@mui/material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
} from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { UseMutationResult } from '@tanstack/react-query';
import { Dictionary, find, isEmpty, omit, reject, some } from 'lodash';
import React from 'react';

import { ConfirmOptions, useConfirmation } from 'components/modals/ConfirmationContext';
import { DisplayedField } from 'interfaces/genericFields';
import { getFieldChanges } from 'utils/helpers';
import { BasicTableRow } from './TableEditingContext/types';
import { useEditableFieldsDataGridColumns } from './useEditableFieldsDataGridColumns';

export const rowEditingControlsColumn = ({
  disabledEditing,
  mutatingRowId,
  rowModesModel,
  setRowModesModel,
  apiRef,
  getRowError,
  saveOperation,
  deleteOperation,
  isDeletedRow,
  isDraftRow,
  onCancelEdit,
  confirmCancelEdit,
  hideRowActions,
}: {
  disabledEditing?: boolean;
  mutatingRowId?: GridRowId;
  rowModesModel: GridRowModesModel;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  apiRef?: React.MutableRefObject<GridApiCommunity>;
  getRowError?: (
    params: GridRowParams & {
      apiRef?: React.MutableRefObject<GridApiCommunity>;
    }
  ) => string | undefined;
  deleteOperation?: (id: GridRowId) => () => Promise<boolean>;
  isDraftRow?: (id: GridRowId) => boolean;
  isDeletedRow?: (id: GridRowId) => boolean;
  confirmCancelEdit?: (id: GridRowId) => Promise<boolean>;
  // A function that is called when the user clicks the cancel button
  onCancelEdit?: (id: GridRowId) => void;
  // A function that returns a promise that resolves to true if the save operation was successful
  saveOperation: (id: GridRowId) => Promise<boolean>;
  hideRowActions?: (id: GridRowId) => boolean;
}): GridColDef => {
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel((curr) => ({ ...curr, [id]: { mode: GridRowModes.Edit } }));
  };

  const handleSaveClick = (id: GridRowId) => async () => {
    if (await saveOperation(id)) {
      const isDraft = Boolean(isDraftRow?.(id));
      if (isDraft) {
        // Remove the draft row from the row model as it will be replaced with the saved row
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setRowModesModel(({ [id]: draftRowMode, ...rest }) => rest);
      } else {
        setRowModesModel((curr) => ({ ...curr, [id]: { mode: GridRowModes.View } }));
      }
    }
  };

  const handleCancelEditClick = (id: GridRowId) => async () => {
    if (!confirmCancelEdit || (await confirmCancelEdit(id))) {
      const isDraft = Boolean(isDraftRow?.(id));
      if (isDraft) {
        // Remove the draft row from the row model as it will be deleted (canceling the creation of the row)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setRowModesModel(({ [id]: draftRowMode, ...rest }) => rest);
      } else {
        // Set the row mode to view and ignore modifications
        setRowModesModel((curr) => ({
          ...curr,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
        }));
      }
      onCancelEdit?.(id);
      apiRef?.current?.stopRowEditMode({ id, ignoreModifications: true });
    }
  };

  return {
    field: 'actions',
    type: 'actions',
    sortable: false,
    editable: false,
    width: 150,
    cellClassName: 'actions',
    getActions: (params) => {
      const { id } = params;
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      const hideActions = hideRowActions?.(id);

      const error = getRowError?.({ ...params, apiRef });

      if (mutatingRowId === id) {
        return [
          <GridActionsCellItem
            label="Saving..."
            key="Saving..."
            icon={<CircularProgress title="Saving..." size={16} />}
            disabled
            showInMenu={false}
          />,
        ];
      } else if (isDeletedRow?.(id)) {
        return [
          <GridActionsCellItem
            label="Deleted"
            key="Deleted"
            icon={
              <Tooltip title="This row has been deleted">
                <Typography variant="caption">Deprecated</Typography>
              </Tooltip>
            }
            disabled
            showInMenu={false}
          />,
        ];
      } else if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip open={Boolean(error) || undefined} title={error || 'Save'} key="Save">
                <CheckIcon titleAccess="Save" color={error ? 'secondary' : 'primary'} />
              </Tooltip>
            }
            label="Save"
            key="Save"
            onClick={!error && handleSaveClick(id)}
            showInMenu={false}
            disabled={Boolean(error)}
            title={error}
          />,
          <GridActionsCellItem
            icon={<CancelIcon titleAccess="Cancel" />}
            showInMenu={false}
            label="Cancel"
            key="Cancel"
            className="textPrimary"
            onClick={handleCancelEditClick(id)}
            color="inherit"
          />,
        ];
      } else if (hideActions) {
        return [];
      } else {
        return [
          <GridActionsCellItem
            icon={<EditIcon titleAccess="Edit" />}
            label="Edit"
            key="Edit"
            disabled={disabledEditing}
            showInMenu={false}
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          ...(deleteOperation
            ? [
                <GridActionsCellItem
                  key="Delete"
                  icon={<DeleteIcon />}
                  label="Delete"
                  disabled={disabledEditing}
                  onClick={deleteOperation(id)}
                  color="inherit"
                />,
              ]
            : []),
        ];
      }
    },
  };
};

type ConfirmModalOptionsGetter<RowType extends BasicTableRow, DraftType extends BasicTableRow> = (params: {
  id: GridRowId;
  newRowValue: RowType | DraftType;
  currentRowValue: RowType | DraftType;
  isDraft: boolean;
  changes: Dictionary<(RowType | DraftType)[keyof RowType & keyof DraftType]>;
}) => ConfirmOptions;

export const useCrudControlsColumns = <
  RowType extends BasicTableRow,
  DraftType extends BasicTableRow = RowType,
  IDFieldType extends keyof BasicTableRow = 'id'
>({
  currentRows,
  draftRows,
  setDraftRows,
  apiRef,
  idField = 'id' as IDFieldType,
  draftIdField,
  deleteTest = (row: any) => Boolean(row?.deletedAt),
  rowModesModel,
  setRowModesModel,
  createMutation,
  updateMutation,
  deleteMutation,
  rowTypeFields,
  noRows,
  idGetter,
  getRowError,
  getSaveConfirmationModalOptions,
  getCancelEditConfirmationModalOptions,
  getDeleteConfirmationModalOptions,
  mutatingRowId,
  setMutatingRowId,
  hideRowActions,
}: {
  currentRows: Array<RowType | DraftType>;
  draftRows: DraftType[];
  setDraftRows: React.Dispatch<React.SetStateAction<DraftType[]>>;
  idField?: IDFieldType;
  draftIdField?: keyof DraftType;
  apiRef?: React.MutableRefObject<GridApiCommunity>;
  deleteTest?: (row: RowType | DraftType) => boolean;
  rowModesModel: GridRowModesModel;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  createMutation: UseMutationResult<Omit<RowType, IDFieldType>, unknown, Omit<RowType, IDFieldType>, unknown>;
  updateMutation: UseMutationResult<RowType, unknown, RowType, unknown>;
  deleteMutation: UseMutationResult<unknown, unknown, string, unknown>;
  rowTypeFields: DisplayedField<RowType | DraftType>[];
  noRows?: boolean;
  idGetter?: (row: RowType | DraftType) => string | number;
  getRowError?: (
    params: GridRowParams & {
      apiRef?: React.MutableRefObject<GridApiCommunity>;
    }
  ) => string;
  getSaveConfirmationModalOptions: ConfirmModalOptionsGetter<RowType, DraftType>;
  getDeleteConfirmationModalOptions: ConfirmModalOptionsGetter<RowType, DraftType>;
  getCancelEditConfirmationModalOptions: ConfirmModalOptionsGetter<RowType, DraftType>;
  mutatingRowId: GridRowId;
  setMutatingRowId: React.Dispatch<React.SetStateAction<GridRowId>>;
  hideRowActions?: (id: GridRowId) => boolean;
}) => {
  const confirmWithModal = useConfirmation();

  const fieldsColumns = useEditableFieldsDataGridColumns<RowType | DraftType>({
    fields: rowTypeFields,
    disableCellEditing: false,
    isLoading: false,
    noRows,
    bulkEditMode: false,
    idGetter,
    useValueSetter: false,
  });

  return React.useMemo(() => {
    const isDraftRow = (id: GridRowId) => some(draftRows, { [draftIdField || idField]: id });
    const isDeletedRow = (id: GridRowId) => {
      const row = apiRef?.current?.getRowWithUpdatedValues?.(id, '') as RowType | DraftType;
      return deleteTest(row);
    };
    const deleteOperation = (id: GridRowId) => async () => {
      try {
        const isDraft = isDraftRow(id);
        const currentRowValue = find(currentRows, (row) => (idGetter ? idGetter(row) : row[idField]) === id);
        const newRowValue =
          (apiRef?.current?.getRowWithUpdatedValues?.(id, '') as RowType | DraftType) || currentRowValue;
        const changes = getFieldChanges(newRowValue, currentRowValue);
        if (
          await confirmWithModal(
            getDeleteConfirmationModalOptions({ id, newRowValue, currentRowValue, isDraft, changes })
          )
        ) {
          if (some(draftRows, { [draftIdField || idField]: id })) {
            setDraftRows(
              reject(draftRows, (draftRow) => draftRow?.[(draftIdField || idField) as keyof DraftType] === id)
            );
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [id]: draftRoleRowMode, ...rest } = rowModesModel;
            setRowModesModel(rest);
          } else if (id) {
            setMutatingRowId(id);
            await deleteMutation.mutateAsync(`${id}`);
            setMutatingRowId(undefined);
          }
          apiRef?.current.stopRowEditMode({ id });
          return true;
        }
      } catch (err) {
        console.error(err);
        setMutatingRowId(undefined);
      }
      return false;
    };

    const confirmCancelEdit = async (id: GridRowId) => {
      const isDraft = isDraftRow(id);
      const currentRowValue = find(currentRows, (row) => (idGetter ? idGetter(row) : row[idField]) === id);
      const newRowValue =
        (apiRef?.current?.getRowWithUpdatedValues?.(id, '') as RowType | DraftType) || currentRowValue;
      const changes = getFieldChanges(newRowValue, currentRowValue);
      return (
        isEmpty(changes) ||
        (await confirmWithModal(
          getCancelEditConfirmationModalOptions({ newRowValue, currentRowValue, isDraft, changes, id })
        ))
      );
    };

    const saveOperation = async (id: GridRowId) => {
      try {
        const isDraft = isDraftRow(id);
        const currentRowValue = find(currentRows, (row) => (idGetter ? idGetter(row) : row[idField]) === id);
        const newRowValue =
          (apiRef?.current?.getRowWithUpdatedValues?.(id, '') as RowType | DraftType) || currentRowValue;
        const changes = getFieldChanges(newRowValue, currentRowValue);
        if (
          await confirmWithModal(
            getSaveConfirmationModalOptions({ newRowValue, currentRowValue, isDraft, changes, id })
          )
        ) {
          setMutatingRowId(id);
          if (isDraft) {
            const valueToSave =
              draftIdField && draftIdField !== idField ? omit(newRowValue, draftIdField) : newRowValue;
            await createMutation.mutateAsync(valueToSave as RowType);
            setDraftRows(
              reject(draftRows, (draftRow) => draftRow?.[(draftIdField || idField) as keyof DraftType] === id)
            );
          } else {
            await updateMutation.mutateAsync(newRowValue as RowType);
          }
          setMutatingRowId(undefined);
          apiRef?.current.stopRowEditMode({ id });
          return true;
        }
      } catch (err) {
        console.error(err);
        setMutatingRowId(undefined);
      }
      return false;
    };

    const onCancelEdit = (id: GridRowId) => {
      const isDraft = isDraftRow(id);
      if (isDraft) {
        setDraftRows(reject(draftRows, (draftRow) => draftRow?.[(draftIdField || idField) as keyof DraftType] === id));
      }
      apiRef?.current.stopRowEditMode({ id, ignoreModifications: true });
    };

    return [
      ...fieldsColumns,
      rowEditingControlsColumn({
        mutatingRowId,
        rowModesModel,
        setRowModesModel,
        apiRef,
        getRowError,
        deleteOperation,
        isDeletedRow,
        isDraftRow,
        onCancelEdit,
        saveOperation,
        confirmCancelEdit,
        hideRowActions,
      }),
    ];
  }, [
    apiRef,
    confirmWithModal,
    createMutation,
    deleteMutation,
    updateMutation,
    draftRows,
    mutatingRowId,
    rowModesModel,
    setDraftRows,
    setRowModesModel,
    getRowError,
    fieldsColumns,
  ]);
};
