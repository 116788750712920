import { VisualizationCreated } from 'interfaces/visualization';
import { groupBy } from 'lodash';

// This function return a uniq key for each visualization
// Combining the visualization id with the readAs property in case of classification
export const getIsDuplicateKey = (visualization: VisualizationCreated) => {
  return `${visualization.id}${visualization.id === 'classification' ? visualization.readAs : ''}`;
};

// This function checks if the visualization is already exist according to the duplicate key
export const isDuplicate = (visualizations: VisualizationCreated[], visualization: VisualizationCreated) =>
  groupBy(visualizations, (currentVisualization) => {
    return getIsDuplicateKey(currentVisualization);
  })?.[getIsDuplicateKey(visualization)]?.length > 1;
