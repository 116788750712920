import { useQueries, useQuery } from '@tanstack/react-query';
import { map } from 'lodash';

export const useGeoJSONFile = (geoJSONFileURL: string) => {
  return useQuery(
    ['geoJSON', geoJSONFileURL],
    async () => {
      const response = await fetch(geoJSONFileURL);
      return response.json();
    },
    { enabled: !!geoJSONFileURL }
  );
};

export const useGeoJSONFiles = (geoJSONFileURLs: string[]) => {
  return useQueries({
    queries: map(geoJSONFileURLs, (geoJSONFileURL) => ({
      queryKey: ['geoJSON', geoJSONFileURL],
      queryFn: async () => {
        const response = await fetch(geoJSONFileURL);
        return response.json();
      },
    })),
  });
};
