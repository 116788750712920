import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  CircularProgress,
  Grid,
  Popover,
  Typography,
} from '@mui/material';
import React, { useCallback, useRef } from 'react';

import SlideIdentifierBadge from 'components/StudyDashboard/ProceduresPage/ProcedureCard/Carousel/SlideIdentifierBadge';
import { Accession } from 'interfaces/accession';
import { filter, join, map } from 'lodash';
import moment from 'moment';
import { getAccessionIdentifier } from 'utils/helpers';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import CaseDataEntry from './CaseDataEntry';
import CaseDataSectionTitle from './CaseDataSectionTitle';

interface Props {
  accession: Accession;
}

const AccessionDataPopover: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ accession }) => {
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  const procedureLabel = getAccessionIdentifier(accession);

  const { accessionData, slides } = accession;

  const {
    patient: { mrn, dateOfBirth },
    procedureId,
  } = accessionData;

  const buttonRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = useCallback(() => {
    setAnchorEl(buttonRef.current);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  return (
    <Grid item>
      <Chip
        ref={buttonRef}
        label={procedureLabel}
        onClick={handleClick}
        deleteIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onDelete={handleClick}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: -8,
        }}
      >
        <Grid container paddingX={3} paddingTop={1} paddingBottom={2} columns={5} width="375px">
          <Grid item xs={5}>
            <Typography variant="h6" component="h2">
              {procedureLabel}
            </Typography>
          </Grid>

          <CaseDataSectionTitle>Accession Data</CaseDataSectionTitle>
          <CaseDataEntry label="MRN" value={mrn} />
          <CaseDataEntry label="Date of Birth" value={dateOfBirth} />
          <CaseDataEntry label="Procedure ID" value={procedureId} />

          <Accordion
            sx={{
              width: '100%',
              marginTop: '8px',
              marginBottom: '8px',
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <CaseDataSectionTitle>Slide Stain and ID</CaseDataSectionTitle>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} columns={5}>
                {isLoadingStainTypeOptions ? (
                  <CircularProgress />
                ) : (
                  map(slides, (slide, index) => (
                    <CaseDataEntry
                      label={`${stainTypeIdToDisplayName(slide.stainingType)} (${index + 1})`}
                      value={<SlideIdentifierBadge>{slide.id}</SlideIdentifierBadge>}
                      key={slide.id}
                    />
                  ))
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <CaseDataEntry
            label="Stain Types"
            value={
              isLoadingStainTypeOptions
                ? 'Loading...'
                : join(
                    map(
                      filter(accession.slides, (slide) => Boolean(slide?.stainingType)),
                      (slide) => stainTypeIdToDisplayName(slide.stainingType)
                    ),
                    ', '
                  ) || undefined
            }
          />
          <CaseDataEntry label="Case Date" value={moment(accession.createdAt).format('ll')} />
        </Grid>
      </Popover>
    </Grid>
  );
};

export default AccessionDataPopover;
