import { FieldSourceOption, FilledFormFields, FormFieldType, FormType, ReviewForm } from 'interfaces/reviewForm';
import { find, groupBy, isNull, join, map, mapValues, maxBy, reduce, toString, values } from 'lodash';

export const flattenFieldList = (fields: ReviewForm['fields']): ReviewForm['fields'] => {
  return reduce(
    fields,
    (acc, field) => {
      if (field.type === 'group') {
        return [...acc, ...flattenFieldList(field.options.fields)];
      }
      return [...acc, field];
    },
    []
  );
};

export type FormFieldBasic = {
  id: string;
  label: string;
  type: FormFieldType;
  options?: FieldSourceOption[];
};

export const formToFields = (form: ReviewForm): FormFieldBasic[] => {
  const fields = flattenFieldList(form.fields);

  return map(fields, (field) => ({
    id: field.id,
    label: field.label,
    type: field.type,
  }));
};

export const typeToName = (type: FormType) => {
  switch (type) {
    case FormType.ScoreByPathologist:
      return 'Pathologist Evaluation';
    default:
      return type;
  }
};

export const stringifyField = (field: FormFieldBasic, filledFields: FilledFormFields): string => {
  const value = filledFields?.[field.id];

  if (isNull(value)) {
    return '';
  }

  switch (field.type) {
    case 'multiselect':
      return join(map(value as string[], toString), ', ');
    case 'checkbox':
      return value ? 'Yes' : 'No';
    default:
      return toString(value);
  }
};
// scoring function for how specific a certain form is, the more applicable context needed the higher the score

export const formSpecificScore = (form: ReviewForm) => {
  const { applicableContext } = form;
  let score = 0;

  if (applicableContext.labIds) {
    score += 1;
  }

  if (applicableContext.studyIds) {
    score += 2;
  }

  if (applicableContext.slideIds) {
    score += 4;
  }

  return score;
};

export const distinctFormsByType = (allForms: ReviewForm[]) => {
  const formsByType = groupBy(allForms, 'type');

  const mostSpecificFormPerType = mapValues(formsByType, (forms) => {
    return maxBy(forms, formSpecificScore);
  });

  return values(mostSpecificFormPerType);
};
export const findAvailableForm = (forms: ReviewForm[], formType: FormType) => {
  const foundForm = find<ReviewForm>(forms, {
    type: formType,
  });
  return foundForm;
};
