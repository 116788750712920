import { useQuery } from '@tanstack/react-query';
import {
  Orchestration,
  OrchestrationResponse,
  SlideResult,
  getCasesOrchestrations,
  getSlidesOrchestrations,
} from 'api/experimentResults';
import useMainFilters from 'components/SearchFilters/hooks/useMainFilters';
import { ResultsMode, SlideResultsSummaryData, bulkPublishableFlowClasses } from 'interfaces/experimentResults';
import { filter, find, flatMap, get, includes, map, some, uniqBy } from 'lodash';
import { useMemo } from 'react';
import { useCasesParams } from 'utils/useCasesParams';
import { useEncodedFilters } from 'utils/useEncodedFilters';

export const useSlideResults = () => {
  const {
    queryParams: { filters, slidesMode },
  } = useEncodedFilters();
  const { isStudyIdSpecific } = useMainFilters();

  const studyId = get(filters, 'studyId', undefined);
  const studyIdIsSpecific = isStudyIdSpecific(studyId);

  const { casesParams } = useCasesParams({ resultsMode: ResultsMode.Manual });

  const {
    data: orchestrationData,
    isLoading,
    isError,
  } = useQuery<OrchestrationResponse>(
    ['orchestrations', casesParams, { slidesMode }],
    () => (slidesMode ? getSlidesOrchestrations(casesParams) : getCasesOrchestrations(casesParams)),
    { enabled: studyIdIsSpecific }
  );

  // For the first step we only show calculated features results
  const calculatedFeaturesOrchestrationData: Orchestration[] = useMemo(() => {
    return (
      map(
        filter(orchestrationData?.orchestrations, (orchestration) =>
          some(orchestration.slidesData, (slideData) => includes(bulkPublishableFlowClasses, slideData.flowClassName))
        ) || [],
        (orchestration) => ({
          ...orchestration,
          slidesData: filter(orchestration.slidesData, (slideData) =>
            includes(bulkPublishableFlowClasses, slideData.flowClassName)
          ),
        })
      ) || []
    );
  }, [orchestrationData]);

  const slideResultsSummary: SlideResultsSummaryData[] = useMemo(() => {
    const orchestrationDataBySlide: SlideResult[] = flatMap(
      calculatedFeaturesOrchestrationData,
      (orchestration) => orchestration.slidesData
    );
    const approvedSlidesData: SlideResult[] = filter(
      orchestrationDataBySlide,
      (slideData) => slideData.internallyApproved == true || slideData.approved == true
    );

    const slideFlowClassResults: SlideResult[] = uniqBy(
      orchestrationDataBySlide,
      (slideData) => `${slideData.slideId} ${slideData.flowClassName}`
    );

    return map(slideFlowClassResults, ({ slideId, flowClassName, caseId, caseName }) => {
      return {
        slideId,
        caseId,
        caseName,
        flowClassName,
        internallyApprovedOrchestrationId: find(approvedSlidesData, { slideId, flowClassName })?.orchestrationId,
        isPublished: find(approvedSlidesData, { slideId, flowClassName })?.approved || false,
      };
    });
  }, [calculatedFeaturesOrchestrationData]);

  return {
    calculatedFeaturesOrchestrationData,
    slideResultsSummary,
    isLoading,
    isError,
  };
};
