import { Grid, TextField, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import React from 'react';

import Slider from 'components/atoms/Slider';
import { getSlideChannelColorSettingsSignal, useUpdateSlideChannelColorSettings } from '../colorSettings';

export const MultiplexGamma: React.FC<
  React.PropsWithChildren<{
    viewerIndex: number;
    slideId: string;
    markerType?: string;
    channelId: string;
    selected: boolean;
  }>
> = ({ viewerIndex, slideId, channelId, selected, markerType }) => {
  useSignals();
  const slideChannelsSettingsSignal = getSlideChannelColorSettingsSignal(viewerIndex, slideId, channelId);

  const currentGamma = selected ? slideChannelsSettingsSignal?.value?.gamma : 0;

  const { handleGammaChange } = useUpdateSlideChannelColorSettings();

  return (
    <>
      <Grid
        item
        container
        wrap="nowrap"
        alignItems="center"
        spacing={2}
        sx={{
          pt: 0,
          '& .MuiGrid-item': {
            paddingTop: 0,
          },
        }}
      >
        <Grid item>
          <Typography variant="caption">Gamma</Typography>
        </Grid>
        <Grid item md={9}>
          <Slider
            sx={{ pt: 0 }}
            value={currentGamma}
            disabled={!selected}
            onValueChange={(gamma: number) => {
              handleGammaChange(viewerIndex, slideId, channelId, gamma, markerType);
            }}
            max={2}
            step={0.0001}
          />
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" justifyContent="space-between" spacing={2}>
        <Grid item>
          <TextField
            size="small"
            type="number"
            variant="standard"
            inputProps={{ min: 0, max: 2, step: 0.05 }}
            InputProps={{ sx: { fontSize: 10.5 } }}
            value={currentGamma}
            disabled={!selected}
            onChange={(event) => {
              const newGamma = Number(event.target.value);
              handleGammaChange(viewerIndex, slideId, channelId, newGamma, markerType);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
