import React from 'react';
import useLocalStorage from 'use-local-storage';

import { useQuery } from '@tanstack/react-query';
import { fetchStudies } from 'api/study';
import { useSlideLabels } from 'components/StudiesDashboard/StudySettings/StudyLabel/useSlideLabels';
import { DisplayedField } from 'interfaces/genericFields';
import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import {
  getFieldsGroupMap,
  getOrderedFields,
  optionalPluralSuffix,
  ProceduresFieldsCategory,
} from 'interfaces/procedure/fields';
import baseFields from 'interfaces/procedure/fields/baseFields';
import { getFormFieldGroups } from 'interfaces/procedure/fields/formFields';
import { ProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import getLabelsFields from 'interfaces/procedure/fields/qcLabelsFields';
import { QualityControlLabel } from 'interfaces/qualityControlLabels';
import { compact, filter, find, flatten, map } from 'lodash';
import { useForms } from 'utils/queryHooks/form/useForm';
import { ALL_FORMS_ID, useFormResponses } from 'utils/queryHooks/form/useFormResponse';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';

export const useEditableFields = ({
  enabled = true,
}: {
  enabled?: boolean;
} = {}) => {
  const { queryParams } = useEncodedFilters();
  const { labId } = useCurrentLabId();
  const studyId = queryParams?.filters?.studyId;
  const { data: studies, isLoading: isStudiesLoading } = useQuery(['studies', labId], fetchStudies(labId), {
    enabled: Boolean(studyId) && enabled,
  });

  const { hasPermission } = usePermissions();

  const isLabelAvailableForUser = (label: QualityControlLabel) => {
    const canViewInternalLabels = hasPermission(Permission.ViewInternalSlideLabels);

    return (
      canViewInternalLabels ||
      (label.attachFilterLabel && label.filterLabelExternal) ||
      (label.attachRejectLabel && label.rejectLabelExternal)
    );
  };

  const studyLabelsConfig = find(studies, { id: queryParams?.filters?.studyId })?.qcLabelsConfig;
  const labelsAvailable = studyId && !isStudiesLoading;
  const labels = labelsAvailable
    ? map(filter(studyLabelsConfig, isLabelAvailableForUser), (config) => config.filterLabel)
    : [];

  const { getLabelDisplayName } = useSlideLabels();

  const labelsFields: DisplayedField<Procedure, boolean[], ProceduresFieldsContext>[] = getLabelsFields(
    labels,
    getLabelDisplayName
  );

  const { formsWithViewableResponses } = useForms(
    {
      studyId,
    },
    {
      enabled,
    }
  );

  const { data: formResponses } = useFormResponses(
    ALL_FORMS_ID,
    {
      studyId,
    },
    { enabled }
  );

  const formsFieldGroups = getFormFieldGroups(formResponses, formsWithViewableResponses);

  const [selectedTableColumnGroup, setSelectedTableColumnGroup] = useLocalStorage<ProceduresFieldsCategory[]>(
    'selectedTableColumnGroup',
    ['metadata']
  );

  const selectedFields = React.useMemo(
    () =>
      compact(
        flatten([
          ...map(baseFields, 'dataKey'),
          'id',
          ...map(
            selectedTableColumnGroup,
            (group) => getFieldsGroupMap({ labelsFields, formsFieldGroups })?.[group as keyof typeof getFieldsGroupMap]
          ),
        ])
      ),
    [selectedTableColumnGroup, labelsFields, formsFieldGroups]
  );

  const orderedFields: Array<DisplayedField<Procedure, any, ProceduresFieldsContext>> = React.useMemo(
    () => getOrderedFields(labelsFields, formsFieldGroups),
    [labelsFields, formsFieldGroups]
  );

  const slidesFields = React.useMemo(
    () =>
      map(
        // replace id field with case_id
        map(orderedFields, ({ dataKey, ...rest }) => {
          if (dataKey === 'id') {
            return { dataKey: 'caseId', ...rest };
          }
          return { dataKey, ...rest };
        }),
        // remove optional plural suffix from labels
        ({ dataKey, label, ...rest }): DisplayedField<Procedure, any, ProceduresFieldsContext> =>
          label && label.endsWith(optionalPluralSuffix)
            ? {
                ...rest,
                dataKey,
                label: label ? label.slice(0, -optionalPluralSuffix.length) : label,
              }
            : { dataKey, label, ...rest }
      ),
    []
  );

  return {
    selectedFields,
    orderedFields,
    selectedTableColumnGroup,
    setSelectedTableColumnGroup,
    labelsFields,
    labels,
    slidesFields,
  };
};
