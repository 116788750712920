import PeopleIcon from '@mui/icons-material/People';
import { Button, Grid, Icon, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React from 'react';

import { TableEditingContextProvider } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { RowSelectionContextProvider } from 'components/atoms/RowSelectionContext';
import wrapPage from 'components/atoms/wrapPage/wrapPage';
import { Permission } from 'interfaces/permissionOption';
import { useUserCredentialsFieldsContext } from 'interfaces/userCredentials/UserCredentialsFieldsContext';
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params';
import { usePermissions } from 'utils/usePermissions';
import { CurrentUserPermissionsAccordion } from './CurrentUserPermissionsAccordion';
import { RolesManagement } from './RolesManagement';
import { UserCredentialsDataGrid } from './UserCredentialsDataGrid';

const userCredentialsEditingPermissions = [Permission.DefineRolesToLabUsers, Permission.ManageNucleaiUsers];

export const UserCredentialsRolesAndPermissions = wrapPage(() => {
  const userCredentialsFieldsContext = useUserCredentialsFieldsContext();
  const { permissions, isLoadingRoles } = userCredentialsFieldsContext;
  const { hasPermission, hasSomePermissions, hasEveryPermission } = usePermissions();

  const [manageNucleaiUsers, setManageNucleaiUsers] = useQueryParam(
    'manageNucleaiUsers',
    withDefault(BooleanParam, hasPermission(Permission.ManageNucleaiUsers))
  );

  const [showRolesManagement, setShowRolesManagement] = React.useState(false);

  return (
    <>
      {hasSomePermissions(userCredentialsEditingPermissions) ? (
        <>
          <Grid container direction="column" spacing={3}>
            <Grid item container my={4} spacing={1} alignItems="center">
              <Grid item>
                <Icon fontSize="medium">
                  <PeopleIcon />
                </Icon>
              </Grid>
              <Grid item>
                <Typography variant="h1">User Credentials Management</Typography>
              </Grid>
            </Grid>
            <Grid item container justifyContent="space-between">
              {hasSomePermissions(userCredentialsEditingPermissions) ? (
                <>
                  <RolesManagement isOpen={showRolesManagement} onClose={() => setShowRolesManagement(false)} />
                  <Button disabled={isLoadingRoles} variant="contained" onClick={() => setShowRolesManagement(true)}>
                    Manage Roles
                  </Button>
                </>
              ) : null}
              {/* Only show the toggle if the user can manage both lab and nucleai users */}
              {hasEveryPermission(userCredentialsEditingPermissions) ? (
                <ToggleButtonGroup
                  size="small"
                  color="primary"
                  value={manageNucleaiUsers ? 'nucleaiUsers' : 'labUsers'}
                  exclusive
                  onChange={(_, newValue) => setManageNucleaiUsers(newValue === 'nucleaiUsers')}
                  aria-label="Procedure-View-Mode"
                >
                  <ToggleButton value="labUsers">Lab Users</ToggleButton>
                  <ToggleButton value="nucleaiUsers">Nucleai Users</ToggleButton>
                </ToggleButtonGroup>
              ) : null}
            </Grid>
            <RowSelectionContextProvider>
              <TableEditingContextProvider fieldsContext={userCredentialsFieldsContext}>
                <UserCredentialsDataGrid manageNucleaiUsers={manageNucleaiUsers} />
              </TableEditingContextProvider>
            </RowSelectionContextProvider>
          </Grid>
        </>
      ) : null}
      <CurrentUserPermissionsAccordion permissions={permissions} />
    </>
  );
});
