import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { DropdownOption } from 'components/atoms/Dropdown/Dropdown';
import { difference, every, filter, includes, map, union } from 'lodash';
import React, { useState } from 'react';
import { ProcedureFilterProps } from '../MainFilters';

interface Props extends Partial<ProcedureFilterProps> {
  options: DropdownOption[];
  label?: string;
  limitTags?: number;
  handleChange: (value: string[]) => void;
  value: string[];
  loading?: boolean;
}

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

const AutocompleteFilter = ({ label, options, limitTags = 10, handleChange, value, loading }: Props) => {
  const [inputValue, setInputValue] = useState('');
  const theme = useTheme();

  // if we want to have the 'all' option, we need to add the prop ListboxComponent={ListboxComponent} to the Autocomplete
  const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>((props, ref) => {
    const isAllChecked = every(options, (item) => includes(value, item.value));
    return (
      <div ref={ref} {...props} style={{ overflowX: 'hidden' }}>
        <FormControlLabel
          onClick={(e) => {
            e.preventDefault();
            toggleCheckAll();
          }}
          label="All"
          control={<Checkbox id="select-all-checkbox" size="small" checked={isAllChecked} />}
          sx={{ px: 2, borderBottom: `1px solid ${theme.palette.grey[200]}`, width: '100%', mx: 0 }}
        />
        {props.children}
      </div>
    );
  });

  const toggleCheckAll = () => {
    const allValues = map(options, (item) => item.value);
    handleChange(every(allValues, (item) => includes(value, item)) ? [] : allValues);
  };

  const onCheck = (event: React.ChangeEvent<HTMLInputElement>, option: DropdownOption) => {
    const { value: curValue } = option;
    const newChecked = includes(value, curValue) ? difference(value, [curValue]) : union(value, [curValue]);
    handleChange(newChecked);
  };

  return (
    <Autocomplete
      multiple
      limitTags={limitTags}
      clearOnBlur={false}
      sx={{
        '&.MuiAutocomplete-root': {
          '& .MuiButtonBase-root': { maxWidth: '180px' },
        },
      }}
      size="small"
      value={filter(options, (item) => includes(value, item.value))}
      disableCloseOnSelect
      blurOnSelect={false}
      options={options}
      getOptionLabel={(option) => option.text}
      onChange={(event: any, newValue: any | null) => {
        const selectedValues: string[] = map(newValue, (item) => item.value);
        handleChange(selectedValues);
      }}
      inputValue={inputValue}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} style={{ paddingTop: 0, paddingBottom: 0 }} key={option.value}>
            <Checkbox
              size="small"
              checked={selected}
              icon={icon}
              checkedIcon={checkedIcon}
              onChange={(e) => onCheck(e, option)}
            />
            {option.text}
          </li>
        );
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'clear') {
          handleChange([]);
        } else {
          setInputValue(newInputValue);
        }
      }}
      filterOptions={(x) => filter(x, (item) => includes(item.text.toLowerCase(), inputValue.toLowerCase()))}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteFilter;
