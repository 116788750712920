import {
  ExperimentResult,
  ExperimentResultsResultType,
  FlowClassName,
  OptionsEntry,
  PresentationInfoLayer,
  publishableFlowClasses,
  publishableResultTypes,
  ResultColorMap,
} from 'interfaces/experimentResults';
import {
  compact,
  concat,
  filter,
  find,
  findIndex,
  flatMap,
  flatMapDeep,
  groupBy,
  includes,
  isEmpty,
  isString,
  map,
  partition,
  replace,
  some,
  uniqBy,
} from 'lodash';
import { isBrackets } from 'utils/formatBrackets';
import { FormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { formatBracketsVisualization } from 'utils/formatBrackets/formatBracketsVisualization';
import { humanize } from 'utils/helpers';

const extensionRegex = /\.\S+$/g;
const underscoreAndPathSepRegex = /[_/]/g;

export const experimentResultToGroup = (result: FeatureMetadata) => result.resultType || result.flowClassName;

export enum HeatmapType {
  Dzi = 'dzi',
  Pmt = 'pmt',
  GeoJson = 'geojson',
  PmtLayer = 'pmt-layer',
  Parquet = 'parquet',
}

export interface FeatureMetadata {
  key?: string;
  id: string;
  value?: number | string;
  heatmapType?: HeatmapType;
  columnName?: string;
  columnType?: OptionsEntry['type'];
  heatmapUrl?: string;
  cellsDataUrl?: string;
  nestedItems?: FeatureMetadata[];
  secondaryResults?: FeatureMetadata[];
  displayName?: string;
  orchestrationId?: string;
  primaryRunOrchestrationId?: string;
  numFeatures?: number;
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  approved?: boolean;
  internallyApproved?: boolean;
  experimentResultId?: number;
  flowClassName?: FlowClassName;
  resultType?: ExperimentResultsResultType;
  color?: string | ResultColorMap;
  options?: ExperimentResult['options'];
}

export interface Results {
  publishedResults: FeatureMetadata[];
  internalResults: {
    [key: string]: FeatureMetadata[];
  };
  deletedResults?: FeatureMetadata[];
}

export interface ParsedResults {
  heatmaps: Results;
  features: Results;
  internalHeatmaps?: {
    [key: string]: FeatureMetadata[];
  };
}

/**
 * Until we standardize the presentation info schema, we need to handle different types of heatmaps
 */
const getPresentationInfoForOptionKey = (experimentResult: ExperimentResult, optionKey: string) => {
  return (experimentResult.presentationInfo?.[optionKey] ||
    experimentResult.presentationInfo?.layers?.[optionKey] ||
    find(experimentResult.presentationInfo?.layers, { key: optionKey }) ||
    find(experimentResult.presentationInfo?.layers, { class_name: optionKey })) as
    | PresentationInfoLayer
    | string
    | undefined;
};

const getHeatmapDisplayName = (context: FormatBracketsOptions, key: string, mainHeatmapKey?: string) => {
  if (isBrackets(key)) {
    return formatBracketsVisualization(key, context, mainHeatmapKey);
  }

  // legacy name
  const itemConfig = context.uiSettings.webappSidebarConfig.heatmapsConfig[key];
  return itemConfig?.displayName || replace(key, /_/g, ' ');
};

const parseBaseFeatureMetadataFromExperimentResult = (
  experimentResult: ExperimentResult,
  keyPrefix: string // keyPrefix is used to differentiate between different types of features / heatmaps for the same experiment result
): FeatureMetadata => ({
  id: `${keyPrefix}[${experimentResult.experimentResultId}]`,
  key: `${keyPrefix}[${experimentResult.experimentResultId}]`,
  experimentResultId: experimentResult.experimentResultId,
  orchestrationId: experimentResult.orchestrationId,
  numFeatures: experimentResult.numFeatures,
  createdAt: experimentResult.createdAt,
  approved: Boolean(experimentResult.approved),
  internallyApproved: Boolean(experimentResult.internallyApproved),
  flowClassName: experimentResult.flowClassName,
  resultType: experimentResult.resultType,
  primaryRunOrchestrationId: experimentResult.primaryRunOrchestrationId,
  deletedAt: experimentResult.deletedAt,
  deletedBy: experimentResult.deletedBy,
  options: experimentResult.options,
});

const parseFeatures = (slideResults: ExperimentResult[]) => {
  const resultsWithFeatures = filter(slideResults, (result) => result.numFeatures > 0);

  // we filter out duplicate orchestration ids to avoid showing the same results twice
  // we do this to avoid a bug when calculated results where saved each heatmap in a different experiment result (all with the same features)
  const slideUniqOrchIdResults = uniqBy(resultsWithFeatures, 'orchestrationId');

  if (slideUniqOrchIdResults.length !== resultsWithFeatures.length) {
    const duplicateOrchIds = groupBy(
      filter(
        resultsWithFeatures,
        (result) => filter(resultsWithFeatures, { orchestrationId: result.orchestrationId }).length > 1
      ),
      'orchestrationId'
    );
    console.warn('Found duplicate orchestration ids in slide feature results', duplicateOrchIds);
  }

  const allFeaturesResults: FeatureMetadata[] = map(slideUniqOrchIdResults, (er) =>
    parseBaseFeatureMetadataFromExperimentResult(er, 'parseFeatures-')
  );

  const [secondaryResults, primaryResults] = partition(allFeaturesResults, (result) =>
    Boolean(result.primaryRunOrchestrationId)
  );

  const secondaryResultsWithoutPrimary = filter(
    secondaryResults,
    (result) => !some(primaryResults, { orchestrationId: result.primaryRunOrchestrationId })
  );

  const resultsWithSecondaryAsNested = map(primaryResults, (primaryResult) => {
    const secondaryResultsForPrimary = filter(secondaryResults, {
      primaryRunOrchestrationId: primaryResult.orchestrationId,
    });

    return {
      ...primaryResult,
      secondaryResults: secondaryResultsForPrimary,
    };
  });

  const allFinalResults = [...resultsWithSecondaryAsNested, ...secondaryResultsWithoutPrimary];

  const [deletedFeatures, finalResults] = partition(
    allFinalResults,
    // Hidden secondary results are handled separately
    (result) => Boolean(result.deletedAt) && isEmpty(result?.secondaryResults)
  );

  // A feature is considered published if it's approved or if any of its secondary results are published
  const [publishedFeatures, unpublishedFeatures] = partition(finalResults, 'approved');

  const internalFeatures = groupBy<FeatureMetadata>(
    filter(unpublishedFeatures, (feature) =>
      Boolean(!feature.approved && (feature.resultType || feature.flowClassName))
    ),
    experimentResultToGroup
  );

  return { publishedFeatures, internalFeatures, deletedFeatures };
};

const parsePmtHeatmap = (
  experimentResult: ExperimentResult,
  layerInfo: PresentationInfoLayer,
  geoJsonLayers?: FeatureMetadata[]
): FeatureMetadata | null => {
  const compositePresentationInfo = getPresentationInfoForOptionKey(experimentResult, 'composite_layer_info');
  const pmtHeatmapComponents = layerInfo || (compositePresentationInfo as PresentationInfoLayer);
  const pmtHeatmapUrl = pmtHeatmapComponents?.storage_url;
  if (!pmtHeatmapUrl) {
    console.warn('Dynamic heatmap url not found', { layerInfo, experimentResult });
    return null;
  }
  if (layerInfo?.file_type && layerInfo.file_type !== 'pmt') {
    console.warn('Unsupported file type for pmt heatmap', { layerInfo, experimentResult });
    return null;
  }

  const optionsForHeatmap = find(experimentResult.options, { key: layerInfo.key })?.layers || experimentResult.options;
  const nestedItems: FeatureMetadata[] = map(optionsForHeatmap, (heatmapOption) => {
    const matchingLayer = find(geoJsonLayers, { key: heatmapOption.key });
    return {
      id: `[${experimentResult.experimentResultId}]-${heatmapOption.key}`,
      key: heatmapOption.key,
      color: heatmapOption.color,
      show: false,
      selected: false,
      displayName: heatmapOption?.key,
      ...(matchingLayer || {}),
      options: optionsForHeatmap,
    };
  });

  const displayName =
    experimentResult?.name ||
    // If the experiment result name is not available, use the artifact url as the name by replacing the file extension and underscores with spaces
    `[${experimentResult.experimentResultId}] - ${replace(
      replace(pmtHeatmapComponents?.artifact_url, extensionRegex, ''),
      underscoreAndPathSepRegex,
      ' '
    )}`;
  return {
    ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parsePmtHeatmap-'),
    displayName: `Vector Heatmap (beta) - ${displayName}`,
    heatmapUrl: pmtHeatmapUrl,
    heatmapType: HeatmapType.Pmt,
    nestedItems,
    options: optionsForHeatmap,
  };
};

export const parseGeoJsonHeatmap = (
  experimentResult: ExperimentResult,
  layerInfo: PresentationInfoLayer,
  formatBracketsOptions: FormatBracketsOptions
): FeatureMetadata | null => {
  const geoJsonUrl = layerInfo?.storage_url;
  if (!geoJsonUrl) {
    console.warn('Dynamic heatmap url not found', { layerInfo, experimentResult });
    return null;
  }
  if (layerInfo?.file_type && layerInfo.file_type !== 'geojson') {
    console.warn('Unsupported file type for geojson heatmap', { layerInfo, experimentResult });
    return null;
  }

  const optionsForHeatmap = find(experimentResult.options, { key: layerInfo.key })?.layers || experimentResult.options;
  const matchingOption = find(optionsForHeatmap, { key: layerInfo.class_name });

  // We support his direct presentation info parsing since this is a legacy feature anyway
  const layerIndex = findIndex(experimentResult?.presentationInfo?.layers, { class_name: layerInfo.class_name });

  if (!matchingOption && layerIndex === -1) {
    console.warn('No matching option found for geojson layer', { layerInfo, experimentResult });
    return null;
  }

  const displayName =
    getHeatmapDisplayName(formatBracketsOptions, matchingOption?.key || layerInfo?.class_name) ||
    // If the experiment result name is not available, use the artifact url as the name by replacing the file extension and underscores with spaces
    `${replace(replace(layerInfo?.artifact_url, extensionRegex, ''), underscoreAndPathSepRegex, ' ')}`;
  return {
    ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseGeoJsonHeatmap-'),
    id: `parseGeoJsonHeatmap-[${experimentResult.experimentResultId}]=geojson-${layerInfo.class_name}`,
    key: matchingOption?.key || layerInfo?.class_name || `[${experimentResult.experimentResultId}]-${layerIndex}`,
    displayName,
    heatmapUrl: geoJsonUrl,
    heatmapType: HeatmapType.GeoJson,
  };
};

export const parseLayeredVectorHeatmaps = (
  experimentResult: ExperimentResult,
  formatBracketsOptions: FormatBracketsOptions
): FeatureMetadata | null => {
  const parentDisplayName = `${experimentResult.name || humanize(experimentResult.flowClassName)}`;

  // TODO: we should align the options schema to have the nested layers in an option.layers array, making the source of truth the options array
  // This will then become the 'legacy' parser for pmt (if we decide to keep it)
  const pmtLayer = find(experimentResult?.presentationInfo?.layers, { file_type: 'pmt' });
  if (!pmtLayer) {
    return null;
  }

  const nestedOptions = find(experimentResult.options, { key: pmtLayer.key })?.layers || experimentResult.options;

  const nestedItems: FeatureMetadata[] = compact(
    map(nestedOptions, (option) => {
      // TODO: we should align the options schema to have the nested layers in an option.layers array, making the source of truth the options array
      // This will then become the 'legacy' parser for pmt (if we decide to keep it)
      const matchingLayer = getPresentationInfoForOptionKey(experimentResult, option.key) as PresentationInfoLayer;
      // For each option, find the matching layer and parse it as a heatmap if it's a geojson layer
      if (matchingLayer) {
        if (matchingLayer?.file_type === 'parquet') {
          return null; // Handled by parseParquetHeatmaps
        } else if (matchingLayer?.file_type === 'geojson') {
          // If the layer is a geojson layer, parse it as a geojson heatmap
          return parseGeoJsonHeatmap(experimentResult, matchingLayer, formatBracketsOptions);
        } else if (matchingLayer && matchingLayer.file_type !== 'pmt') {
          // If the layer is not a pmt layer, log a warning, since we don't support other types of layers yet
          console.warn('Unsupported file type for layered heatmap', { matchingLayer, experimentResult, option });
        }
      }

      // If no layer is found, parse the option as a 'pmt-layer' heatmap, which contains the option's color and key
      return {
        ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseLayeredVectorHeatmaps-nested-'),
        id: `parseLayeredVectorHeatmaps-nested-[${experimentResult.experimentResultId}]-${option.key}`,
        displayName: getHeatmapDisplayName(formatBracketsOptions, option?.key),
        heatmapType: HeatmapType.PmtLayer,
        options: nestedOptions,
        key: option.key,
      };
    })
  );

  const pmtHeatmap = pmtLayer ? parsePmtHeatmap(experimentResult, pmtLayer, nestedItems) : null;
  return (
    pmtHeatmap || {
      ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseLayeredVectorHeatmaps-'),
      displayName: parentDisplayName,
      nestedItems,
      options: nestedOptions,
    }
  );
};

const parseLegacyRasterHeatmaps = (experimentResult: ExperimentResult): FeatureMetadata[] => {
  if (!experimentResult) {
    return [];
  }
  const singleHeatmapUrlPresentationInfo = getPresentationInfoForOptionKey(experimentResult, 'heatmap_url');

  if (isEmpty(singleHeatmapUrlPresentationInfo)) {
    return [];
  }

  const parentDisplayName = experimentResult.name || humanize(experimentResult.flowClassName);
  const nestedItems: FeatureMetadata[] = map(experimentResult.options, (heatmapOption) => {
    return {
      id: `[${experimentResult.experimentResultId}]-${heatmapOption.key}`,
      key: heatmapOption.key,
      color: heatmapOption.color,
      show: false,
      selected: false,
      displayName: heatmapOption?.key,
    };
  });

  const newHMResult: FeatureMetadata = {
    ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseLegacyRasterHeatmaps-'),
    displayName: parentDisplayName,
    heatmapUrl:
      (singleHeatmapUrlPresentationInfo as PresentationInfoLayer)?.storage_url ||
      (singleHeatmapUrlPresentationInfo as string),
    heatmapType: HeatmapType.Dzi,
    nestedItems,
  };

  return [newHMResult];
};

/*
 * Get all the heatmap 'options' and parse their nested layers as composite raster heatmaps
 */
const parseCompositeRasterHeatmapUrls = (
  experimentResult: ExperimentResult,
  formatBracketsOptions: FormatBracketsOptions
): FeatureMetadata[] => {
  return flatMap(experimentResult.options, (heatmapOption) => {
    const compositePresentationInfo = heatmapOption
      ? getPresentationInfoForOptionKey(experimentResult, heatmapOption.key)
      : null;

    if (!compositePresentationInfo || !isString(compositePresentationInfo)) {
      return [];
    }

    const parentDisplayName = getHeatmapDisplayName(formatBracketsOptions, heatmapOption.key);

    const nestedItems: FeatureMetadata[] = compact(
      map(heatmapOption.layers, (nestedHeatmap) => {
        const presentationInfo =
          getPresentationInfoForOptionKey(experimentResult, heatmapOption.key.concat(nestedHeatmap.key)) ||
          getPresentationInfoForOptionKey(experimentResult, nestedHeatmap.key);
        if (!presentationInfo || !isString(presentationInfo)) {
          return null;
        }
        return {
          id: `[${experimentResult.experimentResultId}]-${heatmapOption.key}-${nestedHeatmap.key}`,
          key: nestedHeatmap.key,
          color: nestedHeatmap.color,
          heatmapUrl: presentationInfo,
          show: false,
          selected: false,
          displayName: getHeatmapDisplayName(formatBracketsOptions, nestedHeatmap.key, heatmapOption.key),
          heatmapType: HeatmapType.Dzi,
          options: heatmapOption.layers,
        };
      })
    );

    const newHMResult: FeatureMetadata = {
      ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseCompositeRasterHeatmapUrls-'),
      id: `[${experimentResult.experimentResultId}]-${heatmapOption.key}`,
      key: heatmapOption.key,
      color: heatmapOption.color,
      heatmapUrl: compositePresentationInfo,
      heatmapType: HeatmapType.Dzi,
      nestedItems,
      displayName: parentDisplayName,
      options: heatmapOption.layers,
    };

    const hasUrls = Boolean(newHMResult?.heatmapUrl) || some(newHMResult.nestedItems, 'heatmapUrl');

    return hasUrls ? newHMResult : [];
  });
};

/*
 * Get all the heatmap 'options' and parse their nested layers as composite raster heatmaps
 */
const parseParquetHeatmaps = (
  experimentResult: ExperimentResult,
  formatBracketsOptions: FormatBracketsOptions
): FeatureMetadata[] => {
  return flatMap(experimentResult.options, (heatmapOption) => {
    if (heatmapOption?.type === 'point') {
      // Point columns are the X, Y coordinates of cells, not data for a heatmap
      return [];
    }
    const presentationInfo = getPresentationInfoForOptionKey(experimentResult, heatmapOption.key);
    if (!presentationInfo || isString(presentationInfo) || presentationInfo.file_type !== 'parquet') {
      return [];
    }
    const columnName = heatmapOption.column_name;
    if (!columnName) {
      console.warn('No column name found for parquet heatmap', { heatmapOption, experimentResult });
      return [];
    }
    const parentDisplayName = getHeatmapDisplayName(formatBracketsOptions, heatmapOption.key);
    const nestedOptions = heatmapOption?.layers;
    const nestedItems: FeatureMetadata[] = map(nestedOptions, (nestedHeatmap) => {
      return {
        ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseParquetHeatmaps-'),
        id: `[${experimentResult.experimentResultId}]-${heatmapOption.key}-${heatmapOption.column_name}-${nestedHeatmap.key}`,
        key: `${heatmapOption.key}-${nestedHeatmap.key}`,
        color: nestedHeatmap.color,
        show: false,
        selected: false,
        displayName: getHeatmapDisplayName(formatBracketsOptions, nestedHeatmap.key, heatmapOption.key),
        heatmapType: HeatmapType.Parquet,
        options: nestedOptions,
        columnName: heatmapOption.column_name,
        columnType: heatmapOption?.type,
      };
    });

    const newHMResult: FeatureMetadata = {
      ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseParquetHeatmaps-'),
      id: `[${experimentResult.experimentResultId}]-${heatmapOption.key}-${heatmapOption.column_name}`,
      key: heatmapOption.key,
      color: heatmapOption.color,
      heatmapUrl: presentationInfo.storage_url,
      heatmapType: HeatmapType.Parquet,
      nestedItems,
      displayName: parentDisplayName,
      options: nestedOptions,
      columnName: heatmapOption.column_name,
      columnType: heatmapOption?.type,
    };

    const hasUrls = Boolean(newHMResult?.heatmapUrl) || some(newHMResult.nestedItems, 'heatmapUrl');

    return hasUrls ? newHMResult : [];
  });
};

export const parseHeatmaps = (slideResults: ExperimentResult[], formatBracketsOptions: FormatBracketsOptions) => {
  const heatmapsWithPresentationInfoAndWithoutOptions = filter(
    slideResults,
    (result) => !isEmpty(result.presentationInfo) && isEmpty(result.options)
  );
  if (!isEmpty(heatmapsWithPresentationInfoAndWithoutOptions)) {
    console.warn('Found results with presentation info but no options', heatmapsWithPresentationInfoAndWithoutOptions);
  }
  const allHeatmapsResults: FeatureMetadata[] = flatMap(slideResults, (experimentResult) => {
    const heatmaps: FeatureMetadata[] = [];

    const topLevelPmtHeatmap = getPresentationInfoForOptionKey(experimentResult, 'composite_layer_info');

    if (topLevelPmtHeatmap) {
      const topLevelPmt = parsePmtHeatmap(experimentResult, topLevelPmtHeatmap as PresentationInfoLayer);
      if (topLevelPmt) {
        heatmaps.push(topLevelPmt);
      }
    }

    heatmaps.push(...parseLegacyRasterHeatmaps(experimentResult));

    heatmaps.push(...parseCompositeRasterHeatmapUrls(experimentResult, formatBracketsOptions));

    heatmaps.push(...parseParquetHeatmaps(experimentResult, formatBracketsOptions));

    // PMT heatmaps aren't production ready yet, so we don't show them to customers
    if (!experimentResult?.approved) {
      const layeredVectorHeatmap = parseLayeredVectorHeatmaps(experimentResult, formatBracketsOptions);
      if (layeredVectorHeatmap) {
        heatmaps.push(layeredVectorHeatmap);
      }
    }

    return heatmaps;
  });

  const [secondaryResults, primaryResults] = partition(allHeatmapsResults, (result) =>
    Boolean(result.primaryRunOrchestrationId)
  );

  const secondaryResultsWithoutPrimary = filter(
    secondaryResults,
    (result) => !some(primaryResults, { orchestrationId: result.primaryRunOrchestrationId })
  );

  const resultsWithSecondaryAsNested = map(primaryResults, (primaryResult) => {
    const secondaryResultsForPrimary = filter(secondaryResults, {
      primaryRunOrchestrationId: primaryResult.orchestrationId,
      key: primaryResult.key,
    });

    return {
      ...primaryResult,
      secondaryResults: secondaryResultsForPrimary,
    };
  });

  const allHeatmapsResultsWithNested = [...resultsWithSecondaryAsNested, ...secondaryResultsWithoutPrimary];

  const [deletedHeatmaps, heatmapsResults] = partition(
    allHeatmapsResultsWithNested,
    // Hidden secondary results are handled separately
    (result) => Boolean(result.deletedAt) && isEmpty(result?.secondaryResults)
  );

  const [publishedHeatmaps, allUnpublishedHeatmaps] = partition(
    heatmapsResults,
    (heatmap) => heatmap.approved || some(heatmap.secondaryResults, 'approved')
  );
  const visibleUnpublishedHeatmaps = filter(allUnpublishedHeatmaps, (heatmap) => Boolean(heatmap.flowClassName));

  const [publishableHeatmapsList, unpublishableHeatmapsList] = partition(
    visibleUnpublishedHeatmaps,
    (heatmap) =>
      includes(publishableFlowClasses, heatmap.flowClassName) || includes(publishableResultTypes, heatmap.resultType)
  );
  const publishableHeatmaps = groupBy(publishableHeatmapsList, experimentResultToGroup);
  const internalHeatmaps = groupBy(unpublishableHeatmapsList, experimentResultToGroup);

  return { publishedHeatmaps, publishableHeatmaps, internalHeatmaps, deletedHeatmaps };
};

export const parseSlideExperimentResults = (
  slideResults: ExperimentResult[],
  formatBracketsOptions: FormatBracketsOptions
): ParsedResults => {
  const { publishedFeatures, internalFeatures, deletedFeatures } = parseFeatures(slideResults);

  const { publishedHeatmaps, publishableHeatmaps, internalHeatmaps, deletedHeatmaps } = parseHeatmaps(
    slideResults,
    formatBracketsOptions
  );

  const results: ParsedResults = {
    heatmaps: {
      publishedResults: publishedHeatmaps,
      internalResults: publishableHeatmaps,
      deletedResults: deletedHeatmaps,
    },
    features: {
      publishedResults: publishedFeatures,
      internalResults: internalFeatures,
      deletedResults: deletedFeatures,
    },
    internalHeatmaps,
  };

  return results;
};

export const getAllFlatMapDeepHeatmaps = (heatmapOverlays: FeatureMetadata[]): FeatureMetadata[] =>
  flatMapDeep(compact(heatmapOverlays), (heatmap) =>
    compact(
      concat(
        heatmap,
        getAllFlatMapDeepHeatmaps(heatmap?.secondaryResults || []),
        getAllFlatMapDeepHeatmaps(heatmap?.nestedItems || [])
      )
    )
  );
