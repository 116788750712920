import { useQueries } from '@tanstack/react-query';
import { getStudyProcedures } from 'api/study';
import { compact, flatMap, isEmpty, some, times } from 'lodash';
import { useEffect } from 'react';
import queryClient from 'utils/queryClient';
import { useStableProcedureStatistics } from '../ProceduresPage/useStableProcedureStatistics';

const PAGE_SIZE = 50;
const MAX_CONCURRENT_QUERIES = 5;

export const useAllStudyProcedures = (studyId: string, enabled: boolean = true) => {
  const { totalCases } = useStableProcedureStatistics();
  const totalPages = Math.ceil(totalCases / PAGE_SIZE);

  const proceduresQueries = useQueries({
    queries: times(totalPages, (index) => {
      return {
        queryKey: ['procedures', studyId, index + 1],
        queryFn: () => getStudyProcedures({ studyId, page: index + 1, pageSize: PAGE_SIZE }),
        enabled: enabled && Boolean(studyId),
        config: {
          concurrency: MAX_CONCURRENT_QUERIES,
        },
      };
    }),
  });

  useEffect(() => {
    return () => {
      queryClient.cancelQueries(['procedures', studyId]);
    };
  }, []);

  const procedures = compact(flatMap(proceduresQueries, (query) => query.data));

  const isLoading = !isEmpty(proceduresQueries) && some(proceduresQueries, 'isLoading');

  return { procedures, isLoading, proceduresQueries };
};
