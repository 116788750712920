import { ExternalLabel } from 'interfaces/externalLabel';
import { DisplayedField } from 'interfaces/genericFields';

export const idField: DisplayedField<ExternalLabel, string> = {
  filterType: 'multiSelect',
  dataKey: 'id',
  label: 'ExternalLabel ID',
  columnWidth: { width: 90 },
};

export const textField: DisplayedField<ExternalLabel, string> = {
  cellEditType: 'text',
  filterType: 'text', // TODO: change to multiSelect (based on search filter perhaps) or text search
  dataKey: 'text',
  label: 'Name',
  columnWidth: { width: 350 },
  valueGetter(row) {
    return row.deletedAt != null ? `${row.text} (Deprecated)` : row.text;
  },
};

export const deletedAt: DisplayedField<ExternalLabel, string> = {
  cellEditType: 'text',
  filterType: 'text',
  dataKey: 'deletedAt',
  label: 'Deleted At',
  columnWidth: { width: 200 },
};

export const externalLabelFields: Array<DisplayedField<ExternalLabel, any>> = [idField, textField, deletedAt];
