import { usePendingSlides } from 'components/StudyDashboard/ProceduresPage/usePendingSlides';
import { useStableProcedureStatistics } from 'components/StudyDashboard/ProceduresPage/useStableProcedureStatistics';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useProceduresWithQAExperimentResultsOnly } from './useProceduresWithQAExperimentResultsOnly';

export const useCurrentStateCases = ({
  enabled = true,
}: {
  enabled?: boolean;
} = {}) => {
  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);

  const {
    data: proceduresData,
    isLoading: proceduresLoading,
    error: casesError,
  } = useProceduresWithQAExperimentResultsOnly({
    enabled: !pendingSlidesMode && enabled,
  });
  const {
    allMockCasesOfPendingSlides,
    mockCasesOfPendingSlides,
    isPendingSlidesLoading,
    totalPendingSlides,
    pendingSlidesError,
    pendingSlidesInPreviousPages,
    pendingCasesInPreviousPages,
  } = usePendingSlides({ enabled: pendingSlidesMode && enabled });
  const {
    totalCases,
    totalSlides: totalSlidesInCases,
    slidesInPreviousPages: slidesInCasesInPrevPages,
    proceduresInPreviousPages: casesInPrevPages,
  } = useStableProcedureStatistics({ enabled });

  const isLoading = pendingSlidesMode ? isPendingSlidesLoading : proceduresLoading;
  const totalCasesCount = pendingSlidesMode ? totalPendingSlides : totalCases;
  const totalSlidesCount = pendingSlidesMode ? totalPendingSlides : totalSlidesInCases;
  const procedures = pendingSlidesMode ? mockCasesOfPendingSlides : proceduresData?.procedures;
  const slidesInPreviousPages = pendingSlidesMode ? pendingSlidesInPreviousPages : slidesInCasesInPrevPages;
  const casesInPreviousPages = pendingSlidesMode ? pendingCasesInPreviousPages : casesInPrevPages;
  const error = pendingSlidesMode ? pendingSlidesError : casesError;

  return {
    isLoading: isLoading && enabled,
    totalCasesCount,
    totalSlidesCount,
    procedures,
    slidesInPreviousPages,
    casesInPreviousPages,
    allMockCasesOfPendingSlides: pendingSlidesMode ? allMockCasesOfPendingSlides : undefined,
    error,
  };
};
