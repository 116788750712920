import { PERMISSIONS } from 'interfaces/user';
import { get } from 'lodash/fp';
import store from 'redux/store';
import apiUtils from 'utils/api';
import { hasPermissionLegacy } from './user';

interface Params<T = any, E = any> {
  url: string;
  onSuccess(body: T): void;
  onFailure(error: E): void;
  data?: string;
  method: string;
  signal?: AbortSignal;
  mediaType?: string;
  responseType?: string;
}

export const apiRequestHandler = <T = any, E = any>(requestHandlerParams: Params<T, E>) => {
  const { url, onSuccess, onFailure, data, method = 'GET', signal, mediaType, responseType } = requestHandlerParams;

  const authMetadata = store.getState().auth;
  const { accessToken } = authMetadata;

  const blockApiCalls = hasPermissionLegacy(PERMISSIONS.BLOCK_API_PERSISTANCE, authMetadata);

  if (blockApiCalls && method !== 'GET') {
    onSuccess(null);
    return;
  }

  apiUtils
    .request({
      method,
      url,
      data,
      accessToken,
      signal,
      mediaType,
      responseType,
    })
    .then(get('body'))
    .then(onSuccess)
    .catch((error: any) => {
      if (error.code === 'ABORTED') {
        return;
      }
      if (error.status === 500 || error.status === undefined) {
        console.error('API error', error);
      } else if (error.status === 401) {
        console.warn('user unauthorized');
      }
      if (onFailure) {
        onFailure(error);
      }
    });
};

// apiRequestHandler wrapped in a Promise
export const apiRequestHandlerPromise = <T = any>(params: Omit<Params, 'onSuccess' | 'onFailure'>) =>
  new Promise<T>((resolve, reject) => {
    apiRequestHandler({
      onSuccess: (data: T) => resolve(data),
      onFailure: (error) => reject(error),
      ...params,
    });
  });

export default apiRequestHandler;
