export enum ThresholdMethod {
  F1 = 'f1',
  BALANCED_ACCURACY = 'balanced_accuracy',
  AUTO = 'auto',
}

export interface MultiplexThresholdConfig {
  cellRules?: string | { [key: string]: { [key: string]: number } };
  annotationMapping?: { [key: string]: string };
  thresholdMethod: ThresholdMethod;
  inferenceResultsArtifactUrl: string;
  annotationToInferenceMatchingDistUm: number;
  thresholdPerMarker: boolean;
  annotationAssignmentIds?: number[];
  saveThresholdedResultsToCsv: boolean;
  quantifiedChannelNames?: string[];
  existingThresholdsPath?: string;
}
