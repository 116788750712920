import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { InferredFeatureConfig } from 'interfaces/inferredFeatures';
import { map, some } from 'lodash';
import React, { FunctionComponent } from 'react';

interface SingleInferredFeatureFilterProps {
  inferredFeatureConfig: InferredFeatureConfig;
  setSelectedValueId: (id: string) => void;
  selectedValueId: string;
}

const SingleInferredFeatureFilter: FunctionComponent<React.PropsWithChildren<SingleInferredFeatureFilterProps>> = ({
  inferredFeatureConfig,
  setSelectedValueId,
  selectedValueId,
}) => {
  const { rules, defaultValue } = inferredFeatureConfig;

  const isSelectedValueIdRelevant =
    defaultValue.id === selectedValueId || some(rules, (rule) => rule.id === selectedValueId);

  return (
    <>
      <Typography variant="body2" sx={{ paddingBottom: 1 }}>
        {inferredFeatureConfig.featureName}
      </Typography>
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={isSelectedValueIdRelevant ? selectedValueId : null}
        onChange={(event, value) => setSelectedValueId(value)}
        size="small"
        sx={{ paddingBottom: 1, width: '100%', display: 'flex' }}
      >
        {map([defaultValue, ...rules], (value) => (
          <ToggleButton key={value.id} value={value.id} style={{ flex: 1 }}>
            {value.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
};

export default SingleInferredFeatureFilter;
