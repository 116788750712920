import CopyIcon from '@mui/icons-material/FileCopy';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useClipboard } from 'use-clipboard-copy';

interface CopyTaxonomyPathButtonProps {
  taxonomyPath: string;
}

const CopyTaxonomyPathButton: React.FC<CopyTaxonomyPathButtonProps> = ({ taxonomyPath }) => {
  const clipboard = useClipboard({ copiedTimeout: 1000 });

  const handleCopy = () => {
    clipboard.copy(taxonomyPath);
  };

  return (
    <Tooltip title={clipboard.copied ? 'Copied!' : 'Copy path'} placement="top">
      <IconButton aria-label="Copy" onClick={handleCopy}>
        <CopyIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CopyTaxonomyPathButton;
