import { DataGridProps, GridRowModes, GridRowModesModel, useGridApiRef } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';

import { getModelTypeOptions } from 'api/modelTypes';
import { SettingsDataGrid } from 'components/atoms/BaseDataGrid/SettingsDataGrid';
import {
  generateGetCellClassNames,
  handleRowModesModelChangeWithoutDraftIds,
} from 'components/atoms/EditableDataGrid/helpers';
import Loader from 'components/Loader';
import { ModelType } from 'interfaces/modelType';
import { modelTypeFields } from 'interfaces/modelType/modelTypeFields';
import { ModelTypeDraft } from './types';
import { useModelTypesColumns } from './useModelTypesColumns';
import { generateDraftId, getModelTypeId } from './utils';

export const ModelTypesDataGrid = () => {
  const { data: dbModelTypes, isLoading: isLoadingModelTypes } = useQuery(['modelTypes'], () => getModelTypeOptions());
  const [draftModelTypes, setDraftModelTypes] = React.useState<ModelTypeDraft[]>([]);
  const modelTypes = React.useMemo(
    () => [...draftModelTypes, ...(dbModelTypes || [])],
    [dbModelTypes, draftModelTypes]
  );
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const handleAddModelType = useCallback(() => {
    // We use the draftId field to distinguish between draft rows and db rows
    // This is necessary because we need to be able to edit ids in new rows but not in existing rows
    const draftId = generateDraftId();
    const newModelType: ModelTypeDraft = {
      displayName: '',
      id: '',
      draftId,
      type: '',
      trainingType: '',
      backgroundClassesToIgnore: [],
    };
    setDraftModelTypes((oldDraftModelTypes) => [newModelType, ...oldDraftModelTypes]);
    setRowModesModel((oldRowModesModel) => ({
      ...oldRowModesModel,
      [newModelType.draftId]: { mode: GridRowModes.Edit },
    }));
  }, [setDraftModelTypes, setRowModesModel]);

  const apiRef = useGridApiRef();

  const handleRowModesModelChange: DataGridProps<ModelType>['onRowModesModelChange'] = React.useCallback(
    (newRowModesModel: GridRowModesModel) => {
      handleRowModesModelChangeWithoutDraftIds(
        newRowModesModel,
        setRowModesModel,
        draftModelTypes,
        (row: ModelTypeDraft) => row.draftId
      );
    },
    [draftModelTypes]
  );

  const columns = useModelTypesColumns({
    noRows: isEmpty(modelTypes),
    apiRef,
    modelTypes,
    draftModelTypes,
    rowModesModel,
    setDraftModelTypes,
    setRowModesModel,
  });

  const getModelTypeCellClassName: DataGridProps['getCellClassName'] = React.useMemo(
    () =>
      generateGetCellClassNames<ModelType | ModelTypeDraft>({
        apiRef,
        requiredFields: ['id', 'displayName', 'sourceType'],
        uniqueFieldGroups: [['id'], ['displayName']],
        draftRows: draftModelTypes,
        fieldsToCheckForErrors: modelTypeFields,
        idGetter: getModelTypeId,
      }),
    [apiRef, draftModelTypes]
  );

  return !isLoadingModelTypes ? (
    <SettingsDataGrid
      apiRef={apiRef}
      addText="Add Model Type"
      handleAdd={handleAddModelType}
      getCellClassName={getModelTypeCellClassName}
      rows={modelTypes}
      columns={columns}
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      getRowId={getModelTypeId}
    />
  ) : (
    <Loader />
  );
};
