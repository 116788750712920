import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { darken, useTheme } from '@mui/system';
import { useSignals } from '@preact/signals-react/runtime';
import { defaultLayerColors } from 'components/theme/theme';
import { sortBy } from 'lodash';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

export const ColorPicker: React.FC<
  React.PropsWithChildren<{
    currentColor: any;
    onColorChange?: (color: string) => void;
    disable?: boolean;
    disableEditing?: boolean; // Still shows the color picker but disables editing
    applyChangeWhenFocusOut?: boolean; // call the onColorChange only when the user clicks save
  }>
> = ({ currentColor, onColorChange, disable = false, disableEditing = false, applyChangeWhenFocusOut = false }) => {
  useSignals();
  const theme = useTheme();
  if (!currentColor) {
    currentColor = theme.palette.primary.main;
  }
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [temporaryColor, setTemporaryColor] = useState(currentColor);

  const colorToShow = applyChangeWhenFocusOut ? temporaryColor : currentColor;

  const openColorPopOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeColorPopOver = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (applyChangeWhenFocusOut && reason === 'backdropClick') {
      onColorChange(temporaryColor);
    } else if (reason !== 'backdropClick') {
      setTemporaryColor(currentColor);
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button
        role="color-picker"
        size="small"
        sx={{
          minWidth: 17,
          height: 17,
          mr: 1,
          display: 'flex',
          ...(!disable ? { backgroundColor: colorToShow?.hex || colorToShow } : {}),
          '&:hover': {
            backgroundColor: darken(colorToShow?.hex || colorToShow, 0.5), // Preserve color on hover
          },
          ...(disableEditing && !disable // If we only disable editing, we want to show the color but not allow editing
            ? {
                '&.Mui-disabled': {
                  backgroundColor: colorToShow?.hex || colorToShow,
                },
              }
            : {}),
        }}
        variant="contained"
        onClick={openColorPopOver}
        disabled={disable || disableEditing}
      />
      <Popover
        id={id}
        open={open && !disable && !disableEditing}
        anchorEl={anchorEl}
        onClose={closeColorPopOver}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <SketchPicker
          color={colorToShow}
          onChange={(color) => {
            if (!onColorChange) console.error('ColorPicker: onColorChange is required when not disabled');
            else if (!applyChangeWhenFocusOut) onColorChange(color?.hex);
            else setTemporaryColor(color?.hex);
          }}
          presetColors={sortBy(defaultLayerColors)}
        />
      </Popover>
    </>
  );
};
