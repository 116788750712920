import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { CircularProgress, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { filter, fromPairs, get, keyBy, map } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';

import { createCustomerResults } from 'api/customerResults';
import { cancelJob, getJob } from 'api/jobs';
import { getInternalUsers } from 'api/userCredentials';
import { JobType } from 'interfaces/job';
import { Permission } from 'interfaces/permissionOption';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { useUiSettings } from 'utils/queryHooks/uiConstantsHooks';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { usePermissions } from 'utils/usePermissions';
import { jobsQueryKey } from './useJobs';

export const useJobColumns = ({
  setCurrentJobModal,
  viewOnly,
}: {
  setCurrentJobModal?: (value: { jobType: JobType; jobData: any }) => void;
  viewOnly?: boolean;
}): {
  jobColumns: GridColDef[];
  isLoading: boolean;
} => {
  const { userId: loggedInUserId } = useAppSelector((state) => state.auth.profile);
  const navigate = useNavigate();

  const { labSearch } = useCurrentLabId();

  const { data: internalUsers, isLoading: isLoadingInternalUsers } = useQuery(['internalUsers'], getInternalUsers);
  const internalUsersById = useMemo(() => keyBy(internalUsers, 'id'), [internalUsers]);

  const { hasPermission, isLoading: isLoadingPermissions } = usePermissions();
  const canContinueJob = hasPermission(Permission.ContinueJob);
  const canCancelAllUsersJobs = hasPermission(Permission.CancelAllUsersJobs);
  const canCancelOwnJobs = hasPermission(Permission.CancelOwnJobs);
  const hasCancelJobPermission = canCancelAllUsersJobs || canCancelOwnJobs;

  const { uiSettings, isLoadingUiSettings } = useUiSettings();

  const jobStatusToDisplayText = fromPairs(
    map(uiSettings?.enumDisplayNames?.['jobStatus'], ({ value, label }) => [value, label]) || []
  );
  const requestSenderToDisplayText = fromPairs(
    map(uiSettings?.enumDisplayNames?.['requestSender'], ({ value, label }) => [value, label]) || []
  );

  const createCustomerResultsMutation = useMutationWithErrorSnackbar({
    mutationFn: createCustomerResults,
    mutationDescription: 'create customer results',
  });

  const jobMutation = useMutationWithErrorSnackbar({
    mutationFn: getJob,
    mutationDescription: 'get job',
  });

  const queryClient = useQueryClient();

  const cancelJobMutation = useMutationWithErrorSnackbar({
    onSuccess: () => {
      queryClient.invalidateQueries([jobsQueryKey]);
    },
    mutationFn: cancelJob,
    mutationDescription: 'cancel job',
  });

  const jobColumns: GridColDef[] = [
    {
      field: 'startedAt',
      headerName: 'Started At',
      width: 160,
      renderCell: (params) => <Typography variant="body2">{moment(params.value).format('lll')}</Typography>,
    },
    { field: 'name', headerName: 'Name', width: 230 },
    { field: 'description', headerName: 'Description', width: 250 },
    {
      field: 'userId',
      headerName: 'User',
      width: 150,
      renderCell: (params) => (
        <Typography variant="body2">
          {internalUsersById[params.value]?.name ?? internalUsersById[params.value]?.primaryEmail ?? params.value}
        </Typography>
      ),
    },
    { field: 'orchestrationId', headerName: 'Orchestration Id', width: 270 },
    {
      field: 'requestSender',
      headerName: 'Source',
      width: 100,
      renderCell: (params) => <Typography variant="body2">{requestSenderToDisplayText[params.value]}</Typography>,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => <Typography variant="body2">{jobStatusToDisplayText[params.value]}</Typography>,
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      editable: false,
      width: 150,
      cellClassName: 'actions',
      getActions: (params) => {
        const type = params.row.type;
        const externalTaskLink = params.row.externalTaskLink;
        const status = params.row.status;
        const jobId = params.row.id;
        const userId = params.row.userId;

        const action = [
          <Tooltip placement="top-start" title="Go to clearML" key={`externalTaskLink-${params.id}`}>
            <Link
              color="inherit"
              underline="hover"
              href={externalTaskLink}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <OpenInNewIcon />
            </Link>
          </Tooltip>,
        ];

        if (type === 'calculate_features') {
          action.push(
            <Tooltip placement="top-start" title="Rebuild" key={`rebuild-${params.id}`}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    { pathname: `/calculate-features`, search: `${labSearch}` },
                    { state: { jobId: params.row.id } }
                  );
                }}
              >
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
          );
        }

        if (type === JobType.Inference || type === JobType.MultiplexNormalization) {
          action.push(
            <Tooltip placement="top-start" title="Rebuild" key={`rebuild-${params.id}`}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentJobModal({ jobType: type, jobData: params.row });
                }}
              >
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
          );
        }

        if (canContinueJob && type === 'prepare_export_results' && !isLoadingPermissions) {
          action.push(
            <Tooltip placement="top-start" title="Continue" key={`continue-${params.id}`}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  jobMutation.mutate(params.row.id.toString(), {
                    onSuccess: (job) => {
                      createCustomerResultsMutation.mutate({
                        studyId: job.studyId,
                        resultTypesToExport: get(job.params, 'resultTypesToExport'),
                        jobId: params.row.id,
                      });
                    },
                  });
                }}
              >
                <ArrowRightIcon />
              </IconButton>
            </Tooltip>
          );
        }

        if (hasCancelJobPermission) {
          const canCancelJob = canCancelAllUsersJobs || (canCancelOwnJobs && userId === loggedInUserId);
          action.push(
            <Tooltip placement="top-start" title="Cancel job" key={`cancel-${params.id}`}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  cancelJobMutation.mutate(jobId);
                }}
                disabled={!(status === 'running') || !canCancelJob || !externalTaskLink || cancelJobMutation.isLoading}
              >
                {cancelJobMutation.isLoading ? <CircularProgress size={20} /> : <CloseIcon />}
              </IconButton>
            </Tooltip>
          );
        }

        return action;
      },
    },
  ];

  return {
    jobColumns: viewOnly ? filter(jobColumns, (column) => column.field !== 'actions') : jobColumns,
    isLoading: isLoadingInternalUsers || isLoadingPermissions || isLoadingUiSettings,
  };
};
