import { Viewport } from '@deck.gl/core/typed';
import { DeckGLProps } from '@deck.gl/react/typed';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Divider, ListItemIcon, ListItemText, MenuItem, Paper, Popper } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import centroid from '@turf/centroid';
import { Feature } from '@turf/helpers';
import { find, findIndex } from 'lodash';
import React from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';

import { FeatureSizeSection } from 'components/FeatureSizeSection';
import HtmlDeckGLOverlay from 'components/HtmlDeckGLOverlay';
import HtmlDeckGLOverlayItem from 'components/HtmlDeckGLOverlay/HtmlDeckGLOverlayItem';
import { getAnnotationTodoNameAndDefinition } from 'components/Procedure/Infobar/SlideInfobar/SlideAnnotation/helpers';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { ImagePyramid } from 'components/Procedure/useSlideImages';
import { Permission } from 'interfaces/permissionOption';
import { getActiveAnnotationAssignmentIdViewerKey } from 'services/annotations/useAnnotationQueryParams';
import { usePermissions } from 'utils/usePermissions';
import { useActiveAnnotationDraft } from '../useActiveAnnotationDraft';
import { AnnotationOptionsMenu } from './AnnotationOptionsMenu';
import { StickyMenuItems } from './StickyMenuItems';

const DEFAULT_COORDINATES = [0, 0] as [number, number];

export interface TaggedItem extends DiagnosisItem {
  isPoint?: boolean;
}

export interface DiagnosisItem {
  text: string;
  diagnosis: string;
  color: string;
  positive: boolean;
  selected: boolean;
  icon?: React.ReactNode;
}

export interface StickyItem {
  text: string;
  icon: React.ReactNode;
  menuAction: () => void;
  color?: any; // TODO: PropTypes.Color;
}

export const AnnotationsContextMenu: React.FC<{
  slide: SlideWithChannelAndResults;
  baseImagePyramids: ImagePyramid;
  viewport: Viewport;
  rightClickInfo?: Parameters<DeckGLProps['onClick']>[0];
  locked?: boolean;
  isSelectFeaturesOn?: boolean;
  // TODO: is this necessary?
  isOnlyNonCanvasEditingEnabled?: boolean;
}> = ({
  slide,
  viewport,
  baseImagePyramids,
  rightClickInfo,
  locked,
  isSelectFeaturesOn,
  isOnlyNonCanvasEditingEnabled,
}) => {
  useSignals();
  const { activeAnnotationData, slideAnnotations } = useActiveAnnotationDraft({
    slideId: slide?.id,
    viewerIndex: slide?.viewerIndex,
  });
  const annotationFeatures = activeAnnotationData?.features;

  const unassignedFeatureIndex = findIndex(annotationFeatures, (feature) => !feature?.properties?.diagnosis);

  const unassignedFeature = unassignedFeatureIndex >= 0 ? annotationFeatures[unassignedFeatureIndex] : undefined;

  const rightClickFeatureIndex = rightClickInfo?.index;

  const rightClickFeature = !isNaN(rightClickFeatureIndex) ? annotationFeatures[rightClickFeatureIndex] : undefined;

  const contextMenuFeatureIndex = unassignedFeature ? unassignedFeatureIndex : rightClickFeatureIndex;

  const featureForContextMenu = (unassignedFeature || rightClickFeature) as Feature;

  const featureCoordinates = featureForContextMenu
    ? (centroid(featureForContextMenu)?.geometry?.coordinates as [number, number])
    : undefined;

  const { hasPermission } = usePermissions();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);

  const featureAnnotationId = featureForContextMenu?.properties?.annotationId;
  const [activeAnnotationAssignmentId] = useQueryParam(
    getActiveAnnotationAssignmentIdViewerKey(slide.viewerIndex),
    NumberParam
  );

  const savedAnnotationData = canAnnotateSlides
    ? !isNaN(featureAnnotationId)
      ? find(slideAnnotations, { annotationId: featureAnnotationId })
      : find(slideAnnotations, {
          annotationAssignment: {
            annotationAssignmentId: activeAnnotationAssignmentId,
          },
        })
    : undefined;

  const { name, todo } = getAnnotationTodoNameAndDefinition(savedAnnotationData);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement>(null);

  return (
    canAnnotateSlides && (
      <HtmlDeckGLOverlay viewport={viewport}>
        <HtmlDeckGLOverlayItem
          key="contextMenu"
          draggable={false}
          coordinates={featureCoordinates || DEFAULT_COORDINATES}
        >
          <div ref={(el) => setAnchorEl(el)} />

          <Popper id="annotationContextMenu" open={Boolean(featureCoordinates)} anchorEl={anchorEl}>
            <Paper>
              <AnnotationOptionsMenu
                annotationId={savedAnnotationData?.annotationId}
                annotationAssignmentId={savedAnnotationData?.annotationAssignment?.annotationAssignmentId}
                annotationData={activeAnnotationData}
                todoName={name}
                viewerIndex={slide?.viewerIndex}
                featureIndex={contextMenuFeatureIndex}
                todoOptions={todo?.options}
              />

              <Divider variant="middle" />

              <StickyMenuItems
                viewerIndex={slide?.viewerIndex}
                annotationDraft={activeAnnotationData}
                featureIndex={contextMenuFeatureIndex}
                isSelectFeaturesOn={isSelectFeaturesOn}
                isOnlyNonCanvasEditingEnabled={isOnlyNonCanvasEditingEnabled}
              />

              <Divider variant="middle" />

              {/* TODO: implement lock */}
              <MenuItem onClick={console.debug}>
                <ListItemIcon>
                  {locked ? <LockOpenIcon fontSize="small" /> : <LockIcon fontSize="small" />}
                </ListItemIcon>
                <ListItemText>{locked ? 'Unlock' : 'Lock'}</ListItemText>
              </MenuItem>

              <FeatureSizeSection
                feature={featureForContextMenu}
                maxResolution={slide?.maxResolution}
                maxLevel={baseImagePyramids?.layerSource?.maxLevel ?? 0}
              />
            </Paper>
          </Popper>
        </HtmlDeckGLOverlayItem>
      </HtmlDeckGLOverlay>
    )
  );
};
