export interface ResultColorMap {
  color_map: string;
  min_value: number;
  max_value: number;
}

export interface NormalizationResult {
  metadata: {};
  channel_normalization_results: Array<{
    params: {
      max_c: number;
      min_c: number;
    };
    channel_index: number;
    derivation_method: string;
    normalization_type: string;
  }>;
}

export interface PresentationInfoLayer {
  file_type?: 'pmt' | 'geojson' | 'parquet';
  class_name: string;
  storage_url: string;
  artifact_url: string;
  key?: string;
}

export interface OptionsEntry {
  key: string;
  color?: string | ResultColorMap;
  column_name?: string;
  layers?: OptionsEntry[];
  with_composite?: boolean;
  type:
    | 'point' // i.e. X, Y coordinates
    | 'boolean' // i.e. marker positivity
    | 'categorical' // i.e. label
    | 'categorical_array'; // i.e. polygons that include this point
}

export interface ExperimentResult {
  approved: boolean | null;
  internallyApproved: boolean | null;
  experimentResultId: number;
  flowClassName: FlowClassName;
  name?: string;
  options?: OptionsEntry[];
  resultType?: ExperimentResultsResultType;
  description?: string;
  orchestrationId: string;
  primaryRunOrchestrationId?: string;
  presentationInfo: { [key: string]: any };
  numFeatures?: number;
  features?: Features;
  featureValues?: any;
  studyId: string;
  createdAt: string;
  createdBy?: string;
  deletedAt?: string;
  deletedBy?: string;
  qaFailed: boolean;
}

export enum FlowClassName {
  CalculateFeatures = 'CalculateFeatures',
  ConcurrentInferenceFlow = 'ConcurrentInferenceFlow',
  DeconvSlideProcessor = 'DeconvSlideProcessor',
  CreateMultiplexHistogramFlow = 'CreateMultiplexHistogramFlow',
  CellTypingFlow = 'CellTypingFlow',
  NuclearSegmentationFlow = 'NuclearSegmentationFlow',
  MultiplexCellSegmentationProcessor = 'MultiplexCellSegmentationProcessor',
  NormalizeMultiplexSlideFlow = 'NormalizeMultiplexSlideFlow',
  External = 'External',
}

export const bulkPublishableFlowClasses: FlowClassName[] = [FlowClassName.CalculateFeatures, FlowClassName.External];

export const publishableFlowClasses: FlowClassName[] = [
  FlowClassName.CalculateFeatures,
  FlowClassName.NormalizeMultiplexSlideFlow,
  FlowClassName.NuclearSegmentationFlow,
  FlowClassName.DeconvSlideProcessor,
  FlowClassName.CreateMultiplexHistogramFlow,
  FlowClassName.External,
  FlowClassName.CellTypingFlow,
];

export enum ExperimentResultsResultType {
  Normalization = 'normalization',
}

export const publishableResultTypes: string[] = [ExperimentResultsResultType.Normalization];

export interface ExperimentResultUpdate {
  approved?: boolean;
  internallyApproved?: boolean;
}

export interface SlideResultsSummaryData {
  slideId: string;
  caseId: number;
  caseName: string;
  flowClassName: FlowClassName;
  internallyApprovedOrchestrationId?: string;
  isPublished: boolean;
}

export interface Features {
  [key: string]: number | string | Array<number | null | undefined>;
}

export interface Feature {
  key: string;
  value: number | string;
}

export interface HeatmapItem {
  key: string;
  id: string;
  value: number | string;
  nestedItems?: HeatmapItem[];
}

export enum ResultsMode {
  Manual = 'manual',
  InternallyApproved = 'internally_approved',
  Published = 'published',
  All = 'all',
}

export interface ResultsExistence {
  // flowClassName
  [key: string]: {
    // slideId
    [key: string]: boolean;
  };
}

export enum ArtifactType {
  GenericPipsTableParquet = 'generic_table.pips_cells_table.parquet',
}
