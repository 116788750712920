import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CircularValueProgress from 'components/atoms/Progress/CircularValueProgress';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

export const ProgressIndicator: React.FunctionComponent<
  React.PropsWithChildren<{ isAborted: boolean; isError: boolean; progress: number }>
> = ({ isAborted, isError, progress }) => {
  if (isError) {
    return (
      <Tooltip placement="top" title="Upload Failed">
        <ErrorIcon color="error" />
      </Tooltip>
    );
  } else if (isAborted) {
    return (
      <Tooltip placement="top" title="Upload Cancelled">
        <RemoveCircleOutlineIcon />
      </Tooltip>
    );
  }

  if (!progress || progress < 100) {
    return <CircularValueProgress value={progress} progresssize={35} loadersize={20} />;
  } else {
    return <CheckCircleIcon color="success" />;
  }
};

export default ProgressIndicator;
