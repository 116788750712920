import CloseIcon from '@mui/icons-material/Close';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { TodoOption } from 'interfaces/annotation';
import { filter, includes, map } from 'lodash';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import CreateAnnotationAssignmentSection from './CreateAnnotationAssignmentSection';

export interface AssignmentFormValues {
  assignmentName: string;
  classesToAnnotate: TodoOption[];
}

interface DialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  buttonText: string;
  isLoadingButtonText: string;
  initialValues?: AssignmentFormValues;
  handleApply: SubmitHandler<AssignmentFormValues>;
  isLoading?: boolean;
}

const AnnotationAssignmentDialog: React.FC<DialogProps> = ({
  open,
  onClose,
  title,
  buttonText,
  isLoadingButtonText,
  initialValues,
  handleApply,
  isLoading,
}) => {
  const assignmentCreationForm = useForm<AssignmentFormValues>({
    mode: 'onChange',
    resolver: yupResolver(assignmentCreationSchema),
    defaultValues: initialValues || {
      assignmentName: '',
      classesToAnnotate: [],
    },
  });

  const handleDeleteClassToAnnotate = (id: string) => {
    const previousClassesToAnnotate = assignmentCreationForm.watch('classesToAnnotate');
    assignmentCreationForm.setValue(
      'classesToAnnotate',
      filter(previousClassesToAnnotate, (previousClassToAnnotate) => previousClassToAnnotate.name !== id)
    );
  };

  const handleColorChange = (id: string, color: any) => {
    const previousClassesToAnnotate = assignmentCreationForm.watch('classesToAnnotate');
    assignmentCreationForm.setValue(
      'classesToAnnotate',
      map(previousClassesToAnnotate, (previousClassToAnnotate) => {
        if (previousClassToAnnotate.name === id) {
          return {
            ...previousClassToAnnotate,
            color,
          };
        }
        return previousClassToAnnotate;
      })
    );
  };

  const handleClassesToAnnotateChange = (newClassesToAnnotate: TodoOption[]) => {
    const prevClassesToAnnotate = assignmentCreationForm.watch('classesToAnnotate');

    const prevIds = map(prevClassesToAnnotate, 'name');
    // Add newly selected items at the end
    const added = filter(newClassesToAnnotate, (newClassToAnnotate) => !includes(prevIds, newClassToAnnotate.name));

    // Keep only the ids that are still selected, in their original order
    const retained = filter(prevClassesToAnnotate, (prevClassToAnnotate) =>
      includes(map(newClassesToAnnotate, 'name'), prevClassToAnnotate.name)
    );

    const newClassesToAnnotateWithPreviousOrder = [...retained, ...added];

    assignmentCreationForm.setValue('classesToAnnotate', newClassesToAnnotateWithPreviousOrder);
    // validate to update errors
    assignmentCreationForm.trigger('classesToAnnotate');
  };

  const handleClose = (event: React.MouseEvent, reason: string) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialogContent-root': {
          padding: 0,
        },
      }}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        {title}
        <IconButton aria-label="close" onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <CreateAnnotationAssignmentSection
          assignmentCreationForm={assignmentCreationForm}
          onDeleteClassToAnnotate={handleDeleteClassToAnnotate}
          onColorChange={handleColorChange}
          onClassesToAnnotateChange={handleClassesToAnnotateChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={assignmentCreationForm.handleSubmit(handleApply)}
          color="primary"
          variant="contained"
          disabled={isLoading}
        >
          {isLoading ? isLoadingButtonText : buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const assignmentCreationSchema = yup.object({
  assignmentName: yup.string().required('Assignment name is required'),
  classesToAnnotate: yup.array().required().min(1, 'At least one class is required'),
});

export default AnnotationAssignmentDialog;
