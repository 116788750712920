import { CancerTypeMap } from 'interfaces/cancerType';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getCancerTypes = (labId: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<CancerTypeMap>({
    url: `cancer-types?${stringify({ labId })}`,
    method: 'GET',
    signal,
  });
};

export const getAllCancerTypes = ({ signal }: { signal?: AbortSignal }) => {
  return apiRequestHandlerPromise<CancerTypeMap>({
    url: `all-cancer-types`,
    method: 'GET',
    signal,
  });
};
