import { find } from 'lodash';
import React from 'react';

import {
  BulkChangesSummary,
  RowsChangesSummary,
} from 'components/atoms/EditableDataGrid/TableEditingContext/ChangesSummary';
import { RowsChangesState } from 'components/atoms/EditableDataGrid/TableEditingContext/types';
import { formatFieldValue } from 'interfaces/genericFields/formatFieldValues';
import { getOrderedFields } from 'interfaces/procedure/fields';
import { ProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';

export const ProcedureRowChangesSummary: React.FC<
  React.PropsWithChildren<{
    rowChanges: RowsChangesState;
    fieldsContext: ProceduresFieldsContext;
    caseId?: number;
  }>
> = ({ caseId, rowChanges, fieldsContext }) => {
  return (
    <RowsChangesSummary
      rowsChanges={
        isNaN(Number(caseId))
          ? rowChanges
          : {
              [caseId]: rowChanges[caseId],
            }
      }
      rowToDisplayString={(id) => {
        return `Case ${id}`;
      }}
      fieldToDisplayString={(fieldId) => find(getOrderedFields(), (f) => f.dataKey === fieldId)?.label || fieldId}
      valueToDisplayString={(value, fieldId) => {
        const field = find(getOrderedFields(), (f) => f.dataKey === fieldId);
        if (!field) {
          console.warn(`Could not find field ${fieldId}`);
          return value;
        }
        return formatFieldValue(value, field, fieldsContext);
      }}
    />
  );
};

export const SlideRowChangesSummary: React.FC<
  React.PropsWithChildren<{
    fieldsContext: ProceduresFieldsContext;
    caseId: number;
    slideId: string;
    slideChanges: object;
  }>
> = ({ fieldsContext, caseId, slideId, slideChanges }) => {
  return (
    <RowsChangesSummary
      rowsChanges={{ [caseId]: slideChanges }}
      rowToDisplayString={() => {
        return `Case ${caseId}, slide ${slideId}`;
      }}
      fieldToDisplayString={(fieldId) => find(getOrderedFields(), (f) => f.dataKey === fieldId)?.label || fieldId}
      valueToDisplayString={(value, fieldId) => {
        const field = find(getOrderedFields(), (f) => f.dataKey === fieldId);
        if (!field) {
          console.warn(`Could not find field ${fieldId}`);
          return value;
        }
        return formatFieldValue(value, field, fieldsContext, 'slides');
      }}
    />
  );
};

export const ProcedureSlideBulkChangesSummary: React.FC<
  React.PropsWithChildren<{
    bulkChanges: object;
    fieldsContext: ProceduresFieldsContext;
  }>
> = ({ bulkChanges, fieldsContext }) => {
  return (
    <BulkChangesSummary
      bulkChanges={bulkChanges}
      fieldToDisplayString={(fieldId) => find(getOrderedFields(), (f) => f.dataKey === fieldId)?.label || fieldId}
      valueToDisplayString={(value, fieldId) => {
        const field = find(getOrderedFields(), (f) => f.dataKey === fieldId);
        if (!field) {
          console.warn(`Could not find field ${fieldId}`);
          return value;
        }
        return formatFieldValue(value, field, fieldsContext, 'slides');
      }}
    />
  );
};
