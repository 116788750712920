import { Add, ArrowDropDown, PushPin } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Toolbar,
  Typography,
} from '@mui/material';
import { find, keys, map, uniq, values } from 'lodash';
import React, { useState } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';

import { AnnotationExtensionStates } from 'interfaces/annotation';
import { ASSIGNMENT_STATES } from './state';

interface Props {
  state: string;
  onSelectState: (state: string) => void;
  togglePinned?: () => void;
  pinned: boolean;
  isLoading: boolean;
}

const workingStates = uniq([AnnotationExtensionStates.AllAssignment, ...keys(ASSIGNMENT_STATES)]);

const AssignmentsListHeader: React.FunctionComponent<Props> = ({
  state,
  onSelectState,
  togglePinned,
  pinned,
  isLoading,
}) => {
  const [anchorEl, setAnchorEl] = useState<Element>(null);

  if (!state) {
    state = AnnotationExtensionStates.AllAssignment;
  }

  const workingState =
    find(values(ASSIGNMENT_STATES), { route: state }) ?? ASSIGNMENT_STATES[AnnotationExtensionStates.AllAssignment];
  const toggleOpen: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget));
  };

  const [activeAnnotation, setActiveAnnotation] = useQueryParam('activeAnnotationAssignmentIdViewer0', NumberParam);

  const open = Boolean(anchorEl);

  return (
    <Toolbar variant="dense" disableGutters>
      <Grid item container direction="row" justifyContent="space-between">
        <Grid item>
          <Button
            id="assignments-list-state-button"
            aria-controls={open ? 'assignments-list-state' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={toggleOpen}
          >
            <Typography variant="h3">{workingState.label}</Typography>
            <ArrowDropDown />
          </Button>
          <Menu
            id="assignments-list-state"
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'assignments-list-state-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuList>
              {map(workingStates, (stateName: AnnotationExtensionStates) => {
                const stateObj = ASSIGNMENT_STATES[stateName];
                return (
                  <MenuItem
                    key={stateName}
                    onClick={() => {
                      onSelectState(stateName);
                      setAnchorEl(null);
                    }}
                  >
                    <ListItemText>{stateObj.label}</ListItemText>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        </Grid>
        <Grid item container direction="row" xs="auto" alignItems="center">
          {isLoading && (
            <Grid item pr={2}>
              {<CircularProgress size={20} />}
            </Grid>
          )}
          {Boolean(togglePinned) && (
            <Grid item>
              <IconButton onClick={togglePinned} title="Pin Assignments" color={pinned ? 'primary' : 'default'}>
                <PushPin />
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <IconButton
              disabled={true}
              // TODO: Implement this in a future story
              title="Create Assignment (Not Implemented Yet)"
            >
              <Add />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default AssignmentsListHeader;
