import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Permission } from 'interfaces/permissionOption';
import { isEmpty, map, omit, orderBy } from 'lodash';
import React, { useEffect } from 'react';
import { loadLabs } from 'redux/actions/labs';
import { resetCheat } from 'redux/modules/cheatModeSlice';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

const LabDropdown: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const labsRaw = useAppSelector((state) => state?.labs?.all);

  const { hasPermission } = usePermissions();
  const canViewMultipleLabs = hasPermission(Permission.ApplyPermissionsAcrossLabs);

  const { labId: currentLabId, setLabId } = useCurrentLabId();
  const { setQueryParams, queryParams } = useEncodedFilters();

  const didLoadLabs = !isEmpty(labsRaw);
  const shouldLoadLabs = canViewMultipleLabs && !didLoadLabs;

  useEffect(() => {
    if (shouldLoadLabs) {
      dispatch(loadLabs());
    }
  }, [shouldLoadLabs]);

  const labs = map(labsRaw, rawLabToLab);

  const handleLabClick = (event: SelectChangeEvent) => {
    const labId = event.target.value;
    if (labId !== currentLabId) {
      setLabId(labId, 'replaceIn');
      // Remove studyId filter when switching labs
      setQueryParams({
        page: 1,
        filters:
          queryParams.filters?.studyId !== undefined
            ? isEmpty(omit(queryParams.filters, 'studyId'))
              ? undefined
              : omit(queryParams.filters, 'studyId')
            : queryParams.filters,
      });
      dispatch(resetCheat());
    }
  };

  return !isEmpty(labs) && canViewMultipleLabs ? (
    <Select
      defaultValue={currentLabId && ''}
      onChange={handleLabClick}
      value={currentLabId}
      variant="standard"
      label="Lab"
      disableUnderline
    >
      {map(orderBy(labs, 'text'), (lab) => (
        <MenuItem key={lab.value} value={lab.value}>
          {lab.text}
        </MenuItem>
      ))}
    </Select>
  ) : null;
};

interface Lab {
  key: string;
  text: string;
  value: string;
  content: string;
}

const rawLabToLab = (rawLab: { name: string; id: string }): Lab =>
  ({
    text: rawLab.name,
    value: rawLab.id,
  } as Lab);

export default LabDropdown;
