import { DataGridProps, GridRowModes, GridRowModesModel, useGridApiRef } from '@mui/x-data-grid';
import { SettingsDataGrid } from 'components/atoms/BaseDataGrid/SettingsDataGrid';
import {
  generateGetCellClassNames,
  handleRowModesModelChangeWithoutDraftIds,
} from 'components/atoms/EditableDataGrid/helpers';
import Loader from 'components/Loader';
import { TaxonomyTag } from 'interfaces/taxonomyTag';
import { isEmpty } from 'lodash';
import React from 'react';
import useTaxonomyTags from '../../../../utils/queryHooks/taxonomy/useTaxonomyTags';
import { taxonomyTagFields } from './taxonomyTagFields';
import { TaxonomyTagDraft } from './types';
import { useTaxonomyTagColumns } from './useTaxonomyTagColumns';
import { generateDraftId, getTaxonomyTagId } from './utils';

export const TaxonomyTagsDataGrid: React.FC = () => {
  const { data: dbTaxonomyTags, isLoading: isLoadingTaxonomyTags } = useTaxonomyTags();

  const [draftTaxonomyTags, setDraftTaxonomyTags] = React.useState<TaxonomyTagDraft[]>([]);
  const taxonomyTags = React.useMemo(
    () => [...draftTaxonomyTags, ...(dbTaxonomyTags || [])],
    [dbTaxonomyTags, draftTaxonomyTags]
  );
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const handleAddTaxonomyTag = React.useCallback(() => {
    // We use the draftId field to distinguish between draft rows and db rows
    // This is necessary because we need to be able to edit ids in new rows but not in existing rows
    const draftId = generateDraftId();
    const newTaxonomyTag: TaxonomyTagDraft = { displayName: '', id: '', draftId, labels: [] };
    setDraftTaxonomyTags((oldDraftTaxonomyTags) => [newTaxonomyTag, ...oldDraftTaxonomyTags]);
    setRowModesModel((oldRowModesModel) => ({
      ...oldRowModesModel,
      [newTaxonomyTag.draftId]: { mode: GridRowModes.Edit },
    }));
  }, [setDraftTaxonomyTags, setRowModesModel]);

  const apiRef = useGridApiRef();

  const handleRowModesModelChange: DataGridProps<TaxonomyTag>['onRowModesModelChange'] = React.useCallback(
    (newRowModesModel: GridRowModesModel) => {
      handleRowModesModelChangeWithoutDraftIds(
        newRowModesModel,
        setRowModesModel,
        draftTaxonomyTags,
        (row: TaxonomyTagDraft) => row.draftId
      );
    },
    [draftTaxonomyTags]
  );

  const columns = useTaxonomyTagColumns({
    noRows: isEmpty(taxonomyTags),
    apiRef,
    taxonomyTags,
    draftTaxonomyTags,
    rowModesModel,
    setDraftTaxonomyTags,
    setRowModesModel,
  });

  const getTaxonomyTagCellClassName: DataGridProps['getCellClassName'] = React.useMemo(
    () =>
      generateGetCellClassNames<TaxonomyTag | TaxonomyTagDraft>({
        apiRef,
        requiredFields: ['id', 'displayName', 'sourceType'],
        uniqueFieldGroups: [['id'], ['displayName']],
        draftRows: draftTaxonomyTags,
        fieldsToCheckForErrors: taxonomyTagFields,
        idGetter: getTaxonomyTagId,
      }),
    [apiRef, draftTaxonomyTags]
  );

  return !isLoadingTaxonomyTags ? (
    <SettingsDataGrid
      apiRef={apiRef}
      addText="Add Taxonomy Tag"
      handleAdd={handleAddTaxonomyTag}
      getCellClassName={getTaxonomyTagCellClassName}
      rows={taxonomyTags}
      columns={columns}
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      getRowId={getTaxonomyTagId}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'id',
              sort: 'asc',
            },
          ],
        },
      }}
    />
  ) : (
    <Loader />
  );
};
