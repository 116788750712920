import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Box, Grid, IconButton, Paper, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { VirtualizedAutocomplete } from 'components/FeaturesDashboard/KeySelect/VirtualizedAutocomplete';
import { useTransformedDataTransfer } from 'components/SearchFilters/hooks/useTransformedDataTransfer';
import { compact, filter, first, isEmpty, isString } from 'lodash';
import React, { FunctionComponent } from 'react';
import { CaseSearchItem } from 'services/searchIndex';
import { useDebouncedCallback } from 'use-debounce';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import FullSearchHelperText from './FullSearchHelperText';
import SearchResult from './SearchResult';
import SearchTips from './SearchTips';

interface FullSearchAutocompleteProps {
  currentStudyId?: string;
  value: string;
  options: CaseSearchItem[];
  setValue: (value: string) => void;
  onSearch: (search: string) => void; // on debounced typing
  onApplySearch: (event: React.SyntheticEvent<Element, Event>, option: CaseSearchItem | string) => void;
  onInputChange?: (event: React.SyntheticEvent<Element, Event>, value: string | CaseSearchItem) => void;
  isLoading: boolean;
  isError?: boolean;
  disabled?: boolean;
  isDrawer?: boolean;
  noResults?: boolean;
}

const FullSearchAutocomplete: FunctionComponent<React.PropsWithChildren<FullSearchAutocompleteProps>> = ({
  currentStudyId,
  value,
  options,
  setValue,
  onSearch,
  onApplySearch,
  onInputChange,
  disabled,
  isLoading,
  isError,
  isDrawer = false,
  noResults,
}) => {
  const theme = useTheme();
  const [searchQuery] = useQueryParam('searchTerm', StringParam);

  const [open, setOpen] = React.useState(false);

  const handleDebouncedSearch = useDebouncedCallback((searchTerm) => {
    onSearch(searchTerm);
  }, 300);

  const handleInputChange = (newInputValue: string) => {
    setValue(newInputValue);
    handleDebouncedSearch(newInputValue);
    if (isDrawer) {
      onInputChange(null, newInputValue);
    }
  };

  const isStudyIdSpecific = Boolean(currentStudyId);

  const groupByHandler = (option: CaseSearchItem) => {
    if (option.isSlideOnly) {
      return 'External/Pending slides';
    }
    if (!isStudyIdSpecific) {
      return 'From all studies';
    }
    if (option.studyId === currentStudyId) {
      return 'Current study';
    }
    return 'Other studies';
  };

  const [slidesMode] = useQueryParam('slidesMode', BooleanParam);

  const getOptionLabel = (option: CaseSearchItem | string) => {
    if (isString(option)) {
      return option;
    }
    if (slidesMode) {
      return first(option.slides)?.id;
    }
    const caseLabel = option?.caseLabel ?? '(Unlabeled Case)';

    return caseLabel;
  };

  const { handleOnDrop, handleOnPaste } = useTransformedDataTransfer(value, handleInputChange);

  const numberOfTerms = compact(value.split(',')).length;
  const resultsStatsText = `${
    filter(options, (option) => option.studyId === currentStudyId).length
  } / ${numberOfTerms} found in current study`;
  return (
    <Grid container>
      <Grid item xs>
        <VirtualizedAutocomplete<CaseSearchItem, false, boolean>
          data-cy="full-search"
          options={options}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onApplySearch(event, value);
              setOpen(false);
            }
          }}
          PaperComponent={({ children }) => (
            <Paper sx={{ width: '40em' }}>
              {!isEmpty(value) && (
                <Box sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                  <Typography variant="caption">{resultsStatsText}</Typography>
                </Box>
              )}
              {children}
            </Paper>
          )}
          onInputChange={(event, newValue) => handleInputChange(newValue)}
          onChange={onApplySearch}
          onSubmit={(e) => onApplySearch(e, value)}
          getOptionDisabled={(option) => option.caseLabel === 'No results'}
          autoComplete
          freeSolo
          selectOnFocus={false}
          filterOptions={(x) => x} // see https://mui.com/material-ui/react-autocomplete/#search-as-you-type
          groupBy={groupByHandler}
          onPaste={handleOnPaste}
          onDrop={handleOnDrop}
          forcePopupIcon={false}
          noOptionsText={
            isError ? 'Error accrued' : isLoading ? 'Loading...' : noResults ? 'No cases found' : <SearchTips />
          }
          blurOnSelect
          inputValue={value}
          value={value}
          getOptionLabel={(option) => getOptionLabel(option)}
          defaultValue={''}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={'Search'}
              size="small"
              helperText={
                <FullSearchHelperText
                  isStudyIdSpecific={isStudyIdSpecific}
                  localSearch={value}
                  options={options}
                  currentStudyId={currentStudyId}
                  isDrawer={isDrawer}
                />
              }
              FormHelperTextProps={{ sx: !isDrawer && { height: 20, overflow: 'visible' } }}
              InputProps={{
                ...params.InputProps,
                endAdornment:
                  value === searchQuery || (isEmpty(value) && isEmpty(searchQuery)) ? (
                    params.InputProps.endAdornment
                  ) : (
                    <React.Fragment>
                      {!isDrawer && (
                        <Tooltip title="Apply search">
                          <IconButton
                            onClick={(event) => {
                              setOpen(false);
                              onApplySearch(event, value);
                            }}
                            size="small"
                            sx={{ height: '25px' }}
                            data-cy="apply-full-search-button"
                          >
                            <ArrowCircleRightIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
              }}
            />
          )}
          itemHeight={70}
          renderOption={(option) => ({
            key: String(slidesMode ? first(option.slides)?.id : option.caseId),
            displayName: '',
            customNode: (
              <SearchResult
                searchQuery={value}
                option={option}
                notInCurrentStudy={Boolean(currentStudyId) && option.studyId !== currentStudyId}
              />
            ),
          })}
          size="small"
          sx={{
            mb: 1.5,
            '& .MuiListSubheader-root': {
              backgroundColor: theme.palette.mode == 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default FullSearchAutocomplete;
