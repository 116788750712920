import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TreeView } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Popover,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getVisualizationPresets, getVisualizationsForCalculateFeatures } from 'api/visualization';
import { ClassificationModel } from 'interfaces/classificationModel';
import { VisualizationCreated, VisualizationPreset } from 'interfaces/visualization';
import { compact, filter, findIndex, includes, isEmpty, keyBy, map } from 'lodash';
import React, { useState } from 'react';
import { uuidv4 } from 'utils/helpers';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import { NewVisualization } from './NewVisualization';
import { ClassOption } from './VisualizationLayer';
import { isDuplicate } from './utils';

export interface ClassOptionsByType {
  [modelType: string]: ClassOption[];
}

export interface VisualizationStainProps {
  stain: string;
  classes: ClassOptionsByType;
  visualizationsCreated: VisualizationCreated[];
  setVisualizations: React.Dispatch<React.SetStateAction<VisualizationCreated[]>>;
  classificationModelOptions: ClassificationModel[];
}

const VisualizationStain: React.FC<React.PropsWithChildren<VisualizationStainProps>> = ({
  stain,
  classes,
  visualizationsCreated,
  setVisualizations,
  classificationModelOptions,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorElPresets, setAnchorElPresets] = useState<HTMLButtonElement | null>(null);

  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  const { data: visualizations } = useQuery({
    queryKey: ['visualizations'],
    queryFn: () => getVisualizationsForCalculateFeatures(),
  });

  const { data: visualizationPresets } = useQuery({
    queryKey: ['visualizations', stain],
    queryFn: () => getVisualizationPresets(stain),
  });

  const visualizationsById = keyBy(visualizations, 'id');

  const addNewVisualization = (visualizationId: string) => {
    setAnchorEl(null);
    setVisualizations((prevVisualizations) => [
      ...prevVisualizations,
      {
        newVisualizationId: uuidv4(),
        id: visualizationId,
        displayName: visualizationsById[visualizationId]?.displayName,
        input: visualizationsById[visualizationId]?.input,
        stain,
        withComposite: true,
        layers: [{ classKey: '', color: '#000000' }],
        index: visualizationsById[visualizationId]?.index,
      },
    ]);
  };

  const updateVisualization = (visualizationUpdate: VisualizationCreated) => {
    setVisualizations((prevVisualizations) => {
      const newVisualizations = [...prevVisualizations];
      const visualizationIndex = findIndex(
        newVisualizations,
        (visualization) => visualization?.newVisualizationId == visualizationUpdate.newVisualizationId
      );
      newVisualizations[visualizationIndex] = visualizationUpdate;
      return newVisualizations;
    });
  };

  const deleteVisualization = (newVisualizationId: string) => {
    setVisualizations((prevVisualizations) =>
      filter(prevVisualizations, (visualization) => visualization?.newVisualizationId !== newVisualizationId)
    );
  };

  const addNewVisualizationPreset = (visualizationPreset: VisualizationPreset) => {
    setAnchorElPresets(null);
    const newVisualizations = map(compact(visualizationPreset?.presetJson), (visualization) => ({
      newVisualizationId: uuidv4(),
      id: visualization.visualizationId,
      displayName: visualizationsById[visualization.visualizationId]?.displayName,
      input: visualizationsById[visualization.visualizationId]?.input,
      stain,
      withComposite: visualization.withComposite,
      layers: map(compact(visualization?.layers), (layer) => ({
        classKey: layer.classKey,
        color: layer.color,
        classNotExist: !includes(
          map(classes[visualizationsById[visualization.visualizationId]?.input], 'value'),
          layer.classKey
        ),
      })),
      index: visualizationsById[visualization.visualizationId]?.index,
    }));

    setVisualizations((prevVisualizations) => [...prevVisualizations, ...newVisualizations]);
  };

  return (
    <Accordion disableGutters defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {isLoadingStainTypeOptions ? <CircularProgress /> : stainTypeIdToDisplayName(stain)}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} direction="column">
          <Grid item xs={12}>
            <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
              {map(visualizationsCreated, (visualizationCreated) => (
                <NewVisualization
                  isDuplicate={isDuplicate(visualizationsCreated, visualizationCreated)}
                  key={visualizationCreated?.newVisualizationId}
                  visualization={visualizationCreated}
                  updateVisualization={updateVisualization}
                  deleteVisualization={deleteVisualization}
                  classes={classes}
                  classificationModelOptions={classificationModelOptions}
                />
              ))}
            </TreeView>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} justifyContent="flex-start">
              <Grid item>
                <Button
                  aria-describedby="simple-popover"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                  disabled={isEmpty(visualizations)}
                >
                  Add New Visualization
                </Button>
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <List>
                    {map(visualizations, (visualization) => (
                      <ListItemButton key={visualization?.id} onClick={() => addNewVisualization(visualization?.id)}>
                        <ListItemText primary={visualization?.displayName} />
                      </ListItemButton>
                    ))}
                  </List>
                </Popover>
              </Grid>
              <Grid item>
                <Button
                  aria-describedby="simple-popover"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorElPresets(event.currentTarget)}
                  disabled={isEmpty(visualizationPresets)}
                >
                  Presets
                </Button>
                <Popover
                  open={Boolean(anchorElPresets)}
                  anchorEl={anchorElPresets}
                  onClose={() => setAnchorElPresets(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <List>
                    {map(visualizationPresets, (visualizationPreset) => (
                      <ListItemButton
                        key={visualizationPreset?.id}
                        onClick={() => addNewVisualizationPreset(visualizationPreset)}
                      >
                        <ListItemText primary={visualizationPreset?.displayName} />
                      </ListItemButton>
                    ))}
                  </List>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default VisualizationStain;
