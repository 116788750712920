import { Chart, ChartDataset, Title } from 'chart.js';
import { PartialCohort } from 'interfaces/cohort_old';
import { findIndex, findLastIndex, map, slice, times } from 'lodash';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { splitStringAfterNChars } from 'utils/helpers';
import {
  borderColorPalette,
  colorPalette,
  invertedBorderColorPalette,
  invertedColorPalette,
} from '../chartsColorPallete';
import { cohortsToDistanceBasedDataset, distanceBasedChartOptions } from './distanceBased.util';

Chart.register(Title);

interface Props {
  cohorts: PartialCohort[];
  horizontalKeyName?: string;
  bucketSize: number;
  lowerBound: number;
  upperBound: number;
  thresholdValue?: number;
  chartIndex?: number;
  yScaleTitle?: string;
  numCharactersPerLine?: number;
}

const DistanceBasedChart: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  cohorts,
  horizontalKeyName,
  ...props
}) => {
  const { bucketSize, lowerBound, upperBound } = props;
  const datasets: ChartDataset<'bar'>[] = (
    horizontalKeyName
      ? cohortsToDistanceBasedDataset([lowerBound, upperBound], horizontalKeyName, bucketSize)(cohorts)
      : []
  ) as ChartDataset<'bar'>[];
  return <DistanceBasedChartForDatasets datasets={datasets} {...props} />;
};

export const DistanceBasedChartForDatasets: React.FunctionComponent<
  React.PropsWithChildren<
    Omit<Props, 'cohorts' | 'horizontalKeyName'> & {
      datasets: ChartDataset<'bar'>[];
    }
  >
> = ({
  datasets: baseDatasets,
  bucketSize,
  lowerBound,
  upperBound,
  thresholdValue = 0,
  numCharactersPerLine = 70,
  chartIndex,
  yScaleTitle,
}) => {
  const firstDataIndex = Math.min(
    ...map(baseDatasets, (dataset) => findIndex(dataset.data, (dataPoint) => dataPoint > 0))
  );
  const lastDataIndex = Math.max(
    ...map(baseDatasets, (dataset) => findLastIndex(dataset.data, (dataPoint) => dataPoint > 0))
  );

  const hasData = firstDataIndex !== -1 || lastDataIndex !== -1;

  const data = React.useMemo(() => {
    const labels = hasData
      ? times(lastDataIndex - firstDataIndex + 1, (i) => (firstDataIndex + i) * bucketSize + lowerBound)
      : times((upperBound - lowerBound) / bucketSize + 1, (i) => i * bucketSize + lowerBound);

    const datasets = map(baseDatasets, (dataset) => {
      const newData = slice(dataset.data, firstDataIndex, lastDataIndex + 1);
      return {
        ...dataset,
        // To avoid labels being too long, we split them into multiple lines
        // this behavior (passing an array of lines) isn't documented in the type
        label: splitStringAfterNChars(dataset.label, numCharactersPerLine) as any,
        data: hasData ? newData : dataset.data,
        backgroundColor:
          chartIndex !== undefined
            ? map(labels, (lower) =>
                lower > thresholdValue
                  ? colorPalette[chartIndex % colorPalette.length]
                  : invertedColorPalette[chartIndex % invertedColorPalette.length]
              )
            : dataset.backgroundColor,
        borderColor:
          chartIndex !== undefined
            ? map(labels, (lower) =>
                lower > thresholdValue
                  ? borderColorPalette[chartIndex % borderColorPalette.length]
                  : invertedBorderColorPalette[chartIndex % invertedBorderColorPalette.length]
              )
            : dataset.borderColor,
      };
    });
    return { labels, datasets };
  }, [
    JSON.stringify(baseDatasets),
    firstDataIndex,
    lastDataIndex,
    hasData,
    bucketSize,
    thresholdValue,
    chartIndex,
    numCharactersPerLine,
  ]);

  const minBucketValue = firstDataIndex * bucketSize + lowerBound;
  const maxBucketValue = lastDataIndex * bucketSize + lowerBound;

  return (
    <Bar
      style={{ minHeight: 250, maxHeight: 300, width: '100%' }}
      data={data}
      options={distanceBasedChartOptions({
        valuesRange: hasData ? [minBucketValue, maxBucketValue] : [lowerBound, upperBound],
        bucketSize,
        yScaleTitle,
        maxCharsPerLabelLine: Math.max(1, Math.floor(numCharactersPerLine * 0.7)),
      })}
    />
  );
};

export default DistanceBasedChart;
