import { LocalOffer } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { Slide } from 'interfaces/slide';
import OpenSeadragon from 'openseadragon';
import React from 'react';
import OSDViewer from 'utils/OSD/OSDViewer';
import { uuidv4 } from 'utils/helpers';

declare const IMAGE_HOST: string;

const SLIDE_LOAD_RETRY = 3;
const DEEPZOOM_DOMAIN = 'https://image.nucleai.cloud/';

export interface Scalebar {
  viewer: Omit<OpenSeadragon.Viewer, 'canvas' | 'container' | 'element' | 'drawer' | 'navigator'>;
  referenceItemIdx: number;
  pixelsPerMeter: number;
  updateOptions(options: Partial<Scalebar>): void;
  currentPPM(givenZoom?: number): number;
  minWidth: number;
  sizeAndTextRenderer(
    ppm: number,
    minWidth: number
  ): {
    text: string;
    size: number;
  };
}

interface Props {
  darkBackground?: boolean;
  slide: Slide;
  onZoomSlide?: (zoomObj: { zoom: number }) => void;
  onPanSlide?: (panObj: { center: OpenSeadragon.Point }) => void;
  onOpenSlide?: () => void;
  children: React.ReactNode;
  isDisplayingTaggedSlide?: boolean;
}

export const changeUrlDomain = (newDomain: string, originalUrl: string) => {
  if (!originalUrl) {
    return originalUrl;
  }
  const orignalUrlObject = new URL(originalUrl);
  const newDomainObject = new URL(newDomain);
  orignalUrlObject.host = newDomainObject.host;
  orignalUrlObject.port = newDomainObject.port;
  orignalUrlObject.protocol = newDomainObject.protocol;
  return orignalUrlObject.toString();
};

export const onFailedAddTiledImage = (event: any, viewer: any) => {
  console.warn(`Failed to load tiled image locally ${event.options.tileSource} falling back to production`);
  viewer.addTiledImage({
    tileSource: changeUrlDomain(DEEPZOOM_DOMAIN, event.options.tileSource),
    crossOriginPolicy: event.options.crossOriginPolicy,
    opacity: event.options.opacity,
  });
};

class Viewer extends React.Component<Props> {
  id: string;

  failCount = 0;

  el: HTMLElement;

  osd: OSDViewer;

  componentDidUpdate(prevProps: Props) {
    if (this.props.slide.id !== prevProps.slide.id) {
      this.openSlide(this.props.slide);
    }
  }

  init(navigatorId: string | null, opacity: number, degrees = 0, openSlide = true) {
    this.id = uuidv4();

    const config = {
      element: this.el,
      navigatorId,
      showNavigator: Boolean(navigatorId),
      degrees,
      gestureSettingsMouse: undefined as any,
      gestureSettingsTouch: undefined as any,
      opacity,
    };

    this.osd = new OSDViewer(
      config,
      this.props.onOpenSlide,
      this.props.onZoomSlide,
      this.props.onPanSlide,
      this.onFailedSlide
    );

    if (openSlide) this.openSlide(this.props.slide);
  }

  openSlide(slide: Slide) {
    if (slide.labId && slide.format) {
      fetch(`${IMAGE_HOST}/manifest/${slide.id}/?lab_id=${slide.labId}&slide_format=${slide.format}`)
        .then((response) => response.json())
        .then((data) => this.osd.openSlide(data));
    }
  }

  onFailedSlide = () => {
    console.warn(`Failed to load slide ${this.props.slide.id} retrying`);
    if (this.failCount < SLIDE_LOAD_RETRY) {
      this.openSlide(this.props.slide);
      this.failCount += 1;
    }
  };

  setColorMap(filterItems: { hexColor: any; range: number[]; gamma: number; indexItem: number }[]) {
    this.osd.setColorMap(filterItems);
  }

  zoomTo(zoom: number) {
    this.osd.zoomTo(zoom);
  }

  panTo(centerPoint: OpenSeadragon.Point) {
    this.osd.panTo(centerPoint);
  }

  getCenter() {
    return this.osd.getCenter();
  }

  getZoom() {
    return this.osd.getZoom();
  }

  viewportToImageRectangle(point: OpenSeadragon.Point) {
    return this.osd.viewportToImageRectangle(point);
  }

  viewportToImageCoordinates(point: OpenSeadragon.Point) {
    return this.osd.viewportToImageCoordinates(point);
  }

  imageToViewportPoint(point: { x: number; y: number }) {
    return this.osd.imageToViewportPoint(point);
  }

  render() {
    return (
      <div
        className={this.props.isDisplayingTaggedSlide ? 'viewer displaying-tagged-slide' : 'viewer'}
        style={{ height: '100%', background: this.props.darkBackground ? '#000' : '#fff' }}
        ref={(el) => {
          this.el = el;
        }}
      >
        {this.props.children}
        {this.props.isDisplayingTaggedSlide && (
          <Chip
            label="Tagged Slide"
            variant="filled"
            size="small"
            sx={{
              zIndex: 100,
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              border: '1px solid',
            }}
            icon={<LocalOffer color="inherit" />}
          />
        )}
      </div>
    );
  }
}

export default Viewer;
