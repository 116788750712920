import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography, useTheme } from '@mui/material';
import { QueryFunction, QueryKey, UseQueryOptions, useQueries, useQuery } from '@tanstack/react-query';
import { OptionsObject, useSnackbar } from 'notistack';
import React from 'react';
import { logout } from 'redux/actions/auth';
import { useAppDispatch } from 'redux/hooks';
import { ResponseError } from 'superagent';

export interface QueryOptions<TQueryFnData = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>
  extends Omit<UseQueryOptions<TQueryFnData, ResponseError, TData, TQueryKey>, 'queryKey' | 'queryFn'> {
  queriedEntityName?: string;
  consoleOnly?: boolean;
  doNotLogoutOnUnauthorized?: boolean;
  errorMessageGetter?: (error: ResponseError) => string;
  onFailure?: (error: ResponseError) => void;
}

export type UseQueryResult<
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = ReturnType<typeof useQuery<TQueryFnData, ResponseError, TData, TQueryKey>>;

export type QueriesOptionsForUseQueries<
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = Omit<QueryOptions<TQueryFnData, TData, TQueryKey>, 'context'> & { queryKey: TQueryKey };

export declare type QueriesResults<
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = UseQueryResult<TQueryFnData, TData, TQueryKey>[];

const useQueryResultsErrorAndRetrySnackbar = <
  MultipleQueries extends boolean,
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  queryOption: singleQueryOption,
  queryOptions,
  returnValue: singleReturnValue,
  returnValues,
  snackbarOptions,
}: {
  snackbarOptions?: Partial<OptionsObject<'error'>>;
} & (MultipleQueries extends false
  ? {
      queryOption: QueryOptions<TQueryFnData, TData, TQueryKey>;
      queryOptions?: never;
      returnValue: UseQueryResult<TQueryFnData, TData, TQueryKey>;
      returnValues?: never;
    }
  : {
      queryOption?: never;
      queryOptions: readonly QueriesOptionsForUseQueries<TQueryFnData, TData, TQueryKey>[];
      returnValue?: never;
      returnValues: QueriesResults<TQueryFnData, TData, TQueryKey>;
    })) => {
  const previousErrors = React.useRef<ResponseError[]>([]);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();

  React.useEffect(() => {
    const queryOptionsForHook = queryOptions || [singleQueryOption];
    const returnValuesForHook = returnValues || [singleReturnValue];
    for (let i = 0; i < queryOptionsForHook.length || 0; i++) {
      const options = queryOptionsForHook[i];
      const returnValue = returnValuesForHook[i];

      if (!options || !returnValue) {
        continue;
      }

      const {
        enabled = true,
        errorMessageGetter,
        doNotLogoutOnUnauthorized,
        onFailure,
        queriedEntityName,
        consoleOnly,
      } = options;
      const { refetch, error, isLoading } = returnValue;

      if (enabled && !isLoading && error && error !== previousErrors.current[i]) {
        previousErrors.current[i] = error;
        const message = errorMessageGetter ? errorMessageGetter(error) : String(error);
        if (error.status === 500 || error.status === undefined) {
          console.error('API error', error, message);
        } else if (error.status === 401 && !doNotLogoutOnUnauthorized) {
          console.warn('user unauthorized');
          dispatch(logout());
        } else if (onFailure) {
          onFailure(error);
        } else {
          console.error('Query error', { error, message, options, returnValue });
        }
        if (!consoleOnly) {
          enqueueSnackbar(
            <Accordion sx={{ backgroundColor: 'transparent', boxShadow: 'none', backgroundImage: 'none' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
              >
                <Typography variant="body1">
                  Failed to load{queriedEntityName ? ' ' + queriedEntityName : ''}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>{message}</AccordionDetails>
            </Accordion>,
            {
              style: { backgroundColor: theme.palette.background.default },
              variant: 'error',
              action: (key) => (
                <Button
                  color="secondary"
                  onClick={() => {
                    closeSnackbar(key);
                    if (refetch) {
                      refetch();
                    } else {
                      window.location.reload();
                    }
                  }}
                >
                  Try again
                </Button>
              ),
              ...snackbarOptions,
            }
          );
        }
      }
    }
  }, [queryOptions, returnValues, theme]);

  const hasQueryOptionsAndReturnValues = Boolean(queryOptions && returnValues);
  if (
    (hasQueryOptionsAndReturnValues && queryOptions.length !== returnValues.length) ||
    (!hasQueryOptionsAndReturnValues && (queryOptions || returnValues))
  ) {
    throw new Error('queryOptions and returnValues must have the same length');
  }
  if ((queryOptions || returnValues) && (singleQueryOption || singleReturnValue)) {
    throw new Error(
      'Only one pair of inputs should be provided - queryOptions and returnValues or queryOption and returnValue'
    );
  }
};

export const useQueryWithErrorAndRetrySnackbar = <
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFunction: QueryFunction<TQueryFnData, TQueryKey>,
  queryOption: QueryOptions<TQueryFnData, TData, TQueryKey> = {},
  snackbarOptions?: Partial<OptionsObject<'error'>>
): UseQueryResult<TQueryFnData, TData, TQueryKey> => {
  const {
    queriedEntityName,
    errorMessageGetter = (error: ResponseError) => error?.message,
    doNotLogoutOnUnauthorized,
    onFailure,
    ...innerOptions
  } = queryOption;
  const returnValue = useQuery<TQueryFnData, ResponseError, TData, TQueryKey>(queryKey, queryFunction, innerOptions);

  useQueryResultsErrorAndRetrySnackbar({ queryOption, returnValue, snackbarOptions });

  return returnValue;
};

export const useQueriesWithErrorAndRetrySnackbar = <
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  queries,
  context,
  snackbarOptions,
}: {
  queries: readonly QueriesOptionsForUseQueries<TQueryFnData, TData, TQueryKey>[];
  context?: UseQueryOptions['context'];
  snackbarOptions?: Partial<OptionsObject<'error'>>;
}): UseQueryResult<TQueryFnData, TData, TQueryKey>[] => {
  const returnValues = useQueries({ queries, context }) as UseQueryResult<TQueryFnData, TData, TQueryKey>[];

  useQueryResultsErrorAndRetrySnackbar({ queryOptions: queries, returnValues, snackbarOptions });

  return returnValues;
};
