import classnames from 'classnames';
import React from 'react';
import './ToggleSwitch.scss';

export enum TitlePlacement {
  LEFT = 'left',
  RIGHT = 'right',
}

interface Props {
  title: string;
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
}

const ToggleSwitch: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  title,
  checked,
  disabled,
  onChange,
}) => (
  <div
    className={classnames('toggle-switch', { checked, disabled })}
    onClick={() => {
      if (!disabled) onChange();
    }}
  >
    <span className="title left">{title}</span>
    <label className="switch">
      <span className="slider" />
    </label>
  </div>
);

export default ToggleSwitch;
