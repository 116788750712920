import * as React from 'react';

interface Props extends React.HTMLProps<HTMLDivElement> {
  // Injected by HtmlOverlay
  x?: number;
  y?: number;

  // Needed for parent to calculate x/y
  coordinates: [number, number];

  // User provided
  zIndex?: string | number;

  style?: React.CSSProperties;
}

export default class HtmlDeckGLOverlayItem extends React.Component<Props> {
  render() {
    const { x, y, children, zIndex = 'auto', style, ...divProps } = this.props;

    return (
      // Using transform translate to position overlay items will result in a smooth zooming
      // effect, whereas using the top/left css properties will cause overlay items to
      // jiggle when zooming
      <div
        {...divProps}
        style={{ transform: `translate(${x}px, ${y}px)`, position: 'absolute', zIndex, ...(style || {}) }}
      >
        {children}
      </div>
    );
  }
}
