import { DeckGLProps } from '@deck.gl/react/typed';
import { useSignals } from '@preact/signals-react/runtime';
import {
  Dictionary,
  compact,
  concat,
  every,
  find,
  flatMap,
  flatten,
  fromPairs,
  keys,
  map,
  some,
  sortBy,
  uniq,
  values,
} from 'lodash';
import { useMemo, useRef } from 'react';

import { COORDINATE_SYSTEM } from '@deck.gl/core/typed';
import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { HeatmapType } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { HeatmapsImagePyramids, ImagePyramid } from 'components/Procedure/useSlideImages';
import MultiScaleImageLayer, { OVERVIEW_LAYER_ID } from './StainsLayers/layers/multiScaleImageLayer';

const heatmapSelections = [{ layerIndex: 0 }];

export const useHeatmapLayers = ({
  slide,
  baseImagePyramids,
  heatmapsImagePyramids,
  overview,
}: {
  slide: SlideWithChannelAndResults;
  baseImagePyramids: ImagePyramid;
  heatmapsImagePyramids: HeatmapsImagePyramids;
  overview?: boolean;
}): DeckGLProps['layers'] => {
  useSignals();
  const slideId = slide?.id;
  const viewerIndex = slide?.viewerIndex;
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];

  const heatmapsSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId];
  const sortedHeatmapIds = sortBy(keys(heatmapsImagePyramids));
  const sortedHeatmapSettings = compact(
    map(sortedHeatmapIds, (heatmapId) => ({
      heatmapId,
      heatmapSettings: heatmapsSettings?.[heatmapId]?.value,
    }))
  );

  const didVisualizeHeatmaps = useRef<Dictionary<boolean>>({});

  const heatmapResults = slide?.heatmapResults;
  const internalHeatmaps = slide?.internalHeatmaps;

  return useMemo(() => {
    const allSlideHeatmaps = compact(
      flatMap(
        flatMap(
          concat(
            heatmapResults?.publishedResults,
            flatten(values(heatmapResults?.internalResults)),
            flatten(values(internalHeatmaps))
          ),
          (heatmap) => concat(heatmap.secondaryResults || [], heatmap)
        ),
        (heatmap) => concat(heatmap, heatmap?.nestedItems || [])
      )
    );

    const newHeatmapLayersSettings = fromPairs(
      compact(
        map(sortedHeatmapSettings, ({ heatmapId, heatmapSettings }) => {
          const layerSource = heatmapsImagePyramids?.[heatmapId];
          if (!layerSource) {
            return undefined;
          }

          const childHeatmap = find(allSlideHeatmaps, { id: heatmapId });
          const isRasterHeatmap = childHeatmap?.heatmapType === HeatmapType.Dzi;

          const parentHeatmap = find(allSlideHeatmaps, ({ nestedItems }) => some(nestedItems, { id: heatmapId }));
          const parentSettings = find(sortedHeatmapSettings, {
            heatmapId: parentHeatmap?.id,
          })?.heatmapSettings;
          const allParentNestedHeatmapsSelected = every(
            map(parentHeatmap?.nestedItems, 'id'),
            (nestedId) => find(sortedHeatmapSettings, { heatmapId: nestedId })?.heatmapSettings?.selected
          );
          const parentSelected =
            Boolean(parentHeatmap?.heatmapUrl) && (parentSettings?.selected || allParentNestedHeatmapsSelected);

          // If the heatmap is a raster heatmap and it's parent is selected, don't consider it selected individually (the parent will render it)
          const isHeatmapSelected = (!isRasterHeatmap || !parentSelected) && heatmapSettings?.selected;

          const isSelectedAndShown = isHeatmapSelected && heatmapSettings?.show;
          // for nested raster heatmaps, we don't support individual opacity in UI, so we use the parent's opacity
          const settingsToUse = isRasterHeatmap ? parentSettings || heatmapSettings : heatmapSettings;
          const heatmapOpacity = isSelectedAndShown ? settingsToUse?.opacity ?? 0 : 0;

          const shouldPreload = Boolean(
            // If there are a few heatmaps, load them all when hidden so that they can be toggled on without loading
            keys(heatmapsImagePyramids).length <= 5 ||
              // If the heatmap is published, load it
              find(heatmapResults?.publishedResults, { id: heatmapId })
          );

          if (!isHeatmapSelected && !shouldPreload && !didVisualizeHeatmaps.current[heatmapId]) {
            return [heatmapId, undefined];
          }
          didVisualizeHeatmaps.current[heatmapId] = true;

          const layerMaxZoom = layerSource.maxLevel;
          const baseLayerMaxZoom = baseImagePyramids?.layerSource.maxLevel;
          const zoomOffset = baseLayerMaxZoom - layerMaxZoom;

          return [
            heatmapId,
            {
              layerSource,
              baseImageSource: baseImagePyramids?.layerSource,
              selections: heatmapSelections,
              excludeBackground: !overview,
              overviewLayer: overview,
              viewerIndex,
              id: `${overview ? OVERVIEW_LAYER_ID : 'MultiScaleImageLayer'}-${heatmapId}-${layerSource.getUniqueId()}`,
              coordinateSystem: COORDINATE_SYSTEM.DEFAULT,
              tileSize: layerSource.getTileSize(),
              zoomOffset,
              pickable: true,
              isOverlay: true,
              layerOpacities: heatmapOpacity,
              layersVisible: isHeatmapSelected,
            },
          ];
        })
      )
    );
    const heatmapIds = uniq(map(allSlideHeatmaps, 'id'));

    return compact(
      map(heatmapIds, (id) => {
        const params = newHeatmapLayersSettings[id];
        if (!params) {
          return undefined;
        }
        try {
          return new MultiScaleImageLayer(params);
        } catch (e) {
          console.error('Error creating MultiScaleImageLayer', e);
          return undefined;
        }
      })
    );
  }, [
    slideId,
    viewerIndex,
    heatmapResults,
    internalHeatmaps,
    baseImagePyramids,
    heatmapsImagePyramids,
    JSON.stringify(sortedHeatmapSettings),
  ]);
};
