import { useQuery } from '@tanstack/react-query';
import { getPermissionOptions } from 'api/permissionOptions';
import { getRoles } from 'api/roles';
import { Permission, PermissionOption } from 'interfaces/permissionOption';
import { Role } from 'interfaces/roles';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { usePermissions } from 'utils/usePermissions';

export interface UserCredentialsFieldsContext {
  labId: string;
  permissions: PermissionOption[];
  roles: Role[];
  isLoadingRoles: boolean;
  isLoadingPermissions: boolean;
}

export const useUserCredentialsFieldsContext = () => {
  const { labId } = useCurrentLabId();
  const { hasSomePermissions } = usePermissions();
  const canViewRoles = hasSomePermissions([Permission.DefineRolesToLabUsers, Permission.ManageNucleaiUsers]);
  const { data: roles, isLoading: isLoadingRoles } = useQuery(['roles', labId], () => getRoles(labId), {
    enabled: canViewRoles,
  });
  const { data: permissions, isLoading: isLoadingPermissions } = useQuery(['permissions'], getPermissionOptions);

  return {
    labId,
    roles,
    permissions,
    isLoadingRoles,
    isLoadingPermissions,
  };
};
