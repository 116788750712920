import { Grid, TextField, Typography } from '@mui/material';
import { RangeValue } from 'interfaces/caseQuery';
import React from 'react';

interface Props {
  onChange: (currentValue: RangeValue) => void;
  value?: RangeValue;
}

const CalculatedFeatureRange = (props: Props) => {
  const { onChange } = props;

  const [inputValue, setInputValue] = React.useState<RangeValue | undefined>(null);

  const value = inputValue ?? props.value;

  const [, startTransition] = React.useTransition();

  const onChangeValue = (newValue: RangeValue) => {
    setInputValue(newValue);
    startTransition(() => {
      onChange(newValue);
    });
  };

  const handleStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeValue({ start: event.target.value, end: value?.end || '' });
  };

  const handleEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeValue({ start: value?.start || '', end: event.target.value });
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between" flexWrap="nowrap">
      <Grid item flexGrow={2}>
        <TextField
          fullWidth
          label="From"
          type="number"
          value={value?.start || ''}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          onChange={handleStartChange}
        />
      </Grid>
      <Grid item>
        <Typography variant="body1" margin={1}>
          <strong>-</strong>
        </Typography>
      </Grid>
      <Grid item flexGrow={2}>
        <TextField
          fullWidth
          value={value?.end || ''}
          label="To"
          type="number"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleEndChange}
        />
      </Grid>
    </Grid>
  );
};

export default CalculatedFeatureRange;
