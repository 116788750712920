import ModalWrapper from 'components/ModalWrapper';
import { Permission } from 'interfaces/permissionOption';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from 'utils/usePermissions';
import UploadSlidesForm from './UploadSlidesForm';
import './UploadSlidesModal.scss';

interface Props {
  studyId: string;
  onClose: () => void;
  isOpen: boolean;
}

const UploadSlidesModal: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { onClose, isOpen } = props;
  const navigate = useNavigate();

  const { hasPermission } = usePermissions();

  const canRunDemo = hasPermission(Permission.UseNucleaiExperimentalFeatures);
  React.useEffect(() => {
    if (canRunDemo) {
      const onCtrrlN = (event: KeyboardEvent) => {
        if (event.ctrlKey && (event.key === 'n' || event.key === 'N' || event.key === 'מ')) {
          event.preventDefault();
          onClose();
          navigate({
            pathname: '/procedures',
            search: 'labId=cc66db90-1e3e-11e9-80b7-0af5642e5b64&studyId=5c323dbc-3373-11ed-8f20-6dd71ae56152',
          });
        }
      };
      document.addEventListener('keydown', onCtrrlN, false);

      return () => {
        document.removeEventListener('keydown', onCtrrlN, false);
      };
    }
  }, [canRunDemo, onClose, navigate]);

  return (
    <ModalWrapper onClose={onClose} isOpen={isOpen}>
      <UploadSlidesForm {...props} />
    </ModalWrapper>
  );
};

export default UploadSlidesModal;
