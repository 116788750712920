import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Switch, Typography } from '@mui/material';
import { useQueries, useQuery } from '@tanstack/react-query';
import { getFeaturesByExperimentResultId, getFeaturesByExperimentResultIdQueryKey } from 'api/features';
import LabelledInput from 'components/atoms/LabelledInput/LabelledInput';
import Loader from 'components/Loader';
import { filter, includes, isEmpty, map, partition, some } from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useMemo, useState } from 'react';
import { useSearchFiltering } from 'services/matchSort';
import useLocalStorage from 'use-local-storage';
import { isDistanceBasedFormattedFeature } from 'utils/features';
import { useFormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { stabilizedQueriesDependency } from 'utils/queryClient';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useStainTypeIdToIndex } from 'utils/useStainTypeIdToIndex';
import FeatureCategory from './FeatureCategory';
import FeatureList, { featureListHeight } from './FeatureList';
import { formatFeaturesWebWorker } from './formatFeaturesWebWorker';

interface Props {
  experimentResultIds?: number[];
  title?: string;
  internalFeatures?: boolean;
  highlightedFeatures?: string[];
  hiddenFeatures?: string[]; // TODO: This prop is not used - find out if it can be removed
  slideStainTypeId: string;
  doesCaseHaveMultipleStainResults: boolean;
}

const defaultExperimentResultIds: number[] = [];
const defaultHighlightedFeatures: string[] = [];

const Features: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  experimentResultIds = defaultExperimentResultIds,
  title = 'Features',
  internalFeatures = false,
  highlightedFeatures = defaultHighlightedFeatures,
  slideStainTypeId,
  doesCaseHaveMultipleStainResults,
}) => {
  const { stainTypeIdToIndex, isLoadingStainTypeOptions } = useStainTypeIdToIndex();
  const [hideNones, setHideNones] = useLocalStorage<boolean>('HiddenFeaturesNoneValues', true);
  const { labId } = useCurrentLabId();

  const [expandAccordion, setExpandAccordion] = useState(!internalFeatures);

  const queriedFeatures = useQueries({
    queries: map(experimentResultIds, (experimentResultId) => ({
      queryKey: getFeaturesByExperimentResultIdQueryKey({ experimentResultId, labId }),
      queryFn: () => getFeaturesByExperimentResultId(experimentResultId, labId),
      enabled: expandAccordion && experimentResultId !== undefined,
    })),
  });

  const isLoadingFeatures =
    some(queriedFeatures, 'isLoading') &&
    some(experimentResultIds, (experimentResultId) => experimentResultId !== undefined);

  const formatBracketsOptions = useFormatBracketsOptions(doesCaseHaveMultipleStainResults);

  const stainIndexFilter =
    !isLoadingStainTypeOptions && slideStainTypeId ? stainTypeIdToIndex(slideStainTypeId)?.toString() : undefined;

  if (!stainIndexFilter && !isLoadingStainTypeOptions && slideStainTypeId) {
    console.debug(`Failed to get stainIndex for stain type ${slideStainTypeId}: ${stainIndexFilter}`);
  }

  const queriedFeaturesData = map(queriedFeatures, 'data');
  const { data: formattedFeatures, isLoading: isLoadingFormattedFeatures } = useQuery({
    queryFn: () => formatFeaturesWebWorker(queriedFeaturesData, stainIndexFilter, formatBracketsOptions),
    enabled: !isLoadingFeatures && !formatBracketsOptions.isLoading && !isLoadingStainTypeOptions,
    queryKey: [
      'formattedFeatures',
      stabilizedQueriesDependency(queriedFeatures),
      experimentResultIds,
      slideStainTypeId,
      formatBracketsOptions,
      stainIndexFilter,
    ],
  });

  const relevantFormattedFeatures = useMemo(
    () => (hideNones ? filter(formattedFeatures, (feature) => feature.value !== null) : formattedFeatures || []),
    [formattedFeatures, hideNones]
  );

  const {
    filteredOptions: filteredFeatures,
    localSearch: searchText,
    setLocalSearch: setSearchText,
    isLoading: isLoadingSearch,
  } = useSearchFiltering(['key', 'displayName', 'nameOverride'], relevantFormattedFeatures, {
    threshold: matchSorter.rankings.CONTAINS,
  });

  const hasNones: boolean = some(formattedFeatures, { value: null });

  const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHideNones(event.target.checked);
  };

  const noFeatures = !isLoadingFormattedFeatures && isEmpty(relevantFormattedFeatures);
  const noResults = isEmpty(filteredFeatures) && Boolean(searchText) && !isLoadingSearch;

  const [highlightedFeaturesList, otherFeaturesList] = useMemo(
    () => partition(filteredFeatures, (feature) => includes(highlightedFeatures, feature.key)),
    [filteredFeatures, highlightedFeatures]
  );

  return (
    <Accordion square expanded={expandAccordion} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary onClick={() => setExpandAccordion((prev) => !prev)} expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={12}>
            <Typography variant={internalFeatures ? 'subtitle2' : 'h4'}>{title}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ display: 'grid', padding: 1 }}>
        <>
          {isLoadingFeatures || isLoadingFormattedFeatures ? (
            <Loader />
          ) : (
            <Grid container spacing={1}>
              {!isEmpty(relevantFormattedFeatures) && (
                <Grid item sx={{ width: 1 }}>
                  <LabelledInput
                    size="small"
                    label="Search feature"
                    value={searchText}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchText(event.target.value);
                    }}
                    onReset={() => {
                      setSearchText('');
                    }}
                    onKeyDown={null}
                  />
                </Grid>
              )}
              {noFeatures ? (
                <Grid item sx={{ width: 1 }}>
                  <Typography variant="body2">No features found</Typography>
                </Grid>
              ) : noResults ? (
                <Grid item sx={{ width: 1, height: featureListHeight }}>
                  <Typography variant="body2">No results found</Typography>
                </Grid>
              ) : (
                <Grid item xs={12} container>
                  {filteredFeatures &&
                    (!isEmpty(highlightedFeaturesList) && !searchText ? (
                      <>
                        <FeatureCategory
                          openByDefault
                          name={'Feature Highlights'}
                          filteredFeatures={highlightedFeaturesList}
                          skipVirtualization
                          addStain={doesCaseHaveMultipleStainResults}
                        />
                        <FeatureCategory
                          name={'Other'}
                          filteredFeatures={otherFeaturesList}
                          addStain={doesCaseHaveMultipleStainResults}
                        />
                      </>
                    ) : some(filteredFeatures, (feature) => isDistanceBasedFormattedFeature(feature)) ? (
                      <FeatureCategory
                        filteredFeatures={filteredFeatures}
                        addStain={doesCaseHaveMultipleStainResults}
                      />
                    ) : (
                      <FeatureList filteredFeatures={filteredFeatures} addStain={doesCaseHaveMultipleStainResults} />
                    ))}
                  {hasNones && (
                    <Grid item container justifyContent="end" alignItems="center">
                      <Typography variant="body2">
                        Hide <pre style={{ display: 'inline' }}>None</pre> values
                      </Typography>
                      <Switch checked={hideNones} size="small" onChange={onSwitchChange} />
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          )}
        </>
      </AccordionDetails>
    </Accordion>
  );
};

export default Features;
