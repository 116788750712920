import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import { IconButton, Tooltip, useTheme } from '@mui/material';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

interface Props {
  isAdvancedSearchIndication?: boolean;
  text: string;
  isIconButton?: boolean;
  filled?: boolean;
}

const AdvancedSearchButton = ({ isAdvancedSearchIndication, text, isIconButton = false, filled }: Props) => {
  const theme = useTheme();

  const [, setAdvancedSearchDrawerOpen] = useQueryParam('advancedSearchDrawerOpen', BooleanParam);

  const onHandleDrawerToggleButton = () => {
    setAdvancedSearchDrawerOpen((current) => !current, 'replaceIn');
  };

  return isIconButton ? (
    <Tooltip title={text} placement="top">
      <IconButton
        sx={{
          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
        }}
        color="primary"
        onClick={onHandleDrawerToggleButton}
      >
        {isAdvancedSearchIndication ? (
          <Badge variant="dot" color="primary">
            <FilterAltIcon />
          </Badge>
        ) : filled ? (
          <FilterAltIcon />
        ) : (
          <FilterAltOutlinedIcon />
        )}
      </IconButton>
    </Tooltip>
  ) : (
    <Button
      endIcon={
        isAdvancedSearchIndication ? (
          <Badge variant="dot" color="primary">
            <FilterAltIcon />
          </Badge>
        ) : filled ? (
          <FilterAltIcon />
        ) : (
          <FilterAltOutlinedIcon />
        )
      }
      variant="contained"
      color="secondary"
      onClick={onHandleDrawerToggleButton}
    >
      {text}
    </Button>
  );
};

export default AdvancedSearchButton;
