export interface OrchestrationParamsDeps {
  classes?: string[];
  modelTsm?: string;
  dynamicCellDetectionConfig?: string;
  useDynamicCellDetection?: boolean;
  dedupValue?: number;
}

export enum OrchestrationDataType {
  Categorical = 'categorical',
  CategoricalArray = 'categorical_array',
  Int = 'int',
  Float = 'float',
  Boolean = 'boolean',
  Point = 'point',
  Multipolygon = 'multipolygon',
  BooleanArray = 'boolean_array',
}

export const orchestrationDataTypeNumbers = [OrchestrationDataType.Float, OrchestrationDataType.Int];

export interface NumberOption {
  min?: number;
  max?: number;
}

export interface OrchestrationData {
  label?: string;
  type?: OrchestrationDataType;
  options?:
    | string[] // Categorical
    | NumberOption; // Float, Int
}

export interface OrchestrationParams {
  resultType?: string;
  studyId?: string;
  orchestrationId: string;
  flowClassName?: string;
  deps: OrchestrationParamsDeps;
  expirationDate?: string;
  gitHash?: string;
  data?: OrchestrationData[];
}

export interface OrchestrationInference {
  orchestrationId: string;
  slideIds: string[];
  tsmSlideOverrides: { [slideId: string]: string };
  params: OrchestrationParams;
  orchestrationResultArtifactUrlPattern: string;
  orchestrationTsmArtifactUrlPattern?: string;
}

export interface InferenceModelData {
  modelUrl: string;
  modelId?: string;
  modelType: string;
  classes?: string[];
}

export interface ModelInference extends InferenceModelData {
  orchestrations?: OrchestrationInference[];
}

export interface SlideInferenceResults {
  slideId: string;
  stainingType: string;
  originalFileName: string;
  caseId: string;
  caseLabel: string;
}

export interface MapValuesToBins {
  bin: string | number;
  minValue?: number;
  maxValue?: number;
}

export interface ClassificationBinningParams {
  numBins?: number;
  mapValuesToBins?: MapValuesToBins[];
}

export interface Input {
  modelType?: string;
  flowClassName?: string;
  artifactUrl?: string;
  assignmentId?: number;
  classesToUse?: string[];
  classesToIgnore?: string[];
  readAs?: string;
  binning?: ClassificationBinningParams;
}

export interface ResultsInput {
  input: Input;
  override?: Input;
}

export interface DefectModel {
  path: string;
  excludeClasses: string[];
}

export interface Inputs {
  // caseId
  [key: string]: {
    // slideId
    [key: string]: ResultsInput[];
  };
}
