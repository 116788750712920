import { Box, Breakpoint, Container, Toolbar } from '@mui/material';
import React from 'react';

import SiteHeader from 'components/SiteHeader/SiteHeader';
import DashboardFooter from 'components/StudiesDashboard/DashboardFooter';
import { MainPageAnnotationDrawer } from '../../AssignmentsList/MainPageAnnotationDrawer';
import { FlexBody } from '../FlexContainers/FlexContainers';

const wrapPage = (
  WrappedComponent: React.FunctionComponent<React.PropsWithChildren<unknown>>,
  containerSx: {} = {},
  containerMaxWidth: false | Breakpoint = 'lg'
) => {
  const WrapPageComponent: React.FunctionComponent<React.PropsWithChildren<unknown>> = (props) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <FlexBody>
          <SiteHeader />
          <Toolbar />
          <Container sx={containerSx} maxWidth={containerMaxWidth}>
            <WrappedComponent {...props} />
          </Container>
          <MainPageAnnotationDrawer />
        </FlexBody>
        <DashboardFooter />
      </Box>
    );
  };

  return WrapPageComponent;
};

export default wrapPage;
