import { TaxonomyTag } from 'interfaces/taxonomyTag';
import { omit } from 'lodash';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getTaxonomyTags = (label?: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<TaxonomyTag[]>({
    url: `taxonomy/taxonomy_tags?${stringify({ label })}`,
    method: 'GET',
    signal,
  });
};

export const getTagById = (id: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<TaxonomyTag>({
    url: `taxonomy/taxonomy_tags/${id}`,
    method: 'GET',
    signal,
  });
};

export const createTag = (tag: TaxonomyTag, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<TaxonomyTag>({
    url: `taxonomy/taxonomy_tags`,
    method: 'POST',
    signal,
    data: JSON.stringify(tag),
  });
};

export const updateTag = (tag: TaxonomyTag, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<TaxonomyTag>({
    url: `taxonomy/taxonomy_tags/${tag.id}`,
    method: 'PATCH',
    signal,
    data: JSON.stringify(omit(tag, 'id')),
  });
};

export const deleteTag = (id: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<TaxonomyTag>({
    url: `taxonomy/taxonomy_tags/${id}`,
    method: 'DELETE',
    signal,
  });
};
