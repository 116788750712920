import { GridRowId, GridRowModesModel, GridRowParams } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import React from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createRole, deleteRole, updateRole } from 'api/roles';
import { doesUpdatingRowHaveRequiredFields, isUpdatingRowUnique } from 'components/atoms/EditableDataGrid/helpers';
import { useCrudControlsColumns } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { Role } from 'interfaces/roles';
import { rolesFields } from 'interfaces/roles/rolesFields';
import { RolesFieldsContext } from 'interfaces/roles/rolesFieldsContext';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { RoleRowChangesSummary } from './RoleRowChangesSummary';

const getRoleError = ({
  id,
  apiRef,
}: GridRowParams<Role> & {
  apiRef: React.MutableRefObject<GridApiCommunity>;
}) => {
  const hasDuplicateRole = !isUpdatingRowUnique({
    apiRef,
    rowId: id,
    uniqueFields: ['name', 'labSpecific'],
  });

  const missingRequiredFields = !doesUpdatingRowHaveRequiredFields({
    apiRef,
    rowId: id,
    requiredFields: ['name'],
  });

  return hasDuplicateRole ? 'Duplicate role' : missingRequiredFields ? 'Missing required fields' : '';
};

export const useRolesColumns = ({
  apiRef,
  noRows,
  roles,
  draftRoles,
  rowModesModel,
  setDraftRoles,
  setRowModesModel,
}: {
  noRows?: boolean;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  roles: Role[];
  draftRoles: Role[];
  rowModesModel: GridRowModesModel;
  setDraftRoles: React.Dispatch<React.SetStateAction<Role[]>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();
  const { fieldsContext } = useTableEditingContext<Role, RolesFieldsContext>();

  const { labId } = useCurrentLabId();

  const queryClient = useQueryClient();

  const mutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(['roles', labId]);
    },
  };

  const createRoleMutation = useMutation((role: Omit<Role, 'id'>) => createRole({ role, labId }), mutationOptions);
  const updateRoleMutation = useMutation((role: Role) => updateRole({ role, labId }), mutationOptions);
  const deleteRoleMutation = useMutation((id: string) => deleteRole({ id, labId }), mutationOptions);

  const columns = useCrudControlsColumns<Role, Role, 'id'>({
    createMutation: createRoleMutation,
    deleteMutation: deleteRoleMutation,
    updateMutation: updateRoleMutation,
    apiRef,
    rowModesModel,
    setRowModesModel,
    setDraftRows: setDraftRoles,
    draftRows: draftRoles,
    currentRows: roles,
    rowTypeFields: rolesFields,
    getRowError: getRoleError,
    noRows,
    getCancelEditConfirmationModalOptions: ({ newRowValue, isDraft, changes }) => ({
      title: `Cancel role ${isDraft ? 'creation' : 'update'}`,
      text: (
        <RoleRowChangesSummary
          fieldsContext={fieldsContext}
          roleDisplayString={`Role "${newRowValue?.name}" ${newRowValue?.labSpecific ? ' (lab-specific)' : ''}`}
          changes={changes}
        />
      ),
    }),
    getSaveConfirmationModalOptions: ({ newRowValue, isDraft, changes }) => ({
      title: `${isDraft ? 'Create' : 'Update'} role`,
      text: (
        <RoleRowChangesSummary
          fieldsContext={fieldsContext}
          roleDisplayString={`Role "${newRowValue?.name}" ${newRowValue?.labSpecific ? ' (lab-specific)' : ''}`}
          changes={changes}
        />
      ),
    }),
    getDeleteConfirmationModalOptions: () => ({
      title: 'Delete Role',
      text: 'Are you sure you want to delete this role?',
    }),
    mutatingRowId,
    setMutatingRowId,
  });

  return columns;
};
