import AdjustIcon from '@mui/icons-material/Adjust';
import BorderStyleIcon from '@mui/icons-material/BorderStyle';
import CloseIcon from '@mui/icons-material/Close';
import PentagonOutlinedIcon from '@mui/icons-material/PentagonOutlined';
import { Checkbox, IconButton, List, ListItem, ListItemIcon, ListItemText, Skeleton } from '@mui/material';
import { ColorPicker } from 'components/atoms/ColorPicker';
import { TodoOption } from 'interfaces/annotation';
import { every, find, forEach, includes, isEmpty, map, some } from 'lodash';
import React from 'react';

interface SelectProps {
  isTodoSelected: (id: string) => boolean;
  selectedClassesToAnnotate: TodoOption[];
  onClassesSelected: (classes: TodoOption[]) => void;
}

interface ClassesToAnnotateListProps {
  classesToAnnotate: TodoOption[];
  onDeleteTodo?: (id: string) => void;
  onColorChange?: (id: string, color: string) => void;
  isSelectable?: boolean;
  selectProps?: SelectProps;
  isEditable?: boolean;
}

const ClassesToAnnotateList: React.FC<ClassesToAnnotateListProps> = ({
  classesToAnnotate,
  onDeleteTodo,
  onColorChange,
  isSelectable,
  selectProps,
  isEditable,
}) => {
  if (isEditable && !onDeleteTodo) {
    console.warn(
      'ClassesToAnnotateList: onDeleteTodo is not defined but isEditable is true- (not showing delete button)'
    );
  }
  if (isEditable && !onColorChange) {
    console.warn(
      'ClassesToAnnotateList: onColorChange is not defined but isEditable is true- (not allowing to edit color)'
    );
  }
  if (isSelectable && !selectProps) {
    console.warn('ClassesToAnnotateList: selectProps is not defined but isSelectable is true- (not showing checkbox)');
  }

  const allowSelect = isSelectable && Boolean(selectProps);
  const allSelected =
    allowSelect && every(classesToAnnotate, (classToAnnotate) => selectProps.isTodoSelected(classToAnnotate.name));
  const someSelected =
    allowSelect &&
    !allSelected &&
    some(classesToAnnotate, (classToAnnotate) => selectProps.isTodoSelected(classToAnnotate.name));

  const toggleSelection = selectProps
    ? (ids: string[], checked: boolean) => {
        const newSelectedClassesToAnnotate = selectProps.selectedClassesToAnnotate || [];
        forEach(ids, (id) => {
          const idSelected = includes(map(selectProps.selectedClassesToAnnotate, 'name'), id);
          if (checked) {
            if (!idSelected) newSelectedClassesToAnnotate.push(find(classesToAnnotate, { name: id }));
          } else {
            newSelectedClassesToAnnotate.splice(
              newSelectedClassesToAnnotate.indexOf(find(newSelectedClassesToAnnotate, { name: id })),
              1
            );
          }
        });
        selectProps.onClassesSelected(newSelectedClassesToAnnotate);
      }
    : undefined;

  const checkAll = (checked: boolean) => {
    toggleSelection(map(classesToAnnotate, 'name'), checked);
  };

  return (
    <List>
      {!isEmpty(classesToAnnotate) && allowSelect && (
        <ListItem>
          <ListItemIcon sx={{ minWidth: '40px' }}>
            <Checkbox
              checked={allSelected}
              indeterminate={someSelected}
              onClick={() => {
                if (allSelected) {
                  checkAll(false);
                } else {
                  checkAll(true);
                }
              }}
            />
          </ListItemIcon>
          <ListItemText primary={'Select All'} />
        </ListItem>
      )}
      {map(classesToAnnotate, (classToAnnotate) => (
        <ClassToAnnotateListItem
          key={classToAnnotate.name}
          item={classToAnnotate}
          onDelete={isEditable && Boolean(onDeleteTodo) ? () => onDeleteTodo(classToAnnotate.name) : undefined}
          onColorChange={
            isEditable && Boolean(onColorChange)
              ? (newColor) => onColorChange(classToAnnotate.name, newColor)
              : undefined
          }
          isSelectable={allowSelect}
          isSelected={selectProps?.isTodoSelected && selectProps.isTodoSelected(classToAnnotate.name)}
          toggleSelection={toggleSelection ? (checked) => toggleSelection([classToAnnotate.name], checked) : undefined}
          isEditable={isEditable}
        />
      ))}
    </List>
  );
};

interface ClassToAnnotateListItemProps {
  item: TodoOption;
  onColorChange?: (color: string) => void;
  onDelete?: () => void;
  isSelectable?: boolean;
  isSelected?: boolean;
  toggleSelection?: (checked: boolean) => void;
  isEditable?: boolean;
}

const ClassToAnnotateListItem: React.FC<ClassToAnnotateListItemProps> = ({
  item,
  onColorChange,
  onDelete,
  isSelectable,
  isSelected,
  toggleSelection,
  isEditable,
}) => {
  const { className, color, displayName, name } = item;

  const handleColorChange = (newColor: string) => {
    onColorChange(newColor);
  };

  return (
    <ListItem
      secondaryAction={
        isEditable && (
          <IconButton edge="end" onClick={() => onDelete()}>
            <CloseIcon />
          </IconButton>
        )
      }
    >
      {isSelectable && Boolean(toggleSelection) && (
        <ListItemIcon sx={{ minWidth: '40px' }}>
          <Checkbox checked={isSelected} onChange={(e) => toggleSelection(e.target.checked)} />
        </ListItemIcon>
      )}

      {/* the default minWidth is 56px witch is too big for this case */}
      <ListItemIcon sx={{ minWidth: '40px' }}>
        <ColorPicker
          disableEditing={!isEditable || !handleColorChange}
          currentColor={color}
          onColorChange={handleColorChange}
        />
      </ListItemIcon>
      <ListItemIcon sx={{ minWidth: '40px' }}>
        {className === 'polygon' ? (
          name.includes('roi') ? (
            <BorderStyleIcon />
          ) : (
            <PentagonOutlinedIcon />
          )
        ) : (
          <AdjustIcon />
        )}
      </ListItemIcon>
      <ListItemText
        primary={displayName ?? name}
        primaryTypographyProps={{
          variant: 'subtitle2',
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        secondary={`${map(item.markerPositivity, (value, key) => `${key}-${value}`).join(', ')}`}
      />
    </ListItem>
  );
};

export const ClassesToAnnotateListSkeleton: React.FC = () => {
  return (
    <List>
      {Array.from({ length: 5 }).map((_, index) => (
        <ListItem key={index}>
          <ListItemIcon sx={{ minWidth: '40px' }}>
            <Skeleton variant="rounded" width={15} height={15} />
          </ListItemIcon>
          <ListItemIcon sx={{ minWidth: '40px' }}>
            <Skeleton variant="rounded" width={15} height={15} />
          </ListItemIcon>
          <Skeleton variant="text" width={300} />
        </ListItem>
      ))}
    </List>
  );
};

export default ClassesToAnnotateList;
