import { useQuery } from '@tanstack/react-query';
import { getFormSource } from 'api/sources';
import { FieldSourceId } from 'interfaces/reviewForm';

export const useFormSource = (sourceId: FieldSourceId) => {
  return useQuery({
    queryKey: ['formSource', { sourceId }],
    queryFn: ({ signal }) => getFormSource(sourceId, signal).then((response) => response.source),
  });
};
