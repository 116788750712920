import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useMutation } from '@tanstack/react-query';
import { createCustomerResults } from 'api/customerResults';
import { includes, isEmpty, join, map } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import queryClient from 'utils/queryClient';

interface Props {
  disabled?: boolean;
  studyId: string;
}

const resultTypeOptions = ['nuclear_segmentations_table', 'cells_table', 'areas_table'];

const PrepareCustomerResultsForDownload: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  disabled = false,
  studyId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const createCustomerResultsMutation = useMutation(createCustomerResults, {
    onError: () => {
      enqueueSnackbar("Error occurred, Prepare Customer Results can't start", { variant: 'error' });
    },
    onSuccess: () => {
      enqueueSnackbar('Start preparing customer results', { variant: 'success' });
      queryClient.invalidateQueries(['jobs']);
    },
  });

  const [resultTypesToExport, setResultTypesToExport] = useState<string[]>([]);
  const handleFilesToExportChange = (event: SelectChangeEvent<string[]>) => {
    if (!Array.isArray(event.target.value)) {
      setResultTypesToExport([event.target.value]);
      return;
    }
    setResultTypesToExport(event.target.value);
  };

  const disablePrepareButton = createCustomerResultsMutation.isLoading || isEmpty(resultTypesToExport);

  return (
    <>
      <Tooltip title={disabled ? 'Select study' : 'Prepare customer approved results for download'} placement="top">
        <span>
          <Button
            size="small"
            color="primary"
            disabled={disabled}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setIsModalOpen(true);
            }}
          >
            Prepare customer results for download
          </Button>
        </span>
      </Tooltip>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth="md">
        <DialogTitle>Prepare Customer Results</DialogTitle>
        <DialogContent dividers>
          <FilesToExportSelect selectedFilesToExport={resultTypesToExport} onChange={handleFilesToExportChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              setIsModalOpen(false);
              createCustomerResultsMutation.mutate({ studyId, resultTypesToExport });
            }}
            disabled={disablePrepareButton}
          >
            Prepare
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const FilesToExportSelect = ({
  selectedFilesToExport,
  onChange,
}: {
  selectedFilesToExport: string[];
  onChange: (event: SelectChangeEvent<string[]>) => void;
}) => {
  const areAllSelected =
    selectedFilesToExport && resultTypeOptions && selectedFilesToExport.length === resultTypeOptions.length;
  const areSomeSelected = !isEmpty(selectedFilesToExport) && selectedFilesToExport.length < resultTypeOptions.length;

  return (
    <>
      <InputLabel>Files to export</InputLabel>
      <Select
        multiple
        value={selectedFilesToExport}
        onChange={(event) => {
          if (includes(event.target.value, 'ALL')) {
            onChange({ target: { value: areAllSelected ? [] : resultTypeOptions } } as SelectChangeEvent<string[]>);
            return;
          }
          onChange(event);
        }}
        renderValue={(selected) => join(selected as string[], ', ')}
      >
        <MenuItem value="ALL">
          <Checkbox checked={areAllSelected} indeterminate={areSomeSelected} />
          <ListItemText primary={'All'} />
        </MenuItem>
        {map(resultTypeOptions, (option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={includes(selectedFilesToExport, option)} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default PrepareCustomerResultsForDownload;
