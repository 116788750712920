import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { Button, Grow, Menu, MenuList, Paper, Popper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { addSlidesToAnnotationAssignment } from 'api/annotationAssignments';
import { getStainTypeFilteredIds } from 'api/stainTypes';
import CalculateFeaturesMenuItem from 'components/Pages/CalculateFeatures/CalculateFeaturesMenuItem';
import useMainFilters from 'components/SearchFilters/hooks/useMainFilters';
import SaveCohortModal from 'components/SearchFilters/SaveCohortModal';
import { CreateAnnotationAssignmentDialog } from 'components/StudyDashboard/AnnotationAssignment/CreateAnnotationAssignmentDialog';
import { ActionMenuItem } from 'components/StudyDashboard/ProceduresPage/Actions/ActionMenuItem';
import { ActionModal } from 'components/StudyDashboard/ProceduresPage/Actions/ActionModal';
import ApproveResultsForm from 'components/StudyDashboard/ProceduresPage/Actions/ApproveResults/ApproveResultsForm';
import AssignGenericClustersForm from 'components/StudyDashboard/ProceduresPage/Actions/AssignGenericClustersForm';
import { CohortQueryObject } from 'interfaces/cohort';
import { JobType } from 'interfaces/job';
import { Permission } from 'interfaces/permissionOption';
import { MULTIPLEX_STAIN_ID } from 'interfaces/stainType';
import { includes, isEmpty, map, omitBy } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { stringify } from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';
import queryClient from 'utils/queryClient';
import { useAnnotationAssignments } from 'utils/useAnnotationAssignments';
import { useCasesParams } from 'utils/useCasesParams';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { usePermissions } from 'utils/usePermissions';
import { useRowSelectionContext } from '../atoms/RowSelectionContext';
import JobModal from './JobsModal';

export enum ActionType {
  ApproveResults = 'approve_results',
  AssignGenericClusters = 'assign_generic_clusters',
  CreateCohort = 'create_cohort',
  CreateAssignment = 'create_assignment',
  AddToAssignment = 'add_to_assignment',
  CalculateFeatures = 'calculate_features',
  Inference = 'inference',
  MultiplexCellSegmentation = 'multiplex_cell_segmentation',
  MultiplexThreshold = 'multiplex_threshold_binary_classification',
  MultiplexHistogram = 'multiplex_histogram_creation',
  MultiplexNormalization = 'multiplex_normalization',
  MultiplexBinaryClassifier = 'multiplex_binary_classification',
  MultiplexCellTyping = 'multiplex_cell_typing',
}

const addToAssignmentActionIndex = 3;

const assignmentsLoadingSubItems = [
  {
    type: ActionType.AddToAssignment,
    header: 'Loading annotation assignments...',
    disabled: true,
  },
];

const assignmentsErrorSubItems = [
  {
    type: ActionType.AddToAssignment,
    header: 'Error loading annotation assignments',
    disabled: true,
  },
];

interface ActionItem {
  type: ActionType;
  header: string;
  hide?: boolean; // don't show option at all
  disabled?: boolean; // show option but disabled
  jobType?: JobType;
  customMenuItem?: React.ReactNode;
  subMenuItems?: ActionItem[];
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

interface Props {
  totalRows: number;
  disablePortal?: boolean;
}

const ActionsMenu: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ totalRows, disablePortal = true }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
      setSubmenuAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const [currentActionItem, setCurrentActionItem] = useState<ActionItem>(null);

  const handleSubmenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setSubmenuAnchorEl(event.currentTarget);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { labId } = useCurrentLabId();
  const [filters] = useQueryParam('filters', JsonParam);
  const { isStudyIdSpecific } = useMainFilters();
  const studyId = filters?.studyId;
  const studyIdIsSpecific = isStudyIdSpecific(studyId);
  const encodedParamsForStudy = stringify({ labId, filters: JSON.stringify({ studyId }) });
  const { data: stains } = useQuery({
    queryKey: ['slidesStainsTypes', encodedParamsForStudy],
    queryFn: ({ signal }) => getStainTypeFilteredIds(encodedParamsForStudy, signal),
  });

  const {
    data: annotationAssignments,
    isLoading: isLoadingAssignments,
    isError: isErrorAssignments,
    queryKey: annotationAssignmentsQueryKey,
  } = useAnnotationAssignments(studyId);

  const { casesParams } = useCasesParams();

  const { hasPermission } = usePermissions();
  const canApproveResults = hasPermission(Permission.PublishResults);
  const canAssignGenericClusters = hasPermission(Permission.AssignGenericClusters) && studyIdIsSpecific;
  const canRunInference = hasPermission(Permission.RunInference);
  const canRunCalculateFeatures = hasPermission(Permission.RunCalculateFeatures);
  const canRunMultiplexCellSegmentation = hasPermission(Permission.RunMultiplexCellSegmentation);
  const canRunMultiplexBinaryClassifier = hasPermission(Permission.ExecuteMultiplexBinaryClassifier);
  const canRunMultiplexNormalization = hasPermission(Permission.ExecuteMultiplexNormalization);
  const canRunMultiplexHistogram = hasPermission(Permission.ExecuteMultiplexHistogram);
  const canRunMultiplexThreshold = hasPermission(Permission.ExecuteMultiplexThreshold);
  const canRunMultiplexCellTyping = hasPermission(Permission.ExecuteMultiplexCellTyping);
  const canCreateAnnotationAssignment = hasPermission(Permission.CreateAnnotationAssignment);
  const isMultiplex = includes(stains, MULTIPLEX_STAIN_ID);

  const { noneSelected } = useRowSelectionContext();
  const noRows = totalRows === 0;
  const disableRowOperations = noRows || noneSelected(totalRows);

  const addSlidesToAssignmentMutation = useMutationWithErrorSnackbar({
    mutationFn: addSlidesToAnnotationAssignment,
    mutationDescription: 'add slides to annotation assignment',
    onSuccess: (data: any) => {
      enqueueSnackbar(data?.message || 'Slides added to assignment', { variant: 'success' });
      queryClient.invalidateQueries(annotationAssignmentsQueryKey);
      queryClient.invalidateQueries(['annotations']);
    },
  });

  const addToAssignmentSubItems = useMemo(
    () =>
      isLoadingAssignments
        ? assignmentsLoadingSubItems
        : isErrorAssignments
        ? assignmentsErrorSubItems
        : map(annotationAssignments, (assignment) => ({
            type: ActionType.AddToAssignment,
            header: `${assignment.name}-${assignment.createdAt}`,
            enabled: canCreateAnnotationAssignment,
            onClick: () => {
              setCurrentActionItem(null);
              handleMenuClose();
              handleSubmenuClose();
              addSlidesToAssignmentMutation.mutate({
                annotationAssignmentId: assignment.annotationAssignmentId,
                casesParams,
              });
            },
          })),
    [isLoadingAssignments, isErrorAssignments, annotationAssignments, canCreateAnnotationAssignment]
  );

  const actionItems: ActionItem[] = useMemo(() => {
    return [
      { type: ActionType.ApproveResults, header: 'Approve Results', hide: !canApproveResults },
      { type: ActionType.AssignGenericClusters, header: 'Assign Generic Clusters', hide: !canAssignGenericClusters },
      { type: ActionType.CreateCohort, header: 'Create Cohort' },
      { type: ActionType.CreateAssignment, header: 'Create Assignment', hide: !canCreateAnnotationAssignment },
      {
        type: ActionType.AddToAssignment,
        header: 'Add To Existing Assignment',
        hide: !canCreateAnnotationAssignment,
        subMenuItems: addToAssignmentSubItems,
      },
      { type: ActionType.Inference, header: 'Run Inference', hide: !canRunInference, jobType: JobType.Inference },
      {
        type: ActionType.CalculateFeatures,
        header: 'Run Calculate Features',
        hide: !canRunCalculateFeatures,
        jobType: JobType.CalculateFeatures,
        customMenuItem: <CalculateFeaturesMenuItem disabled={!canRunCalculateFeatures} />,
      },
      {
        type: ActionType.MultiplexCellSegmentation,
        header: 'Run Multiplex Cell Segmentation',
        hide: !(canRunMultiplexCellSegmentation && isMultiplex),
        jobType: JobType.MultiplexCellSegmentation,
      },
      {
        type: ActionType.MultiplexBinaryClassifier,
        header: 'Run Multiplex Binary Classifier',
        hide: !(canRunMultiplexBinaryClassifier && isMultiplex),
        jobType: JobType.BinaryClassifier,
      },
      {
        type: ActionType.MultiplexHistogram,
        header: 'Run Multiplex Histogram',
        hide: !(canRunMultiplexHistogram && isMultiplex),
        jobType: JobType.MultiplexHistogram,
      },
      {
        type: ActionType.MultiplexNormalization,
        header: 'Run Multiplex Normalization',
        hide: !(canRunMultiplexNormalization && isMultiplex),
        jobType: JobType.MultiplexNormalization,
      },
      {
        type: ActionType.MultiplexThreshold,
        header: 'Run Multiplex Threshold',
        hide: !(canRunMultiplexThreshold && isMultiplex),
        jobType: JobType.MultiplexThreshold,
      },
      {
        type: ActionType.MultiplexCellTyping,
        header: 'Run Multiplex Cell Typing',
        hide: !(canRunMultiplexCellTyping && isMultiplex),
        jobType: JobType.MultiplexCellTyping,
      },
    ];
  }, [
    canApproveResults,
    canCreateAnnotationAssignment,
    canRunCalculateFeatures,
    canRunInference,
    canRunMultiplexBinaryClassifier,
    canRunMultiplexCellSegmentation,
    canRunMultiplexCellTyping,
    canRunMultiplexHistogram,
    canRunMultiplexNormalization,
    canRunMultiplexThreshold,
    isMultiplex,
    addToAssignmentSubItems,
  ]);

  // if the user chose the add to assignment option before the assignments were loaded, we need to update the current action item
  // with the loaded assignments once they are fetched
  useEffect(() => {
    if (currentActionItem?.type === ActionType.AddToAssignment) {
      setCurrentActionItem(actionItems[addToAssignmentActionIndex]);
    }
  }, [annotationAssignments]);

  const onCloseModal = () => {
    setCurrentActionItem(null);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, actionItem: ActionItem) => {
    setCurrentActionItem(actionItem);
    if (actionItem.subMenuItems) {
      handleSubmenuOpen(event);
      return;
    }

    handleMenuClose();
  };

  const { queryParams, convertToQueryObject } = useEncodedFilters();
  const cohortQueryObject = useMemo(() => convertToQueryObject(queryParams), [queryParams]);
  const cohortQueryObjectWithSelection: CohortQueryObject = {
    ...cohortQueryObject,
    fromSelection: true,
    slidesMode: casesParams.slidesMode,
    ...omitBy(
      {
        caseIdsToInclude: casesParams.caseIdsToInclude,
        caseIdsToExclude: casesParams.caseIdsToExclude,
        slideIdsToInclude: casesParams.slideIdsToInclude,
        slideIdsToExclude: casesParams.slideIdsToExclude,
      },
      isEmpty
    ),
  };

  return (
    <div>
      <Button color="secondary" onClick={handleClick} endIcon={<AddToPhotosIcon />} data-cy="actions-menu-button">
        Actions
      </Button>

      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal={disablePortal}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left bottom',
            }}
          >
            <Paper>
              <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                {map(actionItems, (menuItem) => {
                  return (
                    !menuItem.hide &&
                    (menuItem.customMenuItem || (
                      <ActionMenuItem
                        key={menuItem.header}
                        disabled={disableRowOperations || menuItem.disabled}
                        header={menuItem.header}
                        onClick={(event) => {
                          if (menuItem.onClick) {
                            menuItem.onClick(event);
                            return;
                          }
                          handleMenuItemClick(event, menuItem);
                        }}
                      />
                    ))
                  );
                })}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Menu
        anchorEl={submenuAnchorEl}
        open={Boolean(submenuAnchorEl)}
        onClose={handleSubmenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            style: {
              maxHeight: 400,
              width: 500,
            },
          },
        }}
      >
        {map(currentActionItem?.subMenuItems, (subMenuItem) => {
          return (
            !subMenuItem.hide &&
            (subMenuItem.customMenuItem || (
              <ActionMenuItem
                key={subMenuItem.header}
                disabled={disableRowOperations || subMenuItem.disabled}
                header={subMenuItem.header}
                onClick={(event) => {
                  if (subMenuItem.onClick) {
                    subMenuItem.onClick(event);
                    return;
                  }
                  handleMenuItemClick(event, subMenuItem);
                }}
              />
            ))
          );
        })}
      </Menu>
      {currentActionItem &&
        (currentActionItem.jobType ? (
          <JobModal casesParams={casesParams} jobType={currentActionItem.jobType} onClose={onCloseModal} />
        ) : currentActionItem.type === ActionType.ApproveResults ? (
          <ActionModal header="Approve Results" onClose={onCloseModal}>
            <ApproveResultsForm onClose={onCloseModal} />
          </ActionModal>
        ) : currentActionItem.type === ActionType.AssignGenericClusters ? (
          <ActionModal header="Assign Generic Clusters" onClose={onCloseModal}>
            <AssignGenericClustersForm onClose={onCloseModal} />
          </ActionModal>
        ) : currentActionItem.type === ActionType.CreateCohort ? (
          <SaveCohortModal
            isOpen={true}
            onClose={onCloseModal}
            queryObject={cohortQueryObjectWithSelection}
            onlyCohort
          />
        ) : currentActionItem.type === ActionType.CreateAssignment ? (
          <CreateAnnotationAssignmentDialog open onClose={onCloseModal} />
        ) : null)}
    </div>
  );
};

export default ActionsMenu;
