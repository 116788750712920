import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { FunctionComponent } from 'react';

interface AddRowButtonProps {
  handleAdd: React.MouseEventHandler<HTMLButtonElement>;
  addText: string;
}

const AddRowButton: FunctionComponent<React.PropsWithChildren<AddRowButtonProps>> = ({ handleAdd, addText }) => {
  return (
    <Button onClick={handleAdd} color="secondary" startIcon={<Add />} variant="contained">
      {addText}
    </Button>
  );
};

export default AddRowButton;
