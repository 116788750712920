import { find } from 'lodash';
import React from 'react';

import { RowsChangesSummary } from 'components/atoms/EditableDataGrid/TableEditingContext/ChangesSummary';
import { UserCredentials } from 'interfaces/userCredentials';
import { userCredentialsFields } from 'interfaces/userCredentials/userCredentialsFields';
import { UserCredentialsFieldsContext } from 'interfaces/userCredentials/UserCredentialsFieldsContext';

export const UserCredentialsRowChangesSummary: React.FC<
  React.PropsWithChildren<{
    userDisplayString?: string;
    changes: Partial<UserCredentials>;
    fieldsContext: UserCredentialsFieldsContext;
  }>
> = ({ changes, userDisplayString, fieldsContext }) => {
  return (
    <RowsChangesSummary
      rowsChanges={{ changes }}
      rowToDisplayString={() => userDisplayString || changes.primaryEmail}
      fieldToDisplayString={(fieldId) => find(userCredentialsFields, (f) => f.dataKey === fieldId)?.label || fieldId}
      valueToDisplayString={(value, fieldId) => {
        const field = find(userCredentialsFields, (f) => f.dataKey === fieldId);
        return field?.valueFormatter?.({ value }, fieldsContext) ?? `${value}`;
      }}
    />
  );
};
