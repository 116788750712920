import { Drawer, useTheme } from '@mui/material';
import { includes } from 'lodash';
import React, { useCallback } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import AssignmentList from 'components/AssignmentsList';
import { SITE_HEADER_NUMBER } from 'components/SiteHeader/SiteHeader';
import { annotationsDrawerWidth } from '../atoms/FlexContainers/FlexContainers';

export const MainPageAnnotationDrawer = () => {
  const theme = useTheme();
  const [annotationsListVisibility, setAnnotationsDrawerVisibility] = useQueryParam(
    'annotationsListVisibility',
    StringParam
  );
  const visible = includes(['open', 'pinned'], annotationsListVisibility);
  const pinned = annotationsListVisibility === 'pinned';

  const togglePinned = useCallback(() => {
    setAnnotationsDrawerVisibility(!pinned ? 'pinned' : 'open');
  }, [setAnnotationsDrawerVisibility, pinned]);

  return (
    <Drawer
      anchor="right"
      open={visible}
      onClose={() => setAnnotationsDrawerVisibility('')}
      variant={pinned ? 'persistent' : 'temporary'}
      sx={{
        width: annotationsDrawerWidth,
        [`& .MuiDrawer-paper`]: {
          width: annotationsDrawerWidth,
          backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[50],
          zIndex: pinned ? theme.zIndex.appBar - 1 : 'auto',
          height: pinned ? `calc(100% - ${SITE_HEADER_NUMBER}px)` : '100%',
          marginTop: pinned ? `${SITE_HEADER_NUMBER}px` : '0px',
        },
      }}
    >
      <AssignmentList pinned={pinned} togglePinned={togglePinned} />
    </Drawer>
  );
};
