import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { Badge, Button, Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Permission } from 'interfaces/permissionOption';
import { logout } from 'redux/actions/auth';
import { usePermissions } from 'utils/usePermissions';
import { AssignmentListToggle } from './AssignmentListToggle';
import LabDropdown from './LabDropdown';
import ResultsModeDropdown from './ResultsModeDropdown';
import { UserMenu } from './UserMenu';

interface Props {
  name: string;
  onLogout(): void;
}

const ControlPanel: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ name, onLogout }) => {
  const navigate = useNavigate();
  const { hasPermission, hasSomePermissions } = usePermissions();
  const canViewMultipleLabs = hasPermission(Permission.ApplyPermissionsAcrossLabs);
  const isAccessionViewer = hasPermission(Permission.ViewAccessionDashboard);
  const showAdminLabel = hasSomePermissions([
    Permission.ManageNucleaiUsers,
    Permission.DefineRolesToLabUsers,
    Permission.AppointAdminsPerLab,
  ]);
  const showNucleaiUserBadge = hasPermission(Permission.UseNucleaiExperimentalFeatures);
  const Welcome = (
    <Typography data-test-class="user-name" variant="body1" noWrap>
      Hello, {name} &nbsp;
    </Typography>
  );

  const canViewAnnotationsList = hasPermission(Permission.ViewAnnotationsList);

  return (
    <Grid item xs={10} container spacing={1} alignItems="center" justifyContent="end" wrap="nowrap">
      {!isAccessionViewer && (
        <Grid item xs={4} container alignItems="center" justifyContent="end" wrap="nowrap">
          {hasPermission(Permission.ViewUnpublishedResults) && (
            <Grid item>
              <ResultsModeDropdown />
            </Grid>
          )}
          {canViewAnnotationsList && (
            <Grid item>
              <AssignmentListToggle />
            </Grid>
          )}
          {hasSomePermissions([
            Permission.EditStainTypeOptions,
            Permission.EditExternalLabelOptions,
            Permission.EditSlideTagOptions,
            Permission.EditAreaTypeOptions,
            Permission.EditClusterTypeOptions,
          ]) && (
            <Grid item>
              <IconButton color="inherit" onClick={() => navigate('/admin/operations-settings')}>
                <SettingsIcon />
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <IconButton color="inherit">
              <NotificationsNoneOutlinedIcon data-testid="bell-icon" />
            </IconButton>
          </Grid>
          <UserMenu />
        </Grid>
      )}
      <Grid item>
        {showAdminLabel ? (
          <Badge badgeContent={'A'} color="secondary">
            {Welcome}
          </Badge>
        ) : showNucleaiUserBadge ? (
          <Badge badgeContent={'N'} color="secondary">
            {Welcome}
          </Badge>
        ) : (
          Welcome
        )}
      </Grid>

      {canViewMultipleLabs && (
        <Grid item marginLeft={showAdminLabel ? 1 : 0}>
          <LabDropdown />
        </Grid>
      )}
      <Grid item>
        <Button color="inherit" onClick={onLogout} data-test-class="logout" sx={{ cursor: 'pointer' }}>
          Logout
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  name: state.auth.profile && state.auth.profile.name,
});

const mapDispatchToProps = (dispatch: any) => ({
  onLogout: () => {
    dispatch(logout());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ControlPanel);
