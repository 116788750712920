import { Chart, ChartDataset, Title } from 'chart.js';
import { PartialCohort } from 'interfaces/cohort_old';
import { filter, isNumber, max, min, range, reduce } from 'lodash';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartKey, cohortToValues, CountBy, isCategoricalChartKey } from '../chart.util';
import CategoricalHistogramChart from './CategoricalHistogram';
import { barChartOptions, cohortsToHistogramDataset } from './histogram.util';

Chart.register(Title);
Chart.defaults.elements.bar.borderWidth = 1.5;

interface Props {
  cohorts: PartialCohort[];
  horizontalKey?: ChartKey;
  countBy?: CountBy;
  bucketCount?: number;
}
const HistogramChart: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  cohorts,
  horizontalKey,
  countBy,
  bucketCount,
}) => {
  return isCategoricalChartKey(horizontalKey) ? (
    <CategoricalHistogramChart cohorts={cohorts} horizontalKey={horizontalKey} countBy={countBy} />
  ) : (
    <NumericalHistogramChart cohorts={cohorts} horizontalKey={horizontalKey} bucketCount={bucketCount} />
  );
};

const NumericalHistogramChart: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  cohorts,
  horizontalKey,
  bucketCount = 10,
}) => {
  const dataFromCohort = cohortToValues(horizontalKey?.name);

  const rangeOfValues: [number, number] = reduce(
    cohorts,
    (acc, cohort) => {
      // Filter out distance based features if they fell through the cracks
      const values = filter(dataFromCohort(cohort), (v): v is number => isNumber(v));
      const [currentMin, currentMax] = acc;
      const newMin = min([currentMin, ...values]);
      const newMax = max([currentMax, ...values]);

      return [newMin, newMax];
    },
    [Infinity, -Infinity]
  );

  const labels = range(0, bucketCount);

  const datasets = cohortsToHistogramDataset(
    rangeOfValues,
    horizontalKey?.name,
    bucketCount
  )(cohorts) as ChartDataset<'bar'>[];

  const data = { labels, datasets };

  return (
    <Bar style={{ minHeight: 250, maxHeight: 300 }} data={data} options={barChartOptions(rangeOfValues, bucketCount)} />
  );
};

export default HistogramChart;
