import { compact, flatMap, join, map, reduceRight, split, uniq } from 'lodash';
import { matchSorter, MatchSorterOptions } from 'match-sorter';
import { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

function filterSearchOptionsForPart<T>(
  keys: string[],
  options: T[],
  part: string,
  threshold: MatchSorterOptions['threshold'] = matchSorter.rankings.WORD_STARTS_WITH
) {
  const terms = compact(split(part, ' '));

  const filteredOptions = reduceRight(
    terms,
    (optionsLeft, term) => {
      return matchSorter(optionsLeft, term, {
        keys,
        threshold,
      });
    },
    options
  );

  return filteredOptions;
}

const whiteSpacesExceptSpaceRegex = /[^\S ]/;

export function filterSearchOptions<T>(
  keys: string[],
  options: T[],
  input: string,
  threshold?: MatchSorterOptions['threshold']
) {
  if (!input) return [];

  const parts = compact(flatMap(split(input, ','), (part) => split(part, whiteSpacesExceptSpaceRegex)));
  const filteredParts = flatMap(map(parts, (part) => filterSearchOptionsForPart(keys, options, part, threshold)));

  const filteredOptions = uniq(filteredParts);

  return filteredOptions;
}

interface SearchFilteringOptions {
  debounce?: number;
  initialSearch?: string;
  threshold?: MatchSorterOptions['threshold'];
}

export function useSearchFiltering<T>(
  keys: string[],
  options: T[],
  { debounce = 50, initialSearch = '', threshold }: SearchFilteringOptions = {}
) {
  const [localSearch, setLocalSearch] = useState(initialSearch);

  const [debouncedLocalSearch, { isPending }] = useDebounce(localSearch, debounce);

  const filteredOptions = useMemo(
    () => filterSearchOptions(keys, options, debouncedLocalSearch, threshold),
    [join(keys, ','), options, debouncedLocalSearch]
  );

  return {
    filteredOptions: localSearch ? filteredOptions : options,
    localSearch,
    setLocalSearch,
    noResults: debouncedLocalSearch && !filteredOptions.length,
    isLoading: isPending(),
  };
}
