import { Button, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import { FormResponse, ResponseContext, ReviewForm } from 'interfaces/reviewForm';
import { isEqual, map, omit } from 'lodash';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useCreateFormResponse, useExistingFormResponse } from 'utils/queryHooks/form/useFormResponse';
import ResponseSummary from './ResponseSummary';
import ReviewFormField from './ReviewFormField';
import { typeToName } from './form.util';

interface ReviewPanelProps {
  form: ReviewForm;
  context: ResponseContext;
  isLoadingCaseData?: boolean;
}

const ReviewPanel: FunctionComponent<React.PropsWithChildren<ReviewPanelProps>> = ({
  form,
  context,
  isLoadingCaseData,
}) => {
  const responseContext = useMemo(
    () => (form.isSlideSpecific ? context : omit(context, 'slideId')),
    [context, form.isSlideSpecific]
  );

  const theme = useTheme();

  const { data: existingResponse } = useExistingFormResponse(form.id, responseContext, { enabled: !isLoadingCaseData });

  const [currentResponse, setCurrentResponse] = React.useState<FormResponse | null>(null);

  useEffect(() => {
    if (currentResponse) {
      setCurrentResponse(null);
      console.info('Response context changed, clearing current non-saved response');
    }
  }, [responseContext, form.id]);

  const response = currentResponse ?? existingResponse;

  const createResponse = useCreateFormResponse();

  const handleResponseChange = (fieldId: string, value: any) => {
    setCurrentResponse((previousCurrentResponse) => {
      const previousResponse = previousCurrentResponse ?? existingResponse;
      return {
        ...previousResponse,
        filledFields: {
          ...previousResponse?.filledFields,
          [fieldId]: value,
        },
      };
    });
  };

  const submitResponse = async () => {
    await createResponse.mutateAsync({
      formId: form.id,
      context: responseContext,
      response: currentResponse,
    });
    setCurrentResponse(null);
  };

  return (
    <Grid container direction="column" justifyContent="space-between" height="100%">
      <Grid item container direction="column" xs={10} spacing={2} justifyContent="flex-start" wrap="nowrap">
        <Grid item>
          <Typography variant="h3">{typeToName(form.type)}</Typography>
        </Grid>
        {map(form.fields, (field) => (
          <Grid item key={field.id}>
            <ReviewFormField field={field} response={response} handleResponseChange={handleResponseChange} />
          </Grid>
        ))}
        <Grid container item direction="column" sx={{
          position: "sticky",
          bottom: 0,
          backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[50],
          boxShadow: '0px -5px 5px -5px rgba(0, 0, 0, 0.2)',
        }}>
          <Grid item mr={0} ml="auto" mb={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={submitResponse}
              disabled={!currentResponse || createResponse.isLoading || isEqual(currentResponse, existingResponse)}
              endIcon={createResponse.isLoading ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {existingResponse ? 'Update' : 'Submit'}
            </Button>
          </Grid>
          <Grid item xs>
            {existingResponse && <ResponseSummary response={existingResponse} />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReviewPanel;
