import { DataGridProps, GridRowModes, GridRowModesModel, useGridApiRef } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { isEmpty, map } from 'lodash';
import React, { useCallback } from 'react';

import { getStainTypeOptions } from 'api/stainTypes';
import { SettingsDataGrid } from 'components/atoms/BaseDataGrid/SettingsDataGrid';
import {
  generateGetCellClassNames,
  handleRowModesModelChangeWithoutDraftIds,
} from 'components/atoms/EditableDataGrid/helpers';
import Loader from 'components/Loader';

import { StainType } from 'interfaces/stainType';
import { stainTypeFields } from 'interfaces/stainType/stainTypeFields';

import { StainTypeDraft } from './types';
import { useStainTypesColumns } from './useStainTypesColumns';
import { generateDraftId, getStainTypeId } from './utils';

export const StainTypesDataGrid = () => {
  const { data: dbStainTypes, isLoading: isLoadingStainTypes } = useQuery(['stainTypes'], () => getStainTypeOptions());
  const [draftStainTypes, setDraftStainTypes] = React.useState<StainTypeDraft[]>([]);
  const stainTypes = React.useMemo(
    () => [...draftStainTypes, ...(dbStainTypes || [])],
    [dbStainTypes, draftStainTypes]
  );
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const handleAddStainType = useCallback(() => {
    // We use the draftId field to distinguish between draft rows and db rows
    // This is necessary because we need to be able to edit ids in new rows but not in existing rows
    const draftId = generateDraftId();
    const newStainType: StainTypeDraft = {
      displayName: '',
      id: '',
      canBeMifMarker: false,
      draftId,
      deletedAt: null,
      aliases: [],
    };
    setDraftStainTypes((oldDraftStainTypes) => [newStainType, ...oldDraftStainTypes]);
    setRowModesModel((oldRowModesModel) => ({
      ...oldRowModesModel,
      [newStainType.draftId]: { mode: GridRowModes.Edit },
    }));
  }, [setDraftStainTypes, setRowModesModel]);

  const apiRef = useGridApiRef();

  const handleRowModesModelChange: DataGridProps<StainType>['onRowModesModelChange'] = React.useCallback(
    (newRowModesModel: GridRowModesModel) => {
      handleRowModesModelChangeWithoutDraftIds(
        newRowModesModel,
        setRowModesModel,
        draftStainTypes,
        (row: StainTypeDraft) => row.draftId
      );
    },
    [draftStainTypes]
  );

  const columns = useStainTypesColumns({
    noRows: isEmpty(stainTypes),
    apiRef,
    draftStainTypes,
    rowModesModel,
    stainTypes: dbStainTypes,
    setDraftStainTypes,
    setRowModesModel,
  });

  const getStainTypeCellClassName: DataGridProps['getCellClassName'] = React.useMemo(
    () =>
      generateGetCellClassNames<StainType | StainTypeDraft>({
        apiRef,
        requiredFields: ['id', 'displayName', 'canBeMifMarker'],
        uniqueFieldGroups: [['id'], ['displayName']],
        draftRows: draftStainTypes,
        fieldsToCheckForErrors: stainTypeFields,
        idGetter: getStainTypeId,
        context: {
          stainTypes: map(
            stainTypes,
            (stainType) =>
              apiRef?.current?.getRowWithUpdatedValues?.(getStainTypeId(stainType), '') as StainType | StainTypeDraft
          ),
        },
      }),
    [apiRef, draftStainTypes]
  );

  return !isLoadingStainTypes ? (
    <SettingsDataGrid
      apiRef={apiRef}
      addText="Add Stain Type"
      handleAdd={handleAddStainType}
      getCellClassName={getStainTypeCellClassName}
      rows={stainTypes}
      columns={columns}
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      getRowId={getStainTypeId}
    />
  ) : (
    <Loader />
  );
};
