import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Chip, Grid, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { groupBy, isEmpty, orderBy } from 'lodash';
import React, { useMemo } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { ProtomapTree } from './Heatmaps/ProtomapTileControl';
import { RasterHeatmapTree } from './Heatmaps/RasterHeatmaps';
import { useSlideResultsExpandedState } from './useSlideResultsExpandedState';

interface Props {
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  title: string;
  heatmaps: FeatureMetadata[];
  filterText: string;
  hideOrchestrationId?: boolean;
}

const InternalHeatmaps: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  slideId,
  viewerIndex,
  stainTypeId,
  title,
  heatmaps,
  filterText,
  hideOrchestrationId,
}) => {
  useSignals();
  const [useDeckGL] = useQueryParam('useDeckGL', BooleanParam);
  const { isExpanded, setIsExpanded } = useSlideResultsExpandedState({
    title,
    viewerIndex,
    slideStainTypeId: stainTypeId,
  });

  const { dzi: dziHeatmaps, pmt: pmtHeatmaps } = useMemo(
    () => groupBy(orderBy(heatmaps, 'createdAt', 'desc'), 'heatmapType'),
    [heatmaps]
  );

  return (
    <Accordion
      square
      expanded={isExpanded}
      onChange={(event, newExpanded) => setIsExpanded(newExpanded)}
      slotProps={{ transition: { unmountOnExit: true } }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={6}>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          <Grid item>
            <Chip label="Internal Results" />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 1 }}>
        {!isEmpty(dziHeatmaps) && (
          <RasterHeatmapTree
            addOrchestrationIdToUrl
            hideOpacityWhenNotSelected
            slideId={slideId}
            viewerIndex={viewerIndex}
            stainTypeId={stainTypeId}
            heatmaps={dziHeatmaps}
            filterText={filterText}
            hideOrchestrationId={hideOrchestrationId}
          />
        )}
        {useDeckGL && !isEmpty(pmtHeatmaps) && (
          <ProtomapTree
            addOrchestrationIdToUrl
            pmtHeatmaps={pmtHeatmaps}
            slideId={slideId}
            viewerIndex={viewerIndex}
            filterText={filterText}
            stainTypeId={stainTypeId}
            hideOrchestrationId={hideOrchestrationId}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default InternalHeatmaps;
