export const modelTypesOptions = [
  {
    text: 'deepcell',
    value: 'deepcell',
  },
  {
    text: 'cellpose',
    value: 'cellpose',
  },
];
