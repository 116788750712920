import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import ControlPanel from 'components/SiteHeader/ControlPanel';
import { useColorMode } from '../ColorModeContext';
import SupportMenu from './SupportMenu';

import './SiteHeader.scss';

interface Props {
  isLoggedIn: boolean;
}

export const SITE_HEADER_NUMBER = 62;
export const SITE_HEADER_HEIGHT = `${SITE_HEADER_NUMBER}px`;

const SiteHeader: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ isLoggedIn }) => {
  const colorMode = useColorMode();
  const { mode, toggleColorMode } = colorMode;
  const theme = useTheme();

  return (
    <AppBar
      sx={{
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900],
        color: theme.palette.mode === 'light' ? theme.palette.grey[900] : theme.palette.grey[50],
        minHeight: SITE_HEADER_HEIGHT,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid container alignSelf="center" justifyContent="space-between" sx={{ px: 3 }}>
        <Grid item sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Grid item>
            <Link
              component={RouterLink}
              to={{
                pathname: '/',
              }}
              className="logo"
            >
              <img
                src={`static/logo_${mode == 'dark' ? 'white' : 'black'}.svg`}
                alt="nucleai logo"
                className="icon-logo"
              />
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={10} container alignItems="center" justifyContent="end">
          {isLoggedIn && <ControlPanel />}
          <Grid item>
            <SupportMenu />
          </Grid>
          <Grid item>
            <IconButton onClick={toggleColorMode} color="inherit" sx={{ paddingRight: 0 }}>
              {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

const mapStateToProps = (state: any) => ({
  isLoggedIn: Boolean(state.auth.accessToken),
});

export default connect(mapStateToProps)(SiteHeader);
