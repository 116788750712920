import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Grid, ListItem, ListItemIcon, TextField } from '@mui/material';
import { AutomaticCondition } from 'interfaces/automaticCondition';
import { InferredFeatureRule } from 'interfaces/inferredFeatures';
import { Study } from 'interfaces/study';
import React, { FunctionComponent } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import AutomaticConditionBuilder from '../../../atoms/ConditionBuilder/AutomaticConditionBuilder';

interface InferredFeatureRuleProps {
  rule: InferredFeatureRule;
  onChange: (newRule: InferredFeatureRule) => void;
  study: Study;
  onRemove: () => void;
  index: number;
  orchestrationId: string;
}

const InferredFeatureRuleSettings: FunctionComponent<React.PropsWithChildren<InferredFeatureRuleProps>> = ({
  rule,
  onChange,
  study,
  onRemove,
  index,
  orchestrationId,
}) => {
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange({ ...rule, name: e.target.value });
  const handleConditionChange = (condition: AutomaticCondition) => onChange({ ...rule, condition });

  return (
    <Draggable key={rule.id} draggableId={rule.id} index={index}>
      {(provided) => (
        <ListItem key={rule.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <ListItemIcon>
            <DragIndicatorIcon />
          </ListItemIcon>
          <Grid xs={10} item>
            <AutomaticConditionBuilder
              condition={rule.condition}
              onConditionChange={handleConditionChange}
              study={study}
              shouldShowErrors={false}
              editable
              orchestrationId={orchestrationId}
              onRemove={onRemove}
              featuresOnly
            />
          </Grid>
          <Grid xs={2} item>
            <TextField label="Name" placeholder="e.g Inflamed" value={rule.name} onChange={handleNameChange} />
          </Grid>
        </ListItem>
      )}
    </Draggable>
  );
};

export default InferredFeatureRuleSettings;
