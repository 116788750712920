import { GridRowId, GridRowModesModel } from '@mui/x-data-grid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { find, isEmpty, map } from 'lodash';
import React from 'react';

import { setUserCredentialsRolesAndPermissions } from 'api/userCredentials';
import { rowEditingControlsColumn } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { useEditableFieldsDataGridColumns } from 'components/atoms/EditableDataGrid/useEditableFieldsDataGridColumns';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { UserCredentials, UserCredentialsPaginatedResponse, UserSource } from 'interfaces/userCredentials';
import { userCredentialsFields } from 'interfaces/userCredentials/userCredentialsFields';
import { UserCredentialsFieldsContext } from 'interfaces/userCredentials/UserCredentialsFieldsContext';
import { getFieldChanges } from 'utils/helpers';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { UserCredentialsRowChangesSummary } from './UserCredentialsRowChangesSummary';

export const useUserCredentialsColumns = ({
  manageNucleaiUsers,
  pageUsers,
  isEditingRow,
  noRows,
  bulkEditMode,
  rowModesModel,
  shouldApplyBulkChangesToRow,
  setRowModesModel,
  paginatedUserCredentialsQueryKey,
  apiRef,
}: {
  manageNucleaiUsers: boolean;
  pageUsers: UserCredentials[];
  isEditingRow?: boolean;
  noRows?: boolean;
  bulkEditMode: boolean;
  rowModesModel: GridRowModesModel;
  shouldApplyBulkChangesToRow: (id: GridRowId) => boolean;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  paginatedUserCredentialsQueryKey: any;
  apiRef?: React.MutableRefObject<any>;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();
  const { fieldsContext } = useTableEditingContext<UserCredentials, UserCredentialsFieldsContext>();
  const confirmWithModal = useConfirmation();

  const { labId } = useCurrentLabId();

  const queryClient = useQueryClient();

  const setUserCredentialsRolesAndPermissionsMutation = useMutation(setUserCredentialsRolesAndPermissions, {
    onSuccess: (changedId, userUpdate) => {
      queryClient.setQueryData(paginatedUserCredentialsQueryKey, (oldData: UserCredentialsPaginatedResponse) => ({
        ...oldData,
        users: map(oldData.users, (user) =>
          user.id === userUpdate.id ? { ...user, ...userUpdate, id: changedId, userSource: UserSource.DB } : user
        ),
      }));
      queryClient.invalidateQueries(paginatedUserCredentialsQueryKey);
      queryClient.invalidateQueries({ queryKey: ['permissions'] });
    },
  });

  const confirmCancelEdit = async (id: GridRowId) => {
    const currentRowValue = find(pageUsers, { id: id as string });
    const newRowValue = (apiRef?.current?.getRowWithUpdatedValues?.(id, '') as UserCredentials) || currentRowValue;
    const changes = getFieldChanges(newRowValue, currentRowValue);
    return (
      isEmpty(changes) ||
      (await confirmWithModal({
        title: 'Cancel changes to user credentials?',
        text: (
          <UserCredentialsRowChangesSummary
            fieldsContext={fieldsContext}
            userDisplayString={newRowValue?.primaryEmail}
            changes={changes}
          />
        ),
      }))
    );
  };

  const saveOperation = async (id: GridRowId) => {
    try {
      const currentRowValue = find(pageUsers, { id: id as string });
      const newRowValue = (apiRef?.current?.getRowWithUpdatedValues?.(id, '') as UserCredentials) || currentRowValue;
      const changes = getFieldChanges(newRowValue, currentRowValue);
      if (
        await confirmWithModal({
          title: 'Save changes to user credentials?',
          text: (
            <UserCredentialsRowChangesSummary
              fieldsContext={fieldsContext}
              userDisplayString={newRowValue?.primaryEmail}
              changes={changes}
            />
          ),
        })
      ) {
        setMutatingRowId(id);
        try {
          await setUserCredentialsRolesAndPermissionsMutation.mutateAsync({
            ...newRowValue,
            labId: !manageNucleaiUsers ? labId : undefined,
            applyToAllLabs: manageNucleaiUsers,
          });
          setMutatingRowId(undefined);
          apiRef.current?.stopRowEditMode({ id });
        } catch (err) {
          console.error(err);
          setMutatingRowId(undefined);
        }
        return true;
      }
    } catch (err) {
      console.error(err);
      setMutatingRowId(undefined);
    }
    return false;
  };

  const userCredentialsFieldsColumns = useEditableFieldsDataGridColumns({
    fields: userCredentialsFields,
    disableCellEditing: false,
    isLoading: false,
    noRows,
    bulkEditMode,
    shouldApplyBulkChangesToRow,
    useValueSetter: false,
  });

  return [
    ...userCredentialsFieldsColumns,
    rowEditingControlsColumn({
      mutatingRowId,
      disabledEditing: isEditingRow,
      rowModesModel,
      setRowModesModel,
      onCancelEdit: (id) => apiRef?.current.stopRowEditMode({ id, ignoreModifications: true }),
      saveOperation,
      confirmCancelEdit,
      apiRef,
    }),
  ];
};
