import { Grid, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LabelledInput from 'components/atoms/LabelledInput/LabelledInput';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setDate, setText } from 'redux/actions/studyFilters';

const StudyFilters: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [value, setValue] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(setDate(startDate, endDate));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    setText(value);
  }, []);

  useEffect(() => {
    if (Boolean(value) && value.length > 2) {
      dispatch(setText(value));
    }
  }, [value]);

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && Boolean(value) && value.length < 4) {
      dispatch(setText(''));
    }
  };

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onReset = () => {
    setValue('');
    dispatch(setText(''));
  };

  return (
    <Grid item container alignItems="center" spacing={2}>
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="Start Date"
            inputFormat="DD/MM/YYYY"
            value={startDate}
            onChange={setStartDate}
            renderInput={(params) => <TextField {...params} />}
            className="date-picker-start"
            mask={'__/__/____'}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="End Date"
            inputFormat="DD/MM/YYYY"
            value={endDate}
            onChange={setEndDate}
            renderInput={(params) => <TextField {...params} />}
            className="date-picker-end"
            mask={'__/__/____'}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item>
        <LabelledInput
          label="Search"
          value={value}
          onChange={onTextChange}
          dataTestClass="input-text-filter"
          onReset={onReset}
          onKeyDown={onKeyDown}
        />
      </Grid>
    </Grid>
  );
};

export default StudyFilters;
