import { PartialCohort } from 'interfaces/cohort_old';
import { useProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { map } from 'lodash';
import React from 'react';
import { Chart } from 'react-chartjs-2';
import { getAllGroups } from 'utils/cohort.util';
import { displayProcedureFieldValueName } from 'utils/helpers';
import { CategoricalChartKey, NumericalChartKey } from '../chart.util';
import { boxChartOptions, cohortToBoxDataset } from './box.util';

interface Props {
  cohorts: PartialCohort[];
  categoricalKey?: CategoricalChartKey;
  verticalKey?: NumericalChartKey;
}

const BoxChart: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  cohorts,
  categoricalKey,
  verticalKey,
}) => {
  // assume cohorts are fully loaded
  const procedureFieldContext = useProceduresFieldsContext();

  const displayFieldName = displayProcedureFieldValueName(procedureFieldContext);

  const allGroups = categoricalKey ? getAllGroups(categoricalKey, cohorts) : [];

  const cohortToDataset = cohortToBoxDataset(categoricalKey, verticalKey, allGroups);
  const datasets = map(cohorts, (cohort, index) => cohortToDataset(cohort, index));

  const labels = map(allGroups, (group) => displayFieldName(categoricalKey.name, group));

  return <Chart data={{ datasets, labels }} type="boxplot" options={boxChartOptions()} />;
};

export default BoxChart;
