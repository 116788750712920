import { Box, Tooltip, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useClipboard } from 'use-clipboard-copy';

interface SlideIdentifierBadgeProps {
  absolutePositioning?: boolean;
}

const SlideIdentifierBadge: FunctionComponent<React.PropsWithChildren<SlideIdentifierBadgeProps>> = ({
  children,
  absolutePositioning,
}) => {
  const clipboard = useClipboard({
    copiedTimeout: 1000,
  });

  const shortenedId = children?.toString().slice(0, 8);

  const absolutePositioningStyle: React.CSSProperties = {
    position: 'absolute',
    top: 5,
    right: 5,
    padding: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1,
  };

  return (
    <Tooltip title={clipboard.copied ? 'Copied!' : 'Click to copy'} placement="top">
      <Box
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          clipboard.copy(children as string);
        }}
        sx={absolutePositioning ? absolutePositioningStyle : {}}
      >
        <Tooltip title={children} placement="bottom">
          <Typography>{shortenedId}</Typography>
        </Tooltip>
      </Box>
    </Tooltip>
  );
};

export default SlideIdentifierBadge;
