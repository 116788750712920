import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useCasesViewMode } from 'components/StudyDashboard/ProceduresPage/useCasesViewMode';
import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useCurrentStateCases } from 'utils/useCurrentStateCases';
import { useTableEditingContext } from '../atoms/EditableDataGrid/TableEditingContext';
import { useRowSelectionContext } from '../atoms/RowSelectionContext';
import ActionsMenu from './ActionsMenu';

const SelectedDisplay = () => {
  const [slidesMode] = useQueryParam('slidesMode', BooleanParam);
  const selectedItemsType = slidesMode ? 'slides' : 'cases';
  const { totalCasesCount, totalSlidesCount } = useCurrentStateCases();
  const totalRowsCount = slidesMode ? totalSlidesCount : totalCasesCount;

  const { selectedRows, selectionMode, omittedRows } = useRowSelectionContext();

  const totalSelectedRows = selectionMode === 'select' ? selectedRows.length : totalRowsCount - omittedRows.length;

  const [, setCasesOverviewDrawerOpen] = useQueryParam('casesOverviewDrawerOpen', BooleanParam);

  const { setBulkEditMode } = useTableEditingContext();
  const itemsStr =
    totalSelectedRows > 1 ? selectedItemsType : selectedItemsType.substring(0, selectedItemsType.length - 1);

  return (
    <Grid container spacing={2}>
      <Grid item sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography variant="h3">{`${totalSelectedRows} ${itemsStr} selected`}</Typography>
      </Grid>
      <Grid item>
        <Button
          endIcon={<EditIcon />}
          variant="contained"
          data-cy="edit-selected-items-button"
          onClick={() => {
            setBulkEditMode(true);
            setCasesOverviewDrawerOpen(false);
          }}
        >
          Edit
        </Button>
      </Grid>
      <Grid item>
        <ActionsMenu totalRows={totalSelectedRows} />
      </Grid>
    </Grid>
  );
};

const EditSnackbar: React.FunctionComponent<React.PropsWithChildren> = () => {
  const theme = useTheme();
  const { casesViewMode } = useCasesViewMode();

  const [slidesMode] = useQueryParam('slidesMode', BooleanParam);

  const { totalCasesCount, totalSlidesCount } = useCurrentStateCases();

  const totalRows = slidesMode ? totalSlidesCount : totalCasesCount;

  const { someSelected } = useRowSelectionContext();

  return (
    <Snackbar
      data-cy="edit-snackbar"
      open={casesViewMode !== 'table' && someSelected(totalRows)}
      sx={{
        '& .MuiSnackbarContent-action': {
          color: theme.palette.mode === 'light' ? theme.palette.grey[700] : theme.palette.grey[200],
        },
        '& .MuiSnackbarContent-message': { width: '100%', p: 2, color: theme.palette.text.primary },
        '& .MuiSnackbarContent-root': {
          backgroundColor: theme.palette.background.paper,
          padding: 0,
        },
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message={<SelectedDisplay />}
    />
  );
};

export default EditSnackbar;
