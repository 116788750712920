import { createSlice } from '@reduxjs/toolkit';

// Important: this method uses Immer underneath.
// That means that reducers defined here can mutate state - and the package makes sure the state stays immutable.
export const cheatModeSlice = createSlice({
  name: 'cheatMode',
  initialState: {
    cheatMode: [],
    cheatStage: 0,
  } as CheatModeState['cheatMode'],
  reducers: {
    setCheatMode: (state, { payload }) => {
      state.cheatMode = payload;
    },
    setCheatStage: (state) => {
      state.cheatStage += 1;
    },
    resetCheat: (state) => {
      state.cheatStage = 0;
      state.cheatMode = [];
    },
  },
});

export const { setCheatMode, setCheatStage, resetCheat } = cheatModeSlice.actions;

export interface CheatModeState {
  cheatMode: {
    cheatMode: string[];
    cheatStage: number;
  };
}

export default cheatModeSlice.reducer;
