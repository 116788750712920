import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  MenuList,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';

import { UpdateSlideChannelMarkersMutation, UpdateStudyMarkersMutation } from './useChannelMarkerEditor';

interface Props {
  isMissingChannelNames: boolean;
  isSaving: boolean;
  hasMissingMarkers: boolean;
  hasChanges: boolean;
  hasDuplicates: boolean;
  hasPreviouslyAssignedChannelsMissingMarkers: boolean;
  isCheckingStudyChannels: boolean;
  doAllSlidesHaveSameChannels: boolean;
  updateSlideChannelMarkers: UpdateSlideChannelMarkersMutation;
  updateStudyMarkers: UpdateStudyMarkersMutation;
}

export const ChannelMarkersToolbar: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  isSaving,
  isMissingChannelNames,
  hasPreviouslyAssignedChannelsMissingMarkers,
  hasChanges,
  hasMissingMarkers,
  hasDuplicates,
  isCheckingStudyChannels,
  doAllSlidesHaveSameChannels,
  updateSlideChannelMarkers,
  updateStudyMarkers,
}) => {
  const [saveStudyMarkersElement, setAnchorActionListElement] = React.useState<HTMLButtonElement | null>(null);
  // updateStudyMarkers will be null if slide does not belong to a study
  const disabled = !updateStudyMarkers || hasDuplicates || isSaving || hasPreviouslyAssignedChannelsMissingMarkers;
  const saveSingleSlideDisabled = disabled || !hasChanges;
  return (
    <Grid container direction="column" mb={3} spacing={1}>
      <Grid container item justifyContent="space-between" alignItems="end" px={2}>
        <Grid item md={12}>
          <Typography variant="caption">
            {isMissingChannelNames ? (
              <>Multiplex slides must have markers assigned to each channel to allow inference to be run.</>
            ) : (
              <>
                Changing the marker selection for a multiplex slide will cause the slide{"'"}s inference results to be
                reprocessed.
              </>
            )}
          </Typography>
          <Divider sx={{ marginBlock: 1 }} />
        </Grid>
        <Grid item md={12}>
          <ButtonGroup aria-label="split button" size="small" variant="outlined" color="secondary">
            <Button
              variant="outlined"
              title={
                hasMissingMarkers
                  ? 'Missing Marker Selection'
                  : saveSingleSlideDisabled
                  ? 'No Changes to Save'
                  : 'Save Marker Selection'
              }
              disabled={saveSingleSlideDisabled}
              onClick={!saveSingleSlideDisabled ? () => updateSlideChannelMarkers() : undefined}
            >
              Save Marker Selection
            </Button>
            <Button
              aria-haspopup="menu"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorActionListElement(event.currentTarget);
              }}
              disabled={disabled || isMissingChannelNames}
              data-testid="marker-type-save-controls-dropdown"
            >
              <ArrowDropDownIcon />
            </Button>
            <Popover
              open={Boolean(saveStudyMarkersElement) && !disabled && !isMissingChannelNames}
              anchorEl={saveStudyMarkersElement}
              onClose={() => {
                setAnchorActionListElement(null);
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuList id="split-button-menu" autoFocusItem>
                <Tooltip
                  title={
                    isCheckingStudyChannels ? (
                      <Grid item container alignItems="center" justifyContent="center">
                        <CircularProgress size={20} sx={{ mr: 1 }} />
                        Checking if all slides have the same markers...
                      </Grid>
                    ) : !doAllSlidesHaveSameChannels ? (
                      <Typography variant="caption">
                        {
                          "Slides in this study don't have the same number of channels - saving markers will overwrite any existing markers on slides that have a different number of channels."
                        }
                      </Typography>
                    ) : (
                      ''
                    )
                  }
                >
                  {/* Span is used to allow tooltip to be displayed when disabled */}
                  <span>
                    <MenuItem
                      disabled={disabled || isMissingChannelNames || isCheckingStudyChannels}
                      onClick={() => {
                        if (!disabled && !isMissingChannelNames && !isCheckingStudyChannels) {
                          setAnchorActionListElement(null);
                          updateStudyMarkers();
                        }
                      }}
                    >
                      <Typography color={!doAllSlidesHaveSameChannels ? 'error' : 'inherit'}>
                        Save Marker Selection to Whole Study
                      </Typography>
                    </MenuItem>
                  </span>
                </Tooltip>
              </MenuList>
            </Popover>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
      </Grid>
    </Grid>
  );
};
