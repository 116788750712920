import DeleteIcon from '@mui/icons-material/Delete';

import { CellRulePanel, CellRuleStudyId, CellRuleValue } from 'interfaces/cellRule';
import React, { useRef, useState } from 'react';
import useCellRules from 'utils/queryHooks/cellRule/useCellRules';
import CellRulesDataGrid from '.';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { CellType } from 'interfaces/cellType';
import { StainType } from 'interfaces/stainType';
import { filter, includes, isEmpty, isNil, map, some } from 'lodash';
import {
  useDeleteCellRulePanelMutation,
  useUpdateCellRulePanelMutation,
} from 'utils/queryHooks/cellRule/useCellRuleMutations';
import CellSelectDropdown from './CellRuleOptions/CellSelectDropdown';
import StainTypeSelectDropdown from './CellRuleOptions/StainTypeDropdown';
import { useCellTypesUsedByPanel, useStainTypesUsedByPanel } from './panel.util';

interface CellRulesPanelDataContainerProps {
  studyId: CellRuleStudyId;
  panelId: string;
}

export const CellRulesPanel: React.FC<CellRulesPanelDataContainerProps> = ({ studyId, panelId }) => {
  const [localCellTypes, setLocalCellTypes] = useState<CellType[]>(null);
  const [localStainTypes, setLocalStainTypes] = useState<StainType[]>(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const previousCellTypes = useRef<CellType[]>(null);

  const { data: panel, isLoading: isLoadingPanel } = useCellRules(panelId, studyId);
  const updatePanelMutation = useUpdateCellRulePanelMutation();

  const usedCellTypes = useCellTypesUsedByPanel(panel);
  const usedStainTypes = useStainTypesUsedByPanel(panel);

  const selectedCellTypes = localCellTypes ?? usedCellTypes ?? [];
  const selectedStainTypes = localStainTypes ?? usedStainTypes ?? [];

  const handleCellTypesChange = (cellTypes: CellType[]) => {
    const cellTypesThatHaveRulesNotIndifferent = map(
      filter(panel?.rules ?? [], (rule) => rule.ruleValue !== CellRuleValue.Indifferent),
      'cellTypeId'
    );
    if (
      !isEmpty(cellTypesThatHaveRulesNotIndifferent) &&
      some(
        cellTypesThatHaveRulesNotIndifferent,
        (cellTypeIdFromRules) => !includes(map(cellTypes, 'id'), cellTypeIdFromRules)
      )
    ) {
      setShowConfirmationDialog(true);
      previousCellTypes.current = localCellTypes;
    }

    setLocalCellTypes(cellTypes);
  };

  const handleConfirmation = () => {
    updatePanelMutation.mutate(
      {
        studyId,
        panelId,
        cellTypeIdsToDelete: map(selectedCellTypes, 'id'),
      },
      {
        onError: () => {
          setLocalCellTypes(previousCellTypes.current);
        },
      }
    );
    setShowConfirmationDialog(false);
  };

  const handleCancel = () => {
    setLocalCellTypes(previousCellTypes.current);
    setShowConfirmationDialog(false);
  };

  return isLoadingPanel ? (
    <CellRulesPanelSkeleton />
  ) : (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StainTypeSelectDropdown
            selectedStainTypes={selectedStainTypes}
            onSelectStainTypes={setLocalStainTypes}
            mifMarkersOnly
            limitTags={10}
          />
        </Grid>
        <Grid item xs={12}>
          <CellSelectDropdown selectedCellTypes={selectedCellTypes} onSelectCellTypes={handleCellTypesChange} />
        </Grid>
        <Grid item xs={12}>
          <CellRulesDataGrid
            panelSelected={!isNil(panelId)}
            isLoading={isLoadingPanel}
            cellTypes={selectedCellTypes}
            stainTypes={selectedStainTypes}
            panelWithRules={panel}
            editable
          />
        </Grid>
      </Grid>
      {showConfirmationDialog && (
        <ConfirmationModal
          onConfirm={handleConfirmation}
          onCancel={handleCancel}
          title="Warning"
          text="Changing the cell types will remove rules for the cell types that are not selected. Are you sure you want to proceed?"
        />
      )}
      {updatePanelMutation.isLoading && (
        <Dialog open maxWidth="md">
          <DialogContent>
            Saving changes
            <CircularProgress color="inherit" size={15} sx={{ ml: 1 }} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export const CellRulesPanelSkeleton: React.FC = () => {
  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <Skeleton variant="rounded" width={'100%'} height={35} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" width={'100%'} height={35} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" width={'100%'} height={400} />
      </Grid>
    </Grid>
  );
};

interface CellRulesPanelAccordionProps {
  studyId: CellRuleStudyId;
  panel: CellRulePanel;
}

export const CellRulesPanelAccordion: React.FC<CellRulesPanelAccordionProps> = ({ studyId, panel }) => {
  const deleteMutation = useDeleteCellRulePanelMutation();
  const deletePanel = () => {
    deleteMutation.mutate({ studyId, panelId: panel.id });
  };

  return (
    <Accordion key={panel.id}>
      <AccordionSummary>
        <ListItemText>
          <Typography>{panel.label}</Typography>
        </ListItemText>
        <ListItemSecondaryAction>
          {deleteMutation.isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                deletePanel();
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </AccordionSummary>
      <AccordionDetails>
        <CellRulesPanel studyId={studyId} panelId={panel.id} />
      </AccordionDetails>
    </Accordion>
  );
};
