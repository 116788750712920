import { Dictionary } from 'lodash';
import { Inputs } from './calculateFeatures';
import { FlowClassName } from './experimentResults';
import GridBasedCalculationParams from './girdBasedCalculationParams';
import { MultiplexHistogramConfig } from './jobs/multiplex/histogramParams';
import { MultiplexNormalizationJobParams } from './jobs/multiplex/normalizationParams';
import { PostProcessingActionCreated } from './postProcessingAction';
import { VisualizationCreated } from './visualization';

export interface InferenceParams {
  stainType?: string;
  runType?: string;
  modelPaths?: string[];
  buildNumber?: string;
  heatmapNames?: string[];
  studyId?: string;
  tissueMaskFromAnnotations?: boolean | string;
  assignmentIds?: string[];
  classNames?: string[];
  dedupValue?: string;
  tissueSegmentationModelOverride?: string;
  manifest?: string[] | string;
  version?: string;
  branchName?: string;
  clearmlMachineType: string;
  inferenceVmsLimit?: number;
  skipRunExistingArtifacts?: boolean;
  useDynamicCellDetection?: boolean;
  dynamicCellDetectionConfig?: string;
}

export type InferenceManifest = { slide_id: string }[];

export interface SecondaryAnalysisInput {
  [caseId: string]: {
    [slideId: string]: {
      inclusionMultipolygon?: any;
      exclusionMultipolygon?: any;
    };
  };
}

export interface CalculateFeaturesParams {
  manifest: Record<string, string[]>;
  studyId: string;
  postprocessing: PostProcessingActionCreated[];
  features: PostProcessingActionCreated[];
  gridBasedCalculationParams: { [key: string]: GridBasedCalculationParams };
  visualizations: VisualizationCreated[];
  inputs: Inputs;
  secondaryAnalysisPolygons: SecondaryAnalysisInput;
}

export interface PrepareCustomerResultsParams {
  numOfCasesInZip: number;
  resultTypesToExport: string[];
}

export type CalculateFeaturesManifest = Array<{
  slides: Dictionary<string>;
  case_id: string;
}>;

export enum JobStatus {
  Pending = 'pending',
  Running = 'running',
  FailedToStart = 'failed_to_start',
  Failed = 'failed',
  Completed = 'completed',
}

export enum JobType {
  CalculateFeatures = 'calculate_features',
  Inference = 'inference',
  MultiplexCellSegmentation = 'multiplex_cell_segmentation',
  PrepareCustomerResults = 'prepare_export_results',
  MultiplexThreshold = 'multiplex_threshold_binary_classification',
  MultiplexNormalization = 'multiplex_normalization',
  MultiplexHistogram = 'multiplex_histogram_creation',
  BinaryClassifier = 'multiplex_binary_classification',
  MultiplexCellTyping = 'multiplex_cell_typing',
}

type CommonJobFields = {
  updatedAt?: string;
  status?: string;
  userId?: string;
  type?: JobType;
  name?: string;
  externalTaskId?: string;
  description?: string;
  externalTaskLink?: string;
  requestSender?: string;
  statusMessage?: string;
  studyId?: string;
  orchestrationId?: string;
  startedAt?: string;
  id?: string;
  children?: Job[];
  manifest?: string[];
  parentId?: string;
};

export type CalculateFeaturesJob = CommonJobFields & {
  flowClassName: FlowClassName.CalculateFeatures;
  type: JobType.CalculateFeatures;
  params: CalculateFeaturesParams;
  manifest: CalculateFeaturesManifest;
};

export type InferenceJob = CommonJobFields & {
  flowClassName: FlowClassName.ConcurrentInferenceFlow;
  type: JobType.Inference;
  params: InferenceParams;
  manifest: InferenceManifest;
};

export type PrepareCustomerResultsJob = CommonJobFields & {
  flowClassName?: undefined | null;
  type: JobType.PrepareCustomerResults;
  params: PrepareCustomerResultsParams;
};

export type NormalizationJob = CommonJobFields & {
  flowClassName?: undefined | null;
  type: JobType.MultiplexNormalization;
  params: { histogramNormalization: MultiplexNormalizationJobParams; histogramCreation: MultiplexHistogramConfig };
};

export type HistogramJob = CommonJobFields & {
  flowClassName?: undefined | null;
  type: JobType.MultiplexHistogram;
  params: MultiplexHistogramConfig;
};

export type Job = CalculateFeaturesJob | InferenceJob | PrepareCustomerResultsJob | NormalizationJob | HistogramJob;

export interface JobResponse {
  jobs: Job[];
  totalJobs: number;
}
