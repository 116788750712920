import { Button, Grid } from '@mui/material';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BooleanParam, NumberParam, useQueryParam } from 'use-query-params';

import { useEncodedFilters } from 'utils/useEncodedFilters';
import useResultsMutation from './useResultsMutation';

export const APPROVE_RESULTS_TEXT = 'Approve';
export const DISAPPROVE_RESULTS_TEXT = 'Disapprove';
export const PUBLISH_RESULTS_TEXT = 'Publish';
export const UNPUBLISH_RESULTS_TEXT = 'Unpublish';

export interface OrchestrationApprovalProps {
  slideId: string;
  viewerIndex: number;
  orchestrationId?: string;
  primaryRunOrchestrationId?: string;
  experimentResultIds?: number[];
  isInternallyApproved: boolean;
  doesSlideHaveApprovedResults: boolean;
  isOrchestrationIdPublished?: boolean;
  flowClassNames?: string[];
  resultTypes?: string[];
  studyId: string;
  onApprovalMutation?: () => void;
}

export const OrchestrationApproval: React.FunctionComponent<OrchestrationApprovalProps> = ({
  slideId,
  viewerIndex,
  orchestrationId,
  primaryRunOrchestrationId = orchestrationId,
  experimentResultIds,
  isInternallyApproved,
  isOrchestrationIdPublished,
  doesSlideHaveApprovedResults,
  flowClassNames,
  resultTypes,
  studyId,
  onApprovalMutation,
}) => {
  const { queryParams } = useEncodedFilters();
  const [caseIdFromParam] = useQueryParam('caseId', NumberParam);
  const [isPublishMode] = useQueryParam(`isPublishModeViewer${viewerIndex}`, BooleanParam);
  const params = useParams();
  const caseId = queryParams.slidesMode ? Number(caseIdFromParam) : Number(params.id);

  const { handleFieldSave } = useResultsMutation(caseId, studyId, onApprovalMutation);

  const [approvalType, setApprovalType] = React.useState<'approved' | 'internallyApproved' | null>(null);

  const confirmationModalText =
    approvalType === 'approved'
      ? doesSlideHaveApprovedResults
        ? 'This slide has published results. By publishing these results, the old ones will be unpublished. Are you sure you want to replace the published results?'
        : 'By publishing results, all users will see the results. Are you sure you want to publish the results?'
      : !isInternallyApproved
      ? 'Are you sure you want to approve these results?'
      : 'Are you sure you want to disapprove these results?';

  return (
    <>
      {isPublishMode && (
        <Grid item xs={6} container>
          <Grid item>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setApprovalType('internallyApproved');
              }}
            >
              {isInternallyApproved ? DISAPPROVE_RESULTS_TEXT : APPROVE_RESULTS_TEXT}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={isOrchestrationIdPublished}
              onClick={(e) => {
                e.stopPropagation();
                setApprovalType('approved');
              }}
            >
              Publish
            </Button>
          </Grid>
        </Grid>
      )}
      {approvalType && (
        <ConfirmationModal
          title={`${approvalType === 'approved' ? PUBLISH_RESULTS_TEXT : APPROVE_RESULTS_TEXT} Results`}
          text={confirmationModalText}
          onConfirm={() => {
            handleFieldSave({
              orchestrationId: approvalType === 'approved' ? primaryRunOrchestrationId : orchestrationId,
              experimentResultIds,
              slideId,
              fieldName: approvalType,
              value: approvalType === 'approved' || !isInternallyApproved,
              flowClassNames,
              resultTypes,
            });
            setApprovalType(null);
          }}
          onCancel={() => setApprovalType(null)}
        />
      )}
    </>
  );
};

export default OrchestrationApproval;
