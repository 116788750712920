export interface ArtifactResultsConfig {
  resultTypes?: string[];
  filterArtifactsWithTsmOnly?: boolean;
  groupByModel?: boolean;
}

export enum ResultTypes {
  MarkersProbabilities = 'slide_inference_multiplex_markers_probabilities',
  MarkerPositivities = 'multiplex_thresholds',
  SlideInferenceAreas = 'slide_inference_areas',
  SlideInferencePoints = 'slide_inference_points',
  SlideInferenceDefects = 'slide_inference_defects',
  SlideInferenceTls = 'slide_inference_tls',
  SlideInferenceClassificationIntensity = 'slide_inference_classification_intensity',
  SlideInferenceClassification = 'slide_inference_classification',
  SlideInferenceNuclei = 'slide_inference_nuclei',
  SlideCellAttributesTable = 'slide_cell_attributes_table',
}
export const BinaryClassifierConfig: ArtifactResultsConfig = {
  resultTypes: [ResultTypes.MarkersProbabilities],
  filterArtifactsWithTsmOnly: false,
};

export const CellTypingConfig: ArtifactResultsConfig = {
  resultTypes: [ResultTypes.MarkerPositivities],
  filterArtifactsWithTsmOnly: false,
  groupByModel: false,
};

export const AreaConfig: ArtifactResultsConfig = {
  resultTypes: [ResultTypes.SlideInferenceAreas],
  filterArtifactsWithTsmOnly: true,
};

export const CellConfig: ArtifactResultsConfig = {
  resultTypes: [ResultTypes.SlideInferencePoints],
  filterArtifactsWithTsmOnly: true,
};

export const DefectConfig: ArtifactResultsConfig = {
  resultTypes: [ResultTypes.SlideInferenceDefects],
  filterArtifactsWithTsmOnly: true,
};

export const TlsConfig: ArtifactResultsConfig = {
  resultTypes: [ResultTypes.SlideInferenceTls],
  filterArtifactsWithTsmOnly: true,
};

export const ClassificationIntensityConfig: ArtifactResultsConfig = {
  resultTypes: [ResultTypes.SlideInferenceClassificationIntensity],
  filterArtifactsWithTsmOnly: false,
};

export const ClassificationConfig: ArtifactResultsConfig = {
  resultTypes: [ResultTypes.SlideInferenceClassification],
  filterArtifactsWithTsmOnly: false,
};

export const NucleiConfig: ArtifactResultsConfig = {
  resultTypes: [ResultTypes.SlideInferenceNuclei],
  filterArtifactsWithTsmOnly: false,
};

export const CellAttributesTableConfig: ArtifactResultsConfig = {
  resultTypes: [ResultTypes.SlideCellAttributesTable],
  filterArtifactsWithTsmOnly: false,
  groupByModel: false,
};
