import { QueryClient, useQueries } from '@tanstack/react-query';
import { reduce } from 'lodash';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retryDelay: 20 * 1_000, // 20 seconds
      cacheTime: 5 * 60 * 1_000, // 5 minutes
    },
  },
});

// hack for now until we migrate to @tanstack/react-query v5 which requires react 18
// see https://github.com/TanStack/query/issues/3049
// also see https://github.com/TanStack/query/issues/5137
export function stabilizedQueriesDependency(queries: ReturnType<typeof useQueries>) {
  return reduce(queries, (acc, cur) => acc + cur.dataUpdatedAt, 0);
}

export default queryClient;
