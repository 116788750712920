import { Chart, ChartDataset, Legend, LineElement, Tooltip } from 'chart.js';
import React from 'react';
import { Scatter } from 'react-chartjs-2';

import { PartialCohort } from 'interfaces/cohort_old';
import { map } from 'lodash';
import { FunctionComponent } from 'react';
import { cohortToKaplanMeierDataset, kaplanMeierOptions, SurvivalAnalysisType } from './kaplanMeier.util';

Chart.register(LineElement, Tooltip, Legend);
interface Props {
  cohorts: PartialCohort[];
  parameter: SurvivalAnalysisType;
}

const KaplanMeierChart: FunctionComponent<React.PropsWithChildren<Props>> = ({ cohorts, parameter }) => {
  const datasets = map(cohorts, cohortToKaplanMeierDataset(parameter)) as ChartDataset<'scatter'>[];

  const options = kaplanMeierOptions(parameter);

  return <Scatter data={{ datasets }} options={options} />;
};

export default KaplanMeierChart;
