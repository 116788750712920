import { useTheme } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useUploadFilesContext } from 'components/UploadFilesContext';
import React from 'react';
import UploadSummaryList from './UploadSummaryList';

const UploadProcess: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { showUploadWidget, setShowUploadWidget, setFileUploads } = useUploadFilesContext();
  const theme = useTheme();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setFileUploads({});
    setShowUploadWidget(false);
  };

  return (
    <Snackbar
      open={showUploadWidget}
      onClose={handleClose}
      sx={{
        '& .MuiSnackbarContent-message': { width: '100%', padding: 0 },
        '& .MuiSnackbarContent-root': {
          backgroundColor: theme.palette.background.paper,
          padding: 0,
        },
      }}
      message={<UploadSummaryList onClose={handleClose} />}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    />
  );
};

export default UploadProcess;
