import { AreaType } from 'interfaces/areaType';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getAreaTypeOptions = () => {
  return apiRequestHandlerPromise<AreaType[]>({
    url: `area_types`,
    method: 'GET',
  });
};

export const createAreaTypeOption = ({ id, displayName, sourceType, postprocessed }: Omit<AreaType, 'index'>) => {
  return apiRequestHandlerPromise<AreaType>({
    url: 'area_types',
    method: 'POST',
    data: JSON.stringify({ id, displayName, sourceType, postprocessed }),
  });
};

export const updateAreaTypeOption = ({ id, displayName, sourceType, postprocessed }: Omit<AreaType, 'index'>) => {
  return apiRequestHandlerPromise({
    url: `area_types/${id}`,
    method: 'POST',
    data: JSON.stringify({ displayName, sourceType, postprocessed }),
  });
};

export const deleteAreaTypeOption = (id: string) => {
  return apiRequestHandlerPromise({
    url: `area_types/${id}`,
    method: 'DELETE',
  });
};
