import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgress, ListItemIcon, ListItemText, MenuItem, Tooltip } from '@mui/material';
import React from 'react';

interface StudyExportMenuItemProps {
  isLoading: boolean;
  onClick: () => void;
  disabled?: boolean;
  disabledMessage?: string;
  title: string;
  tooltip?: string;
}

const StudyExportMenuItem: React.FC<StudyExportMenuItemProps> = ({
  disabled,
  disabledMessage,
  isLoading,
  onClick,
  title,
  tooltip,
}) => {
  return (
    <Tooltip title={disabled ? disabledMessage : tooltip ?? ''} placement="top">
      <span>
        <MenuItem disabled={disabled} onClick={onClick}>
          <ListItemIcon>
            <DownloadIcon />
          </ListItemIcon>
          <ListItemText>{title}</ListItemText>
          <ListItemIcon
            sx={{
              marginLeft: 1,
            }}
          >
            {isLoading && <CircularProgress size={16} />}
          </ListItemIcon>
        </MenuItem>
      </span>
    </Tooltip>
  );
};

export default StudyExportMenuItem;
