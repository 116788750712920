import { formatFeatures } from './formatFeatures';

export async function formatFeaturesWebWorker(
  ...args: Parameters<typeof formatFeatures>
): Promise<ReturnType<typeof formatFeatures>> {
  const worker = new Worker(new URL('./worker.ts', import.meta.url));

  return new Promise<ReturnType<typeof formatFeatures>>((resolve, reject) => {
    worker.onmessage = function (e: MessageEvent<ReturnType<typeof formatFeatures>>) {
      resolve(e.data);
      worker.terminate();
    };

    worker.onerror = function (e: ErrorEvent) {
      reject(e);
      worker.terminate();
    };

    worker.postMessage({ args });
  });
}
