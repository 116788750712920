import { Taxonomy, TaxonomyCreate, UpdateTaxonomy } from 'interfaces/taxonomy';
import { omit } from 'lodash';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getAllTaxonomies = (signal?: AbortSignal) => {
  return apiRequestHandlerPromise<Taxonomy[]>({
    url: `taxonomy`,
    method: 'GET',
    signal,
  });
};

export const getAllTaxonomiesForPath = (path: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<Taxonomy[]>({
    url: `taxonomy/${path}`,
    method: 'GET',
    signal,
  });
};

export const updateTaxonomy = (path: string, updateModel: UpdateTaxonomy, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<Taxonomy>({
    url: `taxonomy/${path}`,
    method: 'PUT',
    signal,
    data: JSON.stringify(updateModel),
  });
};

export const createTaxonomy = (taxonomy: TaxonomyCreate, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<Taxonomy>({
    url: `taxonomy/${taxonomy.path}`,
    method: 'POST',
    signal,
    data: JSON.stringify(omit(taxonomy, 'path')),
  });
};

export const deleteTaxonomy = (path: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<Taxonomy>({
    url: `taxonomy/${path}`,
    method: 'DELETE',
    signal,
  });
};
