import { Grid, Typography } from '@mui/material';
import { modelTypesByApiModelValue } from 'components/Pages/Jobs/inferenceFieldsOptions';
import { OrchestrationInference } from 'interfaces/calculateFeatures';
import { join, map } from 'lodash';
import React from 'react';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import { getOverrideAnnotationsTimestamp } from '../../utils';

interface Validation {
  type: 'ClassConfig' | 'TsmModel';
  currentTypeData: React.ReactNode;
  newTypeData: string | React.ReactNode;
}

interface ConfirmationModalMessageTextProps {
  modelType: string;
  modelId: string;
  stain: string;
  slideIds: string[];
  messageType: 'Error' | 'Warning';
  validations: Validation[];
}

const validationTypeDict = {
  ClassConfig: {
    problemText: 'class config',
    problemUnderText: 'model',
  },
  TsmModel: {
    problemText: 'tsm model',
    problemUnderText: 'orchestration',
  },
};

export const ConfirmationModalMessageText: React.FC<React.PropsWithChildren<ConfirmationModalMessageTextProps>> = ({
  modelType,
  modelId,
  stain,
  slideIds,
  messageType,
  validations,
}) => {
  const { stainTypeIdToDisplayName } = useStainTypeIdToDisplayName();

  const getValidationExplanation = (validation: Validation, moreThanOne: boolean) => {
    const validationText = validationTypeDict[validation.type];

    return (
      <Grid container direction="column">
        {moreThanOne && (
          <Grid item mt={1}>
            <Typography variant="h4"> {'AND'}</Typography>
          </Grid>
        )}
        <Grid item mt={1}>
          <Typography>{`You chose slides under ${validationText.problemUnderText} with ${validationText.problemText}: `}</Typography>
          <Typography variant="h4"> {validation.newTypeData}</Typography>
        </Grid>
        <Grid item mt={1}>
          <Typography>{`While the other selected slides are under ${validationText.problemUnderText} with ${validationText.problemText}: `}</Typography>
          <Typography variant="h4"> {validation.currentTypeData}</Typography>
        </Grid>
      </Grid>
    );
  };

  const problemsText = join(
    map(validations, (validation) => validationTypeDict[validation.type].problemText),
    ' and'
  );

  return (
    <Grid container direction="column">
      <Grid item>
        {`The selected orchestration under model ${
          modelTypesByApiModelValue[modelType]?.text ?? modelType
        }- ${modelId} for the ${stainTypeIdToDisplayName(stain)} slides:`}
      </Grid>
      {map(slideIds, (slideId) => (
        <Grid item key={slideId}>
          {slideId}
        </Grid>
      ))}
      <Grid item>{`have different ${problemsText}.`}</Grid>
      {map(validations, (validation, index) => getValidationExplanation(validation, index > 0))}
      {messageType === 'Error' && (
        <Grid item mt={2}>
          <Typography variant="h4">{`!! If you confirm, it will override the previous selected inference results !!`}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export const getClassConfigErrorValidation = (currentClassConfig: string[], newClassConfig: string[]): Validation => {
  return {
    type: 'ClassConfig',
    currentTypeData: <Typography variant="h4"> {join(currentClassConfig, ', ')}</Typography>,
    newTypeData: join(newClassConfig, ', '),
  };
};

export const getTSMModelErrorWithOverrideAnnotationsValidation = (
  currentTsmModelPerSlide: Record<
    string,
    {
      modelUrl: string;
      overrideAnnotationsTimestamp: string;
    }
  >,
  newOrchestration: OrchestrationInference
): Validation => {
  return {
    type: 'TsmModel',
    currentTypeData: map(currentTsmModelPerSlide, (tsmModel, slideId) => (
      <Grid container mt={1}>
        <Grid item>
          <Typography>{slideId} -</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">
            {tsmModel.modelUrl} with override annotations timestamp: {tsmModel.overrideAnnotationsTimestamp}
          </Typography>
        </Grid>
      </Grid>
    )),
    newTypeData: map(currentTsmModelPerSlide, (_, slideId) => (
      <Grid container mt={1}>
        <Grid item>
          <Typography>{slideId} -</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">
            {newOrchestration?.params?.deps?.modelTsm} with override annotations timestamp:
            {getOverrideAnnotationsTimestamp(
              newOrchestration?.tsmSlideOverrides?.[slideId] ?? newOrchestration.orchestrationTsmArtifactUrlPattern
            )}
          </Typography>
        </Grid>
      </Grid>
    )),
  };
};

export const getTSMModelErrorValidation = (
  currentTsmModelUrlPerSlide: Record<string, string>,
  newOrchestration: OrchestrationInference
): Validation => {
  return {
    type: 'TsmModel',
    currentTypeData: map(currentTsmModelUrlPerSlide, (tsmModel, slideId) => (
      <Grid container mt={1}>
        <Grid item>
          <Typography>{slideId} -</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">{tsmModel}</Typography>
        </Grid>
      </Grid>
    )),
    newTypeData: newOrchestration?.params?.deps?.modelTsm,
  };
};
