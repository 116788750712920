import React from 'react';
import { IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyButton: React.FunctionComponent<
  React.PropsWithChildren<{
    onCopy(): void;
  }>
> = ({ onCopy }) => (
  <IconButton onClick={onCopy} color="primary">
    <ContentCopyIcon />
  </IconButton>
);

export default CopyButton;
