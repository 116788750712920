export const SET_DATE = 'set_date';
export const SET_TEXT = 'set_text';

export interface StudyFiltersState {
  startDate: Date;
  endDate: Date;
  text: string;
}

export interface SetDateAction {
  type: typeof SET_DATE;
  payload: StudyFiltersState;
}

export interface SetTextAction {
  type: typeof SET_TEXT;
  payload: StudyFiltersState;
}

export type DateActionType = SetDateAction;
export type TextActionType = SetTextAction;
