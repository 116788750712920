import { Dialog, DialogContent, DialogProps as MuiDialogProps } from '@mui/material';
import React, { FunctionComponent } from 'react';

export interface DialogProps {
  onClose: () => void;
  isOpen: boolean;
  sx?: MuiDialogProps['sx'];
  maxWidth?: MuiDialogProps['maxWidth'];
  fullWidth?: MuiDialogProps['fullWidth'];
}

const BasicDialog: FunctionComponent<React.PropsWithChildren<DialogProps>> = ({
  children,
  onClose,
  isOpen,
  sx,
  maxWidth = 'md',
  fullWidth = false,
}) => {
  return isOpen ? (
    <Dialog
      onClick={(e) => {
        onClose(); // close dialog when click outside of dialog content
        e.stopPropagation();
      }}
      open={isOpen}
      onClose={onClose}
      maxWidth={maxWidth}
      sx={sx}
      fullWidth={fullWidth}
    >
      <DialogContent
        onClick={(e) => {
          e.stopPropagation(); // prevent the click from propagating to the parent to make the dialog stay open
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  ) : null;
};

export default BasicDialog;
