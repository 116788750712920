import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, List, ListItem, Typography } from '@mui/material';
import { find, isEmpty, map } from 'lodash';
import React from 'react';

import { Permission, PermissionOption, permissionToName } from 'interfaces/permissionOption';
import { usePermissions } from 'utils/usePermissions';

export const CurrentUserPermissionsAccordion: React.FC<
  React.PropsWithChildren<{
    permissions: PermissionOption[];
  }>
> = ({ permissions }) => {
  const {
    hasSomePermissions,
    permissions: currentUserCredentials,
    isLoading: loadingCurrentUserPermissions,
  } = usePermissions();

  const [expandedState, setExpanded] = React.useState<boolean | null>(null);
  // If the user has no permissions to manage permissions, the accordion should be expanded by default
  const expanded =
    expandedState ??
    (!loadingCurrentUserPermissions &&
      !hasSomePermissions([Permission.DefineRolesToLabUsers, Permission.ManageNucleaiUsers]));

  return (
    <Grid item>
      <Accordion
        expanded={expanded}
        onChange={(e, newState) => setExpanded(newState)}
        square
        variant="outlined"
        disabled={loadingCurrentUserPermissions}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1">Current User Permissions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loadingCurrentUserPermissions ? (
            'Loading...'
          ) : currentUserCredentials && !isEmpty(currentUserCredentials) ? (
            <List dense>
              {map(currentUserCredentials, (permissionId) => {
                const permissionOption = find(permissions, {
                  permission: permissionId,
                });
                return permissionOption ? (
                  <ListItem key={permissionId}>- {permissionToName(permissionOption, permissionId)}</ListItem>
                ) : null;
              })}
            </List>
          ) : (
            'No permissions set for current user'
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
