import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import React from 'react';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { DEFAULT_PAGE_SIZE } from '../ProcedurePagination';

export const DEFAULT_ROW_HEIGHT = 41.5;

export const TOOLBAR_HEIGHT = 100;
export const FOOTER_HEIGHT = 52;
export const GRID_ITEM_PADDING = 24;
export const HORIZONTAL_SCROLLBAR_HEIGHT = 12;

export interface StableTableHeightProps {
  isLoading?: boolean; // This is used to determine if the data grid is loading a new page
  currentNumberOfRows?: number; // This is the number of rows in the current page after loading
}

/**
 * This hook is used to calculate the height of the data grid.
 * It is used to prevent the data grid from jumping around when the number of rows changes.
 * During initial loading, the height is calculated based on the page size.
 * After loading, the height is calculated based on the number of rows.
 * When loading an additional page, the previous height is used until the new page is loaded.
 * @param {StableTableHeightProps}
 * @returns {number} The height of the data grid in pixels
 */
export const useStableTableHeight = ({ isLoading, currentNumberOfRows }: StableTableHeightProps) => {
  const { bulkEditMode } = useTableEditingContext();
  const { queryParams } = useEncodedFilters();
  const pageSize = queryParams.pageSize || DEFAULT_PAGE_SIZE;

  const [stableNumberOfRows, setStableNumberOfRows] = React.useState(
    Math.max(currentNumberOfRows ?? pageSize, pageSize)
  );

  React.useEffect(() => {
    const newNumberOfRows = Math.max(currentNumberOfRows ?? pageSize, pageSize);
    if (!isLoading && newNumberOfRows !== stableNumberOfRows) {
      setStableNumberOfRows(newNumberOfRows);
    }
  }, [isLoading, currentNumberOfRows, pageSize]);

  // The height of the data grid is the height of the rows + the height of the toolbar + the height of the footer + the padding of the container + the height of the scrollbar
  const columnHeaderHeight = bulkEditMode ? 2.5 * DEFAULT_ROW_HEIGHT : DEFAULT_ROW_HEIGHT;
  const rowsHeight = DEFAULT_ROW_HEIGHT * stableNumberOfRows + columnHeaderHeight;
  const additionalHeight = TOOLBAR_HEIGHT + FOOTER_HEIGHT + GRID_ITEM_PADDING + HORIZONTAL_SCROLLBAR_HEIGHT;
  return rowsHeight + additionalHeight;
};
