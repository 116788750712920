import { Box, Typography } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { get, keys, map, toPairs } from 'lodash';
import React from 'react';
import { RowsChangesState } from './types';

export interface ChangeFormattingProps {
  fieldToDisplayString?: (field: string) => string;
  valueToDisplayString?: (value: any, field?: string) => string;
  emptyValueDisplayString?: (field?: string) => string;
}

export interface RowChangesSummaryProps extends ChangeFormattingProps {
  rowsChanges: RowsChangesState;
  rowToDisplayString?: (rowId: GridRowId) => string;
}

export interface BulkChangesSummaryProps extends ChangeFormattingProps {
  bulkChanges: Record<string, any>;
}

export const RowsChangesSummary: React.FC<React.PropsWithChildren<RowChangesSummaryProps>> = ({
  rowsChanges,
  rowToDisplayString,
  fieldToDisplayString,
  valueToDisplayString,
  emptyValueDisplayString,
}) => (
  <>
    {map(toPairs(rowsChanges), ([rowId, changes]) => (
      <Box key={rowId}>
        <Typography variant="h6">{rowToDisplayString ? rowToDisplayString(rowId) : `Row ID ${rowId}:`}</Typography>
        {map(keys(changes), (field) => {
          const value = get(changes, field);
          const valueToDisplay = valueToDisplayString?.(value, field) ?? value;
          return (
            <Typography key={field} variant="body1">
              - Set {"'"}
              {fieldToDisplayString?.(field) ?? field}
              {/* "valueToDisplay ??" doesn't work, it prints the empty string, this way if the string is empty it's considered as false */}
              {"'"} to: {valueToDisplay ? valueToDisplay : emptyValueDisplayString?.(field) ?? 'null'}
            </Typography>
          );
        })}
      </Box>
    ))}
  </>
);

export const BulkChangesSummary: React.FC<React.PropsWithChildren<BulkChangesSummaryProps>> = ({
  bulkChanges,
  fieldToDisplayString,
  valueToDisplayString,
  emptyValueDisplayString,
}) => (
  <>
    {map(keys(bulkChanges), (field) => {
      const value = get(bulkChanges, field);
      const valueToDisplay = valueToDisplayString?.(value, field) ?? value;
      return (
        <Typography key={field} variant="body1">
          - {fieldToDisplayString?.(field) ?? field}: {valueToDisplay ?? emptyValueDisplayString?.(field) ?? 'null'}
        </Typography>
      );
    })}
  </>
);
