import { Tooltip } from '@mui/material';
import React from 'react';

interface Props {
  text: string;
}

const TableCellTextContent: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ text }) => (
  <Tooltip title={text}>
    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</span>
  </Tooltip>
);

export default TableCellTextContent;
