import reduxFreezeMiddleware from 'redux-freeze';
import thunkMiddleware from 'redux-thunk';
// eslint-disable-next-line import/no-cycle
import apiMiddleware from 'redux/middlewares/api';
// eslint-disable-next-line import/no-cycle
import authMiddleware from 'redux/middlewares/auth';
import appReducer from 'redux/reducers';
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { loadState, LocalStorageFields, saveState } from './localStorage';

// declare global {
//   interface Window {
//     __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
//   }
// }

const middlewares = [thunkMiddleware, apiMiddleware, authMiddleware];

// eslint-disable-next-line no-undef
const isDev = process.env.NODE_ENV !== 'production';

// eslint-disable-next-line no-underscore-dangle
// const composeEnhancers = (isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

if (isDev) {
  // eslint-disable-next-line global-require
  middlewares.push(reduxFreezeMiddleware);
}
const persistedState = loadState(LocalStorageFields.Preferences);
const store = configureStore({
  reducer: appReducer,
  preloadedState: persistedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});
// const store = createStore(appReducer, persistedState, composeEnhancers(applyMiddleware(...middlewares)));

store.subscribe(() => {
  saveState(store.getState(), LocalStorageFields.Preferences);
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
