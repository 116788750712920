import { BasicTableRow } from 'components/atoms/EditableDataGrid/TableEditingContext/types';
import { flatMap, get, join, map, omit, times, uniq } from 'lodash';

import { DisplayedField } from '.';

export interface UnwoundRowMetadata {
  baseRowId: string | number;
  innerRowId: string | number;
}

const UNWOUND_ROW_ID_SEPARATOR = '||||';

export type UnwoundRow<R extends BasicTableRow, UnwoundType = any> = R &
  UnwoundType & {
    _unwoundRowMetadata: UnwoundRowMetadata;
  };

export const generateUnwoundRowIdFromMetadata = (metadata: UnwoundRowMetadata) => {
  return join([`${metadata.baseRowId}`, `${metadata.innerRowId}`], UNWOUND_ROW_ID_SEPARATOR);
};

export const unwoundRowMetadataFromId = (id: string): UnwoundRowMetadata => {
  const [baseRowId, innerRowId] = id.split(UNWOUND_ROW_ID_SEPARATOR);
  return {
    baseRowId,
    innerRowId: innerRowId as any,
  };
};

export const unwindRow = <R extends BasicTableRow, UnwoundType extends object>({
  row,
  arrayFieldToUnwind,
  innerRowIdx,
  rowIdField,
  unwoundRowIdField,
  omitUnwoundFields,
}: {
  row: R;
  arrayFieldToUnwind: keyof R;
  innerRowIdx: number;
  rowIdField?: keyof R;
  unwoundRowIdField?: keyof UnwoundType;
  omitUnwoundFields?: (keyof UnwoundType)[];
}): UnwoundRow<R, UnwoundType> => {
  const unwoundRow = (row[arrayFieldToUnwind] as UnwoundType[])?.[innerRowIdx];
  const metadata: UnwoundRowMetadata = {
    baseRowId: row[rowIdField ?? ('id' as keyof R)] as string | number,
    innerRowId: unwoundRow[unwoundRowIdField || ('id' as keyof UnwoundType)] as string | number,
  };
  return {
    ...row,
    ...(omitUnwoundFields ? (omit(unwoundRow, ...omitUnwoundFields) as UnwoundType) : unwoundRow),
    _unwoundRowMetadata: metadata,
    id: generateUnwoundRowIdFromMetadata(metadata),
  };
};

export const unwindRows = <R extends BasicTableRow, UnwoundType extends object>({
  rows,
  arrayFieldToUnwind,
  unwoundRowIdField,
  rowIdField,
  omitUnwoundFields,
}: {
  rows: R[];
  arrayFieldToUnwind: keyof R;
  rowIdField?: keyof R;
  unwoundRowIdField?: keyof UnwoundType;
  omitUnwoundFields?: (keyof UnwoundType)[];
}): UnwoundRow<R, UnwoundType>[] => {
  return flatMap(rows, (row: R) => {
    const unwoundRows = row[arrayFieldToUnwind] as UnwoundType[];
    return times(unwoundRows.length, (idx) =>
      unwindRow<R, UnwoundType>({
        row,
        arrayFieldToUnwind,
        innerRowIdx: idx,
        rowIdField,
        unwoundRowIdField,
        omitUnwoundFields,
      })
    );
  });
};

export const getInnerRowIdFromUnwoundRowMetadata = (rows: string[]) =>
  uniq(
    map(rows, (unwoundRowId) => {
      const { innerRowId } = unwoundRowMetadataFromId(unwoundRowId);
      return innerRowId as string;
    })
  );

export const getBaseRowIdFromUnwoundRowMetadata = (rows: string[]): string[] =>
  uniq(
    map(rows, (unwoundRowId) => {
      const { baseRowId } = unwoundRowMetadataFromId(unwoundRowId);
      return baseRowId as string;
    })
  );

export const getFieldValue = <R extends BasicTableRow, V, Context = any>(
  row: R,
  field: DisplayedField<R, V, Context>,
  fieldsContext?: Context
): V => {
  if (field?.isFieldOfObjectInArray) {
    const { objectArrayInnerPath, objectArrayPath } = field;

    const changedObjectArray = get(row, objectArrayPath);
    return field?.valueGetter
      ? field?.valueGetter(row, fieldsContext)
      : (map(changedObjectArray, (o) => get(o, objectArrayInnerPath)) as V);
  } else {
    return field?.valueGetter ? field?.valueGetter(row, fieldsContext) : get(row, field.updatePath || field.dataKey);
  }
};
