import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import AuthService from 'utils/auth/AuthService';
import './Login.scss';

interface Props {
  isAuthenticated: boolean;
  triedAuthenticateFromLocalStorage: boolean;
}

const Login: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  isAuthenticated,
  triedAuthenticateFromLocalStorage,
}) => {
  const location = useLocation();

  const startAuth0Login = !isAuthenticated && triedAuthenticateFromLocalStorage;
  useEffect(() => {
    if (startAuth0Login) {
      AuthService.login();
    }
  }, [startAuth0Login]);

  const { from } = location.state || { from: { pathname: '/' } };
  return isAuthenticated ? (
    // @ts-ignore
    <Navigate to={from} />
  ) : (
    <div>
      <div id="lock-container" />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: Boolean(state.auth.accessToken),
  triedAuthenticateFromLocalStorage: state.auth.triedAuthenticateFromLocalStorage,
});

export default connect(mapStateToProps)(Login);
