import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import BarChartIcon from '@mui/icons-material/BarChart';
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
import ExpandIcon from '@mui/icons-material/Expand';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { Grow, SpeedDialIcon, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { styled, useTheme } from '@mui/system';
import { ChartType } from 'interfaces/chart';
import { PartialCohort } from 'interfaces/cohort_old';
import { map } from 'lodash';
import React from 'react';
import Chart from './Chart';
import { convertToChartKey } from './chart.util';

const defaultActions: { key: ChartType; icon: any; name: string }[] = [
  { key: ChartType.Histogram, icon: <BarChartIcon />, name: 'Histogram Chart' },
  { key: ChartType.Box, icon: <CandlestickChartOutlinedIcon />, name: 'Box Plot' },
  { key: ChartType.Scatter, icon: <ScatterPlotIcon />, name: 'Scatter Plot' },
  { key: ChartType.KaplanMeier, icon: <SsidChartIcon />, name: 'Kaplan Meier' },
  { key: ChartType.DistanceBased, icon: <ExpandIcon />, name: 'Distance Based' },
];

const ChartPlaceholder = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  borderSize: 2,
  borderStyle: 'dotted',
  transform: 'translateZ(0px)',
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const AddChart: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  cohorts,
  onClick,
  actions = defaultActions,
}) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [chartPreviewType, setChartPreviewType] = React.useState<ChartType>(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setChartPreviewType(null);
  };
  const handleHoverAction = (key: ChartType) => () => setChartPreviewType(key);

  const handleSelectChart = (key: ChartType) => () => {
    handleClose();
    onClick(key);
  };

  return (
    <ChartPlaceholder
      flex="grow"
      display="flex"
      borderColor={theme.palette.grey[700]}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
    >
      <Backdrop open={open} />
      <Grow mountOnEnter={true} in={Boolean(chartPreviewType)}>
        <Box width={open ? '70%' : 0}>
          <Chart cohorts={cohorts} chartType={chartPreviewType} horizontalKey={convertToChartKey('...')} preview />
        </Box>
      </Grow>
      <Grow in={!open}>
        <Typography variant="caption">Add Chart</Typography>
      </Grow>

      <SpeedDial
        ariaLabel="Select chart type to add"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon openIcon={<ArrowUpwardIcon />} />}
        onOpen={handleOpen}
        open={open}
      >
        {map(actions, (action) => (
          <SpeedDialAction
            sx={{ color: 'primary' }}
            key={action.key}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen={chartPreviewType === action.key}
            onClick={handleSelectChart(action.key)}
            onOpen={handleHoverAction(action.key)}
            onClose={handleHoverAction(null)}
          />
        ))}
      </SpeedDial>
    </ChartPlaceholder>
  );
};

interface Props {
  onClick: (key: ChartType) => void;
  cohorts: PartialCohort[];
  actions?: { key: ChartType; icon: any; name: string }[];
}

export default AddChart;
