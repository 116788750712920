import getTileDataFromUrls from '../getTileData';

export async function getTileDataFromUrlsWebWorker(
  ...args: Parameters<typeof getTileDataFromUrls>
): Promise<Awaited<ReturnType<typeof getTileDataFromUrls>>> {
  const worker = new Worker(new URL('./worker.ts', import.meta.url));

  return new Promise<Awaited<ReturnType<typeof getTileDataFromUrls>>>((resolve, reject) => {
    worker.onmessage = function (e: MessageEvent<Awaited<ReturnType<typeof getTileDataFromUrls>>>) {
      resolve(e.data);
      worker.terminate();
    };

    worker.onerror = function (e: ErrorEvent) {
      reject(e);
      worker.terminate();
    };

    worker.postMessage({ args });
  });
}
