import { ModelType } from 'interfaces/modelType';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getModelTypeOptions = () => {
  return apiRequestHandlerPromise<ModelType[]>({
    url: `model_types`,
    method: 'GET',
  });
};

export const createModelTypeOption = (modelType: ModelType) => {
  return apiRequestHandlerPromise<ModelType>({
    url: 'model_types',
    method: 'POST',
    data: JSON.stringify(modelType),
  });
};

export const updateModelTypeOption = ({ id, ...data }: ModelType) => {
  return apiRequestHandlerPromise({
    url: `model_types/${id}`,
    method: 'POST',
    data: JSON.stringify(data),
  });
};

export const deleteModelTypeOption = (id: string) => {
  return apiRequestHandlerPromise({
    url: `model_types/${id}`,
    method: 'DELETE',
  });
};
