import { Divider, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FormResponseWithAuthor } from 'interfaces/reviewForm';
import React, { FunctionComponent } from 'react';

interface ResponseSummaryProps {
  response: FormResponseWithAuthor;
}

const ResponseSummary: FunctionComponent<ResponseSummaryProps> = ({ response }) => {
  const reviewerName = response?.author?.name ?? 'no author';

  return (
    <>
      <Divider />
      <Grid container item py={1}>
        <Grid container item>
          <Grid item>Reviewer: {reviewerName}</Grid>
        </Grid>
        {response?.submissionDate && (
          <Grid item>
            <Typography variant="body2">Last submitted at {dayjs(response.submissionDate).format('lll')}</Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ResponseSummary;
