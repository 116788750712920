import React from 'react';

import { INFOBAR_PIXEL_WIDTH } from 'components/Procedure/ProcedurePage';
import SlideControls from 'components/Procedure/SlideControls';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { min } from 'lodash';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { OrthographicMapViewState } from '../OrthographicMapview';
import ColorMapLegendOverlay from './ColorMapLegendOverlay';
import { DeckGLScaleIndicator } from './DeckGLScaleIndicator';
import LayerLoadingOverlay from './LayerLoadingOverlay';
import MousePositionOverlay from './MousePositionOverlay';
import SlideIdOverlay from './SlideIdOverlay';
import ViewerWarningOverlay from './ViewerWarningOverlay';

interface SlideViewerOverlaysProps {
  displaySlideId: boolean;
  slide: SlideWithChannelAndResults;
  viewState: OrthographicMapViewState;
  viewSize: { width: number; height: number };
  numLoadingBaseLayers: number;
  numBaseLayers: number;
  numLoadingHeatmapLayers: number;
  numHeatmapLayers: number;
  showResearchPurposesWarning: boolean;
}

export const SlideViewerOverlays: React.FC<SlideViewerOverlaysProps> = ({
  displaySlideId,
  slide,
  viewSize,
  viewState,
  numLoadingBaseLayers,
  numBaseLayers,
  numLoadingHeatmapLayers,
  numHeatmapLayers,
  showResearchPurposesWarning,
}) => {
  const [inlineSlideControls] = useQueryParam('inlineSlideControls', BooleanParam);
  return (
    <>
      {inlineSlideControls && (
        <SlideControls
          width={min([viewSize.width - 30, INFOBAR_PIXEL_WIDTH])}
          sx={{
            zIndex: 100,
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
          slide={slide}
        />
      )}
      {showResearchPurposesWarning && (
        <div
          style={{
            position: 'absolute',
            zIndex: 100,
            top: '20px',
            right: '16px',
            left: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ViewerWarningOverlay warningMessage="For research purposes only. Not for diagnostic use." />
        </div>
      )}
      <div
        style={{
          position: 'absolute',
          zIndex: 100,
          bottom: '20px',
          right: '16px',
          left: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          height: '30px',
        }}
      >
        {displaySlideId && <SlideIdOverlay slideId={slide.id} />}
        <ColorMapLegendOverlay slide={slide} />
        <div style={{ display: 'flex' }}>
          <LayerLoadingOverlay
            numLoadingBaseLayers={numLoadingBaseLayers}
            numBaseLayers={numBaseLayers}
            numLoadingHeatmapLayers={numLoadingHeatmapLayers}
            numHeatmapLayers={numHeatmapLayers}
          />

          <MousePositionOverlay viewerIndex={slide.viewerIndex} />

          {Boolean(!isNaN(slide.maxResolution) && !isNaN(slide.sizeCols) && viewState && viewSize) && (
            <DeckGLScaleIndicator viewState={viewState} maxResolution={slide.maxResolution} />
          )}
        </div>
      </div>
    </>
  );
};
