import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Chip, IconButton, Popover, useTheme } from '@mui/material';

import { CaseQueryFeature, RangeValue } from 'interfaces/caseQuery';
import { QueryFilter } from 'interfaces/cohort';
import { DataCategory, Field } from 'interfaces/genericFields';
import React from 'react';
import CalculatedFeature from './CalculatedFeatures/CalculatedFeature';
import MainFilter from './MainFilters/MainFilter';
import QueryFieldInput from './QueryFieldInput';
import useFeatureFilters, { featuresFilterOptions } from './hooks/useFeatureFilters';
import useSearchFiltersFromQueryParams from './hooks/useSearchFiltersFromQueryParams';

interface Props {
  label: string;
  onDelete?: () => void;
  category?: DataCategory | 'filters';
  field?: CaseQueryFeature | Field;
  isFeatureField?: boolean;
  onFieldChange?: (type: string, key: string, value: QueryFilter['value'], category: Field['dataCategory']) => void;
  onMainFilterChange?: (filterKey: string, value: any) => void;
  onFeatureFilterChange?: (
    index: number,
    value: string | RangeValue,
    filterType: 'select' | 'range',
    selectedFeatureKey: string
  ) => void;

  filterValue?:
    | string
    | number
    | boolean
    | QueryFilter
    | string[]
    | number[]
    | boolean[]
    | {
        start: string;
        end: string;
      };

  featureValue?: RangeValue;
  submitChange?: () => void;
  isMainFilter?: boolean;
  filterKey?: string;
  isQcFilter?: boolean;
}

const FilterChip: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  onDelete,
  field,
  filterValue,
  featureValue,
  isFeatureField,
  onFieldChange,
  onFeatureFilterChange,
  onMainFilterChange,
  submitChange = null,
  isMainFilter,
  filterKey,
  isQcFilter,
}) => {
  const [filterPopoverAnchorEl, setFilterPopoverAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const { queryParams } = useSearchFiltersFromQueryParams();
  const theme = useTheme();

  const { featuresData, featuresLoading } = useFeatureFilters({
    studyId: queryParams?.filters?.studyId,
    orchestrationId: queryParams?.filters?.orchestrationId,
  });

  const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterPopoverAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setFilterPopoverAnchorEl(null);
    submitChange();
  };

  // TODO: inferred filters edit from chips is not supported yet: https://nucleai.atlassian.net/browse/ATOM-2309
  const editUnsupported = filterKey === 'qcLabels' || filterKey === 'excludedSlides';

  return (
    <>
      <Chip
        label={label}
        onDelete={onDelete}
        sx={{
          '&.MuiChip-root': {
            '& .MuiChip-label': {
              lineHeight: 'initial',
            },
            '& .MuiChip-icon': {
              order: 1,
              ml: 0,
              mr: 1,
              p: 0,
            },
            '& .MuiChip-deleteIcon': {
              order: 2,
            },
          },
        }}
        icon={
          <IconButton
            onClick={(e) => handleEditClick(e)}
            disabled={editUnsupported}
            sx={{ color: editUnsupported ? theme.palette.grey[200] : 'initial' }}
          >
            <ExpandMoreIcon />
          </IconButton>
        }
      />
      <Popover
        open={Boolean(filterPopoverAnchorEl)}
        anchorEl={filterPopoverAnchorEl}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            p: 2,
            minWidth: '300px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {!isFeatureField ? (
          isMainFilter ? (
            <MainFilter
              filterKey={filterKey}
              filterValue={filterValue}
              onChange={(fKey, fValue) => onMainFilterChange(filterKey, fValue)}
            />
          ) : isQcFilter ? (
            // QC type filter uses the same onChange function as the main filters, even though it's not a main filter
            <QueryFieldInput
              field={field as Field}
              onChange={(type, key, val) => {
                onMainFilterChange(filterKey, val);
              }}
              value={filterValue}
            />
          ) : (
            <QueryFieldInput
              field={field as Field}
              onChange={(type, key, val) => {
                onFieldChange(type, key, val, (field as Field)?.dataCategory);
              }}
              value={filterValue}
            />
          )
        ) : (
          <CalculatedFeature
            onFieldChanged={onFeatureFilterChange}
            value={featureValue}
            featuresData={featuresData}
            featuresFilterOptions={featuresFilterOptions}
            loading={featuresLoading && Boolean(queryParams?.filters?.studyId)}
            featureIndex={0}
            selectedFeatureKey={(field as CaseQueryFeature)?.featureKey}
          />
        )}
      </Popover>
    </>
  );
};

export default FilterChip;
