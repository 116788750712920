import { BooleanParam, useQueryParam } from 'use-query-params';

export const useProcedurePageColumns = (
  maxColumns: number = 12
): {
  layout: 'large' | 'medium' | 'small';
  xs: number;
  sm: number;
  md: number;
  lg: number;
} => {
  const [advancedSearchDrawerOpen] = useQueryParam('advancedSearchDrawerOpen', BooleanParam);
  const [casesOverviewDrawerOpen] = useQueryParam('casesOverviewDrawerOpen', BooleanParam);

  const layout =
    casesOverviewDrawerOpen && advancedSearchDrawerOpen
      ? 'small'
      : advancedSearchDrawerOpen || casesOverviewDrawerOpen
      ? 'medium'
      : 'large';

  return {
    layout,
    xs: maxColumns / 12,
    sm: maxColumns / 12,
    md: maxColumns / (layout === 'small' ? 12 : layout === 'medium' ? 6 : 4),
    lg: maxColumns / (layout === 'small' ? 4 : 3),
  };
};
