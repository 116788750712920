import AWS from 'aws-sdk';
import { Upload, useUploadFilesContext } from 'components/UploadFilesContext';
import { useRef } from 'react';
import { uuidv4 } from 'utils/helpers';
import { deleteAwsTemporaryCredentials, getAwsTemporaryCredentials, getS3UploadObject } from './S3Utils';
export interface UploadItem {
  id: string;
  file: File;
  managedUpload?: AWS.S3.ManagedUpload;
}

export const useS3Upload = (labId: string, studyId: string) => {
  const { setFileUploads, setShowUploadWidget } = useUploadFilesContext();
  const uploadsQueueRef = useRef<Array<{ id: string; fileToUpload: File }>>([]);
  const isBusy = useRef(false);

  const getCredentialsAndStartUpload = async (file: File) => {
    const uploadId = uuidv4();
    const optimisticItem: Upload = {
      id: uploadId,
      file,
      managedUpload: undefined,
    };

    uploadsQueueRef.current.push({ id: uploadId, fileToUpload: file });
    setFileUploads((prevFileUploads) => ({
      ...prevFileUploads,
      [uploadId]: optimisticItem,
    }));
    setShowUploadWidget(true);

    if (isBusy.current) {
      return;
    }

    isBusy.current = true;
    const creds = await getAwsTemporaryCredentials(labId);

    const uploadPromises = [];

    while (uploadsQueueRef.current.length) {
      const { id, fileToUpload } = uploadsQueueRef.current.shift();

      const upload = getS3UploadObject(creds.Credentials, labId, studyId, fileToUpload);

      const item: Upload = {
        id,
        file: fileToUpload,
        managedUpload: upload,
        progress: 0,
        error: undefined,
      };

      upload.on('httpUploadProgress', (progress) => {
        const uploadedBytes = progress.loaded;
        const totalBytes = progress.total;
        const percentage = Math.round((uploadedBytes / totalBytes) * 100);
        setFileUploads((prevFileUploads) => ({ ...prevFileUploads, [id]: { ...item, progress: percentage } }));
      });

      const promise = new Promise((resolve, reject) => {
        upload.send((err, data) => {
          if (err) {
            console.error(err.code, err.message);
            setFileUploads((prevFileUploads) => ({ ...prevFileUploads, [id]: { ...item, error: err } }));
            if (err.code !== 'RequestAbortedError') {
              reject(err);
              return;
            }
          }

          resolve(data);
        });
      });

      uploadPromises.push(promise);
    }

    try {
      await Promise.allSettled(uploadPromises);
    } finally {
      isBusy.current = false;
      await deleteAwsTemporaryCredentials(creds.Credentials);
    }
  };

  return { getCredentialsAndStartUpload };
};
