import { Box } from '@mui/system';
import Markdown from 'markdown-to-jsx';
import React, { FunctionComponent } from 'react';

interface Props {
  content: string;
}

const MarkdownRenderer: FunctionComponent<React.PropsWithChildren<Props>> = ({ content }) => {
  // Add options to MarkdownRenderer - to open all <a> links in the markdown content in target=blank and rel="noopener noreferrer"
  const options = {
    overrides: {
      a: {
        props: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    },
  };

  return (
    <Box sx={{ p: 3 }}>
      <Markdown options={options}>{content}</Markdown>
    </Box>
  );
};

export default MarkdownRenderer;
