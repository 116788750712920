import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { RangeValue } from 'interfaces/caseQuery';
import { Study } from 'interfaces/study';
import { isEmpty, map } from 'lodash';
import React from 'react';
import useFeatureFilters, { featuresFilterOptions } from '../hooks/useFeatureFilters';
import useSearchFiltersFromQueryParams from '../hooks/useSearchFiltersFromQueryParams';
import CalculatedFeature from './CalculatedFeature';

interface Props {
  study: Study;
  studyIdInput: string;
  ruleBoxes: {}[];
  onAddRule: () => void;
  featuresFilters: any[];
  onFeatureFilterChange: (
    index: number,
    value: string | RangeValue,
    filterType: 'select' | 'range',
    selectedFeatureKey: string
  ) => void;
  onDeleteFeatureFilter: (index: number) => void;
  enabled: boolean;
}

const CalculatedFeatures = ({
  study,
  studyIdInput,
  ruleBoxes,
  onAddRule,
  featuresFilters,
  onFeatureFilterChange,
  onDeleteFeatureFilter,
  enabled,
}: Props) => {
  const theme = useTheme();
  const studyIdIsSpecific = Boolean(study);
  const { queryParams } = useSearchFiltersFromQueryParams();
  const { featuresData, featuresLoading } = useFeatureFilters({
    studyId: studyIdInput,
    orchestrationId: queryParams?.filters?.orchestrationId,
    enabled,
  });

  return (
    <Grid container item direction="column" justifyContent="center">
      <Grid
        item
        container
        sx={{
          mt: 2,
          border: isEmpty(ruleBoxes) ? 'none' : `1px solid ${theme.palette.grey[300]}`,
          borderRadius: '5px',
        }}
      >
        {map(ruleBoxes, (ruleBox, ruleBoxIndex) => (
          <CalculatedFeature
            disabled={!studyIdIsSpecific}
            key={`${ruleBoxIndex}-${JSON.stringify(ruleBox)}`}
            onClose={() => {
              onDeleteFeatureFilter(ruleBoxIndex);
            }}
            onFieldChanged={onFeatureFilterChange}
            value={featuresFilters && featuresFilters[ruleBoxIndex]?.value}
            featuresData={featuresData}
            featuresFilterOptions={featuresFilterOptions}
            loading={featuresLoading && Boolean(studyIdInput)}
            featureIndex={ruleBoxIndex}
            selectedFeatureKey={featuresFilters && featuresFilters[ruleBoxIndex]?.featureKey}
          />
        ))}
      </Grid>

      <Grid item container direction="row-reverse">
        <Grid item sx={{ mt: 1 }}>
          <Button disabled={!studyIdIsSpecific} onClick={onAddRule}>
            <AddIcon />
            New Rule
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CalculatedFeatures;
