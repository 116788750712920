import { DisplayedField } from 'interfaces/genericFields';
import { SlideTag } from 'interfaces/slideTag';

export const idField: DisplayedField<SlideTag, string> = {
  filterType: 'multiSelect',
  dataKey: 'id',
  label: 'SlideTag ID',
  columnWidth: { width: 90 },
};

export const tagValueField: DisplayedField<SlideTag, string> = {
  cellEditType: 'text',
  filterType: 'text', // TODO: change to multiSelect (based on search filter perhaps) or text search
  dataKey: 'tagValue',
  label: 'Name',
  columnWidth: { width: 150 },
};

export const perStudyField: DisplayedField<SlideTag, boolean> = {
  cellEditType: 'checkbox',
  filterType: 'checkbox',
  dataKey: 'perStudy',
  label: 'Per Study',
  columnWidth: { width: 100 },
};

export const perExperimentResultField: DisplayedField<SlideTag, boolean> = {
  cellEditType: 'checkbox',
  filterType: 'checkbox',
  dataKey: 'perExperimentResult',
  label: 'Per Experiment Result',
  columnWidth: { width: 150 },
};

export const uiOrderField: DisplayedField<SlideTag, number> = {
  cellEditType: 'number',
  filterType: 'range',
  dataKey: 'uiOrder',
  label: 'Order in UI',
  columnWidth: { width: 100 },
};

export const slideTagFields: Array<DisplayedField<SlideTag, any>> = [
  idField,
  tagValueField,
  perStudyField,
  perExperimentResultField,
  uiOrderField,
];
