import React, { FunctionComponent } from 'react';

import { Procedure } from 'interfaces/procedure';
import { flatMap } from 'lodash';
import { DataGridInterfaceWrapper } from '../ProceduresPage/DataGrids/DataGridInterfaceWrapper';

interface NotEditableSlidesDataGridProps {
  procedures: Procedure[];
}

const NotEditableSlidesDataGrid: FunctionComponent<React.PropsWithChildren<NotEditableSlidesDataGridProps>> = ({
  procedures,
}) => {
  return (
    <DataGridInterfaceWrapper
      forceHeight={650}
      casesInPage={procedures}
      hidePagination
      totalSlides={flatMap(procedures, (procedure) => procedure.slides).length}
      totalCases={procedures.length}
      isLoading={false}
      disableEditing
      pendingSlidesMode={false}
      hideActionsMenu
    />
  );
};

export default NotEditableSlidesDataGrid;
