import React, { useMemo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, FilterOptionsState, Grid, IconButton, TextField, useTheme } from '@mui/material';
import { VirtualizedAutocomplete } from 'components/FeaturesDashboard/KeySelect/VirtualizedAutocomplete';
import { RangeValue } from 'interfaces/caseQuery';
import { NamedFeature } from 'interfaces/features';
import { find, isArray } from 'lodash';
import { useGetFeatureDisplayNameWithContext } from 'utils/features/contextHooks';
import CalculatedFeatureRange from './CalculatedFeatureRange';

interface Props {
  value: RangeValue;
  selectedFeatureKey: string;
  featuresData: NamedFeature[];
  featuresFilterOptions: (options: NamedFeature[], state: FilterOptionsState<NamedFeature>) => NamedFeature[];
  loading?: boolean;
  onFieldChanged: (
    index: number,
    value: string | RangeValue,
    filterType: 'select' | 'range',
    selectedFeatureKey: string
  ) => void;
  onClose?: () => void;
  featureIndex: number;
  disabled?: boolean;
}

const CalculatedFeature = ({
  onFieldChanged,
  value,
  featuresData,
  featuresFilterOptions,
  loading,
  onClose,
  featureIndex,
  selectedFeatureKey,
  disabled,
}: Props) => {
  const theme = useTheme();
  const { getFeatureDisplayNameWithContext, isLoadingFormatterData } = useGetFeatureDisplayNameWithContext(true);

  const currentFeature = find(featuresData, { key: selectedFeatureKey });
  const currentValue = useMemo(() => {
    if (currentFeature) {
      return currentFeature;
    }
    return {
      key: selectedFeatureKey,
      displayName: getFeatureDisplayNameWithContext(selectedFeatureKey),
      nameOverride: '',
    };
  }, [currentFeature, selectedFeatureKey, getFeatureDisplayNameWithContext]);
  const options = useMemo(() => {
    if (currentFeature) {
      return featuresData;
    }
    return [currentValue, ...(isArray(featuresData) ? featuresData : [])];
  }, [currentFeature, featuresData, currentValue]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      spacing={2}
      sx={{
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[800],
        p: 1,
        pb: 2,
        width: '100%',
        mt: 0,
        ml: 0,

        '& .MuiGrid-root': {
          '&.MuiGrid-item': {
            pl: 0,
            '&:first-of-type': {
              pt: 0,
            },
          },
        },
      }}
    >
      <Grid item sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <IconButton onClick={onClose} sx={{ p: 0 }} disabled={disabled}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <VirtualizedAutocomplete<NamedFeature>
          filterOptions={featuresFilterOptions}
          disabled={disabled || loading || isLoadingFormatterData}
          size="small"
          options={options}
          value={currentValue}
          onChange={(e, feature) => {
            onFieldChanged(featureIndex, null, 'select', feature?.key);
          }}
          getOptionLabel={(feature) => feature?.nameOverride || feature?.displayName}
          renderOption={(option) => ({
            key: option?.key,
            displayName: option?.nameOverride || option?.displayName,
          })}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Feature"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>

      <Grid item>
        <CalculatedFeatureRange
          value={value}
          onChange={(rangeValue: RangeValue) => onFieldChanged(featureIndex, rangeValue, 'range', selectedFeatureKey)}
        />
      </Grid>
    </Grid>
  );
};

export default CalculatedFeature;
