/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  CircularProgress,
  DialogTitle,
  Divider,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useMutation } from '@tanstack/react-query';
import { createStudy } from 'api/study';
import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import UploadManifest from 'components/atoms/UploadManifest/UploadManifest';
import formats from 'constants/manifestFormats';
import { CancerType } from 'interfaces/cancerType';
import { isEmpty, map, orderBy } from 'lodash';
import React, { ReactElement, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useAppSelector } from 'redux/hooks';
import { useS3Upload } from 'services/useS3Upload';
import { isDemoLab } from 'utils/demo';
import { humanize } from 'utils/helpers';
import queryClient from 'utils/queryClient';
import { useCurrentLabId } from 'utils/useCurrentLab';
import * as yup from 'yup';
import { PROJECT, SELF_SERVICE } from '.';
import StudyPermissions from './StudyPermissions';

const UploadStudyForm = (props: IFormProps): ReactElement => {
  const { onClose, cancerTypes, isAdmin, labs = [] } = props;

  const [isSaveStudyFail, setIsSaveStudyFail] = useState(false);
  const [duringUpload, setDuringUpload] = useState(false);
  const [cancerTypeId, setCancerTypeId] = React.useState<number>(-1);
  const { labId, setLabId } = useCurrentLabId();
  const { cheatMode } = useAppSelector((state) => state.cheatMode);

  const currentStudyId = useRef<string | null>(null);
  const { getCredentialsAndStartUpload } = useS3Upload(labId, currentStudyId.current);

  const {
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
    watch,
    reset,
    resetField,
    formState: { errors },
    getValues,
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: { isProject: false, labId: labId },
  });

  const watchIsProject = watch('isProject', false);

  const onSubmit: SubmitHandler<IFormValues> = async (data) => {
    const convertedData = {
      ...data,
      cancer_type_id: cancerTypeId.toString(),
      labId,
      service_type: watchIsProject ? PROJECT : SELF_SERVICE,
    };

    uploadStudy(convertedData);
  };

  const labOptions = map(labs, (lab) => ({
    value: lab.id,
    text: lab.name,
  }));

  const cancerTypeOptions = orderBy(
    map(cancerTypes, (cancerType) => ({
      text: cancerType.displayName,
      value: cancerType.id,
    })),
    'text'
  );

  const onSuccess = () => {
    reset();
    setDuringUpload(false);
    onClose();
  };

  const onFinishUploadStudy = async (studyId: string, manifestFile: File) => {
    currentStudyId.current = studyId;
    if (manifestFile && currentStudyId.current) {
      await getCredentialsAndStartUpload(manifestFile);

      setDuringUpload(false);
    }
    onSuccess();
  };

  const createStudyMutation = useMutation(createStudy, {
    onSuccess: () => {
      queryClient.invalidateQueries(['studies', labId]);
    },
  });

  const uploadStudy = async (data: IFormValues) => {
    setDuringUpload(true);
    setIsSaveStudyFail(false);

    try {
      const { study_id: studyId } = await createStudyMutation.mutateAsync(data);
      return await onFinishUploadStudy(studyId, data.manifestFile);
    } catch (err) {
      setIsSaveStudyFail(true);
      setDuringUpload(false);
    }
  };

  return (
    <form data-testid="upload-study-form" onSubmit={handleSubmit(onSubmit)}>
      <Snackbar open={isSaveStudyFail} autoHideDuration={6000} message="Failed to save study, please try again" />
      <Grid container direction="column" px={2}>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item>
            <DialogTitle variant="h1" sx={{ textTransform: 'uppercase', p: 0, pt: 1, px: 0 }}>
              Create Study
            </DialogTitle>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose} sx={{ p: 0 }}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container direction="column" spacing={3} mt={1}>
          <Grid item container spacing={2} direction="column">
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="study_name"
                  render={({ field: { onChange } }) => (
                    <TextField
                      label="Name"
                      {...register('study_name')}
                      onChange={onChange}
                      placeholder="Type Here"
                      error={Boolean(errors['study_name'])}
                      helperText={humanize(errors['study_name']?.message)}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="cancer_type_id"
                  render={({ field: { onChange, value } }) => (
                    <LabelledDropdown
                      label="Indication"
                      inputRef={register('cancer_type_id') as unknown as React.RefObject<HTMLSelectElement>}
                      options={cancerTypeOptions}
                      value={value}
                      onOptionSelected={(optionValue) => {
                        onChange(optionValue);
                        setCancerTypeId(Number(optionValue));
                      }}
                      error={Boolean(errors['cancer_type_id'])}
                      helperText={humanize(errors['cancer_type_id']?.message)}
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="external_study_id"
                  render={({ field: { onChange } }) => (
                    <TextField
                      label="External Study Id"
                      {...register('external_study_id')}
                      onChange={onChange}
                      placeholder="Type Here"
                    />
                  )}
                />
              </Grid>
              {isAdmin && (
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="labId"
                    defaultValue={labId}
                    render={({ field: { onChange, value } }) => (
                      <LabelledDropdown
                        dataTestId="select-lab"
                        label="Lab"
                        required
                        inputRef={register('labId') as unknown as React.RefObject<HTMLSelectElement>}
                        options={labOptions}
                        value={value || labId}
                        onOptionSelected={(optionValue) => {
                          onChange(optionValue);
                          setLabId(optionValue, 'replaceIn');
                        }}
                        error={!isEmpty(errors?.['labId']?.message)}
                        helperText={humanize(errors?.['labId']?.message)}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>

            {isAdmin && (
              <Grid item justifyContent="flex-end">
                <Box data-testid="service-type-input" display="flex" width="65px" justifyContent="space-between">
                  <Controller
                    control={control}
                    name="isProject"
                    render={({ field: { onChange } }) => <input type="checkbox" onChange={onChange} />}
                  />
                  <span>Project</span>
                </Box>
              </Grid>
            )}
            <Grid item mt={0.5}>
              <Controller
                control={control}
                name="description"
                render={({ field: { onChange } }) => (
                  <TextField
                    {...register('description')}
                    onChange={onChange}
                    placeholder="Type Here"
                    multiline
                    label="Description"
                    minRows={6}
                  />
                )}
              />
            </Grid>
            {!isEmpty(cheatMode) && isDemoLab(labId) && (
              <Grid item container direction="column" spacing={2}>
                <Grid item mt={1}>
                  <Divider />
                </Grid>
                <Grid item>
                  <Typography variant="h4">Permission Settings</Typography>
                </Grid>
                <Grid item container>
                  <StudyPermissions control={control} />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <UploadManifest
              getValues={getValues}
              clearErrors={clearErrors}
              setError={setError}
              resetField={resetField}
              manifestFileErrors={errors.manifestFile}
              control={control}
              manifestFile={watch('manifestFile')}
            />
            <Box display="flex" flexDirection="row-reverse">
              <Button
                disableElevation
                variant="contained"
                color="primary"
                data-testid="done-button"
                disabled={duringUpload}
                onClick={handleSubmit(onSubmit)}
              >
                {duringUpload ? <CircularProgress color="inherit" size={20} /> : 'Create study'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export interface IFormProps {
  onClose: () => void;
  cancerTypes: CancerType[];
  labs?: Record<string, string>[];
  isAdmin: boolean;
}

export interface IFormValues extends IStudy {
  manifestFile?: File;
}

interface IStudy {
  study_name: string;
  description: string;
  external_study_id: string;
  cancer_type_id: string;
  labId: string;
  isProject: boolean;
}

const schema = yup.object({
  study_name: yup.string().required(),
  cancer_type_id: yup.string().required('Indication is required'),
  external_study_id: yup.string(),
  description: yup.string(),
  labId: yup.string(),
  isProject: yup.boolean(),
  manifestFile: yup.mixed().test('fileType', 'File must be a csv/xlsx file', (value: File) => {
    return !value || formats.includes(value?.type);
  }),
});

export default UploadStudyForm;
