import InfoIcon from '@mui/icons-material/Info';

import { Button, Checkbox, CircularProgress, Grid, IconButton, Popover, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getInternalUsers } from 'api/userCredentials';
import { defaultRowsPerPage, useJobs } from 'components/Pages/Jobs/useJobs';
import { Job, JobType } from 'interfaces/job';
import { concat, includes, keyBy, map, some } from 'lodash';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

export interface OldJobsStepProps {
  jobType: JobType;
  selectedJobId: string;
  onSelectedJob: (newValue: Job) => void;
}

export const OldJobsStep = ({ jobType, selectedJobId, onSelectedJob }: OldJobsStepProps): ReactElement => {
  const [jobOptions, setJobOptions] = useState<Job[]>([]);
  const [selectedJob, setSelectedJob] = useState<Job>(undefined);
  const [infoClickedJob, setInfoClickedJob] = useState<Job>(undefined);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const { data: jobs, isLoading: isJobsLoading } = useJobs({
    page,
    fullData: true,
    additionalFilters: { type: jobType },
  });
  const { data: internalUsers } = useQuery(['internalUsers'], getInternalUsers);
  const internalUsersById = useMemo(() => keyBy(internalUsers, 'id'), [internalUsers]);

  const getJobInfoToShow = (job: Job) => {
    if (!job) {
      return {};
    }
    return {
      id: job.id,
      user: internalUsersById[job.userId]?.name ?? internalUsersById[job.userId]?.primaryEmail ?? job.userId,
      name: job.name,
      description: job.description,
      status: job.status,
      updatedAt: job.updatedAt,
      orchestrationId: job.orchestrationId,
      params: job.params,
    };
  };

  const jobInfoToShow = useMemo(() => getJobInfoToShow(infoClickedJob), [infoClickedJob]);

  useEffect(() => {
    if (jobs) {
      if (jobs.totalJobs > page * defaultRowsPerPage) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      if (some(jobs?.jobs, (job) => !includes(map(jobOptions, 'id'), job.id))) {
        setJobOptions((prevOption) => concat(prevOption, jobs?.jobs));
      }

      if (selectedJobId) {
        setSelectedJob(jobs?.jobs.find((job) => job.id === selectedJobId));
      }
    }
  }, [jobs]);

  const handleLoadMore = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
  }, []);

  const onSelectedJobParamChange = (newValue: Job) => {
    handleCloseJobDetails();
    if (selectedJob?.id === newValue.id) {
      setSelectedJob(undefined);
      onSelectedJob(undefined);
    } else {
      setSelectedJob(newValue);
      onSelectedJob(newValue);
    }
  };

  const [anchorElJobDetails, setAnchorElJobDetails] = useState<null | HTMLElement>(null);

  const handleInfoClick = (event: React.MouseEvent<HTMLButtonElement>, option: any) => {
    event.stopPropagation();
    setInfoClickedJob(option);
    setAnchorElJobDetails(event.currentTarget);
  };
  const handleCloseJobDetails = () => {
    setAnchorElJobDetails(null);
  };
  const openInfoPopover = Boolean(anchorElJobDetails);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        {map(jobOptions, (job) => {
          return (
            <Grid container wrap="nowrap" alignItems="center" spacing={1}>
              <Grid item>
                <Checkbox checked={selectedJob?.id === job.id} onChange={() => onSelectedJobParamChange(job)} />
              </Grid>
              <Grid item>{`${job.id}- ${job.name}`}</Grid>
              <Grid item>
                <IconButton onClick={(e) => handleInfoClick(e, job)}>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      </Grid>

      {isJobsLoading && (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )}
      <Grid item>
        <Button onClick={handleLoadMore} disabled={!hasMore}>
          Load More
        </Button>
      </Grid>

      <Popover
        open={openInfoPopover}
        anchorEl={anchorElJobDetails}
        onClose={handleCloseJobDetails}
        disablePortal
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            zIndex: 1400,
            maxWidth: '500px', // Control the width of the popover
            padding: '10px',
          },
        }}
      >
        <Typography component="pre">{JSON.stringify(jobInfoToShow, null, 2)}</Typography>
      </Popover>
    </Grid>
  );
};
