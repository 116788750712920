import { Viewport } from '@deck.gl/core/typed';
import { GeoJsonLayer } from '@deck.gl/layers/typed';
import { Paper, Popper, Tooltip, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { Feature } from '@turf/helpers';
import { isEqual } from 'lodash';
import React from 'react';
import { useClipboard } from 'use-clipboard-copy';

import HtmlDeckGLOverlay from 'components/HtmlDeckGLOverlay';
import HtmlDeckGLOverlayItem from 'components/HtmlDeckGLOverlay/HtmlDeckGLOverlayItem';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { viewerHoverData } from './viewerDataSignals';

const DEFAULT_COORDINATES = [0, 0] as [number, number];

export const PMTFeaturePopover: React.FC<{
  slide: SlideWithChannelAndResults;
  viewport: Viewport;
}> = ({ slide, viewport }) => {
  useSignals();
  const [hoverDataPickingInfo, hoverDataPickingEvent] = viewerHoverData[slide.viewerIndex]?.value || [];

  const isGeoJsonLayer = hoverDataPickingInfo?.sourceLayer instanceof GeoJsonLayer;

  const [featureForContextMenu, setFeatureForContextMenu] = React.useState<Feature | null>(null);
  const [popoverCoordinates, setPopoverCoordinates] = React.useState<[number, number] | null>(null);

  React.useEffect(() => {
    if (isGeoJsonLayer) {
      const newFeatureForContextMenu = hoverDataPickingInfo?.object as Feature;
      const newPopoverCoordinates = newFeatureForContextMenu?.properties?.name
        ? (hoverDataPickingInfo?.coordinate as [number, number])
        : undefined;
      if (newPopoverCoordinates && !isEqual(newPopoverCoordinates, popoverCoordinates)) {
        setFeatureForContextMenu(newFeatureForContextMenu);
        setPopoverCoordinates(newPopoverCoordinates);
      }
    }
  }, [isGeoJsonLayer, hoverDataPickingInfo?.object, hoverDataPickingInfo?.coordinate]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement>(null);

  const displayText = featureForContextMenu?.properties?.name || featureForContextMenu?.properties?.id;
  const clipboard = useClipboard({ copiedTimeout: 1000 });

  const handleCopy = () => {
    clipboard.copy(displayText);
  };

  return (
    <HtmlDeckGLOverlay viewport={viewport}>
      <HtmlDeckGLOverlayItem
        key="contextMenu"
        draggable={false}
        coordinates={popoverCoordinates || DEFAULT_COORDINATES}
      >
        <div ref={(el) => setAnchorEl(el)} />
        {isGeoJsonLayer && Boolean(featureForContextMenu) && Boolean(popoverCoordinates) && displayText && (
          <Popper id="annotationContextMenu" open anchorEl={anchorEl} placement="top">
            <Paper sx={{ m: 1 }}>
              <Tooltip title={clipboard.copied ? 'Copied!' : undefined} placement="top">
                <Typography variant="caption" sx={{ p: 1 }} onClick={handleCopy}>
                  {displayText}
                </Typography>
              </Tooltip>
            </Paper>
          </Popper>
        )}
      </HtmlDeckGLOverlayItem>
    </HtmlDeckGLOverlay>
  );
};
