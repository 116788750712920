import { useQuery } from '@tanstack/react-query';
import { getCohorts } from 'api/cohorts';
import { filter, isEmpty, map } from 'lodash';
import { useCurrentLabId } from 'utils/useCurrentLab';

type QueryOption = {
  value: string | 0;
  text: string;
};

const useCohortFilters = () => {
  const { labId } = useCurrentLabId();
  const { data: cohorts, isLoading: cohortsLoading } = useQuery(['cohorts', labId], () => getCohorts(labId));

  const defaultOption: QueryOption = { value: 0, text: 'All' };

  const snapshotCohorts = filter(cohorts, (cohort) => Boolean(cohort.casesSnapshot));
  const queries = filter(cohorts, (cohort) => !cohort.casesSnapshot);

  const queryOptions: QueryOption[] = map(queries, (query) => ({
    value: query.id,
    text: query.name,
  }));

  if (!isEmpty(queryOptions)) {
    queryOptions.unshift(defaultOption);
  }

  const snapshotCohortOptions: QueryOption[] = map(snapshotCohorts, (cohort) => ({
    value: cohort.id,
    text: cohort.name,
  }));

  return {
    snapshotCohortOptions,
    queryOptions,
    snapshotCohorts,
    queries,
    cohorts,
    cohortsLoading,
  };
};

export default useCohortFilters;
