import { Drawer, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getJob } from 'api/jobs';
import { jobDrawerWidth } from 'components/atoms/FlexContainers/FlexContainers';
import { SITE_HEADER_HEIGHT } from 'components/SiteHeader/SiteHeader';
import { isEmpty } from 'lodash';
import React from 'react';
import { JobDetails } from './JobDetails';

export const JobDrawer: React.FC<
  React.PropsWithChildren<{
    headerHeight?: number | string;
    jobDrawerOpen: boolean;
    setJobDrawerOpen: (open: boolean) => void;
    currentJobId: string;
  }>
> = ({ jobDrawerOpen, setJobDrawerOpen, currentJobId, headerHeight = SITE_HEADER_HEIGHT }) => {
  const theme = useTheme();

  const {
    data: job,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['job', currentJobId],
    queryFn: ({ signal }) => getJob(currentJobId, signal),
    refetchOnMount: 'always',
    enabled: !isEmpty(currentJobId),
  });

  return (
    <Drawer
      key={job?.id}
      anchor={'right'}
      open={jobDrawerOpen}
      onClose={() => setJobDrawerOpen(false)}
      variant={'persistent'}
      sx={{
        width: jobDrawerWidth,
        [`& .MuiDrawer-paper`]: {
          width: jobDrawerWidth,
          zIndex: theme.zIndex.appBar - 1,
          height: `calc(100% - ${headerHeight})`,
          marginTop: headerHeight,
        },
      }}
    >
      <JobDetails job={job} isLoading={isLoading || isFetching} onClosed={() => setJobDrawerOpen(false)} />
    </Drawer>
  );
};
