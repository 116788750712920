import { DataGrid, DataGridProps, GridCellEditStopReasons, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { filter, find, fromPairs, get, includes, isEqual, map, reduce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { CaseUpdate, getUpdatesFromCase } from 'api/study';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { useEditableFieldsDataGridColumns } from 'components/atoms/EditableDataGrid/useEditableFieldsDataGridColumns';
import { useRowSelectionContext } from 'components/atoms/RowSelectionContext';
import { getCheckboxColumn } from 'components/atoms/RowSelectionContext/checkboxColumn';
import { DisplayedField } from 'interfaces/genericFields';
import { Procedure } from 'interfaces/procedure';
import { slideFieldsToExcludeFromEditPanelInCaseMode } from 'interfaces/procedure/fields';
import { ProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import { useCaseUpdateMutation } from './useCaseUpdateMutation';

export interface CasesDataGridProps {
  casesInPage: Procedure[];
  totalRows: number;
  isLoading: boolean;
  disableEditing?: boolean;
  pendingSlidesMode?: boolean;
  selectedFields: string[];
  orderedFields: Array<DisplayedField<Procedure, any, ProceduresFieldsContext>>;
  dataGridProps?: Partial<DataGridProps>;
  onCaseRowChange?: (caseId: number, changes: Partial<CaseUpdate>) => void;
}

export const CasesDataGrid: React.FC<React.PropsWithChildren<CasesDataGridProps>> = ({
  dataGridProps,
  totalRows,
  casesInPage,
  isLoading,
  disableEditing,
  pendingSlidesMode,
  selectedFields,
  orderedFields,
  onCaseRowChange,
}) => {
  const { queryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const { isRowSelected } = useRowSelectionContext();
  const { bulkEditMode, bulkChanges, getRowsWithChanges } = useTableEditingContext<
    Procedure,
    ProceduresFieldsContext
  >();
  const { hasMetadataEditingPermissions, hasClinicalDataEditingPermissions } = usePermissions();

  const rowsWithChanges = useMemo(() => {
    return getRowsWithChanges(casesInPage, isRowSelected);
  }, [getRowsWithChanges, casesInPage, isRowSelected]);

  const [tableRows, setTableRows] = useState(rowsWithChanges);
  useEffect(() => {
    setTableRows(rowsWithChanges);
  }, [rowsWithChanges]);

  const caseUpdateMutation = useCaseUpdateMutation({
    additionalOnError: () => cancelEditing(),
    additionalOnSuccess: () => (editingRow.current = null),
  });

  // Move to wrapper component
  const columnVisibilityModel: { [fieldName: string]: boolean } = React.useMemo(() => {
    return fromPairs(map(orderedFields, (field) => [field.dataKey, includes(selectedFields, field.dataKey)]));
  }, [selectedFields, orderedFields]);

  const noRows = totalRows === 0;
  const procedureFieldsColumns = useEditableFieldsDataGridColumns({
    fields: filter(
      map(orderedFields, (field) => {
        const shouldDisableMetadataEditing = !hasMetadataEditingPermissions && field.dataCategory === 'metadata';
        const shouldDisableClinicalDataEditing =
          !hasClinicalDataEditingPermissions && field.dataCategory === 'clinical';
        if (shouldDisableMetadataEditing || shouldDisableClinicalDataEditing) {
          return {
            ...field,
            cellEditType: undefined,
            unwoundRowCellEditType: undefined,
          };
        }
        return field;
      }),
      (f) => !includes(slideFieldsToExcludeFromEditPanelInCaseMode, f.dataKey)
    ),
    isLoading,
    noRows,
    bulkEditMode,
    shouldApplyBulkChangesToRow: isRowSelected,
  });

  const columns: GridColDef<Procedure>[] = pendingSlidesMode
    ? procedureFieldsColumns
    : [getCheckboxColumn(totalRows, bulkEditMode), ...procedureFieldsColumns];

  const getCellClassName: DataGridProps<Procedure>['getCellClassName'] = React.useCallback(
    ({ id, field }) => {
      if (
        (editingRow.current?.id === Number(id) &&
          get(editingRow.current, field) !== get(find(tableRows, { id: Number(id) }), field)) ||
        (bulkChanges &&
          field in bulkChanges &&
          isRowSelected(id) &&
          get(find(casesInPage, { id: Number(id) }), field) !== bulkChanges[field])
      ) {
        return 'cell-value-changed';
      } else {
        return '';
      }
    },
    [casesInPage, bulkChanges, isRowSelected, tableRows]
  );

  const currentPageIds = React.useMemo(() => map(casesInPage, 'id'), [casesInPage]);

  const editingRow = React.useRef<Procedure | null>(null);

  const handleCellEditStart: DataGridProps['onCellEditStart'] = (params) => {
    editingRow.current = find(tableRows, (row) => row.id === params.id) || null;
  };
  const cancelEditing = () => {
    setTableRows((prevRows) => map(prevRows, (row) => (row.id === editingRow.current?.id ? editingRow.current : row)));
    editingRow.current = null;
  };

  const handleCellEditStop: DataGridProps<Procedure>['onCellEditStop'] = (params) => {
    // Stop editing a row when the user presses escape
    if (params.reason === GridCellEditStopReasons.escapeKeyDown) {
      cancelEditing();
      return;
    }
  };
  const apiRef = useGridApiRef();

  const processRowUpdate = (newRow: Procedure, oldRow: Procedure) => {
    const updatedFields: Partial<CaseUpdate> = reduce(
      newRow,
      function (result, value, key) {
        return isEqual(value, get(oldRow, key)) ? result : { ...result, [key]: value };
      },
      {}
    );

    if (isEqual(updatedFields, {})) {
      return newRow;
    }

    if (onCaseRowChange) {
      onCaseRowChange(Number(newRow.id), updatedFields);
    } else {
      caseUpdateMutation.mutate({
        studyId: newRow?.studyId,
        caseId: Number(newRow.id),
        update: getUpdatesFromCase(updatedFields),
        filterParams: {
          features: queryParams?.features,
          filters: queryParams?.filters,
          clinicalData: queryParams?.clinicalData,
          labId: queryParams?.labId,
        },
      });
    }

    return newRow;
  };

  return (
    <DataGrid
      {...dataGridProps}
      apiRef={apiRef}
      loading={isLoading}
      getCellClassName={getCellClassName}
      columnVisibilityModel={columnVisibilityModel}
      rowSelectionModel={filter(currentPageIds, isRowSelected)}
      rows={tableRows}
      rowCount={totalRows}
      columns={columns}
      isCellEditable={() => !disableEditing && !caseUpdateMutation.isLoading && !bulkEditMode}
      onCellEditStop={handleCellEditStop}
      onCellEditStart={handleCellEditStart}
      processRowUpdate={processRowUpdate}
    />
  );
};
