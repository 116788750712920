import { Signal, signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import {
  Dictionary,
  entries,
  find,
  findIndex,
  findLastIndex,
  first,
  isEmpty,
  max,
  min,
  orderBy,
  reduce,
  times,
  values,
} from 'lodash';
import { useCallback, useEffect, useMemo, useState, useTransition } from 'react';

import {
  getNormalizationValues,
  getNormalizationValuesQueryKey,
  getSlideChannelNormalizations,
  getSlideChannelNormalizationsQueryKey,
} from 'api/slideMultiplexChannelNormalizations';
import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { NormalizationResult } from 'interfaces/experimentResults';
import { SlideChannel } from 'interfaces/slide';
import { MULTIPLEX_STAIN_ID } from 'interfaces/stainType';
import { JsonParam, useQueryParam } from 'use-query-params';
import { MAX_UINT16, MAX_UINT8 } from 'utils/constants';
import { useCurrentLabId } from 'utils/useCurrentLab';
import usePrevious from 'utils/usePrevious';
import { useQueryWithErrorAndRetrySnackbar } from 'utils/useQueryWithErrorAndRetrySnackbar';

export type NormalizationRange = [min: number, max: number];

// { [slideId]: { [channelId]: Signal<NormalizationRange> } }
export const slidesChannelNormalizationSettings: Array<Signal<Dictionary<Dictionary<Signal<NormalizationRange>>>>> =
  times(MAX_VIEWERS, () => signal({}));

export const useMultiplexSlideChannelNormalizationsOptions = ({ slide }: { slide: SlideWithChannelAndResults }) => {
  const { labId } = useCurrentLabId();
  const {
    data: unsortedNormalizationOptions,
    isLoading: isLoadingNormalizationOptions,
    refetch,
  } = useQueryWithErrorAndRetrySnackbar(
    getSlideChannelNormalizationsQueryKey({ id: slide.id, labId: slide.labId ?? labId }),
    ({ signal: abortSignal }) =>
      getSlideChannelNormalizations({ id: slide.id, labId: slide.labId ?? labId }, abortSignal),
    { queriedEntityName: 'normalizations', enabled: slide?.stainingType === MULTIPLEX_STAIN_ID }
  );

  const normalizationOptions = useMemo(
    () => orderBy(unsortedNormalizationOptions, ['createdAt'], ['desc']),
    [unsortedNormalizationOptions]
  );

  const defaultNormalization =
    find(normalizationOptions, { approved: true }) || // Use the first approved normalization
    find(normalizationOptions, { internallyApproved: true }) || // Use the first internally approved normalization
    first(normalizationOptions); // Use the most recent normalization
  return { normalizationOptions, defaultNormalization, isLoadingNormalizationOptions, refetchNormalizations: refetch };
};

export const computeDynamicRangeFromNormalizationOrChannelHistogram = (
  channelIndex: number,
  channelHistogram?: Pick<SlideChannel, 'histogram'>['histogram'],
  normalizationData?: NormalizationResult,
  is16Bit = false
): [min: number, max: number] => {
  const channelNormalizationResult = find(
    normalizationData?.channel_normalization_results,
    (c) => `${c.channel_index}` === `${channelIndex}`
  );

  const maxChannelRange = is16Bit ? MAX_UINT16 : MAX_UINT8;
  const minColorRange =
    channelNormalizationResult?.params?.min_c ??
    ((channelHistogram ? findIndex(channelHistogram, (value) => value > 0) : 0) / MAX_UINT8) * maxChannelRange;
  const maxColorRange =
    channelNormalizationResult?.params?.max_c ??
    ((channelHistogram ? findLastIndex(channelHistogram, (value) => value > 0) : MAX_UINT8) / MAX_UINT8) *
      maxChannelRange;

  return [max([minColorRange, 0]), min([max([maxColorRange, minColorRange + 1]), maxChannelRange])];
};

export const useMultiplexSlideChannelNormalization = ({ slide }: { slide: SlideWithChannelAndResults }) => {
  useSignals();
  const [, startTransition] = useTransition();
  const [urlMarkerNormalizationValues, setUrlMarkerNormalizationValues] = useQueryParam<{
    [markerType: string]: { [viewerIndex: number]: NormalizationRange };
  }>('markerNormalizationValues', JsonParam);
  const { defaultNormalization, normalizationOptions, isLoadingNormalizationOptions } =
    useMultiplexSlideChannelNormalizationsOptions({
      slide,
    });

  const [manualNormalizationId, setCurrentNormalizationId] = useState(defaultNormalization?.experimentResultId ?? null);
  const currentNormalizationId = manualNormalizationId ?? defaultNormalization?.experimentResultId ?? null;
  const currentNormalization = find(
    normalizationOptions,
    (normalization) => normalization.experimentResultId === currentNormalizationId
  );

  const slideId = slide.id;
  const viewerSlidesChannelNormalizationSettings = slidesChannelNormalizationSettings[slide.viewerIndex];

  const canLoadNormalizationData = currentNormalization?.experimentResultId !== undefined;
  // The backend will return an empty object if there is no normalization data - so we will check the loaded data before using it
  const {
    data: loadedNormalizationData,
    isLoading: isLoadingNormalizationData,
    refetch: refetchNormalization,
  } = useQueryWithErrorAndRetrySnackbar(
    getNormalizationValuesQueryKey(slide.id, currentNormalization?.experimentResultId),
    ({ signal: abortSignal }) =>
      getNormalizationValues<NormalizationResult>(slide.id, currentNormalization?.experimentResultId, abortSignal),
    {
      enabled: canLoadNormalizationData,
      queriedEntityName: 'multiplex channel normalization data',
    }
  );

  // Only use the normalization data if it is not empty (i.e. the backend returned data). This will allow us to check for loading errors
  // Normalization results should return an object with a key 'channel_normalization_results' which contains the normalization data per channel
  const normalizationData = !isEmpty(loadedNormalizationData?.channel_normalization_results)
    ? loadedNormalizationData
    : undefined;

  const previousNormalizationData = usePrevious(normalizationData);
  const previousSlideId = usePrevious(slide?.id);

  const resetChannelNormalizationSettings = useCallback(
    (channelIndex: number, markerType?: string) => {
      if (!viewerSlidesChannelNormalizationSettings) {
        console.warn(`Invalid viewerIndex: ${slide.viewerIndex}`);
        return;
      }
      const previousViewerSlidesChannelsNormalizationSettings = viewerSlidesChannelNormalizationSettings.value;
      const slideChannelNormalizationSettings = previousViewerSlidesChannelsNormalizationSettings[slideId] || {};

      const channel = slide?.channels[channelIndex];
      const range = computeDynamicRangeFromNormalizationOrChannelHistogram(
        Number(channelIndex),
        channel?.histogram,
        normalizationData,
        slide.encoding === 'uint16'
      );

      if (!slideChannelNormalizationSettings[channelIndex]) {
        slideChannelNormalizationSettings[channelIndex] = signal(range);
      } else {
        // Update the range if the slide has changed or if new normalization data is available
        slideChannelNormalizationSettings[channelIndex].value = range;
      }
      viewerSlidesChannelNormalizationSettings.value = {
        ...(previousViewerSlidesChannelsNormalizationSettings || {}),
        [slideId]: slideChannelNormalizationSettings,
      };

      if (markerType && urlMarkerNormalizationValues) {
        startTransition(() => {
          setUrlMarkerNormalizationValues((prev) => {
            if (!prev?.[markerType]) {
              return prev;
            }
            const newMarkerNormalizationValues = prev;
            newMarkerNormalizationValues[markerType][slide.viewerIndex] = undefined;
            return newMarkerNormalizationValues;
          }, 'replaceIn');
        });
      }
    },
    [
      viewerSlidesChannelNormalizationSettings,
      slideId,
      normalizationData,
      slide?.channels,
      slide.viewerIndex,
      urlMarkerNormalizationValues,
    ]
  );

  // Apply visualization settings after loading new normalization data
  useEffect(() => {
    if (!viewerSlidesChannelNormalizationSettings) {
      console.warn(`Invalid viewerIndex: ${slide.viewerIndex}`);
      return;
    }
    const previousViewerSlidesChannelsNormalizationSettings = viewerSlidesChannelNormalizationSettings.value;
    const previousSlideChannelNormalizationSettings = previousViewerSlidesChannelsNormalizationSettings[slideId] || {};

    const newSlideChannelSettings: Dictionary<Signal<[min: number, max: number]>> = reduce(
      entries(slide?.channels),
      (acc, [channelIndex, channel]) => {
        const markerType = slide?.channelMarkerTypes?.[channelIndex];
        const urlMarkerNormalizationValue = markerType
          ? urlMarkerNormalizationValues?.[markerType]?.[slide.viewerIndex] ||
            first(values(urlMarkerNormalizationValues?.[markerType]))
          : undefined;
        const range =
          urlMarkerNormalizationValue ||
          computeDynamicRangeFromNormalizationOrChannelHistogram(
            Number(channelIndex),
            channel?.histogram,
            normalizationData,
            slide.encoding === 'uint16'
          );
        if (!acc[channelIndex]) {
          acc[channelIndex] = signal(range);
        } else if (previousSlideId !== slideId || previousNormalizationData !== normalizationData) {
          // Update the range if the slide has changed or if new normalization data is available
          acc[channelIndex].value = range;
        }
        return acc;
      },
      previousSlideChannelNormalizationSettings
    );

    viewerSlidesChannelNormalizationSettings.value = {
      ...(previousViewerSlidesChannelsNormalizationSettings || {}),
      [slideId]: newSlideChannelSettings,
    };
  }, [
    currentNormalizationId,
    viewerSlidesChannelNormalizationSettings,
    slideId,
    slide,
    normalizationData,
    urlMarkerNormalizationValues,
  ]);

  return {
    currentNormalizationId,
    normalizationData,
    normalizationOptions,
    defaultNormalization,
    setCurrentNormalizationId,
    resetChannelNormalizationSettings,
    isLoadingNormalizationOptions,
    isLoadingNormalizationData: canLoadNormalizationData && isLoadingNormalizationData,
    currentNormalization,
    refetchNormalization,
  };
};
