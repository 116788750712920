import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from '@mui/material';
import React, { FunctionComponent } from 'react';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

interface StudyManifestInProgressDialogProps {
  open: boolean;
  completedBatches: number;
  totalBatches: number;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  setRunningDialogOpen: (open: boolean) => void;
  isError?: boolean;
}

export const StudyManifestInProgressDialog: FunctionComponent<
  React.PropsWithChildren<StudyManifestInProgressDialogProps>
> = ({ open, completedBatches, totalBatches, onClose, setRunningDialogOpen, isError }) => {
  return (
    <Dialog open={open}>
      <DialogContent>
        {isError ? (
          <Typography color="error">An error occurred while executing the manifest. Please contact support.</Typography>
        ) : (
          <>
            <Box sx={{ width: '100%' }}>
              <LinearProgressWithLabel value={(completedBatches / totalBatches) * 100} />
            </Box>
            {totalBatches === completedBatches && <Typography>Manifest executed successfully!</Typography>}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!isError && completedBatches < totalBatches}
          onClick={(e) => {
            setRunningDialogOpen(false);
            onClose(e);
          }}
        >
          {isError ? 'Close' : 'Great!'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
