import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { getUploadList } from 'api/uploads';
import { NON_SPECIFIC_STUDY_VALUES } from 'components/SearchFilters/hooks/useMainFilters';
import { concat, includes, isEmpty, toString } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';

interface SlideStatusProps {
  isCheatMode?: boolean;
  cheatStage?: number;
  total: number;
  ingested: number;
  navigateToUploadsDashboard?: boolean;
}

const SlideStatus = ({
  isCheatMode = false,
  cheatStage = 0,
  total,
  ingested,
  navigateToUploadsDashboard = false,
}: SlideStatusProps) => {
  const theme = useTheme();
  const { stringifyWithLab } = useCurrentLabId();
  const { encodedFilters, queryParams } = useEncodedFilters();

  const fileListSearch = stringifyWithLab({ studyId: queryParams.filters?.studyId });

  const { data: fileList } = useQuery({
    queryKey: ['uploadedFilesList', fileListSearch],
    queryFn: () => getUploadList(fileListSearch),
    refetchInterval: 120000,
    enabled:
      Boolean(queryParams.filters?.studyId) &&
      !includes(NON_SPECIFIC_STUDY_VALUES, toString(queryParams.filters?.studyId)),
  });

  const slidesInReview = concat(fileList?.slides?.pending || [], fileList?.slides?.processed || []);

  const cheatModeIngestedNum = cheatStage === 1 ? 0 : 11;
  const realIngestedNum = ingested || 0;
  const enableNavigation = navigateToUploadsDashboard && !isEmpty(slidesInReview);

  return (isCheatMode && cheatStage > 0) || realIngestedNum > 0 || !isEmpty(slidesInReview) ? (
    <Box sx={{ marginRight: '10px' }}>
      <Typography component="span" color="text.secondary">
        {`${isCheatMode ? cheatModeIngestedNum : realIngestedNum} / `}
      </Typography>

      <Typography
        component={enableNavigation ? Link : 'span'}
        to={{
          pathname: `/uploads-dashboard`,
          search: encodedFilters,
        }}
        color={enableNavigation ? theme.palette.primary.main : 'text.secondary'}
        style={{ textDecoration: enableNavigation ? 'underline' : 'none' }}
      >
        {isCheatMode ? '11' : isEmpty(slidesInReview) ? total : slidesInReview.length}
        {` Uploaded Files`}
      </Typography>
    </Box>
  ) : null;
};

export default SlideStatus;
