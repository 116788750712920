import BackIcon from '@mui/icons-material/ArrowBack';
import { Autocomplete, CircularProgress, Grid, IconButton, TextField, Typography } from '@mui/material';
import AdvancedSearchButton from 'components/SearchFilters/AdvancedSearch/AdvancedSearchButton';
import useMainFilters from 'components/SearchFilters/hooks/useMainFilters';
import { find } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useEncodedFilters } from 'utils/useEncodedFilters';

interface Props {
  selectedStudyId: string;
  hasFilters?: boolean;
}

const AnalysisStudioHeader: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  selectedStudyId,
  hasFilters,
}) => {
  const navigate = useNavigate();

  const { studyOptions, handleFilterChange, studiesLoading } = useMainFilters();
  const [advancedSearchDrawerOpen] = useQueryParam('advancedSearchDrawerOpen', BooleanParam);
  const { encodedFilters } = useEncodedFilters();

  const options: any = studyOptions || [];

  return (
    <Grid container direction="row" px={3} alignItems="center" spacing={1} my={4}>
      <Grid item xs={4}>
        <Grid item container spacing={1} alignItems="center">
          <Grid item>
            <IconButton
              onClick={() => {
                navigate({ pathname: '/procedures', search: encodedFilters });
              }}
            >
              <BackIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h1">Analysis Studio</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container>
          {!advancedSearchDrawerOpen && (
            <Grid item>
              <Grid item sx={{ display: 'flex', justifyContent: 'start' }} mr={1}>
                <AdvancedSearchButton text="Filter Cases" isIconButton filled={hasFilters} />
              </Grid>
            </Grid>
          )}
          <Grid item xs={10}>
            <Autocomplete
              size="small"
              id="study"
              options={options}
              value={find(options, (option) => option?.value == selectedStudyId) || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Study"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {studiesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              getOptionLabel={(option) => option.text || ''}
              onChange={(event, option) => {
                handleFilterChange('studyId', option?.value);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AnalysisStudioHeader;
