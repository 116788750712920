import { OverviewPreset } from 'interfaces/overviewPreset';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const saveOverviewPreset = (overviewPreset: Partial<OverviewPreset>) => {
  return apiRequestHandlerPromise({
    url: 'overview_presets',
    method: 'POST',
    data: JSON.stringify(overviewPreset),
  });
};

export const updateOverviewPreset = (overviewPreset: Partial<OverviewPreset>) => {
  return apiRequestHandlerPromise({
    url: `overview_presets/${overviewPreset.id}`,
    method: 'PUT',
    data: JSON.stringify(overviewPreset.preset),
  });
};

export const getOverviewPresets = (labId: string, studyId: string): Promise<OverviewPreset[]> => {
  return apiRequestHandlerPromise({
    url: `overview_presets?${stringify({ labId: labId, studyId: studyId })}`,
    method: 'GET',
  });
};

export const deleteOverviewPreset = (presetId: string): Promise<OverviewPreset[]> => {
  return apiRequestHandlerPromise({
    url: `overview_presets/${presetId}`,
    method: 'DELETE',
  });
};
