import { Grid, Typography } from '@mui/material';
import React from 'react';

interface Props {
  warningMessage: string;
}

export const ViewerWarningOverlay: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ warningMessage }) => {
  return (
    <Grid
      item
      style={{
        fontSize: '12px',
        alignSelf: 'flex-center',
        padding: '8px',
        color: '#ffff00',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <Typography variant="caption">{warningMessage}</Typography>
    </Grid>
  );
};

export default ViewerWarningOverlay;
