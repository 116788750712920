import { Grid } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import React from 'react';

import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import ToggleableSlider from 'components/Procedure/ToggleableSlider/ToggleableSlider';
import { useGroupedLayerChanges } from './handleChanges';

export const LayerOpacityToggle: React.FC<{
  viewerIndex: number;
  slideId: string;
  parentKey: string;
  layerName: string;
  layerUrlKey: string;
  stainTypeId: string;
  selected: boolean;
  defaultOpacity?: number;
}> = ({ viewerIndex, slideId, parentKey, layerName, layerUrlKey, stainTypeId, selected, defaultOpacity = 100 }) => {
  useSignals();
  const { handleOpacityChange, handleShowChange } = useGroupedLayerChanges(
    viewerIndex,
    slideId,
    parentKey,
    layerName,
    layerUrlKey,
    stainTypeId
  );
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];

  const currentLayerSettings =
    viewerSlideLayerVisualizationSettings?.value?.[slideId]?.[`${parentKey}-${layerName}`]?.value;

  return (
    <Grid item md={4}>
      <ToggleableSlider
        value={selected ? currentLayerSettings?.opacity ?? 0 : 0}
        disabled={!selected}
        checked={Boolean(selected && currentLayerSettings?.show)}
        onToggleChange={() => handleShowChange(defaultOpacity)}
        onValueChange={handleOpacityChange}
      />
    </Grid>
  );
};
