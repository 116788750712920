// import { Button, Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
// import { useEncodedFilters } from 'utils/useEncodedFilters';
// import useMainFilters from '../hooks/useMainFilters';

const NoCasesFound: React.FC<React.PropsWithChildren<unknown>> = () => {
  // const { resetAllFiltersExceptStudy } = useEncodedFilters();

  // const { handleFilterChange } = useMainFilters();

  // const onResetStudy = () => {
  //   handleFilterChange('studyId', null);
  // };

  // TODO: uncomment and add this later

  return (
    <Container>
      {/* <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
        <Grid item>
          <Typography variant="h1" color="text.primary">
            No cases found
          </Typography>
        </Grid>
        <Grid item>
          <Grid item container spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  textTransform: 'none',
                }}
                onClick={onResetStudy}
              >
                Set study selection to &quot;all&quot;
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  textTransform: 'none',
                }}
                onClick={resetAllFiltersExceptStudy}
              >
                Reset filters
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
    </Container>
  );
};

export default NoCasesFound;
