import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton, TextField, TextFieldProps, TextFieldVariants } from '@mui/material';
import React from 'react';

type Props<Variant extends TextFieldVariants> = Omit<TextFieldProps<Variant>, 'onChange'> & {
  onChange(textSearch: string): void;
  closeSearchMode(): void;
};

const SearchBar = <Variant extends TextFieldVariants>({
  helperText,
  onChange,
  closeSearchMode,
  ...props
}: React.PropsWithChildren<Props<Variant>>) => {
  return (
    <TextField
      {...props}
      placeholder="Search"
      helperText={helperText}
      autoFocus
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => onChange(event.target.value)}
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={() => {
              onChange('');
              closeSearchMode();
            }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        ),
      }}
    />
  );
};

export default SearchBar;
