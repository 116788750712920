import { Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton } from '@mui/material';
import { debounce, filter, find, includes, isEmpty, map } from 'lodash';
import React from 'react';

import { Procedure } from 'interfaces/procedure';
import useSlideTagOptions from 'utils/queryHooks/useSlideTagOptions';
import { useQuerySlideTagsById } from './useQuerySlideTagsById';
import { useToggleSlideTag } from './useToggleSlideTag';

export const SlideTagsSelectionMenu: React.FC<
  React.PropsWithChildren<{
    currentCase: Procedure;
    slideIdForTagging: string;
    orchestrationId?: string;
    isLoadingCaseData?: boolean;
    refetchProcedure: () => void;
  }>
> = ({ currentCase, slideIdForTagging, orchestrationId, isLoadingCaseData, refetchProcedure }) => {
  const { studyId, labId } = currentCase || {};

  const { data: allSlideTags, isLoading: isLoadingSlideTags } = useQuerySlideTagsById({
    slideId: slideIdForTagging,
    labId,
    enabled: !isLoadingCaseData,
  });

  const { data: allSlideTagOptions, isLoading: isLoadingSlideTagOptions } = useSlideTagOptions({
    enabled: !isLoadingCaseData,
  });

  const slideTagOptions = React.useMemo(
    () =>
      filter(
        allSlideTagOptions,
        (tag) => (!tag.perStudy || Boolean(studyId)) && (!tag.perExperimentResult || Boolean(orchestrationId))
      ),
    [allSlideTagOptions, studyId, orchestrationId]
  );

  const slideTagIds = React.useMemo(
    () =>
      map(
        filter(allSlideTags, (tag) => {
          const tagOption = find(slideTagOptions, { id: tag.tagId });
          return (
            Boolean(tagOption) &&
            (!tagOption.perStudy || tag.studyId === studyId) &&
            (!tagOption.perExperimentResult || tag.orchestrationId === orchestrationId)
          );
        }),
        'tagId'
      ),
    [allSlideTags, allSlideTagOptions, studyId, orchestrationId]
  );

  const toggleTag = useToggleSlideTag({ currentCase, slideTagIds, orchestrationId, refetchProcedure });

  const isLoadingTagForOrchestration = !orchestrationId;

  React.useEffect(() => {
    if (!isEmpty(slideTagOptions)) {
      const toggleTagByKeyboard = debounce(
        (event: KeyboardEvent) => {
          const key = event.key;
          const tagIdx = parseInt(key) - 1;
          if (tagIdx >= 0 && slideTagOptions[tagIdx]?.id) {
            const tag = slideTagOptions[tagIdx];
            if (!tag.perExperimentResult || !isLoadingTagForOrchestration) {
              toggleTag(slideTagOptions[tagIdx]);
            }
          }
        },
        500,
        { leading: true }
      );
      window.addEventListener('keydown', toggleTagByKeyboard);
      return () => {
        window.removeEventListener('keydown', toggleTagByKeyboard);
      };
    }
  }, [slideTagOptions, toggleTag, isLoadingTagForOrchestration]);

  return isLoadingSlideTagOptions ? (
    <Skeleton variant="rectangular" height="100%" width="100%" />
  ) : (
    <List sx={{ width: '100%' }}>
      {map(slideTagOptions, (tag, index) => {
        const isSelected = includes(slideTagIds, tag.id);
        // Disable if the tag is experiment specific and we are loading the slide tags for the selected orchestrationId
        const tagStatusLoading = isLoadingSlideTags || (tag.perExperimentResult && isLoadingTagForOrchestration);
        return (
          <ListItem dense disablePadding key={`${tag.id}-${orchestrationId}-${tagStatusLoading}-${isSelected}`}>
            <ListItemButton
              selected={!tagStatusLoading && isSelected}
              onClick={!tagStatusLoading ? () => toggleTag(tag) : undefined}
              disabled={tagStatusLoading}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{ ...(!tagStatusLoading && isSelected && { bgcolor: 'primary.main' }), borderRadius: '20%' }}
                >
                  {index + 1}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={tag.tagValue}
                secondary={
                  tagStatusLoading
                    ? 'Loading status...'
                    : tag.perExperimentResult
                    ? 'Result specific'
                    : tag.perStudy
                    ? 'Study specific'
                    : ''
                }
                secondaryTypographyProps={{ fontSize: '10px' }}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};
