import { QueryClientProvider } from '@tanstack/react-query';
import AppView from 'components/AppView';
import React from 'react';
import { connect, Provider } from 'react-redux';
import { Dispatch } from 'redux';
import { tryAuthenticateFromLocalStorage } from 'redux/actions/auth';
import store, { RootState } from 'redux/store';
import { Auth0Profile } from 'utils/auth/AuthService';
import queryClient from 'utils/queryClient';
import { ConfirmationServiceProvider } from './modals/ConfirmationContext';
import { UploadFilesContextProvider } from './UploadFilesContext';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  tryAuthenticateFromLocalStorage: (currentProfile?: Auth0Profile) =>
    dispatch(tryAuthenticateFromLocalStorage(currentProfile)),
});

const mapStateToProps = (state: RootState) => {
  return {
    hasError: state.error.message !== null && state.error.code !== 404,
    errorMessage: state.error.message,
    isAuthenticated: Boolean(state.auth.accessToken),
    triedAuthenticateFromLocalStorage: state.auth.triedAuthenticateFromLocalStorage,
    userProfile: state.auth.profile,
  };
};

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(AppView);

const App = () => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <UploadFilesContextProvider>
          <ConfirmationServiceProvider>
            <ConnectedApp />
          </ConfirmationServiceProvider>
        </UploadFilesContextProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
