import type { Layer } from '@deck.gl/core/typed';
import { LayerExtension } from '@deck.gl/core/typed';
import { ShaderModule } from '@deck.gl/core/typed/types/types';
import filterColor from './filterColor';
import fsModule from './fsModule';

export interface LinearDeconvExtensionProps {
  /** 3x3 matrix of deconvolution coefficients (3 coefficients per color channel) */
  coefficients: Float32Array;
  intercept?: Float32Array;
}

const defaultIntercept = Float32Array.from([0, 0, 0]);

const shaders = {
  modules: [
    {
      name: 'stain-devonv-module',
      fs: fsModule,
    } as ShaderModule,
  ],
  inject: {
    // Test by discarding all pixels
    // 'fs:DECKGL_FILTER_COLOR': `if (rgba.a > 0.0) discard;`,
    'fs:DECKGL_FILTER_COLOR': filterColor,
  },
};

/** Perform staining deconv on a slide. */
export default class LinearDeconvExtension extends LayerExtension<LinearDeconvExtensionProps> {
  static extensionName = 'LinearDeconvExtension';

  getShaders(this: Layer<LinearDeconvExtensionProps>) {
    return shaders;
  }

  draw(this: Layer<Required<LinearDeconvExtensionProps>>, { uniforms }: any): void {
    uniforms.coefficients = this.props.coefficients;
    uniforms.intercept = this.props.intercept || defaultIntercept;
  }
}
