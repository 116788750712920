import { Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useClipboard } from 'use-clipboard-copy';

interface Props {
  slideId: string;
}

export const SlideIdOverlay: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ slideId }) => {
  const clipboard = useClipboard({ copiedTimeout: 1000 });

  return (
    <Tooltip title={clipboard.copied ? 'Copied!' : 'Click to copy'} placement="top">
      <Grid
        item
        style={{
          fontSize: '10px',
          alignSelf: 'flex-end',
          padding: '0 5px',
          color: '#fff',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          clipboard.copy(slideId);
        }}
      >
        <Typography variant="caption">{slideId}</Typography>
      </Grid>
    </Tooltip>
  );
};

export default SlideIdOverlay;
