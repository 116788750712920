import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import './ScaleIndicator.scss';
import { Scalebar } from './Viewer';

interface Props {
  scalebarInstance: Scalebar;
}

const ScaleIndicator: FunctionComponent<React.PropsWithChildren<Props>> = ({ scalebarInstance }) => {
  const ref = useRef(null);

  const [currentPPM, setCurrentPPM] = useState(1);

  const resizeHandler = useCallback(() => {
    if (scalebarInstance) setCurrentPPM(scalebarInstance.currentPPM());
  }, [scalebarInstance]);

  useEffect(() => {
    if (scalebarInstance) {
      scalebarInstance.viewer.addHandler('open', resizeHandler);
      scalebarInstance.viewer.addHandler('resize', resizeHandler);
      scalebarInstance.viewer.addHandler('animation', resizeHandler);
    }
    return () => {
      if (scalebarInstance) {
        scalebarInstance.viewer.removeHandler('resize', resizeHandler);
        scalebarInstance.viewer.removeHandler('animation', resizeHandler);
      }
    };
  }, [scalebarInstance]);

  const generateScalebar = useCallback(() => {
    if (!scalebarInstance) {
      return undefined;
    }

    const sizeAndText = scalebarInstance.sizeAndTextRenderer(currentPPM, scalebarInstance.minWidth);
    return (
      <>
        <div className="scalebar-scale" style={{ width: `${sizeAndText.size}px` }} />
        <div className="scalebar-name">{sizeAndText.text}</div>
      </>
    );
  }, [scalebarInstance, currentPPM]);

  const scalebar = generateScalebar();

  return (
    <div className="scale-indicator" ref={ref}>
      {scalebar}
    </div>
  );
};

export default ScaleIndicator;
