import { ChevronLeft, ChevronRight, Fullscreen, FullscreenExit, Pause, PlayArrow } from '@mui/icons-material';
import React from 'react';
import { ReactImageGalleryProps } from 'react-image-gallery';

import { IconButton } from '@mui/material';

export const GalleryViewerControls = ({
  disableRightNav,
  disableLeftNav,
}: {
  disableRightNav?: boolean;
  disableLeftNav?: boolean;
} = {}): Pick<
  ReactImageGalleryProps,
  'renderFullscreenButton' | 'renderPlayPauseButton' | 'renderLeftNav' | 'renderRightNav'
> => ({
  renderFullscreenButton: (onClick, isGalleryFullscreen) => (
    <IconButton className="image-gallery-icon image-gallery-fullscreen-button" onClick={onClick}>
      {isGalleryFullscreen ? <FullscreenExit /> : <Fullscreen />}
    </IconButton>
  ),
  renderPlayPauseButton: (onClick, isPlaying) => (
    <IconButton className="image-gallery-icon image-gallery-play-button" onClick={onClick}>
      {isPlaying ? <Pause /> : <PlayArrow />}
    </IconButton>
  ),
  renderLeftNav: (onClick, disabled) => (
    <IconButton
      onClick={onClick}
      disabled={disabled || disableLeftNav}
      className="image-gallery-icon image-gallery-left-nav"
    >
      <ChevronLeft fontSize="large" />
    </IconButton>
  ),
  renderRightNav: (onClick, disabled) => (
    <IconButton
      onClick={onClick}
      disabled={disabled || disableRightNav}
      className="image-gallery-icon image-gallery-right-nav"
    >
      <ChevronRight fontSize="large" />
    </IconButton>
  ),
});
