import { AreaType } from 'interfaces/areaType';
import { ClassificationModel } from 'interfaces/classificationModel';
import { ClusterType } from 'interfaces/clusterType';
import { FeatureFormatter } from 'interfaces/featureFormatter';
import { StainType } from 'interfaces/stainType';
import Visualization from 'interfaces/visualization';
import {
  UiSettings,
  useAreaTypeOptions,
  useClassificationModelOptions,
  useClusterTypeOptions,
  useFeatureFormatters,
  useStainTypeOptions,
  useUiSettings,
  useVisualizationTypeOptions,
} from 'utils/queryHooks/uiConstantsHooks';

export interface FormatBracketsOptions {
  stainTypeOptions: StainType[];
  areaTypeOptions: AreaType[];
  visualizationTypeOptions?: Visualization[];
  clusterTypeOptions: ClusterType[];
  classificationModelOptions: ClassificationModel[];
  addStain: boolean;
  uiSettings: UiSettings;
  featureFormatters: FeatureFormatter[];
  isLoading: boolean;
}

export const useFormatBracketsOptions = (addStain: boolean): FormatBracketsOptions => {
  const { isLoadingStainTypeOptions, stainTypeOptions } = useStainTypeOptions();
  const { isLoadingAreaTypes, areaTypeOptions } = useAreaTypeOptions();
  const { isLoadingClusterTypes, clusterTypeOptions } = useClusterTypeOptions();
  const { isLoadingVisualizationTypes, visualizationTypeOptions } = useVisualizationTypeOptions();
  const { isLoadingClassificationModels, classificationModelOptions } = useClassificationModelOptions();
  const { uiSettings, isLoadingUiSettings } = useUiSettings();
  const { featureFormatters, isLoadingFeatureFormatters } = useFeatureFormatters();

  return {
    addStain,
    stainTypeOptions,
    areaTypeOptions,
    clusterTypeOptions,
    visualizationTypeOptions,
    classificationModelOptions,
    uiSettings,
    featureFormatters,
    isLoading:
      isLoadingStainTypeOptions ||
      isLoadingAreaTypes ||
      isLoadingClusterTypes ||
      isLoadingVisualizationTypes ||
      isLoadingClassificationModels ||
      isLoadingUiSettings ||
      isLoadingFeatureFormatters,
  };
};
