import { IconButton } from '@mui/material';
import React from 'react';

import EqualizerIcon from '@mui/icons-material/Equalizer';

interface Props {
  themed?: boolean;
  faded?: boolean;
  slideId?: string;
  annotationAssignmentId: number;
}

const AssignmentRedashButton: React.FunctionComponent<Props> = ({ slideId, annotationAssignmentId, themed, faded }) => {
  const url = slideId
    ? `https://redash.services.nucleai.net/dashboard/slide-x-assignment-summary?p_slide=${slideId}&p_assignment='${annotationAssignmentId}'`
    : `https://redash.services.nucleai.net/dashboard/assignment-summary?p_Assignments=${annotationAssignmentId}`;
  return (
    <IconButton disabled={faded} href={url} target="_blank" rel="noopener noreferrer">
      <EqualizerIcon htmlColor={themed ? 'red' : undefined} />
    </IconButton>
  );
};

export default AssignmentRedashButton;
