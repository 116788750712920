import { Dictionary } from 'lodash';

import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const fetchFormatExtensions = () => {
  return apiRequestHandlerPromise<Dictionary<string>>({
    url: `format_extensions`,
    method: 'GET',
  });
};
