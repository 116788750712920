import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Close';
import { Autocomplete, Button, IconButton, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import { cloneDeep, filter, find, findIndex, includes, isEmpty, map, reject } from 'lodash';
import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form';

const StudyPermissions = (props: Props): ReactElement => {
  const { control } = props;
  const [studyPermission, setStudyPermission] = React.useState('Anyone');
  const [usersPermissionSettings, setUsersPermissionSettings] = React.useState<UserPermissionSettings[]>([]);
  const [selectedUser, setSelectedUser] = React.useState(null);

  const handleAddUser = () => {
    setUsersPermissionSettings([...usersPermissionSettings, { id: selectedUser.id, permissions: 'View' }]);
    setSelectedUser(null);
  };

  const handleUpdateUserPermissions = (permissionsUpdate: UserPermissionSettings) => {
    const nextUsersPermissionSettings = cloneDeep(usersPermissionSettings);
    const userIndex = findIndex(nextUsersPermissionSettings, { id: permissionsUpdate.id });
    if (userIndex > -1) {
      nextUsersPermissionSettings[userIndex].permissions = permissionsUpdate.permissions;
      setUsersPermissionSettings(nextUsersPermissionSettings);
    }
  };

  const filterUserOptionsList = () => {
    const existingUserIds = map(usersPermissionSettings, 'id');
    return filter(demoNamesList, (user) => {
      return !includes(existingUserIds, user['id']);
    });
  };

  const onRemoveUser = (userId: string) => () => {
    setUsersPermissionSettings(reject(usersPermissionSettings, { id: userId }));
  };

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Controller
          control={control}
          name="studyPermissions"
          defaultValue={studyPermission}
          render={({ field: { onChange } }) => (
            <LabelledDropdown
              size="small"
              variant="standard"
              label="Access Level"
              options={studyPermissionOptions}
              value={studyPermission}
              onOptionSelected={(optionValue) => {
                onChange(optionValue);
                setStudyPermission(optionValue);
              }}
            />
          )}
        />
      </Grid>
      {studyPermission === 'Restricted' && (
        <Grid item container direction="column" spacing={1}>
          <Grid item container spacing={1} alignItems="center">
            <Grid item xs={6}>
              <Autocomplete
                size="small"
                disablePortal
                id="add user permissions"
                options={filterUserOptionsList()}
                value={selectedUser}
                renderInput={(params) => <TextField {...params} label="Add users or groups" />}
                onChange={(event, selected) => {
                  setSelectedUser(selected);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button disabled={isEmpty(selectedUser)} endIcon={<AddIcon />} onClick={handleAddUser} variant="outlined">
                Add
              </Button>
            </Grid>
          </Grid>

          {!isEmpty(usersPermissionSettings) && (
            <Grid item ml={1} mt={1}>
              <Typography variant="caption">Allowed Users</Typography>
            </Grid>
          )}
          {map(usersPermissionSettings, (userPermissionSettings: UserPermissionSettings) => (
            <Grid item container spacing={1} alignItems="center" p={1}>
              <Grid item xs={8} container alignItems="center" spacing={1}>
                <Grid item>
                  <IconButton onClick={onRemoveUser(userPermissionSettings.id)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid item>{find(demoNamesList, { id: userPermissionSettings.id })?.label}</Grid>
              </Grid>

              <Grid item xs={4}>
                <LabelledDropdown
                  label="Permission"
                  variant="standard"
                  size="small"
                  options={userPermissionOptions}
                  value={userPermissionSettings.permissions}
                  onOptionSelected={(optionValue) => {
                    handleUpdateUserPermissions({
                      id: userPermissionSettings.id,
                      permissions: optionValue as UserPermission,
                    });
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

const studyPermissionOptions = [
  {
    text: 'Anyone',
    value: 'Anyone',
  },
  {
    text: 'Restricted',
    value: 'Restricted',
  },
  {
    text: 'Only Me',
    value: 'Only Me',
  },
];

const userPermissionOptions = [
  {
    text: 'View',
    value: 'View',
  },
  {
    text: 'Comment',
    value: 'Comment',
  },
  {
    text: 'Edit',
    value: 'Edit',
  },
  {
    text: 'Owner',
    value: 'Owner',
  },
];

type UserPermission = 'View' | 'Comment' | 'Edit' | 'Owner';

interface UserPermissionSettings {
  id: string;
  permissions: UserPermission;
}

interface UserOption {
  id: string;
  label: string;
}

interface Props {
  control: any;
}

const demoNamesList: UserOption[] = [
  { id: '0', label: 'Phyllis Morgana' },
  { id: '1', label: 'Helen Marketa' },
  { id: '2', label: 'Deborah Amalita' },
  { id: '3', label: 'Ketty Almeria' },
  { id: '4', label: 'Ddene Jeni' },
  { id: '5', label: 'Rebekkah Lulita' },
  { id: '6', label: 'Junette Bendite' },
  { id: '7', label: 'Belia Valenka' },
  { id: '8', label: 'George Lilah' },
  { id: '9', label: 'Dominga Jocelyne' },
  { id: '10', label: 'Amabel Bride' },
  { id: '11', label: 'Milicent Ethel' },
  { id: '12', label: 'Joelle Lethia' },
  { id: '13', label: 'Leigha Karen' },
  { id: '14', label: 'Anestassia Gracia' },
  { id: '15', label: 'Donny Carey' },
  { id: '16', label: 'Debbie Mathilda' },
  { id: '17', label: 'Loretta Sammy' },
  { id: '18', label: 'Gratia Rosie' },
  { id: '19', label: 'Keriann Cammi' },
  { id: '20', label: 'Deidre Anette' },
  { id: '21', label: 'Elisha Carmen' },
  { id: '22', label: 'Ginelle Verina' },
  { id: '23', label: 'Edeline Agata' },
  { id: '24', label: 'Willyt Nicolle' },
  { id: '25', label: 'Kalila Melisande' },
  { id: '26', label: 'Sarina Milissent' },
  { id: '27', label: 'Janine Wini' },
  { id: '28', label: 'Audry Felisha' },
  { id: '29', label: 'Tish Lizbeth' },
  { id: '30', label: 'Pavia Sheela' },
  { id: '31', label: 'Romola Haily' },
  { id: '32', label: 'Mira Malia' },
  { id: '33', label: 'Dynah Lotty' },
  { id: '34', label: 'Charlotte Nikkie' },
  { id: '35', label: 'Leonore Brigid' },
  { id: '36', label: 'Mariya Winna' },
  { id: '37', label: 'Marybelle Kimmy' },
  { id: '38', label: 'Tiffany Katie' },
  { id: '39', label: 'Jordan Dasie' },
  { id: '40', label: 'Tilda Alexia' },
  { id: '41', label: 'Lydia Julee' },
  { id: '42', label: 'Lari Clem' },
  { id: '43', label: 'Pearl Whitney' },
  { id: '44', label: 'Annemarie Mildrid' },
  { id: '45', label: 'Samantha Carolina' },
  { id: '46', label: 'Wendie Kassey' },
  { id: '47', label: 'Ariel Koralle' },
  { id: '48', label: 'Glen Isa' },
  { id: '49', label: 'Hollie Mandy' },
  { id: '50', label: 'Ardra Guglielma' },
  { id: '51', label: 'Carina Star' },
  { id: '52', label: 'Chrissie Coletta' },
  { id: '53', label: 'Meggy Annabela' },
  { id: '54', label: 'Theadora Eddy' },
  { id: '55', label: 'Beverlee Rubie' },
  { id: '56', label: 'Tabbitha Joby' },
  { id: '57', label: 'Wendye Margo' },
  { id: '58', label: 'Alta Tarra' },
  { id: '59', label: 'Joanna Ninette' },
  { id: '60', label: 'Bernadette Etta' },
  { id: '61', label: 'Kassey Keely' },
  { id: '62', label: 'Robbyn Reggie' },
  { id: '63', label: 'Brigitta Isa' },
  { id: '64', label: 'Jess Modestia' },
  { id: '65', label: 'Kim Frank' },
  { id: '66', label: 'Dael Sile' },
  { id: '67', label: 'Janaya Bernadette' },
  { id: '68', label: 'Benedicta Caty' },
  { id: '69', label: 'Fredi Sheilah' },
  { id: '70', label: 'Pier Emalia' },
  { id: '71', label: 'Genny Karine' },
  { id: '72', label: 'Hedvige Shayne' },
  { id: '73', label: 'Neysa Katherina' },
  { id: '74', label: 'Kassi Emelita' },
  { id: '75', label: 'Nertie Ingeberg' },
  { id: '76', label: 'Sharyl Ashleigh' },
  { id: '77', label: 'Haleigh Drona' },
  { id: '78', label: 'Koral Patsy' },
  { id: '79', label: 'Haleigh Kore' },
  { id: '80', label: 'Margy Louella' },
  { id: '81', label: 'Gertrud Hilde' },
  { id: '82', label: 'Joleen Maggee' },
  { id: '83', label: 'Ronny Junia' },
  { id: '84', label: 'Nadia Ferdinanda' },
  { id: '85', label: 'Lavinia Danica' },
  { id: '86', label: 'Fleurette Emmi' },
  { id: '87', label: 'Annie Anneliese' },
  { id: '88', label: 'Nettie Shirlene' },
  { id: '89', label: 'Margot Leontine' },
  { id: '90', label: 'Maurene Jasmin' },
  { id: '91', label: 'Philippe Trula' },
  { id: '92', label: 'Muire Dania' },
  { id: '93', label: 'Kynthia Nolana' },
  { id: '94', label: 'Evey Ingrid' },
  { id: '95', label: 'Alyss Lexie' },
  { id: '96', label: 'Mireielle Joana' },
  { id: '97', label: 'Madonna Alexina' },
  { id: '98', label: 'Joela Ibbie' },
  { id: '99', label: 'Shoshana Gilemette' },
];

export default StudyPermissions;
