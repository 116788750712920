import { Grid } from '@mui/material';
import { get, isEmpty, map, orderBy, trim } from 'lodash';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { CaseSearchItem } from 'services/searchIndex';
import FullSearchAutocomplete from './FullSearchAutocomplete';
import { useFullSearch } from './useFullSearch';
import useSlideSearch from './useSlideSearch';

export const fullSearchKeys = ['caseLabel', 'caseId', 'slides.*.id', 'slides.*.fileName'];

interface FullSearchProps {
  currentStudyId?: string;
  enabled?: boolean;
  isDrawer?: boolean;
  handleChange?: (event: React.SyntheticEvent<Element, Event>, value: string | CaseSearchItem) => void;
}

const FullSearch: FunctionComponent<React.PropsWithChildren<FullSearchProps>> = ({
  currentStudyId,
  enabled = true,
  isDrawer = false,
  handleChange,
}) => {
  const {
    searchQuery,
    searchInputValue,
    setSearchInputValue,
    filteredOptions,
    searchHasNoResults,
    handleSearch,
    handleApplyOption,
    searchIndexSuccess,
    isSearchIndexLoading,
    isErrorSearchIndex,
  } = useFullSearch({ studyId: currentStudyId, enabled, isDrawer, handleChange });

  useEffect(() => {
    if (searchInputValue !== searchQuery) {
      setSearchInputValue(searchQuery ?? '');
      handleSearch(searchQuery ?? '');
      handleChange(null, searchQuery ?? '');
    }
  }, [searchQuery]);

  const isStudyIdSpecific = Boolean(currentStudyId);

  const optionsSortedByCurrentStudy = useMemo(() => {
    if (isStudyIdSpecific) {
      return orderBy(filteredOptions, (option) => option.studyId === currentStudyId, 'desc');
    }
    return filteredOptions;
  }, [filteredOptions, currentStudyId]);

  const noResults =
    searchInputValue && trim(searchInputValue) !== '' && Boolean(get(searchHasNoResults, searchInputValue, false));

  const {
    data: externalSlidesSearchResultsData,
    isSuccess: isSuccessOtherResults,
    isInitialLoading: isLoadingOtherResults,
  } = useSlideSearch(searchInputValue, Boolean(noResults));

  const otherSearchResults: CaseSearchItem[] = useMemo(() => {
    if (!isSuccessOtherResults) {
      return [];
    }
    return map(externalSlidesSearchResultsData.results, (result) => {
      return {
        studyId: 'other',
        studyName: 'other',
        caseId: null,
        caseLabel: null,
        slides: [
          {
            id: result.id,
            fileName: result.fileName,
            labId: result.labId,
          },
        ],
        isSlideOnly: true,
      };
    });
  }, [isSuccessOtherResults, externalSlidesSearchResultsData]);

  const options = !isEmpty(optionsSortedByCurrentStudy) ? optionsSortedByCurrentStudy : otherSearchResults;

  return (
    <Grid container>
      <Grid item xs>
        <FullSearchAutocomplete
          currentStudyId={currentStudyId}
          value={searchInputValue}
          options={options}
          setValue={setSearchInputValue}
          onSearch={handleSearch}
          onApplySearch={handleApplyOption}
          onInputChange={handleChange}
          disabled={!searchIndexSuccess}
          isDrawer={isDrawer}
          isLoading={isSearchIndexLoading || isLoadingOtherResults}
          isError={isErrorSearchIndex}
          noResults={noResults}
        />
      </Grid>
    </Grid>
  );
};

export default FullSearch;
