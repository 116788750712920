// eslint-disable-next-line import/order
import { ReactComponent as EyeDisabled } from './eye-disabled.svg';
// eslint-disable-next-line import/order
import { Grid, IconButton, SliderProps, Typography } from '@mui/material';
import classnames from 'classnames';
import Slider from 'components/atoms/Slider';
import React from 'react';
import './ToggleableSlider.scss';
import { ReactComponent as EyeEnabled } from './eye-enabled.svg';

export enum TitlePlacement {
  LEFT = 'left',
  RIGHT = 'right',
}

interface Props {
  title?: string;
  value: number;
  checked: boolean;
  disabled?: boolean;
  sliderProps?: Partial<SliderProps>;
  fullSlider?: boolean;
  hideSlider?: boolean;
  onToggleChange: () => void;
  onValueChange: (value: number) => void;
}

const ToggleableSlider: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  title,
  value,
  checked,
  disabled,
  sliderProps,
  fullSlider,
  hideSlider,
  onToggleChange,
  onValueChange,
}) => (
  <div className={classnames('toggleable-slider', { disabled })}>
    <Grid container wrap="nowrap" alignItems="center">
      {title && (
        <Grid item md={4}>
          <Typography variant="subtitle1">{title}</Typography>
        </Grid>
      )}
      <Grid item md={fullSlider ? 10 : 8}>
        {!hideSlider && (
          <Slider
            muted={!checked}
            value={value}
            disabled={disabled}
            onValueChange={(opacity: number) => {
              onValueChange(opacity);
              if (opacity > 0 && !checked) {
                onToggleChange();
              }
            }}
            {...sliderProps}
          />
        )}
      </Grid>
      <Grid item pl={1} md={2}>
        <IconButton
          disabled={disabled}
          color="primary"
          onClick={(event) => {
            if (!disabled) onToggleChange();
            event.stopPropagation();
          }}
        >
          {checked && (value ?? 0) > 0 ? <EyeEnabled /> : <EyeDisabled />}
        </IconButton>
      </Grid>
    </Grid>
  </div>
);

export default ToggleableSlider;
