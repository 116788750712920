import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import Loader from 'components/Loader';
import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import { ProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { ReviewForm } from 'interfaces/reviewForm';
import { flatMap, map } from 'lodash';
import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useCurrentStateCases } from 'utils/useCurrentStateCases';
import { usePermissions } from 'utils/usePermissions';
import { DataGridInterfaceWrapper } from './DataGrids/DataGridInterfaceWrapper';
import { ErrorMessage } from './ErrorMessage';
import { GalleryViewer } from './GalleryViewer';
import { UnpaginatedGalleryViewer } from './GalleryViewer/UnpaginatedGalleryViewer';
import ProcedureCards from './ProcedureCards/ProcedureCards';
import { ProcedurePagination } from './ProcedurePagination';
import { useCasesViewMode } from './useCasesViewMode';
import { usePendingSlides } from './usePendingSlides';

export const CasesDisplay: React.FC<
  React.PropsWithChildren<{
    displayProcedureModeToggle?: boolean;
    cheatModeSmallerThanTwo?: boolean;
    pendingSlidesMode?: boolean;
    forms?: ReviewForm[];
  }>
> = ({ displayProcedureModeToggle, cheatModeSmallerThanTwo, pendingSlidesMode, forms }) => {
  const [showGallery, setShowGallery] = useQueryParam('showGallery', BooleanParam);
  const { hasPermission, hasSomeEditingPermissions } = usePermissions();

  const { casesViewMode } = useCasesViewMode();
  const canUseExperimentalFeatures = hasPermission(Permission.UseNucleaiExperimentalFeatures);
  const procedureViewMode = displayProcedureModeToggle ? casesViewMode : 'card';
  const canUseGalleryMode = displayProcedureModeToggle && canUseExperimentalFeatures;

  const theme = useTheme();

  const { isLoading: isCasesLoading, totalCasesCount, totalSlidesCount, procedures, error } = useCurrentStateCases();
  const { fieldsContext: proceduresFieldsContext } = useTableEditingContext<Procedure, ProceduresFieldsContext>();

  const { allMockCasesOfPendingSlides } = usePendingSlides();
  const slideCasePairs = flatMap(allMockCasesOfPendingSlides, (procedure: Procedure) =>
    map(procedure.slides, (slide) => ({
      slide,
      case: procedure,
    }))
  );

  const isLoading =
    isCasesLoading || proceduresFieldsContext.allCancerTypesLoading || proceduresFieldsContext.stainTypesInStudyLoading;

  return (
    <>
      {error ? (
        <ErrorMessage />
      ) : procedureViewMode === 'table' ? (
        <DataGridInterfaceWrapper
          casesInPage={procedures}
          hidePagination={cheatModeSmallerThanTwo}
          totalSlides={totalSlidesCount}
          totalCases={totalCasesCount}
          forms={forms}
          isLoading={isLoading}
          disableEditing={!hasSomeEditingPermissions}
          pendingSlidesMode={pendingSlidesMode}
        />
      ) : isLoading ? (
        <Grid
          container
          className="dashboard-page-body"
          justifyContent="center"
          sx={{ flex: '1 1 auto', backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[50] }}
        >
          <Grid item>
            <Loader />
          </Grid>
        </Grid>
      ) : (
        <>
          {canUseGalleryMode &&
            showGallery &&
            (pendingSlidesMode ? (
              <UnpaginatedGalleryViewer slideCasePairs={slideCasePairs} onClose={() => setShowGallery(false)} />
            ) : (
              <GalleryViewer totalCases={totalCasesCount} totalSlides={totalSlidesCount} />
            ))}
          <ProcedureCards
            procedures={procedures}
            isLoading={isLoading}
            galleryView={procedureViewMode === 'gallery' && canUseGalleryMode}
            pendingSlidesMode={pendingSlidesMode}
          />
          {!isLoading && totalCasesCount > 0 && !cheatModeSmallerThanTwo && (
            <ProcedurePagination totalSlides={totalSlidesCount} totalCases={totalCasesCount} />
          )}
        </>
      )}
    </>
  );
};
