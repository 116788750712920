import Pin from 'interfaces/pin';
import { MouseTracker, OverlayRotationMode, Point, Viewer, ViewerEvent } from 'openseadragon';
import React from 'react';
import { uuidv4 } from 'utils/helpers';
import MarkerImgSrc from './marker.svg';
import PinModal from './PinModal';
import './SlidePinComments.scss';

interface Props {
  viewer: Viewer;
  pins: Pin[];
  onAddPin(pin: Pin): void;
  onAddPinToView(pin: Pin): void;
  onRemovePin(pin: Pin): void;
  onRemovePinFromView(pin: Pin): void;
  onSavePin(pin: Pin): void;
}

interface State {
  selectedPin: Pin | null;
  createPinMode: boolean;
}

const getPinElement = (id: string, pinIndex: number) => {
  const pinDiv = document.createElement('div');
  const pinImg = document.createElement('img');
  const pinNumber = document.createElement('label');
  pinDiv.id = id;
  pinDiv.title = 'click to edit';
  pinDiv.classList.add('pin');
  pinImg.src = MarkerImgSrc;
  pinImg.classList.add('pin-img');
  const pinNumberClass = pinIndex < 10 ? 'one-digit' : 'two-digits';
  pinNumber.classList.add(pinNumberClass);
  pinNumber.innerHTML = pinIndex.toString();
  pinDiv.appendChild(pinImg);
  pinDiv.appendChild(pinNumber);
  return pinDiv;
};

const getNextPinIndex = (pins: Pin[]) => {
  let maxPinIndex = 0;
  pins.forEach((pin) => {
    if (pin.pinIndex > maxPinIndex) {
      maxPinIndex = pin.pinIndex;
    }
  });
  return maxPinIndex + 1;
};

export class SlidePinComments extends React.Component<Props, State> {
  DefaultComment = 'Enter comment here..';

  constructor(props: Props) {
    super(props);
    this.state = { selectedPin: null, createPinMode: true };
    this.resetSelectedPin = this.resetSelectedPin.bind(this);
    this.initPins = this.initPins.bind(this);
    this.handleAddPin = this.handleAddPin.bind(this);
  }

  componentDidMount() {
    this.props.viewer.addHandler('open', this.initPins);
    this.props.viewer.addHandler('zoom', this.handleClosePin);
    this.props.viewer.addHandler('pan', this.handleClosePin);
    this.props.viewer.addHandler('canvas-nonprimary-press', this.handleRightClick);
    this.props.viewer.element.oncontextmenu = this.disableDefault;
  }

  componentWillUnmount() {
    this.props.viewer.removeHandler('open', this.initPins);
    this.props.viewer.removeHandler('zoom', this.handleClosePin);
    this.props.viewer.removeHandler('pan', this.handleClosePin);
    this.props.viewer.removeHandler('canvas-nonprimary-press', this.handleRightClick);
    this.props.viewer.element.oncontextmenu = null;
  }

  handleRightClick = (event: ViewerEvent) => {
    this.handleClosePin();
    this.handleAddPin(event);
  };

  disableDefault(event: any) {
    event.preventDefault();
  }

  componentDidUpdate(prevProps: Props) {
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    if (this.props.viewer._opening) {
      return;
    }
    prevProps.pins.forEach((prevPropsPin: Pin) => {
      if (!this.props.pins.find((pin: Pin) => pin.id === prevPropsPin.id)) {
        this.props.viewer.removeOverlay(prevPropsPin.id);
      }
    });
    this.props.pins.forEach((pin: Pin) => {
      if (!prevProps.pins.find((pinFromPrevProps: Pin) => pinFromPrevProps.id === pin.id)) {
        this.addPinToViewer(pin);
      }
    });
  }

  initPins() {
    (this.props.pins || []).forEach((pin: Pin) => {
      this.addPinToViewer(pin);
    });
  }

  resetSelectedPin() {
    this.setState({ selectedPin: null, createPinMode: true });
  }

  addPinToViewer(pin: Pin) {
    const pinElement = getPinElement(pin.id, pin.pinIndex || getNextPinIndex(this.props.pins));

    const viewportCoordinates = this.viewerFirstTiledImage().imageToViewportCoordinates(pin.x, pin.y);

    this.props.viewer.addOverlay({
      element: pinElement,
      location: new Point(viewportCoordinates.x, viewportCoordinates.y),
      rotationMode: OverlayRotationMode.NO_ROTATION,
    });

    // eslint-disable-next-line no-new
    new MouseTracker({
      element: pinElement.id,
      clickHandler: (_) => {
        this.handleClosePin();
        this.setState({ selectedPin: pin, createPinMode: false });
      },
    });
  }

  private viewerFirstTiledImage() {
    return this.props.viewer.world.getItemCount() > 1
      ? this.props.viewer.world.getItemAt(0)
      : this.props.viewer.viewport;
  }

  handleAddPin(event: any) {
    this.resetSelectedPin();
    const pixelPoint = this.props.viewer.viewport.pointFromPixel(event.position);
    const imageCoordinates = this.viewerFirstTiledImage().viewportToImageCoordinates(pixelPoint);

    const pin = {
      id: uuidv4(),
      pinIndex: getNextPinIndex(this.props.pins),
      comment: this.DefaultComment,
      x: imageCoordinates.x,
      y: imageCoordinates.y,
      draft: true,
    };

    this.props.onAddPinToView(pin);
    this.setState({ selectedPin: pin, createPinMode: true });
  }

  handleRemovePinFromView = () => {
    this.props.onRemovePinFromView(this.state.selectedPin);
    this.resetSelectedPin();
  };

  handleDeletePin = () => {
    this.props.onRemovePin(this.state.selectedPin);
    this.resetSelectedPin();
  };

  handleClosePin = () => {
    if (this.state.selectedPin && this.state.selectedPin.draft) {
      this.handleRemovePinFromView();
    } else {
      this.resetSelectedPin();
    }
  };

  handleSavePin = (comment: string) => {
    const editedPin = this.state.selectedPin;
    editedPin.comment = comment;
    this.props.onSavePin(editedPin);
    this.resetSelectedPin();
  };

  handleSaveNewPin = (comment: string) => {
    const editedPin = this.state.selectedPin;
    editedPin.comment = comment === this.DefaultComment ? '' : comment;
    this.props.onAddPin(editedPin);
    this.resetSelectedPin();
  };

  handleEditPin = () => {
    this.setState({ createPinMode: true });
  };

  render() {
    const { selectedPin, createPinMode } = this.state;
    if (selectedPin == null) {
      return <></>;
    }
    const { viewer } = this.props;
    const item = viewer.world.getItemCount() > 1 ? viewer.world.getItemAt(0) : viewer.viewport;
    // @ts-ignore
    const viewportCoordinates = item.imageToViewerElementCoordinates(new Point(selectedPin.x, selectedPin.y));

    return (
      <PinModal
        key={selectedPin.id}
        pin={selectedPin}
        createPinMode={createPinMode}
        viewportCoordinates={viewportCoordinates}
        handleRemovePinFromView={this.handleRemovePinFromView}
        handleSaveNewPin={this.handleSaveNewPin}
        handleSaveExistingPin={this.handleSavePin}
        handleClosePin={this.handleClosePin}
        handleEditPin={this.handleEditPin}
        handleDeletePin={this.handleDeletePin}
      />
    );
  }
}

export default SlidePinComments;
