import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import { getJob } from 'api/jobs';

export const useJob = (jobId: string) => {
  return useQuery({
    queryKey: ['job', jobId],
    queryFn: ({ signal }) => getJob(jobId, signal),
    enabled: !isEmpty(jobId),
    retry: false,
  });
};
