import { DrawPolygonMode as NebulaDrawPolygonMode } from 'nebula.gl';
import { Polygon } from './geojson-types';

export class DrawPolygonMode extends NebulaDrawPolygonMode {
  handleKeyUp(
    event: Parameters<NebulaDrawPolygonMode['handleKeyUp']>[0],
    props: Parameters<NebulaDrawPolygonMode['handleKeyUp']>[1]
  ) {
    // this is taken from GeoJsonEditMode class in nebula.gl
    // it is needed to cancel the current drawing when escape is pressed
    // we need to copy it from there to here because NebulaDrawPolygonMode is overriding the handleKeyUp method
    if (event.key === 'Escape') {
      this.resetClickSequence();
      props.onEdit({
        // Because the new drawing feature is dropped, so the data will keep as the same.
        updatedData: props.data,
        editType: 'cancelFeature',
        editContext: {},
      });
    }

    // this is taken from DrawPolygonMode class in nebula.gl
    if (event.key === 'Enter') {
      const clickSequence = this.getClickSequence();
      if (clickSequence.length > 2) {
        const polygonToAdd: Polygon = {
          type: 'Polygon',
          coordinates: [[...clickSequence, clickSequence[0]]],
        };
        this.resetClickSequence();

        const editAction = this.getAddFeatureOrBooleanPolygonAction(polygonToAdd, props);
        if (editAction) {
          props.onEdit(editAction);
        }
      }
    }
  }

  handleClick(clickEvent: any, props: any): void {
    if (clickEvent?.sourceEvent?.button !== 0) {
      return;
    }
    super.handleClick(clickEvent, props);
  }
}
