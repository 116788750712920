import { NO_STUDY_VALUE } from 'components/SearchFilters/hooks/useMainFilters';
import { SlideInferenceResults } from 'interfaces/calculateFeatures';
import { ClinicalData } from 'interfaces/clinical';
import { ResultsMode } from 'interfaces/experimentResults';
import { Procedure } from 'interfaces/procedure';
import { Study, StudyUpdate } from 'interfaces/study';
import { omit, pick } from 'lodash';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const fetchStudies =
  (labId?: string, withStatistics: boolean = false) =>
  (): Promise<Study[]> => {
    return apiRequestHandlerPromise<Study[]>({
      url: `studies?${stringify({ labId, withStatistics })}`,
      method: 'GET',
    });
  };

export const updateStudy = (study: Partial<StudyUpdate>) => {
  return apiRequestHandlerPromise({
    url: `studies/${study.id}`,
    method: 'PATCH',
    data: JSON.stringify(study),
  });
};

export const resetStudy = (study_id: string) => {
  return apiRequestHandlerPromise({
    url: `studies/${study_id}/reset`,
    method: 'DELETE',
  });
};

export const fetchSingleStudy = (studyId: string, labId?: string): Promise<Study> => {
  return apiRequestHandlerPromise<Study>({
    url: `studies/${studyId}?${stringify({ labId })}`,
    method: 'GET',
  });
};

export const getStudyProcedureQueryKey = (studyId: string | number, procedureId: number, queryParams?: any) => {
  // this key is without the studyId because for now the backend fetches the case's studyId by itself.
  // and we can also be in a case page with studyId = ALL / NONE, so we don't want to refetch the case
  // this should be changed in the future
  return ['procedure', procedureId, { ...omit(queryParams?.filters, 'studyId'), slidesMode: queryParams?.slidesMode }];
};

export const getStudyProcedures = ({
  studyId,
  page,
  pageSize,
}: {
  studyId: string;
  page: number;
  pageSize: number;
}): Promise<Procedure[]> => {
  return apiRequestHandlerPromise<Procedure[]>({
    url: `studies/${studyId}/procedures?${stringify({ page, pageSize })}`,
    method: 'GET',
  });
};

export const getStudyProcedure = (
  studyId: string | number,
  procedureId: number,
  encodedFilters?: string,
  signal?: AbortSignal
) => {
  // TODO: remove this when we finish the refactor, this is a temporary fix for the case where studyId is undefined
  // the backend fetches the case's studyId by itself, so we don't need to send the real one
  if (!studyId) {
    studyId = NO_STUDY_VALUE;
  }
  return apiRequestHandlerPromise<{ procedure: Procedure }>({
    url: `studies/${studyId}/procedures/${procedureId}?${encodedFilters}`,
    method: 'GET',
    signal,
  });
};

export const getAllStudies = () => {
  return apiRequestHandlerPromise<Pick<Study, 'id' | 'name'>[]>({
    url: `studies/list_options`,
    method: 'GET',
  });
};

export const startStudyAnalysis = (studyId: string) => {
  return apiRequestHandlerPromise({
    url: `studies/${studyId}/analysis`,
    method: 'PATCH',
    data: JSON.stringify({ analysis_start_time: new Date().toISOString() }),
  });
};

export const createStudy = (data: Partial<Study>): Promise<{ study_id: string }> => {
  return apiRequestHandlerPromise({
    url: `add_new_study`,
    method: 'POST',
    data: JSON.stringify(data),
  });
};

export const overrideStudy = ({ studyId, procedureLabels }: { studyId: string; procedureLabels: string[] }) => {
  return apiRequestHandlerPromise<Procedure[]>({
    url: `studies/${studyId}/procedures/override`,
    method: 'POST',
    data: JSON.stringify({ procedureLabels }),
  });
};

export const createProcedures = ({
  studyId,
  procedures,
}: {
  studyId: string;
  procedures: Procedure[];
}): Promise<Procedure[]> => {
  return apiRequestHandlerPromise<Procedure[]>({
    url: `studies/${studyId}/procedures?${stringify({ overrideStudy })}`,
    method: 'POST',
    data: JSON.stringify({ procedures }),
  });
};

export type CaseUpdate = Pick<Procedure, 'label' | 'cancerTypeId' | 'cancerSubtypes'>;
export const getUpdatesFromCase = (caseData: Partial<Procedure>): Partial<CaseUpdate> => {
  return pick(caseData, ['label', 'cancerTypeId', 'cancerSubtypes']);
};

export const updateProcedure = ({
  studyId,
  caseId,
  update,
  filterParams,
}: {
  studyId: string;
  caseId: number;
  update: CaseUpdate;
  filterParams: {
    features?: any;
    filters?: any;
    clinicalData?: ClinicalData;
    labId?: string;
    slidesMode?: boolean;
  };
}) => {
  return apiRequestHandlerPromise<Procedure>({
    url: `studies/${studyId}/procedures/${caseId}`,
    method: 'POST',
    data: JSON.stringify({
      ...filterParams,
      procedureUpdates: update,
    }),
  });
};

export const getCellsTablePresignedUrls = (studyId: string, orchestrationId: string, resultsMode: ResultsMode) => {
  return apiRequestHandlerPromise<Record<string, string>>({
    url: `studies/${studyId}/cells_table_presigned_urls?${stringify({
      orchestrationId,
      resultsMode,
    })}`,
    method: 'GET',
  });
};

export const getSlidesDataForArtifactsResults = (studyId: string, caseParams: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<SlideInferenceResults[]>({
    url: `studies/${studyId}/slides/data_for_artifacts_results?${caseParams}`,
    method: 'GET',
    signal,
  });
};
