import { Grid } from '@mui/material';
import PageHeader from 'components/PageHeader';
import wrapPage from 'components/atoms/wrapPage/wrapPage';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MarkdownRenderer from '../MarkdownRenderer';
import markdownContent from '../TERMSANDCONDITIONS.md';

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const outsideOfApp = !location.state || location.state?.fromExternalLink;

  return (
    <Grid container direction="column" sx={{ my: 4, px: 6 }}>
      <Grid item>
        <PageHeader
          pageTitle={'Terms of Use'}
          onBackClick={() => {
            if (outsideOfApp) {
              navigate('/');
            } else {
              navigate(-1);
            }
          }}
        />
      </Grid>
      <Grid item>
        <MarkdownRenderer content={markdownContent} />
      </Grid>
    </Grid>
  );
};

export default wrapPage(TermsAndConditions, {}, false);
