import ExpandIcon from '@mui/icons-material/ExpandMore';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { runMultiplexHistogram } from 'api/platform';
import { HistogramJob, JobType } from 'interfaces/job';
import { MultiplexHistogramConfig } from 'interfaces/jobs/multiplex/histogramParams';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { humanize } from 'utils/helpers';
import { CasesParams } from 'utils/useCasesParams';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import * as yup from 'yup';
import { JobWithRebuild } from '../JobWithRebuild';
import { OldJobsStep } from '../OldJobsStep';
import { PlatformStepper } from '../PlatformStepper';
import HistogramConfig, { defaultHistogramConfig, validationHistogramConfig } from './RunHistogram/HistogramParams';

const SNACK_BAR_KEY_RUN_MULTIPLEX_HISTOGRAM = 'RUN_MULTIPLEX_HiSTOGRAM';

export interface RunMultiplexHistogramProps {
  onClose: () => void;
  jobId?: string;
  casesParams: CasesParams;
}

export const RunMultiplexHistogram = (props: RunMultiplexHistogramProps): ReactElement => {
  const { onClose, casesParams, jobId } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedJobId, setSelectedJobId] = useState<string>(jobId ?? undefined);

  const currentValidationSchema = validationSchema[activeStep];

  const [isStepFailed, setIsStepFailed] = useState<Record<number, boolean>>({});

  const {
    reset,
    getValues,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<HistogramFormValues>({
    mode: 'onChange',
    defaultValues: {
      jobName: null,
      jobDescription: null,
      configParams: defaultHistogramConfig,
    },
    resolver: yupResolver(currentValidationSchema),
  });

  const checkValidationAndSetIsStepFailed = (stepIndex: number, objectToValidate: Record<string, any>) => {
    validationSchema[stepIndex]
      .validate(objectToValidate)
      .then(() => {
        setIsStepFailed((prev) => ({
          ...prev,
          [stepIndex]: false,
        }));
      })
      .catch(() => {
        setIsStepFailed((prev) => ({
          ...prev,
          [stepIndex]: true,
        }));
      });
  };

  const runMultiplexHistogramMutation = useMutationWithErrorSnackbar({
    mutationFn: runMultiplexHistogram,
    mutationDescription: 'run multiplex histogram',
    onSuccess: () => {
      enqueueSnackbar('Multiplex Histogram Started', { variant: 'success' });
    },
    onSettled() {
      closeSnackbar(SNACK_BAR_KEY_RUN_MULTIPLEX_HISTOGRAM);
    },
  });

  const onSubmit: SubmitHandler<HistogramFormValues> = async (data) => {
    runMultiplexHistogramMutation.mutate({
      ...casesParams,
      jobName: data.jobName,
      jobDescription: data.jobDescription,
      configParams: data.configParams,
    });

    enqueueSnackbar({
      variant: 'success',
      message: (
        <Grid container>
          <Grid item>
            <Typography>Waiting for Multiplex Histogram to start</Typography>
          </Grid>
          <Grid item>
            <CircularProgress sx={{ marginLeft: 10 }} color="inherit" size={20} />
          </Grid>
        </Grid>
      ),
      key: SNACK_BAR_KEY_RUN_MULTIPLEX_HISTOGRAM,
      autoHideDuration: null,
    });

    onClose();
  };

  const validateConfigurationStep = async () => {
    checkValidationAndSetIsStepFailed(2, {
      configParams: watch('configParams'),
    });
  };

  const onSelectedJobParamChange = (newValue: HistogramJob) => {
    if (isEmpty(newValue)) {
      setSelectedJobId(undefined);
      reset({
        jobName: null,
        jobDescription: null,
        configParams: defaultHistogramConfig,
      });
    } else {
      reset({
        jobName: newValue?.name,
        jobDescription: newValue?.description,
        configParams: newValue?.params,
      });

      validateConfigurationStep();

      setSelectedJobId(newValue.id);
      if (!('params' in newValue)) {
        console.warn('histogram params not found in job params');
      }
      if (!('name' in newValue)) {
        console.warn('name not found in job params');
      }
      if (!('description' in newValue)) {
        console.warn('description not found in job params');
      }
    }
  };

  const steps = [
    {
      label: 'Upload Params From Old Job',
      optional: true,
      content: (
        <OldJobsStep
          jobType={JobType.MultiplexHistogram}
          onSelectedJob={onSelectedJobParamChange}
          selectedJobId={selectedJobId}
        />
      ),
    },
    {
      label: 'Job Name and Description',
      subLabel: activeStep > 0 && watch('jobName'),
      optional: true,
      content: (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Controller
              control={control}
              name="jobName"
              render={({ field: { onChange } }) => (
                <TextField
                  label="Job Name"
                  {...register('jobName')}
                  onChange={onChange}
                  placeholder="Type Here"
                  error={Boolean(errors['jobName'])}
                  helperText={humanize(errors['jobName']?.message)}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="jobDescription"
              render={({ field: { onChange } }) => (
                <TextField
                  label="Job Description"
                  {...register('jobDescription')}
                  onChange={onChange}
                  placeholder="Type Here"
                  error={Boolean(errors['jobDescription'])}
                  helperText={humanize(errors['jobDescription']?.message)}
                  multiline
                  minRows={4}
                />
              )}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      label: 'Histogram Configuration',
      optional: true,
      content: (
        <HistogramConfig
          histogramConfig={watch('configParams') ?? ({} as MultiplexHistogramConfig)}
          setHistogramConfig={(histogramConfig) => {
            reset({
              ...getValues(),
              configParams: histogramConfig,
            });

            validateConfigurationStep();
          }}
          casesParams={casesParams}
        />
      ),
      onNextOrBackClick: () => validateConfigurationStep(),
    },
    {
      label: 'Advanced',
      optional: true,
      content: (
        <Grid container>
          <Grid item xs={2}>
            <TextField
              label="Max Workers"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 16 } }}
              value={watch('configParams.maxWorkers') ?? 16}
              onChange={(event) =>
                reset({
                  ...getValues(),
                  configParams: { ...getValues().configParams, maxWorkers: Number(event.target.value) },
                })
              }
            />
          </Grid>
        </Grid>
      ),
    },
  ];

  return (
    <JobWithRebuild jobId={jobId} onSelectedJobParamChange={onSelectedJobParamChange}>
      <PlatformStepper
        handleSubmit={handleSubmit(onSubmit)}
        steps={steps}
        setActiveStepForValidation={setActiveStep}
        isStepFailed={isStepFailed}
      />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandIcon />}>Histogram Params Summary (JSON)</AccordionSummary>
        <AccordionDetails>
          <Typography component="pre">
            {JSON.stringify(
              {
                jobName: watch('jobName'),
                jobDescription: watch('jobDescription'),
                configParams: watch('configParams'),
              },
              null,
              2
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </JobWithRebuild>
  );
};

export interface HistogramFormValues {
  jobName: string;
  jobDescription: string;
  configParams: MultiplexHistogramConfig;
}

const validationSchema = [
  yup.object({}),
  yup.object({}),
  yup.object({
    configParams: validationHistogramConfig,
  }),
  yup.object({}),
];
