import { filter, find, first, isArray, isEmpty, join, map, uniq } from 'lodash';
import { EnumDisplayNames } from 'utils/queryHooks/uiConstantsHooks';
import { BaseDisplayedFieldOfObjectInArray, DisplayedField, ListField } from '.';

export const getDisplayNameFromEnumValue = (enumType: string, value: string, enumDisplayNames?: EnumDisplayNames) =>
  find(enumDisplayNames?.[enumType], { value })?.label ?? value ?? '';

export const formatEnumValue = <V>(rawValue: V, enumType: string, enumDisplayNames?: EnumDisplayNames) =>
  typeof rawValue === 'string'
    ? getDisplayNameFromEnumValue(enumType, rawValue, enumDisplayNames)
    : // Check at least one element contains non empty values
    isArray(rawValue)
    ? !isEmpty(filter(rawValue, (v) => Boolean(`${v ?? ''}`.trim())))
      ? // If so, format all elements, leaving empty ones as '-'
        join(
          map(rawValue, (v) => getDisplayNameFromEnumValue(enumType, v, enumDisplayNames)?.trim() ?? '-'),
          ', '
        )
      : '' // Otherwise, return empty string
    : // Otherwise, return empty string
      '';

export const defaultFormatter = <V>(value: V): string =>
  `${
    isArray(value)
      ? // Check at least one element contains non empty values
        !isEmpty(filter(value, (v) => Boolean(`${v ?? ''}`.trim())))
        ? // If so, format all elements, leaving empty ones as '-'
          join(
            map(value, (v) => `${v ?? ''}`.trim()),
            ', '
          )
        : // Otherwise, return empty string
          ''
      : value ?? ''
  }`;

export const objectArrayFieldFormatter = <R, V = string[] | number[] | boolean[], Context extends {} = {}>(
  value: V,
  field: BaseDisplayedFieldOfObjectInArray<R, V, Context>,
  context: Context
) =>
  `${
    isArray(value)
      ? // Check at least one element contains non empty values
        !isEmpty(
          filter(value, (v) =>
            Boolean(`${(field?.singleObjectFormatter ? field?.singleObjectFormatter(v, context) : v) ?? ''}`.trim())
          )
        )
        ? // If so, format all elements, leaving empty ones as '-'
          join(
            map(
              value,
              (v) =>
                `${(field?.singleObjectFormatter ? field?.singleObjectFormatter(v, context) : v) ?? '-'}`.trim() || '-'
            ),
            ', '
          )
        : // Otherwise, return empty string
          ''
      : value ?? ''
  }`;

export const formatUniqueFieldValue = <
  R,
  V = string | number | string[] | number[] | boolean | boolean[],
  Context extends { enumDisplayNames?: EnumDisplayNames } = {},
  UnwoundValue extends boolean = false
>(
  rawValue: UnwoundValue extends true ? (V extends Array<any> ? V[0] : V) : V,
  field: DisplayedField<R, V, Context>,
  context?: Context
): string => {
  if (!isArray(rawValue)) {
    console.warn('We are expecting an array here, but got something else', {
      field,
      rawValue,
    });
    return '';
  }
  // if the field is marked as unique, we expect all array values to be the same, and display a single value
  // Example: slide biopsy site in case mode
  const uniqueValues = uniq(rawValue);
  if (uniqueValues.length > 1) {
    console.warn("We're expecting a single unique value here, but got multiple", {
      field,
      uniqueValues,
    });
    // still show all the values
    return objectArrayFieldFormatter(uniqueValues, field as any, context);
  } else if ('singleObjectFormatter' in field) {
    // if there is a singleObjectFormatter, use it
    return field.singleObjectFormatter(first(uniqueValues) as any, context);
  } else {
    // if there is no singleObjectFormatter, use the default formatter for array fields
    return objectArrayFieldFormatter(uniqueValues, field as any, context);
  }
};

export const formatFieldValue = <
  R,
  V = string | number | string[] | number[] | boolean | boolean[],
  Context extends { enumDisplayNames?: EnumDisplayNames } = {},
  UnwoundValue extends boolean = false
>(
  rawValue: UnwoundValue extends true ? (V extends Array<any> ? V[0] : V) : V,
  field: DisplayedField<R, V, Context>,
  context?: Context,
  arrayFieldToUnwind?: keyof R
): string => {
  const isUnwoundField =
    field.isFieldOfObjectInArray && arrayFieldToUnwind && arrayFieldToUnwind === field.objectArrayPath;
  return field.valueFormatter
    ? field.valueFormatter({ value: rawValue }, context)
    : isUnwoundField && field.singleObjectFormatter
    ? field.singleObjectFormatter(rawValue as any, context)
    : !isUnwoundField && field.isFieldOfObjectInArray
    ? field.enforceUniquePerBaseRow
      ? formatUniqueFieldValue(rawValue, field as any, context)
      : objectArrayFieldFormatter(rawValue, field as any, context)
    : (field as ListField<Context>).enumType && context?.enumDisplayNames
    ? formatEnumValue(rawValue, (field as ListField<Context>).enumType, context?.enumDisplayNames)
    : defaultFormatter(rawValue);
};
