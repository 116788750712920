import { combineReducers } from 'redux';
import chartsReducer from 'redux/modules/chartSlice';
import cheatModeReducer from 'redux/modules/cheatModeSlice';
import authReducer from 'redux/reducers/auth';
import errorReducer from 'redux/reducers/error';
import labsReducer from 'redux/reducers/labs';
import preferencesReducer from 'redux/reducers/preferences';
import studyFiltersReducer from 'redux/reducers/studyFilters';

export default combineReducers({
  error: errorReducer,
  labs: labsReducer,
  auth: authReducer,
  preferences: preferencesReducer,
  cheatMode: cheatModeReducer,
  charts: chartsReducer,
  studyFilters: studyFiltersReducer,
});
