import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid } from '@mui/material';
import React from 'react';

import { useBulkEditControl } from './useBulkEditControl';

export interface BulkEditControlProps {
  disabled?: boolean;
  onSave?: (changes: { [field: string]: any }) => void;
}

export const BulkEditControl: React.FC<React.PropsWithChildren<BulkEditControlProps>> = ({ disabled, onSave }) => {
  const { bulkEditMode, setBulkEditMode, hasChanges, saveBulkEdits, undoBulkEdits } = useBulkEditControl(onSave);

  return (
    <Grid
      flexDirection="row"
      sx={{
        justifyContent: 'flex-end',
      }}
    >
      {bulkEditMode ? (
        <Grid container justifyContent="space-between">
          <Button
            size="small"
            disabled={!bulkEditMode}
            onClick={undoBulkEdits}
            startIcon={<CloseIcon fontSize="small" />}
            variant="text"
            data-cy="cancel-bulk-edits-button"
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={disabled || (bulkEditMode && !hasChanges)}
            onClick={saveBulkEdits}
            startIcon={<CheckIcon fontSize="small" />}
            data-cy="save-bulk-edits-button"
          >
            Save
          </Button>
        </Grid>
      ) : (
        <Button
          size="small"
          disabled={disabled || (bulkEditMode && !hasChanges)}
          onClick={() => setBulkEditMode(true)}
          title={disabled ? 'Select rows to start editing' : 'Bulk Edit'}
          endIcon={<EditIcon fontSize="small" />}
          data-cy="bulk-edit-button"
        >
          Bulk Edit
        </Button>
      )}
    </Grid>
  );
};
