import { Close } from '@mui/icons-material';

import { AppBar, Button, Dialog, DialogProps, Grid, IconButton, Toolbar, Typography, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';

import Loader from 'components/Loader';
import { Procedure } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import { useNavigate } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';

export const GalleryDialog: React.FC<
  React.PropsWithChildren<{
    onClose: DialogProps['onClose'];
    onKeyDown: DialogProps['onKeyDown'];
    isLoading: boolean;
    fullScreenActive: boolean;
    currentSlideIndex: number;
    totalSlides: number;
    currentCase?: Procedure;
    currentSlide?: Slide;
    galleryLabel?: ReactNode;
  }>
> = ({
  onClose,
  onKeyDown,
  isLoading,
  fullScreenActive,
  children,
  currentSlideIndex,
  totalSlides,
  currentCase,
  currentSlide,
  galleryLabel,
}) => {
  const { queryParams } = useEncodedFilters();
  const slidesMode = Boolean(queryParams?.slidesMode);
  const navigate = useNavigate();
  const theme = useTheme();
  const [galleryWindowCaseId] = useQueryParam('galleryWindowCaseId', NumberParam, {});
  const [galleryWindowSlideId] = useQueryParam('galleryWindowSlideId', StringParam, {});
  const { getUrlToCasePage, getUrlToSlidePage } = useNavigationToViewerPage();

  const slideIdForNavigation = currentSlide?.id ?? galleryWindowSlideId;
  const viewerPageUrl = slidesMode
    ? getUrlToSlidePage({
        slideId: slideIdForNavigation,
      })
    : getUrlToCasePage({
        caseId: currentCase?.id ?? galleryWindowCaseId,
        caseStudyId: currentCase?.studyId,
        selectedSlideIds: slidesMode ? [slideIdForNavigation] : undefined,
      });

  return (
    <Dialog
      open
      fullScreen
      onClose={onClose}
      onKeyDown={onKeyDown}
      sx={{
        height: '100vh',
        width: '100vw',
        maxWidth: '100vw',
        maxHeight: '100vh',
        overflow: 'hidden',

        '& .image-gallery-content': {
          backgroundColor: theme.palette.mode == 'dark' ? 'black' : 'white',
          '& .image-gallery-slide': {
            '& .image-gallery-image': {
              height: fullScreenActive ? 'calc(100vh - 90px)' : 'calc(100vh - 170px)',
            },
          },
          '& .image-gallery-thumbnail': {
            height: 81,
            '& .image-gallery-thumbnail-image': {
              height: 75,
              maxHeight: 75,
            },
          },
          '& .image-gallery-icon': {
            display: 'flex',
            position: 'absolute',
            filter: 'none',
            '&:hover': {
              color: 'unset',
            },
            margin: '5px',
            background: 'rgb(255 255 255 / 50%)',
            '& svg':
              theme.palette.mode === 'dark'
                ? {
                    filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,0.8))',
                  }
                : undefined,
          },
        },
      }}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Grid
            sx={{ mr: 5, flexGrow: 0, flexShrink: 1, width: 'unset', minWidth: 150 }}
            container
            direction={'column'}
          >
            <Typography variant="h6" component="div">
              {isLoading ? (
                <>Loading Slides...</>
              ) : (
                <>
                  Slide {currentSlideIndex}/{totalSlides}
                </>
              )}
            </Typography>
          </Grid>
          <Grid sx={{ mr: 5, flexGrow: 0, flexShrink: 1, width: 'unset' }} container direction={'column'}>
            <Typography variant="body1" component="div">
              Case {currentCase?.label ?? galleryWindowCaseId}
            </Typography>
          </Grid>
          <Grid sx={{ mr: 5, flex: 1 }} container direction={'column'}>
            <Typography variant="body1" component="div">
              {currentSlide?.originalFileName}
            </Typography>
          </Grid>
          <Grid sx={{ mr: 5, flex: 1 }} container direction={'column'}>
            <Typography variant="body1" component="div">
              Slide ID {slideIdForNavigation}
            </Typography>
          </Grid>

          <Grid sx={{ mr: 5, flex: 1 }} container direction={'column'}>
            {galleryLabel && (
              <Typography variant="body1" component="div">
                {galleryLabel}
              </Typography>
            )}
          </Grid>

          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              onClose({}, 'backdropClick');
              navigate(viewerPageUrl, {
                replace: false,
              });
            }}
          >
            Open Viewer
          </Button>
          <IconButton
            sx={{ ml: 2 }}
            edge="end"
            color="inherit"
            onClick={() => onClose({}, 'backdropClick')}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      {isLoading ? <Loader /> : children}
    </Dialog>
  );
};
