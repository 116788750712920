import { useTheme } from '@mui/material';
import { DataGridProps } from '@mui/x-data-grid';

export const useDataGridStyle = () => {
  const theme = useTheme();
  const sx: DataGridProps['sx'] = {
    '& .MuiToolbar-root': {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    '& .MuiDataGrid-withBorderColor': {
      borderColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
      borderRightStyle: 'solid',
      borderRightWidth: '1px',
    },

    '& .MuiDataGrid-columnHeader': {
      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],

      '& .MuiDataGrid-columnHeaderTitleContainerContent': {
        '&, &>.MuiDataGrid-columnHeaderTitle, & .MuiTypography-root': {
          fontWeight: 'bold',
          textOverflow: 'ellipsis',
          overflow: 'clip',
          display: 'inline-block',
        },
      },
    },
    '& .MuiDataGrid-columnHeaderTitleContainerContent': {
      width: '100%',
    },
    '& .cell-value-changed': {
      backgroundColor: 'rgba(0, 0, 255, 0.1)',
    },
    '& .cell-error': {
      backgroundColor: 'rgba(255, 0, 0, 0.5)',
    },
    '& .MuiTablePagination-root': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .MuiDataGrid-overlayWrapper': {
      height: '100%',
      '& .MuiDataGrid-overlayWrapperInner': {
        height: '100%',
      },
    },
  };
  return { sx };
};
