import { useQuery, useQueryClient } from '@tanstack/react-query';
import { createFormResponse, getExistingFormResponse, getFormResponses } from 'api/forms/responses';
import { FormResponse, FormResponseWithAuthor, ResponseContext, ReviewFormNewResponse } from 'interfaces/reviewForm';
import { isNil, omitBy, pick } from 'lodash';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';

const contextToKey = (context: Partial<ResponseContext>) =>
  omitBy(pick(context, 'slideId', 'caseId', 'studyId'), isNil); // This function is used to create a consistent key for the query based on the response context

export const useCreateFormResponse = () => {
  const client = useQueryClient();
  return useMutationWithErrorSnackbar({
    mutationDescription: 'create form response',
    mutationFn: (data: { formId: string; response: ReviewFormNewResponse; context: ResponseContext }) =>
      createFormResponse(data.formId, data.response, data.context),
    onSuccess: (data) => {
      client.invalidateQueries(['formResponses', data.response.formId]);
      client.invalidateQueries(['formResponses', 'all']);

      client.setQueryData<FormResponse>(
        ['formResponse', data.response.formId, contextToKey(data.response.context)],
        data.response
      );
    },
  });
};

export const useExistingFormResponse = (
  formId: string,
  context: ResponseContext,
  {
    enabled = true,
  }: {
    enabled?: boolean;
  } = {}
) => {
  return useQuery<FormResponseWithAuthor>({
    queryKey: ['formResponse', formId, contextToKey(context)],
    queryFn: () => getExistingFormResponse(formId, context).then((response) => response.response),
    enabled: Boolean(enabled && formId && context), // This ensures the query only runs when formId and context are available
  });
};

export const ALL_FORMS_ID = 'all';

type AllFormsId = typeof ALL_FORMS_ID;

export const useFormResponses = (
  formId: AllFormsId | string,
  context: Partial<ResponseContext>,
  { enabled = true, ...options }: Partial<Parameters<typeof useQuery<FormResponseWithAuthor[]>>[2]> = {}
) => {
  return useQuery<FormResponseWithAuthor[]>({
    queryKey: ['formResponses', formId, contextToKey(context)],
    queryFn: () => getFormResponses(formId, context).then((response) => response.responses),
    enabled: Boolean(formId && context && enabled), // This ensures the query only runs when formId and context are available
    ...options,
  });
};
