import { Button, Snackbar, SnackbarContent } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';

interface CookieNoticeProps {}

const CookieNotice: FunctionComponent<React.PropsWithChildren<CookieNoticeProps>> = () => {
  const [accepted, setAccepted] = useLocalStorage('cookie-notice-accepted', false);
  return (
    <Snackbar
      id="cookie-notice"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={!accepted}
    >
      <SnackbarContent
        message={
          <>
            This website stores cookies on your computer. These cookies are used to collect information about how you
            interact with this website and allow us to remember you. We use this information in order to improve and
            customize your browsing experience and for analytics and metrics about our visitors both on this website and
            other media. To find out more about the cookies we use, see our{' '}
            <Link to="/privacy-policy">Privacy Policy.</Link>
          </>
        }
        action={
          <Button
            id="cookie-notice-accept"
            data-cy="cookie-notice-accept"
            color="primary"
            size="small"
            onClick={() => setAccepted(true)}
            variant="contained"
          >
            Accept
          </Button>
        }
      />
    </Snackbar>
  );
};

export default CookieNotice;
