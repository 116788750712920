import { createFilterOptions } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import { OrchestrationResponse } from 'api/experimentResults';
import { getFeatureKeysByStudyAndOrchestration } from 'api/features';
import { NamedFeature } from 'interfaces/features';
import { useProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { getFeaturesKeysObjectsWebWorker } from 'utils/features/getFeaturesKeysObjectsWebWorker';
import { useFormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { useFeatureFormatters, useUiSettings } from 'utils/queryHooks/uiConstantsHooks';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import useMainFilters from './useMainFilters';
import useOrchestrationFilter from './useOrchestrationFilter';

export const featuresFilterOptions = createFilterOptions({
  stringify: (option: NamedFeature) => (option?.displayName || '') + ' ' + (option?.nameOverride || ''),
});

const useFeatureFilters = ({
  studyId,
  orchestrationId,
  onSuccessGetExperimentDefinitions,
  includeInferredFeatures = false,
  includeHiddenFeatures = false,
  stainIndexFilter, // Filter results by stain index
  enabled = true,
}: {
  studyId: string;
  orchestrationId: string;
  onSuccessGetExperimentDefinitions?: (data: OrchestrationResponse) => void;
  includeInferredFeatures?: boolean;
  includeHiddenFeatures?: boolean;
  stainIndexFilter?: string;
  enabled?: boolean;
}) => {
  const { uiSettings, isLoadingUiSettings } = useUiSettings();
  const { featureFormatters, isLoadingFeatureFormatters } = useFeatureFormatters();

  const { stainTypesInStudy } = useProceduresFieldsContext();
  const addStainToFeatureName = stainTypesInStudy && stainTypesInStudy.length > 1;

  const formatBracketsOptions = useFormatBracketsOptions(addStainToFeatureName);

  const { isStudyIdSpecific } = useMainFilters();
  const studyIdIsSpecific = isStudyIdSpecific(studyId);
  const { queryParams } = useEncodedFilters();

  const { orchestrationsLoading, orchestrationIdOptions, orchestrations } = useOrchestrationFilter(
    studyId,
    queryParams.resultsMode,
    onSuccessGetExperimentDefinitions
  );

  const canLoadFeatureKeys = studyIdIsSpecific && !orchestrationsLoading && enabled;
  const { data: featureKeysResponse, isLoading: featureKeysLoadingState } = useQuery({
    queryKey: [
      'featureKeys',
      {
        studyId,
        orchestrationId,
        includeInferredFeatures,
        includeHiddenFeatures,
        resultsMode: queryParams.resultsMode,
      },
    ],
    queryFn: () =>
      getFeatureKeysByStudyAndOrchestration(
        studyId,
        orchestrationId,
        includeInferredFeatures,
        includeHiddenFeatures,
        queryParams.resultsMode
      ),
    placeholderData: { featuresKeys: [] },
    enabled: canLoadFeatureKeys,
  });
  const featureKeysLoading = featureKeysLoadingState && canLoadFeatureKeys;

  const parseFeatureData = () => {
    return getFeaturesKeysObjectsWebWorker(featureKeysResponse?.featuresKeys, formatBracketsOptions, stainIndexFilter);
  };

  const isLoadingFormatterData = isLoadingFeatureFormatters || isLoadingUiSettings || formatBracketsOptions.isLoading;

  const canParseFeaturesData = !isEmpty(featureKeysResponse?.featuresKeys) && enabled && !isLoadingFormatterData;
  const { data: featuresData, isLoading: isParsingFeaturesDataState } = useQuery({
    queryKey: [
      'featuresData',
      featureKeysResponse?.featuresKeys,
      uiSettings,
      featureFormatters,
      formatBracketsOptions,
      stainIndexFilter,
      addStainToFeatureName,
    ],
    queryFn: parseFeatureData,
    enabled: canParseFeaturesData,
    staleTime: Infinity, // getFeaturesKeysObj is a pure function, so we can cache it indefinitely
    retry: false,
  });
  const isParsingFeaturesData = isParsingFeaturesDataState && canParseFeaturesData;

  return {
    orchestrationIdOptions,
    orchestrationsLoading,
    featuresLoading: featureKeysLoading || isParsingFeaturesData,
    orchestrations,
    featuresData: featuresData as NamedFeature[] | undefined,
  };
};

export default useFeatureFilters;
