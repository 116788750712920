import { stringify } from 'qs';

import { AnnotationExtension } from 'interfaces/annotation';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getAnnotationExtensionsByAssignmentIds = (annotationAssignmentIds: number[], signal: AbortSignal) => {
  return apiRequestHandlerPromise<AnnotationExtension[]>({
    url: `annotation_extensions/by_annotation_assignment_ids?${stringify(
      { annotationAssignmentIds },
      { arrayFormat: 'repeat' }
    )}`,
    method: 'GET',
    signal,
  });
};

export const getAllAnnotationExtensions = ({ signal }: { signal?: AbortSignal }) => {
  return apiRequestHandlerPromise<AnnotationExtension[]>({
    url: `annotation_extensions/all`,
    method: 'GET',
    signal,
  });
};

export const getUnarchivedAnnotationExtensions = ({ signal }: { signal?: AbortSignal }) => {
  return apiRequestHandlerPromise<AnnotationExtension[]>({
    url: `annotation_extensions/all_unarchived`,
    method: 'GET',
    signal,
  });
};
