import { BasicTableRow, RowChange } from 'components/atoms/EditableDataGrid/TableEditingContext/types';
import deepEqual from 'deep-equal';
import { castArray, compact, findIndex, get, isEmpty, map } from 'lodash';
import { UnwoundRow, getFieldValue } from './unwindRowsWithInnerArrays';

import { BaseDisplayedFieldOfObjectInArray, DisplayedField } from '.';

export const checkFieldValuesChanged = (changedValue: any, oldValue: any, isArrayValue: boolean): boolean =>
  isArrayValue
    ? !deepEqual(oldValue, changedValue) &&
      // if both are empty, we don't want to show the field as changed
      (!isEmpty(compact(oldValue)) || !isEmpty(compact(changedValue)))
    : // if both are empty, we don't want to show the field as changed
      !deepEqual(compact([oldValue]), compact([changedValue]));

export const didFieldChangeInRow = <R extends BasicTableRow, Context = any>(
  changedRow: R,
  oldRow: R,
  field: DisplayedField<R, any, Context>,
  fieldsContext?: Context,
  innerIdx?: number
): boolean => {
  const changedValue = getFieldValue(changedRow, field, fieldsContext);
  const oldValue = getFieldValue(oldRow, field, fieldsContext);
  if (field.isFieldOfObjectInArray && innerIdx !== undefined) {
    return checkFieldValuesChanged(changedValue[innerIdx], oldValue[innerIdx], false);
  } else {
    return checkFieldValuesChanged(changedValue, oldValue, field.isFieldOfObjectInArray);
  }
};

export const didFieldChangeForUnwoundObjectInArray = <
  R extends BasicTableRow,
  V extends any,
  UnwoundType extends object,
  Context = any
>(
  changedValue: V,
  changedRow: UnwoundRow<R, UnwoundType>,
  oldRow: R,
  field: BaseDisplayedFieldOfObjectInArray<R, any, Context>
): boolean => {
  const { objectArrayInnerPath, objectArrayPath, objectArrayKeyPath } = field;
  const { innerRowId } = changedRow._unwoundRowMetadata;

  const innerRowIdx = findIndex(get(oldRow, objectArrayPath), (o) => get(o, objectArrayKeyPath) === innerRowId);

  const updatePath = `${objectArrayPath}[${innerRowIdx}].${objectArrayInnerPath}`;

  return deepEqual(changedValue, get(oldRow, updatePath));
};

export const getRowChangesForObjectInArray = <R extends BasicTableRow, V extends any[], Context = any>(
  changedValue: V,
  changedRow: R,
  oldRow: R,
  field: BaseDisplayedFieldOfObjectInArray<R, any, Context>
): RowChange[] => {
  const rowChanges: RowChange[] = [];

  const { objectArrayInnerPath, objectArrayKeyPath, objectArrayPath } = field;
  const changedObjectArray = get(changedRow, objectArrayPath);
  const objectArrayKeys: Array<string | number> = map(changedObjectArray, (o) => get(o, objectArrayKeyPath));

  if (castArray(changedValue).length > objectArrayKeys.length) {
    console.warn("Too many object array elements are assigned - don't update");
    return rowChanges;
  }
  const oldObjectArray = get(oldRow, objectArrayPath);
  for (const rowObjectKey of objectArrayKeys) {
    const relevantObjectIndex = findIndex(oldObjectArray, (o) => get(o, objectArrayKeyPath) === rowObjectKey);

    if (relevantObjectIndex < 0) {
      console.error('Error finding object in array - resetting changes');
      rowChanges.push({ path: objectArrayInnerPath, value: null });
    } else {
      const updatePath = `${objectArrayPath}[${relevantObjectIndex}].${objectArrayInnerPath}`;
      const keyPath = `${objectArrayPath}[${relevantObjectIndex}].${objectArrayKeyPath}`;
      const innerObjectChangedValue = changedValue[relevantObjectIndex];
      rowChanges.push(
        { path: updatePath, value: innerObjectChangedValue },
        // Make sure the inner object's key is documented in the row changes
        { path: keyPath, value: rowObjectKey }
      );
    }
  }
  return rowChanges;
};

export const getRowChangesForUnwoundObjectInArray = <
  R extends BasicTableRow,
  V extends any,
  UnwoundType extends object,
  Context = any
>(
  changedValue: V,
  changedRow: UnwoundRow<R, UnwoundType>,
  oldRow: R,
  field: BaseDisplayedFieldOfObjectInArray<R, any, Context>
): RowChange[] => {
  const rowChanges: RowChange[] = [];

  const { objectArrayInnerPath, objectArrayKeyPath, objectArrayPath } = field;
  const { innerRowId } = changedRow._unwoundRowMetadata;

  const innerRowIdx = findIndex(get(oldRow, objectArrayPath), (o) => get(o, objectArrayKeyPath) === innerRowId);

  const updatePath = `${objectArrayPath}[${innerRowIdx}].${objectArrayInnerPath}`;
  const keyPath = `${objectArrayPath}[${innerRowIdx}].${objectArrayKeyPath}`;

  if (!didFieldChangeForUnwoundObjectInArray(changedValue, changedRow, oldRow, field)) {
    rowChanges.push(
      { path: updatePath, value: changedValue },
      // Make sure the inner object's key is documented in the row changes
      { path: keyPath, value: innerRowId }
    );
  }

  return rowChanges;
};
