import { CircularProgress, Grid, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import React from 'react';

interface Props {
  numLoadingBaseLayers: number;
  numBaseLayers: number;
  numLoadingHeatmapLayers: number;
  numHeatmapLayers: number;
}

export const LayerLoadingOverlay: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  numLoadingBaseLayers,
  numBaseLayers,
  numLoadingHeatmapLayers,
  numHeatmapLayers,
}) => {
  useSignals();

  const percentLoadedBaseLayers = numBaseLayers > 0 ? (numBaseLayers - numLoadingBaseLayers) / numBaseLayers : 1;
  const percentLoadedHeatmapLayers =
    numHeatmapLayers > 0 ? (numHeatmapLayers - numLoadingHeatmapLayers) / numHeatmapLayers : 1;

  const loadedEntity = percentLoadedBaseLayers < 1 ? 'Slide' : percentLoadedHeatmapLayers < 1 ? 'Base Layers' : null;
  const loadedPercent =
    percentLoadedBaseLayers < 1
      ? percentLoadedBaseLayers
      : percentLoadedHeatmapLayers < 1
      ? percentLoadedHeatmapLayers
      : 1;
  const loadedCount =
    percentLoadedBaseLayers < 1
      ? numBaseLayers - numLoadingBaseLayers
      : percentLoadedHeatmapLayers < 1
      ? numHeatmapLayers - numLoadingHeatmapLayers
      : 0;
  const totalCount =
    percentLoadedBaseLayers < 1 ? numBaseLayers : percentLoadedHeatmapLayers < 1 ? numHeatmapLayers : 0;

  return (
    Boolean(loadedEntity) && (
      <Grid
        item
        container
        width="fit-content"
        mr={1}
        sx={{
          fontSize: '10px',
          alignSelf: 'center',
          color: '#fff',
          borderRadius: 2,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          padding: '0 10px',
        }}
      >
        <Typography variant="caption">
          <CircularProgress size={10} sx={{ mr: 1 }} />
          {loadedEntity} Loading ({Math.round(loadedPercent * 100)}%)... ({loadedCount}/{totalCount})
        </Typography>
      </Grid>
    )
  );
};

export default LayerLoadingOverlay;
