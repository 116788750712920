import { FormField, FormResponse } from 'interfaces/reviewForm';
import { get } from 'lodash';
import React, { FunctionComponent } from 'react';
import FormCheckboxField from './FormCheckboxField';
import FormMultiSelectField from './FormMultiSelectField';
import FormSelectField from './FormSelectField';
import FormTextField from './FormTextField';
import FormToggleButtonSelectField from './FormToggleButtonSelectField';

export interface ReviewFormFieldProps {
  field: FormField;
  response?: FormResponse;
  handleResponseChange?: (fieldId: string, value: any) => void;
}

const ReviewFormField: FunctionComponent<React.PropsWithChildren<ReviewFormFieldProps>> = ({
  field,
  response,
  handleResponseChange,
}) => {
  const { id } = field;

  const value = get(response?.filledFields, id);

  const onChange = (newValue: string | string[] | boolean) => {
    handleResponseChange?.(id, newValue);
  };

  switch (field.type) {
    case 'text':
      return <FormTextField field={field} value={value as string} onChange={onChange} />;
    case 'select':
      if (field.options?.style === 'toggle-button')
        return <FormToggleButtonSelectField field={field} value={value as string} onChange={onChange} />;
      return <FormSelectField field={field} value={value as string} onChange={onChange} />;
    case 'multiselect':
      return <FormMultiSelectField field={field} value={value as string[]} onChange={onChange} />;
    case 'checkbox':
      return <FormCheckboxField field={field} value={value as boolean} onChange={onChange} />;

    default:
      return null;
  }
};

export default ReviewFormField;
