import { Procedure } from 'interfaces/procedure';
import React from 'react';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { CaseNavigationPanel } from './CaseNavigationPanel';
import { SlideNavigationPanel } from './SlideNavigationPanel';

const NavigationPanel: React.FunctionComponent<
  React.PropsWithChildren<{
    currentCase: Procedure;
    studyId?: string;
    isLoadingCaseData?: boolean;
  }>
> = ({ currentCase, studyId, isLoadingCaseData }) => {
  const { queryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });
  const navigateBySlide = Boolean(queryParams.slidesMode);

  return navigateBySlide ? (
    <SlideNavigationPanel currentCase={currentCase} studyId={studyId} isLoadingCaseData={isLoadingCaseData} />
  ) : (
    <CaseNavigationPanel currentCase={currentCase} studyId={studyId} isLoadingCaseData={isLoadingCaseData} />
  );
};

export default NavigationPanel;
