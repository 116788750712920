import { TextField } from '@mui/material';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

interface TaxonomySearchProps {
  value: string;
  setValue: (value: React.SetStateAction<string>) => void;
  onSearch: (query: string) => void;
}

const TaxonomySearch: React.FC<TaxonomySearchProps> = ({ value, setValue, onSearch }) => {
  const handleDebouncedSearch = useDebouncedCallback((searchTerm) => {
    onSearch(searchTerm);
  }, 300);

  const onSearchChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value: newValue } = event.target;
    setValue(newValue);
    handleDebouncedSearch(newValue);
  };

  return <TextField fullWidth size="small" label="Search" value={value} onChange={onSearchChange} />;
};

export default TaxonomySearch;
