import { Slide } from 'interfaces/slide';
import { entries, map } from 'lodash';

export interface ChannelMetadata {
  id: string;
  channelName?: string;
  url?: string;
}

const generateChannelMetadata = ({
  channelIndex,
  channelName,
  slideId,
  labId,
}: {
  channelIndex: number | string;
  channelName?: string;
  slideId: string;
  labId: string;
}): ChannelMetadata => ({
  id: `${channelIndex}`,
  channelName,
  url: `https://image-static.nucleai.cloud/${labId}/${slideId}/tiles_${channelIndex}.dzi`,
});

export const channelsMetadataFromSlideChannels = ({
  id,
  labId,
  channels,
  channelMarkerTypes,
}: Pick<Slide, 'id' | 'labId' | 'channels' | 'channelMarkerTypes'>): ChannelMetadata[] =>
  channels
    ? map(entries(channels), ([channelIndex, channel]) =>
        generateChannelMetadata({ channelIndex, channelName: channel.name, slideId: id, labId })
      )
    : channelMarkerTypes
    ? map(entries(channelMarkerTypes), ([channelIndex, channelName]) =>
        generateChannelMetadata({
          channelIndex,
          channelName,
          slideId: id,
          labId,
        })
      )
    : [];
