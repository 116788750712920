// @ts-nocheck
import ClickAwayListener from '@mui/base/ClickAwayListener';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import DeleteIcon from '@mui/icons-material/Delete';
import GridViewIcon from '@mui/icons-material/GridView';
import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateCohort } from 'api/cohorts';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import CohortQueryList from 'components/SearchFilters/CohortQueryList';
import { SnackbarCloseButton } from 'components/Snackbars/SnackbarCloseButton';
import { Cohort } from 'interfaces/cohort';
import { Permission } from 'interfaces/permissionOption';
import { Study } from 'interfaces/study';
import { find, flatMap, keys, slice, uniq, values } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { stringify } from 'qs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { BooleanParam, encodeQueryParams, JsonParam, StringParam, useQueryParams } from 'use-query-params';
import { getFiltersFromQueryObject } from 'utils/advancedFilterAdapter';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { usePermissions } from 'utils/usePermissions';
import CardContentEditable from './CardContentEditable';
import { CardDataBox, StyledCard, TypeIconBox } from './CardStyles';
import CardTypography from './CardTypography';

type ContentEditableState = {
  previousValue: Cohort;
  updatedValue: Cohort;
};

interface Props {
  cohort: Cohort;
  onDelete: (cohortId: string) => void;
  isDeleted?: boolean;
  study?: Study;
}

const CohortCard: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  cohort,
  onDelete,
  isDeleted,
  study,
}: Props) => {
  const theme = useTheme();

  const navigate = useNavigate();
  const { labId } = useCurrentLabId();
  const { hasPermission } = usePermissions();

  const [inEditMode, setInEditMode] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [, setQueryParams] = useQueryParams({
    advancedSearchDrawerOpen: BooleanParam,
    cohortId: StringParam,
    cohortName: StringParam,
  });

  const queryClient = useQueryClient();

  const getSnackbarKey = (cohortSnapshot: Cohort) => {
    return JSON.stringify(cohortSnapshot);
  };

  const enqueueSnackbarSave = (cohortState: ContentEditableState, undoChanges: boolean) => {
    closeSnackbar(getSnackbarKey(cohortState.previousValue));

    enqueueSnackbar(undoChanges ? 'Changes discarded' : 'Changes saved', {
      key: getSnackbarKey(cohortState.updatedValue),
      variant: 'success',
      style: { textTransform: 'none' },
      action: (key) => (
        <>
          {!undoChanges && (
            <Button
              data-cy={
                'undo-button-' +
                cohortState.previousValue.id +
                cohortState.previousValue.name +
                cohortState.previousValue.description
              }
              color="secondary"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                closeSnackbar(key);
                cohortUndoMutation.mutate(cohortState.previousValue);
              }}
            >
              UNDO
            </Button>
          )}
          <SnackbarCloseButton snackbarKey={key} onClick={() => setInEditMode(false)} />
        </>
      ),
    });
  };

  const replaceUpdatedCohort = (cohorts: Cohort[], updatedCohort: Cohort) => {
    const index = cohorts.findIndex((item) => item.id === updatedCohort.id);

    return [...slice(cohorts, 0, index), updatedCohort, ...slice(cohorts, index + 1)];
  };

  const onMutate = async (updatedCohort: Cohort) => {
    await queryClient.cancelQueries(['cohorts', labId]);

    const previousCohort: Cohort = find(queryClient.getQueryData<Cohort[]>(['cohorts', labId]), {
      id: updatedCohort.id,
    });

    queryClient.setQueryData(['cohorts', labId], (old) => replaceUpdatedCohort(old, updatedCohort));

    return { previousCohort };
  };

  const onError = (err, updatedCohort, context) => {
    queryClient.setQueryData(['cohorts', labId], (old) => replaceUpdatedCohort(old, context.previousCohort));
    enqueueSnackbar('Error occurred, changes are not saved', {
      variant: 'error',
    });
  };

  const onSettled = () => {
    queryClient.invalidateQueries(['cohorts', labId]);
  };

  const onSuccess = (isUndo: boolean, updatedCohort: Cohort, context) => {
    const state: ContentEditableState = {
      previousValue: context.previousCohort,
      updatedValue: updatedCohort,
    };
    enqueueSnackbarSave(state, isUndo);
  };

  const cohortMutation = useMutation(updateCohort, {
    onMutate,
    onError,
    onSuccess: (data, updatedCohort: Cohort, context) => onSuccess(false, updatedCohort, context),
    onSettled,
  });

  const cohortUndoMutation = useMutation(updateCohort, {
    onMutate,
    onError,
    onSuccess: (data, updatedCohort: Cohort, context) => onSuccess(true, updatedCohort, context),
    onSettled,
  });

  const handleFieldSave = (fieldName: keyof Cohort, value: string) => {
    if (value !== cohort[fieldName]) {
      const newCohort = {
        ...cohort,
        [fieldName]: value,
      };
      cohortMutation.mutate(newCohort);
    }
  };

  const cohortQueryObjectForList = {
    filters: getFiltersFromQueryObject(cohort.queryObject.filters),
    features: cohort.queryObject.features,
    clinicalData: getFiltersFromQueryObject(cohort.queryObject.clinicalData),
    searchTerm: cohort.queryObject.searchTerm,
  };

  const currentStudyId = cohort.queryObject.filters['studyId'] || study?.id;

  const onCohortClick = () => {
    if (!inEditMode && !cohortMutation.isLoading) {
      navigateCohortDashboard();
    } else {
      setInEditMode(false);
    }
  };

  const commonParams = {
    labId: StringParam,
    cohortId: StringParam,
    cohortName: StringParam,
    filters: JsonParam,
  };

  const navigateCohortDashboard = () => {
    const encodedQueryWithFilters = encodeQueryParams(commonParams, {
      labId,
      cohortId: cohort.id,
      cohortName: cohort.name,
      filters: { studyId: currentStudyId, cohortIds: [cohort.id] },
    });

    navigate({
      pathname: '/procedures',
      search: `?${stringify(encodedQueryWithFilters)}`,
    });
  };

  const onEditFiltersClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    setQueryParams({
      advancedSearchDrawerOpen: true,
      cohortId: cohort.id,
    });
  };

  const onConfirmDelete = () => {
    onDelete(cohort.id);
    setIsDeleteConfirmationModalOpen(false);
  };

  const casesSlides = values(cohort?.casesSnapshot);
  const slideCount = uniq(flatMap(casesSlides, 'slides')).length;
  const canDeleteOwnCohorts = hasPermission(Permission.EditAndDeleteOwnCohorts);
  const canDeleteAllCohorts = hasPermission(Permission.EditAndDeleteAnyCohort);

  const { userId } = useAppSelector((state) => state.auth.profile);

  return (
    <>
      {isDeleteConfirmationModalOpen && (
        <ConfirmationModal
          title={`Delete cohort "${cohort.name}"?`}
          onConfirm={onConfirmDelete}
          onCancel={() => setIsDeleteConfirmationModalOpen(false)}
          text="If you confirm, this cohort will be deleted. This action cannot be undone."
        />
      )}
      <ClickAwayListener onClickAway={() => setInEditMode(false)}>
        <StyledCard
          data-test-class={`cohort-card-${cohort.id}`}
          variant="outlined"
          className={`cohort-card ${isDeleted ? 'deleted' : ''}`}
          onClick={onCohortClick}
          editMode={inEditMode}
        >
          <CardHeader
            title={
              <CardContentEditable
                disabled={cohortMutation.isLoading}
                savedValue={cohort.name}
                onSave={(newValue: string) => handleFieldSave('name', newValue)}
                onEditModeChange={setInEditMode}
              />
            }
            subheader={
              <Grid container>
                <CardTypography color="primary">Cohort</CardTypography>
                <Divider orientation="vertical" variant="middle" flexItem sx={{ mt: 0, mb: 0, mr: 1, ml: 1 }} />
                <CardTypography>{cohort.createdAt && moment(cohort.createdAt).format('ll')}</CardTypography>
              </Grid>
            }
            avatar={
              <TypeIconBox bgcolor={theme.palette.primary.light} borderRadius="50%">
                {cohort?.casesSnapshot ? <CollectionsBookmarkIcon /> : <FilterAltIcon />}
              </TypeIconBox>
            }
            titleTypographyProps={{ fontSize: '26px', fontWeight: 'bold' }}
            action={
              <CardActions>
                <Tooltip title="Cases" placement="top">
                  <Box>
                    <IconButton onClick={onCohortClick}>
                      <GridViewIcon />
                    </IconButton>
                  </Box>
                </Tooltip>

                {(canDeleteAllCohorts || (canDeleteOwnCohorts && cohort.createdBy === userId)) && (
                  <>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Tooltip title="Delete cohort" placement="top">
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setIsDeleteConfirmationModalOpen(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </CardActions>
            }
          ></CardHeader>

          <Typography
            data-cy="cohort-description"
            variant="body1"
            component={'div'}
            color="text.secondary"
            sx={{
              padding: '14px',
              maxWidth: '80%',
              marginBottom: '20px',
              fontSize: '15px',
              margin: 'auto 0',
            }}
          >
            <CardContentEditable
              disabled={cohortMutation.isLoading}
              savedValue={cohort.description}
              onSave={(newValue: string) => handleFieldSave('description', newValue)}
              onEditModeChange={setInEditMode}
            />
          </Typography>

          <CardContent
            sx={{
              padding: 2,
              mb: 2,
              display: 'flex',
              backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
              maxHeight: '120px',
              overflowY: 'scroll',
              mr: '16px',
              ml: '16px',
              border: `1px solid ${theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700]}`,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{ pb: 2 }}>
                <CohortQueryList queryObject={cohortQueryObjectForList} />
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container justifyContent="end">
              <Grid item>
                <Button variant="text" color="primary" disableElevation onClick={onEditFiltersClick}>
                  Save as cohort
                </Button>
              </Grid>
            </Grid>
          </CardContent>

          <CardActions sx={{ pr: 2, pl: 2 }}>
            <CardDataBox>
              <CardTypography>Study Source</CardTypography>
              <CardTypography color="text.primary" bold>
                {study?.externalStudyId || 'All'}
              </CardTypography>
            </CardDataBox>

            {cohort?.casesSnapshot && (
              <>
                <CardDataBox>
                  <CardTypography>Cases</CardTypography>
                  <CardTypography color="text.primary" bold>
                    {keys(cohort.casesSnapshot).length}
                  </CardTypography>
                </CardDataBox>

                <CardDataBox>
                  <CardTypography>Slides</CardTypography>
                  <CardTypography color="text.primary" bold>
                    {slideCount}
                  </CardTypography>
                </CardDataBox>
              </>
            )}
          </CardActions>
        </StyledCard>
      </ClickAwayListener>
    </>
  );
};

export default CohortCard;
