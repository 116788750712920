import { Permission } from 'interfaces/permissionOption';
import React, { FunctionComponent } from 'react';
import { usePermissions } from 'utils/usePermissions';
import AccessionDashboard from './AccessionDashboard/AccessionDashboard';
import PrivateRoute from './Auth/PrivateRoute';
import Loader from './Loader';
import StudiesDashboard from './StudiesDashboard';

interface MainPageProps {
  isAuthenticated: boolean;
}

const MainPageRoute: FunctionComponent<React.PropsWithChildren<MainPageProps>> = ({ isAuthenticated }) => {
  const { hasPermission, isLoading } = usePermissions();

  if (isLoading && isAuthenticated) {
    return <Loader />;
  }

  if (hasPermission(Permission.ViewAccessionDashboard)) {
    return (
      <PrivateRoute
        component={AccessionDashboard}
        loginRoute="/login"
        isAuthenticated={isAuthenticated}
        permissionsRequired={[Permission.ViewAccessionDashboard]}
      />
    );
  } else {
    return <PrivateRoute component={StudiesDashboard} loginRoute="/login" isAuthenticated={isAuthenticated} />;
  }
};

export default MainPageRoute;
