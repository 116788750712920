import { Box, Button, Typography } from '@mui/material';
import { NormalizationParamOverrideChannelGroup } from 'interfaces/jobs/multiplex/normalizationParams';
import { flatMap, includes, map, pullAt, slice, some } from 'lodash';
import React, { useState } from 'react';
import { defaultChannelNormalizationConfig } from '../RunMultiplexNormalization';
import { ChannelGroupsOverrideAccordion } from './ChannelGroupsOverrideForm';

interface ChannelGroupsOverridesFormProps {
  channelGroupsOverrides: NormalizationParamOverrideChannelGroup[];
  setChannelGroupsOverrides: React.Dispatch<React.SetStateAction<NormalizationParamOverrideChannelGroup[]>>;
}

export const ChannelGroupsOverridesForm: React.FC<React.PropsWithChildren<ChannelGroupsOverridesFormProps>> = ({
  channelGroupsOverrides,
  setChannelGroupsOverrides,
}) => {
  const createNewOverride = () => {
    setOpenAccordionIds(new Set(openAccordionIds.add(channelGroupsOverrides.length)));
    setChannelGroupsOverrides([
      ...channelGroupsOverrides,
      {
        name: '',
        channels: [],
        overrides: defaultChannelNormalizationConfig,
      },
    ]);
  };

  const removeOverride = (index: number) => {
    const newOverrides = [...channelGroupsOverrides];
    pullAt(newOverrides, index);
    setChannelGroupsOverrides(newOverrides);
  };

  const setChannelGroupOverride = (index: number, newOverride: NormalizationParamOverrideChannelGroup) => {
    setChannelGroupsOverrides([
      ...slice(channelGroupsOverrides, 0, index),
      newOverride,
      ...slice(channelGroupsOverrides, index + 1),
    ]);
  };

  const [openAccordionIds, setOpenAccordionIds] = useState<Set<number>>(new Set());

  const handleAccordionChange = (id: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    const newOpenAccordionIds = new Set(openAccordionIds);
    if (isExpanded) {
      newOpenAccordionIds.add(id);
    } else {
      newOpenAccordionIds.delete(id);
    }
    setOpenAccordionIds(newOpenAccordionIds);
  };

  const channelsNotUnique = some(channelGroupsOverrides, (channelGroup, index) =>
    some(
      channelGroupsOverrides,
      (otherChannelGroup, otherIndex) =>
        index !== otherIndex &&
        some(channelGroup.channels, (currentGroupChannel) => includes(otherChannelGroup.channels, currentGroupChannel))
    )
  );
  const errorMessage = channelsNotUnique
    ? 'There are at least two groups with the same channel, please ensure that each channel appears only in one group'
    : '';

  const selectedChannelsFromOtherGroups = (index: number) => {
    const otherOverrides = [...slice(channelGroupsOverrides, 0, index), ...slice(channelGroupsOverrides, index + 1)];
    return flatMap(otherOverrides, 'channels');
  };

  return (
    <Box sx={{ my: 2 }}>
      {map(channelGroupsOverrides, (override, index) => {
        return (
          <ChannelGroupsOverrideAccordion
            key={index}
            channelGroupOverride={override}
            setChannelGroupOverride={(newOverride) => setChannelGroupOverride(index, newOverride)}
            show8bitParams={true}
            show16bitParams={true}
            index={index}
            removeOverride={removeOverride}
            open={openAccordionIds?.has(index)}
            onChange={handleAccordionChange(index)}
            alreadySelectedChannels={selectedChannelsFromOtherGroups(index)}
          />
        );
      })}
      {errorMessage && (
        <Box sx={{ mt: 1 }}>
          <Typography color="error">{errorMessage}</Typography>
        </Box>
      )}
      <Button sx={{ mt: 1 }} onClick={createNewOverride} color="primary" variant="contained">
        Add New Override
      </Button>
    </Box>
  );
};
