import { Grid, Typography } from '@mui/material';
import { filter, includes, isEmpty } from 'lodash';
import React, { FunctionComponent, useMemo, useState } from 'react';

import OrchestrationIdAutoComplete from 'components/SearchFilters/AdvancedSearch/OrchestrationIdAutoComplete';
import useFeatureFilters, { featuresFilterOptions } from 'components/SearchFilters/hooks/useFeatureFilters';
import { NamedFeature } from 'interfaces/features';
import { Permission } from 'interfaces/permissionOption';
import { Study } from 'interfaces/study';
import { usePermissions } from 'utils/usePermissions';
import FeaturesDropdown from './FeaturesDropdown';

interface FeaturesSettingsProps {
  study: Study;
  highlightedFeatures: NamedFeature[];
  setHighlightedFeatures: (features: NamedFeature[]) => void;
  hiddenFeatures: NamedFeature[];
  setHiddenFeatures: (features: NamedFeature[]) => void;
}

const FeaturesSettings: FunctionComponent<React.PropsWithChildren<FeaturesSettingsProps>> = ({
  study,
  highlightedFeatures: localHighlightedFeatures,
  setHighlightedFeatures,
  hiddenFeatures: localHiddenFeatures,
  setHiddenFeatures,
}) => {
  const { hasPermission } = usePermissions();

  const canUpdateHighlightedFeatures = hasPermission(Permission.UpdateHighlightedFeatures);
  const canEditHiddenFeatures = hasPermission(Permission.EditHiddenFeatures);

  const [orchestrationId, setOrchestrationId] = useState<string>(null);
  const { orchestrationIdOptions, orchestrationsLoading, featuresData, featuresLoading } = useFeatureFilters({
    studyId: study.id,
    orchestrationId,
    onSuccessGetExperimentDefinitions: ({ orchestrations }) =>
      orchestrations && setOrchestrationId(orchestrations[0]?.orchestrationId),
    includeInferredFeatures: true,
  });

  const { featuresData: featuresWithHiddenData, featuresLoading: featuresWithHiddenLoading } = useFeatureFilters({
    studyId: study.id,
    orchestrationId,
    onSuccessGetExperimentDefinitions: ({ orchestrations }) =>
      orchestrations && setOrchestrationId(orchestrations[0]?.orchestrationId),
    includeHiddenFeatures: true,
    includeInferredFeatures: true,
  });

  const propsHighlightedFeatures = useMemo(
    () => filter(featuresData, (feature) => includes(study.highlightedFeatures, feature.key)),
    [featuresData, study.highlightedFeatures]
  );

  const propsHiddenFeatures = useMemo(
    () => filter(featuresWithHiddenData, (feature) => includes(study.hiddenFeatures, feature.key)),
    [featuresWithHiddenData, study.hiddenFeatures]
  );

  const highlightedFeatures = localHighlightedFeatures || propsHighlightedFeatures;
  const hiddenFeatures = localHiddenFeatures || propsHiddenFeatures;

  return (
    <Grid
      sx={{
        p: 2,
      }}
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant="subtitle2">Choose Highlighted/Hide Features</Typography>
      </Grid>
      <Grid item xs={8}>
        <OrchestrationIdAutoComplete
          onChange={setOrchestrationId}
          options={orchestrationIdOptions}
          disabled={!(canUpdateHighlightedFeatures || canEditHiddenFeatures)}
          loading={orchestrationsLoading}
          value={orchestrationId}
          helperText="Select an orchestration to use its features (no orchestration = approved features)"
        />
      </Grid>
      <Grid item xs={12}>
        <FeaturesDropdown
          loading={featuresLoading}
          features={featuresData}
          disabled={!canUpdateHighlightedFeatures || isEmpty(featuresData)}
          selectedFeatures={highlightedFeatures}
          setSelectedFeatures={setHighlightedFeatures}
          featuresFilterOptions={featuresFilterOptions}
          label={`Highlighted Features${isEmpty(featuresData) ? ' (No features available)' : ''}`}
          placeholder="Choose highlighted features"
        />
      </Grid>
      <Grid item xs={12}>
        <FeaturesDropdown
          loading={featuresWithHiddenLoading}
          features={featuresWithHiddenData}
          disabled={!canEditHiddenFeatures || isEmpty(featuresData)}
          selectedFeatures={hiddenFeatures}
          setSelectedFeatures={setHiddenFeatures}
          featuresFilterOptions={featuresFilterOptions}
          label={`Hide Features${isEmpty(featuresData) ? ' (No features available)' : ''}`}
          placeholder="Choose hide features"
        />
      </Grid>
    </Grid>
  );
};

export default FeaturesSettings;
