import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import CellRulePanelsContainer from '../../Pages/OperationsSettings/CellRulesDataGrid/CellRulePanelsContainer';

interface CellRuleStudySettingsProps {
  studyId: string;
}

const CellRuleStudySettings: React.FC<CellRuleStudySettingsProps> = ({ studyId }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h3">Cell Rules</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <CellRulePanelsContainer studyId={studyId} />
      </AccordionDetails>
    </Accordion>
  );
};

export default CellRuleStudySettings;
