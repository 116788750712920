import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React from 'react';
import { useDeleteTaxonomyMutation } from 'utils/queryHooks/taxonomy/useTaxonomyMutations';

interface DeleteTaxonomyButtonProps {
  path: string;
}

const DeleteTaxonomyButton: React.FC<DeleteTaxonomyButtonProps> = ({ path }) => {
  const mutation = useDeleteTaxonomyMutation(path);

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleConfirmDelete = () => {
    mutation.mutate();
    setOpenDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <IconButton aria-label="Delete" onClick={handleOpenDialog} color="error">
        <DeleteIcon />
      </IconButton>

      <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Are you sure you want to delete?</DialogTitle>
        <DialogContent>
          <DialogContentText>This action cannot be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteTaxonomyButton;
