import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { DataGridPagination } from 'components/atoms/DataGridPagination';
import { useRowSelectionContext } from 'components/atoms/RowSelectionContext';
import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useCurrentStateCases } from 'utils/useCurrentStateCases';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { useCasesViewMode } from './useCasesViewMode';

export const DEFAULT_PAGE_SIZE = 24;

export interface ProcedurePaginationProps {
  totalCases: number;
  totalSlides: number;
  dataGridPagination?: boolean;
  onPageChange?: (withOffset: boolean) => (event: React.ChangeEvent<unknown>, nextPage?: number) => void;
  onRowsPerPageChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  forcePage?: number;
  forcePageSize?: number;
}

export const ProcedurePagination: React.FC<React.PropsWithChildren<ProcedurePaginationProps>> = ({
  totalCases,
  totalSlides,
  dataGridPagination,
  onPageChange,
  onRowsPerPageChange,
  forcePage,
  forcePageSize,
}) => {
  // This section is to check if the edit snackbar is open or not
  // If its open - add padding so it wont cover the pagination
  const { casesViewMode } = useCasesViewMode();
  const [slidesMode] = useQueryParam('slidesMode', BooleanParam);
  const { totalCasesCount, totalSlidesCount } = useCurrentStateCases();
  const totalRows = slidesMode ? totalSlidesCount : totalCasesCount;
  const { someSelected } = useRowSelectionContext();
  const editSnackbarOpen = casesViewMode !== 'table' && someSelected(totalRows);

  const { queryParams, setQueryParams } = useEncodedFilters();

  const page = queryParams.page || 1;
  const pageSize = queryParams.pageSize || DEFAULT_PAGE_SIZE;
  const pageCount = queryParams?.slidesMode ? Math.ceil(totalSlides / pageSize) : Math.ceil(totalCases / pageSize);

  const handlePageChange = (withOffset: boolean) => (event: React.ChangeEvent<unknown>, nextPage?: number) => {
    if (onPageChange) {
      onPageChange(withOffset)(event, nextPage);
      return;
    }

    const nextPageWithOffset = withOffset ? nextPage + 1 : nextPage;

    if (page !== nextPageWithOffset) {
      setQueryParams({ page: nextPageWithOffset });
    }
  };

  const handleRowsPerPageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onRowsPerPageChange) {
        onRowsPerPageChange(event);
        return;
      }
      const newPageSize = parseInt(event.target.value) ?? DEFAULT_PAGE_SIZE;
      if (newPageSize !== pageSize) {
        setQueryParams({ pageSize: newPageSize });
      }
    },
    [pageSize, setQueryParams]
  );

  const currentRowsPerPage = forcePageSize ? forcePageSize : pageSize;
  const currentPage = forcePage ? forcePage - 1 : page - 1;

  return dataGridPagination ? (
    <DataGridPagination
      rowsPerPageOptions={[12, 24, 36, 60, 96]}
      count={queryParams?.slidesMode ? totalSlides : totalCases}
      rowsPerPage={currentRowsPerPage}
      page={currentPage}
      onPageChange={handlePageChange(true)}
      onRowsPerPageChange={handleRowsPerPageChange}
      pageCount={pageCount}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        '&>.MuiTablePagination-toolbar': {
          '& .MuiInputBase-root': {
            width: 'max-content',
          },
          '&>*': {
            alignItems: 'center',
            marginBottom: 0,
          },
          '& .MuiTablePagination-displayedRows': {
            minWidth: '90px',
          },
        },
      }}
      labelRowsPerPage={queryParams?.slidesMode ? 'Slides per page:' : 'Cases per page:'}
      SelectProps={{
        inputProps: {
          'aria-label': queryParams?.slidesMode ? 'Slides per page' : 'Cases per page',
        },
        native: true,
      }}
    />
  ) : (
    <Stack
      spacing={2}
      display="flex"
      alignItems="center"
      padding={3}
      sx={{ ...(editSnackbarOpen ? { paddingBottom: 7 } : {}) }}
    >
      <Pagination
        shape="rounded"
        defaultPage={queryParams.page}
        disabled={pageCount <= 1}
        count={pageCount}
        onChange={handlePageChange(false)}
        page={page}
      />
    </Stack>
  );
};
