import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import { Box, IconButton, MenuItem, Tooltip } from '@mui/material';
import { TableEditingContextProvider } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { RowSelectionContextProvider } from 'components/atoms/RowSelectionContext';
import { useProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';

import React, { FunctionComponent, useState } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import CaseManifestDialog from './CaseManifestDialog';

interface StudyManifestControlProps {
  studyId: string;
  menuItem?: boolean;
}

const StudyManifestControl: FunctionComponent<React.PropsWithChildren<StudyManifestControlProps>> = ({
  studyId,
  menuItem,
}) => {
  const [open, setOpen] = useState(false);
  const [slidesMode, setSlidesMode] = useQueryParam('slidesMode', BooleanParam);
  const previousSlidesMode = slidesMode;
  const procedureFieldContext = useProceduresFieldsContext({ isEditPreview: true });

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    setSlidesMode(true);
    e.stopPropagation();
  };

  return (
    <>
      {menuItem ? (
        <MenuItem onClick={handleClick}>
          <Box>
            <IconButton>
              <AutoFixHighIcon />
            </IconButton>
          </Box>
          Manifest
        </MenuItem>
      ) : (
        <Tooltip title="Manifest" placement="top">
          <Box>
            <IconButton onClick={handleClick}>
              <AutoFixHighIcon />
            </IconButton>
          </Box>
        </Tooltip>
      )}
      <RowSelectionContextProvider>
        <TableEditingContextProvider fieldsContext={procedureFieldContext}>
          <CaseManifestDialog
            open={open}
            onClose={(e) => {
              e?.stopPropagation();
              setOpen(false);
              setSlidesMode(previousSlidesMode);
            }}
            studyId={studyId}
          />
        </TableEditingContextProvider>
      </RowSelectionContextProvider>
    </>
  );
};

export default StudyManifestControl;
