import {
  compact,
  Dictionary,
  filter,
  find,
  findKey,
  groupBy,
  includes,
  isEmpty,
  keyBy,
  lowerCase,
  map,
  slice,
  sortBy,
  uniq,
} from 'lodash';

import { useQuery } from '@tanstack/react-query';
import { getAllAnnotationAssignments } from 'api/annotationAssignments';
import { getAllAnnotationExtensions, getAnnotationExtensionsByAssignmentIds } from 'api/annotationExtensions';
import { getTaggers } from 'api/taggers';
import {
  AnnotationAssignment,
  AnnotationExtension,
  AnnotationExtensionStates,
  generateAnnotationExtensionSlideAssignmentId,
} from 'interfaces/annotation';
import { TAGGERS } from 'interfaces/tagger';
import { useMemo } from 'react';
import { useAppSelector } from 'redux/hooks';
import { useQueryWithErrorAndRetrySnackbar } from 'utils/useQueryWithErrorAndRetrySnackbar';
import { ASSIGNMENT_STATES } from './state';

export interface AssignmentFilterResults {
  assignment?: Partial<AnnotationAssignment>;
  slides?: Partial<AnnotationExtension>[];
  doesMatchState?: boolean;
}

const UUID_REGEX = new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$', 'i');

/**
 * Get filtered assignments (assignments with slides based on the filter state)
 * based on the current filter state.
 * @param annotationAssignments - all annotation assignments
 * @param annotationFilterState - the current filter state
 * @param assignmentExtensionsById - slides grouped by assignment id
 * @returns FilteredAssignment[] - filtered assignments with slides based on the filter state
 */
const filterItemsByState = (
  annotationAssignments: AnnotationAssignment[],
  annotationFilterState: string,
  assignmentExtensionsById: Dictionary<AnnotationExtension[]>
): AssignmentFilterResults[] => {
  if (
    annotationFilterState &&
    annotationFilterState !== ASSIGNMENT_STATES[AnnotationExtensionStates.AllAssignment].route
  ) {
    const stateName = findKey(ASSIGNMENT_STATES, { route: annotationFilterState });
    const assignmentWithSlides: AssignmentFilterResults[] = map(annotationAssignments, (assignment) => ({
      assignment,
      slides: filter(assignmentExtensionsById[assignment.annotationAssignmentId], {
        workingState: stateName as AnnotationExtensionStates,
      }),
    }));
    return filter(assignmentWithSlides, ({ slides }) => !isEmpty(slides));
  } else {
    return map(annotationAssignments, (assignment) => ({
      assignment,
      slides: assignmentExtensionsById[assignment.annotationAssignmentId],
    }));
  }
};

const orphanedAssignment: AnnotationAssignment = {
  name: 'No Assignment',
  description: 'No Assignment',
  todos: null,
  annotationAssignmentId: 0,
  archived: false,
  assignmentPriority: null,
  createdAt: null,
  createdBy: null,
};

const getCurrentOrphanedAssignment = (uuid: string): AssignmentFilterResults[] => {
  return [
    {
      assignment: orphanedAssignment,
      slides: [{ slideId: uuid, annotationAssignmentId: 0, workingState: AnnotationExtensionStates.None }],
    },
  ];
};

const filterAssignmentAndSlidesByIds = ({
  assignments,
  assignmentExtensionsById,
  currentFilter,
}: {
  assignments: AnnotationAssignment[];
  assignmentExtensionsById: Dictionary<AnnotationExtension[]>;
  currentFilter: string;
}): AssignmentFilterResults[] => {
  // Begin by checking string includes for assignment ID and slide ID
  const partialIdMatches = map(assignments, (assignment) => ({
    assignment,
    slides: filter(
      assignmentExtensionsById[assignment.annotationAssignmentId],
      ({ annotationAssignmentId, slideId }: AnnotationExtension) =>
        includes(`${annotationAssignmentId}`, currentFilter) || includes(slideId, currentFilter)
    ),
  }));
  const assignmentFilteredByIds = filter(
    [
      ...partialIdMatches,
      // If the current filter is a UUID, we also want to show the orphaned assignment with the slide
      ...(UUID_REGEX.test(currentFilter) ? getCurrentOrphanedAssignment(currentFilter) : []),
    ],
    ({ slides }) => !isEmpty(slides)
  );
  return map(assignmentFilteredByIds, ({ slides, assignment }) => {
    return { slides, assignment, doesMatchState: false };
  });
};

const filterAssignmentByIdNameAndState = ({
  assignments,
  assignmentExtensionsById,
  annotationFilterState,
  currentFilter,
}: {
  assignments: AnnotationAssignment[];
  assignmentExtensionsById: Dictionary<AnnotationExtension[]>;
  annotationFilterState: string;
  currentFilter: string;
}) => {
  const filteredByName = filter(
    assignments,
    ({ name: assignmentName, annotationAssignmentId }) =>
      (assignmentName && includes(lowerCase(assignmentName), lowerCase(currentFilter))) ||
      includes(annotationAssignmentId.toString(), currentFilter)
  );
  const sortedFiltered = sortBy(filteredByName, 'annotationAssignmentId');

  return filterItemsByState(sortedFiltered, annotationFilterState, assignmentExtensionsById);
};

export const getFilteredAssignments = ({
  assignments,
  assignmentExtensionsById,
  annotationFilterState,
  currentFilter,
}: {
  assignments: AnnotationAssignment[];
  assignmentExtensionsById: Dictionary<AnnotationExtension[]>;
  annotationFilterState: string;
  currentFilter: string;
}): AssignmentFilterResults[] => {
  if (currentFilter) {
    const assignmentsFilteredByNameAndState = filterAssignmentByIdNameAndState({
      assignments,
      assignmentExtensionsById,
      annotationFilterState,
      currentFilter,
    });

    const assignmentFilteredByIds = filterAssignmentAndSlidesByIds({
      assignments,
      assignmentExtensionsById,
      currentFilter,
    });

    const assignmentsUniquelyMatchingNameAndState = filter(
      assignmentsFilteredByNameAndState,
      (fullAssignment) =>
        !find(
          assignmentFilteredByIds,
          ({ assignment }) => assignment.annotationAssignmentId === fullAssignment.assignment.annotationAssignmentId
        )
    );

    return [
      ...map(assignmentsUniquelyMatchingNameAndState, (filteredAssignment) => ({
        ...filteredAssignment,
        doesMatchState: true,
      })),
      ...map(assignmentFilteredByIds, (filteredAssignment) => ({ ...filteredAssignment, doesMatchState: false })),
    ];
  } else {
    return filterItemsByState(assignments, annotationFilterState, assignmentExtensionsById);
  }
};

export const useFilteredAssignments = ({
  annotationFilterState,
  currentFilter,
  enableQueries = true,
  firstLoadedAnnotationsNum = 20,
}: {
  annotationFilterState: string;
  currentFilter: string;
  enableQueries?: boolean;
  firstLoadedAnnotationsNum?: number;
}): {
  filteredAssignments: AssignmentFilterResults[];
  isLoadingAssignments: boolean;
  isLoadingExtensions: boolean;
} => {
  const userProfile = useAppSelector((state) => state.auth.profile);

  const { data: taggers } = useQueryWithErrorAndRetrySnackbar(['taggers'], getTaggers, {
    queriedEntityName: 'taggers',
  });
  const currentTagger = userProfile.userId ? find(taggers, { authUserId: userProfile.userId }) : null;

  const { data: assignmentRecords, isLoading: isLoadingAssignments } = useQueryWithErrorAndRetrySnackbar(
    ['annotationAssignments', 'all'],
    getAllAnnotationAssignments,
    {
      enabled: enableQueries && Boolean(currentTagger),
      queriedEntityName: 'annotation assignments',
    }
  );

  const firstLoadedAnnotationIds = useMemo(
    () => map(slice(assignmentRecords, 0, firstLoadedAnnotationsNum), 'annotationAssignmentId'),
    [assignmentRecords]
  );

  const { data: firstLoadedExtensionRecords, isLoading: isLoadingFirstLoadedExtensions } =
    useQueryWithErrorAndRetrySnackbar(
      ['annotationExtensions', firstLoadedAnnotationIds],
      ({ signal }: { signal?: AbortSignal }) =>
        getAnnotationExtensionsByAssignmentIds(firstLoadedAnnotationIds, signal),
      {
        enabled: !isLoadingAssignments && !isEmpty(assignmentRecords),
        queriedEntityName: 'annotation extensions',
      }
    );
  const { data: allAssignmentExtensionRecords, isLoading: isLoadingAllExtensions } = useQuery(
    ['annotationExtensions', 'all'],
    getAllAnnotationExtensions,
    {
      enabled: !isLoadingAssignments && !isLoadingFirstLoadedExtensions && !isEmpty(assignmentRecords),
    }
  );

  const assignmentExtensionRecords = allAssignmentExtensionRecords || firstLoadedExtensionRecords;
  const isLoadingExtensions = isLoadingAllExtensions || isLoadingFirstLoadedExtensions;

  const filteredAssignments = useMemo(() => {
    if (!currentTagger) {
      console.warn('No tagger found matching user ID');
      return getFilteredAssignments({
        assignments: [],
        assignmentExtensionsById: {},
        annotationFilterState,
        currentFilter,
      });
    }
    const assignmentsById = keyBy(assignmentRecords, 'annotationAssignmentId');
    const slidesById = keyBy(assignmentExtensionRecords, (slide) =>
      generateAnnotationExtensionSlideAssignmentId(slide.annotationAssignmentId, slide.slideId)
    );
    const slides = sortBy(
      filter(
        slidesById,
        (slide: AnnotationExtension) =>
          includes(userProfile.backOfficeRole, TAGGERS.ROLES.SUPERTAGGER) ||
          slide.taggerId === currentTagger.taggerId ||
          includes(assignmentsById[slide.annotationAssignmentId]?.allowedTaggerIds, currentTagger.taggerId)
      ),
      'annotationExtensionsId'
    );

    const assignments = sortBy(
      compact(uniq(map(slides, (slide) => assignmentsById[slide.annotationAssignmentId]))),
      (assignment: AnnotationAssignment) => -assignment.annotationAssignmentId
    );
    const assignmentExtensionsById = groupBy(slides, 'annotationAssignmentId');
    return getFilteredAssignments({
      assignments,
      assignmentExtensionsById,
      annotationFilterState,
      currentFilter,
    });
  }, [assignmentRecords, assignmentExtensionRecords, userProfile, currentTagger, annotationFilterState, currentFilter]);

  return {
    filteredAssignments,
    isLoadingAssignments,
    isLoadingExtensions,
  };
};
