export const SET_PREFERENCES = 'set_preferences';

export interface PreferencesState {
  showNavigator: boolean;
}

export interface SetPreferencesAction {
  type: typeof SET_PREFERENCES;
  payload: PreferencesState;
}

export type PreferencesActionTypes = SetPreferencesAction;
