export enum ExpressionOptions {
  Lineage = 'Lineage',
  Phenotypic = 'Phenotypic',
}

export enum LocalizationOptions {
  Membrane = 'Membrane',
  Nucleus = 'Nucleus',
}

export interface StainType {
  id: string;
  displayName: string;
  index: number;
  canBeMifMarker: boolean;
  aliases?: string[];
  localizations?: LocalizationOptions[];
  expressions?: ExpressionOptions[];
  deletedAt?: string | null;
}

export const MULTIPLEX_STAIN_ID = 'mplex';
