import { Procedure } from 'interfaces/procedure';
import moment from 'moment';
import { DisplayedField } from '../../genericFields';
import { getUpdatePathForProcedureClinicalDataField } from './helpers';

import {
  artifactsField,
  biopsyTypeField,
  encodingField,
  maxResolutionField,
  numOfChannelsField,
  objectiveMagnificationField,
  qcFailedField,
  scanDateField,
  scannerManufacturerField,
  scannerModelField,
  tsmAreaField,
} from './slideFields';

export const caseCreationDateField: DisplayedField<Procedure, string> = {
  filterType: 'date-range',
  dataCategory: 'metadata',
  dataKey: 'createdAt',
  label: 'Creation Date',
  isFullDate: true,
  views: ['day', 'month', 'year'],
  columnWidth: { width: 150 },
  valueFormatter(params, context) {
    return moment(params.value).format('l, h:mm:ss a');
  },
};

export const dateOfMetastasisField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'date',` if/when we allow clinical data editing
  filterType: 'date-range',
  dataCategory: 'clinical',
  dataKey: 'date_of_metastasis',
  label: 'Date of Metastasis',
  isFullDate: true,
  views: ['day', 'month', 'year'],
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.dateOfMetastasis,
  updatePath: getUpdatePathForProcedureClinicalDataField('dateOfMetastasis'),
};

export default [
  caseCreationDateField,
  scanDateField,
  biopsyTypeField,
  scannerModelField,
  scannerManufacturerField,
  dateOfMetastasisField,
  artifactsField,
  qcFailedField,
  tsmAreaField,
  objectiveMagnificationField,
  maxResolutionField,
  numOfChannelsField,
  encodingField,
];
