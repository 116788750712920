import EditIcon from '@mui/icons-material/Edit';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from '@mui/material';
import Loader from 'components/Loader';
import { CellRulePanel } from 'interfaces/cellRule';
import React, { useState } from 'react';
import { useUpdateCellRulePanelMutation } from 'utils/queryHooks/cellRule/useCellRuleMutations';

const EditCellPanelDialog: React.FunctionComponent<{
  studyId: string;
  panel: CellRulePanel;
}> = ({ studyId, panel }) => {
  const [open, setOpen] = useState(false);
  const [localLabel, setLabel] = useState(null);

  const label = localLabel ?? panel.label;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateMutation = useUpdateCellRulePanelMutation();

  const handleSave = () => {
    updateMutation.mutate({ panelId: panel.id, studyId, label });
    handleClose();
  };

  const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <EditIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Cell Panel</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            sx={{
              padding: 2,
            }}
          >
            <Grid item>
              <TextField label="Label" value={label} onChange={handleLabelChange} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} variant="contained" color="primary" disabled={!localLabel}>
            Save {updateMutation.isLoading && <Loader size="small" />}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditCellPanelDialog;
