import { Signal, signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { Dictionary, entries, first, isEmpty, isEqual, keys, reduce, times, values } from 'lodash';
import { useEffect, useTransition } from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';

import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { defaultLayerColors } from 'components/theme/theme';
import { SlideChannel } from 'interfaces/slide';

export interface SlideChannelColorSettings {
  color?: any;
  gamma?: number;
  opacity?: number;
}

// { [slideId]: { [channelId]: Signal<SlideChannelColorSettings> } }
export const slidesChannelColorSettings: Array<Signal<Dictionary<Dictionary<Signal<SlideChannelColorSettings>>>>> =
  times(MAX_VIEWERS, () => signal({}));

export const getSlideChannelColorSettingsSignal = (viewerIndex: number, slideId: string, channelId: string) => {
  const viewerSlidesChannelsSettings = slidesChannelColorSettings[viewerIndex];
  if (!viewerSlidesChannelsSettings) {
    console.warn(`Invalid viewerIndex: ${viewerIndex}`);
    return;
  }

  return viewerSlidesChannelsSettings.value?.[slideId]?.[channelId];
};

export const useUpdateSlideChannelColorSettings = () => {
  useSignals();

  const [, startTransition] = useTransition();

  const [, setMarkerChannelColorSettings] = useQueryParam<{
    [markerType: string]: { [viewerIndex: number]: SlideChannelColorSettings };
  }>('markerChannelColorSettings', JsonParam);

  const updateSlideChannelColorSettings = (
    viewerIndex: number,
    slideId: string,
    channelId: string,
    settings: Partial<SlideChannelColorSettings>,
    markerType?: string
  ) => {
    const slideChannelsSettingsSignal = getSlideChannelColorSettingsSignal(viewerIndex, slideId, channelId);

    if (slideChannelsSettingsSignal) {
      const previousSettings = slideChannelsSettingsSignal?.value;
      const newSettings = { ...previousSettings, ...settings };

      if (!isEqual(previousSettings, newSettings)) {
        slideChannelsSettingsSignal.value = newSettings;

        if (markerType) {
          // Update marker type settings in URL
          startTransition(() => {
            setMarkerChannelColorSettings(
              (prev) => ({
                ...prev,
                [markerType]: {
                  ...prev?.[markerType],
                  [viewerIndex]: newSettings,
                },
              }),
              'replaceIn'
            );
          });
        }
      }
    }
  };

  const handleOpacityChange = (
    viewerIndex: number,
    slideId: string,
    channelId: string,
    opacity: number,
    markerType?: string
  ) => {
    updateSlideChannelColorSettings(viewerIndex, slideId, channelId, { opacity }, markerType);
  };

  const handleColorChange = (
    viewerIndex: number,
    slideId: string,
    channelId: string,
    color: any,
    markerType?: string
  ) => {
    updateSlideChannelColorSettings(viewerIndex, slideId, channelId, { color }, markerType);
  };

  const handleGammaChange = (
    viewerIndex: number,
    slideId: string,
    channelId: string,
    gamma: number,
    markerType?: string
  ) => {
    updateSlideChannelColorSettings(viewerIndex, slideId, channelId, { gamma }, markerType);
  };

  const handleShowChange = (viewerIndex: number, slideId: string, channelId: string, markerType?: string) => {
    const viewerSlidesChannelsSettings = getSlideChannelColorSettingsSignal(viewerIndex, slideId, channelId);
    handleOpacityChange(
      viewerIndex,
      slideId,
      channelId,
      viewerSlidesChannelsSettings?.value.opacity === 0 ? 100 : 0,
      markerType
    );
  };

  return { handleOpacityChange, handleColorChange, handleGammaChange, handleShowChange };
};

// { [slideId]: { [channelId]: boolean } }
export const slidesChannelToggles = times(MAX_VIEWERS, () => signal<Dictionary<Dictionary<boolean>>>({}));

export const useSelectChannel = () => {
  useSignals();

  const [, startTransition] = useTransition();

  const [, setMarkerChannelToggle] = useQueryParam<{
    [markerType: string]: { [viewerIndex: number]: boolean };
  }>('markerChannelToggle', JsonParam);

  const handleSelectChannel = (
    viewerIndex: number,
    slideId: string,
    channelId: string,
    show: boolean,
    markerType?: string
  ) => {
    const viewerSlidesChannelsToggles = slidesChannelToggles[viewerIndex];
    if (!viewerSlidesChannelsToggles) {
      console.warn(`Invalid viewerIndex: ${viewerIndex}`);
      return;
    }

    viewerSlidesChannelsToggles.value = {
      ...viewerSlidesChannelsToggles.value,
      [slideId]: { ...viewerSlidesChannelsToggles.value[slideId], [channelId]: show },
    };

    if (markerType) {
      // Update marker type settings in URL
      startTransition(() => {
        setMarkerChannelToggle(
          (prev) => ({
            ...prev,
            [markerType]: {
              ...prev?.[markerType],
              [viewerIndex]: show,
            },
          }),
          'replaceIn'
        );
      });
    }
  };

  return { handleSelectChannel };
};

export const computeDefaultSlideChannelColorSettings = (
  channelIndex: number,
  channel: SlideChannel
): SlideChannelColorSettings & { id: number } => {
  const color =
    channel.color && CSS.supports('color', channel.color)
      ? channel.color
      : defaultLayerColors[Number(channelIndex ?? 0) % defaultLayerColors.length];

  return {
    id: channelIndex,
    color,
    gamma: 1,
    opacity: 100,
  };
};

// Add slide channel settings for new slides
export const useSlideChannelViewSettings = (slide: SlideWithChannelAndResults) => {
  useSignals();
  const slideId = slide?.id;
  const viewerIndex = slide.viewerIndex;

  const slideChannels = slide?.channels;

  const viewerSlidesChannelsSettings = slidesChannelColorSettings[viewerIndex];
  const viewerSlideChannelToggles = slidesChannelToggles[viewerIndex];
  if (!viewerSlidesChannelsSettings || !viewerSlideChannelToggles) {
    console.warn(`Invalid viewerIndex: ${viewerIndex}`);
  }

  const [markerChannelColorSettings] = useQueryParam<{
    [markerType: string]: { [viewerIndex: number]: SlideChannelColorSettings };
  }>('markerChannelColorSettings', JsonParam);

  const [markerChannelToggle] = useQueryParam<{
    [markerType: string]: { [viewerIndex: number]: boolean };
  }>('markerChannelToggle', JsonParam);

  useEffect(() => {
    if (viewerSlidesChannelsSettings && slideId && !isEmpty(slideChannels)) {
      const previousViewerSlidesChannelsSettings = viewerSlidesChannelsSettings.value;
      const previousSlideChannelSettings = previousViewerSlidesChannelsSettings[slideId] || {};

      const newSlideChannelSettings: Dictionary<Signal<SlideChannelColorSettings>> = reduce(
        entries(slide?.channels),
        (acc, [channelIndex, channel]) => {
          const markerType = slide?.channelMarkerTypes?.[channelIndex];
          const colorSettingsFromUrl = markerType
            ? markerChannelColorSettings?.[markerType]?.[viewerIndex] ||
              first(values(markerChannelColorSettings?.[markerType]))
            : undefined;

          const newColorSettings = {
            ...computeDefaultSlideChannelColorSettings(Number(channelIndex), channel),
            ...(colorSettingsFromUrl || {}),
          };
          if (acc?.[channelIndex] === undefined) {
            // Creating default channel settings for slide
            acc[channelIndex] = signal(newColorSettings);
          } else {
            // Updating existing channel settings
            const previousChannelSettings = acc[channelIndex].value;
            // Don't override existing settings - they are already separated per slide
            acc[channelIndex].value = { ...newColorSettings, ...previousChannelSettings };
          }
          return acc;
        },
        previousSlideChannelSettings
      );

      viewerSlidesChannelsSettings.value = {
        ...previousViewerSlidesChannelsSettings,
        [slideId]: newSlideChannelSettings,
      };
    }
  }, [viewerSlidesChannelsSettings, slideId, slideChannels, markerChannelColorSettings]);

  useEffect(() => {
    if (viewerSlideChannelToggles && slideId && !isEmpty(slideChannels)) {
      const previousViewerSlideChannelToggles = viewerSlideChannelToggles.value;
      viewerSlideChannelToggles.value = {
        ...previousViewerSlideChannelToggles,
        [slideId]: {
          ...(previousViewerSlideChannelToggles?.[slideId] || {}),
          ...reduce(
            keys(slide?.channels),
            (acc, channelIndex) => {
              const markerType = slide?.channelMarkerTypes?.[channelIndex];
              const showFromUrl = markerType ? markerChannelToggle?.[markerType]?.[viewerIndex] : undefined;

              acc[channelIndex] = showFromUrl ?? previousViewerSlideChannelToggles?.[slideId]?.[channelIndex];
              return acc;
            },
            {} as Dictionary<boolean>
          ),
          '0': true,
        },
      };
    }
  }, [viewerSlideChannelToggles, slideId, slideChannels, markerChannelToggle]);
};
