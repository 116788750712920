import React from 'react';

import { RowsChangesSummary } from 'components/atoms/EditableDataGrid/TableEditingContext/ChangesSummary';
import { Role } from 'interfaces/roles';
import { rolesFields } from 'interfaces/roles/rolesFields';
import { RolesFieldsContext } from 'interfaces/roles/rolesFieldsContext';
import { find } from 'lodash';

export const RoleRowChangesSummary: React.FC<
  React.PropsWithChildren<{
    roleDisplayString?: string;
    changes: Partial<Role>;
    // Passed to component because it is rendered in the ConfirmationContext
    fieldsContext: RolesFieldsContext;
  }>
> = ({ changes, roleDisplayString, fieldsContext }) => {
  return (
    <RowsChangesSummary
      rowsChanges={{ changes }}
      rowToDisplayString={() =>
        roleDisplayString || `Role "${changes?.name}" ${changes?.labSpecific ? ' (lab-specific)' : ''}`
      }
      fieldToDisplayString={(fieldId) => find(rolesFields, (f) => f.dataKey === fieldId)?.label || fieldId}
      valueToDisplayString={(value, fieldId) => {
        const field = find(rolesFields, (f) => f.dataKey === fieldId);
        return field?.valueFormatter?.({ value }, fieldsContext) ?? `${value}`;
      }}
    />
  );
};
