import { useQuery } from '@tanstack/react-query';
import { getAnnotationAssignments } from 'api/annotationAssignments';
import { NON_SPECIFIC_STUDY_VALUES } from 'components/SearchFilters/hooks/useMainFilters';
import { Permission } from 'interfaces/permissionOption';
import { includes } from 'lodash';
import { stringify } from 'qs';
import { useCurrentLabId } from './useCurrentLab';
import { usePermissions } from './usePermissions';

export const useAnnotationAssignments = (studyId: string) => {
  const { hasPermission } = usePermissions();
  const { labId } = useCurrentLabId();
  const isSpecificStudySelected = studyId && !includes(NON_SPECIFIC_STUDY_VALUES, studyId.toString());
  const canViewAnnotationAssignments = hasPermission(Permission.ViewAnnotationsList) && isSpecificStudySelected;

  const encodedAnnotationAssignmentsParams = stringify({ filters: JSON.stringify({ studyId }), labId });
  const queryKey = ['annotationAssignments', encodedAnnotationAssignmentsParams];

  return {
    ...useQuery({
      queryKey: queryKey,
      queryFn: () => getAnnotationAssignments(encodedAnnotationAssignmentsParams),
      enabled: canViewAnnotationAssignments,
    }),
    queryKey,
  };
};
