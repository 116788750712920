import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import { IconButton } from '@mui/material';
import { includes } from 'lodash';
import React, { useCallback } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

export interface AssignmentListToggleProps {
  marginTop?: number;
}

export const AssignmentListToggle = () => {
  const [annotationsListVisibility, setAnnotationsDrawerVisibility] = useQueryParam(
    'annotationsListVisibility',
    StringParam
  );
  const annotationDrawerVisible = includes(['open', 'pinned'], annotationsListVisibility);

  const toggleAnnotationDrawerVisible = useCallback(() => {
    setAnnotationsDrawerVisibility(annotationDrawerVisible ? '' : 'open');
  }, [annotationDrawerVisible, setAnnotationsDrawerVisibility]);

  return (
    <IconButton
      onClick={toggleAnnotationDrawerVisible}
      color={annotationDrawerVisible ? 'primary' : 'inherit'}
      title="Select Annotation Assignment"
    >
      <HighlightAltIcon />
    </IconButton>
  );
};
