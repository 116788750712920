import { handleActions } from 'redux-actions';

import { ErrorState, SET_SYSTEM_ERROR, ErrorActionTypes } from 'redux/actionTypes/error';

import * as actionTypes from 'redux/actionTypes/index';

const initialState: ErrorState = {
  message: null,
  code: null,
};

const errorReducer = handleActions(
  {
    [SET_SYSTEM_ERROR]: (state: ErrorState, action: ErrorActionTypes) => ({
      message: action.payload.message,
      code: action.payload.code,
    }),
    [actionTypes.API_REQUEST]: () => ({
      message: null,
      code: null,
    }),
  },
  initialState
);

export default errorReducer;
