import { Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';

interface SearchTipsProps {}

const SearchTips: FunctionComponent<React.PropsWithChildren<SearchTipsProps>> = () => {
  return (
    <>
      <Typography fontWeight={'bold'}>Search by:</Typography>
      <Typography variant="body1">
        Case name, case ID, study name, slide ID, slide file name. Use commas/new line/tab to separate multiple search
        terms.
      </Typography>
    </>
  );
};

export default SearchTips;
