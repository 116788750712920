import { Grid, Typography } from '@mui/material';
import { useSlideLabels } from 'components/StudiesDashboard/StudySettings/StudyLabel/useSlideLabels';
import SlideCarouselItem from 'components/StudyDashboard/ProceduresPage/ProcedureCard/Carousel/SlideCarouselItem';
import { CohortWithSelectedFeatures } from 'interfaces/cohort_old';
import { Procedure } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import { filter, flatMap, includes, map } from 'lodash';
import React from 'react';
import { getSlideLabelsText } from 'utils/qcLabels';
import FlaggedSlidesList from './FlaggedSlidesList';

interface Props {
  cohort: CohortWithSelectedFeatures;
  label: string;
}

const slideThumbnailBorderRadius = '4px';

const CohortSlideLabelSummary: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ cohort, label }) => {
  const relevantSlideCasePairs: {
    slide: Slide;
    case: Procedure;
  }[] = flatMap(cohort.procedures, (procedure) =>
    map(
      filter(procedure.slides, (slide) => includes(getSlideLabelsText(slide), label)),
      (slide) => ({
        slide,
        case: procedure,
      })
    )
  );

  const relevantSlides = map(relevantSlideCasePairs, 'slide');
  const [openFlaggedList, setOpenFlaggedList] = React.useState(false);

  const numberOfRelevantSlides = relevantSlides?.length || 0;
  const galleryCardSize = 70;
  const { getLabelDisplayName } = useSlideLabels();

  const labelDisplayName = getLabelDisplayName(label);

  if (numberOfRelevantSlides === 0) return null;

  return (
    <Grid
      item
      container
      direction="row"
      spacing={1}
      sx={{
        flexWrap: 'nowrap',
        '& .carousel-item': {
          borderRadius: slideThumbnailBorderRadius,
          width: galleryCardSize,
          height: galleryCardSize,
        },
        '&:hover':
          numberOfRelevantSlides > 1
            ? {
                cursor: 'pointer',
              }
            : {},
      }}
      onClick={() => setOpenFlaggedList(true)}
    >
      <Grid item width={galleryCardSize + 10}>
        <SlideCarouselItem slide={relevantSlides[0]} galleryView mode="minimal">
          {numberOfRelevantSlides > 1 && (
            <Grid
              sx={{
                borderRadius: slideThumbnailBorderRadius,
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  color: 'white',
                  display: 'flex',
                }}
              >
                {`+${numberOfRelevantSlides - 1}`}
              </Typography>
            </Grid>
          )}
        </SlideCarouselItem>
      </Grid>

      <Grid item container direction="column" justifyContent="center">
        <Grid item>
          <Typography variant="h4">{labelDisplayName}</Typography>
        </Grid>
        <Grid item>
          <FlaggedSlidesList
            open={openFlaggedList}
            setOpen={setOpenFlaggedList}
            label={labelDisplayName}
            slideCasePairs={relevantSlideCasePairs}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CohortSlideLabelSummary;
