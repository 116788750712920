import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RoundedButton = styled(Button)({
  borderRadius: '100px',
  textTransform: 'none',
});

export const MainButton = styled(RoundedButton)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '14px',
  height: '40px',
  whiteSpace: 'nowrap',
}));
