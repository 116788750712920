import { SET_DATE, SET_TEXT } from '../actionTypes/studyFilters';

export const setDate = (startDate: Date | null, endDate: Date | null) => ({
  type: SET_DATE,
  payload: {
    startDate,
    endDate,
  },
});

export const setText = (text: string) => ({
  type: SET_TEXT,
  payload: {
    text,
  },
});
