import * as AT from 'redux/actionTypes';

export const tryAuthenticateFromLocalStorage = (profile) => ({
  type: AT.TRY_AUTHENTICATE_FROM_LOCAL_STORAGE,
  payload: profile,
});

export const finishedTryToAuthenticateFromLocalStorage = () => ({
  type: AT.FINISHED_TRY_AUTHENTICATE_FROM_LOCAL_STORAGE,
  payload: {},
});

export const loginSuccess = (profile, scope, accessToken, expiresAt) => ({
  type: AT.LOGIN_SUCCESS,
  payload: {
    profile,
    scope,
    accessToken,
    expiresAt,
  },
});

export const logout = () => ({
  type: AT.LOGOUT,
  payload: {},
});

export const renewToken = (accessToken, scope) => ({
  type: AT.RENEW_TOKEN,
  payload: { accessToken, scope },
});
