import { Autocomplete, CircularProgress, Grid, TextField, TextFieldProps } from '@mui/material';
import { CellRulePanel, CellRuleStudyId, globalStudyId } from 'interfaces/cellRule';
import { find, isEmpty } from 'lodash';
import React from 'react';
import AddCellPanelDialog from './AddCellPanelDialog';
import EditCellPanelDialog from './EditCellPanelDialog';

interface CellRulePanelDropdownProps {
  panels: CellRulePanel[];
  selectedPanelId: string;
  onSelectPanel: (panelId: string) => void;
  isLoading: boolean;
  studyId: CellRuleStudyId;
  editable?: boolean;
  textFieldProps?: TextFieldProps;
}

const emptyPanel: Partial<CellRulePanel> = { id: '', label: '' };

const CellRulePanelDropdown: React.FC<CellRulePanelDropdownProps> = ({
  panels,
  selectedPanelId,
  onSelectPanel,
  isLoading,
  studyId,
  editable = false,
  textFieldProps,
}) => {
  const currentPanel = find(panels, (panel) => panel.id === selectedPanelId);

  const shouldDisplayAdd = editable && (studyId !== globalStudyId || isEmpty(panels.length)) && !isLoading;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs>
        <Autocomplete
          options={panels}
          getOptionLabel={(panel) => panel.label ?? 'Unlabelled'}
          value={currentPanel ?? emptyPanel}
          onChange={(event, newValue) => {
            const selectedValue = newValue?.id ?? emptyPanel.id;
            onSelectPanel(selectedValue);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          noOptionsText="No panels available"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Panel"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              {...textFieldProps}
            />
          )}
        />
      </Grid>

      {shouldDisplayAdd && (
        <Grid item>
          <AddCellPanelDialog studyId={studyId} />
        </Grid>
      )}
      {currentPanel && editable && (
        <Grid item>
          <EditCellPanelDialog panel={currentPanel} studyId={studyId} />
        </Grid>
      )}
    </Grid>
  );
};

export default CellRulePanelDropdown;
