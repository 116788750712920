import { styled, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import { isEmpty } from 'lodash';
import React, { ReactElement } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import { humanize } from 'utils/helpers';
import CLIInstructions from './CLIInstructions';
import regions from './regions';
import { IFormValues } from './UploadSlidesForm';
import { UploadBox } from './UploadSlidesFormUI';

const UseExistingStorageForm = (props: Props): ReactElement => {
  const { formProps } = props;
  const regionsOptions = [{ value: null, text: 'Choose from list', disabled: true }, ...regions];

  const {
    register,
    control,
    formState: { errors },
  } = formProps;

  return (
    <>
      <BorderedUploadBox data-testid="existing-storage-container">
        <Grid container spacing={2} direction="column">
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="slideDirectory"
                render={({ field: { onChange } }) => (
                  <TextField
                    label="Slide Directory"
                    inputRef={register('slideDirectory')}
                    onChange={onChange}
                    placeholder="Paste a link to slide directory"
                    helperText={humanize(errors?.['slideDirectory']?.message)}
                    error={!isEmpty(errors?.['slideDirectory']?.message)}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="region"
                render={({ field: { onChange, value } }) => (
                  <LabelledDropdown
                    label="Region"
                    options={regionsOptions}
                    value={value}
                    onOptionSelected={(optionValue) => {
                      onChange(optionValue);
                    }}
                    placeholder="Choose from list"
                    inputRef={register('region')}
                    helperText={humanize(errors?.['region']?.message)}
                    error={!isEmpty(errors?.['region']?.message)}
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="accessKeyId"
                render={({ field: { onChange } }) => (
                  <TextField
                    label="Access Key"
                    inputRef={register('accessKeyId')}
                    onChange={onChange}
                    placeholder="Type Here"
                    helperText={humanize(errors?.['accessKeyId']?.message)}
                    error={!isEmpty(errors?.['accessKeyId']?.message)}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="secretAccessKey"
                render={({ field: { onChange } }) => (
                  <TextField
                    label="Secret Key"
                    inputRef={register('secretAccessKey')}
                    onChange={onChange}
                    placeholder="Type Here"
                    helperText={humanize(errors?.['secretAccessKey']?.message)}
                    error={!isEmpty(errors?.['secretAccessKey']?.message)}
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <CLIInstructions />
      </BorderedUploadBox>
    </>
  );
};

const BorderedUploadBox = styled(UploadBox)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

interface Props {
  formProps: UseFormReturn<IFormValues, any> | any;
}

export default UseExistingStorageForm;
