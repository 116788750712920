import { Slide } from 'interfaces/slide';
import { MULTIPLEX_STAIN_ID } from 'interfaces/stainType';
import React, { FunctionComponent } from 'react';
import { getSlideThumbnailUrl } from 'utils/helpers';
import ImageWithFallback from '../../ImageWithFallback';

interface Props {
  slide: Slide;
}

const SlideThumbnailImage: FunctionComponent<Props> = ({ slide }) => {
  const thumbnailUrl = getSlideThumbnailUrl(slide);
  const isMultiplex = slide?.stainingType === MULTIPLEX_STAIN_ID;
  return (
    <ImageWithFallback
      imageUrls={[thumbnailUrl]}
      isMultiplex={isMultiplex}
      slideEncoding={slide?.encoding || 'uint8'}
    />
  );
};

export default SlideThumbnailImage;
