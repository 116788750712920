import { compact, every, intersection, isEmpty, join, keys, map } from 'lodash';

import { procedureFieldCategoryToTitle } from 'interfaces/procedure/fields';
import {
  dateOfDiagnosisField,
  dateOfLastFollowupField,
  ecogScoreField,
  tnmStageMField,
  tnmStageNField,
  tnmStageTField,
  treatmentEndDateField,
  treatmentLineField,
  treatmentStartDateField,
  treatmentTypeField,
} from 'interfaces/procedure/fields/clinicalFields';
import { caseCreationDateField, dateOfMetastasisField } from 'interfaces/procedure/fields/metadataFields';
import {
  dateOfDeathField,
  dateOfObjectiveResponseField,
  earlyDiscontinuationDateField,
  overallSurvivalField,
  pfsField,
  recistObjectiveResponseField,
  vitalStatusField,
} from 'interfaces/procedure/fields/outcomeFields';
import {
  artifactsField,
  biopsySiteField,
  biopsyTypeField,
  negativeControlField,
  objectiveMagnificationField,
  positiveControlField,
  scannerManufacturerField,
  scannerModelField,
  tsmAreaField,
} from 'interfaces/procedure/fields/slideFields';

import { FieldConfigItem } from 'interfaces/genericFields';
import { biopsyDateField } from 'interfaces/procedure/fields/caseFields';
import {
  bmiField,
  ethnicityField,
  raceField,
  sexField,
  smokingHistoryField,
  smokingStatusField,
  yearOfBirthField,
} from 'interfaces/procedure/fields/patientFields';

const requiredFieldsForCompositeKeyFilter = ['dataType', 'cellTypeSource', 'areaType', 'queryRange'];

const orderedKeysForCompositeKeyFilter = [
  'dataType',
  'cellTypeSource',
  'ihcSource',
  'cellTypeTarget',
  'ihcTarget',
  'areaType',
];

const orderedKeysForCompositeKeyFilterWithTypeLast = [
  'cellTypeSource',
  'ihcSource',
  'cellTypeTarget',
  'ihcTarget',
  'areaType',
  'dataType',
];

export const getCompositeFilterIfValid = (compositeFilter: any): any | null => {
  const { start, end } = compositeFilter?.queryRange || {};
  const noRange = every([start, end], isEmpty);

  if (
    intersection(keys(compositeFilter), requiredFieldsForCompositeKeyFilter).length ===
    requiredFieldsForCompositeKeyFilter.length
  ) {
    let inferredKey = '';
    if (
      compositeFilter['dataType'] === 'fraction' ||
      compositeFilter['dataType'] === 'count' ||
      compositeFilter['dataType'] === 'density' ||
      compositeFilter['dataType'] === 'area'
    ) {
      inferredKey = join(
        compact(map(orderedKeysForCompositeKeyFilterWithTypeLast, (key) => compositeFilter[key])),
        '_'
      );
    } else {
      inferredKey = join(compact(map(orderedKeysForCompositeKeyFilter, (key) => compositeFilter[key])), '_');
    }

    if (!noRange) return { [inferredKey]: { start, end } };
  }
  return null;
};

const filterFieldsConfig: FieldConfigItem[] = [
  {
    title: procedureFieldCategoryToTitle.metadata,
    fieldGroups: [
      {
        fields: [caseCreationDateField],
      },
      {
        fields: [biopsySiteField],
      },
      {
        fields: [biopsyTypeField],
      },
      {
        fields: [scannerManufacturerField, scannerModelField],
      },
      {
        fields: [artifactsField],
      },
      {
        fields: [dateOfMetastasisField],
      },
      {
        fields: [tsmAreaField],
      },
      {
        fields: [negativeControlField],
      },

      {
        fields: [positiveControlField],
      },
      {
        fields: [objectiveMagnificationField],
      },
    ],
  },
  {
    title: procedureFieldCategoryToTitle.patient,
    fieldGroups: [
      {
        fields: [sexField, yearOfBirthField],
      },
      {
        fields: [ethnicityField, raceField],
      },
      {
        fields: [bmiField],
      },
      {
        fields: [smokingStatusField, smokingHistoryField],
      },
    ],
  },
  {
    title: procedureFieldCategoryToTitle.clinical,
    fieldGroups: [
      {
        fields: [ecogScoreField, tnmStageTField, tnmStageMField, tnmStageNField],
      },
      {
        fields: [biopsyDateField, dateOfDiagnosisField],
      },
      {
        fields: [treatmentTypeField, treatmentLineField],
      },
      {
        fields: [treatmentStartDateField, treatmentEndDateField, dateOfLastFollowupField],
      },
    ],
  },
  {
    title: procedureFieldCategoryToTitle.outcome,
    fieldGroups: [
      {
        fields: [dateOfObjectiveResponseField, recistObjectiveResponseField, earlyDiscontinuationDateField, pfsField],
      },
      {
        fields: [pfsField, overallSurvivalField],
      },
      {
        fields: [vitalStatusField, dateOfDeathField],
      },
    ],
  },
];

export default filterFieldsConfig;
