import { forEach, isEmpty, map, uniq } from 'lodash';
import { MeasureDistanceMode } from 'nebula.gl';

export type Position = [number, number] | [number, number, number];

export interface MeasureDistanceModeConfig {
  units?: string;
  formatTooltip?: (distance: number) => string;
  measurementCallback?: (distance: number) => void;
  centerTooltipsOnLine?: boolean;
}

export class MeasureOrthographicDistanceMode extends MeasureDistanceMode {
  previousClickSequences: Position[][] = [];

  /**
   * Wrapper around the super.getGuides method to render the previous click sequences in addition to the current one
   * @param props
   * @returns Features to render
   */
  getGuides(props: Parameters<MeasureDistanceMode['getGuides']>[0]): ReturnType<MeasureDistanceMode['getGuides']> {
    const guides: ReturnType<MeasureDistanceMode['getGuides']> = super.getGuides(props);

    // Render the previous click sequences, replicated from the super.getGuides method
    forEach(this.previousClickSequences, (clickSequence) => {
      if (!isEmpty(clickSequence)) {
        guides.features.push({
          type: 'Feature',
          properties: {
            guideType: 'tentative',
          },
          geometry: {
            type: 'LineString',
            coordinates: clickSequence,
          },
        });
      }

      const editHandles = map(
        clickSequence,
        (clickedCoord, index): ReturnType<MeasureDistanceMode['getGuides']>['features'][0] => ({
          type: 'Feature',
          properties: {
            guideType: 'editHandle',
            editHandleType: 'existing',
            featureIndex: -1,
            positionIndexes: [index],
          },
          geometry: {
            type: 'Point',
            coordinates: clickedCoord,
          },
        })
      );

      guides.features.push(...editHandles);
    });

    return guides;
  }

  /**
   * Wrapper around the super.handleClick method to
   * 1. record the previous click sequence
   * 2. persist tooltips across measuring sessions
   * 3. limit the number of click sequences to 1 per measuring session if aggregateDistances is not enabled
   * @param event
   * @param props
   */
  handleClick(
    event: Parameters<MeasureDistanceMode['handleClick']>[0],
    props: Parameters<MeasureDistanceMode['handleClick']>[1]
  ) {
    if (this._isMeasuringSessionFinished) {
      // If the measuring session is finished and includes more than one click, document it before starting a new sequence
      const previousClickSequence = this.getClickSequence();
      if ((previousClickSequence?.length ?? 0) > 1) {
        this.previousClickSequences.push(previousClickSequence);
      }
    }

    // Copy the current tooltips before calling super.handleClick to persist them across measuring sessions
    const currentTooltips = [...this._currentTooltips];
    super.handleClick(event, props);
    const { modeConfig } = props;
    const { aggregateDistances = false } = modeConfig || {};

    // Limit the number of click sequences to 1 if aggregateDistances is not enabled
    if (!this._isMeasuringSessionFinished && !aggregateDistances && (this.getClickSequence()?.length ?? 0) > 1) {
      this._isMeasuringSessionFinished = true;
    }

    // Restore the current tooltips after calling super.handleClick
    this._currentTooltips = uniq([...currentTooltips, ...this._currentTooltips]);
  }

  /**
   * Wrapper around the super._calculateDistanceForTooltip method to replace the distance calculation logic
   * with a simple Euclidean distance calculation, for orthographic views
   * @param params - Parameters to calculate the distance
   * @returns [number] Distance
   */
  _calculateDistanceForTooltip = ({
    positionA,
    positionB,
    modeConfig,
  }: {
    positionA: Position;
    positionB: Position;
    modeConfig: MeasureDistanceModeConfig;
  }) => {
    const { measurementCallback } = modeConfig || {};
    const distance = Math.sqrt((positionA[0] - positionB[0]) ** 2 + (positionA[1] - positionB[1]) ** 2);

    if (measurementCallback) {
      measurementCallback(distance);
    }

    return distance;
  };

  _formatTooltip(distance: number, modeConfig?: MeasureDistanceModeConfig) {
    const { formatTooltip, units = 'meters' } = modeConfig || {};

    let text;
    if (formatTooltip) {
      text = formatTooltip(distance);
    } else {
      // By default, round to 2 decimal places and append units
      text = `${Number(distance).toFixed(2)} ${units}`;
    }

    return text;
  }
}
