import WarningIcon from '@mui/icons-material/Warning';
import { ReactComponent as SlideNum } from './slideNum.svg';

// eslint-disable-next-line import/order
import { Grid, Tooltip } from '@mui/material';
import React from 'react';
import './ItemsCountBadge.scss';

interface Props {
  slideCount: number;
  caseHasQcFailedSlide?: boolean;
  showWarning?: boolean;
}

const ItemCountBadge: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  slideCount,
  caseHasQcFailedSlide,
  showWarning,
}) => {
  return (
    <Grid
      container
      sx={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}
      direction="row"
      className="slide-count"
    >
      <Grid item className="count-icon">
        <SlideNum />
      </Grid>
      <Grid item mx={0.5}>
        {slideCount}
      </Grid>
      {showWarning && caseHasQcFailedSlide && (
        <>
          <Grid item mx={1}>
            |
          </Grid>
          <Grid item alignItems="center" display="flex">
            <Tooltip title="This case has one slide or more that were excluded in quality control">
              <WarningIcon sx={{ color: '#f0ba44' }} fontSize="small" />
            </Tooltip>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ItemCountBadge;
