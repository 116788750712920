import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { some } from 'lodash';
import { useEffect } from 'react';

export const getHeatmapUrlKeyFromFeatureMetadata = ({
  heatmap,
  parentHeatmap,
  addOrchestrationId, // Required for internal heatmaps
}: {
  heatmap: FeatureMetadata;
  parentHeatmap?: FeatureMetadata;
  addOrchestrationId?: boolean;
}) => {
  if (!heatmap) {
    return '';
  }
  const parentHeatmapUrlKey = parentHeatmap?.key || parentHeatmap?.id;
  const heatmapBaseUrlKey = heatmap.key || heatmap.id;
  const keyWithoutOrchId =
    parentHeatmapUrlKey && heatmap?.id !== parentHeatmap?.id
      ? `${parentHeatmapUrlKey}-${heatmapBaseUrlKey}`
      : heatmapBaseUrlKey;
  // Multiple internal heatmaps can have the same layer key, so we need to use the orchestration id to differentiate them
  return addOrchestrationId
    ? `${parentHeatmap?.orchestrationId || heatmap.orchestrationId}-${keyWithoutOrchId}`
    : keyWithoutOrchId;
};

export const useInitialExpandIfHeatmapSelected = ({
  expandAccordion,
  setExpandAccordion,
  heatmaps,
  viewerIndex,
  slideId,
}: {
  expandAccordion: boolean;
  setExpandAccordion: (expand: boolean) => void;
  heatmaps: FeatureMetadata[];
  viewerIndex: number;
  slideId: string;
}) => {
  // On first render, set the default expanded state based on selected heatmaps
  useEffect(() => {
    if (expandAccordion) {
      return;
    }
    const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
    const slideLayerVisualizationSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId];

    const hasCurrentlySelectedHeatmaps = some(
      heatmaps,
      ({ id }) => slideLayerVisualizationSettings?.[id]?.value?.selected
    );
    if (hasCurrentlySelectedHeatmaps) {
      setExpandAccordion(hasCurrentlySelectedHeatmaps);
    }
  }, [heatmaps, viewerIndex, slideId]);
};
