import { Skeleton } from '@mui/material';
import { ChartDataset } from 'chart.js';
import { map } from 'lodash';
import React from 'react';
import { Scatter } from 'react-chartjs-2';

import { PartialCohort } from 'interfaces/cohort_old';
import { useGetNameOverrideOrDisplayNameWithContext } from 'utils/features/contextHooks';
import { ChartKey, cohortToPoints } from '../chart.util';
import { cohortToScatterDataset, scatterChartOptions } from './scatter.util';

interface Props {
  cohorts: PartialCohort[];
  verticalKey?: ChartKey;
  horizontalKey?: ChartKey;
}

const ScatterChart: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  // assume cohorts are fully loaded

  const { getNameOverrideOrDisplayNameWithContext, isLoadingFormatterData } =
    useGetNameOverrideOrDisplayNameWithContext(false);

  const { cohorts, verticalKey, horizontalKey } = props;

  const dataFromCohort = cohortToScatterDataset(cohortToPoints(horizontalKey?.name, verticalKey?.name));

  const verticalKeyName = getNameOverrideOrDisplayNameWithContext(verticalKey?.name);

  const horizontalKeyName = getNameOverrideOrDisplayNameWithContext(horizontalKey?.name);

  const options = scatterChartOptions(horizontalKeyName, verticalKeyName);

  const datasets = map(cohorts, dataFromCohort) as ChartDataset<'scatter'>[];

  return isLoadingFormatterData ? (
    <Skeleton variant="rectangular" />
  ) : (
    <Scatter data={{ datasets }} options={options} />
  );
};

export default ScatterChart;
