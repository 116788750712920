const usEast1 = {
  text: 'US East (N. Virginia)',
  extraText: 'us-east-1',
  value: 'us-east-1',
};

const usEast2 = {
  text: 'US East (Ohio)',
  extraText: 'us-east-2',
  value: 'us-east-2',
};

const usWest1 = {
  text: 'US West (N. California)',
  extraText: 'us-west-1',
  value: 'us-west-1',
};

const usWest2 = {
  text: 'US West (Oregon)',
  extraText: 'us-west-2',
  value: 'us-west-2',
};

const afSouth1 = {
  text: 'Africa (Cape Town)',
  extraText: 'af-south-1',
  value: 'af-south-1',
};

const apEast1 = {
  text: 'Asia Pacific (Hong Kong)',
  extraText: 'ap-east-1',
  value: 'ap-east-1',
};

const apSoutheast3 = {
  text: 'Asia Pacific (Jakarta)',
  extraText: 'ap-southeast-3',
  value: 'ap-southeast-3',
};

const apSouth1 = {
  text: 'Asia Pacific  (Mumbai)',
  extraText: 'ap-south-1',
  value: 'ap-south-1',
};

const apNortheast3 = {
  text: 'Asia Pacific (Osaka)',
  extraText: 'ap-northeast-3',
  value: 'ap-northeast-3',
};

const apNortheast2 = {
  text: 'Asia Pacific (Seoul)',
  extraText: 'ap-northeast-2',
  value: 'ap-northeast-2',
};

const apSoutheast1 = {
  text: 'Asia Pacific (Singapore)',
  extraText: 'ap-southeast-1',
  value: 'ap-southeast-1',
};

const apSoutheast2 = {
  text: 'Asia Pacific (Sydney)',
  extraText: 'ap-southeast-2',
  value: 'ap-southeast-2',
};

const apNortheast1 = {
  text: 'Asia Pacific (Tokyo)',
  extraText: 'ap-northeast-1',
  value: 'ap-northeast-1',
};

const caCentral1 = {
  text: 'Canada (Central)',
  extraText: 'ca-central-1',
  value: 'ca-central-1',
};

const euCentral1 = {
  text: 'Europe (Frankfurt)',
  extraText: 'eu-central-1',
  value: 'eu-central-1',
};

const euWest1 = {
  text: 'Europe (Ireland)',
  extraText: 'eu-west-1',
  value: 'eu-west-1',
};

const euWest2 = {
  text: 'Europe (London)',
  extraText: 'eu-west-2',
  value: 'eu-west-2',
};

const euSouth1 = {
  text: 'Europe (Milan)',
  extraText: 'eu-south-1',
  value: 'eu-south-1',
};

const euWest3 = {
  text: 'Europe (Paris)',
  extraText: 'eu-west-3',
  value: 'eu-west-3',
};

const euNorth1 = {
  text: 'Europe (Stockholm)',
  extraText: 'eu-north-1',
  value: 'eu-north-1',
};

const meSouth1 = {
  text: 'Middle East (Bahrain)',
  extraText: 'me-south-1',
  value: 'me-south-1',
};

const saEast1 = {
  text: 'South America (São Paulo)',
  extraText: 'sa-east-1',
  value: 'sa-east-1',
};

const regions: {
  text: string;
  extraText?: string;
  value: string;
  disabled?: boolean;
}[] = [
  usEast1,
  usEast2,
  usWest1,
  usWest2,
  afSouth1,
  apEast1,
  apSoutheast3,
  apSouth1,
  apNortheast3,
  apNortheast2,
  apSoutheast1,
  apSoutheast2,
  apNortheast1,
  caCentral1,
  euCentral1,
  euWest1,
  euWest2,
  euSouth1,
  euWest3,
  euNorth1,
  meSouth1,
  saEast1,
];

export default regions;
