import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CommentIcon from '@mui/icons-material/Comment';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import DrawIcon from '@mui/icons-material/Draw';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import EditIcon from '@mui/icons-material/Edit';
import PolylineIcon from '@mui/icons-material/Polyline';
import PushPinIcon from '@mui/icons-material/PushPin';
import SquareIcon from '@mui/icons-material/Square';
import StraightenIcon from '@mui/icons-material/Straighten';
import { Box, Divider, Fade, Grid, Popper, ToggleButton } from '@mui/material';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { viewerAnnotationData } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/EditAnnotationLayers/useActiveAnnotationDraft';
import { useSecondaryAnalysisOrchestrationIdQueryParams } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/SecondaryAnalysisLayer/useSecondaryAnalysisQueryParams';
import { Permission } from 'interfaces/permissionOption';
import { flatMap, forEach, fromPairs, map } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useAnnotationQueryParams } from 'services/annotations/useAnnotationQueryParams';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { usePermissions } from 'utils/usePermissions';
import { useClearSecondaryAnalysisSelections, useCurrentSecondaryAnalysisOptions } from './secondaryAnalysis';
import { SlideInteractionMenuOptions, SlideInteractionOption } from './SlideInteractionOption';

export enum SlideAnnotationEditType {
  Delete = 'delete',
  Modify = 'modify',
  Polygon = 'polygon',
  PolygonByDragging = 'polygonByDragging',
  Roi = 'roi',
  Point = 'point',
}

// This is an internal type that we save inside properties of a feature.
// It is taken from the legacy backoffice codebase.
export enum ShapeSubTypes {
  Polygon = 'polygon',
  Rect = 'rect',
  Point = 'circle',
}

export const editTypeToShapeSubTypeMap = {
  [SlideAnnotationEditType.Polygon]: ShapeSubTypes.Polygon,
  [SlideAnnotationEditType.PolygonByDragging]: ShapeSubTypes.Polygon,
  [SlideAnnotationEditType.Roi]: ShapeSubTypes.Rect,
  [SlideAnnotationEditType.Point]: ShapeSubTypes.Point,
};

export const slideAnnotationOptions: SlideInteractionMenuOptions[] = [
  {
    editType: SlideAnnotationEditType.Delete,
    title: 'Delete Individual Annotations',
    icon: DeleteOutlineIcon,
  },
  {
    editType: SlideAnnotationEditType.Modify,
    title: 'Modify Annotations',
    icon: DriveFileRenameOutlineIcon,
  },
  {
    editType: SlideAnnotationEditType.Polygon,
    title: 'Polygon',
    icon: PolylineIcon,
  },
  {
    editType: SlideAnnotationEditType.PolygonByDragging,
    title: 'Polygon By Dragging',
    icon: DrawIcon,
  },
  {
    editType: SlideAnnotationEditType.Roi,
    title: 'ROI Selection',
    icon: SquareIcon,
  },
  {
    editType: SlideAnnotationEditType.Point,
    title: 'Point',
    icon: PushPinIcon,
  },
];

export const SlideInteractionMenu: React.FunctionComponent = () => {
  const [useDeckGL] = useQueryParam('useDeckGL', BooleanParam);
  const [measureToolActive, setMeasureToolActive] = useQueryParam('measureToolActive', BooleanParam);
  const [commentMode, setCommentMode] = useQueryParam('commentMode', StringParam);
  const { annotationsActive, annotationQueryKeys, setAnnotationQueryParams } = useAnnotationQueryParams();
  const [editAnnotationsMode, setEditAnnotationMode] = useQueryParam('editAnnotationsMode', StringParam);
  const [, setHideFillColor] = useQueryParam('hideFillColor', BooleanParam);

  // Check if any of the viewers is currently performing a secondary analysis
  const { secondaryAnalysisActive } = useSecondaryAnalysisOrchestrationIdQueryParams();
  const [secondaryAnalysisAreaSelectionMode, setSecondaryAnalysisAreaSelectionMode] = useQueryParam(
    'secondaryAnalysisAreaSelectionMode',
    StringParam
  );

  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);

  const clearSecondaryAnalysisSelections = useClearSecondaryAnalysisSelections();

  const clearSlideInteraction = useCallback(() => {
    setMeasureToolActive(false);
    setCommentMode(null);
    setAnnotationQueryParams(fromPairs(map(annotationQueryKeys, (key) => [key, null])));
    forEach(viewerAnnotationData, (annotationDraftSignal) => {
      annotationDraftSignal.value = null;
    });
    clearSecondaryAnalysisSelections();
    setEditAnnotationMode(null);
  }, [setMeasureToolActive, setCommentMode]);

  const clearNonAnnotationInteractions = useCallback(() => {
    setMeasureToolActive(false);
    setCommentMode(null);
  }, [setMeasureToolActive, setCommentMode]);

  const areDrawingModesActive = annotationsActive || secondaryAnalysisActive;
  const [isDrawingToolSelectionOpenState, setIsDrawingToolSelectionOpen] = React.useState(areDrawingModesActive);
  const isDrawingToolSelectionOpen = areDrawingModesActive && Boolean(anchorEl) && isDrawingToolSelectionOpenState;

  useEffect(() => {
    // Open/Close the drawing tool selection if the drawing modes are active/inactive
    setIsDrawingToolSelectionOpen(areDrawingModesActive);
  }, [areDrawingModesActive]);

  const { hasPermission } = usePermissions();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);
  const canRunSecondaryAnalysis = hasPermission(Permission.RunSecondaryAnalysis);
  const hasPermissionsToDrawingTools = canAnnotateSlides || canRunSecondaryAnalysis;

  const secondaryAnalysisOptions = useCurrentSecondaryAnalysisOptions();

  const slideInteractionOptions = secondaryAnalysisActive ? secondaryAnalysisOptions : slideAnnotationOptions;

  const activeDrawingMode = secondaryAnalysisActive ? secondaryAnalysisAreaSelectionMode : editAnnotationsMode;
  const setActiveDrawingMode = secondaryAnalysisActive ? setSecondaryAnalysisAreaSelectionMode : setEditAnnotationMode;

  useEffect(() => {
    if (useDeckGL && hasPermissionsToDrawingTools && annotationsActive) {
      const handleKeyDownForShortcuts = (event: KeyboardEvent) => {
        if (event.key === 'p') {
          setEditAnnotationMode(SlideAnnotationEditType.Polygon);
        } else if (event.key === 'r') {
          setEditAnnotationMode(SlideAnnotationEditType.Roi);
        } else if (event.key === 'd') {
          setEditAnnotationMode(SlideAnnotationEditType.Delete);
        } else if (event.key === 'm') {
          setEditAnnotationMode(SlideAnnotationEditType.Modify);
        } else if (event.key === 'h') {
          setHideFillColor((hideFillColor) => !hideFillColor);
        }
      };

      window.addEventListener('keydown', handleKeyDownForShortcuts);

      return () => {
        window.removeEventListener('keydown', handleKeyDownForShortcuts);
      };
    }
  }, [useDeckGL, hasPermissionsToDrawingTools, annotationsActive]);

  const confirmWithModal = useConfirmation();

  return (
    useDeckGL && (
      <>
        {hasPermissionsToDrawingTools && areDrawingModesActive && (
          <>
            <ToggleButton
              ref={setAnchorEl}
              value="slideActions"
              onChange={() => setIsDrawingToolSelectionOpen(!isDrawingToolSelectionOpen)}
              title={
                isDrawingToolSelectionOpen
                  ? secondaryAnalysisActive
                    ? 'Open selections for secondary analysis'
                    : 'Open slide annotation tools'
                  : 'Hide'
              }
              disabled={Boolean(commentMode)}
            >
              {isDrawingToolSelectionOpen ? <ArrowForwardIosIcon /> : <EditIcon />}
            </ToggleButton>
            <Popper anchorEl={anchorEl} open={isDrawingToolSelectionOpen} placement="left" transition disablePortal>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                  <Box sx={{ bgcolor: 'background.paper' }}>
                    <Grid item container direction="row" p={0} m={0}>
                      <SlideInteractionOption
                        title={
                          secondaryAnalysisActive
                            ? 'Clear secondary analysis selections'
                            : 'Clear current slide annotations'
                        }
                        onClick={async () => {
                          if (
                            await confirmWithModal({
                              title: secondaryAnalysisActive
                                ? 'Clear secondary analysis selections'
                                : 'Clear current slide annotations changes',
                              text: secondaryAnalysisActive
                                ? 'Exit secondary analysis mode?'
                                : "Unsaved changes will be lost. Are you sure you want to clear the current slide's annotations?",
                            })
                          ) {
                            clearSlideInteraction();
                          }
                        }}
                        value="clearSlideInteraction"
                        icon={<DeleteSweepIcon />}
                      />
                      <Divider orientation="vertical" />
                      {flatMap(slideInteractionOptions, ({ editType, title, icon: OptionIcon, color, sx }) => (
                        <SlideInteractionOption
                          key={editType}
                          value={editType}
                          title={title}
                          selected={activeDrawingMode === editType}
                          onClick={() => {
                            clearNonAnnotationInteractions();
                            if (editType === activeDrawingMode) {
                              setActiveDrawingMode(null);
                            } else {
                              setActiveDrawingMode(editType);
                            }
                          }}
                          icon={
                            <OptionIcon
                              color={color ? color : activeDrawingMode === editType ? 'primary' : 'inherit'}
                              sx={sx}
                            />
                          }
                        />
                      ))}
                    </Grid>
                  </Box>
                </Fade>
              )}
            </Popper>
          </>
        )}
        <ToggleButton
          value="measureTool"
          selected={measureToolActive}
          onClick={() => {
            clearNonAnnotationInteractions();
            setMeasureToolActive(!measureToolActive);
          }}
          title="Measure tool"
        >
          <StraightenIcon color={measureToolActive ? 'primary' : 'inherit'} />
        </ToggleButton>
        <ToggleButton
          value="commentMode"
          selected={Boolean(commentMode)}
          onClick={() => {
            clearNonAnnotationInteractions();
            setCommentMode(!commentMode ? 'addPoint' : null);
          }}
          title="Add comments to slide"
        >
          <CommentIcon color={commentMode ? 'primary' : 'inherit'} />
        </ToggleButton>
      </>
    )
  );
};
