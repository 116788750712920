import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Grid, Tooltip } from '@mui/material';
import { Feature } from '@turf/helpers';
import React from 'react';

import { aggressiveSizeRounding, calculateFeatureSize, sizeAsMetric } from 'utils/geojsonCalculations';

export const FeatureSizeSection: React.FC<{
  feature: Feature;
  maxResolution: number;
  maxLevel: number;
}> = ({ feature, maxResolution, maxLevel }) => {
  const zoom = 2 ** maxLevel;
  const originalPPM = (1000000 / maxResolution) * zoom;
  const size = calculateFeatureSize(feature);

  return (
    size &&
    size !== -1 && (
      <Grid container columnGap={1} pb={1} justifyContent="center" alignItems="center">
        <Tooltip title={size.toFixed(2)}>
          <span>{aggressiveSizeRounding(size)}px</span>
        </Tooltip>
        <SyncAltIcon />
        <span>
          {sizeAsMetric({ originalPPM, size })}
          <sup>2</sup>
        </span>
      </Grid>
    )
  );
};
