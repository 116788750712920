import { ScatterplotLayer } from '@deck.gl/layers/typed';
import { Dictionary, findIndex, indexOf, isEmpty, map, some, values } from 'lodash';

import { LayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { defaultLayerColors } from 'components/theme/theme';
import { hexToRgb, isHexString } from 'utils/helpers';
import { ParquetFile, ParquetRow } from 'utils/useParquetFile';

export const deckGLParquetCellClassLayer = ({
  idPrefix = '',
  parquetHeatmapId,
  heatmapMetadata,
  visualSettings,
  parquetFile,
  rescaleFactor,
  filteredColumns,
}: {
  idPrefix?: string;
  parquetHeatmapId: string;
  heatmapMetadata: FeatureMetadata;
  visualSettings: Dictionary<LayerVisualizationSettings>;
  rescaleFactor?: number;
  parquetFile: ParquetFile;
  filteredColumns: string[];
}) => {
  if (!parquetHeatmapId) {
    return undefined;
  }
  const pointRadiusAtMaxZoom = Math.max(1, 5 / (rescaleFactor || 1));
  const parquetClasses = map(heatmapMetadata?.options, 'key');
  const hasActiveParquetSource = some(values(visualSettings), 'selected');

  const columnIndex = filteredColumns
    ? indexOf(filteredColumns, heatmapMetadata.columnName)
    : findIndex(
        parquetFile?.metadata?.schema,
        (field: { name: string }) => field?.name === heatmapMetadata.columnName
      ) - 1;

  if (!parquetFile?.rows || isEmpty(parquetClasses) || !hasActiveParquetSource || columnIndex < 0) {
    return undefined;
  }

  return new ScatterplotLayer<ParquetRow>({
    id: `${idPrefix}${parquetHeatmapId}`,
    data: parquetFile.rows,
    getPosition: (d: ParquetRow) => [d[0], d[1]],
    pickable: true,

    // The point radius is scaled based on the zoom level - the higher the zoom level, the higher the point radius, up to the pointRadiusAtMaxZoom.
    getRadius: pointRadiusAtMaxZoom,
    radiusMinPixels: 1,
    // We don't use the pointRadiusMaxPixels because we can zoom beyond the maximum zoom level of the base layer.
    radiusScale: rescaleFactor ? 1 / rescaleFactor : 1,
    getFillColor: (d: ParquetRow) => {
      const columnValue = d[columnIndex];
      const labelIndex = indexOf(parquetClasses, columnValue);
      const layerSettings = visualSettings[`${parquetHeatmapId}-${columnValue}`];
      const opacity = layerSettings?.selected && layerSettings?.show ? (layerSettings?.opacity || 0) / 100 : 0;
      const color = hexToRgb(
        isHexString(layerSettings?.color?.hex || '')
          ? layerSettings?.color?.hex
          : isHexString(layerSettings?.color || '')
          ? layerSettings?.color
          : defaultLayerColors[(labelIndex + defaultLayerColors.length) % defaultLayerColors.length]
      );
      return [...color, Math.floor(opacity * 255)];
    },
    updateTriggers: {
      getRadius: [rescaleFactor],
      getFillColor: [JSON.stringify(visualSettings)],
    },
    getLineWidth: 0,
  });
};
