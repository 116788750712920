import RemoveIcon from '@mui/icons-material/Remove';
import { FormControl, Grid, IconButton, MenuItem, Paper, Select, TextField } from '@mui/material';
import { find, isNumber } from 'lodash';
import React, { FunctionComponent } from 'react';

import { VirtualizedAutocomplete } from 'components/FeaturesDashboard/KeySelect/VirtualizedAutocomplete';
import useFeatureFilters, { featuresFilterOptions } from 'components/SearchFilters/hooks/useFeatureFilters';
import { AutomaticConditionRange, QualityControlSource } from 'interfaces/automaticCondition';
import { NamedFeature } from 'interfaces/features';
import { useGetFeatureDisplayNameWithContext } from 'utils/features/contextHooks';
import { useStudyStainTypeIds } from 'utils/queryHooks/stainType/useStudyStainTypes';
import { AutomaticConditionBuilderProps } from './AutomaticConditionBuilder';

type RangeConditionBuilderProps = AutomaticConditionBuilderProps<AutomaticConditionRange>;

const RangeConditionBuilder: FunctionComponent<React.PropsWithChildren<RangeConditionBuilderProps>> = ({
  condition,
  onConditionChange,
  onRemove,
  study,
  editable,
  orchestrationId,
  shouldShowErrors,
  featuresOnly,
}) => {
  const { source, field, min, max } = condition;

  const handleSourceChange = (updatedSource: QualityControlSource) => {
    onConditionChange({ ...condition, source: updatedSource, field: '' });
  };

  const handleFieldChange = (updatedField: string) => {
    onConditionChange({ ...condition, field: updatedField });
  };

  const handleMinChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target.value === '') {
      onConditionChange({ ...condition, min: undefined });
      return;
    }
    const updatedMin = Number(e.target.value);
    onConditionChange({ ...condition, min: isNumber(updatedMin) ? updatedMin : undefined });
  };

  const handleMaxChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target.value === '') {
      onConditionChange({ ...condition, max: undefined });
      return;
    }
    const updatedMax = Number(e.target.value);
    onConditionChange({ ...condition, max: isNumber(updatedMax) ? updatedMax : undefined });
  };

  const { featuresData, featuresLoading } = useFeatureFilters({
    studyId: study.id,
    orchestrationId,
  });

  const fieldsForSource = {
    slide: [{ key: 'tsm_area_um2', displayName: 'TSM Area um2', nameOverride: '' }],
    experimentResult: featuresData || [],
  };

  const invalidRange =
    (min === undefined && max === undefined) || (min !== undefined && max !== undefined && min > max);
  const invalidSource = source === undefined;
  const invalidField = field === undefined || field === '';

  const { data: studyStains } = useStudyStainTypeIds(study.id);
  const addStainToFeatureName = studyStains && studyStains.length > 1;
  const { getFeatureDisplayNameWithContext, isLoadingFormatterData } =
    useGetFeatureDisplayNameWithContext(addStainToFeatureName);

  return (
    <Paper
      elevation={1}
      sx={{
        width: '100%',
        p: 1,
        mb: 1,
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <IconButton disabled={!editable} onClick={() => onRemove()}>
            <RemoveIcon />
          </IconButton>
        </Grid>
        {!featuresOnly && (
          <Grid item xs={3}>
            <Select
              size="small"
              error={invalidSource}
              disabled={!editable}
              value={source}
              onChange={(e) => handleSourceChange(e.target.value as QualityControlSource)}
            >
              <MenuItem value="slide">Slide</MenuItem>
              <MenuItem value="experimentResult">Experiment Result</MenuItem>
            </Select>
          </Grid>
        )}
        <Grid item xs>
          <FormControl fullWidth>
            <VirtualizedAutocomplete<NamedFeature>
              size="small"
              filterOptions={featuresFilterOptions}
              disabled={!editable || isLoadingFormatterData}
              options={fieldsForSource[source]}
              value={
                find(fieldsForSource[source], { key: field }) || {
                  key: field,
                  displayName: getFeatureDisplayNameWithContext(field),
                  nameOverride: '',
                }
              }
              componentsProps={{
                paper: { style: { width: '40em' } },
              }}
              onChange={(event, featureKey) => handleFieldChange(featureKey?.key || '')}
              renderOption={(fieldOption) => ({
                key: fieldOption.key,
                displayName: fieldOption?.nameOverride || fieldOption?.displayName,
              })}
              getOptionLabel={(fieldOption) => fieldOption?.nameOverride || fieldOption?.displayName}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  label={'Field'}
                  error={invalidField}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: featuresLoading && source === 'experimentResult' ? 'Loading...' : null,
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <TextField
            size="small"
            disabled={!editable}
            label="Min"
            type="number"
            value={Number(min) ?? null}
            onChange={handleMinChange}
            error={invalidRange && shouldShowErrors}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            size="small"
            disabled={!editable}
            label="Max"
            type="number"
            value={Number(max) ?? null}
            onChange={handleMaxChange}
            error={invalidRange && shouldShowErrors}
            fullWidth
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RangeConditionBuilder;
