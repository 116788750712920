import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { compact, filter, find, first, includes, isEmpty, map, some, sortBy, uniq } from 'lodash';
import React from 'react';

import { LocalOffer } from '@mui/icons-material';
import { useTheme } from '@mui/system';
import Dropdown from 'components/atoms/Dropdown/Dropdown';
import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import { SlideTagsSelectionMenu } from './SlideTagsSelectionMenu';

const NO_ORCHESTRATION_ID = '-';

const SlideTagsPanel: React.FunctionComponent<
  React.PropsWithChildren<{
    onTransitionEnd?: () => void;
    showCloseButton?: boolean;
    selectedSlideIds?: string[];
    currentCase: Procedure;
    currentSlide: Slide;
    refetchProcedure: () => void;
    isPlaceholderCaseData?: boolean;
    isLoadingCaseData?: boolean;
    onClose: () => void;
  }>
> = ({
  selectedSlideIds,

  onTransitionEnd,
  showCloseButton,
  currentCase,
  currentSlide: slideForTagging,
  refetchProcedure,
  isLoadingCaseData,
  isPlaceholderCaseData,
  onClose,
}) => {
  const { hasPermission } = usePermissions();
  const hasTaggingPermissions = hasPermission(Permission.EditSlideTagsAssignments);

  const canTagSlides = hasTaggingPermissions;

  const theme = useTheme();

  const slideIdForTagging = slideForTagging?.id;

  const toggleDrawer = () => {
    onClose();
    onTransitionEnd?.();
  };

  const orchestrationIdOptions: Array<{ value: string; text: string }> = React.useMemo(() => {
    if (!slideForTagging) {
      return [{ value: NO_ORCHESTRATION_ID, text: 'Loading slide...' }];
    } else if (isPlaceholderCaseData) {
      return [{ value: NO_ORCHESTRATION_ID, text: 'Loading results...' }];
    }

    const { experimentResults } = slideForTagging;
    const sortedResults = sortBy(experimentResults, [({ approved }) => (approved ? 1 : -1), 'createdAt']).reverse();
    const uniqueOrchestrationIds = uniq(compact(map(sortedResults, 'orchestrationId')));

    if (isEmpty(uniqueOrchestrationIds)) {
      return [
        { value: NO_ORCHESTRATION_ID, text: 'No experiment results to tag', details: 'Can only tag by slide / study' },
      ];
    }
    return map(uniqueOrchestrationIds, (orchestrationId) => {
      const relevantResults = filter(experimentResults, { orchestrationId });
      const isApproved = some(relevantResults, 'approved');
      const createdAt = first(relevantResults)?.createdAt;
      return {
        value: orchestrationId,
        text: `${orchestrationId}${isApproved ? ' - Approved' : ''}`,
        details: `${dayjs(createdAt).format('L LT')}`,
      };
    });
  }, [slideForTagging]);

  const [selectedOrchestrationIdState, setOrchestrationId] = React.useState<string | undefined>(undefined);
  const selectedOrchestrationId = includes(map(orchestrationIdOptions, 'value'), selectedOrchestrationIdState)
    ? selectedOrchestrationIdState
    : undefined;

  const { queryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const filteredOrchestrationId =
    queryParams?.filters?.orchestrationId &&
    includes(map(orchestrationIdOptions, 'value'), queryParams?.filters?.orchestrationId)
      ? (queryParams?.filters?.orchestrationId as string)
      : undefined;
  const approvedOrchestrationId = find(slideForTagging?.experimentResults, 'approved')?.orchestrationId;

  const orchestrationId =
    // User selected orchestrationId
    selectedOrchestrationId ??
    // Filtered orchestrationId for slide if it exists
    filteredOrchestrationId ??
    // Approved orchestrationId for slide if it exists
    approvedOrchestrationId ??
    // Default to first orchestrationId
    first(orchestrationIdOptions)?.value;

  return canTagSlides ? (
    <>
      <Grid item pt={1} container xs={showCloseButton ? 10 : 12} alignItems={'center'}>
        <Grid item container flexDirection="row">
          <LocalOffer
            sx={{ mr: 1 }}
            color={
              selectedSlideIds && uniq(selectedSlideIds).length > 1
                ? theme.palette.mode === 'dark'
                  ? 'primary'
                  : 'secondary'
                : undefined
            }
          />
          <Typography variant="h3">Slide Tags</Typography>
        </Grid>
        <Dropdown
          addSeparatorToOptions
          size="small"
          variant="standard"
          label="Orchestration ID"
          options={orchestrationIdOptions}
          onOptionSelected={setOrchestrationId}
          value={orchestrationId || NO_ORCHESTRATION_ID}
        />
      </Grid>
      {showCloseButton && (
        <Grid item xs={2} textAlign="right">
          <IconButton onClick={toggleDrawer}>
            <CloseIcon />
          </IconButton>
        </Grid>
      )}
      <Grid container item sx={{ overflow: 'auto', width: '100%' }}>
        <SlideTagsSelectionMenu
          currentCase={currentCase}
          orchestrationId={orchestrationId}
          slideIdForTagging={slideIdForTagging}
          refetchProcedure={refetchProcedure}
          isLoadingCaseData={isLoadingCaseData}
        />
      </Grid>
    </>
  ) : null;
};

export default SlideTagsPanel;
