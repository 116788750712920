import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { TreeItem } from '@mui/lab';
import { Autocomplete, Button, FormControl, FormHelperText, Grid, IconButton, Popover, TextField } from '@mui/material';
import { Layer } from 'interfaces/visualization';
import { find } from 'lodash';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

export interface ClassOption {
  label: string | number;
  value: string | number;
  type: string;
}

interface VisualizationLayerProps {
  classes: ClassOption[];
  classExist: boolean;
  classificationClassExist: boolean;
  layerKey: string;
  layer: Layer;
  updateLayer: (layer: any) => void;
  deleteLayer: () => void;
  isClassification: boolean;
  classificationClasses: ClassOption[];
}

export const VisualizationLayer: React.FC<React.PropsWithChildren<VisualizationLayerProps>> = ({
  classes,
  classExist,
  classificationClassExist,
  layer,
  layerKey,
  updateLayer,
  deleteLayer,
  isClassification,
  classificationClasses,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openColorPopOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeColorPopOver = () => {
    setAnchorEl(null);
  };

  return (
    <TreeItem
      key={layerKey}
      nodeId={layerKey}
      label={
        <Grid container wrap="nowrap" spacing={1} alignItems="center" mb={1} justifyContent="flex-start">
          <Grid item>
            <Button
              role="color-picker"
              size="small"
              sx={{
                minWidth: 17,
                height: 17,
                mr: 1,
                display: 'flex',
                background: layer.color,
              }}
              variant="contained"
              onClick={openColorPopOver}
            />
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={closeColorPopOver}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
              <SketchPicker
                color={layer.color}
                onChangeComplete={(color) => updateLayer({ ...layer, color: color?.hex })}
              />
            </Popover>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth variant="standard" error={!classExist} className={!classExist ? 'error' : undefined}>
              <Grid item container direction="column">
                <Grid item xs={7}>
                  <Autocomplete
                    size="small"
                    id="marker-select-label"
                    groupBy={(option) => option.type}
                    value={
                      layer.classKey
                        ? {
                            label: find(classes, (config) => config.value === layer.classKey)?.label ?? layer.classKey,
                            value: layer.classKey,
                            type: find(classes, (config) => config.value === layer.classKey)?.type ?? '',
                          }
                        : null
                    }
                    options={classes}
                    onChange={(_event, classOption) => updateLayer({ ...layer, classKey: classOption?.value })}
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                  />
                </Grid>
                <Grid item>
                  {!classExist && (
                    <FormHelperText error id="class-does-not-exist-error-text">
                      This class does not exist. Please select different model or create this class in post processing.
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          {isClassification && (
            <Grid item xs={3}>
              <FormControl
                fullWidth
                variant="standard"
                error={!classificationClassExist}
                className={!classificationClassExist ? 'error' : undefined}
              >
                <Grid item container direction="column">
                  <Grid item xs={3}>
                    <Autocomplete
                      size="small"
                      groupBy={(option) => option.type}
                      value={
                        layer.classificationClassKey
                          ? {
                              label:
                                find(classificationClasses, (config) => config.value === layer.classificationClassKey)
                                  ?.label ?? layer.classificationClassKey,
                              value: layer.classificationClassKey,
                              type:
                                find(classificationClasses, (config) => config.value === layer.classificationClassKey)
                                  ?.type ?? '',
                            }
                          : null
                      }
                      options={classificationClasses}
                      onChange={(_event, classOption) =>
                        updateLayer({ ...layer, classificationClassKey: classOption?.value })
                      }
                      renderInput={(params) => <TextField {...params} variant="standard" />}
                    />
                  </Grid>
                  <Grid item>
                    {!classificationClassExist && (
                      <FormHelperText error id="class-does-not-exist-error-text">
                        This class does not exist. Please select different classification model.
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          )}
          <Grid item>
            <IconButton onClick={() => deleteLayer()} size="small">
              <DeleteOutlineIcon />
            </IconButton>
          </Grid>
        </Grid>
      }
    ></TreeItem>
  );
};
