import { find, includes, isEmpty, replace, slice, some, split, startsWith, toString } from 'lodash';

import Visualization from 'interfaces/visualization';
import { f } from 'utils/formatBrackets/formatBracketsFeature';
import {
  bracketsRegex,
  buildClassifiersString,
  getDictBrackets,
  getFormattedArea,
  getFormattedCellWithStainAndClassifiers,
  getFormattedClassificationModels,
  getFormattedStain,
  titleCase,
} from '.';
import { FormatBracketsOptions } from './formatBracketsOptions';

const visualizationWithClassifiers = ['v:5', 'v:8', 'v:10'];

const getVisualizationId = (visualizationParts: string[]): string => {
  const visualizationIdPart = find(visualizationParts, (visualizationPart) => startsWith(visualizationPart, 'v:'));
  return split(visualizationIdPart, ':')[1];
};

export const formatBracketsVisualization = (
  key: string,
  bracketTypesOptions: FormatBracketsOptions,
  mainHeatmapKey?: string
): string => {
  try {
    const visualizationParts = slice(split(key, bracketsRegex), 1, -1);
    const visualizationId = getVisualizationId(visualizationParts);
    let formattedVisualization = '';

    if (v[visualizationId]) {
      formattedVisualization = v[visualizationId](visualizationParts, bracketTypesOptions);
    } else {
      const dictBrackets = getDictBrackets(visualizationParts);
      if (dictBrackets['c']) {
        if (
          mainHeatmapKey &&
          some(visualizationWithClassifiers, (visualization) => mainHeatmapKey.includes(visualization))
        ) {
          formattedVisualization = `${buildClassifiersString(dictBrackets['classifiers'], bracketTypesOptions, {
            withParenthesis: false,
            isMarkerPositivity: includes(mainHeatmapKey, 'v:8'),
          })}`;
        } else {
          formattedVisualization = `${getFormattedCellWithStainAndClassifiers(
            dictBrackets['c'],
            dictBrackets['s'],
            dictBrackets['classifiers'],
            bracketTypesOptions
          )}`;
        }
      } else if (dictBrackets['a']) {
        formattedVisualization = `${getFormattedArea(dictBrackets['a'], bracketTypesOptions.areaTypeOptions)}`;
      }
    }

    if (!isEmpty(formattedVisualization)) {
      return titleCase(formattedVisualization);
    }

    return key;
  } catch (error) {
    console.error(`Error formatting visualization: ${key}`, error);
    return key;
  }
};

const v: Record<string, (visualizationParts: string[], options: FormatBracketsOptions) => string> = {
  '1': (visualizationParts, { visualizationTypeOptions }) => {
    const dictBrackets = getDictBrackets(visualizationParts);

    return getFormattedVisualization(dictBrackets['v'], visualizationTypeOptions);
  },
  '2': (visualizationParts, { visualizationTypeOptions }) => {
    const dictBrackets = getDictBrackets(visualizationParts);

    return getFormattedVisualization(dictBrackets['v'], visualizationTypeOptions);
  },
  '3': (visualizationParts, { visualizationTypeOptions }) => {
    const dictBrackets = getDictBrackets(visualizationParts);

    return getFormattedVisualization(dictBrackets['v'], visualizationTypeOptions);
  },
  '4': (visualizationParts, { visualizationTypeOptions }) => {
    const dictBrackets = getDictBrackets(visualizationParts);

    return getFormattedVisualization(dictBrackets['v'], visualizationTypeOptions);
  },
  '5': (visualizationParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(visualizationParts);
    const formattedCell =
      dictBrackets['c'] === 'all_cells'
        ? ''
        : getFormattedCellWithStainAndClassifiers(
            dictBrackets['c'],
            dictBrackets['s'],
            dictBrackets['classifiers'],
            bracketTypesOptions
          );

    return `${getFormattedClassificationModels(
      dictBrackets['cm'],
      bracketTypesOptions.classificationModelOptions
    )} ${getFormattedVisualization(dictBrackets['v'], bracketTypesOptions.visualizationTypeOptions)} ${formattedCell}`;
  },
  '6': (visualizationParts, { visualizationTypeOptions }) => {
    const dictBrackets = getDictBrackets(visualizationParts);

    return getFormattedVisualization(dictBrackets['v'], visualizationTypeOptions);
  },
  '7': (visualizationParts, options) => {
    const dictBrackets = getDictBrackets(visualizationParts);

    return f[dictBrackets['f']](visualizationParts, options);
  },
  '8': (visualizationParts, { visualizationTypeOptions }) => {
    const dictBrackets = getDictBrackets(visualizationParts);

    return getFormattedVisualization(dictBrackets['v'], visualizationTypeOptions);
  },
  '9': (visualizationParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(visualizationParts);
    const sourceStain = dictBrackets['source_s']?.['s']
      ? ` (Registered from ${getFormattedStain(dictBrackets['source_s']?.['s'], bracketTypesOptions.stainTypeOptions)})`
      : '';
    return `${getFormattedVisualization(
      dictBrackets['v'],
      bracketTypesOptions.visualizationTypeOptions
    )} ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['c'],
      dictBrackets['s'],
      dictBrackets['classifiers'],
      bracketTypesOptions
    )}${sourceStain}`;
  },
  '10': (visualizationParts, { visualizationTypeOptions }) => {
    const dictBrackets = getDictBrackets(visualizationParts);

    return getFormattedVisualization(dictBrackets['v'], visualizationTypeOptions);
  },
  '11': (visualizationParts, { visualizationTypeOptions }) => {
    const dictBrackets = getDictBrackets(visualizationParts);

    return getFormattedVisualization(dictBrackets['v'], visualizationTypeOptions);
  },
};

export const getFormattedVisualization = (index: string, visualizationTypeOptions: Visualization[]) => {
  const visualization = find(
    visualizationTypeOptions,
    (visualizationTypeOption) => toString(visualizationTypeOption.index) === index
  );

  return visualization?.displayName || replace(index, /[_-]/g, ' ');
};
