import { Grid } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { every, filter, map, slice, times } from 'lodash';
import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

import { SlidesViewerProps } from '../SlidesViewer';
import { ViewerContainer } from './ViewerContainer';
import { useDeckGLViewStates } from './useDeckGLViewStates';

export const DeckGLSlidesViewer: React.FC<React.PropsWithChildren<SlidesViewerProps>> = ({
  slides,
  baseSlideLoadingStates,
  magnificationValue,
  updateMagnificationFromZoom,
  displaySlideId,
  showNavigation,
  hideComments,
  procedureId,
  slidesBaseImagePyramids,
  slidesHeatmapsImagePyramids,
}) => {
  useSignals();
  const slidesWithLoadedBaseImages = filter(
    slides,
    (slide, slideIndex) => !baseSlideLoadingStates[slideIndex] && Boolean(slidesBaseImagePyramids?.[slide?.id])
  );

  const fullViewSize = useResizeDetector();

  // Divide the full view size into a grid of cells of equal size, except for the last row which may have wider cells
  const numCols = Math.ceil(Math.sqrt(slides.length));
  const numRows = Math.ceil(slides.length / numCols);

  const lastFilledRow = Math.floor((slides.length - 1) / numCols);
  const lastRowDivisor = slides.length - lastFilledRow * numCols;

  const viewSizes: Array<{ width: number; height: number }> = React.useMemo(
    () =>
      times(slides.length, (viewerIndex) => {
        const isInLastRow = viewerIndex >= lastFilledRow * numCols;

        return isInLastRow
          ? { width: fullViewSize.width / lastRowDivisor, height: fullViewSize.height / numRows }
          : { width: fullViewSize.width / numCols, height: fullViewSize.height / numRows };
      }),
    [fullViewSize.width, fullViewSize.height, numCols, numRows, lastFilledRow, lastRowDivisor]
  );

  const { viewStateChangeHandlers } = useDeckGLViewStates({
    slides: slidesWithLoadedBaseImages,
    updateMagnificationFromZoom,
    viewSizes,
    slidesBaseImagePyramids,
    magnificationValue,
  });

  const [slideIdForReviewing] = useQueryParam('slideIdForReviewing', StringParam);
  const [reviewTab] = useQueryParam('reviewTab', BooleanParam);

  const isReviewPanelOpen = Boolean(reviewTab);

  const focusedSlideId = isReviewPanelOpen ? slideIdForReviewing : undefined;

  const areAllSlidesFocused = every(slides, { id: focusedSlideId });

  return (
    <Grid ref={fullViewSize.ref} flexDirection="column" container overflow="hidden" position="relative" flex={1}>
      {times(numRows, (rowIndex) => {
        const minViewerIndex = rowIndex * numCols;
        const isInLastRow = rowIndex === numRows - 1;
        const maxViewerIndex = isInLastRow ? slides.length + 1 : minViewerIndex + numCols;
        return (
          <Grid
            key={rowIndex}
            item
            container
            wrap="nowrap"
            alignItems="center"
            columns={maxViewerIndex - minViewerIndex}
            overflow="hidden"
            position="relative"
            flex={1}
          >
            {map(slice(slides, minViewerIndex, maxViewerIndex), (slide, index) => (
              <ViewerContainer
                key={`${slide?.id || 'loading'}-${slide?.viewerIndex ?? minViewerIndex + index}`}
                viewSize={viewSizes[slide?.viewerIndex ?? minViewerIndex + index]}
                slide={slide}
                onViewStateChange={viewStateChangeHandlers[slide?.viewerIndex ?? minViewerIndex + index]}
                displaySlideId={displaySlideId}
                showNavigation={showNavigation}
                hideComments={hideComments}
                procedureId={procedureId}
                showTaggedChip={!areAllSlidesFocused && focusedSlideId === slide?.id}
                isLoading={baseSlideLoadingStates[slide?.viewerIndex ?? minViewerIndex + index]}
                baseImagePyramids={slidesBaseImagePyramids?.[slide?.id]}
                heatmapsImagePyramids={slidesHeatmapsImagePyramids?.[slide?.id]}
              />
            ))}
          </Grid>
        );
      })}
    </Grid>
  );
};
