import { compact, last } from 'lodash';
import React, { SyntheticEvent } from 'react';
import { QueryFieldProps } from '../QueryFieldInput';

import { Autocomplete, TextField } from '@mui/material';
import { MultiTextField, TextField as TextFieldType } from 'interfaces/genericFields';

const MultiText = ({ onChange, value, field }: QueryFieldProps<TextFieldType | MultiTextField>) => {
  const { filterType, dataKey } = field;
  const handleChange = (event: SyntheticEvent<Element, Event>, nextValue: string | string[]) => {
    if (last(nextValue) === '') {
      onChange(filterType, dataKey, []);
      return;
    }
    const effectiveSelection = compact(nextValue);
    onChange(filterType, dataKey, effectiveSelection);
  };

  return (
    <Autocomplete<string | number, boolean, boolean, boolean>
      multiple
      value={value}
      onChange={handleChange}
      size="small"
      id="limited-select"
      sx={{
        '& #limited-select': {
          whiteSpace: 'normal',
        },
      }}
      freeSolo={true}
      open={false}
      options={['']}
      renderInput={(params) => <TextField {...params} placeholder={value?.length ? '' : 'All'} />}
    />
  );
};

export default MultiText;
