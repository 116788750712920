import { CellRulePanelWithRules } from 'interfaces/cellRule';
import { CellType } from 'interfaces/cellType';
import { StainType } from 'interfaces/stainType';
import { filter, includes, map, uniq } from 'lodash';
import { useStainTypeOptions } from 'utils/queryHooks/uiConstantsHooks';
import { useCellTypeOptions } from 'utils/queryHooks/useCellTypeOptions';

export function useStainTypesUsedByPanel(panel: CellRulePanelWithRules): StainType[] {
  const { stainTypeOptions } = useStainTypeOptions();

  if (panel === undefined) return [];

  return filter(stainTypeOptions, (stainType) => includes(panel.stainTypeIds, stainType.id));
}

export function useCellTypesUsedByPanel(panel: CellRulePanelWithRules): CellType[] {
  const { data: cellTypes } = useCellTypeOptions();

  if (panel === undefined) return [];

  const usedCellTypeIds = uniq(map(panel.rules, 'cellTypeId'));

  return filter(cellTypes, (cellType) => includes(usedCellTypeIds, cellType.id));
}
