import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { Button, DialogTitle, IconButton, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { useUploadFilesContext } from 'components/UploadFilesContext';
import ErrorPopupButton from 'components/atoms/ErrorPopupButton/ErrorPopupButton';
import UploadManifest from 'components/atoms/UploadManifest/UploadManifest';
import formats from 'constants/manifestFormats';
import { generateStorage, useExistingStorage } from 'constants/uploadOptions';
import { map } from 'lodash';
import pLimit from 'p-limit';
import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { saveStorageToSecret } from 'services/S3Utils';
import { useS3Upload } from 'services/useS3Upload';
import { useCurrentLabId } from 'utils/useCurrentLab';
import * as yup from 'yup';
import GenerateCredentials from './GenerateCredentials';
import './UploadSlidesModal.scss';
import UseExistingStorageForm from './UseExistingStorageForm';

const HiddenToggle = styled('input')({
  appearance: 'none',
});

const UploadStudyForm = (props: Props): ReactElement => {
  const { onClose, studyId } = props;
  const [selectedTab, setSelectedTab] = useState(generateStorage.key);
  const [isSaveStorageFail, setIsSaveStorageFail] = useState(false);

  const { labId } = useCurrentLabId();

  const { getCredentialsAndStartUpload } = useS3Upload(labId, studyId);
  const { isPending, setIsPending } = useUploadFilesContext();

  useEffect(() => {
    if (isPending) {
      setIsPending(false);
    }
  }, []);

  const formProps = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: { isExternalStorage: false },
  });

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    watch,
    resetField,
    reset,
    formState: { errors },
    getValues,
    register,
    setValue,
  } = formProps;

  const onSaveExistingStorageFail = () => {
    setIsSaveStorageFail(true);
    setIsPending(false);
  };

  const onSaveExistingStorageSuccess = () => {
    setIsSaveStorageFail(false);
  };

  const formSubmit: SubmitHandler<IFormValues> = async (formValues) => {
    const files = formValues.slides;
    if (formValues.manifestFile) {
      files.push(formValues.manifestFile);
    }

    setIsPending(true);

    if (!formValues.isExternalStorage) {
      uploadFiles(files);
    } else {
      const storageData = {
        region: formValues.region,
        slideDirectory: formValues.slideDirectory,
        accessKeyId: formValues.accessKeyId,
        secretAccessKey: formValues.secretAccessKey,
      };
      saveStorageToSecret(labId, storageData, onSaveExistingStorageFail, onSaveExistingStorageSuccess);

      if (!isSaveStorageFail) {
        uploadFiles(files);
      }
    }

    onClose();
    reset();
  };

  const uploadFiles = async (files: File[]) => {
    const concurrencyLimit = pLimit(10);
    const uploadPromises = map(files, (file) => concurrencyLimit(() => getCredentialsAndStartUpload(file)));

    await Promise.all(uploadPromises);
  };

  const isUseExistingStorageSelected = selectedTab === useExistingStorage.key;

  return (
    <div className={classnames('upload-slides-form')} data-testid="upload-slides-form">
      <Box display="flex" flexDirection="column" className={classnames('upload-slides-modal-container')}>
        <div className={classnames('section upload-slide-modal-top')}>
          <Grid container spacing={4} justifyContent="space-between">
            <Grid item xs={6}>
              <DialogTitle variant="h1" sx={{ textTransform: 'uppercase', p: 1 }}>
                Upload Slides
              </DialogTitle>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose} sx={{ p: 0 }}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
          <div className={classnames('storage')}>
            <Typography
              variant="h6"
              alignItems="center"
              display="flex"
              data-testid="use-nucleai-storage"
              onClick={() => {
                setSelectedTab(generateStorage.key);
                setValue('isExternalStorage', false);
              }}
            >
              {formProps.watch('isExternalStorage') ? (
                <>
                  <ArrowBackIcon />
                  Back to Nucleai storage
                </>
              ) : (
                'Use Nucleai storage directory'
              )}
            </Typography>

            <Box display="flex" alignItems="center">
              {isUseExistingStorageSelected && isSaveStorageFail && (
                <div className={classnames('error-container')}>
                  <ErrorPopupButton content={'Something went wrong, please try again.'}></ErrorPopupButton>
                </div>
              )}
              <Inventory2OutlinedIcon color="primary" />

              <Controller
                control={control}
                name="isExternalStorage"
                render={({ field: { onChange } }) => (
                  <HiddenToggle
                    type="checkbox"
                    checked={isUseExistingStorageSelected}
                    {...register('isExternalStorage')}
                    onChange={onChange}
                    placeholder="Type Here"
                  />
                )}
              />

              <Typography
                variant="body1"
                data-testid="use-existing-storage"
                onClick={() => {
                  setSelectedTab(useExistingStorage.key);
                  setValue('isExternalStorage', true);
                  resetField('slides');
                }}
                color="primary"
                sx={{ marginLeft: '5px', fontSize: '18px' }}
              >
                Use my existing storage
              </Typography>
            </Box>
          </div>

          {selectedTab === generateStorage.key ? (
            <GenerateCredentials studyId={studyId} formProps={formProps} />
          ) : isUseExistingStorageSelected ? (
            <UseExistingStorageForm formProps={formProps} />
          ) : null}

          <UploadManifest
            getValues={getValues}
            clearErrors={clearErrors}
            setError={setError}
            resetField={resetField}
            manifestFileErrors={errors.manifestFile}
            control={control}
            manifestFile={watch('manifestFile')}
          />

          <div className={classnames('section upload-slides-footer')}>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              data-testid="done-button"
              disabled={isPending}
              onClick={handleSubmit(formSubmit)}
            >
              {isPending ? <span className="ellipsis">Uploading</span> : 'Upload'}
            </Button>
          </div>
        </div>
      </Box>
    </div>
  );
};

const schema = yup.object({
  slides: yup.array(),
  isExternalStorage: yup.boolean(),
  slideDirectory: yup.string().when('isExternalStorage', {
    is: true,
    then: yup.string().required(),
  }),
  region: yup.string().when('isExternalStorage', {
    is: true,
    then: yup.string().required(),
  }),
  accessKeyId: yup.string().when('isExternalStorage', {
    is: true,
    then: yup.string().required(),
  }),
  secretAccessKey: yup.string().when('isExternalStorage', {
    is: true,
    then: yup.string().required(),
  }),
  manifestFile: yup.mixed().test('fileType', 'File must be a csv/xlsx file', (value: File) => {
    return !value || formats.includes(value?.type);
  }),
});

interface Props {
  studyId: string;
  onClose: () => void;
}

export interface IFormValues {
  slides: File[];
  isExternalStorage: boolean;
  manifestFile: File;
  slideDirectory: string;
  region: string;
  accessKeyId: string;
  secretAccessKey: string;
}

export default UploadStudyForm;
