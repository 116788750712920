import classnames from 'classnames';
import React, { forwardRef } from 'react';
import './HeaderIconButton.scss';

interface Props {
  icon: React.ReactElement;
  label: string;
  disabled?: boolean;
  selected?: boolean;
  title?: string;
  onClick: () => void;
}

const HeaderIconButton = forwardRef<HTMLDivElement, Props>(
  ({ icon, label, disabled, selected, onClick, title }, ref) => (
    <div
      className={classnames('header-icon-button', { disabled, selected })}
      onClick={disabled ? undefined : onClick}
      ref={ref}
      title={title}
    >
      {icon}
      <span>{label}</span>
    </div>
  )
);

export default HeaderIconButton;
