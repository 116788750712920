import NETWORK_LABELS from 'constants/networkLabels';
import { setSystemError } from 'redux/actions/error';
import * as AT from 'redux/actionTypes';

const setLabs = (body) => ({
  type: AT.SET_LABS,
  payload: { labs: body },
});

// eslint-disable-next-line import/prefer-default-export
export const loadLabs = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'labs',
    label: NETWORK_LABELS.FETCH_LABS,
    onSuccess: (body, dispatch) => dispatch(setLabs(body)),
    onFailure: (error, dispatch) =>
      dispatch(setSystemError(`Failed to load labs: ${error.message || error.response.text}`, error.status)),
  },
});
