import { SearchIndex } from 'interfaces/searchIndex';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getSearchIndex = (labSearch: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<SearchIndex>({
    url: `studies/search_index${labSearch}`,
    method: 'GET',
    signal,
  });
};
