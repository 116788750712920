import { handleActions } from 'redux-actions';
import * as AT from 'redux/actionTypes';

const initialState = {
  all: [],
};

const labsReducer = handleActions(
  {
    [AT.SET_LABS]: (state, action) => ({
      all: action.payload.labs.sort((lab1, lab2) => (lab1.name > lab2.name ? 1 : -1)),
    }),
  },
  initialState
);

export default labsReducer;
