import { Chip, Grid, Skeleton, styled, Tooltip } from '@mui/material';
import CardMedia from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/CardMedia';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useSlideLabels } from 'components/StudiesDashboard/StudySettings/StudyLabel/useSlideLabels';
import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import { Slide, SlideQcLabel } from 'interfaces/slide';
import { flatMap, isEmpty, join, map, partition, reduce, times, uniq } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { getProcedureIdentifier, getStainStrWithNegPosControl } from 'utils/helpers';
import { usePermissions } from 'utils/usePermissions';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import { CardMode } from '.';
import ApprovalWidget from '../ApprovalWidget';
import BackofficeLink from './BackofficeLink';
import SlideCarousel from './Carousel/SlideCarousel';

export const StyledCard = styled(Card)(() => ({
  borderRadius: '15px',

  '&:hover': {
    boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.2)',
  },

  'a:hover': {
    color: 'inherit',
  },
}));

export const StyledCardContent = styled(CardContent)(
  ({
    theme: {
      palette: { mode, grey, text },
    },
  }) => ({
    color: mode === 'light' ? grey[700] : grey[200],
    '&:hover': {
      color: mode === 'light' ? text.primary : text.secondary,
    },
  })
);

export const SectionTitleTypography = styled(Typography)(() => ({
  fontSize: '12px',
}));

export const SectionContentTypography = styled(Typography)(() => ({
  fontSize: '14px',
}));

export const Section = styled(Box)(() => ({
  margin: '10px 0',
}));

interface Props {
  procedure: Procedure;
  galleryView?: boolean;
  mode?: CardMode;
  forceDisplayCheckbox?: boolean;
  selectable?: boolean;
}

export const NOT_AVAILABLE = 'N/A';

const stainCountMap = (stains: string[]) => {
  return reduce(
    stains,
    (record: Record<string, number>, cur) => {
      record[cur] = (record[cur] || 0) + 1;
      return record;
    },
    {}
  );
};

export const CaseCardContents: React.FunctionComponent<
  React.PropsWithChildren<
    Props & {
      currentVisibleSlide: Slide;
      setCurrentVisibleSlide?: React.Dispatch<React.SetStateAction<Slide>>;
    }
  >
> = ({
  children,
  procedure,
  galleryView,
  currentVisibleSlide,
  setCurrentVisibleSlide,
  mode,
  forceDisplayCheckbox,
  selectable,
}) => {
  const theme = useTheme();
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  const { hasPermission } = usePermissions();
  const canApproveCases = hasPermission(Permission.ApproveCases);
  const canViewInternalLabels = hasPermission(Permission.ViewInternalSlideLabels);

  const stains = flatMap(procedure.slides, (slide) => {
    const stainingType = slide.stainingType;
    const negativeControl = slide.negativeControl;
    const positiveControl = slide.positiveControl;

    return getStainStrWithNegPosControl(stainTypeIdToDisplayName(stainingType), negativeControl, positiveControl);
  });

  const stainCount = stainCountMap(stains);

  const stainsDisplayArray = map(stains, (stain) => {
    return stainCount[stain] > 1 ? `${stain} (${stainCount[stain]})` : stain;
  });

  const uniqueStains = uniq(stainsDisplayArray);

  const stainsDisplay = isLoadingStainTypeOptions ? 'Loading...' : join(uniqueStains, ', ');

  const allQcLabels: SlideQcLabel[] = currentVisibleSlide?.qcLabels;
  const tags = map(currentVisibleSlide?.slideTagAssignments, 'slideMetadataTag');
  const [labelsWithError, successfullyCalcLabels] = partition(allQcLabels, (qcLabel) => qcLabel?.errorCalculatingLabel);
  const showLabelsErrorChip = canViewInternalLabels && !isEmpty(labelsWithError);
  const [showLabelsErrorInfo, setShowLabelsErrorInfo] = useState(false);

  const { isLoading: isExternalLabelsLoading, getLabelDisplayName } = useSlideLabels();

  const skeletonChipSizes = useMemo(() => times(2, () => getRandomChipSize()), []);

  const numSlides = procedure?.slides?.length ?? 0;

  return (
    <>
      <CardMedia>
        <SlideCarousel
          caseData={procedure}
          slides={procedure.slides}
          onChangeSlide={numSlides > 1 && setCurrentVisibleSlide}
          galleryView={galleryView}
          selectable={selectable}
          forceDisplayCheckbox={forceDisplayCheckbox}
          mode={mode}
        >
          {children}
        </SlideCarousel>
      </CardMedia>

      {!galleryView && (
        <StyledCardContent>
          <Typography variant="h6" component="h2" sx={{ fontWeight: theme.typography.fontWeightBold }}>
            {getProcedureIdentifier(procedure)}
          </Typography>

          <Typography variant="h6" sx={{ fontSize: '14px' }}>
            {numSlides ? stainsDisplay : NOT_AVAILABLE}
          </Typography>

          <Section>
            <SectionTitleTypography>Date Processed</SectionTitleTypography>

            <SectionContentTypography>{moment(procedure?.createdAt).format('LL')}</SectionContentTypography>
          </Section>

          <Section>
            <SectionTitleTypography>Comments</SectionTitleTypography>

            <SectionContentTypography>{procedure?.comments || NOT_AVAILABLE}</SectionContentTypography>
          </Section>

          <Section>
            <SectionTitleTypography>Labels</SectionTitleTypography>

            {!isEmpty(successfullyCalcLabels) || showLabelsErrorChip ? (
              isExternalLabelsLoading ? (
                <Grid container direction="row" sx={{ width: '100%' }}>
                  {map(skeletonChipSizes, (size, i) => (
                    <Skeleton
                      key={i}
                      variant="rectangular"
                      width={size}
                      height={25}
                      sx={{ borderRadius: 5, marginRight: 1, marginTop: 1 }}
                    />
                  ))}
                </Grid>
              ) : (
                <>
                  {map(successfullyCalcLabels, (label: SlideQcLabel) => (
                    <Chip
                      key={label.text}
                      label={getLabelDisplayName(label.text)}
                      color="primary"
                      size="small"
                      sx={{ marginRight: 1, marginTop: 1 }}
                    />
                  ))}
                  {showLabelsErrorChip && (
                    <Tooltip title={showLabelsErrorInfo ? 'click to see less info' : 'click to see more info'}>
                      <Chip
                        onClick={(e) => {
                          e.preventDefault();
                          setShowLabelsErrorInfo((prevShowLabelsErrorInfo) => !prevShowLabelsErrorInfo);
                        }}
                        clickable={true}
                        label="!"
                        size="small"
                        sx={{ marginRight: 1, marginTop: 1 }}
                      />
                    </Tooltip>
                  )}
                  {showLabelsErrorInfo && (
                    <Typography variant="subtitle2">
                      {`Error calculating label${labelsWithError.length > 1 ? 's' : ''}: ${join(
                        map(labelsWithError, (label) => getLabelDisplayName(label.text)),
                        ', '
                      )}`}
                    </Typography>
                  )}
                </>
              )
            ) : (
              NOT_AVAILABLE
            )}
          </Section>

          {canViewInternalLabels && (
            <Section>
              <SectionTitleTypography>Tags</SectionTitleTypography>

              <SectionContentTypography>
                {tags?.length
                  ? map(tags, (tag) => (
                      <Chip
                        key={tag.id}
                        label={tag.tagValue}
                        color="secondary"
                        size="small"
                        sx={{ marginRight: 1, marginTop: 1 }}
                      />
                    ))
                  : NOT_AVAILABLE}
              </SectionContentTypography>
            </Section>
          )}

          {canApproveCases && (
            <>
              <Section>
                <SectionTitleTypography>Nucleai Slide ID</SectionTitleTypography>
                {currentVisibleSlide?.id ? (
                  <SectionContentTypography variant="h6">
                    <Grid container alignItems="center">
                      {currentVisibleSlide?.id}
                      <BackofficeLink slide_id={currentVisibleSlide?.id} />
                    </Grid>
                  </SectionContentTypography>
                ) : (
                  <SectionContentTypography>{NOT_AVAILABLE}</SectionContentTypography>
                )}
              </Section>

              <SectionContentTypography variant="h6">
                <ApprovalWidget procedure={procedure} />
              </SectionContentTypography>
            </>
          )}
        </StyledCardContent>
      )}
    </>
  );
};

function getRandomChipSize() {
  return Math.random() * 20 + 40;
}
