import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Typography } from '@mui/material';
import ModalWrapper from 'components/ModalWrapper';
import { AutomaticCondition } from 'interfaces/automaticCondition';
import { cloneDeep, map, pickBy, uniq } from 'lodash';
import React from 'react';
import { NewCell } from '..';
import NewCellsStain from './NewCellsStain';

export interface NewAreasModalProps {
  newCells: Record<string, NewCell>;
  setNewCells: React.Dispatch<React.SetStateAction<Record<string, NewCell>>>;
  onClose: () => void;
  stains: string[];
  getMappingFiltersMetadataForLogicalQuery: (stain: string) => any;
}

export const NewCellsModal: React.FC<React.PropsWithChildren<NewAreasModalProps>> = ({
  onClose,
  newCells,
  setNewCells,
  stains,
  getMappingFiltersMetadataForLogicalQuery,
}) => {
  const newCellsStains = map(newCells, (newCell) => newCell.stain);
  const allStains = uniq([...newCellsStains, ...stains]);

  const addNewCell = (newCellId: string, newCellName: string, newCellValue: AutomaticCondition, stain: string) => {
    setNewCells((prevNewCells) => {
      return {
        ...prevNewCells,
        [newCellId]: {
          name: newCellName,
          value: newCellValue,
          stain,
        },
      };
    });
  };

  const removeNewCell = (newCellId: string) => {
    setNewCells((prevNewCells) => {
      const prevNewCellsCopy = cloneDeep(prevNewCells);
      delete prevNewCellsCopy[newCellId];
      return prevNewCellsCopy;
    });
  };

  return (
    <ModalWrapper onClose={onClose} isOpen maxWidth="lg" fullWidth={true}>
      <Grid container direction="column">
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h2">New Cells</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          {map(allStains, (stain) => (
            <Grid item>
              <NewCellsStain
                newCellsCreated={pickBy(newCells, (newCell) => newCell.stain === stain)}
                key={stain}
                stain={stain}
                addNewCell={addNewCell}
                removeNewCell={removeNewCell}
                getMappingFiltersMetadataForLogicalQuery={getMappingFiltersMetadataForLogicalQuery}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};
