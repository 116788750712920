import { SlideSearchData } from 'interfaces/searchIndex';
import { Slide, SlidePrefetchData } from 'interfaces/slide';
import { SlideTagAssignment } from 'interfaces/slideTagAssignment';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export type SlideUpdate = Partial<Omit<Slide, 'id'>>;
export type SlideChange = SlideUpdate & Pick<Slide, 'id'>;

export const getSlideByIdKeys = ({
  slideId,
  encodedFilters,
  skipExtraData,
}: {
  slideId: string;
  encodedFilters: string;
  skipExtraData?: boolean;
}) => ['pendingSlide', slideId, encodedFilters, skipExtraData];

export const getSlideById = ({
  slideId,
  encodedFilters,
  skipExtraData,
  signal,
}: {
  slideId: string;
  encodedFilters: string;
  skipExtraData?: boolean;
  signal?: AbortSignal;
}) => {
  return apiRequestHandlerPromise<Slide>({
    url: `slides/${slideId}?${encodedFilters}${skipExtraData ? '&skipExtraData=1' : ''}`,
    method: 'GET',
    signal,
  });
};

export const updateSlide = ({
  slideChanges: { id: slideId, ...data },
  labId,
}: {
  slideChanges: SlideChange;
  labId: string;
  // Used for success message
  caseId?: number;
}) => {
  return apiRequestHandlerPromise<Slide>({
    url: `slides/${slideId}?${stringify({ labId })}`,
    method: 'POST',
    data: JSON.stringify(data),
  });
};

export const getSlideTags = (
  slideId: string,
  queryParams: {
    labId: string;
    studyId?: string;
    orchestrationId?: string;
  }
) => {
  return apiRequestHandlerPromise<SlideTagAssignment[]>({
    url: `slides/${slideId}/tags?${stringify(queryParams)}`,
    method: 'GET',
  });
};

export const addSlideTag = (
  slideId: string,
  tagId: string,
  labId: string,
  studyId?: string,
  orchestrationId?: string
) => {
  return apiRequestHandlerPromise({
    url: `slides/${slideId}/tags/${tagId}?lab_id=${labId}`,
    method: 'POST',
    data: studyId ? JSON.stringify({ studyId, orchestrationId }) : undefined,
  });
};

export const removeSlideTag = (
  slideId: string,
  tagId: string,
  labId: string,
  studyId?: string,
  orchestrationId?: string
) => {
  return apiRequestHandlerPromise({
    url: `slides/${slideId}/tags/${tagId}?lab_id=${labId}`,
    method: 'DELETE',
    data: studyId ? JSON.stringify({ studyId, orchestrationId }) : undefined,
  });
};

export const getSlides = (
  {
    queryParams,
    pending = true,
    ignoreLabId = false,
  }: {
    queryParams: string;
    pending?: boolean;
    ignoreLabId?: boolean;
  },
  signal?: AbortSignal
) => {
  return apiRequestHandlerPromise<Slide[]>({
    url: `slides?${queryParams}${queryParams ? '&' : ''}${stringify({ pending, ignoreLabId })}`,
    method: 'GET',
    signal,
  });
};

export const searchSlides = (searchQuery: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<{
    results: SlideSearchData[];
  }>({
    url: `slides/search?${stringify({ q: searchQuery })}`,
    method: 'GET',
    signal,
  });
};

export const getPendingSlidesCount = (queryParams: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<number>({
    url: `slides/count?${queryParams}${queryParams ? '&' : ''}pending=${true}`,
    method: 'GET',
    signal,
  });
};

export const getSlidesViewerNavigationPrefetchData = (queryParams: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<SlidePrefetchData[]>({
    url: `slides/viewer_navigation_prefetch_data?${queryParams}`,
    method: 'GET',
    signal,
  });
};
