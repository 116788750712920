import { CancerSubtype, CancerSubtypeTreeItem } from 'interfaces/cancerType';
import { compact, concat, filter, flatMap, forEach, includes, isEmpty, map, reduce, values } from 'lodash';

type SearchAndFilterResult = {
  processedItems: CancerSubtypeTreeItem[];
  matchingNodes: CancerSubtypeTreeItem[];
};

export const getAllTissueCodes = (items: CancerSubtypeTreeItem[]) => {
  const allCodes: string[] = [];
  const cb = (item: CancerSubtypeTreeItem) => {
    allCodes.push(item.code);
    if (item.children) forEach(item.children, cb);
  };
  forEach(items, cb);

  return allCodes;
};

export const getTreeDataAsArray = (items: CancerSubtype[]): CancerSubtypeTreeItem[] => {
  return map(items, (item) => {
    let children = undefined;
    if (item.children) {
      const childrenArr = map(values(item.children), (child) => child);
      children = getTreeDataAsArray(childrenArr);
    }
    return {
      ...item,
      children,
    };
  });
};

export const searchAndFilterTree = (
  items: CancerSubtypeTreeItem[],
  searchStr: string,
  byExactCode: boolean = false
): SearchAndFilterResult => {
  const matchingNodes: CancerSubtypeTreeItem[] = [];
  const processNode = (node: CancerSubtypeTreeItem): CancerSubtypeTreeItem | null => {
    const innerMatchingChildren: CancerSubtypeTreeItem[] = node?.children
      ? filter(flatMap(node?.children, processNode), (child) => child !== null)
      : [];

    const condition = byExactCode
      ? node?.code?.toLowerCase() === searchStr
      : !isEmpty(innerMatchingChildren) ||
        includes(node?.name?.toLowerCase(), searchStr) ||
        includes(node?.code?.toLowerCase(), searchStr);

    if (condition) {
      matchingNodes.push(node);
      return {
        ...node,
        children: innerMatchingChildren,
      };
    } else {
      return null;
    }
  };

  const processedItems: CancerSubtypeTreeItem[] = filter(flatMap(items, processNode), (item) => item !== null);

  return { processedItems, matchingNodes };
};

export const flattenItems = (items: CancerSubtypeTreeItem[]) => {
  return reduce(
    items,
    (acc, x) => {
      acc = concat(acc, x);
      if (x?.children) {
        acc = concat(acc, flattenItems(x.children));
        x.children = [];
      }
      return acc;
    },
    []
  );
};

export const getOptionCodes = (items: CancerSubtypeTreeItem[]) => {
  const options: string[] = [];
  const cb = (item: CancerSubtypeTreeItem) => {
    options.push(item.code);
    if (!isEmpty(item.children)) forEach(item.children, cb);
  };
  forEach(items, cb);
  return options;
};

export const getExpandedItems = (matchingItems: CancerSubtypeTreeItem[], items: CancerSubtypeTreeItem[]): string[] => {
  const expandedItems: string[] = [];
  forEach(compact(matchingItems), (item) => {
    let parent = item.parent;
    while (parent && !includes(expandedItems, parent)) {
      expandedItems.push(parent);
      parent = getItemByCode(items, parent)?.parent;
    }
    if (!parent) {
      expandedItems.push(item.code);
    }
  });
  return expandedItems;
};

export const getItemByCode = (items: CancerSubtypeTreeItem[], itemId: string): CancerSubtypeTreeItem | undefined => {
  for (const item of items) {
    if (item?.code === itemId) {
      return item;
    }
    if (item?.children) {
      const childItem = getItemByCode(item.children, itemId);
      if (childItem) {
        return childItem;
      }
    }
  }
  return null;
};

export const getItemPath = (item: CancerSubtypeTreeItem, items: CancerSubtypeTreeItem[]): string[] => {
  const path: string[] = item ? [item.code] : [];
  let parent = item?.parent;
  while (parent) {
    path.unshift(parent);
    parent = getItemByCode(items, parent)?.parent;
  }
  return path;
};
