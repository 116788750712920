import { Procedure } from 'interfaces/procedure';
import { DisplayedField } from '../../genericFields';
import { getUpdatePathForProcedureClinicalDataField } from './helpers';

export const ecogScoreField: DisplayedField<Procedure, number> = {
  // TODO: set `cellEditType: 'number',` if/when we allow clinical data editing
  filterType: 'range',
  dataCategory: 'clinical',
  dataKey: 'ecog_score',
  label: 'ECOG Score',
  min: 0,
  max: 5,
  step: 1,
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.ecogScore,
  updatePath: getUpdatePathForProcedureClinicalDataField('ecogScore'),
};

export const tnmStageTField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'select',` if/when we allow clinical data editing
  filterType: 'checkbox-group',
  dataCategory: 'clinical',
  dataKey: 'tnm_stage_t',
  label: 'TNM stage T',
  enumType: 'tnmStageT',
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.tnmStageT,
  updatePath: getUpdatePathForProcedureClinicalDataField('tnmStageT'),
};

export const tnmStageMField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'number',` if/when we allow clinical data editing
  filterType: 'range',
  dataCategory: 'clinical',
  dataKey: 'tnm_stage_m',
  label: 'TNM stage M',
  min: 0,
  max: 1,
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.tnmStageM,
  updatePath: getUpdatePathForProcedureClinicalDataField('tnmStageM'),
};

export const tnmStageNField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'select',` if/when we allow clinical data editing
  filterType: 'checkbox-group',
  dataCategory: 'clinical',
  dataKey: 'tnm_stage_n',
  label: 'TNM stage N',
  enumType: 'tnmStageN',
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.tnmStageN,
  updatePath: getUpdatePathForProcedureClinicalDataField('tnmStageN'),
};

export const dateOfDiagnosisField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'date',` if/when we allow clinical data editing
  filterType: 'date-range',
  dataCategory: 'clinical',
  dataKey: 'date_of_diagnosis',
  label: 'Date of Diagnosis',
  isFullDate: true,
  views: ['day', 'month', 'year'],
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.dateOfDiagnosis,
  updatePath: getUpdatePathForProcedureClinicalDataField('dateOfDiagnosis'),
};

// TODO: change when treatmentType field is defined
export const treatmentTypeField: DisplayedField<Procedure, any> = {
  // TODO: set `cellEditType: 'select',` if/when we allow clinical data editing
  filterType: 'multiSelect',
  dataCategory: 'clinical',
  dataKey: 'treatment_type',
  label: 'Treatment Type',
  enumType: 'treatmentType',
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.treatmentType,
  updatePath: getUpdatePathForProcedureClinicalDataField('treatmentType'),
};

export const treatmentLineField: DisplayedField<Procedure, string> = {
  filterType: 'range',
  dataCategory: 'clinical',
  dataKey: 'treatment_line',
  label: 'Line of Treatment',
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.treatmentLine,
  updatePath: getUpdatePathForProcedureClinicalDataField('treatmentLine'),
  min: 0,
};

export const treatmentStartDateField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'date',` if/when we allow clinical data editing
  filterType: 'date-range',
  dataCategory: 'clinical',
  dataKey: 'treatment_start_date',
  label: 'Start of Treatment',
  isFullDate: true,
  views: ['day', 'month', 'year'],
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.treatmentStartDate,
  updatePath: getUpdatePathForProcedureClinicalDataField('treatmentStartDate'),
};

export const treatmentEndDateField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'date',` if/when we allow clinical data editing
  filterType: 'date-range',
  dataCategory: 'clinical',
  dataKey: 'treatment_end_date',
  label: 'End of Treatment',
  isFullDate: true,
  views: ['day', 'month', 'year'],
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.treatmentEndDate,
  updatePath: getUpdatePathForProcedureClinicalDataField('treatmentEndDate'),
};

export const dateOfLastFollowupField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'date',` if/when we allow clinical data editing
  filterType: 'date-range',
  dataCategory: 'clinical',
  dataKey: 'date_of_last_followup',
  label: 'Last Followup',
  isFullDate: true,
  views: ['day', 'month', 'year'],
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.dateOfLastFollowup,
  updatePath: getUpdatePathForProcedureClinicalDataField('dateOfLastFollowup'),
};

export default [
  ecogScoreField,
  tnmStageTField,
  tnmStageNField,
  tnmStageMField,
  dateOfDiagnosisField,
  treatmentTypeField,
  treatmentLineField,
  treatmentStartDateField,
  treatmentEndDateField,
  dateOfLastFollowupField,
];
