import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { AppBar, Grid, IconButton, ListItem, useTheme } from '@mui/material';
import AWS from 'aws-sdk';
import { filter, includes, isEmpty, keys, map, pickBy } from 'lodash';
import React, { useEffect } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useUploadFilesContext } from '../UploadFilesContext';
import UploadSummaryItem from './UploadSummaryItem';

interface UploadSummaryListProps {
  onClose: () => void;
}

const UploadSummaryList: React.FunctionComponent<React.PropsWithChildren<UploadSummaryListProps>> = ({ onClose }) => {
  const { fileUploads } = useUploadFilesContext();

  const [expanded, setExpanded] = React.useState(true);
  const [completed, setCompleted] = React.useState(false);

  const uploadIds = keys(fileUploads);
  const theme = useTheme();

  const cancelledUploads = filter(
    uploadIds,
    (upload) => (fileUploads[upload]?.error as AWS.AWSError)?.code === 'RequestAbortedError'
  );
  const failedUploads = filter(
    uploadIds,
    (upload) => Boolean(fileUploads[upload].error) && !includes(cancelledUploads, upload)
  );

  const completedUploads = filter(
    uploadIds,
    (upload) =>
      fileUploads[upload].progress === 100 && !includes(cancelledUploads, upload) && !includes(failedUploads, upload)
  );

  const currentlyUploadingOrDone = pickBy(fileUploads, (upload) => upload.progress || upload.error);

  const handleExpandList = () => {
    setExpanded(!expanded);
  };

  let titleText = '';
  const numUploadIds = uploadIds?.length ?? 0;
  const numCompletedUploads = completedUploads?.length ?? 0;
  const numCancelledUploads = cancelledUploads?.length ?? 0;
  const numFailedUploads = failedUploads?.length ?? 0;
  if (!isEmpty(uploadIds) && isEmpty(currentlyUploadingOrDone)) {
    titleText = `${numUploadIds} files pending...`;
  } else if (!completed) {
    titleText = `${numUploadIds - numCompletedUploads - numCancelledUploads} file${
      numUploadIds !== 1 ? 'small' : ''
    } uploading `;
  } else {
    titleText = `${numUploadIds} upload${numCompletedUploads !== 1 ? 'small' : ''} complete `;
  }

  if (!isEmpty(cancelledUploads) || !isEmpty(failedUploads)) {
    titleText += ` (${!isEmpty(cancelledUploads) ? numCancelledUploads + ' cancelled' : ''}${
      !isEmpty(cancelledUploads) && !isEmpty(failedUploads) ? ', ' : ''
    }${!isEmpty(failedUploads) ? numFailedUploads + ' failed' : ''})`;
  }

  useEffect(() => {
    if (numCompletedUploads + numCancelledUploads === numUploadIds) {
      setCompleted(true);
    }
  }, [completedUploads, cancelledUploads]);

  const actionButtonColor = theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.grey[50];

  return (
    <>
      <AppBar
        elevation={0}
        position="static"
        sx={{
          '&.MuiPaper-root': {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[900] : theme.palette.primary.main,
            color: theme.palette.grey[50],
            pl: 2,
            py: 1,
            borderRadius: expanded && !isEmpty(fileUploads) ? '4px 4px 0 0' : '4px',
          },
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>{titleText}</Grid>

          <Grid item>
            <Grid container>
              <Grid item>
                <IconButton onClick={handleExpandList} sx={{ color: actionButtonColor }}>
                  {expanded ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowUpIcon fontSize="small" />}
                </IconButton>
              </Grid>
              {completed && (
                <Grid item>
                  <IconButton onClick={onClose} sx={{ color: actionButtonColor }}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </AppBar>

      {expanded && !isEmpty(currentlyUploadingOrDone) && (
        <Virtuoso
          style={{ height: '300px', minWidth: '400px' }}
          data={map(fileUploads, (upload) => upload)}
          itemContent={(index, upload) => (
            <ListItem
              key={upload.id}
              sx={{
                padding: 0,
                backgroundColor:
                  theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.grey[50],
                color: theme.palette.mode === 'light' ? theme.palette.text.primary : theme.palette.grey[900],
                '&:last-child': { borderRadius: ' 0 0 4px 4px' },
              }}
            >
              <Grid container direction="column">
                <Grid item sx={{ padding: 2 }}>
                  <UploadSummaryItem upload={upload} />
                </Grid>
              </Grid>
            </ListItem>
          )}
        />
      )}
    </>
  );
};

export default UploadSummaryList;
