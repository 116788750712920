import React, { useTransition } from 'react';
import CheckboxGroup from './QueryToolFilters/CheckboxGroup';
import DateRange from './QueryToolFilters/DateRange';
import MultiSelect from './QueryToolFilters/MultiSelect';
import MultiText from './QueryToolFilters/MultiText';
import Range from './QueryToolFilters/Range';
import SingleSelect from './QueryToolFilters/SingleSelect';

import {
  Field,
  FieldType,
  FieldWitCheckboxGroupFilter,
  FieldWithMultiselectFilter,
  FieldWithSelectFilter,
} from 'interfaces/genericFields';

export type OnChange<T extends FieldType> = (type: T, key: string, data: any) => void;
export interface QueryFieldProps<T extends Field> {
  field: T;
  onChange?: OnChange<T['filterType']>;
  value?: any;
}

const QueryFieldInput = <T extends Field>(
  props: QueryFieldProps<T>
): ReturnType<React.FunctionComponent<React.PropsWithChildren<QueryFieldProps<T>>>> => {
  const [, startTransition] = useTransition();

  const [inputValue, setInputValue] = React.useState<any>(null);

  const value = inputValue ?? props.value;

  const onChange: OnChange<T['filterType']> = (type, key, newValue) => {
    setInputValue(newValue);
    startTransition(() => {
      if (props.onChange) {
        props.onChange(type, key, newValue);
      }
    });
  };

  const type = props.field.filterType;
  switch (props.field.filterType) {
    case 'range':
      return <Range value={value} onChange={onChange} field={props.field} />;
    case 'date-range':
      return <DateRange value={value} onChange={onChange} field={props.field} />;
    case 'multiText':
      return <MultiText value={value} onChange={onChange} field={props.field} />;
    case 'multiSelect':
      return <MultiSelect value={value} onChange={onChange} field={props.field as FieldWithMultiselectFilter<any>} />;
    case 'select':
      return <SingleSelect value={value} onChange={onChange} field={props.field as FieldWithSelectFilter<any>} />;
    case 'checkbox-group':
      return (
        <CheckboxGroup value={value} onChange={onChange} field={props.field as FieldWitCheckboxGroupFilter<any>} />
      );
    default:
      console.error(`Unknown field type: ${type}`);
      return null;
  }
};

export default QueryFieldInput;
