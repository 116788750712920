import AWS from 'aws-sdk';
import { every, isEmpty, keys, noop, values } from 'lodash';
import React, { Dispatch, FC, ReactNode, SetStateAction, createContext, useEffect, useState } from 'react';

export interface Upload {
  file: File;
  id: string;
  progress?: number;
  error?: Error;
  managedUpload?: AWS.S3.ManagedUpload;
}

export type FileUploads = Record<string, Upload>;

interface UploadFilesContextState {
  fileUploads: FileUploads;
  setFileUploads: Dispatch<SetStateAction<FileUploads>>;
  setShowUploadWidget: Dispatch<SetStateAction<boolean>>;
  showUploadWidget: boolean;
  isPending: boolean;
  setIsPending: Dispatch<SetStateAction<boolean>>;
  uploadsCompleted: boolean;
}

const UploadFilesContext = createContext<UploadFilesContextState>({
  fileUploads: {},
  setFileUploads: noop,
  setShowUploadWidget: noop,
  showUploadWidget: true,
  isPending: false,
  setIsPending: noop,
  uploadsCompleted: false,
});

interface Props {
  children?: ReactNode;
}

export const UploadFilesContextProvider: FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const [showUploadWidget, setShowUploadWidget] = useState<boolean>(true);
  const [fileUploads, setFileUploads] = useState<FileUploads>({});
  const [isPending, setIsPending] = useState<boolean>(false);

  const uploadsCompleted =
    !isEmpty(keys(fileUploads)) &&
    every(values(fileUploads), (upload) => upload.progress === 100 || upload.error !== undefined);

  useEffect(() => {
    if (uploadsCompleted) {
      setIsPending(false);
    }
  }, [uploadsCompleted]);

  return (
    <UploadFilesContext.Provider
      value={{
        isPending,
        setIsPending,
        fileUploads,
        setFileUploads,
        showUploadWidget,
        setShowUploadWidget,
        uploadsCompleted,
      }}
    >
      {children}
    </UploadFilesContext.Provider>
  );
};

export const useUploadFilesContext = () => React.useContext(UploadFilesContext);
