import { Grid } from '@mui/material';
import { useTheme } from '@mui/system';
import Loader from 'components/Loader';
import { Accession } from 'interfaces/accession';
import { map } from 'lodash';
import React, { FunctionComponent } from 'react';
import AccessionCard from './AccessionCard';

interface Props {
  accessions: Accession[];
  isLoading: boolean;
  layout: 'small' | 'medium' | 'large';
}

const AccessionCards: FunctionComponent<React.PropsWithChildren<Props>> = ({ accessions, isLoading, layout }) => {
  const theme = useTheme();

  return isLoading ? (
    <Grid
      container
      className="dashboard-page-body"
      justifyContent="center"
      sx={{ flex: '1 1 auto', backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[50] }}
    >
      <Grid item>
        <Loader />
      </Grid>
    </Grid>
  ) : (
    <Grid item container spacing={3}>
      {map(accessions, (accession) => {
        return (
          <Grid
            item
            xs={12}
            sm={12}
            md={layout === 'small' ? 12 : layout === 'medium' ? 6 : 4}
            lg={layout === 'small' ? 4 : 3}
            key={accession.id}
          >
            <AccessionCard accession={accession} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AccessionCards;
