import { useQuery } from '@tanstack/react-query';
import { getCellRules } from 'api/cellRules';
import { CellRuleStudyId } from 'interfaces/cellRule';

export const cellRuleQueryKey = ['cellRules'];

const useCellRules = (panelId: string, studyId: CellRuleStudyId, { enabled = true }: { enabled?: boolean } = {}) => {
  const query = useQuery({
    queryKey: [...cellRuleQueryKey, panelId, studyId],
    queryFn: () => getCellRules(panelId, studyId),
    enabled: Boolean(panelId) && enabled,
  });

  return query;
};

export default useCellRules;
