import { cloneDeep, forEach, includes, isArray, isPlainObject, keys, mergeWith, some } from 'lodash';
import { DeepPartial } from 'redux';

/**
 * @description: Given an object and a deep partial of that object representing changes to be made,
 * apply the changes to the object. undefined values will be saved as nulls
 * @param {T} object - The object to be changed
 * @param {DeepPartial<T>} changes - The changes to be made to the object
 * @returns {T} - The object with the changes merged in
 * @example
 * const obj = { a: 1, b: 2, c: { d: 3, e: 6 } };
 * const changes = { a: 2, c: { d: 4, e: undefined } };
 * const newObj = applyObjectChanges(obj, changes);
 * // newObj = { a: 2, b: 2, c: { d: 4, e: null } };
 */
export const applyObjectChanges = <T extends object>(object: T, changes: DeepPartial<T>): T =>
  mergeWith(cloneDeep(object), cloneDeep(changes), (baseValue, changeValue, key, baseObject, changeObject) => {
    // If the change value is an array, we may need to merge it into the base value element-wise
    if (isArray(changeValue)) {
      // If the base value is an array, merge the changes into the array
      if (isArray(baseValue) && some(baseValue, isPlainObject)) {
        const mergeRes = cloneDeep(baseValue);
        forEach(mergeRes, (baseValueItem, index) => {
          const changeValueItem = changeValue.length > index ? changeValue[index] : null;
          if (isPlainObject(changeValueItem)) {
            mergeRes[index] = applyObjectChanges(baseValueItem, changeValueItem);
          } else if (changeValueItem === null) {
            // If the change value is null, mark this value for deletion
            mergeRes[index] = null;
          } else if (changeValueItem !== undefined) {
            // If the change value is not undefined, replace the value
            mergeRes[index] = changeValueItem;
          }
        });
        return mergeRes;
      }
      return changeValue;
    } else if (changeValue === undefined) {
      const changeKeys = keys(changeObject);
      if (includes(changeKeys, key)) {
        return null;
      }
    }
  }) as T;
