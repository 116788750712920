import { Checkbox, FormControlLabel } from '@mui/material';
import { CheckboxField } from 'interfaces/reviewForm';
import React, { FunctionComponent } from 'react';

interface FormCheckboxFieldProps {
  field: CheckboxField;
  value: boolean;
  onChange: (value: boolean) => void;
}

const FormCheckboxField: FunctionComponent<FormCheckboxFieldProps> = ({ field, value, onChange }) => {
  return (
    <FormControlLabel
      label={field.label}
      control={
        <Checkbox
          defaultChecked={field.options?.default}
          checked={Boolean(value)}
          onChange={(e) => onChange(e.target.checked)}
        />
      }
    />
  );
};

export default FormCheckboxField;
