import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';

import { AnnotationExtensionStates } from 'interfaces/annotation';

export interface AssignmentState {
  route: string;
  label: string;
  icon?: React.ElementType;
  stateName?: string;
}

export const ASSIGNMENT_STATES: { [key in AnnotationExtensionStates]: AssignmentState } = {
  [AnnotationExtensionStates.Annotating]: {
    route: 'assignments',
    label: 'Annotation Assignments',
    icon: EditIcon,
    stateName: 'Annotating',
  },
  [AnnotationExtensionStates.QA]: {
    route: 'qa_assignments',
    label: 'QA Assignments',
    icon: SearchIcon,
    stateName: 'QA',
  },
  [AnnotationExtensionStates.Done]: {
    route: 'finished_assignments',
    label: 'Finished Assignments',
    icon: CheckIcon,
    stateName: 'Done',
  },
  [AnnotationExtensionStates.AllAssignment]: {
    route: 'all_assignments',
    label: 'All Assignments',
  },
  [AnnotationExtensionStates.None]: {
    route: 'no_assignments',
    icon: CircleIcon,
    label: 'No Assignment',
    stateName: 'No Assignment',
  },

  [AnnotationExtensionStates.StarredAssignment]: {
    route: 'starred_assignments',
    label: 'Highlighted Assignments',
  },
} as const;
