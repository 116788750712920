import { useFilteredCaseIds } from 'components/Procedure/useFilteredCaseIds';
import { findIndex, first } from 'lodash';

export const useCaseIndexesInFilter = (currentCaseId: number, studyId?: string): CaseNavigationData => {
  const { isInitialLoading: isInitialLoadingCaseIds, data: caseIds } = useFilteredCaseIds(currentCaseId, studyId);

  let nextCaseId = null;
  let previousCaseId = null;
  const currentCaseIndex = findIndex(caseIds, (id) => id === currentCaseId);
  const totalCases = caseIds?.length ?? 0;

  if (currentCaseIndex !== -1 && totalCases > 0) {
    nextCaseId = currentCaseIndex + 1 >= totalCases ? first(caseIds) : caseIds[currentCaseIndex + 1];
    previousCaseId = currentCaseIndex - 1 < 0 ? caseIds[totalCases - 1] : caseIds[currentCaseIndex - 1];
  }

  return {
    isInitialLoadingCaseIds,
    caseIds,
    nextCaseId,
    previousCaseId,
    currentCaseIndex,
    totalCases,
  };
};

export interface CaseNavigationData {
  isInitialLoadingCaseIds: boolean;
  caseIds: number[];
  nextCaseId: number | null;
  previousCaseId: number | null;
  currentCaseIndex: number;
  totalCases: number;
}
