import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { Slide } from 'interfaces/slide';
import { getSlideMacroUrl } from 'utils/helpers';
import { useFormatExtensions } from 'utils/queryHooks/uiConstantsHooks';

interface Props extends React.PropsWithChildren<{}> {
  slide: Slide;
}

const Macro: React.FC<Props> = ({ slide }) => {
  const { formatExtensions, isLoadingFormatExtensions } = useFormatExtensions();

  const { data: slideMacro } = useQuery(['slideMacro', [slide]], {
    queryFn: async ({ signal }): Promise<string> => {
      const response = await fetch(getSlideMacroUrl(slide, formatExtensions), { signal });
      return response.status === 200 ? URL.createObjectURL(await response.blob()) : null;
    },
    enabled: Boolean(slide) && !isLoadingFormatExtensions && Boolean(formatExtensions),
  });

  return (
    Boolean(slideMacro) && (
      <Grid item>
        <img src={slideMacro} alt="macro" />
      </Grid>
    )
  );
};

export default Macro;
