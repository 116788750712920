import { Close } from '@mui/icons-material';
import { IconButton, TextField, Toolbar } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

interface Props {
  setFilter: (filter: string) => void;
}

const AssignmentsSearchBox: React.FunctionComponent<Props> = ({ setFilter }) => {
  const [value, setValue] = useState('');
  const inputRef = useRef<HTMLInputElement>();
  const isAutocompleted = value && value.length > 2;

  useEffect(() => {
    if (isAutocompleted) {
      setFilter(value);
    } else setFilter('');
  }, [isAutocompleted, value]);

  return (
    <Toolbar variant="dense" disableGutters>
      <TextField
        ref={inputRef}
        data-test-class="assignments-search-box"
        type="search"
        placeholder="Enter slide id, assignment name or assignment id"
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      />

      <IconButton
        type="button"
        data-test-class="assignments-search-box-close"
        className="assignments-search-box-close"
        onClick={() => {
          setValue('');
          inputRef.current.focus();
          setFilter('');
        }}
      >
        <Close />
      </IconButton>
    </Toolbar>
  );
};

export default AssignmentsSearchBox;
