import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LabelIcon from '@mui/icons-material/Label';
import ViewIcon from '@mui/icons-material/Visibility';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { Permission } from 'interfaces/permissionOption';
import { QualityControlLabel } from 'interfaces/qualityControlLabels';
import { Study } from 'interfaces/study';
import React, { FunctionComponent, useState } from 'react';
import { usePermissions } from 'utils/usePermissions';
import LabelBuilder from './LabelBuilder';
import { useSlideLabels } from './useSlideLabels';

interface StudyLabelProps {
  study: Study;
  label: QualityControlLabel;
  removeLabel: () => void;
  isNew: boolean;
  onUpdateLabel: (updatedLabel: QualityControlLabel) => void;
}

const StudyLabel: FunctionComponent<React.PropsWithChildren<StudyLabelProps>> = ({
  label,
  isNew,
  study,
  removeLabel,
  onUpdateLabel,
}) => {
  const { hasPermission } = usePermissions();
  const [expanded, setExpanded] = useState(isNew);
  const { getLabelDisplayName } = useSlideLabels();
  const filterLabelDisplayName: string = getLabelDisplayName(label.filterLabel);
  const rejectLabelDisplayName: string = getLabelDisplayName(label.rejectLabel);

  const editable = hasPermission(Permission.EditQcQaLabels);

  return (
    <ListItem key={label.id} sx={{ width: '100%' }}>
      <Accordion key={label.id} onChange={() => setExpanded(!expanded)} expanded={expanded} sx={{ width: '100%' }}>
        <AccordionSummary>
          <ListItemIcon sx={{ alignItems: 'center' }}>
            <LabelIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography>
              {filterLabelDisplayName} / {rejectLabelDisplayName}
            </Typography>

            <Typography variant="subtitle2">ID: {label.id}</Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <IconButton onClick={() => setExpanded(!expanded)}>{editable ? <EditIcon /> : <ViewIcon />}</IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                removeLabel();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </AccordionSummary>

        <AccordionDetails>
          <LabelBuilder
            editable={editable}
            onUpdateLabel={onUpdateLabel}
            label={label}
            study={study}
            isNew={isNew}
            onClose={() => {
              setExpanded(false);
            }}
          />
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
};

export default StudyLabel;
