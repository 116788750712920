import { createSlice } from '@reduxjs/toolkit';
import CohortWithQuery from 'interfaces/cohort_old';

interface ChartState {
  cohorts: CohortWithQuery[];
}
// originally I had more than just a cohorts field so this was warranted but now this can be potentially removed
// or otherwise used for other slices too
const propertyReducer =
  <T extends keyof ChartState>(name: T) =>
  (
    state: ChartState,
    {
      payload,
    }: {
      payload: ChartState[T];
    }
  ) => {
    state[name] = payload;
  };

const initialState: ChartState = {
  cohorts: [],
};

// uses immer to create immutable state
export const chartSlice = createSlice({
  name: 'chart_data',
  initialState,
  reducers: {
    setCohorts: propertyReducer('cohorts'),
    addCohort: (state, { payload: cohort }) => {
      if (!state.cohorts.find((c) => c.id === cohort.id)) state.cohorts.push(cohort);
    },
    addCohorts: (state, { payload: cohorts }) => {
      cohorts.forEach((cohort: CohortWithQuery) => {
        if (!state.cohorts.find((c) => c.id === cohort.id)) state.cohorts.push(cohort);
      });
    },
    updateCohort: (state, { payload: cohort }) => {
      const index = state.cohorts.findIndex((c) => c.id === cohort.id);
      if (index > -1) state.cohorts[index] = cohort;
    },
  },
});
export const { setCohorts, addCohort, updateCohort, addCohorts } = chartSlice.actions;

export default chartSlice.reducer;
