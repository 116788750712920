import { findAvailableForm } from 'components/ReviewPanel/form.util';
import { FormType, ResponseContext, formTypes } from 'interfaces/reviewForm';
import { includes } from 'lodash';
import { StringParam, useQueryParam } from 'use-query-params';
import { useForms } from './useForm';

export const useCurrentForm = (
  formContext: ResponseContext,
  {
    enabled = true,
  }: {
    enabled?: boolean;
  } = {}
) => {
  const [reviewTab] = useQueryParam('reviewTab', StringParam);

  const isFormTab = includes(formTypes, reviewTab);

  const { data: forms, isSuccess } = useForms(formContext, {
    enabled: enabled && isFormTab,
  });

  const currentForm = isFormTab && findAvailableForm(forms, reviewTab as FormType);

  const isFormSlideAgnostic = currentForm && !currentForm.isSlideSpecific;

  return { currentForm, isSuccess, isFormSlideAgnostic, isFormTab };
};
