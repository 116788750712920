import { FormControlProps, Grid, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { includes, isEmpty, map } from 'lodash';
import React, { RefObject } from 'react';

export interface DropdownProps extends FormControlProps {
  value: any;
  options: DropdownOption[];
  onOptionSelected(value: string): void;
  placeholder?: string;
  label?: string;
  inputRef?: RefObject<HTMLSelectElement>;
  dataTestId?: string;
  disabled?: boolean;
  multiple?: boolean;
  addSeparatorToOptions?: boolean;
}

const Dropdown = ({
  options,
  value,
  onOptionSelected,
  placeholder,
  label = '',
  inputRef,
  dataTestId,
  size,
  variant,
  addSeparatorToOptions,
  disabled = false,
  multiple = false,
}: DropdownProps) => {
  const defaultValue = includes(map(options, 'value'), value) ? value : multiple ? [] : '';

  const handleOptionSelected = (event: SelectChangeEvent) => {
    onOptionSelected(event.target.value);
  };

  return (
    <Select
      multiple={multiple}
      disabled={disabled}
      size={size}
      variant={variant}
      inputRef={inputRef}
      label={label}
      defaultValue={defaultValue}
      onChange={handleOptionSelected}
      value={value}
      placeholder={placeholder}
      fullWidth
      data-testid={dataTestId}
    >
      {!isEmpty(options) &&
        map(options, (option, idx) => {
          return (
            <MenuItem
              key={label + option.value}
              value={option.value}
              sx={idx > 0 && addSeparatorToOptions ? { borderTop: 1 } : undefined}
            >
              {option.details ? (
                <Grid container flexDirection="column">
                  <Typography variant="body1" component="span" mr={2}>
                    {option.text}
                  </Typography>
                  <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                    {option.details}
                  </Typography>
                </Grid>
              ) : (
                option.text
              )}
            </MenuItem>
          );
        })}
    </Select>
  );
};

export interface DropdownOption {
  type?: string;
  value: any;
  text: string | null;
  details?: string;
}

export default Dropdown;
