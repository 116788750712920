import { signal } from '@preact/signals-react';
import { Slide } from 'interfaces/slide';

export const MICROMETERS_PER_INCH = 25400;

export const zoomFactor = signal<number>(1);

export const updateZoomFactorFromSlide = (slide: Slide, deckGLViewer?: boolean) => {
  if (!slide.maxResolution) {
    console.error('Could not calculate zoom factor, slide.maxResolution is 0');
    zoomFactor.value = 1;
    return;
  }
  const ppiX = document.getElementById('ppi')?.offsetWidth ?? 0;
  if (ppiX === 0) {
    console.error('Could not calculate zoom factor, ppiX is 0');
    return;
  }
  const slideViewport = deckGLViewer
    ? document.querySelector('div.deckgl-slides-viewer canvas')
    : // Each openseadragon container has 2 canvas elements, both has the same clientWidth
      // which is how much space it takes on the screen, or in other terms the viewport.
      // In single slide view, there are 3 canvas elements in the following order:
      // navigator canvas (present regardless if it's hidden or displayed), and 2 for the slide itself.
      // In comparison view the navigator canvas is not present (this component isn't rendered) and so there are 4
      // canvas elements, 2 for each of the slides.
      // We always pick the canvas with index 1 as this will guarantee us in all cases that we are not using the navigator canvas.
      document.querySelectorAll('div.slides-viewer canvas')[1];

  if (!slideViewport) {
    console.debug('Could not calculate zoom factor, slideViewport was not found');
    return;
  }
  const slideViewportWidth = slideViewport.clientWidth / ppiX; // width in inches
  const slideWidth = (slide.sizeCols * slide.maxResolution) / MICROMETERS_PER_INCH; // dividing by 25400 to convert microns to inches
  const viewerDistanceFactor = 0.42;
  const microscopeFactor = 10; // microscopes have a standard default X10 magnification
  const newZoomFactor = ((slideViewportWidth / slideWidth) * viewerDistanceFactor) / microscopeFactor;
  zoomFactor.value = newZoomFactor;
};
