import { Autocomplete, TextField } from '@mui/material';
import { FieldOption, MultiListField, SingleListField } from 'interfaces/genericFields';
import { find, isEmpty, map, some } from 'lodash';
import React from 'react';
import { EnumDisplayNames } from 'utils/queryHooks/uiConstantsHooks';
import { HeaderCellEditorProps } from '.';
import { useTableEditingContext } from '../TableEditingContext';
import { BasicTableRow } from '../TableEditingContext/types';

const autocompleteComponentProps = {
  // Hide the dropdown indicator from the input
  popupIndicator: { style: { display: 'none' } },
};

const onBlur: React.FocusEventHandler<any> = (e) => {
  // https://github.com/mui/mui-x/issues/3393 - MUI Grid has a bug with components that use portals in header cells
  e.stopPropagation();
};

export interface HeaderSelectInputProps<R, V, Context extends { enumDisplayNames?: EnumDisplayNames }>
  extends HeaderCellEditorProps<R, V, Context, SingleListField<R, Context> | MultiListField<R, Context>> {
  forceRenderMultiple?: boolean;
}

const HeaderSelectInput = <R extends BasicTableRow, V, Context extends { enumDisplayNames?: EnumDisplayNames }>({
  onChange,
  value,
  field,
  forceRenderMultiple,
}: HeaderSelectInputProps<R, V, Context>) => {
  const { enumType, options: manualOptions, cellEditType, optionsGetter, missingValueLabel } = field;
  const multiple = cellEditType === 'multiSelect';

  const { fieldsContext } = useTableEditingContext<R, Context>();

  const options: FieldOption[] = React.useMemo(() => {
    const baseOptions = enumType
      ? fieldsContext?.enumDisplayNames?.[enumType] || []
      : optionsGetter
      ? optionsGetter(fieldsContext)
      : manualOptions;
    return [
      ...(baseOptions || []),
      // Add the missing value option if it's not already in the list
      ...(!some(baseOptions, { value: null })
        ? [
            {
              value: null,
              label: missingValueLabel ?? 'No Selection',
            },
          ]
        : []),
    ];
  }, [enumType, fieldsContext, optionsGetter, manualOptions]);

  const [didInteract, setDidInteract] = React.useState(false);

  const handleAutocompleteChange = React.useCallback(
    (event: React.SyntheticEvent<Element, Event>, newValues: string | number | Array<string | number>) => {
      onChange(newValues);
    },
    [onChange]
  );

  const handleInteractionStart = React.useCallback(() => {
    if (!didInteract) {
      setDidInteract(true);
    }
  }, [didInteract]);

  const actualValue = React.useMemo(
    () => (multiple ? (isEmpty(value) ? [] : value) : value ?? null),
    [multiple, value]
  );

  const getOptionLabel = React.useCallback(
    (optionVal: any) => `${find(options, { value: optionVal })?.label ?? optionVal ?? missingValueLabel ?? ''}`,
    [options, missingValueLabel]
  );

  const optionStrings = React.useMemo(() => map(options, 'value'), [options]);

  if (multiple && !forceRenderMultiple) {
    console.error('HeaderSelectInput: Autocomplete component causing render issues with data grid header cells');
    return null;
  }

  return (
    <Autocomplete<string | number, boolean, false, false>
      // Open the popup on focus, but not when a row editing is started (which focuses all the cells, without blur)
      openOnFocus={didInteract}
      renderInput={(inputParams) => <TextField {...inputParams} label={field.label} />}
      componentsProps={autocompleteComponentProps}
      fullWidth
      multiple={multiple}
      value={actualValue}
      onChange={handleAutocompleteChange}
      onBlur={onBlur}
      size="small"
      onInputChange={handleInteractionStart}
      onClick={handleInteractionStart}
      disableCloseOnSelect
      options={optionStrings}
      getOptionLabel={getOptionLabel}
    />
  );
};

export default HeaderSelectInput;
