import { StainType } from 'interfaces/stainType';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getStainTypeOptions = () => {
  return apiRequestHandlerPromise<StainType[]>({
    url: `stain_types`,
    method: 'GET',
  });
};

export const getStainTypeFilteredIds = (queryParams: string, signal: AbortSignal) => {
  return apiRequestHandlerPromise<string[]>({
    url: `stain_types/filtered_ids?${queryParams}`,
    method: 'GET',
    signal,
  });
};

// the index is not used in the request, we take it out to not send it
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const createStainTypeOption = ({ index, ...data }: StainType) => {
  return apiRequestHandlerPromise<StainType>({
    url: 'stain_types',
    method: 'POST',
    data: JSON.stringify(data),
  });
};

// the index is not used in the request, we take it out to not send it
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const updateStainTypeOption = ({ id, index, ...data }: StainType) => {
  return apiRequestHandlerPromise({
    url: `stain_types/${id}`,
    method: 'POST',
    data: JSON.stringify(data),
  });
};

export const deleteStainTypeOption = (id: string) => {
  return apiRequestHandlerPromise({
    url: `stain_types/${id}`,
    method: 'DELETE',
  });
};
