export enum MultiplexHistogramType {
  Full = 'full',
  Tsm = 'tsm',
  Roi = 'roi',
}

export interface MultiplexHistogramConfig {
  maskType: MultiplexHistogramType;
  roiAssignmentId?: number;
  roiAreaClassName?: string;
  tsmUseArtifactIfAvailable?: boolean;
  resolutionUmpp?: number;
  maxWorkers?: number;
}
