import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getAreaTypeOptions } from 'api/areaTypes';
import { getBiopsySiteTypes } from 'api/biopsySiteTypes';
import { getAllCancerTypes } from 'api/cancerTypes';
import { getClassificationModelOptions } from 'api/classificationModels';
import { getClusterTypeOptions } from 'api/clusterTypes';
import { fetchFormatExtensions } from 'api/formatExtensions';
import { fetchQueryableTypes } from 'api/queryableTypes';
import { getStainTypeOptions } from 'api/stainTypes';
import { getVisualizationTypeOptions } from 'api/visualization';
import { AreaType } from 'interfaces/areaType';
import { BiopsySiteType } from 'interfaces/biopsySiteType';
import { CancerSubtype, CancerTypeMap } from 'interfaces/cancerType';
import { ClassificationModel } from 'interfaces/classificationModel';
import { ClusterType } from 'interfaces/clusterType';
import { FeatureFormatter } from 'interfaces/featureFormatter';
import { QueryableTypes } from 'interfaces/queryableTypes';
import { StainType } from 'interfaces/stainType';
import Visualization from 'interfaces/visualization';
import { Dictionary, filter, find, isEmpty, map, mapValues, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export interface QcConfig {
  [key: string]: { displayName: string };
}

export interface SidebarConfig {
  featuresConfig: {
    [key: string]: {
      displayName: string;
    };
  };
  heatmapsConfig: {
    [key: string]: {
      displayName: string;
      color: string;
    };
  };
}

export interface BiopsyConfig {
  [key: string]: { displayName: string };
}

export interface FeaturesConfig {
  [key: string]: {
    displayName: string;
  };
}

export interface EnumDisplayNames {
  [key: string]: Array<{ value: string; label: string; displayName?: string }>;
}

export interface UiSettings {
  webappQcConfig: QcConfig;
  webappSidebarConfig: SidebarConfig;
  webappBiopsyConfig: BiopsyConfig;
  webappFeaturesConfig: FeaturesConfig;
  cancerSubtypes: Record<string, CancerSubtype>;
  enumDisplayNames?: EnumDisplayNames;
  featureFormatters: FeatureFormatter[];
}

export const useFeatureFormatters = (
  props?: Omit<
    UseQueryOptions<FeatureFormatter[], unknown, FeatureFormatter[], string[]>,
    'initialData' | 'queryFn' | 'queryKey'
  >
) => {
  const { data: featureFormatters, isInitialLoading: isLoadingFeatureFormatters } = useQuery(
    ['featureFormatters'],
    () =>
      apiRequestHandlerPromise<FeatureFormatter[]>({
        method: 'GET',
        url: `feature_formatters`,
      }),
    props
  );

  // First is the most accurate bracket
  const sortedFeatureFormatters = React.useMemo(
    () =>
      sortBy(
        featureFormatters || [],
        (featureFormatter) => -filter(featureFormatter.featureId, (char) => char === '<').length
      ),
    [featureFormatters]
  );

  return {
    featureFormatters: sortedFeatureFormatters,
    isLoadingFeatureFormatters,
  };
};

const biopsySiteTypesPlaceholder: BiopsySiteType[] = [];

export const useBiopsySiteTypes = (
  props?: Omit<
    UseQueryOptions<BiopsySiteType[], unknown, BiopsySiteType[], string[]>,
    'initialData' | 'queryFn' | 'queryKey'
  >
) => {
  const { data: loadedBiopsySiteTypes, isInitialLoading: isLoadingBiopsySiteTypes } = useQuery(
    ['biopsySiteTypes'],
    getBiopsySiteTypes,
    props
  );

  return {
    biopsySiteTypes: loadedBiopsySiteTypes || biopsySiteTypesPlaceholder,
    isLoadingBiopsySiteTypes,
  };
};

const stainTypeOptionsPlaceholder: StainType[] = [];

export const useStainTypeOptions = (
  props?: Omit<UseQueryOptions<StainType[], unknown, StainType[], string[]>, 'initialData' | 'queryFn' | 'queryKey'>
) => {
  const { data: loadedStainTypeOptions, isInitialLoading: isLoadingStainTypeOptions } = useQuery(
    ['stainTypes'],
    getStainTypeOptions,
    props
  );
  const stainsWithoutDeprecated = useMemo(
    () => filter(loadedStainTypeOptions, (stain) => !stain?.deletedAt),
    [loadedStainTypeOptions]
  );

  const mifMarkerTypeOptionsWithoutDeprecated = useMemo(
    () => filter(stainsWithoutDeprecated, (stainType) => stainType?.canBeMifMarker),
    [stainsWithoutDeprecated]
  );

  return {
    stainTypeOptions: loadedStainTypeOptions || stainTypeOptionsPlaceholder,
    stainTypeOptionsWithoutDeprecated: stainsWithoutDeprecated || stainTypeOptionsPlaceholder,
    mifMarkerTypeOptionsWithoutDeprecated: mifMarkerTypeOptionsWithoutDeprecated || stainTypeOptionsPlaceholder,
    isLoadingStainTypeOptions,
  };
};

const areaTypeOptionsPlaceholder: AreaType[] = [];

export const useAreaTypeOptions = (
  props?: Omit<UseQueryOptions<AreaType[], unknown, AreaType[], string[]>, 'initialData' | 'queryFn' | 'queryKey'>
) => {
  const { data: loadedAreaTypeOptions, isInitialLoading: isLoadingAreaTypes } = useQuery(
    ['areaTypes'],
    getAreaTypeOptions,
    props
  );
  return {
    areaTypeOptions: loadedAreaTypeOptions || areaTypeOptionsPlaceholder,
    isLoadingAreaTypes,
  };
};

const classificationModelOptionsPlaceholder: ClassificationModel[] = [];

export const useClassificationModelOptions = (
  props?: Omit<
    UseQueryOptions<ClassificationModel[], unknown, ClassificationModel[], string[]>,
    'initialData' | 'queryFn' | 'queryKey'
  >
) => {
  const { data: loadedClassificationModelOptions, isLoading: isLoadingClassificationModels } = useQuery(
    ['classificationModels'],
    getClassificationModelOptions,
    props
  );

  return {
    classificationModelOptions: loadedClassificationModelOptions || classificationModelOptionsPlaceholder,
    isLoadingClassificationModels,
  };
};

const visualizationTypeOptionsPlaceholder: Visualization[] = [];

export const useVisualizationTypeOptions = (
  props?: Omit<
    UseQueryOptions<Visualization[], unknown, Visualization[], string[]>,
    'initialData' | 'queryFn' | 'queryKey'
  >
) => {
  const { data: loadedVisualizationTypeOptions, isInitialLoading: isLoadingVisualizationTypes } = useQuery(
    ['visualizationTypes'],
    getVisualizationTypeOptions,
    props
  );
  return {
    visualizationTypeOptions: loadedVisualizationTypeOptions || visualizationTypeOptionsPlaceholder,
    isLoadingVisualizationTypes,
  };
};

const clusterTypeOptionsPlaceholder: ClusterType[] = [];

export const useClusterTypeOptions = (
  props?: Omit<UseQueryOptions<ClusterType[], unknown, ClusterType[], string[]>, 'initialData' | 'queryFn' | 'queryKey'>
) => {
  const {
    data: loadedClusterTypeOptions,
    isLoading: isLoadingClusterTypes,
    error,
  } = useQuery(['clusterTypes'], getClusterTypeOptions, props);

  return {
    clusterTypeOptions: loadedClusterTypeOptions || clusterTypeOptionsPlaceholder,
    isLoadingClusterTypes,
    error,
  };
};

const queryableTypesPlaceholder: QueryableTypes = {};

export const useQueryableTypes = (
  props?: Omit<
    UseQueryOptions<QueryableTypes, unknown, QueryableTypes, string[]>,
    'initialData' | 'queryFn' | 'queryKey'
  >
) => {
  const { data: loadedQueryableTypes, isInitialLoading: isLoadingQueryableTypes } = useQuery(
    ['queryableTypes'],
    fetchQueryableTypes,
    props
  );
  return {
    queryableTypes: loadedQueryableTypes || queryableTypesPlaceholder,
    isLoadingQueryableTypes,
  };
};

export const uiSettingsPlaceholder: UiSettings = {
  webappQcConfig: {},
  webappSidebarConfig: { featuresConfig: {}, heatmapsConfig: {} },
  webappBiopsyConfig: {},
  webappFeaturesConfig: {},
  cancerSubtypes: {},
  featureFormatters: [],
};

export const useUiSettings = (
  props?: Omit<UseQueryOptions<UiSettings, unknown, UiSettings, string[]>, 'initialData' | 'queryFn' | 'queryKey'>
) => {
  const { data: loadedUiSettings, isInitialLoading: isLoadingUiSettings } = useQuery(
    ['uiSettings'],
    () =>
      apiRequestHandlerPromise<UiSettings>({
        method: 'GET',
        url: `ui_settings`,
      }),
    props
  );

  const { queryableTypes } = useQueryableTypes();

  const uiSettingsWithFilledEnumDisplayNames = useMemo(() => {
    const enumDisplayNames = loadedUiSettings?.enumDisplayNames || {};

    const filledQueryableTypes: EnumDisplayNames = mapValues(queryableTypes, (queryableTypeValues, queryableTypeName) =>
      map(queryableTypeValues, (value) => ({
        ...(find(enumDisplayNames[queryableTypeName], { value }) || { value, label: value }),
      }))
    );

    const filledEnumDisplayNames = isEmpty(filledQueryableTypes)
      ? enumDisplayNames
      : { ...enumDisplayNames, ...filledQueryableTypes };

    return {
      ...uiSettingsPlaceholder,
      ...loadedUiSettings,
      enumDisplayNames: filledEnumDisplayNames,
    };
  }, [loadedUiSettings?.enumDisplayNames, queryableTypes]);

  return { uiSettings: uiSettingsWithFilledEnumDisplayNames, isLoadingUiSettings };
};

const formatExtensionsPlaceholder: Dictionary<string> = {};

export const useFormatExtensions = (
  props?: Omit<
    UseQueryOptions<Dictionary<string>, unknown, Dictionary<string>, string[]>,
    'initialData' | 'queryFn' | 'queryKey'
  >
) => {
  const { data: loadedFormatExtensions, isInitialLoading: isLoadingFormatExtensions } = useQuery(
    ['formatExtensions'],
    fetchFormatExtensions,
    props
  );

  return {
    formatExtensions: loadedFormatExtensions || formatExtensionsPlaceholder,
    isLoadingFormatExtensions,
  };
};

export const useAllCancerTypes = (
  props?: Omit<UseQueryOptions<CancerTypeMap, unknown, CancerTypeMap, string[]>, 'initialData' | 'queryFn' | 'queryKey'>
) => {
  const { data: allCancerTypes, isLoading: isLoadingAllCancerTypes } = useQuery(
    ['allCancerTypes'],
    getAllCancerTypes,
    props
  );
  return { allCancerTypes, isLoadingAllCancerTypes };
};
