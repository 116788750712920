import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CellRuleValue } from 'interfaces/cellRule';
import { map } from 'lodash';
import React from 'react';
import { cellRuleWidth } from '.';
import CellRuleDisplay from './CellRuleGridCell/CellRuleDisplay';
import { cellRuleOptions } from './CellRuleGridCell/CellRuleDropdown';

interface CellRuleLegendProps {}

export const cellRuleDescriptions: Record<CellRuleValue, string> = {
  [CellRuleValue.Indifferent]: 'Indifferent',
  [CellRuleValue.MustBePositive]: 'Must be positive',
  [CellRuleValue.MustBeNegative]: 'Must be negative',
  [CellRuleValue.OneOfTwoMustBePositive]: 'One of two must be positive',
};

const CellRuleLegend: React.FC<CellRuleLegendProps> = () => {
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      sx={(theme) => ({
        padding: theme.spacing(2),
      })}
    >
      {map(cellRuleOptions, (option) => (
        <Grid
          item
          key={option}
          sx={(theme) => ({
            marginRight: theme.spacing(4),
          })}
        >
          <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item>
              <Box
                sx={{
                  width: cellRuleWidth,
                  height: cellRuleWidth,
                }}
              >
                <CellRuleDisplay ruleValue={option} />
              </Box>
            </Grid>
            <Grid item>
              <Typography>{cellRuleDescriptions[option]}</Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default CellRuleLegend;
