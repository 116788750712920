import { Viewport, assert } from '@deck.gl/core/typed';
import { clamp } from '@math.gl/core';
import { slice } from 'lodash';

abstract class ViewState<T, Props, State> implements IViewState<T> {
  private _viewportProps: Required<Props>;

  private _state: State;

  constructor(props: Required<Props>, state: State) {
    this._viewportProps = this.applyConstraints(props);
    this._state = state;
  }

  getViewportProps(): Required<Props> {
    return this._viewportProps;
  }

  getState(): State {
    return this._state;
  }

  abstract applyConstraints(props: Required<Props>): Required<Props>;

  abstract shortestPathFrom(viewState: T): Props;

  abstract panStart(params: { pos: [number, number] }): T;
  abstract pan({ pos, startPos }: { pos: [number, number]; startPos?: [number, number] }): T;
  abstract panEnd(): T;

  abstract rotateStart(params: { pos: [number, number] }): T;
  abstract rotate(params: { pos?: [number, number]; deltaAngleX?: number; deltaAngleY: number }): T;
  abstract rotateEnd(): T;

  abstract zoomStart({ pos }: { pos: [number, number] }): T;
  abstract zoom({ pos, startPos, scale }: { pos: [number, number]; startPos?: [number, number]; scale: number }): T;
  abstract zoomEnd(): T;

  abstract zoomIn(speed?: number): T;
  abstract zoomOut(speed?: number): T;

  abstract moveLeft(speed?: number): T;
  abstract moveRight(speed?: number): T;
  abstract moveUp(speed?: number): T;
  abstract moveDown(speed?: number): T;

  abstract rotateLeft(speed?: number): T;
  abstract rotateRight(speed?: number): T;
  abstract rotateUp(speed?: number): T;
  abstract rotateDown(speed?: number): T;
}

interface IViewState<T> {
  makeViewport?: (props: Record<string, any>) => Viewport;

  getViewportProps(): Record<string, any>;

  getState(): Record<string, any>;

  shortestPathFrom(viewState: T): Record<string, any>;

  panStart(params: { pos: [number, number] }): T;
  pan({ pos, startPos }: { pos: [number, number]; startPos?: [number, number] }): T;
  panEnd(): T;

  rotateStart(params: { pos: [number, number] }): T;
  rotate(params: { pos?: [number, number]; deltaAngleX?: number; deltaAngleY?: number }): T;
  rotateEnd(): T;

  zoomStart({ pos }: { pos: [number, number] }): T;
  zoom({ pos, startPos, scale }: { pos: [number, number]; startPos?: [number, number]; scale: number }): T;
  zoomEnd(): T;

  zoomIn(speed?: number): T;
  zoomOut(speed?: number): T;

  moveLeft(speed?: number): T;
  moveRight(speed?: number): T;
  moveUp(speed?: number): T;
  moveDown(speed?: number): T;

  rotateLeft(speed?: number): T;
  rotateRight(speed?: number): T;
  rotateUp(speed?: number): T;
  rotateDown(speed?: number): T;
}

//////////////

const PITCH_MOUSE_THRESHOLD = 5;
const PITCH_ACCEL = 1.2;

export type OrthographicMapStateProps = {
  /** Mapbox viewport properties */
  /** The width of the viewport */
  width: number;
  /** The height of the viewport */
  height: number;
  /** The tile zoom level of the map. */
  zoom: number;
  /** The bearing of the viewport in degrees */
  bearing?: number;
  /** The pitch of the viewport in degrees */
  pitch?: number;
  /**
   * Specify the altitude of the viewport camera
   * Unit: map heights, default 1.5
   * Non-public API, see https://github.com/mapbox/mapbox-gl-js/issues/1137
   */
  altitude?: number;

  /** Viewport target */
  target?: [number, number, number];

  /** Viewport constraints */
  maxZoom?: number;
  minZoom?: number;
  maxPitch?: number;
  minPitch?: number;
};

type OrthographicMapStateInternal = {
  /** Interaction states, required to calculate change during transform */
  /* The point on map being grabbed when the operation first started */
  startPanPos?: [number, number];
  /* Center of the zoom when the operation first started */
  startZoomPos?: [number, number];
  /* Pointer target when rotation started */
  startRotatePos?: [number, number];
  /** Bearing when current perspective rotate operation started */
  startBearing?: number;
  /** Pitch when current perspective rotate operation started */
  startPitch?: number;
  /** Zoom when current zoom operation started */
  startZoom?: number;
};

/* Utils */

export class OrthographicMapState extends ViewState<
  OrthographicMapState,
  OrthographicMapStateProps,
  OrthographicMapStateInternal
> {
  makeViewport: (props: Record<string, any>) => Viewport;

  constructor(
    options: OrthographicMapStateProps &
      OrthographicMapStateInternal & {
        makeViewport: (props: Record<string, any>) => Viewport;
      }
  ) {
    const {
      /** Mapbox viewport properties */
      /** The width of the viewport */
      width,
      /** The height of the viewport */
      height,
      /** The tile zoom level of the map. */
      zoom,
      /** The bearing of the viewport in degrees */
      bearing = 0,
      /** The pitch of the viewport in degrees */
      pitch = 0,
      /**
       * Specify the altitude of the viewport camera
       * Unit: map heights, default 1.5
       * Non-public API, see https://github.com/mapbox/mapbox-gl-js/issues/1137
       */
      altitude = 1.5,
      /** Viewport target */
      target = [0, 0, 0],

      /** Viewport constraints */
      maxZoom = 0,
      minZoom = -20,
      maxPitch = 60,
      minPitch = 0,

      /** Interaction states, required to calculate change during transform */
      /* The point on map being grabbed when the operation first started */
      startPanPos,
      /* Center of the zoom when the operation first started */
      startZoomPos,
      /* Pointer target when rotation started */
      startRotatePos,
      /** Bearing when current perspective rotate operation started */
      startBearing,
      /** Pitch when current perspective rotate operation started */
      startPitch,
      /** Zoom when current zoom operation started */
      startZoom,
    } = options;

    assert(Number.isFinite(target?.[0]), 'Number.isFinite(target?.[0]) is false');
    assert(Number.isFinite(target?.[1]), 'Number.isFinite(target?.[1]) is false');
    // `zoom` must be supplied
    assert(Number.isFinite(zoom), 'Number.isFinite(zoom) is false');

    super(
      {
        width,
        height,
        zoom,
        bearing,
        pitch,
        altitude,
        maxZoom,
        minZoom,
        maxPitch,
        minPitch,
        target,
      },
      {
        startPanPos,
        startZoomPos,
        startRotatePos,
        startBearing,
        startPitch,
        startZoom,
      }
    );

    this.makeViewport = options.makeViewport;
  }

  /**
   * Start panning
   * @param {[Number, Number]} pos - target on screen where the pointer grabs
   */
  panStart({ pos }: { pos: [number, number] }): OrthographicMapState {
    return this._getUpdatedState({
      startPanPos: this._unproject(pos),
    });
  }

  /**
   * Pan
   * @param {[Number, Number]} pos - target on screen where the pointer is
   * @param {[Number, Number], optional} startPos - where the pointer grabbed at
   *   the start of the operation. Must be supplied of `panStart()` was not called
   */
  pan({ pos, startPos }: { pos: [number, number]; startPos?: [number, number] }): OrthographicMapState {
    const startPanPos = this.getState().startPanPos || this._unproject(startPos);

    if (!startPanPos) {
      return this;
    }

    const viewport = this.makeViewport(this.getViewportProps());
    const newProps = viewport.panByPosition(startPanPos, pos);

    return this._getUpdatedState(newProps);
  }

  /**
   * End panning
   * Must call if `panStart()` was called
   */
  panEnd(): OrthographicMapState {
    return this._getUpdatedState({
      startPanPos: null,
    });
  }

  /**
   * Start rotating
   * @param {[Number, Number]} pos - target on screen where the center is
   */
  rotateStart({ pos }: { pos: [number, number] }): OrthographicMapState {
    return this._getUpdatedState({
      startRotatePos: pos,
      startBearing: this.getViewportProps().bearing,
      startPitch: this.getViewportProps().pitch,
    });
  }

  /**
   * Rotate
   * @param {[Number, Number]} pos - target on screen where the center is
   */
  rotate({
    pos,
    deltaAngleX = 0,
    deltaAngleY = 0,
  }: {
    pos?: [number, number];
    deltaAngleX?: number;
    deltaAngleY?: number;
  }): OrthographicMapState {
    const { startRotatePos, startBearing, startPitch } = this.getState();

    if (!startRotatePos || startBearing === undefined || startPitch === undefined) {
      return this;
    }
    let newRotation;
    if (pos) {
      newRotation = this._getNewRotation(pos, startRotatePos, startPitch, startBearing);
    } else {
      newRotation = {
        bearing: startBearing + deltaAngleX,
        pitch: startPitch + deltaAngleY,
      };
    }
    return this._getUpdatedState(newRotation);
  }

  /**
   * End rotating
   * Must call if `rotateStart()` was called
   */
  rotateEnd(): OrthographicMapState {
    return this._getUpdatedState({
      startBearing: null,
      startPitch: null,
    });
  }

  /**
   * Start zooming
   * @param {[Number, Number]} pos - target on screen where the center is
   */
  zoomStart({ pos }: { pos: [number, number] }): OrthographicMapState {
    return this._getUpdatedState({
      startZoomPos: this._unproject(pos),
      startZoom: this.getViewportProps().zoom,
    });
  }

  /**
   * Zoom
   * @param {[Number, Number]} pos - target on screen where the current center is
   * @param {[Number, Number]} startPos - the center target at
   *   the start of the operation. Must be supplied of `zoomStart()` was not called
   * @param {Number} scale - a number between [0, 1] specifying the accumulated
   *   relative scale.
   */
  zoom({
    pos,
    startPos,
    scale,
  }: {
    pos: [number, number];
    startPos?: [number, number];
    scale: number;
  }): OrthographicMapState {
    // Make sure we zoom around the current mouse target rather than map center
    let { startZoom, startZoomPos } = this.getState();

    if (!startZoomPos) {
      // We have two modes of zoom:
      // scroll zoom that are discrete events (transform from the current zoom level),
      // and pinch zoom that are continuous events (transform from the zoom level when
      // pinch started).
      // If startZoom state is defined, then use the startZoom state;
      // otherwise assume discrete zooming
      startZoom = this.getViewportProps().zoom;
      startZoomPos = this._unproject(startPos) || this._unproject(pos);
    }
    if (!startZoomPos) {
      return this;
    }

    const { maxZoom, minZoom } = this.getViewportProps();
    const zoomBeforeClamp = (startZoom as number) + Math.log2(scale);
    const zoom = clamp(zoomBeforeClamp, minZoom, maxZoom);

    const zoomedViewport = this.makeViewport({ ...this.getViewportProps(), zoom });

    return this._getUpdatedState({
      zoom,
      ...zoomedViewport.panByPosition(startZoomPos, pos),
    });
  }

  rotateLeft(speed: number = 15): OrthographicMapState {
    return this._getUpdatedState({
      bearing: this.getViewportProps().bearing - speed,
    });
  }

  rotateRight(speed: number = 15): OrthographicMapState {
    return this._getUpdatedState({
      bearing: this.getViewportProps().bearing + speed,
    });
  }

  rotateUp(speed: number = 10): OrthographicMapState {
    return this._getUpdatedState({
      pitch: this.getViewportProps().pitch + speed,
    });
  }

  rotateDown(speed: number = 10): OrthographicMapState {
    return this._getUpdatedState({
      pitch: this.getViewportProps().pitch - speed,
    });
  }

  // shortest path between two view states
  shortestPathFrom(viewState: OrthographicMapState): OrthographicMapStateProps {
    const fromProps = viewState.getViewportProps();
    const props = { ...this.getViewportProps() };
    const { bearing } = props;

    if (Math.abs(bearing - fromProps.bearing) > 180) {
      props.bearing = bearing < 0 ? bearing + 360 : bearing - 360;
    }

    return props;
  }

  /**
   * End zooming
   * Must call if `zoomStart()` was called
   */
  zoomEnd(): OrthographicMapState {
    return this._getUpdatedState({
      startZoomPos: null,
      startZoom: null,
    });
  }

  zoomIn(speed: number = 2): OrthographicMapState {
    return this._getUpdatedState({
      zoom: this._calculateNewZoom({ scale: speed }),
    });
  }

  zoomOut(speed: number = 2): OrthographicMapState {
    return this._getUpdatedState({
      zoom: this._calculateNewZoom({ scale: 1 / speed }),
    });
  }

  moveLeft(speed: number = 50): OrthographicMapState {
    return this._panFromCenter([-speed, 0]);
  }

  moveRight(speed: number = 50): OrthographicMapState {
    return this._panFromCenter([speed, 0]);
  }

  moveUp(speed: number = 50): OrthographicMapState {
    return this._panFromCenter([0, -speed]);
  }

  moveDown(speed: number = 50): OrthographicMapState {
    return this._panFromCenter([0, speed]);
  }

  // Apply any constraints (mathematical or defined by _viewportProps) to map state
  applyConstraints<
    T extends Pick<
      Partial<OrthographicMapStateProps>,
      'maxZoom' | 'minZoom' | 'zoom' | 'maxPitch' | 'minPitch' | 'pitch'
    >
  >(props: T): T {
    // Ensure zoom is within specified range
    if (!isNaN(props.zoom) && (!isNaN(props.maxZoom) || !isNaN(props.minZoom))) {
      const { maxZoom, minZoom, zoom } = props;
      props.zoom = clamp(zoom, minZoom, maxZoom);
    }

    // Ensure pitch is within specified range
    if (!isNaN(props.pitch) && (!isNaN(props.maxPitch) || !isNaN(props.minPitch))) {
      const { maxPitch, minPitch, pitch } = props;
      props.pitch = clamp(pitch, minPitch, maxPitch);
    }

    return props;
  }

  _getUpdatedState(newProps: Partial<OrthographicMapStateProps & OrthographicMapStateInternal>): OrthographicMapState {
    // @ts-ignore
    return new this.constructor({
      makeViewport: this.makeViewport,
      ...this.getViewportProps(),
      ...this.getState(),
      ...newProps,
    });
  }

  _unproject(pos?: number[]): [number, number] | undefined {
    const viewport = this.makeViewport(this.getViewportProps());
    // @ts-ignore
    return pos && viewport.unproject(pos);
  }

  // Calculates new zoom
  _calculateNewZoom({ scale, startZoom }: { scale: number; startZoom?: number }): number {
    const { maxZoom, minZoom } = this.getViewportProps();
    if (startZoom === undefined) {
      startZoom = this.getViewportProps().zoom;
    }
    const zoom = (startZoom as number) + Math.log2(scale);
    return clamp(zoom, minZoom, maxZoom);
  }

  _panFromCenter(offset: [number, number]) {
    const { target } = this.getViewportProps();
    return this.pan({
      startPos: slice(target, 0, 2) as [number, number],
      pos: [target[0] + offset[0], target[1] + offset[1]],
    });
  }

  _getNewRotation(
    pos: [number, number],
    startPos: [number, number],
    startPitch: number,
    startBearing: number
  ): {
    pitch: number;
    bearing: number;
  } {
    const deltaX = pos[0] - startPos[0];
    const deltaY = pos[1] - startPos[1];
    const centerY = pos[1];
    const startY = startPos[1];
    const { width, height } = this.getViewportProps();

    const deltaScaleX = deltaX / width;
    let deltaScaleY = 0;

    if (deltaY > 0) {
      if (Math.abs(height - startY) > PITCH_MOUSE_THRESHOLD) {
        // Move from 0 to -1 as we drag upwards
        deltaScaleY = (deltaY / (startY - height)) * PITCH_ACCEL;
      }
    } else if (deltaY < 0) {
      if (startY > PITCH_MOUSE_THRESHOLD) {
        // Move from 0 to 1 as we drag upwards
        deltaScaleY = 1 - centerY / startY;
      }
    }
    // clamp deltaScaleY to [-1, 1] so that rotation is constrained between minPitch and maxPitch.
    // deltaScaleX does not need to be clamped as bearing does not have constraints.
    deltaScaleY = clamp(deltaScaleY, -1, 1);

    const { minPitch, maxPitch } = this.getViewportProps();

    const bearing = startBearing + 180 * deltaScaleX;
    let pitch = startPitch;
    if (deltaScaleY > 0) {
      // Gradually increase pitch
      pitch = startPitch + deltaScaleY * (maxPitch - startPitch);
    } else if (deltaScaleY < 0) {
      // Gradually decrease pitch
      pitch = startPitch - deltaScaleY * (minPitch - startPitch);
    }

    return {
      pitch,
      bearing,
    };
  }
}
