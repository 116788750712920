import { GridRenderEditCellParams, GridTreeNodeWithRender, renderEditInputCell } from '@mui/x-data-grid';
import React from 'react';

export const ConditionalTextEditor = <
  R = any,
  V extends string | number | boolean | number[] | string[] | boolean[] = string
>(
  props: Omit<GridRenderEditCellParams<R, V, string, GridTreeNodeWithRender>, 'isEditable'> & {
    isEditable: (row: R) => boolean;
    cellEditorRenderer?: (params: GridRenderEditCellParams<R, V, string, GridTreeNodeWithRender>) => any;
  }
) => {
  const { isEditable, cellEditorRenderer = renderEditInputCell, ...params } = props;
  return isEditable(props.row) ? (
    cellEditorRenderer({ ...params, isEditable: true } as GridRenderEditCellParams<
      R,
      V,
      string,
      GridTreeNodeWithRender
    >)
  ) : (
    <>{String(params.value)}</>
  );
};
