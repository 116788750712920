import GridViewIcon from '@mui/icons-material/GridView';
import { Button, ClickAwayListener, FormControl, FormHelperText, Grid, Input } from '@mui/material';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import JobsControl from 'components/Pages/Jobs/JobsControl';
import { specimenMap } from 'components/StudyDashboard/ProceduresPage/ProcedureCard/ProcedureCardUtils';
import SlideStatus from 'components/StudyDashboard/SlideStatus';
import DownloadDisplayNameFeatures from 'components/atoms/DownloadDisplayNameFeatures';
import { ResultsMode } from 'interfaces/experimentResults';
import { Permission } from 'interfaces/permissionOption';
import { Study } from 'interfaces/study';
import { curry, includes, isEmpty } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { stringify } from 'qs';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { JsonParam, StringParam, encodeQueryParams } from 'use-query-params';
import { exportCellsTable, exportFeatureNames } from 'utils/exports';
import { useFormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { useAllCancerTypes } from 'utils/queryHooks/uiConstantsHooks';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import useStudyMutations from 'utils/useStudyMutations';
import CardContentEditable from './CardContentEditable';
import { CardDataBox, StyledCard, TypeIconBox } from './CardStyles';
import CardTypography from './CardTypography';
import StudyExportMenu from './StudyExportMenu/StudyExportMenu';
import StudySettingsControl from './StudySettings/StudySettingsControl';
interface Props {
  study: Study;
}

const backgroundColorCancerType = '#8a8f9226';

const StudyCard: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ study }) => {
  const theme = useTheme();
  const formatBracketsOptions = useFormatBracketsOptions(false);
  const { enqueueSnackbar } = useSnackbar();

  const { labId } = useCurrentLabId();
  const [orchestrationId, setOrchestrationId] = useState('');
  const [isDownloadDisplayNameFeatures, setIsDownloadDisplayNameFeatures] = useState(false);
  const [isOrchestrationIdForDownloadCellsTableDisplay, setIsOrchestrationIdForDownloadCellsTableDisplay] =
    useState(false);

  const [inEditMode, setInEditMode] = useState(false);

  const { queryParams } = useEncodedFilters();

  const { allCancerTypes } = useAllCancerTypes();

  const { cheatMode, cheatStage } = useAppSelector((state) => state.cheatMode);
  const navigate = useNavigate();
  const cancerType = allCancerTypes?.[study?.cancerTypeId];
  const specimen = cancerType ? specimenMap[cancerType.displayName] : null;
  const cancerTypeColor = cancerType?.color ? cancerType.color : backgroundColorCancerType;
  const navigateToStudyDashboard = useCallback(
    curry((e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      const encodedQuery = encodeQueryParams(
        { filters: JsonParam, labId: StringParam, resultsMode: StringParam },
        {
          filters: { ...queryParams.filters, studyId: study.id },
          labId,
          resultsMode: queryParams.resultsMode,
        }
      );

      navigate({
        pathname: `/procedures`,
        search: `?${stringify({ ...encodedQuery })}`,
      });
    }),
    [navigate, study.id]
  );

  const { studyMutation, handleFieldSave } = useStudyMutations(study, {
    onCloseSnackbar: () => setInEditMode(false),
  });

  const onStudyClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!inEditMode && !studyMutation.isLoading) {
      navigateToStudyDashboard(e);
    } else {
      setInEditMode(false);
    }
  };

  const onExportCellsTable = () => {
    exportCellsTable(study.id, orchestrationId, queryParams.resultsMode, () => {
      enqueueSnackbar('Error occurred, download cells table failed', {
        variant: 'error',
      });
    });
  };

  const { hasPermission } = usePermissions();
  const canDownloadFeatureNamesCsv = hasPermission(Permission.CanDownloadFeatureNamesCsv);
  const canDownloadCellsTable = hasPermission(Permission.DownloadCellsTable);
  const canEditStudyName = hasPermission(Permission.EditStudyNameAndDescription);

  return (
    <ClickAwayListener onClickAway={() => setInEditMode(false)}>
      <StyledCard
        data-test-class={`study-card-${study.id}`}
        variant="outlined"
        onClick={onStudyClick}
        sx={{ backgroundColor: theme.palette.background.paper }}
        className="study-card"
        editMode={inEditMode}
      >
        <CardHeader
          sx={{ width: '100%' }}
          title={
            <CardContentEditable
              disabled={!canEditStudyName || studyMutation.isLoading}
              savedValue={study.name}
              onSave={(newValue: string) => handleFieldSave('name', newValue)}
              onEditModeChange={setInEditMode}
            />
          }
          subheader={
            <Grid container spacing={1} alignItems="center">
              {cancerType && (
                <>
                  <Grid item>
                    <Typography variant="body1">{cancerType?.displayName}</Typography>
                  </Grid>
                  <Grid item>
                    <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: '0 10px' }} />
                  </Grid>
                </>
              )}

              <Grid item>{study.date && moment(study.date).format('ll')}</Grid>
            </Grid>
          }
          avatar={
            specimen ? (
              <Box bgcolor="Background" borderRadius="50%">
                <TypeIconBox sx={{ background: cancerTypeColor }}>
                  <img src={specimen.img} alt={specimen.abbreviation} />
                </TypeIconBox>
              </Box>
            ) : null
          }
          action={
            <CardActions>
              {Boolean(study.countInManifest) && (
                <SlideStatus total={study.countInManifest} ingested={study.countIngestedInManifest} />
              )}

              {hasPermission(Permission.ViewJobs) && <JobsControl studyId={study?.id} />}

              <Tooltip title="Cases" placement="top">
                <Box>
                  <IconButton onClick={navigateToStudyDashboard}>
                    <GridViewIcon />
                  </IconButton>
                </Box>
              </Tooltip>

              {hasPermission(Permission.ViewStudySettings) && <StudySettingsControl study={study} />}
            </CardActions>
          }
        ></CardHeader>
        <Typography
          data-cy="study-description"
          variant="body1"
          component="div"
          color="text.secondary"
          sx={{ maxWidth: '80%', padding: '16px', marginBottom: '20px' }}
        >
          <CardContentEditable
            disabled={studyMutation.isLoading}
            savedValue={study.description}
            onSave={(newValue: string) => handleFieldSave('description', newValue)}
            onEditModeChange={setInEditMode}
            placeholder="Add description..."
          />
        </Typography>

        <CardActions sx={{ display: 'flex', alignItems: 'self-start', padding: '0 16px' }}>
          <CardDataBox>
            <CardTypography>Study ID</CardTypography>
            <CardTypography bold color="text.primary">
              {study.externalStudyId}
            </CardTypography>
          </CardDataBox>

          <CardDataBox>
            <CardTypography>Cases</CardTypography>
            <CardTypography color="text.primary" bold>
              {includes(cheatMode, study?.id) && cheatStage < 2 ? 0 : study.casesCount}
            </CardTypography>
          </CardDataBox>
          <CardDataBox>
            <CardTypography>Slides</CardTypography>
            <CardTypography color="text.primary" bold>
              {includes(cheatMode, study?.id) && cheatStage < 2 ? 0 : study.slidesCount}
            </CardTypography>
          </CardDataBox>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
            {canDownloadFeatureNamesCsv && (
              <CardDataBox>
                <Grid container direction="column">
                  <Grid item>
                    <DownloadDisplayNameFeatures
                      nodeId={`download-feature-names-${study.id}`}
                      onClick={() => {
                        setIsDownloadDisplayNameFeatures(
                          (prevIsDownloadDisplayNameFeatures) => !prevIsDownloadDisplayNameFeatures
                        );
                      }}
                      isStudyExists={true}
                    />
                  </Grid>
                  {isDownloadDisplayNameFeatures && (
                    <Grid item container justifyContent="space-between">
                      <Grid item>
                        <FormControl fullWidth variant="standard">
                          <Input
                            id={`download-feature-names-orchestration-id-input-${study.id}`}
                            sx={{ width: '100px' }}
                            aria-describedby="standard-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight',
                            }}
                            value={orchestrationId}
                            onChange={(event) => {
                              setOrchestrationId(event.target.value);
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          />
                          <FormHelperText id="standard-weight-helper-text">Orchestration Id</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <Button
                          id={`download-feature-names-${study.id}-for-orchestration`}
                          disabled={isEmpty(orchestrationId) || formatBracketsOptions.isLoading}
                          variant="outlined"
                          size="small"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            exportFeatureNames({
                              studyId: study.id,
                              orchestrationId,
                              context: formatBracketsOptions,
                            });
                            setIsDownloadDisplayNameFeatures(false);
                          }}
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </CardDataBox>
            )}
            {canDownloadCellsTable && (
              <CardDataBox>
                <Grid container direction="column">
                  <Grid item>
                    <Button
                      id={`download-cells-table-${study.id}`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (queryParams.resultsMode === ResultsMode.Manual) {
                          setIsOrchestrationIdForDownloadCellsTableDisplay(
                            (prevIsOrchestrationIdForDownloadCellsTableDisplay) =>
                              !prevIsOrchestrationIdForDownloadCellsTableDisplay
                          );
                        } else {
                          onExportCellsTable();
                        }
                      }}
                    >
                      Download Cells Table
                    </Button>
                  </Grid>
                  {isOrchestrationIdForDownloadCellsTableDisplay && (
                    <Grid item container justifyContent="space-between">
                      <Grid item>
                        <FormControl fullWidth variant="standard">
                          <Input
                            id={`download-cells-table-orchestration-id-input-${study.id}`}
                            sx={{ width: '100px' }}
                            aria-describedby="standard-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight',
                            }}
                            value={orchestrationId}
                            onChange={(event) => {
                              setOrchestrationId(event.target.value);
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          />
                          <FormHelperText id="standard-weight-helper-text">Orchestration Id</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <Button
                          id={`download-cells-table-${study.id}-for-orchestration`}
                          disabled={isEmpty(orchestrationId)}
                          variant="outlined"
                          size="small"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onExportCellsTable();
                            setIsOrchestrationIdForDownloadCellsTableDisplay(false);
                          }}
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </CardDataBox>
            )}
            <CardDataBox>
              <StudyExportMenu study={study} disabled={studyMutation.isLoading || !study.allowExport} />
            </CardDataBox>
          </Box>
        </CardActions>
      </StyledCard>
    </ClickAwayListener>
  );
};

export default StudyCard;
