import CloseIcon from '@mui/icons-material/Close';
import LabelledTextArea from 'components/atoms/LabelledTextArea';

import { IconButton } from '@mui/material';
import PrimaryButton from 'components/atoms/PrimaryButton';
import Pin from 'interfaces/pin';
import { Point } from 'openseadragon';
import React, { useState } from 'react';
import DeleteButtonSrc from './delete.svg';
import EditButtonSrc from './edit.svg';
import './PinModal.scss';

const PinModal: React.FunctionComponent<
  React.PropsWithChildren<{
    pin: Pin;
    createPinMode: boolean;
    viewportCoordinates: Point;
    handleRemovePinFromView(): void;
    handleDeletePin(): void;
    handleSaveExistingPin(comment: string): void;
    handleClosePin(): void;
    handleEditPin(): void;
    handleSaveNewPin(comment: string): void;
  }>
> = ({
  pin,
  createPinMode,
  viewportCoordinates,
  handleRemovePinFromView,
  handleDeletePin,
  handleSaveExistingPin,
  handleClosePin,
  handleEditPin,
  handleSaveNewPin,
}) => {
  const [comment, setComment] = useState(pin.comment);
  const [editPin, setEditPin] = useState(false);
  const handleCommentChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.trim();
    setComment(value);
  };
  const onEditPin = () => {
    setEditPin(true);
    handleEditPin();
  };
  return (
    <div
      className="pin-box"
      style={{
        top: viewportCoordinates.y - 5,
        left: viewportCoordinates.x + 45,
        height: createPinMode ? '240px' : '170px',
      }}
    >
      <div className="pin-box-content">
        <div className="close-button-container">
          <IconButton
            onClick={createPinMode && !editPin ? handleRemovePinFromView : handleClosePin}
            sx={{ padding: 0 }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
        <div
          className="textbox-container"
          style={{
            height: createPinMode ? '140px' : '75px',
          }}
        >
          {createPinMode && (
            <LabelledTextArea
              label=""
              placeholder={editPin ? '' : comment}
              defaultValue={editPin ? comment : ''}
              rows={5}
              onChange={handleCommentChanged}
            />
          )}
          {!createPinMode && <label className="comment-label">{comment}</label>}
        </div>
        <div className="button-container">
          {createPinMode && (
            <PrimaryButton
              className="button-save"
              caption="SAVE"
              onClick={() => (editPin ? handleSaveExistingPin(comment) : handleSaveNewPin(comment))}
            />
          )}
          {!createPinMode && (
            <>
              <div className="button-edit" onClick={handleDeletePin}>
                <img src={DeleteButtonSrc} alt="delete" />
                <label className="button-label">Delete</label>
              </div>
              <div className="button-edit" onClick={onEditPin}>
                <img src={EditButtonSrc} alt="edit" />
                <label className="button-label">Edit</label>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PinModal;
