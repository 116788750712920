import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { FileInfo } from 'interfaces/fileList';
import { Permission } from 'interfaces/permissionOption';
import { map, orderBy, reject, startCase } from 'lodash';
import moment from 'moment';
import React from 'react';
import { formatBytes } from 'utils/helpers';
import { usePermissions } from 'utils/usePermissions';

type Order = 'asc' | 'desc';

interface HeadCell {
  id: keyof FileInfo;
  label: string;
}

const fields: (keyof FileInfo)[] = ['name', 'path', 'size', 'type', 'date', 'status'];

const headCells: HeadCell[] = map(fields, (field) => ({
  id: field,
  label: startCase(field),
}));

export const getHeadCells = (showPathColumn: boolean) => {
  return showPathColumn ? headCells : reject(headCells, (headCell) => headCell.id === 'path');
};

interface UploadsTableHeadTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof FileInfo) => void;
  order: Order;
  orderByField: string;
  showPathColumn: boolean;
}

const UploadsTableHead = (props: UploadsTableHeadTableProps) => {
  const { order, orderByField, onRequestSort, showPathColumn } = props;
  const createSortHandler = (property: keyof FileInfo) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {map(getHeadCells(showPathColumn), (headCell) => (
          <TableCell key={headCell.id} align="left" sortDirection={orderByField === headCell.id ? order : false}>
            <TableSortLabel
              active={orderByField === headCell.id}
              direction={orderByField === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface UploadsTableProps {
  rows: FileInfo[];
}

const UploadsTable = ({ rows }: UploadsTableProps) => {
  const { hasPermission } = usePermissions();
  const showPathColumn = hasPermission(Permission.UseNucleaiExperimentalFeatures);

  const [order, setOrder] = React.useState<Order>('desc');
  const [orderByField, setOrderBy] = React.useState<keyof FileInfo>('date');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof FileInfo) => {
    const isAsc = orderByField === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <UploadsTableHead
              showPathColumn={showPathColumn}
              order={order}
              orderByField={orderByField}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {orderBy(rows, [orderByField], [order])
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow tabIndex={-1} key={row.id}>
                      <Tooltip title={row.name} placement="bottom" arrow>
                        <TableCell>{row.name}</TableCell>
                      </Tooltip>
                      {showPathColumn && (
                        <Tooltip title={row.path} placement="bottom" arrow>
                          <TableCell>{row.path}</TableCell>
                        </Tooltip>
                      )}
                      <TableCell>{formatBytes(row.size as number)}</TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell>{moment(row.date).format('LL')}</TableCell>
                      <TableCell>{startCase(row.status)}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '& .MuiInputBase-root': {
              width: 'initial',
            },
            '& .MuiToolbar-root > p': {
              margin: 0,
            },
          }}
        />
      </Paper>
    </Box>
  );
};

export default UploadsTable;
