import { CardContent, CardHeader, Grid, Typography } from '@mui/material';
import Loader from 'components/Loader';
import { CohortWithSelectedFeatures } from 'interfaces/cohort_old';
import React from 'react';
import { CardWithBorder, OverviewSubSectionTitle } from '..';
import CohortSlideUsableChart from './CohortSlideUsableChart';
import FlaggedSlidesSummary from './FlaggedSlidesSummary';

interface Props {
  cohort: CohortWithSelectedFeatures;
  isLoading: boolean;
}

const QualityControlSection: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ cohort, isLoading }) => {
  return (
    <Grid item container direction="column" spacing={1} px={5}>
      <Grid item>
        <OverviewSubSectionTitle>Quality Control</OverviewSubSectionTitle>
      </Grid>

      <Grid container item direction="row" justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={4}>
          <CohortSlideUsableChart cohort={cohort} isLoading={isLoading} />
        </Grid>
        {/* here should be artifacts chart */}
        <Grid item xs={12} md={4} container direction="column">
          <CardWithBorder elevation={0}>
            <CardHeader title={<Typography color="black">Excluded Slides</Typography>}></CardHeader>
            <CardContent sx={{ overflowY: 'auto', flexGrow: 1 }}>
              {isLoading ? <Loader /> : <FlaggedSlidesSummary cohort={cohort} type="excluded" />}
            </CardContent>
          </CardWithBorder>
        </Grid>
        <Grid item xs={12} md={4} container direction="column">
          <CardWithBorder elevation={0}>
            <CardHeader title={<Typography color="black">Quality Flags</Typography>}></CardHeader>
            <CardContent sx={{ overflowY: 'auto', flexGrow: 1 }}>
              {isLoading ? <Loader /> : <FlaggedSlidesSummary cohort={cohort} type="flagged" />}
            </CardContent>
          </CardWithBorder>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QualityControlSection;
