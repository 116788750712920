export default `\
float odr = -log(max(color.rgb.r, 1e-6));  // avoid log(0)
float odg = -log(max(color.rgb.g, 1e-6));  // avoid log(0)
float odb = -log(max(color.rgb.b, 1e-6));  // avoid log(0)
vec3 od = vec3(odr, odg, odb);
// [[c_1, c_2, c_3],
//  [c_4, c_5, c_6],
//  [c_7, c_8, c_9]] * [odr, odg, odb] + [c_10, c_11, c_12] =
//     [c1*odr + c2*odg + c3*odb + c10,
//      c4*odr + c5*odg + c6*odb + c11,
//      c7*odr + c8*odg + c9*odb + c12]
vec3 deconvolution = coefficients * od + intercept;
vec3 deconvolutionIntensities = exp(-deconvolution);
color.rgba = vec4(deconvolutionIntensities.r, deconvolutionIntensities.g, deconvolutionIntensities.b, color.rgba.a);
`;
