// eslint-disable-next-line import/no-extraneous-dependencies
import GL from '@luma.gl/constants';

export const MAX_COLOR_INTENSITY = 255;

export const DEFAULT_COLOR_OFF = [0, 0, 0];

// If we want to add more channels, we may need to use more texture atlasses.
export const MAX_CHANNELS = 64; // 16 * 4
export const MAX_TEXTURES = 16;

export const DEFAULT_FONT_FAMILY = "-apple-system, 'Helvetica Neue', Arial, sans-serif";

export const DTYPE_VALUES = {
  // Valid combinations of format, dataFormat, and type: https://registry.khronos.org/OpenGL-Refpages/es3.0/html/glTexImage2D.xhtml
  Uint8: {
    dataFormat: GL.RGBA_INTEGER,
    format: GL.RGBA8UI,
    type: GL.UNSIGNED_BYTE,
    max: 2 ** 8 - 1,
    sampler: 'usampler2D',
  },
  Uint16: {
    dataFormat: GL.RGBA_INTEGER,
    format: GL.RGBA16UI,
    type: GL.UNSIGNED_SHORT,
    max: 2 ** 16 - 1,
    sampler: 'usampler2D',
  },
  Float32: {
    format: GL.RGBA32F,
    dataFormat: GL.RGBA,
    type: GL.FLOAT,
    // Not sure what to do about this one - a good use case for channel stats, I suppose:
    // https://en.wikipedia.org/wiki/Single-precision_floating-point_format.
    max: 3.4 * 10 ** 38,
    sampler: 'sampler2D',
  },
} as const;

export const DTYPE_VALUES_RED_ONLY = {
  // Valid combinations of format, dataFormat, and type: https://registry.khronos.org/OpenGL-Refpages/es3.0/html/glTexImage2D.xhtml
  Uint8: {
    format: GL.R8UI,
    dataFormat: GL.RED_INTEGER,
    type: GL.UNSIGNED_BYTE,
    max: 2 ** 8 - 1,
    sampler: 'usampler2D',
  },
  Uint16: {
    format: GL.R16UI,
    dataFormat: GL.RED_INTEGER,
    type: GL.UNSIGNED_SHORT,
    max: 2 ** 16 - 1,
    sampler: 'usampler2D',
  },
  Float32: {
    format: GL.R32F,
    dataFormat: GL.RED,
    type: GL.FLOAT,
    // Not sure what to do about this one - a good use case for channel stats, I suppose:
    // https://en.wikipedia.org/wiki/Single-precision_floating-point_format.
    max: 3.4 * 10 ** 38,
    sampler: 'sampler2D',
  },
} as const;

export const COLORMAPS = [
  'jet',
  'hsv',
  'hot',
  'cool',
  'spring',
  'summer',
  'autumn',
  'winter',
  'bone',
  'copper',
  'greys',
  'yignbu',
  'greens',
  'yiorrd',
  'bluered',
  'rdbu',
  'picnic',
  'rainbow',
  'portland',
  'blackbody',
  'earth',
  'electric',
  'alpha',
  'viridis',
  'inferno',
  'magma',
  'plasma',
  'warm',
  'rainbow-soft',
  'bathymetry',
  'cdom',
  'chlorophyll',
  'density',
  'freesurface-blue',
  'freesurface-red',
  'oxygen',
  'par',
  'phase',
  'salinity',
  'temperature',
  'turbidity',
  'velocity-blue',
  'velocity-green',
  'cubehelix',
] as const;

export enum RenderingModes {
  MAX_INTENSITY_PROJECTION = 'Maximum Intensity Projection',
  MIN_INTENSITY_PROJECTION = 'Minimum Intensity Projection',
  ADDITIVE = 'Additive',
}
