import { GridRowId, GridRowModesModel } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { useQueryClient } from '@tanstack/react-query';
import { find, omit, reject } from 'lodash';
import React from 'react';

import { createClusterTypeOption, deleteClusterTypeOption, updateClusterTypeOption } from 'api/clusterTypes';
import { useCrudControlsColumns } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { ClusterType } from 'interfaces/clusterType';
import { clusterTypeFields } from 'interfaces/clusterType/clusterTypeFields';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { ClusterTypeRowChangesSummary } from './ClusterTypeRowChangesSummary';
import { ClusterTypeDraft } from './types';
import { getClusterTypeError, getClusterTypeId } from './utils';

export const useClusterTypesColumns = ({
  apiRef,
  noRows,
  clusterTypes,
  draftClusterTypes,
  rowModesModel,
  setDraftClusterTypes,
  setRowModesModel,
}: {
  noRows?: boolean;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  clusterTypes: Array<ClusterType | ClusterTypeDraft>;
  draftClusterTypes: ClusterTypeDraft[];
  rowModesModel: GridRowModesModel;
  setDraftClusterTypes: React.Dispatch<React.SetStateAction<Array<Omit<ClusterType, 'index'>>>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();
  const commonMutationOptions = React.useMemo(() => ({ onError: () => setMutatingRowId(undefined) }), []);

  const queryClient = useQueryClient();

  const createClusterTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (newClusterType) => {
      queryClient.invalidateQueries(['clusterTypes']);
      queryClient.setQueryData(['clusterTypes'], (oldData: ClusterType[]) => [...oldData, newClusterType]);
    },
    mutationFn: (newClusterType: ClusterType) => createClusterTypeOption(omit(newClusterType, 'index')),
    mutationDescription: 'create cluster type',
  });

  const updateClusterTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (data, variables) => {
      const oldRow = find(clusterTypes, { id: mutatingRowId as string });
      queryClient.invalidateQueries(['clusterTypes']);
      queryClient.setQueryData(['clusterTypes'], (oldData: ClusterType[]) => {
        return [...reject(oldData, { id: mutatingRowId }), { ...variables, index: (oldRow as ClusterType)?.index }];
      });
    },
    mutationFn: (clusterTypeUpdate: ClusterType) => updateClusterTypeOption(omit(clusterTypeUpdate, 'index')),
    mutationDescription: 'update cluster type',
  });

  const deleteClusterTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess(data, variables) {
      queryClient.invalidateQueries(['clusterTypes']);
      queryClient.setQueryData(['clusterTypes'], (oldData: ClusterType[]) => reject(oldData, { id: variables }));
    },
    mutationFn: deleteClusterTypeOption,
    mutationDescription: 'delete cluster type',
  });

  const columns = useCrudControlsColumns<ClusterType, ClusterTypeDraft>({
    createMutation: createClusterTypeMutation,
    deleteMutation: deleteClusterTypeMutation,
    updateMutation: updateClusterTypeMutation,
    apiRef,
    rowModesModel,
    setRowModesModel,
    setDraftRows: setDraftClusterTypes,
    draftRows: draftClusterTypes,
    currentRows: clusterTypes,
    rowTypeFields: clusterTypeFields,
    getRowError: getClusterTypeError,
    noRows,
    getCancelEditConfirmationModalOptions: ({ id, newRowValue, isDraft, changes }) => ({
      title: `Cancel cluster type ${isDraft ? 'creation' : 'update'}`,
      text: (
        <ClusterTypeRowChangesSummary
          clusterTypeDisplayString={`Cluster Type "${newRowValue?.displayName}" (${newRowValue?.id ?? id})`}
          changes={changes}
        />
      ),
    }),
    getSaveConfirmationModalOptions: ({ id, newRowValue, isDraft, changes }) => ({
      title: `${isDraft ? 'Create' : 'Update'} cluster type`,
      text: (
        <ClusterTypeRowChangesSummary
          clusterTypeDisplayString={`Cluster Type "${newRowValue?.displayName}" (${newRowValue?.id ?? id})`}
          changes={changes}
        />
      ),
    }),
    getDeleteConfirmationModalOptions: () => ({
      title: 'Delete Cluster Type',
      text: 'Are you sure you want to delete this cluster type?',
    }),
    idGetter: getClusterTypeId,
    draftIdField: 'draftId',
    mutatingRowId,
    setMutatingRowId,
  });

  return columns;
};
