import { procedureToSlides } from 'components/FeaturesDashboard/chart.util';
import { CohortWithSelectedFeatures } from 'interfaces/cohort_old';
import { Procedure } from 'interfaces/procedure';
import { ExcludeOption, QualityControlLabel, QualityControlLabelParam } from 'interfaces/qualityControlLabels';
import { Slide, SlideQcLabel } from 'interfaces/slide';
import { compact, find, flatMap, isEmpty, map, some, uniq } from 'lodash';

export const getLabelValue = (filteredLabel: QualityControlLabelParam, qcLabelConfig: QualityControlLabel[]) => {
  const label = find(qcLabelConfig, { id: filteredLabel?.id });
  return filteredLabel.isReject ? label?.rejectLabel : label?.filterLabel;
};

export const getLabelsWithoutErrorText = (labels: SlideQcLabel[]): string[] =>
  compact(map(labels, (label) => label && !label.errorCalculatingLabel && label.text));

export const getSlideLabelsText = (slide: Slide): string[] => {
  return getLabelsWithoutErrorText(slide?.qcLabels);
};

export const getCaseLabelsText = (procedure: Procedure): string[] => {
  const slides = procedureToSlides(procedure);
  return uniq(flatMap(slides, (slide) => getSlideLabelsText(slide)));
};

export const getCohortNoneExcludedLabelsText = (cohort: CohortWithSelectedFeatures): string[] => {
  return compact(
    flatMap(cohort?.qcLabelsConfig, (labelConfig: QualityControlLabel) => {
      if (labelConfig.excludeOption === null) {
        return [
          labelConfig.attachFilterLabel ? labelConfig.filterLabel : null,
          labelConfig.attachRejectLabel ? labelConfig.rejectLabel : null,
        ];
      } else {
        return null;
      }
    })
  );
};
export const isLabelExternal = (label: QualityControlLabel) => label.filterLabelExternal || label.rejectLabelExternal;

export const getCohortExcludedLabelsText = (cohort: CohortWithSelectedFeatures): string[] => {
  return compact(
    map(cohort?.qcLabelsConfig, (labelConfig: QualityControlLabel) => {
      if (labelConfig.excludeOption) {
        return labelConfig.excludeOption == ExcludeOption.FILTER ? labelConfig.filterLabel : labelConfig.rejectLabel;
      } else {
        return null;
      }
    })
  );
};

export const isSlideExcluded = (slide: Slide): boolean => {
  return slide.qcFailed;
};

export const isSlideFlagged = (slide: Slide): boolean => {
  return !isEmpty(slide.qcLabels) && some(slide.qcLabels, (label: SlideQcLabel) => !label.errorCalculatingLabel);
};
