import { PMTilesMetadata, PMTilesSource } from '@loaders.gl/pmtiles';
import { useQueries, useQuery } from '@tanstack/react-query';
import { map, some, uniqBy } from 'lodash';

import { FeatureMetadata, HeatmapType } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { useMemo } from 'react';

export interface PMTilesResult {
  pmtHeatmap?: FeatureMetadata;
  pmtTileSource: PMTilesSource | null;
  pmtMetadata: PMTilesMetadata | null;
}

export const setupProtomapTiles = async (pmtHeatmap: FeatureMetadata): Promise<PMTilesResult> => {
  const pmtTileSource = new PMTilesSource({ url: pmtHeatmap.heatmapUrl });
  const pmtMetadata = await pmtTileSource.metadata;
  return { pmtTileSource, pmtMetadata };
};

export const useProtomapTiles = (pmtHeatmap: FeatureMetadata): PMTilesResult => {
  const { data } = useQuery(['pmtiles', pmtHeatmap.heatmapUrl], async () => setupProtomapTiles(pmtHeatmap));

  return {
    pmtTileSource: data?.pmtTileSource || null,
    pmtMetadata: data?.pmtMetadata || null,
    pmtHeatmap,
  };
};

const canQueryPmtHeatmap = (pmtHeatmap: FeatureMetadata) =>
  Boolean(pmtHeatmap?.heatmapUrl) &&
  pmtHeatmap.heatmapType === HeatmapType.Pmt &&
  // Wait for pre-signed URLs to be ready
  !pmtHeatmap.heatmapUrl.startsWith('s3://');

export const useProtomapTilesList = (pmtHeatmaps: FeatureMetadata[]) => {
  const pmtTilesQueries = useQueries({
    queries: map(
      uniqBy(pmtHeatmaps, (pmtHeatmap) => pmtHeatmap?.heatmapUrl),
      (pmtHeatmap) => ({
        queryKey: ['pmtiles', pmtHeatmap?.heatmapUrl],
        queryFn: async () => setupProtomapTiles(pmtHeatmap),
        enabled: canQueryPmtHeatmap(pmtHeatmap),
      })
    ),
  });

  const pmtLayersData = useMemo(
    () =>
      uniqBy(
        map(pmtHeatmaps, (pmtHeatmap, queryIndex) => {
          const query = pmtTilesQueries[queryIndex];
          const { pmtTileSource, pmtMetadata } = query?.data || {};
          return {
            pmtHeatmap,
            pmtTileSource,
            pmtMetadata,
            isLoading: query?.isLoading && canQueryPmtHeatmap(pmtHeatmap),
            updatedAt: query?.dataUpdatedAt,
          };
        }),
        'pmtHeatmap.id'
      ),
    [JSON.stringify(map(pmtTilesQueries, 'dataUpdatedAt'))]
  );

  return {
    pmtTilesQueries,
    pmtLayersData,
    isLoading: some(pmtTilesQueries, 'isLoading'),
  };
};
