import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';

export interface ActionControlProps {
  header: string;
  onClose: () => void;
}

export const ActionModal: React.FC<React.PropsWithChildren<ActionControlProps>> = ({ header, onClose, children }) => {
  return (
    <Dialog
      onClick={(e) => {
        onClose(); // close dialog when click outside of dialog content
        e.stopPropagation();
      }}
      open={true}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h2">{header}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        onClick={(e) => {
          e.stopPropagation(); // prevent the click from propagating to the parent to make the dialog stay open
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};
