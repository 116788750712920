import WarningIcon from '@mui/icons-material/Warning';
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ColorPicker } from 'components/atoms/ColorPicker';
import { TaxonomyTree } from 'interfaces/taxonomy';
import { TaxonomyTag } from 'interfaces/taxonomyTag';
import { filter, includes, isEmpty, last, map, slice, some, split } from 'lodash';
import React, { useState } from 'react';
import { useUpdateTaxonomyMutation } from 'utils/queryHooks/taxonomy/useTaxonomyMutations';
import useTaxonomyTags from 'utils/queryHooks/taxonomy/useTaxonomyTags';
import AddTaxonomyButton from './TaxonomyActions/AddTaxonomyButton';
import CopyTaxonomyPathButton from './TaxonomyActions/CopyTaxonomyPathButton';
import DeleteTaxonomyButton from './TaxonomyActions/DeleteTaxonomyButton';
import EditTaxonomyButton from './TaxonomyActions/EditTaxonomyButton';

interface TaxonomyTreeViewItemLabelProps {
  taxonomyTree: TaxonomyTree;
  searchValue?: string;
  isEditor?: boolean;
  withTags?: boolean;
  onAddClassWithTags?: (newClass: string) => void;
}

const getNameWithHighlight = (name: string, searchValue: string) => {
  const index = name?.toLowerCase()?.indexOf(searchValue?.toLowerCase());
  if (index === -1) return { beforeMatch: name, match: '', afterMatch: '' };

  const beforeMatch = slice(name, 0, index);
  const match = slice(name, index, index + searchValue?.length);
  const afterMatch = slice(name, index + searchValue?.length);

  return { beforeMatch, match, afterMatch };
};

function getPathParts(path: string) {
  const parts = split(path, '.');
  return map(parts, (_, index) => slice(parts, 0, index + 1).join('.'));
}

const TaxonomyTreeViewItemLabel: React.FC<React.PropsWithChildren<TaxonomyTreeViewItemLabelProps>> = ({
  taxonomyTree,
  searchValue,
  isEditor,
  withTags,
  onAddClassWithTags,
}) => {
  const { beforeMatch, match, afterMatch } = getNameWithHighlight(taxonomyTree?.name, searchValue);

  const { data: taxonomyTags, isLoading: isLoadingTags, isError: isErrorGettingTags } = useTaxonomyTags();

  // take all tags that have at least one label that is a parent of the current taxonomy
  const relevantTaxonomyTags = filter(taxonomyTags, (tag) =>
    some(tag.labels, (label) => includes(getPathParts(taxonomyTree?.path), label))
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const updateTaxonomyMutation = useUpdateTaxonomyMutation(taxonomyTree?.path);

  const handleDefaultColorChange = (color: string) => {
    updateTaxonomyMutation.mutate({ defaultColor: color });
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item container width="unset" spacing={1} alignItems="center">
        {isEditor && (
          <Grid item>
            <ColorPicker
              currentColor={
                updateTaxonomyMutation.isLoading
                  ? updateTaxonomyMutation.variables.defaultColor
                  : taxonomyTree.defaultColor
              }
              onColorChange={handleDefaultColorChange}
              disableEditing={updateTaxonomyMutation.isLoading}
              applyChangeWhenFocusOut
            />
          </Grid>
        )}
        <Grid item>
          <Typography display="inline">{beforeMatch}</Typography>
          <Typography display="inline" fontWeight={700}>
            {match}
          </Typography>
          <Typography display="inline">{afterMatch}</Typography>
        </Grid>
        {isEditor && !taxonomyTree?.exists && (
          <Grid item>
            <Tooltip title="Taxonomy is not defined but has other taxonomies referencing it as a parent, define it by creating a new taxonomy with this path">
              <WarningIcon color="error" />
            </Tooltip>
          </Grid>
        )}
        {isEditor && taxonomyTree?.isAbstract && (
          <Grid item>
            <Tooltip title="This taxonomy is abstract, it cannot be directly used in the system">
              <Chip label="Abstract" color="primary" variant="outlined" size="small" />
            </Tooltip>
          </Grid>
        )}

        {updateTaxonomyMutation.isLoading && (
          <Grid item>
            <CircularProgress size={20} />
          </Grid>
        )}
        {withTags && onAddClassWithTags && (
          <Grid item ml={0.5} alignContent="end">
            {isErrorGettingTags ? (
              <Typography variant="body2" color="text.secondary">
                Error loading tags
              </Typography>
            ) : isLoadingTags ? (
              <Typography variant="body2" color="text.secondary">
                Loading tags...
              </Typography>
            ) : isEmpty(relevantTaxonomyTags) ? (
              <Typography variant="body2" color="text.secondary">
                No available tags
              </Typography>
            ) : (
              <>
                <Typography
                  variant="body2"
                  color="primary"
                  onClick={handlePopoverOpen}
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  Click to add tags
                </Typography>
                <AddTagsPopover
                  open={open}
                  anchorEl={anchorEl}
                  handlePopoverClose={handlePopoverClose}
                  taxonomyTree={taxonomyTree}
                  onAddClassWithTags={onAddClassWithTags}
                  relevantTaxonomyTags={relevantTaxonomyTags}
                />
              </>
            )}
          </Grid>
        )}
      </Grid>

      {isEditor && (
        <Grid
          item
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {taxonomyTree?.exists && (
            <>
              <EditTaxonomyButton taxonomyTree={taxonomyTree} />
              <AddTaxonomyButton taxonomyTree={taxonomyTree} />
              <DeleteTaxonomyButton path={taxonomyTree?.path} />
              <CopyTaxonomyPathButton taxonomyPath={taxonomyTree?.path} />
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};

interface AddTagsPopoverProps {
  open: boolean;
  anchorEl: HTMLElement;
  handlePopoverClose: () => void;
  taxonomyTree: TaxonomyTree;
  onAddClassWithTags: (newClass: string) => void;
  relevantTaxonomyTags: TaxonomyTag[];
}

const AddTagsPopover: React.FC<React.PropsWithChildren<AddTagsPopoverProps>> = ({
  open,
  anchorEl,
  handlePopoverClose,
  taxonomyTree,
  onAddClassWithTags,
  relevantTaxonomyTags,
}) => {
  const [keepPopoverOpen, setKeepPopoverOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const tagsDisplayNames = map(relevantTaxonomyTags, 'displayName');

  const classToAnnotateDisplayName = isEmpty(selectedOptions)
    ? taxonomyTree.path
    : `${last(split(taxonomyTree.path, '.'))}-${selectedOptions.join('-')}`;
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Grid container direction="column" p={2} width={600} spacing={2}>
        <Grid item>
          <Typography noWrap>
            final class name:{` `}
            {classToAnnotateDisplayName}
          </Typography>
        </Grid>
        <Grid item>
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={tagsDisplayNames}
            value={selectedOptions}
            onChange={(event, newValue) => {
              event.stopPropagation();
              setSelectedOptions(newValue);
            }}
            limitTags={5}
            renderInput={(params) => (
              <TextField
                {...params}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                variant="outlined"
                label="Select Tags"
              />
            )}
          />
        </Grid>
        <Grid item container alignItems="center">
          <Grid item>
            <Checkbox
              sx={{ pl: 0 }}
              checked={keepPopoverOpen}
              onChange={(event) => setKeepPopoverOpen(event.target.checked)}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">Keep open</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onAddClassWithTags(classToAnnotateDisplayName);
              if (!keepPopoverOpen) {
                handlePopoverClose();
              }
            }}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default TaxonomyTreeViewItemLabel;
