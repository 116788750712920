import { Procedure } from 'interfaces/procedure';
import { SlideTag } from 'interfaces/slideTag';
import { compact, find, join, map } from 'lodash';
import { DisplayedField } from '../../genericFields';
import { ProceduresFieldsContext, getProcedureSlideQcLabelsValues } from './helpers';

const getLabelsFields = (
  labels: string[],
  getLabelDisplayName: (labeText: string) => string
): DisplayedField<Procedure, boolean[], ProceduresFieldsContext>[] => {
  return map(labels, (label) => getLabelField(label, getLabelDisplayName));
};

const getLabelField = (
  label: string,
  getLabelDisplayName: (labeText: string) => string
): DisplayedField<Procedure, boolean[], ProceduresFieldsContext> => {
  return {
    filterType: 'multiSelect',
    dataCategory: 'metadata',
    dataKey: label,
    label: getLabelDisplayName(label),
    columnWidth: { width: 150 },
    isFieldOfObjectInArray: true,
    valueGetter: (procedure) => getProcedureSlideQcLabelsValues(procedure, label),
    objectArrayInnerPath: 'qcLabels',
    objectArrayKeyPath: 'id',
    objectArrayPath: 'slides',
    singleObjectFormatter: (qcLabel) => (qcLabel == null ? 'N/A' : qcLabel ? 'Yes' : 'No'),
  };
};

export const slideTagsField: DisplayedField<Procedure, string[] | string[][] | SlideTag[][], ProceduresFieldsContext> =
  {
    filterType: 'multiSelect',
    dataKey: 'slideTags',
    label: 'Slide Tags',
    dataCategory: 'metadata',
    columnWidth: { width: 150 },
    isFieldOfObjectInArray: true,
    objectArrayInnerPath: 'tags',
    objectArrayKeyPath: 'id',
    objectArrayPath: 'slides',
    optionsGetter: (context) => map(context.slideTagOptions, (tag) => ({ value: tag.id, label: tag.tagValue })),
    singleObjectFormatter: (tagsOrTag, context) => {
      if (!tagsOrTag) {
        return '';
      } else if (typeof tagsOrTag === 'string') {
        // tagsOrTag is a string ==> it's the ID of a tag
        return find(context.slideTagOptions, { id: tagsOrTag })?.tagValue ?? '';
      } else if (Array.isArray(tagsOrTag)) {
        return join(
          compact(
            map(tagsOrTag, (tag: string | SlideTag) => {
              if (!tag) {
                return null;
              }
              // tag is a string ==> it's the ID of a tag, otherwise it's a tag object
              const tagEntry = typeof tag === 'string' ? find(context.slideTagOptions, { id: tag }) : tag;
              return tagEntry?.tagValue;
            })
          ),
          ', '
        );
      } else {
        return '';
      }
    },
  };

export default getLabelsFields;
