import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { ReactComponent as TemplateArrow } from './template-arrow.svg';

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.grey[50],
  textAlign: 'center',
}));

const Title = styled(StyledTypography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '24px',
}));

const PageContainer = styled(Container)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

const RoundedBox = styled(Box)(() => ({
  borderRadius: '2px',
}));

const StyledTemplateBox = styled(RoundedBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '400px',
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
  margin: '20px 0',
  border: `1px solid ${theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800]}`,
  position: 'relative',
  padding: '15px',
}));

const LogoTemplate = styled(RoundedBox)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
  height: '20px',
}));

const ClickTemplate = styled(RoundedBox)(() => ({
  display: 'flex',
  height: '40px',
  padding: '10px',
}));

const ClickTemplateButton = styled(RoundedBox)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: '20px',
}));

const ContentTemplate = styled(RoundedBox)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[800],
  height: '104px',
}));

const ArrowBox = styled(Box)(() => ({
  position: 'absolute',
  top: '30%',
  right: '0',
}));

interface Props {
  page: 'studies' | 'cases' | 'slides' | 'procedures' | 'cohorts' | 'accessions';
}

const EmptyPage = ({ page }: Props) => {
  return (
    <PageContainer className="empty-page max-width-container">
      <div className="empty-page__content">
        <StyledTemplateBox>
          <ArrowBox>
            <TemplateArrow />
          </ArrowBox>
          <Grid container direction="column" spacing={2}>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={3}>
                <LogoTemplate />
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12}>
                <ClickTemplate>
                  <Grid container flexDirection="row-reverse">
                    <Grid item xs={3}>
                      <ClickTemplateButton />
                    </Grid>
                  </Grid>
                </ClickTemplate>
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12}>
                <ContentTemplate />
              </Grid>
            </Grid>
          </Grid>
        </StyledTemplateBox>
        <Title className="title">{`There are no ${page} here`}</Title>
        <StyledTypography className="subtitle">{`Start adding ${page} or use the advanced search to create a new cohort`}</StyledTypography>
      </div>
    </PageContainer>
  );
};

export default EmptyPage;
