import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  AutocompleteProps,
  Checkbox,
  Chip,
  FormControl,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import { concat, includes, map, uniqBy } from 'lodash';
import React, { useMemo } from 'react';

import { StainType } from 'interfaces/stainType';
import { useStainTypeOptions } from 'utils/queryHooks/uiConstantsHooks';

interface StainTypeSelectDropdownProps
  extends Omit<
    AutocompleteProps<StainType, true, false, false>,
    | 'onChange'
    | 'value'
    | 'options'
    | 'multiple'
    | 'loading'
    | 'renderInput'
    | 'getOptionLabel'
    | 'renderOption'
    | 'renderTags'
  > {
  selectedStainTypes: StainType[];
  onSelectStainTypes: (StainTypes: StainType[]) => void;
  mifMarkersOnly?: boolean;
}

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

const StainTypeSelectDropdown: React.FC<StainTypeSelectDropdownProps> = ({
  selectedStainTypes,
  onSelectStainTypes,
  mifMarkersOnly,
  ...autocompleteProps
}) => {
  const { stainTypeOptionsWithoutDeprecated, mifMarkerTypeOptionsWithoutDeprecated, isLoadingStainTypeOptions } =
    useStainTypeOptions();
  const theme = useTheme();
  const stainTypesFiltered = mifMarkersOnly ? mifMarkerTypeOptionsWithoutDeprecated : stainTypeOptionsWithoutDeprecated;
  const stainTypesFilteredIds = map(stainTypesFiltered, 'id');
  const stainTypesOptions = useMemo(
    () => uniqBy(concat(stainTypesFiltered, selectedStainTypes), 'id'),
    [stainTypesFiltered, selectedStainTypes]
  );

  return (
    <FormControl
      sx={{
        width: '100%',
      }}
    >
      <Autocomplete
        multiple
        loading={isLoadingStainTypeOptions}
        options={stainTypesOptions}
        getOptionLabel={(option) => option.displayName}
        value={selectedStainTypes}
        disableCloseOnSelect
        onChange={(event, newValue) => onSelectStainTypes(newValue as StainType[])}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} key={option.id}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                size="small"
              />
              {option.displayName}
            </li>
          );
        }}
        renderInput={(params) => <TextField {...params} label="Marker Type(s)" />}
        renderTags={(value, getTagProps) =>
          map(value, (option, index) => {
            const isOptionValid = includes(stainTypesFilteredIds, option.id);
            return (
              <Tooltip key={option.id} title={!isOptionValid && 'not a mif marker'}>
                <Chip
                  sx={{
                    backgroundColor: isOptionValid
                      ? 'auto'
                      : theme.palette.mode === 'light'
                      ? theme.palette.error.light
                      : theme.palette.error.dark,
                  }}
                  variant="filled"
                  label={option.displayName}
                  {...getTagProps({ index })}
                />
              </Tooltip>
            );
          })
        }
        {...autocompleteProps}
      />
    </FormControl>
  );
};

export default StainTypeSelectDropdown;
