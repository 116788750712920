import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Autocomplete,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getModelTypeOptions } from 'api/modelTypes';
import { AnnotationAssignment } from 'interfaces/annotation';
import { compact, filter, find, flatMap, isEmpty, map, uniq } from 'lodash';
import React from 'react';
import { useAreaTypeIdToDisplayName } from 'utils/useAreaTypeIdToDisplayName';
import { SelectedAssignmentDetails } from '..';

export interface AssignmentStainProps {
  assignmentAnnotations: AnnotationAssignment[];
  selectedAssignment: SelectedAssignmentDetails;
  updateSelectedAssignment: (updatedAssignment: SelectedAssignmentDetails) => void;
  removeSelectedAssignment: (assignmentOverrideId: string) => void;
  isDuplicateModelType: boolean;
}

const AssignmentSelection: React.FC<React.PropsWithChildren<AssignmentStainProps>> = ({
  assignmentAnnotations,
  selectedAssignment,
  updateSelectedAssignment,
  removeSelectedAssignment,
  isDuplicateModelType,
}) => {
  const { areaTypeIdToDisplayName, isLoadingAreaTypes } = useAreaTypeIdToDisplayName();

  const {
    data: modelTypes,
    isError: modelTypesError,
    isLoading: modelTypesLoading,
  } = useQuery(['modelTypes'], () => getModelTypeOptions(), {
    retry: false,
  });

  const modelTypesOptions = map(filter(modelTypes, { trainingType: 'segmentation' }), (modelType) => ({
    value: modelType?.type,
    label: modelType?.displayName,
    backgroundClassesToIgnore: modelType?.backgroundClassesToIgnore,
  }));

  const assignmentAnnotationsOptions = map(assignmentAnnotations, (annotation) => ({
    value: annotation?.annotationAssignmentId,
    label: annotation?.name,
  }));

  const selectedAssignmentTodos = uniq(compact(map(flatMap(selectedAssignment?.assignment?.todos, 'options'), 'name')));

  const classOptions =
    map(selectedAssignmentTodos, (todo) => ({
      value: todo,
      label: areaTypeIdToDisplayName(todo),
    })) || [];

  return (
    <Grid container spacing={1} direction="row" alignItems="center">
      <Grid item xs={2.5}>
        <FormControl
          size="small"
          fullWidth
          variant="standard"
          error={isDuplicateModelType}
          className={isDuplicateModelType ? 'error' : undefined}
        >
          <Autocomplete
            size="small"
            id="modelType"
            options={modelTypesOptions}
            value={find(modelTypesOptions, (modelType) => modelType?.value === selectedAssignment?.modelType) || null}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                error={isEmpty(selectedAssignment?.modelType)}
                label="Model Type"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {modelTypesLoading && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            onChange={(event, option) => {
              updateSelectedAssignment({
                ...selectedAssignment,
                modelType: option?.value,
                classToUse: [],
                classToIgnore: [],
              });
            }}
          />
          {isDuplicateModelType ? <FormHelperText error>Duplicate model type</FormHelperText> : ''}
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          size="small"
          id="assignment"
          options={assignmentAnnotationsOptions}
          value={
            find(
              assignmentAnnotationsOptions,
              (assignmentAnnotation) =>
                assignmentAnnotation?.value === selectedAssignment?.assignment?.annotationAssignmentId
            ) || null
          }
          renderInput={(params) => (
            <TextField {...params} required error={isEmpty(selectedAssignment?.assignment)} label="Assignment" />
          )}
          onChange={(event, option) => {
            updateSelectedAssignment({
              ...selectedAssignment,
              assignment: find(
                assignmentAnnotations,
                (assignmentAnnotation) => assignmentAnnotation?.annotationAssignmentId === option?.value
              ),
              classToUse: [],
              classToIgnore: [],
            });
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <RadioGroup
          value={String(selectedAssignment?.useClassesFromIgnore)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            updateSelectedAssignment({
              ...selectedAssignment,
              useClassesFromIgnore: e.target.value === 'true',
            });
          }}
        >
          <FormControlLabel value="false" control={<Radio size="small" />} label="Class To Use" />
          <FormControlLabel value="true" control={<Radio size="small" />} label="Class To Ignore" />
        </RadioGroup>
      </Grid>

      <Grid item xs={3}>
        <Autocomplete
          disabled={isEmpty(selectedAssignment.assignment) || isEmpty(selectedAssignment.modelType)}
          size="small"
          multiple
          disableCloseOnSelect
          limitTags={1}
          options={classOptions}
          value={
            map(
              selectedAssignment?.useClassesFromIgnore
                ? selectedAssignment?.classToIgnore
                : selectedAssignment?.classToUse,
              (classToIgnore) => find(classOptions, (classOption) => classOption?.value == classToIgnore)
            ) || []
          }
          renderTags={(tagValue, getTagProps) =>
            map(tagValue, (option, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              return <Chip key={key} label={option.label} {...tagProps} />;
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={selectedAssignment?.useClassesFromIgnore ? 'Class To Ignore' : 'Class To Use'}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoadingAreaTypes && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(event, option) =>
            updateSelectedAssignment({
              ...selectedAssignment,
              [selectedAssignment?.useClassesFromIgnore ? 'classToIgnore' : 'classToUse']: uniq(map(option, 'value')),
            })
          }
        />
      </Grid>
      <Grid item xs={0.5}>
        <DeleteOutlineIcon
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => removeSelectedAssignment(selectedAssignment.id)}
        />
      </Grid>
    </Grid>
  );
};

export default AssignmentSelection;
