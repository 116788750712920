import { ExperimentResult, Features } from 'interfaces/experimentResults';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export interface NormalizationOverride {
  labId: string;
  studyId?: string;
  name: string;
  description?: string;
  channelOverrides: { [key: string]: [min: number, max: number] };
}

export const getSlideChannelNormalizationsQueryKey = ({ id, labId }: { id: string; labId: string }) => [
  'normalizations',
  { slideId: id, labId },
];
export const getSlideChannelNormalizations = ({ id, labId }: { id: string; labId: string }, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<ExperimentResult[]>({
    url: `slides/${id}/multiplex/channel_normalizations?labId=${labId}`,
    method: 'GET',
    signal,
  });
};

export const generateNormalizationOverride = ({
  slideId,
  baseExperimentResultId,
  ...normalizationOverrideInput
}: { slideId: string; baseExperimentResultId: number } & NormalizationOverride) => {
  return apiRequestHandlerPromise<ExperimentResult>({
    url: `slides/${slideId}/multiplex/channel_normalizations/${baseExperimentResultId}`,
    method: 'POST',
    data: JSON.stringify(normalizationOverrideInput),
  });
};

export const generateManualNormalization = ({
  slideId,
  ...manualNormalizationInput
}: { slideId: string } & NormalizationOverride) => {
  return apiRequestHandlerPromise<ExperimentResult>({
    url: `slides/${slideId}/multiplex/channel_normalizations`,
    method: 'POST',
    data: JSON.stringify(manualNormalizationInput),
  });
};

export const getNormalizationValuesQueryKey = (slideId: string, experimentResultId: number) => [
  'normalizationValues',
  { slideId, experimentResultId },
];

export const getNormalizationValues = <FeatureType = Features>(
  slideId: string,
  experimentResultId: number,
  signal?: AbortSignal
) => {
  return apiRequestHandlerPromise<FeatureType>({
    url: `slides/${slideId}/multiplex/channel_normalizations/${experimentResultId}`,
    method: 'GET',
    signal,
  });
};
