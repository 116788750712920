import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { CircularProgress, IconButton } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { NumberParam, useQueryParam } from 'use-query-params';

import ConfirmationModal from 'components/modals/ConfirmationModal';
import { Permission } from 'interfaces/permissionOption';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import useResultsMutation, { ArchiveAction } from './useResultsMutation';

export interface OrchestrationArchivalProps {
  orchestrationId?: string;
  studyId: string;
  isDeleted: boolean;
  onArchivalMutation?: () => void;
}

export const OrchestrationArchival: React.FunctionComponent<OrchestrationArchivalProps> = ({
  orchestrationId,
  studyId,
  isDeleted,
  onArchivalMutation,
}) => {
  const { queryParams } = useEncodedFilters();
  const [caseIdFromParam] = useQueryParam('caseId', NumberParam);

  const { hasPermission } = usePermissions();
  const canHideSecondaryResults = hasPermission(
    Permission.CanArchiveViewRestoreOwnResults || Permission.CanViewRestoreResultsArchivedByAny
  );

  const params = useParams();
  const caseId = queryParams.slidesMode ? Number(caseIdFromParam) : Number(params.id);

  const { isLoading, handleArchiveAndRestore } = useResultsMutation(caseId, studyId, onArchivalMutation);

  const [archiveAction, setArchiveAction] = React.useState<ArchiveAction | null>(null);

  const confirmationModalText =
    archiveAction === 'archive'
      ? 'Are you sure you want to archive these results?'
      : 'Are you sure you want to restore these results?';

  return (
    <>
      {canHideSecondaryResults && (
        <IconButton
          size="small"
          onClick={(event) => {
            event?.stopPropagation();
            event?.preventDefault();
            setArchiveAction(isDeleted ? 'restore' : 'archive');
          }}
          title={isDeleted ? 'Restore Results' : 'Archive Results'}
        >
          {isLoading ? <CircularProgress size={20} /> : isDeleted ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      )}
      {archiveAction && (
        <ConfirmationModal
          title={`${archiveAction === 'archive' ? 'Archive' : 'Restore'} Results`}
          text={confirmationModalText}
          onConfirm={() => {
            handleArchiveAndRestore({ orchestrationId, archiveAction });
            setArchiveAction(null);
          }}
          onCancel={() => setArchiveAction(null)}
        />
      )}
    </>
  );
};

export default OrchestrationArchival;
