import { createAnnotationAssignment } from 'api/annotationAssignments';
import { AnnotationAssignment } from 'interfaces/annotation';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import queryClient from 'utils/queryClient';
import { useCasesParams } from 'utils/useCasesParams';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import AnnotationAssignmentDialog, { AssignmentFormValues } from './AnnotationAssignmentDialog';

export const DEFAULT_TODO_TYPE = 'annotation_marker';
export const DEFAULT_TODO = 'todo';

interface CreateAssignmentDialogProps {
  open: boolean;
  onClose: () => void;
}

export const CreateAnnotationAssignmentDialog: React.FC<CreateAssignmentDialogProps> = ({ open, onClose }) => {
  const { casesParams } = useCasesParams();
  const createAnnotationAssignmentMutation = useMutationWithErrorSnackbar({
    mutationDescription: 'Create annotation assignment',
    mutationFn: createAnnotationAssignment,
    onSuccess: () => {
      enqueueSnackbar('Annotation assignment created successfully', { variant: 'success' });
      queryClient.invalidateQueries(['annotationAssignments']);
      onClose();
    },
  });

  const handleApply: SubmitHandler<AssignmentFormValues> = async (data) => {
    const newAnnotationAssignment: Pick<AnnotationAssignment, 'name' | 'todos'> = {
      name: data.assignmentName,
      todos: [
        {
          todo: DEFAULT_TODO,
          type: DEFAULT_TODO_TYPE,
          options: data.classesToAnnotate,
        },
      ],
    };

    createAnnotationAssignmentMutation.mutate({ ...newAnnotationAssignment, ...casesParams });
  };

  return (
    <AnnotationAssignmentDialog
      open={open}
      onClose={onClose}
      title="Create Annotation Assignment"
      buttonText="Create"
      isLoadingButtonText="Creating..."
      handleApply={handleApply}
      isLoading={createAnnotationAssignmentMutation.isLoading}
    />
  );
};
