import { Box, useTheme } from '@mui/system';
import React from 'react';

export const NoDataChartPlaceholder: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        minHeight: 250,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[100],
        display: 'flex',
      }}
      justifyContent="center"
      alignItems="center"
    >
      <div>No data</div>
    </Box>
  );
};
