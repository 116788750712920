import { useSignals } from '@preact/signals-react/runtime';
import { Dictionary, compact, first, forEach, fromPairs, isEmpty, join, map, omit, pick, toPairs } from 'lodash';
import React, { useMemo } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import Loader from 'components/Loader';
import {
  baseSlidesVisualSettings,
  slidesLayerVisualizationSettings,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { Point } from 'utils/slideTransformation';
import { defaultBaseSlideVisualSettings } from '../Infobar/slidesVisualizationAndConfiguration';
import { HeatmapsImagePyramids, ImagePyramid } from '../useSlideImages';
import { updateZoomFactorFromSlide, zoomFactor } from '../zoomFactor';
import { DeckGLSlidesViewer } from './DeckGLViewer';
import { slideToManifestUrl } from './DeckGLViewer/utils/setupSlideSources';
import { OSDSlidesViewer } from './OSDSlidesViewer';

export interface SlidesViewerProps {
  slides: SlideWithChannelAndResults[];
  slidesBaseImagePyramids: Dictionary<ImagePyramid>;
  slidesHeatmapsImagePyramids: Dictionary<HeatmapsImagePyramids>;
  baseSlideLoadingStates: boolean[];
  procedureId: number;
  showNavigation: boolean;
  magnificationValue: number;
  updateMagnificationFromZoom: (zoom: number) => void;
  displaySlideId: boolean;
  unmountViewportBounds: (center: Point, zoom: number) => void;
  fieldOfView: { center: Point; zoom: number };
  hideComments?: boolean;
  focusedSlideId?: string;
}

// OSD relies on a clean slate when generating slide overlays, so we need to clear the previous settings when the slide changes
const useClearLayerSettingsOnChange = (slides: SlideWithChannelAndResults[]) => {
  const previousSlides = React.useRef(map(slides, (slide) => pick(slide, 'id', 'viewerIndex')));
  React.useEffect(() => {
    forEach(previousSlides.current, (previousSlide, index) => {
      const slide = slides?.[index];
      const slideOrViewerChanged = previousSlide?.id !== slide?.id || previousSlide?.viewerIndex !== slide?.viewerIndex;

      const slidesLayerVisualizationSettingsForPreviousSlide =
        slidesLayerVisualizationSettings[previousSlide.viewerIndex];
      if (slideOrViewerChanged && slidesLayerVisualizationSettingsForPreviousSlide) {
        const previousLayerSettings = slidesLayerVisualizationSettingsForPreviousSlide.value || {};
        const newLayerSettings = omit(previousLayerSettings, previousSlide?.id);
        slidesLayerVisualizationSettingsForPreviousSlide.value = newLayerSettings;
      }
    });
    previousSlides.current = map(slides, (slide) => pick(slide, 'id', 'viewerIndex'));
  }, [slides]);

  React.useEffect(() => {
    // Clear the previous settings when the component unmounts
    return () => {
      forEach(compact(previousSlides.current), (previousSlide) => {
        const slidesLayerVisualizationSettingsForPreviousSlide =
          slidesLayerVisualizationSettings[previousSlide.viewerIndex];
        if (slidesLayerVisualizationSettingsForPreviousSlide) {
          const previousLayerSettings = slidesLayerVisualizationSettingsForPreviousSlide.value;
          slidesLayerVisualizationSettingsForPreviousSlide.value = {
            ...previousLayerSettings,
            [previousSlide.id]: fromPairs(
              map(toPairs(previousLayerSettings?.[previousSlide.id]), ([slideId, settings]) => {
                settings.value = {
                  ...settings.value,
                  selected: false,
                };
                return [slideId, settings];
              })
            ),
          };
        }
      });
    };
  }, []);
};

export const OSDSlidesViewerWrapper: React.FC<React.PropsWithChildren<SlidesViewerProps>> = (props) => {
  useSignals();
  const slide1 = props?.slides?.[0];
  const slide2 = props?.slides?.[1];

  const osdSlides = React.useMemo(() => compact([slide1, slide2]), [slide1, slide2]);

  const slide1Id = slide1?.id;
  const slide2Id = slide2?.id;

  const slide1BaseSlideVisualSettings = baseSlidesVisualSettings[0].value?.[slide1Id] ?? defaultBaseSlideVisualSettings;
  const slide2BaseSlideVisualSettings = baseSlidesVisualSettings[1].value?.[slide2Id] ?? defaultBaseSlideVisualSettings;

  useClearLayerSettingsOnChange(osdSlides);

  const firstSlide = first(props.slides);
  React.useEffect(() => {
    if (firstSlide?.maxResolution) {
      updateZoomFactorFromSlide(firstSlide, false);
    }
  }, [firstSlide]);

  const zoomFactorValue = zoomFactor.value;

  const slideManifests = useMemo(() => map(osdSlides, slideToManifestUrl), [osdSlides]);
  return !isEmpty(compact(slideManifests)) ? (
    <OSDSlidesViewer
      key={`slides-viewer-${props.procedureId}-${join(slideManifests, ',')}`}
      {...props}
      slides={osdSlides}
      slide1BaseSlideVisualSettings={slide1BaseSlideVisualSettings}
      slide2BaseSlideVisualSettings={slide2BaseSlideVisualSettings}
      zoomFactor={zoomFactorValue}
    />
  ) : (
    <Loader />
  );
};

export const SlidesViewer: React.FC<React.PropsWithChildren<SlidesViewerProps>> = (props) => {
  const [useDeckGL] = useQueryParam('useDeckGL', BooleanParam);

  return useDeckGL ? <DeckGLSlidesViewer {...props} /> : <OSDSlidesViewerWrapper {...props} />;
};

export default SlidesViewer;
