import { Box, CircularProgress, FormControl, FormHelperText, InputLabel } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';
import Dropdown, { DropdownProps } from './Dropdown';

interface Props extends DropdownProps {
  label: string;
  className?: string;
  helperText?: string;
  error?: boolean;
  required?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  multiple?: boolean;
  loading?: boolean;
}

const LabelledDropdown: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  dataTestId,
  label,
  helperText,
  error,
  required,
  loading = false,
  ...dropdownProps
}) => (
  <FormControl
    fullWidth
    error={error}
    required={!loading && required}
    size={dropdownProps.size}
    variant={dropdownProps.variant}
  >
    <InputLabel>
      {loading ? (
        <Box sx={{ display: 'flex' }}>
          {label} <CircularProgress sx={{ marginLeft: 7 }} color="inherit" size={20} />
        </Box>
      ) : (
        label
      )}
    </InputLabel>
    <Dropdown {...dropdownProps} label={label} data-testid={dataTestId} />
    {!isEmpty(helperText) && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
);

export default LabelledDropdown;
