import { COORDINATE_SYSTEM } from '@deck.gl/core/typed';
import { TileLayer, _Tileset2D as Tileset2D } from '@deck.gl/geo-layers/typed';
import { Matrix4 } from '@math.gl/core';
import { clamp, forEach, range } from 'lodash';

const defaultProps = {
  pickable: { type: 'boolean', value: true, compare: true },
  coordinateSystem: COORDINATE_SYSTEM.DEFAULT,
  dtype: { type: 'string', value: 'Uint8', compare: true },
  preloadZoomLevels: { type: 'number', value: 0, compare: true },
};

export interface CustomTileLayerProps {
  preloadZoomLevels?: number;
  tileModelMatrix?: Matrix4;
}

/**
 * This layer serves as a proxy of sorts to the rendering done in renderSubLayers, reacting to viewport changes in a custom manner.
 */
// @ts-ignore
export default class CustomTileLayer<
  Data extends any,
  Props extends CustomTileLayerProps = CustomTileLayerProps
> extends TileLayer<Data, Props> {
  static layerName = 'CustomTileLayer';

  state!: {
    tileset: Tileset2D | null;
    isLoaded: boolean;
    frameNumber?: number;
  };

  /**
   * This function allows us to controls which viewport gets to update the Tileset2D.
   * This is a uniquely TileLayer issue since it updates based on viewport updates thanks
   * to its ability to handle zoom-pan loading.  Essentially, with a picture-in-picture,
   * this prevents it from detecting the update of some other viewport that is unwanted.
   */
  _updateTileset() {
    const { tileset } = this.state;
    const { zRange, modelMatrix, tileModelMatrix, minZoom, maxZoom, preloadZoomLevels } = this.props;

    const viewport = this.context.viewport;
    let frameNumber = tileset.update(viewport, {
      zRange,
      modelMatrix: (tileModelMatrix || modelMatrix) as Matrix4,
    });
    if (preloadZoomLevels > 0) {
      forEach(
        range(
          clamp(viewport.zoom + 1, minZoom, maxZoom),
          clamp(viewport.zoom + 1 + preloadZoomLevels, minZoom, maxZoom)
        ),
        (zoomLevel) => {
          try {
            frameNumber = tileset.update(new window.Proxy(viewport, { zoom: zoomLevel } as any), {
              zRange,
              modelMatrix: (tileModelMatrix || modelMatrix) as Matrix4,
            });
          } catch (err) {
            console.error(`Failed to update tileset for zoom level ${zoomLevel}`, err);
          }
        }
      );
    }
    const { isLoaded } = tileset;

    const loadingStateChanged = this.state.isLoaded !== isLoaded;
    const tilesetChanged = this.state.frameNumber !== frameNumber;

    if (isLoaded && (loadingStateChanged || tilesetChanged)) {
      this._onViewportLoad();
    }

    if (tilesetChanged) {
      // Save the tileset frame number - trigger a rerender
      this.setState({ frameNumber });
    }
    // Save the loaded state - should not trigger a rerender
    this.state.isLoaded = isLoaded;
  }
}

CustomTileLayer.layerName = 'CustomTileLayer';
CustomTileLayer.defaultProps = defaultProps;
