export default `\
precision highp float;
uniform vec3 intercept;
uniform mat3 coefficients0;
uniform mat3 coefficients1;
uniform mat3 coefficients2;
uniform mat3 coefficients3;
uniform mat3 coefficients4;
uniform mat3 coefficients5;
uniform mat3 coefficients6;
`;
