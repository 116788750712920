import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, IconButton, Paper, TextareaAutosize, Typography } from '@mui/material';
import { map, reject } from 'lodash';
import React, { useState } from 'react';

import {
  createProcedureSlidePinComment,
  deleteProcedureSlidePinComment,
  procedureSlidePinCommentsQueryKey,
  updateProcedureSlidePinComment,
} from 'api/procedureSlidePinComments';
import Pin from 'interfaces/pin';
import queryClient from 'utils/queryClient';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';

export interface PinModalProps {
  pin: Pin;
  slideId: string;
  procedureId: number;
  onClose(): void;
}

const PinModal: React.FunctionComponent<React.PropsWithChildren<PinModalProps>> = ({
  pin,
  slideId,
  procedureId,
  onClose,
}) => {
  const handleClose = () => {
    setComment(null);
    onClose();
  };
  const [draftComment, setComment] = useState<string | null>(null);
  const [editPin, setEditPin] = useState(false);
  const isWritingComment = pin?.draft || editPin;
  const comment = (isWritingComment ? draftComment ?? pin.comment : pin.comment) || '';
  const handleCommentChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const onEditPin = () => {
    setEditPin(true);
  };

  const createCommentMutation = useMutationWithErrorSnackbar({
    mutationDescription: 'create pin comment',
    mutationFn: (newPin: Pin) => createProcedureSlidePinComment({ procedureId, slideId, pin: newPin }),
    onSuccess: (_) => {
      queryClient.invalidateQueries(procedureSlidePinCommentsQueryKey(procedureId, slideId));
      queryClient.setQueryData(procedureSlidePinCommentsQueryKey(procedureId, slideId), (oldData: any) => {
        return { pins: [...oldData.pins, pin] };
      });
      setComment(null);
      handleClose();
    },
  });

  const deleteCommentMutation = useMutationWithErrorSnackbar({
    mutationDescription: 'delete pin comment',
    mutationFn: () => deleteProcedureSlidePinComment({ procedureId, slideId, pin }),
    onSuccess: (_) => {
      queryClient.invalidateQueries(procedureSlidePinCommentsQueryKey(procedureId, slideId));
      queryClient.setQueryData(procedureSlidePinCommentsQueryKey(procedureId, slideId), (oldData: any) => {
        return {
          pins: reject(oldData.pins, { id: pin.id }),
        };
      });
      handleClose();
    },
  });

  const updateCommentMutation = useMutationWithErrorSnackbar({
    mutationDescription: 'update pin comment',
    mutationFn: (newPin: Pin) => updateProcedureSlidePinComment({ procedureId, slideId, pin: newPin }),
    onSuccess: (_) => {
      queryClient.invalidateQueries(procedureSlidePinCommentsQueryKey(procedureId, slideId));
      queryClient.setQueryData(procedureSlidePinCommentsQueryKey(procedureId, slideId), (oldData: any) => {
        return {
          pins: map(oldData.pins, (oldPin: Pin) => (oldPin.id === pin.id ? pin : oldPin)),
        };
      });
      setComment(null);
      handleClose();
    },
  });

  const isLoading =
    createCommentMutation.isLoading || updateCommentMutation.isLoading || deleteCommentMutation.isLoading;

  return (
    <Paper sx={{ minWidth: 300 }}>
      <IconButton
        disabled={isLoading}
        onClick={handleClose}
        sx={{ padding: 0, position: 'absolute', top: 0, right: 0, zIndex: 1 }}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
      <Grid item container>
        <Grid item container p={1}>
          {isWritingComment ? (
            <TextareaAutosize
              style={{ width: 'calc(100% - 20px)' }}
              placeholder={editPin ? '' : comment}
              value={comment}
              onChange={handleCommentChanged}
              maxRows={5}
            />
          ) : (
            <Typography variant="body1">{comment}</Typography>
          )}
        </Grid>
        <Grid item container direction="column">
          {isWritingComment ? (
            <Grid item xs="auto">
              <Button
                disabled={isLoading}
                onClick={() =>
                  pin?.draft
                    ? createCommentMutation.mutate({ ...pin, comment })
                    : updateCommentMutation.mutate({ ...pin, comment })
                }
              >
                Save
              </Button>
            </Grid>
          ) : (
            <>
              <Grid item xs="auto">
                <Button disabled={isLoading} startIcon={<DeleteIcon />} onClick={() => deleteCommentMutation.mutate()}>
                  Delete
                </Button>
                <Button disabled={isLoading} startIcon={<EditIcon />} onClick={onEditPin}>
                  Edit
                </Button>
              </Grid>
            </>
          )}
          {isLoading && (
            <Grid item sx={{ position: 'absolute', bottom: 0, right: 0 }}>
              <Typography variant="body1">Loading...</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PinModal;
