import AddIcon from '@mui/icons-material/Add';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, List, Typography } from '@mui/material';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { Permission } from 'interfaces/permissionOption';
import { QualityControlLabel } from 'interfaces/qualityControlLabels';
import { Study } from 'interfaces/study';
import { map, reject } from 'lodash';
import React, { FunctionComponent } from 'react';
import { uuidv4 } from 'utils/helpers';
import { usePermissions } from 'utils/usePermissions';
import StudyLabel from '.';
import LabelBuilderModal from './LabelBuilder/LabelBuilderModal';
import { defaultLabel } from './label.util';

interface StudyLabelsProps {
  study: Study;
  setLabels: (labels: QualityControlLabel[]) => void;
  labels: QualityControlLabel[];
  hasUnsavedLabels: boolean;
}

const StudyLabelsSettings: FunctionComponent<React.PropsWithChildren<StudyLabelsProps>> = ({
  study,
  labels,
  setLabels,
  hasUnsavedLabels,
}) => {
  const { hasPermission } = usePermissions();

  const confirmationModal = useConfirmation();

  const [newLabel, setNewLabel] = React.useState<QualityControlLabel>(undefined);

  const editable = hasPermission(Permission.EditQcQaLabels);
  const createNewLabel = () => {
    const newDefaultLabel = {
      ...defaultLabel,
      id: uuidv4().slice(0, 8), // did not use lodash's slice because it is for arrays and not strings
    };

    setNewLabel(newDefaultLabel);
  };

  const removeLabel = async (label: QualityControlLabel) => {
    const result = await confirmationModal({
      title: 'Delete Label?',
    });
    if (result) {
      setLabels(reject(labels, { id: label.id }));
    }
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <Typography variant="h3">Study Labels</Typography>
        {hasUnsavedLabels && (
          <>
            <Chip
              size="small"
              sx={{
                ml: 1,
              }}
              color="primary"
              label="Unsaved Changes"
            ></Chip>
          </>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <List
          sx={{
            '& .MuiListItem-root': {
              px: 0,
            },
          }}
        >
          {labels?.length ? (
            map(labels, (label) => (
              <StudyLabel
                key={label.id}
                label={label}
                removeLabel={() => removeLabel(label)}
                study={study}
                isNew={false}
                onUpdateLabel={(updatedLabel) => {
                  setLabels(
                    map(labels, (currentLabel) => (currentLabel.id === updatedLabel.id ? updatedLabel : currentLabel))
                  );
                }}
              />
            ))
          ) : (
            <Typography>No labels found, create a new one?</Typography>
          )}
        </List>
        <Button onClick={createNewLabel} disabled={!editable} startIcon={<AddIcon />}>
          Add new Label
        </Button>
        <LabelBuilderModal
          editable={editable}
          label={newLabel}
          study={study}
          isOpen={Boolean(newLabel)}
          isNew={true}
          onUpdateLabel={(labelToAdd) => {
            setLabels([...labels, labelToAdd]);
            setNewLabel(undefined);
          }}
          onClose={() => setNewLabel(undefined)}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default StudyLabelsSettings;
