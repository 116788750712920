import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { flatMap, keyBy, map } from 'lodash';
import React from 'react';

import { defaultLayerColors } from 'components/theme/theme';
import { useParquetFile } from 'utils/useParquetFile';
import { LayerVisualizationSettings } from '../../../slidesVisualizationAndConfiguration';

export const computeDefaultParquetTestSettings = (parquetUrl: string, layers: string[]) => {
  const layerSettingsList: LayerVisualizationSettings[] = flatMap(layers, (layer, layerIndex) => {
    return [
      {
        id: `${parquetUrl}-cells-${layer}`,
        color: defaultLayerColors[+layerIndex % defaultLayerColors.length],
        opacity: 100,
        show: false,
        select: false,
      },
      {
        id: `${parquetUrl}-heatmap-${layer}`,
        color: defaultLayerColors[+layerIndex % defaultLayerColors.length],
        opacity: 100,
        show: false,
        select: false,
      },
    ];
  });

  return keyBy(layerSettingsList, 'id');
};

export const SingleParquetTestControl: React.FC<{
  slideId: string;
  viewerIndex: number;
  parquetUrl: string;
  stainTypeId: string;
  filterText: string;
}> = ({ slideId, viewerIndex, parquetUrl, stainTypeId, filterText }) => {
  useSignals();
  const { data: parsedParquet } = useParquetFile(parquetUrl);
  // http://dev.nucleai.cloud:8080/#/procedures/6809?labId=fcfd0b8c-5b73-11ed-9d37-67e3968ec953&experimentResultsSelection=&selectedSlideIds=09f9b349-83d7-4121-a813-1c1bfeda461a&selectedSlideIndices=1&fullscreenViewer=0&slideIdForReviewing=09f9b349-83d7-4121-a813-1c1bfeda461a&useDeckGL=1&fromStudyFilter=0d47a968-64e6-11ed-8d67-ab7097e6b5e3&viewersStainPreferences=mplex&didApplyStudyStainPreferences=1&parquetTestUrls=https://hyperparam-public.s3.amazonaws.com/bunnies.parquet

  console.debug('parsedParquet', parsedParquet);

  return Boolean(parsedParquet) && <div>Placeholder for Parquet Test</div>;
};

export const ParquetTestControl: React.FC<{
  parquetUrls: string[];
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  textSearch: string;
}> = ({ parquetUrls, slideId, viewerIndex, stainTypeId, textSearch }) => {
  const [expandAccordion, setExpandAccordion] = React.useState(true);

  return (
    <Accordion square expanded={expandAccordion} onChange={() => setExpandAccordion(!expandAccordion)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={12}>
            <Typography variant="h4">Parquet Tile / Heatmap Tests (Beta)</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 1 }}>
        {map(parquetUrls, (parquetUrl, urlIdx) => (
          <React.Fragment key={`${urlIdx}-${parquetUrl}`}>
            <Grid item>
              <SingleParquetTestControl
                parquetUrl={parquetUrl}
                slideId={slideId}
                viewerIndex={viewerIndex}
                stainTypeId={stainTypeId}
                filterText={textSearch}
              />
            </Grid>
            <Divider sx={{ borderBottomWidth: 3 }} />
          </React.Fragment>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
