import { OrthographicViewState, View } from '@deck.gl/core/typed';
import { NumericArray } from '@deck.gl/core/typed/types/types';

import { OrthographicMapController } from './OrthographicMapController';
import { OrthographicMapViewport } from './OrthographicMapViewport';

export type OrthographicMapViewState = OrthographicViewState & {
  /** Pitch (tilt) of the map, in degrees. `0` looks top down */
  pitch?: number;
  /** Bearing (rotation) of the map, in degrees. `0` is north up */
  bearing?: number;
  /** Min pitch, default `0` */
  minPitch?: number;
  /** Max pitch, default `60` */
  maxPitch?: number;
  /** If true, viewport will behave like an orthographic viewport */
  forceOrthographic?: boolean;
  /** Whether to ignore the pitch when calculating the viewport matrix. Default `false`. */
  ignorePitch?: boolean;
  doubleClickZoom?: boolean;
};

type OrthographicMapViewProps = {
  /** Scaler for the near plane, 1 unit equals to the height of the viewport. Default to `0.1`. Overwrites the `near` parameter. */
  nearZMultiplier?: number;
  /** Scaler for the far plane, 1 unit equals to the distance from the camera to the top edge of the screen. Default to `1.01`. Overwrites the `far` parameter. */
  farZMultiplier?: number;
  /** Custom projection matrix */
  projectionMatrix?: NumericArray;
  /** Field of view covered by the camera, in the perspective case. In degrees. If not supplied, will be calculated from `altitude`. */
  fovy?: number;
  /** If true, viewport will behave like an orthographic viewport */
  forceOrthographic?: boolean;
  /** Whether to ignore the pitch when calculating the viewport matrix. Default `false`. */
  ignorePitch?: boolean;
  /** Distance of the camera relative to viewport height. Default `1.5`. */
  altitude?: number;
  /** Whether to create an orthographic or perspective projection matrix. Default is `false` (perspective projection). */
  orthographic?: boolean;
};

export default class OrthographicMapView extends View<OrthographicMapViewState, OrthographicMapViewProps> {
  static displayName = 'OrthographicMapView';

  get ViewportType() {
    return OrthographicMapViewport;
  }

  get ControllerType() {
    return OrthographicMapController;
  }
}
