import React from 'react';

import { RowsChangesSummary } from 'components/atoms/EditableDataGrid/TableEditingContext/ChangesSummary';
import { SlideTag } from 'interfaces/slideTag';
import { slideTagFields } from 'interfaces/slideTag/slideTagFields';
import { find } from 'lodash';

export const SlideTagRowChangesSummary: React.FC<
  React.PropsWithChildren<{
    slideTagValue: string;
    slideTagDisplayString?: string;
    changes: Partial<SlideTag>;
  }>
> = ({ slideTagValue, changes, slideTagDisplayString }) => {
  return (
    <RowsChangesSummary
      rowsChanges={{ changes }}
      rowToDisplayString={() => slideTagDisplayString || `Slide Tag "${slideTagValue}"`}
      fieldToDisplayString={(fieldId) => find(slideTagFields, { dataKey: fieldId })?.label || fieldId}
      valueToDisplayString={(value, fieldId) => {
        const field = find(slideTagFields, { dataKey: fieldId });
        return field?.valueFormatter?.({ value }) ?? `${value}`;
      }}
    />
  );
};
