import { additionalNavigationParamsSchema } from 'components/Procedure/Header/NavigationPanel/additionalNavigationParamsSchema';
import { omit } from 'lodash';
import { useCallback } from 'react';
import {
  BooleanParam,
  DelimitedArrayParam,
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
} from 'use-query-params';
import { useEncodedFilters } from './useEncodedFilters';

export interface CasePageUrlProps {
  caseId: number;
  caseStudyId?: string;
  slideIdForReviewing?: string;
  selectedSlideIds?: string[];
  newPage?: number;
  labId?: string;
}

export interface SlidePageUrlProps {
  slideId: string;
  selectedSlideIds?: string[];
  caseId?: number; // caseId and caseStudyId is optional because pending slide doesn't have a case
  caseStudyId?: string;
  newPage?: number;
  labId?: string;
  activeAnnotationAssignment?: number;
}

export const useNavigationToViewerPage = () => {
  const { queryParams, generateEncodedParams } = useEncodedFilters();
  const [searchTerm] = useQueryParam('searchTerm', StringParam);
  const [additionalNavigationParams] = useQueryParams(additionalNavigationParamsSchema);
  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);

  const getSearchStringForViewerPage = useCallback(
    ({
      slideIdForReviewing,
      selectedSlideIds,
      newPage,
      caseId,
      caseStudyId,
      labId,
      activeAnnotationAssignment,
    }: {
      slideIdForReviewing?: string;
      selectedSlideIds?: string[];
      newPage?: number;
      caseId?: number; // this is for slide page (no need to save this twice in case page)
      caseStudyId?: string;
      labId?: string;
      activeAnnotationAssignment?: number;
    }): string => {
      newPage = newPage ?? queryParams.page;

      const filtersWithCaseStudyId = {
        ...omit(queryParams.filters, 'studyId'),
        ...(caseStudyId ? { studyId: caseStudyId } : {}),
      };

      const fromStudyFilter = additionalNavigationParams.fromStudyFilter ?? queryParams.filters?.studyId;

      const encodedParams = generateEncodedParams(
        {
          ...(filtersWithCaseStudyId ? { filters: filtersWithCaseStudyId } : {}),
          ...(newPage ? { page: newPage } : {}),
          ...(labId ? { labId } : {}),
        },
        {
          ...additionalNavigationParams,
          ...(fromStudyFilter ? { fromStudyFilter: fromStudyFilter } : {}),
          ...(slideIdForReviewing ? { slideIdForReviewing: slideIdForReviewing } : {}),
          ...(selectedSlideIds ? { selectedSlideIds: selectedSlideIds } : {}),
          ...(caseId ? { caseId: caseId } : {}),
          ...(searchTerm ? { searchTerm } : {}),
          pendingSlidesMode: pendingSlidesMode,
          // 0 is for the first viewer
          activeAnnotationAssignmentIdViewer0: activeAnnotationAssignment,
        },
        {
          ...additionalNavigationParamsSchema,
          pendingSlidesMode: BooleanParam,
          searchTerm: StringParam,
          caseId: NumberParam,
          selectedSlideIds: DelimitedArrayParam,
          activeAnnotationAssignmentIdViewer0: NumberParam,
        }
      );

      return encodedParams;
    },
    [
      additionalNavigationParams,
      generateEncodedParams,
      queryParams.filters,
      queryParams.page,
      searchTerm,
      pendingSlidesMode,
    ]
  );

  const getLinkToCasePage = useCallback(
    ({ caseId, caseStudyId, slideIdForReviewing, selectedSlideIds, newPage, labId }: CasePageUrlProps) => {
      const encodedParams = getSearchStringForViewerPage({
        slideIdForReviewing,
        selectedSlideIds,
        newPage,
        caseStudyId,
        labId,
      });

      return {
        pathname: `/procedures/${caseId}`,
        search: `?${encodedParams}`,
        hash: '',
      };
    },
    [getSearchStringForViewerPage]
  );

  const getUrlToCasePage = useCallback(
    (props: CasePageUrlProps) => {
      const linkToCasePage = getLinkToCasePage(props);
      return `${linkToCasePage.pathname}${linkToCasePage.search}`;
    },
    [getLinkToCasePage]
  );

  const getLinkToSlidePage = useCallback(
    ({
      slideId,
      selectedSlideIds,
      caseId,
      caseStudyId,
      newPage,
      labId,
      activeAnnotationAssignment,
    }: SlidePageUrlProps) => {
      const newSearchString = getSearchStringForViewerPage({
        selectedSlideIds: selectedSlideIds || [slideId],
        newPage,
        caseId,
        caseStudyId,
        labId,
        activeAnnotationAssignment,
      });

      return {
        pathname: `/slides/${slideId}`,
        search: `?${newSearchString}`,
        hash: '',
      };
    },
    [getSearchStringForViewerPage]
  );

  const getUrlToSlidePage = useCallback(
    (props: SlidePageUrlProps) => {
      const linkToSlidePage = getLinkToSlidePage(props);
      return `${linkToSlidePage.pathname}${linkToSlidePage.search}`;
    },
    [getLinkToSlidePage]
  );

  return {
    getLinkToCasePage,
    getUrlToCasePage,
    getLinkToSlidePage,
    getUrlToSlidePage,
  };
};
