export const SET_SYSTEM_ERROR = 'set_system_error';

export interface ErrorState {
  message: string | null;
  code?: number | null;
}

export interface SetSysteErrorAction {
  type: typeof SET_SYSTEM_ERROR;
  payload: ErrorState;
}

export type ErrorActionTypes = SetSysteErrorAction;
