import { Viewport } from '@deck.gl/core/typed';
import { useSignals } from '@preact/signals-react/runtime';
import React from 'react';

import HtmlDeckGLOverlay from 'components/HtmlDeckGLOverlay';
import HtmlDeckGLOverlayItem from 'components/HtmlDeckGLOverlay/HtmlDeckGLOverlayItem';
import { viewerDraftComments } from '../layers/usePinCommentsLayer';
import PinModal from './PinModal';

interface Props {
  procedureId: number;
  slideId: string;
  viewport: Viewport;
  viewerIndex: number;
}

const PinComments: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  procedureId,
  slideId,
  viewport,
  viewerIndex,
}) => {
  useSignals();
  const viewerSlideDraft = viewerDraftComments[viewerIndex]?.value?.[slideId];

  return (
    Boolean(viewerSlideDraft) && (
      <HtmlDeckGLOverlay viewport={viewport} overflowMargin={150}>
        <HtmlDeckGLOverlayItem
          key={viewerSlideDraft?.id || 'draft'}
          coordinates={[viewerSlideDraft.x, viewerSlideDraft.y]}
        >
          <PinModal
            slideId={slideId}
            procedureId={procedureId}
            pin={viewerSlideDraft}
            onClose={() => {
              if (!viewerDraftComments[viewerIndex]) {
                return;
              }
              const currentDraftComments = viewerDraftComments[viewerIndex].value;
              viewerDraftComments[viewerIndex].value = { ...currentDraftComments, [slideId]: null };
            }}
          />
        </HtmlDeckGLOverlayItem>
      </HtmlDeckGLOverlay>
    )
  );
};

export default PinComments;
