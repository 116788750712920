import { Grid, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import React, { FunctionComponent } from 'react';

interface LabelledDateRangeProps {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  setStartDate: (date: Dayjs | null) => void;
  setEndDate: (date: Dayjs | null) => void;
  label: string;
}

const LabelledDateRange: FunctionComponent<React.PropsWithChildren<LabelledDateRangeProps>> = ({
  label,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const inputFormat = 'DD/MM/YYYY';

  const views = ['year', 'day'] as const;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={1} alignItems="center" justifyContent="space-between" flexWrap="nowrap">
        <Grid item flexGrow={6}>
          <DesktopDatePicker
            inputFormat={inputFormat}
            label={`${label} from`}
            value={startDate > endDate ? endDate : startDate}
            onChange={startDate > endDate ? setEndDate : setStartDate}
            renderInput={(params) => <TextField fullWidth {...params} size="small" error={false} />}
            views={views}
            disableFuture
            mask={'__/__/____'}
          />
        </Grid>
        <Grid item>
          <Typography variant="body1">
            <strong>-</strong>
          </Typography>
        </Grid>
        <Grid item flexGrow={6}>
          <DesktopDatePicker
            inputFormat={inputFormat}
            label={`${label} to`}
            value={endDate < startDate ? startDate : endDate}
            onChange={endDate < startDate ? setStartDate : setEndDate}
            renderInput={(params) => <TextField fullWidth {...params} size="small" error={false} />}
            views={views}
            disableFuture
            mask={'__/__/____'}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default LabelledDateRange;
