import { forEach, isEqual } from 'lodash';
import { useEffect } from 'react';

import {
  LayerVisualizationChange,
  LayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
  useGetLayerSettingsFromUrl,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { Annotation } from 'interfaces/annotation';
import usePrevious from 'utils/usePrevious';
import { getAnnotationSettingsKey, getAnnotationTodoNameAndDefinition } from './helpers';

export const useUpdateAnnotationHeatmapsSettingsOnChange = ({
  slideId,
  viewerIndex,
  stainTypeId,
  slideAnnotations,
  annotationToUrlKey,
  activeAnnotationAssignmentId,
}: {
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  slideAnnotations: Annotation[];
  annotationToUrlKey?: Record<string, string>;
  activeAnnotationAssignmentId?: number;
}) => {
  const getLayerSettingsFromUrl = useGetLayerSettingsFromUrl();
  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  const previousSlideParams = usePrevious({ slideId, viewerIndex, slideAnnotations });
  useEffect(() => {
    if (
      slideAnnotations &&
      previousSlideParams?.slideId === slideId &&
      previousSlideParams?.viewerIndex === viewerIndex &&
      isEqual(slideAnnotations, previousSlideParams?.slideAnnotations)
    ) {
      return;
    }
    const changes: LayerVisualizationChange[] = [];
    forEach(slideAnnotations, (annotation) => {
      const { todo } = getAnnotationTodoNameAndDefinition(annotation);
      forEach(todo?.options, (option) => {
        const isActiveAnnotationAssignmentId =
          activeAnnotationAssignmentId === annotation?.annotationAssignment?.annotationAssignmentId;
        const layerSettingsKey = getAnnotationSettingsKey(annotation, option.name);

        const urlSettings = getLayerSettingsFromUrl({
          layerUrlKey: annotationToUrlKey?.[layerSettingsKey] || layerSettingsKey,
          stainTypeId,
          viewerIndex,
        });
        const newSettings: LayerVisualizationSettings = {
          show: isActiveAnnotationAssignmentId,
          selected: isActiveAnnotationAssignmentId,
          opacity: 100,
          color: option.color ? option.color : '#dddddd',
          ...urlSettings,
        };
        changes.push({ layerId: layerSettingsKey, newSettings });
      });
    });
    applyChangesToSlideLayerVisualizationSettings({
      slideId,
      viewerIndex,
      changes,
      skipUrlUpdate: true,
      changeFlow: `AnnotationHeatmaps-initial`,
    });
  }, [slideAnnotations, annotationToUrlKey, slideId, stainTypeId, viewerIndex, getLayerSettingsFromUrl]);
};
