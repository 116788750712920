import { RowsChangesSummary } from 'components/atoms/EditableDataGrid/TableEditingContext/ChangesSummary';
import { TaxonomyTag } from 'interfaces/taxonomyTag';
import { find } from 'lodash';
import React from 'react';
import { taxonomyTagFields } from './taxonomyTagFields';

const TaxonomyTagRowChangesSummary: React.FC<
  React.PropsWithChildren<{
    taxonomyTagDisplayString?: string;
    changes: Partial<TaxonomyTag>;
  }>
> = ({ changes, taxonomyTagDisplayString }) => {
  return (
    <RowsChangesSummary
      rowsChanges={{ changes }}
      rowToDisplayString={() => taxonomyTagDisplayString || `Taxonomy Tag "${changes?.displayName}"`}
      fieldToDisplayString={(fieldId) => find(taxonomyTagFields, (f) => f.dataKey === fieldId)?.label || fieldId}
      valueToDisplayString={(value, fieldId) => {
        const field = find(taxonomyTagFields, (f) => f.dataKey === fieldId);
        return field?.valueFormatter?.({ value }) ?? `${value}`;
      }}
    />
  );
};

export { TaxonomyTagRowChangesSummary };
