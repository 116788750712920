import { Procedure } from 'interfaces/procedure';
import { DisplayedField } from '../../genericFields';
import { getUpdatePathForProcedureClinicalDataField } from './helpers';

export const dateOfObjectiveResponseField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'date',` if/when we allow clinical data editing
  filterType: 'date-range',
  dataCategory: 'clinical',
  dataKey: 'date_of_objective_response',
  label: 'Objective Response Date',
  isFullDate: true,
  views: ['day', 'month', 'year'],
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.dateOfObjectiveResponse,
  updatePath: getUpdatePathForProcedureClinicalDataField('dateOfObjectiveResponse'),
};

export const recistObjectiveResponseField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'select',` if/when we allow clinical data editing
  filterType: 'checkbox-group',
  dataCategory: 'clinical',
  dataKey: 'recist_objective_response',
  label: 'RECIST Objective Response',
  enumType: 'recistObjectiveResponse',
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.recistObjectiveResponse,
  updatePath: getUpdatePathForProcedureClinicalDataField('recistObjectiveResponse'),
};

export const earlyDiscontinuationDateField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'date',` if/when we allow clinical data editing
  filterType: 'date-range',
  dataCategory: 'clinical',
  dataKey: 'early_discontinuation_date',
  label: 'Discontinuation Date',
  isFullDate: true,
  views: ['day', 'month', 'year'],
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.earlyDiscontinuationDate,
  updatePath: getUpdatePathForProcedureClinicalDataField('earlyDiscontinuationDate'),
};

export const pfsField: DisplayedField<Procedure, number> = {
  // TODO: set `cellEditType: 'number',` if/when we allow clinical data editing
  filterType: 'range',
  dataCategory: 'clinical',
  dataKey: 'pfs_days',
  label: 'PFS Months (30d)',
  conversionFactor: 30,
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.pfsDays,
  valueFormatter: ({ value }) =>
    !isNaN(Number(value)) ? `${Number(Number(value) / 30).toFixed(0)} months` : `${value ?? ''}`,
  updatePath: getUpdatePathForProcedureClinicalDataField('pfsDays'),
};

export const overallSurvivalField: DisplayedField<Procedure, number> = {
  // TODO: set `cellEditType: 'number',` if/when we allow clinical data editing
  filterType: 'range',
  dataCategory: 'clinical',
  dataKey: 'overall_survival_days',
  label: 'Overall Survival Months (30d)',
  conversionFactor: 30,
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.overallSurvivalDays,
  valueFormatter: ({ value }) =>
    !isNaN(Number(value)) ? `${Number(Number(value) / 30).toFixed(0)} months` : `${value ?? ''}`,
  updatePath: getUpdatePathForProcedureClinicalDataField('overallSurvivalDays'),
};

export const vitalStatusField: DisplayedField<Procedure, number> = {
  // TODO: set `cellEditType: 'select',` if/when we allow clinical data editing
  filterType: 'checkbox-group',
  dataCategory: 'clinical',
  dataKey: 'overall_survival_event',
  label: 'Vital Status',
  options: [
    {
      value: '0',
      label: 'Alive',
    },
    {
      value: '1',
      label: 'Deceased',
    },
  ],
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.overallSurvivalEvent,
  valueFormatter: ({ value }) => ['Alive', 'Deceased'][value],
  updatePath: getUpdatePathForProcedureClinicalDataField('overallSurvivalEvent'),
};

export const dateOfDeathField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'date',` if/when we allow clinical data editing
  filterType: 'date-range',
  dataCategory: 'clinical',
  dataKey: 'date_of_death',
  label: 'Death Date',
  isFullDate: true,
  views: ['day', 'month', 'year'],
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.dateOfDeath,
  updatePath: getUpdatePathForProcedureClinicalDataField('dateOfDeath'),
};

export default [
  dateOfObjectiveResponseField,
  recistObjectiveResponseField,
  earlyDiscontinuationDateField,
  pfsField,
  overallSurvivalField,
  vitalStatusField,
  dateOfDeathField,
];
