import { filter, isEmpty, isNull, map, reduce, values } from 'lodash';
import React, { useState } from 'react';

import { Grid, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import { Permission } from 'interfaces/permissionOption';
import { QualityControlLabel, QualityControlLabelParam } from 'interfaces/qualityControlLabels';
import { Study } from 'interfaces/study';
import { isLabelExternal } from 'utils/qcLabels';
import { usePermissions } from 'utils/usePermissions';
import QcFilterLabel from './QcFilterLabel';

interface Props {
  study?: Study;
  onChange: (key: string, value: any) => void;
  controlledValues?: QualityControlLabelParam[];
}

const QcLabelsFilterSelection: React.FC<React.PropsWithChildren<Props>> = ({ study, onChange, controlledValues }) => {
  const theme = useTheme();
  const [filterExcludedLabels, setFilterExcludedLabels] = useState<boolean | null>(null);
  const [selectedLabels, setSelectedLabels] = useState<Record<string, QualityControlLabelParam>>(
    reduce(
      controlledValues,
      (
        convertedControlledValues: Record<string, QualityControlLabelParam>,
        controlledValue: QualityControlLabelParam
      ) => ((convertedControlledValues[controlledValue.id] = controlledValue), convertedControlledValues),
      {}
    )
  );

  const { hasPermission } = usePermissions();
  const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: string, labelConfig: QualityControlLabel) => {
    const newSelectedLabels: Record<string, QualityControlLabelParam> = selectedLabels;
    if (newValue == null) {
      newSelectedLabels[labelConfig.id] = null;
    } else if (newValue === labelConfig.rejectLabel) {
      newSelectedLabels[labelConfig.id] = { id: labelConfig.id, isReject: true };
    } else {
      newSelectedLabels[labelConfig.id] = { id: labelConfig.id, isReject: false };
    }
    setSelectedLabels(newSelectedLabels);
    onChange(
      'qcLabels',
      filter(values(newSelectedLabels), (newSelectedLabel) => !isNull(newSelectedLabel))
    );
  };

  const handleExcludeChange = (event: React.MouseEvent<HTMLElement>, newValue: boolean) => {
    setFilterExcludedLabels(newValue);
    onChange('excludedSlides', newValue);
  };

  const getSelectedLabel = (qcLabelConfig: QualityControlLabel) => {
    const selectedLabelConfig = selectedLabels[qcLabelConfig.id];
    if (selectedLabelConfig) {
      return selectedLabelConfig.isReject ? qcLabelConfig.rejectLabel : qcLabelConfig.filterLabel;
    }

    return null;
  };

  if (!study) {
    return (
      <Typography style={{ color: theme.palette.grey[500] }}>
        Must choose a specific study to filter by QC labels
      </Typography>
    );
  }

  const canViewInternalLabels = hasPermission(Permission.ViewInternalSlideLabels);

  const qcLabelsConfigs = canViewInternalLabels
    ? study?.qcLabelsConfig
    : filter(study?.qcLabelsConfig, isLabelExternal); // the server should only return external labels if the user doesn't have permission to view internal labels, this is a fallback

  return (
    <Grid item container spacing={1}>
      <Grid item xs={12}>
        {(!qcLabelsConfigs || isEmpty(qcLabelsConfigs)) && (
          <Typography style={{ color: theme.palette.grey[500] }}>No QC Labels For This Study</Typography>
        )}
        {map(qcLabelsConfigs, (qcLabelConfig: QualityControlLabel) => (
          <QcFilterLabel
            key={qcLabelConfig.id}
            qcLabelConfig={qcLabelConfig}
            selectedLabel={getSelectedLabel(qcLabelConfig)}
            handleChange={handleChange}
          />
        ))}
      </Grid>
      <Grid item container spacing={0.5}>
        <Grid item>
          <Typography variant="body1">Filter excluded slides</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{`show only cases/slides that have/don't have excluded label`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <ToggleButtonGroup
            color="primary"
            exclusive
            value={filterExcludedLabels}
            onChange={handleExcludeChange}
            size="small"
            sx={{ width: '100%' }}
          >
            {map(['excluded', 'not excluded'], (label) => (
              <ToggleButton key={label} value={label == 'excluded' ? true : false} style={{ flex: 1 }}>
                {label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QcLabelsFilterSelection;
