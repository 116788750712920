import { DataGrid, DataGridProps, GridCellEditStopReasons, GridColDef } from '@mui/x-data-grid';
import {
  filter,
  find,
  fromPairs,
  get,
  includes,
  isEmpty,
  isEqual,
  keys,
  map,
  omit,
  reduce,
  some,
  values,
} from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { SlideChange, SlideUpdate, updateSlide } from 'api/slides';
import { CaseUpdate, getUpdatesFromCase } from 'api/study';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { useEditableFieldsDataGridColumns } from 'components/atoms/EditableDataGrid/useEditableFieldsDataGridColumns';
import { useRowSelectionContext } from 'components/atoms/RowSelectionContext';
import { getCheckboxColumn } from 'components/atoms/RowSelectionContext/checkboxColumn';
import { DisplayedField } from 'interfaces/genericFields';
import { unwindRows, UnwoundRow } from 'interfaces/genericFields/unwindRowsWithInnerArrays';
import { Procedure, ProcedureResponse } from 'interfaces/procedure';
import { caseBaseFields } from 'interfaces/procedure/fields/caseFields';
import { ProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { Slide, slidesEditableKeys } from 'interfaces/slide';
import { useSnackbar } from 'notistack';
import queryClient from 'utils/queryClient';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import { usePendingSlides } from '../usePendingSlides';
import { SlideRowChangesSummary } from './ChangeSummaries';
import { useCasesAndSlidesUpdateMutationsParams } from './useCasesAndSlidesUpdateMutationParams';
import { useCaseUpdateMutation } from './useCaseUpdateMutation';
import { useEditableFields } from './useEditableFields';

export interface SlidesDataGridProps {
  casesInPage: Procedure[];
  totalRows: number;
  isLoading: boolean;
  disableEditing?: boolean;
  pendingSlidesMode?: boolean;
  selectedFields: string[];
  orderedFields: Array<DisplayedField<Procedure, any, ProceduresFieldsContext>>;
  dataGridProps?: Partial<DataGridProps>;
  onCaseRowChange?: (caseId: number, changes: Partial<CaseUpdate>) => void;
  onSlideDataChange?: (slideId: string, changes: Partial<SlideUpdate>) => void;
  applyCellValueChangedClass?: (id: string | number, field: string, row: any) => boolean;
}

export const SlidesDataGrid: React.FC<React.PropsWithChildren<SlidesDataGridProps>> = ({
  dataGridProps,
  totalRows,
  casesInPage,
  isLoading,
  disableEditing,
  pendingSlidesMode,
  selectedFields,
  onCaseRowChange,
  onSlideDataChange,
  applyCellValueChangedClass,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { hasMetadataEditingPermissions, hasClinicalDataEditingPermissions } = usePermissions();

  const { isRowSelected } = useRowSelectionContext();

  const { bulkEditMode, bulkChanges, fieldsContext, getRowsWithChanges } = useTableEditingContext<
    Procedure,
    ProceduresFieldsContext,
    Slide
  >();

  const { slidesFields: fields } = useEditableFields();

  const { encodedFilters, queryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const { onMutateForSlideUpdate, onError } = useCasesAndSlidesUpdateMutationsParams({
    action: 'update slide',
  });
  const { pendingSlidesQueryKey } = usePendingSlides();

  const caseUpdateMutation = useCaseUpdateMutation({
    additionalOnError: () => cancelEditing(),
    additionalOnSuccess: () => (editingRow.current = null),
  });
  const slideUpdateMutation = useMutation({
    mutationFn: updateSlide,
    onMutate: onMutateForSlideUpdate,
    onError,
    onSuccess: (data, { slideChanges, caseId }) => {
      if (pendingSlidesMode) {
        queryClient.setQueryData<Slide[]>(pendingSlidesQueryKey, (oldData) =>
          map(oldData, (slide) => (slide.id === data.id ? (data as Slide) : slide))
        );
      } else {
        queryClient.setQueriesData<ProcedureResponse>(['procedures', encodedFilters], (oldData) => {
          const newProcedures = map(oldData?.procedures, (procedure) => ({
            ...procedure,
            slides: map(procedure.slides, (slide) => (slide.id === data.id ? data : slide)),
          }));
          return oldData ? { ...oldData, procedures: newProcedures } : oldData;
        });
      }
      enqueueSnackbar({
        message: (
          <Box>
            <Typography variant="body1">Changes saved</Typography>
            <SlideRowChangesSummary
              caseId={caseId}
              slideChanges={omit(slideChanges, 'id')}
              slideId={slideChanges.id}
              fieldsContext={fieldsContext}
            />
          </Box>
        ),
        variant: 'success',
      });
    },
    onSettled: () => {
      if (pendingSlidesMode) {
        queryClient.invalidateQueries({ queryKey: pendingSlidesQueryKey });
      } else {
        queryClient.invalidateQueries({ queryKey: ['procedures'], exact: false });
      }
    },
  });

  function handleSlideFieldSave<T extends keyof SlideUpdate>(
    id: Slide['id'],
    caseId: number,
    fieldName: T,
    value: SlideUpdate[T]
  ) {
    const slideChanges: SlideChange = {
      id: id,
      [fieldName]: value,
    };
    slideUpdateMutation.mutate({ slideChanges, labId: queryParams?.labId, caseId });
  }

  const columnVisibilityModel: { [fieldName: string]: boolean } = React.useMemo(() => {
    const visibleFields = pendingSlidesMode ? ['id', ...selectedFields] : ['id', 'caseId', ...selectedFields];
    return fromPairs(map(fields, (field) => [field.dataKey, includes(visibleFields, field.dataKey)]));
  }, [selectedFields, fields]);

  const unwoundSlidesFromCasesInPage: UnwoundRow<Procedure, Slide>[] = useMemo(() => {
    return unwindRows<Procedure, Slide>({
      rows: casesInPage,
      arrayFieldToUnwind: 'slides',
      unwoundRowIdField: 'id',
      omitUnwoundFields: ['cancerTypeId'],
    }) as any[];
  }, [casesInPage]);

  const getCaseSlidesRows = (caseId: number): UnwoundRow<Procedure, Slide>[] => {
    return filter(unwoundSlidesFromCasesInPage, (slideRecord) => {
      return slideRecord._unwoundRowMetadata.baseRowId === caseId;
    });
  };

  const isPartialCaseSelected = some(
    map(unwoundSlidesFromCasesInPage, (slide) => {
      const caseId = slide._unwoundRowMetadata.baseRowId as number;
      return (
        some(getCaseSlidesRows(caseId), (slideRow) => isRowSelected(slideRow.id)) &&
        some(getCaseSlidesRows(caseId), (slideRow) => !isRowSelected(slideRow.id))
      );
    })
  );

  const isBulkChangesContainsUniquePerCaseChange = () => {
    return some(bulkChanges, (value, key) => {
      const changedField = find(fields, { dataKey: key });
      // check if it is a case field, or a slide field that must be unique per case
      if (!includes(slidesEditableKeys, key) || (changedField as any)?.enforceUniquePerBaseRow) {
        return true;
      }
    });
  };

  const unwoundRowsWithChanges: UnwoundRow<Procedure, Slide>[] = useMemo(
    () => getRowsWithChanges(unwoundSlidesFromCasesInPage, isRowSelected),
    [unwoundSlidesFromCasesInPage, getRowsWithChanges, isRowSelected]
  );

  const editingRow = React.useRef<UnwoundRow<Procedure, Slide> | null>(null);

  const [rows, setRows] = React.useState<UnwoundRow<Procedure, Slide>[]>(unwoundRowsWithChanges);
  useEffect(() => {
    setRows(unwoundRowsWithChanges);
  }, [unwoundRowsWithChanges]);

  const tableRows = rows;

  const noRows = totalRows === 0;

  const fieldsToUse = map(fields, (field) => {
    const disableMetadataEditing = !hasMetadataEditingPermissions && field.dataCategory === 'metadata';
    const disableClinicalDataEditing = !hasClinicalDataEditingPermissions && field.dataCategory === 'clinical';

    const disableCaseEditing =
      pendingSlidesMode && some(caseBaseFields, (caseField) => caseField.dataKey == field.dataKey);

    if (disableMetadataEditing || disableClinicalDataEditing || disableCaseEditing) {
      return {
        ...field,
        cellEditType: undefined,
        unwoundRowCellEditType: undefined,
      };
    }
    return field;
  });

  const slideFieldsColumns = useEditableFieldsDataGridColumns<Procedure, ProceduresFieldsContext, Slide>({
    fields: fieldsToUse,
    isLoading,
    noRows,
    bulkEditMode,
    arrayFieldToUnwind: 'slides',
    unwoundRowIdField: 'slideId',
    baseRowIdField: 'caseId',
  });

  const columns: GridColDef<UnwoundRow<Procedure, Slide>>[] = [
    getCheckboxColumn(totalRows, bulkEditMode),
    ...slideFieldsColumns,
  ];

  const isCellValueChangedFromDirectEdit = React.useCallback(
    (id: string | number, field: string) => {
      return (
        editingRow.current?.id === Number(id) &&
        get(editingRow.current, field) !== get(find(tableRows, { id: id }), field)
      );
    },
    [tableRows]
  );

  const isCellValueChangedFromBulkEdit = React.useCallback(
    (id: string | number, field: string, row: UnwoundRow<Procedure, Slide>) => {
      return (
        bulkChanges &&
        field in bulkChanges &&
        isRowSelected(row.id) &&
        !isEqual(get(find(unwoundSlidesFromCasesInPage, { id: id }), field), bulkChanges[field])
      );
    },
    [casesInPage, bulkChanges, unwoundSlidesFromCasesInPage, isRowSelected]
  );

  const getCellClassName: DataGridProps<UnwoundRow<Procedure, Slide>>['getCellClassName'] = React.useCallback(
    ({ id, field, row }) => {
      if (find(fields, { dataKey: field })?.getError?.({ value: get(row, field), row, context: fieldsContext })) {
        return 'cell-error';
      } else if (
        isCellValueChangedFromDirectEdit(id, field) ||
        isCellValueChangedFromBulkEdit(id, field, row) ||
        applyCellValueChangedClass?.(id, field, row)
      ) {
        return 'cell-value-changed';
      } else {
        return '';
      }
    },
    [
      isCellValueChangedFromDirectEdit,
      isCellValueChangedFromBulkEdit,
      applyCellValueChangedClass,
      fieldsContext,
      fields,
    ]
  );

  const currentPageIds: string[] = React.useMemo(() => map(tableRows, 'id'), [tableRows]);

  const cancelEditing = () => {
    setRows((prevRows) => map(prevRows, (row) => (row.id === editingRow.current?.id ? editingRow.current : row)));
    editingRow.current = null;
  };

  const onCellEditStop: DataGridProps<UnwoundRow<Procedure, Slide>>['onCellEditStop'] = (params) => {
    // Stop editing a row when the user presses escape
    if (params.reason === GridCellEditStopReasons.escapeKeyDown) {
      cancelEditing();
    }
  };

  const processRowUpdate = (newRow: UnwoundRow<Procedure, Slide>, oldRow: UnwoundRow<Procedure, Slide>) => {
    const updatedFields: Partial<UnwoundRow<Procedure, Slide>> = reduce(
      newRow,
      function (result, value, key) {
        return isEqual(value, get(oldRow, key)) ? result : { ...result, [key]: value };
      },
      {}
    );

    if (isEqual(updatedFields, {})) {
      return newRow;
    }

    const slideId: string = newRow._unwoundRowMetadata.innerRowId as string;
    const caseId: number = Number(newRow._unwoundRowMetadata.baseRowId);
    const changedFieldKey = keys(updatedFields)[0];
    const changedValue = values(updatedFields)[0];

    const isSlideChange = includes(slidesEditableKeys, changedFieldKey);

    if (isSlideChange) {
      if (onSlideDataChange) {
        onSlideDataChange(slideId, { [changedFieldKey]: changedValue });
      } else {
        handleSlideFieldSave(slideId, caseId, changedFieldKey as keyof SlideUpdate, changedValue as any);
      }
    }
    if (!isSlideChange) {
      if (onCaseRowChange) {
        onCaseRowChange(caseId, getUpdatesFromCase(updatedFields));
      } else {
        caseUpdateMutation.mutate({
          studyId: newRow?.studyId,
          caseId: caseId,
          update: getUpdatesFromCase(updatedFields),
          filterParams: queryParams,
        });
      }
    }

    return {
      ...newRow,
      slides: !isSlideChange
        ? newRow.slides
        : map(newRow.slides, (slide) => (slide.id === slideId ? { ...slide, [changedFieldKey]: changedValue } : slide)),
    };
  };

  const handleCellEditStart: DataGridProps['onCellEditStart'] = (params) => {
    editingRow.current = find(rows, (row) => row.id === params.id) || null;
  };

  const [showAddSlidesSelectionDialog, setShowAddSlidesSelectionDialog] = useState(false);
  const [partialCaseSelectedWarningShown, setPartialCaseSelectedWarningShown] = useState(false);
  useEffect(() => {
    if (
      !partialCaseSelectedWarningShown &&
      bulkEditMode &&
      !isEmpty(bulkChanges) &&
      isBulkChangesContainsUniquePerCaseChange() &&
      isPartialCaseSelected &&
      showAddSlidesSelectionDialog === false
    ) {
      setPartialCaseSelectedWarningShown(true);
      setShowAddSlidesSelectionDialog(true);
    } else if (!bulkEditMode && partialCaseSelectedWarningShown) {
      setPartialCaseSelectedWarningShown(false);
    }
  }, [bulkEditMode, bulkChanges]);

  const isCellEditable =
    !disableEditing && !slideUpdateMutation.isLoading && !caseUpdateMutation.isLoading && !bulkEditMode;

  return (
    <>
      <DataGrid
        {...dataGridProps}
        loading={isLoading}
        getCellClassName={getCellClassName}
        columnVisibilityModel={columnVisibilityModel} // The checkbox column visibility is taken from the checkboxSelection param in dataGridProps
        rowSelectionModel={filter(currentPageIds, isRowSelected)}
        rows={tableRows}
        rowCount={totalRows}
        columns={columns}
        isCellEditable={(params) =>
          (!dataGridProps?.isCellEditable || dataGridProps?.isCellEditable?.(params)) && isCellEditable
        }
        onCellEditStart={handleCellEditStart}
        onCellEditStop={onCellEditStop}
        processRowUpdate={processRowUpdate}
      />
      <PartialCaseSelectedDialog
        open={showAddSlidesSelectionDialog}
        onClose={() => setShowAddSlidesSelectionDialog(false)}
      />
    </>
  );
};

const PartialCaseSelectedDialog: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') {
          return;
        }
        onClose();
      }}
      data-cy="partial-case-selected"
    >
      <DialogTitle>Partial case selected</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are editing a field that is unique per case, but there are slides in the same selected case that are not
          selected. When saving, all slides in the selected cases will be updated.
        </DialogContentText>
        <DialogActions>
          <Button disableElevation variant="contained" onClick={onClose} data-cy="ok-button-partial-case-selected">
            OK
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
