import { ChartOptions } from 'chart.js';
import { CohortWithSelectedFeatures } from 'interfaces/cohort_old';
import { curry, filter, map, range } from 'lodash';
import { inRange } from 'lodash/fp';
import {
  CategoricalChartKey,
  CountBy,
  cohortToValues,
  cohortsToDatasets,
  formatNumber,
  getGroupByCount,
  getLegendOptions,
  getVerticalScaleOptions,
} from '../chart.util';
import { borderColorPalette, colorPalette } from '../chartsColorPallete';

export const cohortToHistogramData = curry(
  (valueRange: [number, number], horizontalKey: string, bucketCount: number, cohort: CohortWithSelectedFeatures) => {
    const dataFromCohort = cohortToValues(horizontalKey);

    const values = dataFromCohort(cohort);

    const bucketSize = (valueRange[1] - valueRange[0]) / bucketCount;
    const buckets = map(range(0, bucketCount), (i: number) => {
      const start = valueRange[0] + i * bucketSize;
      const end = start + bucketSize;
      const count = filter(values, inRange(start, end)).length;

      return count / values.length;
    });

    return buckets;
  }
);

export const cohortsToHistogramDataset = (
  valueRange: [number, number],
  horizontalKey: string,
  bucketCount: number = 10
) => cohortsToDatasets('bar', cohortToHistogramData(valueRange, horizontalKey, bucketCount));

export const barChartOptions = (valueRange: [number, number], bucketCount: number): ChartOptions<'bar'> => {
  // will later combine with the other option method due to similarities
  const [min, max] = valueRange;

  const bucketSize = (max - min) / bucketCount;

  return {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          font: {
            size: 9,
          },
          boxWidth: 10,
        },
      },
      tooltip: {
        callbacks: {
          title: ([{ dataIndex }]) => {
            const bucketStart = min + dataIndex * bucketSize;
            const bucketEnd = bucketStart + bucketSize;
            return `${formatNumber(bucketStart)} - ${formatNumber(bucketEnd)}`;
          },
          label: (context) => {
            const label = context.dataset?.label || '';
            const y = context.parsed.y;

            return `${label} (Frequency ${formatNumber(y * 100)}%)`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        type: 'linear',
        position: 'bottom',
        ticks: {
          callback: (value: number) => {
            if (max === -Infinity) {
              return value;
            }
            const start = min + value * bucketSize;
            const end = start + bucketSize;
            return `${formatNumber(start)} - ${formatNumber(end)}`;
          },
        },
      },
      y: getVerticalScaleOptions('Frequency %'),
    },
  };
};

export const cohortToCategoricalHistogramDataset = curry(
  (
    horizontalKey: CategoricalChartKey,
    labels: string[],
    countBy: CountBy,
    index: number,
    cohort: CohortWithSelectedFeatures
  ) => {
    const groupByCount = getGroupByCount(horizontalKey, cohort.procedures, countBy);
    const data: number[] = labels.map((label) => groupByCount[label] || 0);

    return {
      label: cohort.name,
      data,
      backgroundColor: colorPalette[index % colorPalette.length],
      borderColor: borderColorPalette[index % borderColorPalette.length],
    };
  }
);

export const categoricalHistogramChartOptions = (countBy: CountBy = 'Cases'): ChartOptions<'bar'> => {
  return {
    maintainAspectRatio: false,
    plugins: {
      legend: getLegendOptions('bottom'),
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset?.label || '';
            const y = context.parsed.y;

            return `${label} - ${y} ${countBy}`;
          },
        },
      },
    },
    scales: {
      y: getVerticalScaleOptions(`Number of ${countBy}`),
    },
  };
};
