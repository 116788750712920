import { ChartOptions } from 'chart.js';
import { CohortWithSelectedFeatures } from 'interfaces/cohort_old';
import { curry, get, keys, map } from 'lodash';
import { map as fmap } from 'lodash/fp';
import { getCasesMap } from 'utils/cohort.util';
import { CategoricalChartKey, NumericalChartKey, caseToFeatureValue } from '../chart.util';
import { borderColorPalette, colorPalette } from '../chartsColorPallete';

export const cohortToBoxDataset = curry(
  (
    categoricalKey: CategoricalChartKey,
    featureKey: NumericalChartKey,
    allGroups: string[],
    cohort: CohortWithSelectedFeatures,

    colorIndex: number
  ) => {
    const casesMap = getCasesMap(categoricalKey, cohort);

    const orderedCaseGroups = map(allGroups, (group) => get(casesMap, group, []));

    const data = map(orderedCaseGroups, fmap(caseToFeatureValue(featureKey?.name)));
    return {
      label: cohort.name,
      data,
      backgroundColor: colorPalette[colorIndex % colorPalette.length],
      borderColor: borderColorPalette[colorIndex % borderColorPalette.length],
    };
  }
);

export const cohortToBoxLabels = curry((key: CategoricalChartKey, cohort: CohortWithSelectedFeatures): string[] => {
  const casesMap = getCasesMap(key, cohort);

  if (!casesMap) return [];
  return keys(casesMap);
});

export const boxChartOptions = (): ChartOptions<'bar'> => {
  // will later combine with the other option method due to similarities

  return {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          font: {
            size: 9,
          },
          boxHeight: 10,
          boxWidth: 10,
        },
      },
    },
  };
};
