import { FormControl, FormControlProps, TextField } from '@mui/material';
import { includes, map } from 'lodash';
import React, { FunctionComponent } from 'react';
import { UrlUpdateType } from 'use-query-params';
import { useGetNameOverrideOrDisplayNameWithContext } from 'utils/features/contextHooks';
import { ChartKey } from '../chart.util';
import { VirtualizedAutocomplete } from './VirtualizedAutocomplete';

interface Props extends FormControlProps {
  keys: ChartKey[];
  selectedKey: ChartKey;
  updateSelectedKey: (newValue: ChartKey, updateType?: UrlUpdateType) => void;
  name: string;
  addStainToFeatureName?: boolean;
  enableNullValue?: boolean;
}

const noChartKeys: ChartKey[] = [];

const ChartKeySelect: FunctionComponent<React.PropsWithChildren<Props>> = ({
  keys,
  selectedKey,
  updateSelectedKey,
  name,
  addStainToFeatureName = false,
  enableNullValue = false,
  ...formControlProps
}) => {
  const { getNameOverrideOrDisplayNameWithContext, isLoadingFormatterData } =
    useGetNameOverrideOrDisplayNameWithContext(addStainToFeatureName);

  const value = keys && selectedKey && includes(map(keys, 'name'), selectedKey.name) ? selectedKey : null;

  const distinctTypes = new Set(map(keys, 'type'));
  const hasMoreThenOneKeyType = distinctTypes.size > 1;

  const fontSize = formControlProps?.size === 'small' ? 12 : 14;

  return (
    <FormControl {...formControlProps} fullWidth>
      <VirtualizedAutocomplete
        id="virtualize-chart-key-select"
        value={value}
        fullWidth
        style={{ fontSize: fontSize }}
        options={keys || noChartKeys}
        groupBy={(option) => (hasMoreThenOneKeyType ? option.type : null)}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{ ...params.InputProps, style: { fontSize: fontSize } }}
            variant={formControlProps?.variant}
            size={formControlProps?.size}
            fullWidth={formControlProps?.fullWidth}
            label={name}
          />
        )}
        renderOption={(option) => ({
          key: option.name,
          displayName: getNameOverrideOrDisplayNameWithContext(option.name),
        })}
        onChange={(event: any, newValue: ChartKey | null) => {
          if (newValue || enableNullValue) {
            updateSelectedKey(newValue);
          }
        }}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : getNameOverrideOrDisplayNameWithContext(option.name)
        }
        isOptionEqualToValue={(option, currValue) => option.name === currValue.name && option.type === currValue.type}
        loading={isLoadingFormatterData}
      />
    </FormControl>
  );
};

export default ChartKeySelect;
