import { Grid, TextField } from '@mui/material';
import { AnnotationAssignmentAutocomplete } from 'components/atoms/AnnotationAssignmentAutocomplete';
import { TodoOption } from 'interfaces/annotation';
import { MULTIPLEX_STAIN_ID } from 'interfaces/stainType';
import { compact, flatMap, includes, map, uniq } from 'lodash';
import React, { useMemo } from 'react';
import { CasesParams } from 'utils/useCasesParams';
import { useCurrentLabId } from 'utils/useCurrentLab';
import ClassesToAnnotateList from '../ClassesToAnnotateList';

interface AnotherAssignmentProps {
  selectedClassesToAnnotate: TodoOption[];
  onClassesSelected: (classes: TodoOption[]) => void;
  assignmentId: number | null;
  setAssignmentId: (assignmentId: number | null) => void;
  assignmentTodoOptions: TodoOption[];
  setAssignmentTodoOptions: (assignmentTodoOptions: TodoOption[]) => void;
}

const AnotherAssignment: React.FC<AnotherAssignmentProps> = ({
  selectedClassesToAnnotate,
  onClassesSelected,
  assignmentId,
  setAssignmentId,
  assignmentTodoOptions,
  setAssignmentTodoOptions,
}) => {
  const { labId } = useCurrentLabId();

  const selectedClassesToAnnotateNames = useMemo(
    () => compact(map(selectedClassesToAnnotate, 'name')) ?? [],
    [selectedClassesToAnnotate]
  );

  return (
    <Grid item container direction="column" mt={2}>
      <Grid item px={2}>
        <AnnotationAssignmentAutocomplete
          casesParams={{ labId } as CasesParams}
          slideStainType={MULTIPLEX_STAIN_ID}
          selectedValue={assignmentId ?? null}
          onChange={(event, newValue) => {
            setAssignmentId(newValue?.annotationAssignmentId);
            setAssignmentTodoOptions(uniq(compact(flatMap(newValue?.todos, 'options'))));
          }}
          disableCloseOnSelect
          renderInput={(params) => <TextField {...params} label="Select Assignment" />}
        />
      </Grid>
      <Grid item xs={true} sx={{ overflow: 'auto' }}>
        <ClassesToAnnotateList
          classesToAnnotate={assignmentTodoOptions}
          isSelectable
          selectProps={{
            isTodoSelected: (id) => includes(selectedClassesToAnnotateNames, id),
            selectedClassesToAnnotate,
            onClassesSelected,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AnotherAssignment;
