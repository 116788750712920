import { DisplayedField } from 'interfaces/genericFields';
import { Permission, permissionToName } from 'interfaces/permissionOption';
import { Role } from 'interfaces/roles';
import { find, join, map } from 'lodash';
import { RolesFieldsContext } from './rolesFieldsContext';

export const idField: DisplayedField<Role, string> = {
  filterType: 'multiSelect',
  dataKey: 'id',
  label: 'Role ID',
  columnWidth: { width: 90 },
};

export const nameField: DisplayedField<Role, string> = {
  cellEditType: 'text',
  filterType: 'text', // TODO: change to multiSelect (based on search filter perhaps) or text search
  dataKey: 'name',
  label: 'Name',
  columnWidth: { width: 150 },
};

export const descriptionField: DisplayedField<Role, string> = {
  cellEditType: 'text',
  filterType: 'text',
  dataKey: 'description',
  label: 'Description',
  columnWidth: { width: 250 },
};

export const permissionsField: DisplayedField<Role, string[], RolesFieldsContext> = {
  filterType: 'multiSelect',
  cellEditType: 'checkbox-group',
  dataKey: 'permissions',
  label: 'Permissions',
  columnWidth: { width: 250 },
  optionsGetter: ({ permissions }) =>
    map(permissions, (permission) => ({ value: permission.permission, label: permissionToName(permission) })),
  valueFormatter: ({ value }, { permissions }) =>
    join(
      map((value || []) as Permission[], (id) => {
        const entry = find(permissions, { permission: id });
        return permissionToName(entry, id);
      }),
      ', '
    ),
};

export const labSpecificField: DisplayedField<Role, boolean> = {
  cellEditType: 'checkbox',
  filterType: 'checkbox',
  dataKey: 'labSpecific',
  label: 'Lab Specific',
  columnWidth: { width: 100 },
};

export const rolesFields: Array<DisplayedField<Role, any>> = [
  idField,
  nameField,
  descriptionField,
  permissionsField,
  labSpecificField,
];
