import { useSignals } from '@preact/signals-react/runtime';
import { Dictionary, find, forEach, groupBy, isEmpty, isEqual, sortBy } from 'lodash';
import { useEffect } from 'react';

import {
  getMarkerPositivityParentHeatmapId,
  ParquetLayerType,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/parquetLayers/helpers';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import usePrevious from 'utils/usePrevious';
import {
  LayerVisualizationChange,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
  useGetLayerSettingsFromUrl,
} from '../../../slidesVisualizationAndConfiguration';
import { computeDefaultCellTableLayerSettings, getCellTableLayerId } from './CellTableControl';

export const useUpdateCellTableHeatmapsSettingsOnChange = ({
  slideId,
  viewerIndex,
  stainTypeId,
  layerIdsToUrlKeys,
  parquetHeatmapByType,
}: {
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  layerIdsToUrlKeys?: Record<string, string>;
  parquetHeatmapByType: Dictionary<FeatureMetadata[]>;
}) => {
  useSignals();
  const getLayerSettingsFromUrl = useGetLayerSettingsFromUrl();
  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  const currentSlideParams = {
    slideId,
    viewerIndex,
  };
  const previousSlideParams = usePrevious(currentSlideParams);
  useEffect(() => {
    if (isEqual(currentSlideParams, previousSlideParams)) {
      return;
    }
    const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
    const changes: LayerVisualizationChange[] = [];
    forEach(parquetHeatmapByType[ParquetLayerType.CellClassification], (cellTableHeatmap) => {
      const parquetClasses = cellTableHeatmap.options;
      if (slideId && !isEmpty(parquetClasses)) {
        forEach(parquetClasses, (layer, layerIndex) => {
          const computedLayerSettings = computeDefaultCellTableLayerSettings(cellTableHeatmap, layerIndex, layer);
          const layerSettingsKey = getCellTableLayerId(cellTableHeatmap, layer.key);
          const previousSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId]?.[layerSettingsKey]?.value;
          const layerUrlKey = layerIdsToUrlKeys?.[layerSettingsKey] || layerSettingsKey;
          const urlSettings = getLayerSettingsFromUrl({ layerUrlKey, stainTypeId, viewerIndex });
          changes.push({
            layerId: layerSettingsKey,
            newSettings: {
              ...computedLayerSettings,
              ...(Boolean(previousSettings) && previousSettings),
              ...urlSettings,
            },
          });
        });
      }
    });

    const groupMarkerPositivityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerPositivity],
      getMarkerPositivityParentHeatmapId
    );

    forEach(groupMarkerPositivityHeatmapsById, (parquetHeatmapsByParentId, parentHeatmapId) => {
      forEach(sortBy(parquetHeatmapsByParentId, 'columnName'), (markerPositivityHeatmap, markerIndex) => {
        const positiveOption = find(markerPositivityHeatmap.options, 'key');
        const computedLayerSettings = computeDefaultCellTableLayerSettings(
          markerPositivityHeatmap,
          markerIndex,
          positiveOption
        );
        const layerSettingsKey = `${parentHeatmapId}-${markerPositivityHeatmap.columnName}`;
        const previousSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId]?.[layerSettingsKey]?.value;
        const layerUrlKey = layerIdsToUrlKeys?.[layerSettingsKey] || layerSettingsKey;
        const urlSettings = getLayerSettingsFromUrl({ layerUrlKey, stainTypeId, viewerIndex });
        changes.push({
          layerId: layerSettingsKey,
          newSettings: {
            ...computedLayerSettings,
            ...(Boolean(previousSettings) && previousSettings),
            ...urlSettings,
          },
        });
      });

      applyChangesToSlideLayerVisualizationSettings({
        slideId,
        viewerIndex,
        changes,
        skipUrlUpdate: true,
        changeFlow: `CellTableControl-initial`,
      });
    });
  }, [
    viewerIndex,
    slideId,
    stainTypeId,
    layerIdsToUrlKeys,
    parquetHeatmapByType,
    getLayerSettingsFromUrl,
    applyChangesToSlideLayerVisualizationSettings,
  ]);
};
