import { Button } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateStudy } from 'api/study';
import { SnackbarCloseButton } from 'components/Snackbars/SnackbarCloseButton';
import { Study, StudyUpdate } from 'interfaces/study';
import { find, findIndex, keys, pick, slice } from 'lodash';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import React from 'react';
import { useCurrentLabId } from './useCurrentLab';

type ContentEditableState = {
  previousValue: Study;
  updatedValue: Study;
};

const useStudyMutations = (
  study: Study,
  {
    onSuccess: additionalOnSuccess,
    onCloseSnackbar = () => {},
  }: {
    onSuccess?: (isUndo: boolean, updatedStudy: Partial<Study>, context: any) => void;
    onCloseSnackbar?: () => void;
  } = {}
) => {
  const { labId } = useCurrentLabId();
  const queryClient = useQueryClient();
  const STUDIES_WITH_STATISTICS_QUERY_KEY = ['studies', labId, 'withStatistics'];
  const STUDIES_QUERY_KEY = ['studies', labId];

  const enqueueSnackbarSave = (studyState: ContentEditableState, undoChanges: boolean) => {
    closeSnackbar(getSnackbarKey(studyState.previousValue));

    enqueueSnackbar(undoChanges ? 'Changes discarded' : 'Changes saved', {
      key: getSnackbarKey(studyState.updatedValue),
      variant: 'default',
      style: { textTransform: 'none' },
      action: (key) => (
        <>
          {!undoChanges && (
            <Button
              data-cy={
                'undo-button-' +
                studyState.previousValue.id +
                studyState.previousValue.name +
                studyState.previousValue.description
              }
              color="secondary"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                closeSnackbar(key);
                studyUndoMutation.mutate(studyState.previousValue);
              }}
            >
              UNDO
            </Button>
          )}
          <SnackbarCloseButton snackbarKey={key} onClick={onCloseSnackbar} />
        </>
      ),
    });
  };

  const getSnackbarKey = (studySnapshot: Study) => {
    return JSON.stringify(studySnapshot);
  };

  const replaceUpdatedStudy = (oldStudies: Study[], studyUpdates: Partial<StudyUpdate>) => {
    const index = findIndex(oldStudies, (item) => item.id === studyUpdates.id);

    const updatedStudy = {
      ...oldStudies[index],
      ...studyUpdates,
    };

    return [...slice(oldStudies, 0, index), updatedStudy, ...slice(oldStudies, index + 1)];
  };

  const onMutate = async (studyUpdate: StudyUpdate) => {
    await queryClient.cancelQueries(STUDIES_QUERY_KEY);
    await queryClient.cancelQueries(STUDIES_WITH_STATISTICS_QUERY_KEY);

    const previousStudy: Study = find(
      queryClient.getQueryData<Study[]>(STUDIES_QUERY_KEY),
      (currStudy: Study) => currStudy.id === studyUpdate.id
    );

    queryClient.setQueryData(STUDIES_QUERY_KEY, (old: any) => replaceUpdatedStudy(old, studyUpdate));
    queryClient.setQueryData(STUDIES_WITH_STATISTICS_QUERY_KEY, (old: any) => replaceUpdatedStudy(old, studyUpdate));

    return { updateForUndo: pick(previousStudy, keys(studyUpdate)) };
  };

  const onError = (err: any, studyUpdate: StudyUpdate, context: any) => {
    queryClient.setQueryData(STUDIES_QUERY_KEY, (old: any) => replaceUpdatedStudy(old, context?.updateForUndo));
    queryClient.setQueryData(STUDIES_WITH_STATISTICS_QUERY_KEY, (old: any) =>
      replaceUpdatedStudy(old, context?.updateForUndo)
    );

    enqueueSnackbar('Error occurred, changes are not saved', {
      variant: 'error',
    });
  };

  const onSettled = () => {
    queryClient.invalidateQueries(STUDIES_QUERY_KEY);
    queryClient.invalidateQueries(STUDIES_WITH_STATISTICS_QUERY_KEY);
  };

  const onSuccess = (isUndo: boolean, studyUpdate: StudyUpdate, context: any) => {
    const fullUpdatedStudy = { ...study, ...studyUpdate };
    const state: ContentEditableState = {
      previousValue: { ...study, ...context?.updateForUndo },
      updatedValue: fullUpdatedStudy,
    };
    enqueueSnackbarSave(state, isUndo);
    additionalOnSuccess?.(isUndo, studyUpdate, context);
  };

  const studyMutation = useMutation(updateStudy, {
    onMutate,
    onError,
    onSuccess: (data, studyUpdate: StudyUpdate, context) => onSuccess(false, studyUpdate, context),
    onSettled,
  });

  const studyUndoMutation = useMutation(updateStudy, {
    onMutate,
    onError,
    onSuccess: (data, studyUpdate: StudyUpdate, context) => onSuccess(true, studyUpdate, context),
    onSettled,
  });

  function handleFieldSave<T extends keyof StudyUpdate>(fieldName: T, value: StudyUpdate[T]) {
    if (value !== study[fieldName]) {
      const studyUpdate: Partial<StudyUpdate> = {
        id: study.id,
        [fieldName]: value,
      };
      studyMutation.mutate(studyUpdate);
    }
  }

  return {
    handleFieldSave,
    studyMutation,
  };
};

export default useStudyMutations;
