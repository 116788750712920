import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';

export const UploadBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 10px',
  marginBottom: '20px',
  position: 'relative',
}));

export const FieldContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '10px',

  '.combobox': {
    width: '100%',
    '.combobox-header': {
      height: '36px',
    },
  },
}));

export const SlideDirectoryFieldBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  padding: '10px 10px',
  width: ' 619px',
  background: theme.palette.grey[100],
  borderRadius: '4px',
  color: theme.palette.grey[600],
}));

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const FieldText = styled(Box)(({ theme }) => ({
  padding: '10px 10px 0px',
  width: '170px',
  height: ' 36px',
  background: theme.palette.grey[100],
  borderRadius: '4px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  color: theme.palette.grey[600],
}));
