import { Button, Divider, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import CohortQueryList from 'components/SearchFilters/CohortQueryList';
import { Cohort } from 'interfaces/cohort';
import moment from 'moment';
import React from 'react';

interface Props {
  cohort: Cohort;
  applyFunction?: () => void;
}

const CohortDetails = ({ cohort, applyFunction }: Props) => {
  const theme = useTheme();
  return (
    <Grid container direction="column" spacing={1} p={2}>
      <Grid
        item
        container
        sx={{
          color: theme.palette.secondary.main,
          maxHeight: '200px',
          overflowY: 'auto',
        }}
      >
        <Grid container item direction="column" spacing={1}>
          <Grid item>
            <Typography variant="h4">Created at:</Typography>
            <Typography variant="body2">{`${moment(cohort.createdAt).format('ll')}`}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4">Filters:</Typography>
            <CohortQueryList queryObject={cohort.queryObject} showIcon={false} />
          </Grid>
          {cohort.queryObject?.fromSelection && (
            <Grid item>
              <Typography variant="h4">Created From Selection</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>

      {applyFunction && (
        <Grid container item justifyContent="center" direction="column" spacing={1}>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Tooltip
              arrow
              placement="right"
              title="Click to make a query according to the filters that are included in this cohort."
            >
              <Button onClick={applyFunction} variant="contained" color="secondary">
                Apply Cohort Filters
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CohortDetails;
