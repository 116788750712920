import { map } from 'lodash';
import React from 'react';

import { Box, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { updateProcedure } from 'api/study';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { Procedure, ProcedureResponse } from 'interfaces/procedure';
import { ProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { enqueueSnackbar } from 'notistack';
import queryClient from 'utils/queryClient';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { ProcedureRowChangesSummary } from './ChangeSummaries';
import { useCasesAndSlidesUpdateMutationsParams } from './useCasesAndSlidesUpdateMutationParams';

export const useCaseUpdateMutation = ({
  additionalOnError,
  additionalOnSuccess,
}: {
  additionalOnError?: (error: any, variables: any, context: any) => void;
  additionalOnSuccess?: (data: any, context: any) => void;
}) => {
  const { onMutateForCaseUpdate: onMutate, onError } = useCasesAndSlidesUpdateMutationsParams({
    action: 'update case',
  });
  const { fieldsContext } = useTableEditingContext<Procedure, ProceduresFieldsContext>();
  const { encodedFilters } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  return useMutation({
    mutationFn: updateProcedure,
    onMutate,
    onError: (error: any, variables: any, context: any) => {
      onError(error, variables, context);
      additionalOnError(error, variables, context);
    },
    onSuccess: (data, { update, caseId }) => {
      queryClient.invalidateQueries({ queryKey: ['procedure'], exact: false });

      queryClient.setQueryData<ProcedureResponse>(['procedures', encodedFilters], (oldData) => {
        const newProcedures = map(oldData?.procedures, (procedure) => (data?.id === procedure.id ? data : procedure));
        return oldData ? { ...oldData, procedures: newProcedures } : oldData;
      });
      additionalOnSuccess(data, { update, caseId });
      enqueueSnackbar({
        message: (
          <Box>
            <Typography variant="body1">Changes saved</Typography>
            <ProcedureRowChangesSummary
              caseId={caseId}
              rowChanges={{ [caseId]: update }}
              fieldsContext={fieldsContext}
            />
          </Box>
        ),
        variant: 'success',
      });
    },
  });
};
