import { GridRowId, GridRowModesModel } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { useQueryClient } from '@tanstack/react-query';
import { reject } from 'lodash';
import React from 'react';

import { createStainTypeOption, deleteStainTypeOption, updateStainTypeOption } from 'api/stainTypes';
import { useCrudControlsColumns } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { StainType } from 'interfaces/stainType';
import { stainTypeFields } from 'interfaces/stainType/stainTypeFields';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { StainTypeRowChangesSummary } from './StainTypeRowChangesSummary';
import { StainTypeDraft } from './types';
import { getStainTypeError, getStainTypeId } from './utils';

export const useStainTypesColumns = ({
  apiRef,
  noRows,
  draftStainTypes,
  rowModesModel,
  stainTypes,
  setDraftStainTypes,
  setRowModesModel,
}: {
  noRows?: boolean;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  draftStainTypes: StainTypeDraft[];
  rowModesModel: GridRowModesModel;
  stainTypes: Array<StainType | StainTypeDraft>;
  setDraftStainTypes: React.Dispatch<React.SetStateAction<Array<Omit<StainType, 'index'>>>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();
  const commonMutationOptions = React.useMemo(() => ({ onError: () => setMutatingRowId(undefined) }), []);

  const queryClient = useQueryClient();

  const createStainTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (newStainType) => {
      queryClient.invalidateQueries(['stainTypes']);
      queryClient.setQueryData(['stainTypes'], (oldData: StainType[]) => [...oldData, newStainType]);
    },
    mutationFn: createStainTypeOption,
    mutationDescription: 'create stain type',
  });

  const updateStainTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['stainTypes']);
      queryClient.setQueryData(['stainTypes'], (oldData: StainType[]) => {
        return [...reject(oldData, { id: mutatingRowId }), variables];
      });
    },
    mutationFn: updateStainTypeOption,
    mutationDescription: 'update stain type',
  });

  const deleteStainTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess() {
      queryClient.invalidateQueries(['stainTypes']);
    },
    mutationFn: deleteStainTypeOption,
    mutationDescription: 'delete stain type',
  });

  const columns = useCrudControlsColumns<StainType, StainTypeDraft>({
    createMutation: createStainTypeMutation,
    deleteMutation: deleteStainTypeMutation,
    updateMutation: updateStainTypeMutation,
    apiRef,
    rowModesModel,
    setRowModesModel,
    setDraftRows: setDraftStainTypes,
    draftRows: draftStainTypes,
    currentRows: stainTypes,
    rowTypeFields: stainTypeFields,
    getRowError: getStainTypeError,
    noRows,
    getCancelEditConfirmationModalOptions: ({ newRowValue, isDraft, changes }) => ({
      title: `Cancel stain type ${isDraft ? 'creation' : 'update'}`,
      text: (
        <StainTypeRowChangesSummary
          stainTypeDisplayString={`Stain Type "${newRowValue?.displayName}" (${
            newRowValue?.id ?? getStainTypeId(newRowValue)
          })`}
          changes={changes}
        />
      ),
    }),
    getSaveConfirmationModalOptions: ({ newRowValue, isDraft, changes }) => ({
      title: `${isDraft ? 'Create' : 'Update'} stain type`,
      text: (
        <StainTypeRowChangesSummary
          stainTypeDisplayString={`Stain Type "${newRowValue?.displayName}" (${
            newRowValue?.id ?? getStainTypeId(newRowValue)
          })`}
          changes={changes}
        />
      ),
    }),
    getDeleteConfirmationModalOptions: () => ({
      title: 'Delete Stain Type',
      text: 'Are you sure you want to delete this stain type?',
    }),
    idGetter: getStainTypeId,
    draftIdField: 'draftId',
    mutatingRowId,
    setMutatingRowId,
  });

  return columns;
};
