import BreastLogo from './organs/breast.svg';
import CervixLogo from './organs/cervix.svg';
import ColonLogo from './organs/colon.svg';
import DermLogo from './organs/derm.svg';
import GILogo from './organs/gi.svg';
import GynaecologyLogo from './organs/gynecology.svg';
import LiverLogo from './organs/liver.svg';
import LungLogo from './organs/lung.svg';
import LymphLogo from './organs/lymph.svg';
import NALogo from './organs/na.svg';
import ProstateLogo from './organs/prostate.svg';
import StomachLogo from './organs/stomach.svg';
import UrothelialLogo from './organs/urothelial.svg';
import ColorectalLogo from './organs/colorectal.svg';
import CervicalLogo from './organs/cervical.svg';

export interface Specimen {
  abbreviation: string;
  img: string;
}

export const specimenMap: { [key: string]: Specimen } = {
  Breast: {
    abbreviation: 'breast',
    img: BreastLogo,
  },
  Cervical: {
    abbreviation: 'cervical',
    img: CervicalLogo,
  },
  Cervix: {
    abbreviation: 'Cervix',
    img: CervixLogo,
  },
  Colon: {
    abbreviation: 'colon',
    img: ColonLogo,
  },
  Colorectal: {
    abbreviation: 'colorectal',
    img: ColorectalLogo,
  },
  GI: {
    abbreviation: 'gi',
    img: GILogo,
  },
  Gynaecology: {
    abbreviation: 'gynaecology',
    img: GynaecologyLogo,
  },
  Liver: {
    abbreviation: 'Liver',
    img: LiverLogo,
  },
  Lung: {
    abbreviation: 'lung',
    img: LungLogo,
  },
  'Lymph Nodes': {
    abbreviation: 'lymph nodes',
    img: LymphLogo,
  },
  'N/A': {
    abbreviation: 'na',
    img: NALogo,
  },
  Prostate: {
    abbreviation: 'prostate',
    img: ProstateLogo,
  },
  Skin: {
    abbreviation: 'derm',
    img: DermLogo,
  },
  Stomach: {
    abbreviation: 'stomach',
    img: StomachLogo,
  },
  Urothelial: {
    abbreviation: 'Urothelial',
    img: UrothelialLogo,
  },
};
