import { GridRowParams } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import React from 'react';

import { doesUpdatingRowHaveRequiredFields, isUpdatingRowUnique } from 'components/atoms/EditableDataGrid/helpers';
import { StainType } from 'interfaces/stainType';
import { aliasesField, idField } from 'interfaces/stainType/stainTypeFields';
import { reject } from 'lodash';
import { uuidv4 } from 'utils/helpers';
import { StainTypeDraft } from './types';

export const generateDraftId = () => `draft-${uuidv4()}`;
export const getStainTypeId = (row: StainType | StainTypeDraft) =>
  (row as StainTypeDraft).draftId ?? row.id ?? generateDraftId();

export const getStainTypeError = ({
  id,
  apiRef,
}: GridRowParams<StainType> & {
  apiRef: React.MutableRefObject<GridApiCommunity>;
}) => {
  const hasDuplicateId = !isUpdatingRowUnique({
    apiRef,
    rowId: id,
    uniqueFields: ['id'],
    getId: getStainTypeId,
  });

  const idError = idField.getError?.({ value: apiRef?.current?.getRowWithUpdatedValues?.(id, 'id').id });

  const hasDuplicateDisplayName = !isUpdatingRowUnique({
    apiRef,
    rowId: id,
    uniqueFields: ['displayName'],
    getId: getStainTypeId,
  });

  const missingId = !doesUpdatingRowHaveRequiredFields({
    apiRef,
    rowId: id,
    requiredFields: ['id'],
  });

  const missingDisplayName = !doesUpdatingRowHaveRequiredFields({
    apiRef,
    rowId: id,
    requiredFields: ['displayName'],
  });
  const rowModels = apiRef?.current.getRowModels();
  const rows = Array.from(rowModels.values());
  const otherRows = reject(rows, { id: id });

  const aliasesError = aliasesField.getError({
    value: apiRef?.current?.getRowWithUpdatedValues?.(id, 'id').aliases,
    row: apiRef?.current?.getRowWithUpdatedValues?.(id, 'id') as StainType,
    context: { stainTypes: otherRows as (StainType | StainTypeDraft)[] },
  });

  return hasDuplicateId
    ? 'Duplicate ID'
    : missingId
    ? 'Missing ID'
    : idError
    ? idError
    : hasDuplicateDisplayName
    ? 'Duplicate displayName'
    : missingDisplayName
    ? 'Missing displayName'
    : aliasesError
    ? aliasesError
    : '';
};
