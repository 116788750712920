import {
  BoundingBox,
  MultiScaleImageLayerProps,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/types';
import { DecodedPng } from 'fast-png';
import { isArray } from 'lodash';

export interface MultiScaleImageIndex {
  x: number;
  y: number;
  z: number;
}

export interface MultiScaleImageData {
  data: Array<ImageData | DecodedPng | null>;
  format: number;
  dataFormat: number;
  isDecodedPng: boolean;
}

/**
 * Function to adjust Deck.GL tile bounds to work with deep zoom tiles.
 * This function will adjust the bounds of the tile to account for the overlap pixels and edge tiles.
 */
export function getBoundsForDeepZoomTile<S extends string[]>({
  tile,
  layerSource,
  width,
  height,
}: {
  layerSource: MultiScaleImageLayerProps<S>['layerSource'];
  width: number;
  height: number;
  tile: { index: MultiScaleImageIndex; bbox: BoundingBox };
}): [left: number, top: number, right: number, bottom: number] | null {
  const { bbox } = tile;
  const tileSize = layerSource.getTileSize();

  let left: number;
  let top: number;
  let right: number;
  let bottom: number;

  if (isArray(bbox)) {
    // Pixel coordinates array
    [left, top, right, bottom] = bbox;
  } else if ('west' in bbox) {
    // Geographic coordinates
    ({ west: left, north: top, east: right, south: bottom } = bbox);
  } else {
    // Pixel coordinates object
    ({ left, top, right, bottom } = bbox);
  }

  // Calculate the ratio of the tile size passed to Deck.GL to the actual image width - which can be bigger than the tile size in case of overlap pixels or smaller in case of edge tiles.
  const tileWidthRatio = width / tileSize;
  const tileHeightRatio = height / tileSize;

  if (isNaN(tileWidthRatio) || isNaN(tileHeightRatio)) {
    console.warn('Tile width or height ratio is NaN.', {
      tileWidthRatio,
      tileHeightRatio,
      width,
      height,
      tileSize,
    });
    return null;
  }

  const originalBoundingBoxWidth = right - left;
  const originalBoundingBoxHeight = bottom - top;

  // In case of edge tiles, we scale the bounds to match the actual image size.
  right = left + originalBoundingBoxWidth * tileWidthRatio;
  bottom = top + originalBoundingBoxHeight * tileHeightRatio;

  const bounds: [left: number, top: number, right: number, bottom: number] = [left, bottom, right, top];

  return bounds;
}
