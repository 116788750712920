import { SlideChange, SlideUpdate } from 'api/slides';
import { CaseUpdate } from 'api/study';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { useErrorSnackbarWithRetry } from 'components/Snackbars/useErrorSnackbarWithRetry';
import { Procedure, ProcedureResponse } from 'interfaces/procedure';
import { ProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { Slide } from 'interfaces/slide';
import { includes, isEmpty, map, some } from 'lodash';
import { ResponseError } from 'superagent';
import { BooleanParam, useQueryParam } from 'use-query-params';
import queryClient from 'utils/queryClient';
import { CasesParams } from 'utils/useCasesParams';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { usePendingSlides } from '../usePendingSlides';

const doesCaseIncluded = (procedure: Procedure, casesParams: CasesParams) => {
  let caseIncludes = true;

  if (!isEmpty(casesParams.caseIdsToInclude)) {
    caseIncludes = caseIncludes && includes(casesParams.caseIdsToInclude, procedure.id);
  }
  if (!isEmpty(casesParams.caseIdsToExclude)) {
    caseIncludes = caseIncludes && !includes(casesParams.caseIdsToExclude, procedure.id);
  }

  if (!isEmpty(casesParams.slideIdsToInclude)) {
    caseIncludes = caseIncludes && some(procedure.slides, (slide) => includes(casesParams.slideIdsToInclude, slide.id));
  }

  if (!isEmpty(casesParams.slideIdsToExclude)) {
    caseIncludes =
      caseIncludes && !some(procedure.slides, (slide) => includes(casesParams.slideIdsToExclude, slide.id));
  }

  return caseIncludes;
};

const doesSlideIncluded = (procedure: Procedure, slideId: string, casesParams: CasesParams) => {
  let slideIncludes = doesCaseIncluded(procedure, casesParams);

  if (!isEmpty(casesParams.slideIdsToInclude)) {
    slideIncludes = slideIncludes && includes(casesParams.slideIdsToInclude, slideId);
  }

  if (!isEmpty(casesParams.slideIdsToExclude)) {
    slideIncludes = slideIncludes && !includes(casesParams.slideIdsToExclude, slideId);
  }

  return slideIncludes;
};

export const useCasesAndSlidesUpdateMutationsParams = ({ action }: { action: string }) => {
  const { encodedFilters } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });
  const { clearChanges, setBulkEditMode } = useTableEditingContext<Procedure, ProceduresFieldsContext>();
  const { enqueueErrorSnackbar } = useErrorSnackbarWithRetry();

  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);
  const { pendingSlidesQueryKey } = usePendingSlides();

  // OnMutate functions start with cancel procedures queries so the data in the table wont be updated with the data before the last change
  // Example - A user updates the cancer type, and after it succeeds updates the cancer type again.
  // But then the invalidate queries from the onSuccess function returns and updates the cancer to the first change, then the update succeeds and updates the cancer to the second change.
  // Theres an unexpected jumps in the data.
  return {
    onMutateForSlideUpdate: ({
      slideChanges: { id: slideId, ...changes },
      caseId,
    }: {
      slideChanges: SlideChange;
      labId: string;
      caseId: number;
    }) => {
      queryClient.cancelQueries(['procedures', encodedFilters]);
      const previousValue = pendingSlidesMode
        ? queryClient.getQueryData<Slide[]>(pendingSlidesQueryKey)
        : queryClient.getQueryData<ProcedureResponse>(['procedures', encodedFilters]);

      if (pendingSlidesMode) {
        queryClient.setQueriesData<Slide[]>(pendingSlidesQueryKey, (oldData) =>
          map(oldData, (s) => (s.id === slideId ? { ...s, ...changes } : s))
        );
      } else {
        queryClient.setQueriesData<ProcedureResponse>(['procedures', encodedFilters], (oldData) => {
          const newProcedures = map(oldData?.procedures, (procedure) =>
            procedure.id === caseId
              ? {
                  ...procedure,
                  slides: map(procedure.slides, (slide) => (slide.id === slideId ? { ...slide, ...changes } : slide)),
                }
              : procedure
          );
          return oldData ? { ...oldData, procedures: newProcedures } : oldData;
        });
      }

      return { previousValue };
    },
    onMutateForCaseUpdate: ({ caseId, updates }: { caseId: number; updates: Partial<CaseUpdate> }) => {
      queryClient.cancelQueries(['procedures', encodedFilters]);
      const previousValue = queryClient.getQueryData<ProcedureResponse>(['procedures', encodedFilters]);
      queryClient.setQueriesData<ProcedureResponse>(['procedures', encodedFilters], (oldData) => {
        const newProcedures = map(oldData?.procedures, (procedure) =>
          procedure.id === caseId ? { ...procedure, ...updates } : procedure
        );

        return oldData ? { ...oldData, procedures: newProcedures } : oldData;
      });

      return { previousValue };
    },
    onMutateForCaseBulkUpdate: (
      data: {
        procedureUpdates: Partial<CaseUpdate>;
      } & CasesParams
    ) => {
      queryClient.cancelQueries(['procedures', encodedFilters]);
      const previousValue = queryClient.getQueryData<ProcedureResponse>(['procedures', encodedFilters]);
      queryClient.setQueriesData<ProcedureResponse>(['procedures', encodedFilters], (oldData) => {
        const newProcedures = map(oldData?.procedures, (procedure) =>
          doesCaseIncluded(procedure, data) ? { ...procedure, ...data.procedureUpdates } : procedure
        );
        return oldData ? { ...oldData, procedures: newProcedures } : oldData;
      });
      clearChanges();
      setBulkEditMode(false);

      return { previousValue };
    },
    onMutateForSlideBulkUpdate: (
      data: {
        slideUpdates: Partial<SlideUpdate>;
      } & CasesParams
    ) => {
      queryClient.cancelQueries(['procedures', encodedFilters]);
      const previousValue = pendingSlidesMode
        ? queryClient.getQueryData<Slide[]>(pendingSlidesQueryKey)
        : queryClient.getQueryData<ProcedureResponse>(['procedures', encodedFilters]);
      if (pendingSlidesMode) {
        queryClient.setQueriesData<Slide[]>(pendingSlidesQueryKey, (oldData) => {
          if (data && 'slideIdsToInclude' in data) {
            return map(oldData, (slide) =>
              includes(data.slideIdsToInclude, slide.id) ? { ...slide, ...data.slideUpdates } : slide
            );
          } else if (data && 'slideIdsToExclude' in data) {
            return map(oldData, (slide) =>
              includes(data.slideIdsToExclude, slide.id) ? slide : { ...slide, ...data.slideUpdates }
            );
          } else {
            return oldData;
          }
        });
      } else {
        queryClient.setQueriesData<ProcedureResponse>(['procedures', encodedFilters], (oldData) => {
          const newProcedures = map(oldData?.procedures, (procedure) => ({
            ...procedure,
            slides: map(procedure.slides, (slide) =>
              doesSlideIncluded(procedure, slide.id, data) ? { ...slide, ...data.slideUpdates } : slide
            ),
          }));
          return oldData ? { ...oldData, procedures: newProcedures } : oldData;
        });
      }
      clearChanges();
      setBulkEditMode(false);

      return { previousValue };
    },
    onError: (error: any, variables: any, context: any) => {
      if (pendingSlidesMode) {
        queryClient.setQueriesData<Slide[]>(pendingSlidesQueryKey, context.previousValue);
      } else {
        queryClient.setQueriesData(['procedures', encodedFilters], context.previousValue);
      }
      enqueueErrorSnackbar(error as ResponseError, action);
      if (pendingSlidesMode) {
        queryClient.invalidateQueries({ queryKey: pendingSlidesQueryKey });
      } else {
        queryClient.invalidateQueries({ queryKey: ['procedures'], exact: false });
      }
    },
  };
};
