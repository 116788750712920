import { filter, findIndex, first, includes } from 'lodash';
import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { useFilteredCaseSlideThumbnailsData } from 'components/Procedure/useFilteredCaseIds';
import { DEFAULT_PAGE_SIZE } from 'components/StudyDashboard/ProceduresPage/ProcedurePagination';
import { CaseSlideThumbnailData, Procedure } from 'interfaces/procedure';
import { useSelectedSlideIds } from 'utils/useCurrentSlideIds';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';
import { BaseNavigationPanel } from './BaseNavigationPanel';

const DEFAULT_NAVIGATION_STAGE_TEXT = '1/1';

export const SlideNavigationPanel: React.FunctionComponent<
  React.PropsWithChildren<{
    currentCase: Procedure;
    studyId?: string;
    isLoadingCaseData: boolean;
  }>
> = ({ currentCase, studyId, isLoadingCaseData }) => {
  const [selectedSlideIds] = useSelectedSlideIds(currentCase);
  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);
  const currentCaseId = pendingSlidesMode ? null : currentCase?.id;

  const { queryParams, setQueryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const currentSlideId = first(selectedSlideIds) || first(currentCase?.slides)?.id;

  const { isInitialLoading: isInitialLoadingSlideIds, data: allSlideThumbnailsData } =
    useFilteredCaseSlideThumbnailsData({ studyId });

  // In pending slides mode, we only show pending slides (i.e. slides without a case)
  // In slide mode, we show all slides assigned to a case in the study
  const caseSlideThumbnailsData = filter(allSlideThumbnailsData, ({ caseId }) => {
    // 'Boolean(caseId)' is for scenarios that caseId is null (isNaN(null) is false)
    const isAssignedToCase = Boolean(caseId && !isNaN(caseId));
    return pendingSlidesMode ? !isAssignedToCase : isAssignedToCase;
  });

  const pageSize = queryParams.pageSize || DEFAULT_PAGE_SIZE;

  const { getUrlToSlidePage } = useNavigationToViewerPage();

  // 'Boolean(caseId)' is for scenarios that caseId is null (isNaN(null) is false)
  const hasCaseId = Boolean(currentCaseId) && !isNaN(currentCaseId);
  const currentSlideIndex = findIndex(caseSlideThumbnailsData, {
    ...(hasCaseId ? { caseId: currentCaseId } : {}),
    slideId: currentSlideId,
  });
  const totalSlides = caseSlideThumbnailsData ? caseSlideThumbnailsData.length : 0;

  const previousCaseIdSlideId: CaseSlideThumbnailData | null =
    totalSlides > 0 ? caseSlideThumbnailsData[(currentSlideIndex - 1 + totalSlides) % totalSlides] : null;
  const previousCaseIdSlideIdPage = caseSlideThumbnailsData
    ? Math.floor(((currentSlideIndex - 1 + totalSlides) % totalSlides) / pageSize) + 1
    : null;
  const previousLinkTo = previousCaseIdSlideId
    ? getUrlToSlidePage({
        slideId: previousCaseIdSlideId.slideId,
        selectedSlideIds:
          // If the previous slide is already visible, just move it to the first viewer
          currentCase?.id === previousCaseIdSlideId.caseId && includes(selectedSlideIds, previousCaseIdSlideId.slideId)
            ? [previousCaseIdSlideId.slideId, ...filter(selectedSlideIds, (slideId) => slideId !== currentSlideId)]
            : [previousCaseIdSlideId.slideId],
        caseId: previousCaseIdSlideId.caseId,
        newPage: previousCaseIdSlideIdPage,
      })
    : null;

  const nextCaseIdSlideId: CaseSlideThumbnailData | null =
    totalSlides > 0 ? caseSlideThumbnailsData[(currentSlideIndex + 1 + totalSlides) % totalSlides] : null;
  const nextCaseIdSlideIdPage = caseSlideThumbnailsData
    ? Math.floor(((currentSlideIndex + 1 + totalSlides) % totalSlides) / pageSize) + 1
    : null;
  const nextLinkTo = nextCaseIdSlideId
    ? getUrlToSlidePage({
        slideId: nextCaseIdSlideId.slideId,
        selectedSlideIds:
          // If the next slide is already visible, just move it to the first viewer
          currentCase?.id === nextCaseIdSlideId.caseId && includes(selectedSlideIds, nextCaseIdSlideId.slideId)
            ? [nextCaseIdSlideId.slideId, ...filter(selectedSlideIds, (slideId) => slideId !== currentSlideId)]
            : [nextCaseIdSlideId.slideId],
        caseId: nextCaseIdSlideId.caseId,
        newPage: nextCaseIdSlideIdPage,
      })
    : null;

  // The default value is for the case that somehow the navigation list is empty but we still have slide data and the page is loaded
  const navigationStageText =
    currentSlideIndex !== -1 && totalSlides > 0
      ? `${currentSlideIndex + 1} / ${totalSlides} slides`
      : !isInitialLoadingSlideIds && totalSlides === 0
      ? DEFAULT_NAVIGATION_STAGE_TEXT
      : '';

  const indexInSlideThumbnailsData = findIndex(caseSlideThumbnailsData, {
    caseId: currentCase?.id,
    slideId: currentSlideId,
  });
  const currentPage = caseSlideThumbnailsData ? Math.floor(indexInSlideThumbnailsData / pageSize) : null;
  const totalPages = Math.floor(totalSlides / pageSize) + 1;
  React.useEffect(() => {
    // If the current image's page is not filtered, update the filter
    if (
      !isLoadingCaseData &&
      currentPage !== null &&
      currentPage >= 0 &&
      currentPage < totalPages &&
      currentPage + 1 !== queryParams.page
    ) {
      console.info('Updating page to', currentPage + 1);
      setQueryParams({ page: currentPage + 1 }, 'replaceIn');
    }
  }, [queryParams.page, currentPage, totalPages, isLoadingCaseData]);

  return (
    <BaseNavigationPanel
      nextLinkTo={nextLinkTo}
      previousLinkTo={previousLinkTo}
      isInitialLoading={isInitialLoadingSlideIds || !navigationStageText}
      navigationStageText={navigationStageText}
    />
  );
};
