export const useExistingStorage = {
  key: 'useExistingStorage',
  displayName: 'Use Customer Provided S3 Bucket',
};

export const generateStorage = {
  key: 'generateStorage',
  displayName: 'Upload to Nucleai S3 Bucket',
};

export const uploadFiles = {
  key: 'uploadFiles',
  displayName: 'Upload Files',
};

export const uploadOptions = [useExistingStorage, generateStorage, uploadFiles];
