import { Button, Tooltip } from '@mui/material';
import React from 'react';

interface Props {
  nodeId: string;
  isStudyExists: boolean;
  onClick(): void;
}

const DownloadDisplayNameFeatures: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  nodeId,
  onClick,
  isStudyExists,
}) => {
  return (
    <Tooltip title={isStudyExists ? 'Download study features names' : 'Select study to download'} placement="top">
      <span>
        <Button
          id={nodeId}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
          }}
        >
          Download Features Names
        </Button>
      </span>
    </Tooltip>
  );
};

export default DownloadDisplayNameFeatures;
