import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { approveProcedure, unapproveProcedure } from 'api/procedures';
import { Procedure } from 'interfaces/procedure';
import React from 'react';
import { useEncodedFilters } from 'utils/useEncodedFilters';
interface Props {
  procedure: Procedure;
}

const ApprovalWidget = ({ procedure }: Props) => {
  const { encodedFilters } = useEncodedFilters();

  const queryClient = useQueryClient();

  const approveMutation = useMutation(approveProcedure, {
    onSuccess: () => {
      queryClient.invalidateQueries(['procedures', encodedFilters]);
    },
  });

  const unapproveMutation = useMutation(unapproveProcedure, {
    onSuccess: () => {
      queryClient.invalidateQueries(['procedures', encodedFilters]);
    },
  });

  const handleApproveProcedure = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm('Are you sure you want to approve this procedure?')) {
      approveMutation.mutate(procedure.id);
    }
  };

  const handleUnapproveProcedure = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm('Are you sure you want to disapprove this procedure?')) {
      unapproveMutation.mutate(procedure.id);
    }
  };

  return (
    <Grid container>
      <Grid item xs={5} onClick={procedure.internallyApproved ? handleUnapproveProcedure : handleApproveProcedure}>
        <Grid container alignItems="center">
          <Grid item>
            <IconButton>{procedure.internallyApproved ? <ThumbDownIcon /> : <ThumbUpIcon />}</IconButton>
          </Grid>
          <Grid item>{procedure.internallyApproved ? 'Disapprove' : 'Approve'}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ApprovalWidget;
