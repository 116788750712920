import { COORDINATE_SYSTEM, Layer } from '@deck.gl/core/typed';
import { useSignals } from '@preact/signals-react/runtime';
import { replace } from 'lodash';
import { EditableGeoJsonLayer } from 'nebula.gl';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { getWithUnit } from 'utils/OSD/OSDScalebarPlugin';
import { MeasureOrthographicDistanceMode } from './MeasureOrthographicDistanceMode';

const OVERLAY_COLOR: [number, number, number] = [180, 180, 180];

export const useMeasureToolLayer = ({ slideId, maxResolution }: { slideId: string; maxResolution: number }) => {
  useSignals();
  const [measureToolActive] = useQueryParam('measureToolActive', BooleanParam);

  const measureToolLayer = measureToolActive
    ? // @ts-ignore
      (new EditableGeoJsonLayer({
        id: `measure-tool-layer-${slideId}`,
        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        getRadius: 2,
        mode: MeasureOrthographicDistanceMode,
        modeConfig: {
          // TODO: find where to add `characterSet: [...map(SI_PREFIXES, 'symbol'), ...map(times(10), (i) => String(i)), '.', 'e', '+'],`
          formatTooltip: (distance: number) =>
            replace(getWithUnit((distance / 1000000) * maxResolution, 'm'), 'μ', 'u'),
        },
        stroked: true,
        filled: false,
        editHandleType: 'point',
        getFillColor: OVERLAY_COLOR,
        getLineColor: OVERLAY_COLOR,
        _subLayerProps: { tooltips: { getColor: OVERLAY_COLOR } },
        getEditHandleIconSize: 40,
        pointRadiusMinPixels: 5,
        pointRadiusScale: 2,
        lineWidthScale: 2,
        lineWidthMinPixels: 2,
      }) as Layer<any>)
    : null;

  return measureToolLayer;
};
