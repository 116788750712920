enum CellRuleValue {
  Indifferent = -1,
  MustBeNegative = 0,
  MustBePositive = 1,
  OneOfTwoMustBePositive = 2,
}

interface MinimalCellRule {
  cellTypeId: string;
  stainTypeId: string;
  ruleValue: CellRuleValue;
}

interface CellRule extends MinimalCellRule {
  id: string;
  cellRulePanelId: string;
}

interface CellRulePanel {
  id: string;
  studyId?: string;
  label?: string;
  stainTypeIds?: string[];
  createdAt: Date;
  createdBy?: string;
  updatedAt?: Date;
  updatedBy?: string;
}

interface CellRulePanelWithRules extends CellRulePanel {
  rules: MinimalCellRule[];
}

interface UpdateCellRule {
  cellTypeId: string;
  stainTypeId: string;
  ruleValue: CellRuleValue;
}

const globalStudyId = 'global' as const;
type CellRuleStudyId = typeof globalStudyId | (string & {}); // typescript hack to allow 'global' autocomplete

export {
  CellRule,
  CellRulePanel,
  CellRulePanelWithRules,
  CellRuleStudyId,
  CellRuleValue,
  MinimalCellRule,
  UpdateCellRule,
  globalStudyId,
};
