import { QueryFunctionContext, QueryKey, useQueries, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getProcedureIds, getProcedures } from 'api/procedures';
import { DEFAULT_PAGE_SIZE } from 'components/StudyDashboard/ProceduresPage/ProcedurePagination';
import { ProcedureResponse } from 'interfaces/procedure';
import { map, range, reject, uniq } from 'lodash';
import { getOneIndexedPageWithOffset } from './api';
import { ExperimentResultsSelection, useEncodedFilters } from './useEncodedFilters';

interface Props
  extends Omit<UseQueryOptions<any, unknown, ProcedureResponse, QueryKey>, 'initialData' | 'queryKey' | 'queryFn'> {
  forcePage?: number;
  pageRadius?: number;
  loadIds?: boolean;
}

export const useProceduresWithQAExperimentResultsOnly = ({
  pageRadius = 0,
  enabled = true,
  loadIds,
  forcePage,
  ...props
}: Props = {}) => {
  const { encodedFilters, generateEncodedParams, queryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const currentPageEncodedParams = !isNaN(forcePage) ? generateEncodedParams({ page: forcePage }) : encodedFilters;
  const queryResults = useQuery<any, unknown, ProcedureResponse>({
    ...props,
    enabled,
    queryKey: ['procedures', currentPageEncodedParams],
    queryFn: ({ signal }: QueryFunctionContext) => {
      return getProcedures(currentPageEncodedParams, signal);
    },
  });

  const currentPage = (forcePage || queryParams.page || 1) as number;
  const pageSize = queryParams.pageSize || DEFAULT_PAGE_SIZE;
  const pageCount = queryParams?.slidesMode
    ? Math.ceil((queryResults?.data?.totalSlides ?? 0) / pageSize)
    : Math.ceil((queryResults?.data?.totalProcedures ?? 0) / pageSize);

  const additionalPagesToFetch =
    (pageCount ?? 0) > 1
      ? reject(
          uniq(
            map(range(-1 * pageRadius, pageRadius + 1), (i) => getOneIndexedPageWithOffset(currentPage, i, pageCount))
          ),
          (page) => page === currentPage
        )
      : [];

  useQueries({
    queries: map(additionalPagesToFetch, (page) => ({
      ...props,
      enabled,
      queryKey: ['procedures', generateEncodedParams({ page })],
      queryFn: ({ signal }: QueryFunctionContext) => {
        return getProcedures(generateEncodedParams({ page }), signal);
      },
    })),
  });

  useQuery(['procedureIds', [encodedFilters]], {
    queryFn: () => getProcedureIds(encodedFilters),
    enabled: Boolean(queryResults.data) && loadIds,
  });

  return queryResults;
};
