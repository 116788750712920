import { Box, CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import React from 'react';

interface SizeProps {
  loadersize: number;
  progresssize: number;
}

const CircularValueProgress = (props: CircularProgressProps & { value: number } & SizeProps) => {
  return (
    <Box position="relative" display="inline-flex">
      {props.value ? (
        <CircularProgress variant="determinate" {...props} size={props.progresssize} />
      ) : (
        <CircularProgress size={props.loadersize} />
      )}
      {props.value ? (
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div">{`${Math.round(props.value)}%`}</Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default CircularValueProgress;
