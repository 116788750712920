import React from 'react';

import { RowsChangesSummary } from 'components/atoms/EditableDataGrid/TableEditingContext/ChangesSummary';
import { StainType } from 'interfaces/stainType';
import { stainTypeFields } from 'interfaces/stainType/stainTypeFields';
import { find } from 'lodash';

export const StainTypeRowChangesSummary: React.FC<
  React.PropsWithChildren<{
    stainTypeDisplayString?: string;
    changes: Partial<StainType>;
  }>
> = ({ changes, stainTypeDisplayString }) => {
  return (
    <RowsChangesSummary
      rowsChanges={{ changes }}
      rowToDisplayString={() => stainTypeDisplayString || `Stain Type "${changes?.displayName}"`}
      fieldToDisplayString={(fieldId) => find(stainTypeFields, (f) => f.dataKey === fieldId)?.label || fieldId}
      valueToDisplayString={(value, fieldId) => {
        const field = find(stainTypeFields, (f) => f.dataKey === fieldId);
        return field?.valueFormatter?.({ value }) ?? `${value}`;
      }}
    />
  );
};
