import {
  FeatureCollection,
  GeoJsonFeature,
  Point,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/NebulaGLExtensions/geojson-types';
import equal from 'deep-equal';
import { Annotation, AnnotationFeature, AnnotationPolygonCoordinates, AnnotationsData } from 'interfaces/annotation';
import { cloneDeep, first, includes, last, map, min, pick, size, slice } from 'lodash';

// import OBJECT_HANDLER_TYPES from '../../constants/fabricObjectTypes';
export const OBJECT_HANDLER_TYPES = {
  SQUARE: 'rect',
  POINT: 'circle',
  POLYGON: 'polygon',
  RULER: 'ruler',
  POLYLINE: 'polyline', // deprecated, for backwards compatibility
  FREEFORM_POLYGON: 'freeform_polygon', // deprecated, for backwards compatibility
  FREEFORM_POLYLINE: 'freeform_polyline ', // deprecated, for backwards compatibility
  DRAG: 'drag',
  SELECT: 'select',
  NONE: '',
};

const SUPPORTED_ANNOTATION_GEOMETRY_TYPES = ['Point', 'Polygon'];

export interface Markers {
  type: string;
  features: AnnotationFeature[];
}

// GeoJSON polygons needs to have the first and last point be the same
// In the db we save it without the last point
const removeDuplicateCoordinates = (coordinates: AnnotationPolygonCoordinates): AnnotationPolygonCoordinates => {
  const lastIndex = coordinates.length - 1;
  if (equal(first(coordinates), coordinates[lastIndex])) {
    slice(coordinates, lastIndex);
  }
  return coordinates;
};

/**
 * Adds hidden and selected properties to the feature properties
 * and removes duplicate coordinates from polygons and rectangles
 *
 * @param feature GeoJSON feature to convert
 * @returns the converted feature (or as we call it in the db - marker)
 */
const convertGeoJsonFeatureToMarker = (feature: GeoJsonFeature): AnnotationFeature => {
  if (!includes(SUPPORTED_ANNOTATION_GEOMETRY_TYPES, feature?.geometry?.type)) {
    console.error(`Invalid feature geometry type: ${feature?.geometry?.type}`);
    return;
  }

  const properties = {
    ...pick(feature?.properties, [
      'id',
      'hidden',
      'selected',
      'diagnosis',
      'markerType',
      'shapeSubType',
      'markerPositivity',
    ]),
    hidden: false,
    selected: false,
  } as AnnotationFeature['properties'];

  if (feature.geometry.type === 'Point') {
    return { ...feature, geometry: feature.geometry as Point, properties };
  }

  if (feature.geometry.type === 'Polygon') {
    return {
      ...feature,
      properties,
      geometry: {
        ...feature.geometry,
        coordinates: removeDuplicateCoordinates(first(feature.geometry?.coordinates)),
      },
    };
  }
};

/**
 * Converts a feature from how it is saved in the db to a GeoJSON feature
 * In the db we save polygons without duplicate coordinates (first and last point are the same in GeoJSON)
 * and we save the polygon coordinates as an array of arrays (list of [x, y] coordinates) instead of an array of arrays of arrays
 *
 * @param marker from db
 * @returns GeoJSON feature
 */
const convertMarkerToGeoJsonFeature = (feature: AnnotationFeature): GeoJsonFeature => {
  if (!includes(SUPPORTED_ANNOTATION_GEOMETRY_TYPES, feature?.geometry?.type)) {
    console.error(`Invalid feature geometry type: ${feature?.geometry?.type}`);
    return;
  }

  if (feature.geometry.type === 'Point') {
    return {
      type: 'Feature',
      geometry: feature.geometry,
      properties: feature?.properties || {},
    };
  }

  if (feature.geometry.type === 'Polygon') {
    if (size(feature.geometry.coordinates) > 1) {
      const coordinates = cloneDeep(feature.geometry.coordinates);

      const firstCoordinate = first(coordinates);
      if (!equal(firstCoordinate, last(coordinates))) {
        coordinates.push(firstCoordinate);
      }
      return {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [coordinates], // GeoJson coordinates is an array of array of coordinate (The first array represents the outer boundary of the polygon)
        },
        properties: feature?.properties || {},
      };
    }
  }
};

const convertGeoJsonToAnnotationsData = (featureCollection: FeatureCollection): AnnotationsData => {
  // takes a proper GeoJSON featureCollection and converts it to the format used in the db (Semi-GeoJSON format)
  return {
    todo: 'merged-todo',
    markers: map(featureCollection.features, convertGeoJsonFeatureToMarker),
  };
};

const compareFeatures = (feature1: GeoJsonFeature, feature2: GeoJsonFeature) => {
  /*
    Sort function for deserialize.
    We want hover to select the innermost feature at a given location.
    The selected feature will be the one that appears earlier in the features list and thus rendered first.
    All point features will be at the begining of the list, the rest are sorted by minimal y coordiante.
  */
  if (feature1?.geometry?.type === 'Point' && feature2?.geometry?.type !== 'Point') {
    return 1;
  }
  if (feature2?.geometry?.type === 'Point' && feature1?.geometry?.type !== 'Point') {
    return -1;
  }
  if (feature1?.geometry?.type === 'Point' && feature2?.geometry?.type === 'Point') {
    return 0;
  }
  if (feature1?.geometry?.type !== 'Polygon' || feature2?.geometry?.type !== 'Polygon') {
    console.warn('one of the features is not a point or a polygon', {
      feature1tType: feature1?.geometry?.type,
      feature2Type: feature2?.geometry?.type,
    });
    return 0;
  }
  const feature1Polygon = first(feature1?.geometry?.coordinates) as AnnotationPolygonCoordinates;
  const feature2Polygon = first(feature2?.geometry?.coordinates) as AnnotationPolygonCoordinates;
  return (
    min(map(feature1Polygon, (point: number[]) => point?.[1])) -
    min(map(feature2Polygon, (point: number[]) => point?.[1]))
  );
};

/**
 * converts an annotationsData object to a GeoJSON FeatureCollection according to the given parameters
 *
 * @param annotation - the full annotation object to convert - contains both published version and draft version of the annotationsData
 * @param sortFeatures - whether to sort the features in the GeoJSON
 * @param draft - whether to use the draft annotationsData
 *  - if passed as true, latest param will be ignored and the draft version will be used
 * @param latest - whether to use the latest annotationsData
 *  - the latest version is the annotationsData if it exists, otherwise the draftAnnotationsData
 * if both draft and latest are false - the annotationsData (publish version even if empty) will be used
 * @returns the converted GeoJSON FeatureCollection
 */
const convertAnnotationToGeoJson = ({
  annotation,
  sortFeatures = false,
  draft = false,
  latest = false,
}: {
  annotation: Annotation;
  sortFeatures?: boolean;
  draft?: boolean;
  latest?: boolean;
}): FeatureCollection => {
  if (draft && latest) {
    console.warn('Both draft and latest flags are set to true, only draft will be used');
  }
  const annotationsData = draft
    ? annotation?.draftAnnotationsData
    : latest
    ? annotation?.annotationsData || annotation?.draftAnnotationsData
    : annotation?.annotationsData;

  return convertAnnotationsDataToGeoJson({
    annotationId: annotation?.annotationId,
    annotationsData,
    sortFeatures,
  });
};

const convertAnnotationsDataToGeoJson = ({
  annotationId,
  annotationsData,
  sortFeatures = false,
}: {
  annotationId: number;
  annotationsData: AnnotationsData[];
  sortFeatures: boolean;
}): FeatureCollection => {
  // convert from server response (Semi-GeoJSON) to proper GeoJSON format

  // This value is saved in an array for backwards compatibility, since 2019 there are no more then one annotation
  if (size(annotationsData) > 1) {
    console.warn('More than one annotation in the annotationsData array, taking the first one');
  }
  const annotationData = first(annotationsData);
  const convertedFeatures = map(annotationData?.markers, convertMarkerToGeoJsonFeature);

  const featureCollection: FeatureCollection = { type: 'FeatureCollection', features: convertedFeatures };

  if (sortFeatures) {
    featureCollection.features.sort(compareFeatures);
  }

  return {
    ...featureCollection,
    features: map(featureCollection.features, (feature) => ({
      ...feature,
      properties: {
        ...(feature.properties || {}),
        annotationId: annotationId,
      },
    })),
  };
};

const markerAnnotationService = {
  convertGeoJsonToAnnotationsData,
  convertAnnotationToGeoJson,
  convertAnnotationsDataToGeoJson,
  compareFeatures,
};

export default markerAnnotationService;
