import { find } from 'lodash';
import { useCallback } from 'react';
import { useAreaTypeOptions } from 'utils/queryHooks/uiConstantsHooks';

export const useAreaTypeIdToDisplayName = () => {
  const { areaTypeOptions, isLoadingAreaTypes } = useAreaTypeOptions();
  const areaTypeIdToDisplayName = useCallback(
    (areaTypeId: string) => find(areaTypeOptions, { id: areaTypeId })?.displayName || areaTypeId,
    [areaTypeOptions]
  );
  return {
    isLoadingAreaTypes,
    areaTypeIdToDisplayName,
  };
};
