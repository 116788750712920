import { Link } from '@mui/material';
import { UnpaginatedGalleryViewer } from 'components/StudyDashboard/ProceduresPage/GalleryViewer/UnpaginatedGalleryViewer';
import { Procedure } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import React, { FunctionComponent, ReactNode } from 'react';

interface FlaggedSlidesListProps {
  slideCasePairs: {
    slide: Slide;
    case: Procedure;
  }[];
  label: ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const FlaggedSlidesList: FunctionComponent<FlaggedSlidesListProps> = ({ slideCasePairs, label, open, setOpen }) => {
  return (
    <>
      <Link
        onClick={() => {
          setOpen(true);
        }}
      >
        {slideCasePairs.length} slides
      </Link>
      {open && (
        <UnpaginatedGalleryViewer slideCasePairs={slideCasePairs} onClose={() => setOpen(false)} galleryLabel={label} />
      )}
    </>
  );
};

export default FlaggedSlidesList;
