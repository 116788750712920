import { StringParam, useQueryParam } from 'use-query-params';

import { annotationsDrawerWidth } from 'components/atoms/FlexContainers/FlexContainers';
import { Permission } from 'interfaces/permissionOption';
import { usePermissions } from 'utils/usePermissions';

export const DRAWER_WIDTH = 340;
export const REVIEW_SIDEBAR_WIDTH = 40;

const useReviewSidebarWidth = () => {
  const { hasPermission } = usePermissions();

  const reviewSidebarVisible = hasPermission(Permission.ViewReviewMenu);
  const reviewSidebarWidth = reviewSidebarVisible ? REVIEW_SIDEBAR_WIDTH : 0;

  const [reviewTab] = useQueryParam<string>('reviewTab', StringParam);

  const reviewTabOpen = Boolean(reviewTab);
  const reviewTabWidth = reviewTab === 'annotations' ? annotationsDrawerWidth : reviewTabOpen ? DRAWER_WIDTH : 0;

  const rightDrawerWidth = reviewSidebarWidth + reviewTabWidth;

  return {
    rightDrawerWidth,
    reviewSidebarWidth,
    reviewTabWidth,
    rightDrawerWidthPx: `${rightDrawerWidth}px`,
    reviewSidebarWidthPx: `${reviewSidebarWidth}px`,
    reviewTabWidthPx: `${reviewTabWidth}px`,
  };
};

export default useReviewSidebarWidth;
