import { GridColDef, GridColumnHeaderParams, GridRowId } from '@mui/x-data-grid';
import { map } from 'lodash';
import React from 'react';

import { Tooltip, Typography } from '@mui/material';
import { DisplayedField } from 'interfaces/genericFields';
import { UnwoundRow } from 'interfaces/genericFields/unwindRowsWithInnerArrays';
import { ColumnHeaderWithEditorRenderer } from './ColumnHeaderEditor';
import { generateFieldValueSetter } from './fieldValueSetter';
import { displayedFieldToColumn } from './helpers';
import { useTableEditingContext } from './TableEditingContext';
import { BasicTableRow } from './TableEditingContext/types';

export interface EditableFieldsDataGridColumnsParams<R extends BasicTableRow, UnwoundType = undefined> {
  fields: Array<DisplayedField<R>>;
  disableCellEditing?: boolean;
  isLoading?: boolean;
  bulkEditMode?: boolean;
  shouldApplyBulkChangesToRow?: (rowId: GridRowId) => boolean;
  noRows?: boolean;
  arrayFieldToUnwind?: UnwoundType extends undefined ? undefined : keyof R;
  unwoundRowIdField?: UnwoundType extends undefined ? undefined : string | number | symbol;
  baseRowIdField?: UnwoundType extends undefined ? undefined : string | number | symbol;
  omitUnwoundFields?: UnwoundType extends undefined ? undefined : Array<keyof UnwoundType>;
  idGetter?: (row: R) => string | number;
  useValueSetter?: boolean;
}

export const useEditableFieldsDataGridColumns = <
  R extends BasicTableRow,
  Context extends any = any,
  UnwoundType extends object | undefined = undefined
>({
  fields,
  disableCellEditing,
  isLoading,
  bulkEditMode,
  noRows,
  shouldApplyBulkChangesToRow,
  arrayFieldToUnwind,
  unwoundRowIdField,
  baseRowIdField,
  idGetter,
  useValueSetter = false,
}: EditableFieldsDataGridColumnsParams<R, UnwoundType>): Array<
  UnwoundType extends undefined ? GridColDef<R> : GridColDef<UnwoundRow<R, UnwoundType>>
> => {
  const { fieldsContext, getRowWithChanges, applyRowUpdates } = useTableEditingContext<R, Context>();

  return map(
    fields,
    (field): UnwoundType extends undefined ? GridColDef<R> : GridColDef<UnwoundRow<R, UnwoundType>> => {
      const colDef = displayedFieldToColumn(
        field,
        fieldsContext,
        arrayFieldToUnwind,
        unwoundRowIdField,
        baseRowIdField
      ) as UnwoundType extends undefined ? GridColDef<R> : GridColDef<UnwoundRow<R, UnwoundType>>;

      const headerWithoutEditing =
        colDef?.renderHeader ||
        ((
          headerParams: UnwoundType extends undefined
            ? GridColumnHeaderParams<R, any, any>
            : GridColumnHeaderParams<UnwoundRow<R, UnwoundType>>
        ) => (
          <Tooltip title={colDef.description}>
            <Typography
              sx={{
                fontSize: 'unset',
                width: '100%',
              }}
            >
              {headerParams.colDef.headerName}
            </Typography>
          </Tooltip>
        ));

      const renderHeader =
        bulkEditMode && !isLoading && !noRows
          ? (
              headerParams: UnwoundType extends undefined
                ? GridColumnHeaderParams<R, any, any>
                : GridColumnHeaderParams<UnwoundRow<R, UnwoundType>>
            ) => (
              <ColumnHeaderWithEditorRenderer
                {...headerParams}
                fieldOptions={fields}
                renderHeader={headerWithoutEditing}
                shouldApplyBulkChangesToRow={shouldApplyBulkChangesToRow}
                arrayFieldToUnwind={arrayFieldToUnwind}
                context={fieldsContext}
              />
            )
          : headerWithoutEditing;
      return {
        ...colDef,
        renderHeader: renderHeader as any,
        ...(disableCellEditing || isLoading || noRows
          ? { editable: false }
          : useValueSetter
          ? {
              valueSetter: generateFieldValueSetter({
                field,
                fieldsContext,
                getRowWithChanges: getRowWithChanges,
                applyRowUpdates: applyRowUpdates,
                shouldApplyBulkChangesToRow,
                idGetter,
              }),
            }
          : {}),
      };
    }
  );
};
