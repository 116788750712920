import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';

const CaseDataSectionTitle: React.FunctionComponent<React.PropsWithChildren<{}>> = ({ children }) => {
  const theme = useTheme();
  return (
    <Grid item xs={5} marginTop={1}>
      <Typography variant="subtitle1" component="h3" fontWeight={theme.typography.fontWeightBold}>
        {children}
      </Typography>
    </Grid>
  );
};

export default CaseDataSectionTitle;
