import InsertChartIcon from '@mui/icons-material/InsertChart';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

import { Box, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import PageHeader from 'components/PageHeader';
import { Permission } from 'interfaces/permissionOption';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isPfizerDemoLab } from 'utils/demo';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { usePermissions } from 'utils/usePermissions';

import { MainButton } from 'components/atoms/Buttons/MainButton';
import JobsControl from 'components/Pages/Jobs/JobsControl';
import StudySettingsControl from 'components/StudiesDashboard/StudySettings/StudySettingsControl';
import { Study } from 'interfaces/study';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { CreateAnnotationAssignmentDialog } from './AnnotationAssignment/CreateAnnotationAssignmentDialog';
import StudyManifestControl from './CaseManifest/CaseManifestControl';
import SlideStatus from './SlideStatus';

interface Props {
  cohortMode: boolean;
  renderCohortDetailsToggle?: () => React.ReactNode;
  pageTitle: string;
  tab: string;
  isAnalyzing: boolean;
  isCurrentCheatMode: boolean;
  cheatStage: number;

  tooltipText?: string;
  isAddSlidesDisabled?: boolean;
  study: Study;
  onAddSlidesClick?: () => void;
}

const StudyDashboardHeader = ({
  cohortMode,
  renderCohortDetailsToggle,
  pageTitle,
  tab,
  isAnalyzing,
  isCurrentCheatMode,
  cheatStage,
  tooltipText,
  isAddSlidesDisabled,
  study,
  onAddSlidesClick,
}: Props) => {
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const [assignmentCreationModalOpen, setAssignmentCreationModalOpen] = useState(false);
  const canAccessAnalysisStudio = hasPermission(Permission.AccessAnalysisStudio);

  const { queryParams, encodedFilters } = useEncodedFilters();

  const { labId, stringifyWithLab } = useCurrentLabId();
  const pfizerDemoLab = isPfizerDemoLab(labId);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const showJobsControl = hasPermission(Permission.ViewJobs) && !cohortMode && Boolean(study);
  const showStudySettingsControl = hasPermission(Permission.ViewStudySettings) && !cohortMode && Boolean(study);
  const showStudyManifestControl = hasPermission(Permission.ApplyCaseManifest) && !cohortMode && Boolean(study);
  const showAnnotationAssignmentControl =
    hasPermission(Permission.CreateAnnotationAssignment) && !cohortMode && Boolean(study);

  const showMenu =
    showJobsControl || showStudySettingsControl || showStudyManifestControl || showAnnotationAssignmentControl;

  return (
    <Grid container my={3} px={3} justifyContent="space-between">
      <Grid item container lg={6} md={5} xs={4} alignItems="center" spacing={2}>
        <PageHeader
          isCohort={cohortMode}
          renderCohortDetailsToggle={renderCohortDetailsToggle}
          pageTitle={pageTitle}
          onBackClick={() => {
            const search = stringifyWithLab({
              tab,
              resultsMode: queryParams.resultsMode,
            });
            navigate({
              pathname: `/`,
              search: `?${search}`,
            });
          }}
        />
      </Grid>

      <Grid item lg={6} md={7} xs={8}>
        <Grid container direction="row" spacing={1} alignItems="center" justifyContent="end">
          {isAnalyzing && isCurrentCheatMode ? (
            cheatStage === 2 ? (
              <>
                <span className="gray"> Processing mode</span>
                <span className="ellipsis">...</span>
              </>
            ) : (
              <>
                <span className="gray"> Results are ready</span>
                <i className="icon-success" />
              </>
            )
          ) : (
            <Grid item>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <SlideStatus
                    isCheatMode={isCurrentCheatMode}
                    cheatStage={cheatStage}
                    ingested={study?.countIngestedInManifest}
                    total={study?.countInManifest}
                    navigateToUploadsDashboard
                  />
                </Grid>

                {!pfizerDemoLab && (
                  <Grid item>
                    <Tooltip title={tooltipText} placement="top" arrow>
                      <span>
                        <MainButton
                          disableElevation
                          variant="contained"
                          color="secondary"
                          disabled={isAddSlidesDisabled}
                          onClick={onAddSlidesClick}
                        >
                          Add Slides
                        </MainButton>
                      </span>
                    </Tooltip>
                  </Grid>
                )}
                <Grid item>
                  <MainButton
                    disabled={!canAccessAnalysisStudio}
                    endIcon={<InsertChartIcon />}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      navigate({
                        pathname: `/features-dashboard`,
                        search: encodedFilters,
                      });
                    }}
                  >
                    Analysis Studio
                  </MainButton>
                </Grid>
              </Grid>
            </Grid>
          )}
          {showMenu && (
            <Grid item>
              <Tooltip title="Menu">
                <IconButton onClick={handleMenuOpen}>
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {showJobsControl && <JobsControl menuItem studyId={study?.id} cameFrom="/procedures" />}
                {showStudySettingsControl && <StudySettingsControl menuItem study={study} />}
                {showStudyManifestControl && <StudyManifestControl menuItem studyId={study.id} />}
                {showAnnotationAssignmentControl && (
                  <MenuItem
                    onClick={() => {
                      setAssignmentCreationModalOpen(true);
                      handleMenuClose();
                    }}
                  >
                    <Box>
                      <IconButton>
                        <NoteAddIcon />
                      </IconButton>
                    </Box>
                    Add Annotation Assignment
                  </MenuItem>
                )}
              </Menu>
            </Grid>
          )}
        </Grid>
      </Grid>

      <CreateAnnotationAssignmentDialog
        open={assignmentCreationModalOpen}
        onClose={() => setAssignmentCreationModalOpen(false)}
      />
    </Grid>
  );
};

export default StudyDashboardHeader;
