import React from 'react';

import { HeaderCellEditorProps } from '.';
import { TextField, TextFieldProps } from '@mui/material';

const Text = <R,>({
  onChange,
  value,
  field,
  fieldType = 'text',
}: HeaderCellEditorProps<R> & {
  fieldType?: TextFieldProps['type'];
}) => {
  return (
    <TextField
      value={
        field.cellEditType === 'number' && !isNaN(Number(value))
          ? Number(Number(value / (field.conversionFactor ?? 1)).toFixed(2))
          : value || ''
      }
      variant="outlined"
      type={fieldType}
      InputProps={{
        inputProps: {
          min: fieldType === 'number' && field.cellEditType === 'number' ? field.min : undefined,
          max: fieldType === 'number' && field.cellEditType === 'number' ? field.max : undefined,
          step: fieldType === 'number' && field.cellEditType === 'number' ? field.step : undefined,
        },
      }}
      placeholder={field.label}
      size="small"
      onChange={(event) => {
        const {
          target: { value: targetValue },
        } = event;

        const res = field.cellEditType === 'number' ? Number(targetValue) * (field.conversionFactor ?? 1) : targetValue;
        onChange(res);
      }}
    />
  );
};

export default Text;
