import { Procedure } from 'interfaces/procedure';
import { ProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { Slide } from 'interfaces/slide';
import { cloneDeep, every, find, findIndex, first, includes, indexOf, join, map, slice, some } from 'lodash';
import { Row } from 'read-excel-file';
import { Cell } from 'read-excel-file/types';
import { reduceExtraSpaces } from 'utils/helpers';
import {
  BOOLEAN_OPTIONS,
  LOWER_CASE_MANDATORY_HEADERS,
  LOWER_CASE_SLIDE_IDENTIFIER_HEADER_OPTIONS,
  SLIDE_IDENTIFIER_HEADER_OPTIONS,
} from './consts';

export class ManifestError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ManifestError';
  }
}

export const validateMandatoryHeaders = (headers: string[], warnings: string[]): boolean => {
  const loweredCaseHeaders = map(headers, (header) => header.toLowerCase());
  if (
    !some(LOWER_CASE_SLIDE_IDENTIFIER_HEADER_OPTIONS, (slide_identifier) =>
      includes(loweredCaseHeaders, slide_identifier)
    )
  ) {
    throw new ManifestError(
      `Missing slide identifier, please add one of the following options: ${join(
        SLIDE_IDENTIFIER_HEADER_OPTIONS,
        ', '
      )}`
    );
  }
  if (every(LOWER_CASE_SLIDE_IDENTIFIER_HEADER_OPTIONS, (header) => headers.includes(header.toLowerCase()))) {
    warnings.push('More than one slide identifier header found - taking slide id');
  }
  return every(LOWER_CASE_MANDATORY_HEADERS, (header) => {
    if (!headers.includes(header)) {
      throw new ManifestError(`Missing mandatory header: ${header}`);
    }
    return true;
  });
};

export const getBooleanValue = (value: string | null): boolean | null => {
  if (value === null) {
    return null;
  }

  if (!includes(BOOLEAN_OPTIONS, value.toLowerCase())) {
    throw new ManifestError('Invalid boolean value: ' + value);
  }

  return includes(['y', 'yes'], value.toLowerCase());
};

export const getCaseByFileName = (fileName: string, procedures: Procedure[]) => {
  if (!fileName) {
    return null;
  }
  const trimmedFileName = reduceExtraSpaces(fileName);
  const casesWithTrimmedFileNames = map(procedures, (procedure) => ({
    ...procedure,
    slides: map(procedure?.slides, (slide) => ({
      ...slide,
      originalFileName: reduceExtraSpaces(slide.originalFileName),
    })),
  }));
  const searchedProcedure = find(casesWithTrimmedFileNames, (procedure) =>
    some(procedure?.slides, { originalFileName: trimmedFileName })
  );
  return find(procedures, { id: searchedProcedure?.id });
};

export const getSlideByFileName = (fileName: string, procedure: Procedure) => {
  if (!fileName) {
    return null;
  }
  const trimmedFileName = reduceExtraSpaces(fileName);
  const caseWithTrimmedFileNames = {
    ...procedure,
    slides: map(procedure?.slides, (slide) => ({
      ...slide,
      originalFileName: reduceExtraSpaces(slide.originalFileName),
    })),
  };
  const searchedSlide = find(caseWithTrimmedFileNames.slides, { originalFileName: trimmedFileName });
  return find(procedure.slides, { id: searchedSlide?.id });
};

export const getCaseBySlideId = (slideId: string, procedures: Procedure[]) => {
  return find(procedures, (procedure) => some(procedure?.slides, { id: slideId }));
};

const DEFAULT_CANCER_TYPE_ID = 9; // N/A

export const getCaseFromRowCombinedWithRealData = (
  procedureFieldContext: ProceduresFieldsContext,
  headers: string[],
  row: Row,
  labId: string,
  studyId: string,
  index: number,
  allCasesAndMockCases: Procedure[],
  notEditableSlidesMockCases: Procedure[]
): Procedure => {
  const { allCancerTypes, biopsySiteTypes, stainTypesNotDeprecated } = procedureFieldContext;

  const caseLabelCell: Cell = row[indexOf(headers, 'case id')];
  const caseLabel = caseLabelCell ? reduceExtraSpaces(caseLabelCell.toString()) : null;

  const fileNameCell: Cell = row[indexOf(headers, 'file name')];
  let fileName: string = fileNameCell ? reduceExtraSpaces(fileNameCell.toString()) : null;
  const slideIdCell: Cell = row[indexOf(headers, 'slide id')];
  let slideId: string = slideIdCell ? reduceExtraSpaces(slideIdCell.toString()) : null;

  const stainTypeCell: Cell = row[indexOf(headers, 'stain type')];
  const stainType = stainTypeCell ? reduceExtraSpaces(stainTypeCell.toString()) : null;
  const cancerTypeCell: Cell = row[indexOf(headers, 'cancer type')];
  const cancerType = cancerTypeCell ? reduceExtraSpaces(cancerTypeCell.toString()) : null;
  const biopsySiteCell: Cell = row[indexOf(headers, 'biopsy site')];
  const biopsySite = biopsySiteCell ? reduceExtraSpaces(biopsySiteCell.toString()) : null;
  const cancerSubtypeCell: Cell = row[indexOf(headers, 'cancer subtype')];
  const cancerSubtype = cancerSubtypeCell ? reduceExtraSpaces(cancerSubtypeCell.toString()) : null;
  const biopsyTypeCell: Cell = row[indexOf(headers, 'biopsy type')];
  const biopsyType = biopsyTypeCell ? reduceExtraSpaces(biopsyTypeCell.toString()) : null;
  const metastasisCell: Cell = row[indexOf(headers, 'metastasis (y/n)')];
  const metastasis = metastasisCell ? reduceExtraSpaces(metastasisCell.toString()) : null;
  const positiveControlCell: Cell = row[indexOf(headers, 'positive control on slide (y/n)')];
  const positiveControl = positiveControlCell ? reduceExtraSpaces(positiveControlCell.toString()) : null;
  const negativeControlCell: Cell = row[indexOf(headers, 'negative control on slide (y/n)')];
  const negativeControl = negativeControlCell ? reduceExtraSpaces(negativeControlCell.toString()) : null;

  const cancerTypeId = cancerType
    ? find(allCancerTypes, { displayName: cancerType })?.id || cancerType
    : DEFAULT_CANCER_TYPE_ID;

  const biopsySiteId: number | string = biopsySite
    ? find(biopsySiteTypes, { displayName: biopsySite })?.id || biopsySite
    : null;

  const stainTypeId: string = stainType
    ? find(stainTypesNotDeprecated, { displayName: stainType })?.id || stainType
    : null;

  if (!fileName && !slideId) {
    throw new ManifestError('Missing slide identifier');
  }

  let existingCase: Procedure = null;
  let existingSlide: Slide = null;

  // add slide id fetching when filename provided, and filename fetching when slide id provided
  if (slideId) {
    const notEditableSlideMockCase = find(
      notEditableSlidesMockCases,
      (mockCase) => first(mockCase?.slides)?.id === slideId
    );
    if (notEditableSlideMockCase) {
      existingCase = {
        id: notEditableSlideMockCase.id,
        labId: labId,
        label: caseLabel,
        cancerTypeId: DEFAULT_CANCER_TYPE_ID,
        slides: notEditableSlideMockCase.slides,
        studyId: studyId,
      };
      existingSlide = first(notEditableSlideMockCase.slides);
      fileName = existingSlide.originalFileName;
    } else {
      existingCase = getCaseBySlideId(slideId, allCasesAndMockCases);
      existingSlide = existingCase ? (find(existingCase.slides, { id: slideId }) as Slide) : undefined;
      fileName = existingSlide?.originalFileName;
    }
  } else {
    existingCase = getCaseByFileName(fileName, allCasesAndMockCases);
    existingSlide = existingCase ? getSlideByFileName(fileName, existingCase) : undefined;
    slideId = existingSlide?.id;
  }

  const notEditableSlide = first(
    find(notEditableSlidesMockCases, (mockCase) => first(mockCase?.slides)?.id === slideId)?.slides
  );

  const procedure: Procedure = {
    id: existingCase ? existingCase.id : index,
    labId: labId,
    comments: existingCase?.comments,
    batchDate: existingCase?.batchDate,
    createdAt: existingCase?.createdAt,
    presentationInfo: existingCase?.presentationInfo,
    diagnosis: existingCase?.diagnosis,
    label: caseLabel,
    cancerTypeId: cancerTypeId,
    cancerSubtypes: cancerSubtype ? [cancerSubtype] : existingCase?.cancerSubtypes,
    slides: [
      {
        ...(notEditableSlide || {
          ...(existingSlide ?? {}),
          id: existingSlide?.id || slideId ? reduceExtraSpaces(slideId) : index.toString(),
          originalFileName: existingSlide?.originalFileName || fileName,
          stainingType: stainTypeId ? stainTypeId : existingSlide?.stainingType,
          biopsySiteId: biopsySiteId ? biopsySiteId : existingSlide?.biopsySiteId,
          biopsyType: biopsyType ? biopsyType : existingSlide?.biopsyType,
          negativeControl: negativeControl ? getBooleanValue(negativeControl) : existingSlide?.negativeControl,
          positiveControl: positiveControl ? getBooleanValue(positiveControl) : existingSlide?.positiveControl,
          metastasis: metastasis ? getBooleanValue(metastasis) : existingSlide?.metastasis,
        }),
      },
    ],
    clinicalData: existingCase?.clinicalData,
    internallyApproved: existingCase?.internallyApproved,
    features: existingCase?.features,
    studyId: studyId,
  };

  return procedure;
};

// This function adds the new case to the current cases list.
// If the case already exists, it will add the slide to the existing case.
// If this is a list of slides that does not exist in the database,
// it will generate a new temporary id for the slide if it's case already exists (so slides in the same case will have different ids)
// so each row in the table will have a unique id.
const addCaseToList = (newCase: Procedure, cases: Procedure[], isSlideMissing: boolean = false) => {
  const slide = first(newCase.slides);
  const slideFileName = slide.originalFileName;
  const existingCaseIndex = findIndex(cases, { label: newCase.label });
  if (existingCaseIndex !== -1) {
    const existingCase = cases[existingCaseIndex];
    if (find(existingCase.slides, { originalFileName: slideFileName })) {
      const newWarning = `Slide ${slideFileName} in case ${existingCase.label} already exists in the manifest. (taking first slide)`;
      return { cases, warning: newWarning };
    } else {
      // Generate a new id for the missing slide (does not exist in db) so slides in the same case will have different ids
      const slideToAdd = isSlideMissing ? { ...slide, id: (existingCase.slides.length + 1).toString() } : slide;

      const updatedCase = cloneDeep(existingCase);
      updatedCase.slides.push(slideToAdd);

      const newCases = [...slice(cases, 0, existingCaseIndex), updatedCase, ...slice(cases, existingCaseIndex + 1)];

      return { cases: newCases };
    }
  }
  return { cases: [...cases, newCase] };
};

// This function adds the new case to the relevant list (cases or missing slides)
export const addCaseToRelevantList = (
  newCase: Procedure,
  currentMissingSlides: Procedure[],
  currentCases: Procedure[],
  allExistingAndMockCases: Procedure[]
) => {
  const slideFileName = first(newCase.slides).originalFileName;
  const caseFromDatabase: Procedure = getCaseByFileName(slideFileName, allExistingAndMockCases);

  if (!caseFromDatabase) {
    const { cases: newMissingSlides, warning: newWarning } = addCaseToList(newCase, currentMissingSlides, true);
    return { updatedMissingSlides: newMissingSlides, updatedCases: currentCases, warning: newWarning };
  }

  const { cases: newCases, warning: newWarning } = addCaseToList(newCase, currentCases);
  return { updatedMissingSlides: currentMissingSlides, updatedCases: newCases, warning: newWarning };
};
