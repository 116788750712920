import { ChartOptions } from 'chart.js';
import { CohortWithSelectedFeatures } from 'interfaces/cohort_old';
import { curry, filter, first, get, isArray, isEmpty, map, range } from 'lodash';
import { splitStringAfterNChars } from 'utils/helpers';
import { cohortToValues, cohortsToDatasets, formatNumber, getVerticalScaleOptions } from '../chart.util';

export const cohortToDistanceBasedData = curry(
  (horizontalKey: string, bucketCount: number, cohort: CohortWithSelectedFeatures) => {
    const dataFromCohort = cohortToValues(horizontalKey);

    const valuesPerCase = filter(dataFromCohort(cohort), (v): v is number[] => isArray(v));

    if (valuesPerCase.length > 1) {
      console.error(
        'Distance based features are supposed to return a single array of histogram values - but we received multiple arrays.',
        {
          horizontalKey,
          valuesPerCase,
          cohort,
        }
      );
    }
    const values = first(valuesPerCase) || [];
    if (valuesPerCase && !isEmpty(values) && values.length !== bucketCount) {
      console.error(
        'Distance based features are supposed to return an array of histogram values per bucket - ' +
          'but we received an unexpected number of buckets.',
        {
          horizontalKey,
          expected: bucketCount,
          actual: values.length,
          values,
          cohort,
        }
      );
    }

    const buckets = map(range(0, bucketCount), (i: number) => get(values, i, 0) ?? 0);

    return buckets;
  }
);

export const cohortsToDistanceBasedDataset = (
  [minValue, maxValue]: [number, number],
  horizontalKey: string,
  bucketSize: number
) => {
  const bucketCount = isNaN(bucketSize) ? 10 : Math.ceil((maxValue - minValue) / bucketSize);
  bucketSize = bucketSize ?? (maxValue - minValue) / bucketCount;
  return cohortsToDatasets('bar', cohortToDistanceBasedData(horizontalKey, bucketCount));
};

export const distanceBasedChartOptions = ({
  valuesRange: [min, max],
  bucketSize,
  yScaleTitle = 'Value',
  maxCharsPerLabelLine = 50,
}: {
  valuesRange: [number, number];
  bucketSize: number;
  yScaleTitle?: string;
  maxCharsPerLabelLine?: number;
}): ChartOptions<'bar'> => {
  return {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 9,
          },
          boxWidth: 0,
          boxHeight: 0,
          boxPadding: 0,
        },
      },
      tooltip: {
        callbacks: {
          title: ([{ dataIndex }]) => {
            const start = min + dataIndex * bucketSize;
            const end = start + bucketSize;
            const title = `${start} - ${end}`;
            return title;
          },
          label: (context) => {
            const label = context.dataset?.label || '';
            const y = context.parsed.y;

            // Multiple strings will create a multiple line label - not documented in chart.js types
            return [...splitStringAfterNChars(`${label}`, maxCharsPerLabelLine), `${formatNumber(y)} (${y})`] as any;
          },
        },
      },
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        min,
        max,
        bounds: 'data',
        beginAtZero: false,

        ticks: {
          stepSize: bucketSize,
        },
      },
      y: {
        ...getVerticalScaleOptions(yScaleTitle),
        display: true,
        position: { x: 0 },
        ticks: { display: false },
        grid: {
          borderColor: 'black',
          borderWidth: 1,
        },
        title: {
          display: false,
        },
      },
      yText: {
        ...getVerticalScaleOptions(yScaleTitle),
        display: true,
        grid: {
          display: false,
        },
      },
    },
  };
};
