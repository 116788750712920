import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Grid } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import React, { useCallback, useState } from 'react';

import { NormalizationResult } from 'interfaces/experimentResults';
import { useSelectChannel } from '../colorSettings';
import { ChannelMarkerTypeChoice } from './ChannelMarkerTypeChoice';
import { MainSettings } from './MainSettings';
import { MultiplexChannelColor } from './MultiplexChannelColor';
import { MultiplexDynamicRange } from './MultiplexDynamicRange';
import { MultiplexGamma } from './MultiplexGamma';

interface Props {
  markerType: string;
  draftAssignedMarkers: string[];
  channelId: string;
  channelName: string;
  editChannelMarkerMode: boolean;
  draftMarkerType: string;
  selected: boolean;
  disabled?: boolean;
  slideId: string;
  viewerIndex: number;
  encoding: string;
  channelHistogram?: number[];
  normalizationData?: NormalizationResult;
  handleChannelMarkerTypeChange(channel: string, value: string): void;
  resetChannelNormalizationSettings: (channelIndex: number, markerType?: string) => void;
}

const MultiplexChannelControl: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  draftAssignedMarkers,
  channelId,
  channelName,
  editChannelMarkerMode,
  draftMarkerType,
  markerType: dbMarkerType,
  selected,
  disabled = false,
  slideId,
  viewerIndex,
  encoding,
  channelHistogram,
  normalizationData,
  handleChannelMarkerTypeChange,
  resetChannelNormalizationSettings,
}) => {
  useSignals();
  const markerType = editChannelMarkerMode ? draftMarkerType : dbMarkerType;
  const [expand, setExpand] = useState(false);
  const toggleExpand = useCallback(() => setExpand((prev) => !prev), []);

  const { handleSelectChannel } = useSelectChannel();

  return (
    <Accordion disableGutters expanded={expand && !editChannelMarkerMode} square>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={editChannelMarkerMode ? { opacity: 0.3 } : undefined}
            onClick={!editChannelMarkerMode ? toggleExpand : undefined}
          />
        }
        sx={{ paddingInline: 1 }}
      >
        <Grid container wrap="nowrap" alignItems="center" direction="column">
          <Grid container wrap="nowrap" alignItems="center" sx={{ height: 30 }}>
            <Grid item>
              <Checkbox
                checked={selected}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const newSelected = Boolean(event?.target?.checked);
                  handleSelectChannel(viewerIndex, slideId, channelId, newSelected, markerType);
                }}
                disabled={disabled}
              />
            </Grid>
            <MultiplexChannelColor
              selected={selected}
              viewerIndex={viewerIndex}
              slideId={slideId}
              channelId={channelId}
              markerType={markerType}
            />
            {editChannelMarkerMode ? (
              <ChannelMarkerTypeChoice
                channelId={channelId}
                channelName={channelName}
                markerType={markerType}
                assignedMarkers={draftAssignedMarkers}
                previousMarkerAssignment={dbMarkerType}
                handleChannelMarkerTypeChange={handleChannelMarkerTypeChange}
              />
            ) : (
              <MainSettings
                channelId={channelId}
                channelName={channelName}
                markerType={markerType}
                selected={selected}
                viewerIndex={viewerIndex}
                slideId={slideId}
              />
            )}
          </Grid>
          <MultiplexDynamicRange
            viewerIndex={viewerIndex}
            encoding={encoding}
            slideId={slideId}
            channelId={channelId}
            selected={selected}
            resetChannelNormalizationSettings={resetChannelNormalizationSettings}
            channelHistogram={channelHistogram}
            normalizationData={normalizationData}
            markerType={markerType}
          />
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingInline: 1 }}>
        <Grid container wrap="nowrap" alignItems="center" direction="column">
          <MultiplexGamma
            viewerIndex={viewerIndex}
            slideId={slideId}
            channelId={channelId}
            selected={selected}
            markerType={markerType}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default MultiplexChannelControl;
