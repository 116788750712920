import Loader from 'components/Loader';
import { Permission } from 'interfaces/permissionOption';
import React from 'react';
import { Location, Navigate, NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { usePermissions } from 'utils/usePermissions';

interface Props {
  component: React.ComponentType<React.PropsWithChildren<RouteProps>>;
  isAuthenticated: boolean;
  loginRoute: string;
  permissionsRequired?: Permission[]; // if not provided, all roles except disallowed by default are allowed
}

const disallowedByDefault = [Permission.ViewAccessionDashboard];

const defaultRoute = '/';

export interface RouteProps {
  location: Location;
  navigate: NavigateFunction;
  params: Record<string, string>;
}

const PrivateRoute: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  component: Component,
  isAuthenticated,
  loginRoute,
  permissionsRequired,
}) => {
  const user = useAppSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const { permissions, isLoading, hasSomePermissions, isError } = usePermissions();

  if (!isAuthenticated || !user || isError) {
    return <Navigate to={loginRoute} replace state={{ from: location }} />;
  }

  if (isLoading || permissions === undefined) {
    return <Loader />;
  }

  const defaultDisallowed = hasSomePermissions(disallowedByDefault); // if user has any of the default disallowed roles, they are disallowed by default
  const overrideAllowed = hasSomePermissions(permissionsRequired || []); // if user has any of the allowed roles, they are allowed even if they are disallowed by default

  if (defaultDisallowed && !overrideAllowed) {
    return <Navigate to={defaultRoute} replace state={{ from: location }} />;
  }

  return <Component location={location} navigate={navigate} params={params} />;
};

export default PrivateRoute;
