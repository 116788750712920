import { clamp, first, isEmpty, map, uniq } from 'lodash';

import { OSDPixelSource } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/OSDPixelSource';
import { calculateMaxZoom, calculateMaxZoomOffset } from 'utils/slideTransformation';
import { SlideDziChannel } from './setupSlideSources';

export const generateSlidePixelSource = ({
  slideTileSources,
  sourceName,
  debug,
}: {
  slideTileSources: SlideDziChannel[];
  sourceName: string;
  debug?: boolean;
}): OSDPixelSource<SlideDziChannel> => {
  if (isEmpty(slideTileSources)) {
    console.warn(`No tile sources found for ${sourceName}`, slideTileSources);
    return null;
  }
  const referenceSource = first(slideTileSources);
  const sourceDimensions = referenceSource.source.dimensions;
  const largestDim = Math.max(sourceDimensions.x, sourceDimensions.y);

  const tileSizes: number[] = map(slideTileSources, 'metadata.tileSize');
  const uniqueTileSizes = uniq(tileSizes);
  if (uniqueTileSizes.length > 1) {
    throw new Error('Tile sizes are not uniform');
  }
  const tileSize = first(uniqueTileSizes);

  const maxLevel = calculateMaxZoom(largestDim);
  const maxZoomOffset = calculateMaxZoomOffset(largestDim, tileSize);
  const minLevel = clamp(maxLevel - maxZoomOffset, 0, maxLevel);

  if (debug) {
    console.debug(`Generating pixel source for ${sourceName} with levels ${minLevel}-${maxLevel}`);
  }

  return new OSDPixelSource(slideTileSources, { maxLevel, minLevel, debug });
};
