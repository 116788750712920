import { useQuery } from '@tanstack/react-query';
import { fetchSingleStudy } from 'api/study';
import { useCurrentLabId } from './useCurrentLab';

const useStudy = (
  studyId: string,
  {
    enabled = true,
  }: {
    enabled?: boolean;
  } = {}
) => {
  const { labId } = useCurrentLabId();
  const query = useQuery(['study', studyId], () => fetchSingleStudy(studyId, labId), {
    enabled: Boolean(studyId) && enabled,
  });

  return { study: query.data, ...query };
};

export default useStudy;
