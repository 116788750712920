import { useEffect } from 'react';

import { useCurrentLabId } from 'utils/useCurrentLab';
import { useCaseIdToLabId } from './useCaseIdToLabId';

// Sync lab with case's lab when in case page
export const useSetInitialLabId = () => {
  const { queryLabId, setLabId, userLabId } = useCurrentLabId();
  const { procedureLabId, isLoading, caseId } = useCaseIdToLabId();

  useEffect(() => {
    if (!queryLabId && !caseId && userLabId) {
      setLabId(userLabId, 'replaceIn');
    } else if (procedureLabId && procedureLabId !== queryLabId) {
      // Sync lab with case's lab when in case page
      setLabId(procedureLabId, 'replaceIn');
    }
  }, [userLabId, caseId, queryLabId, setLabId, procedureLabId, isLoading]);
};
