import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Button, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { isInteger, map } from 'lodash';
import numeral from 'numeral';
import React from 'react';

const presetMagnificationLevels = [2, 10, 20, 40];

/**
 * Round down if the magnification is greater than 10
 * Round to the nearest tenth if the magnification is less than 10, but remove the decimal if it's a whole number
 * This is to prevent displaying magnifications like "X2.0" instead of "X2"
 * i.e. 2.0 -> 2, 2.5 -> 2.5
 */
export const formatMagnification = (value: number) => {
  const magnificationDecimalValue = numeral(value).format('0.0');
  if (value > 10 || isInteger(Number(magnificationDecimalValue))) {
    return numeral(magnificationDecimalValue).format('0');
  } else {
    return magnificationDecimalValue;
  }
};

const SlideMagnification: React.FunctionComponent<
  React.PropsWithChildren<{
    magnificationValue: number;
    onChangeMagnification(newMagnification: number): void;
  }>
> = ({ magnificationValue, onChangeMagnification }) => {
  useSignals();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item>
      <Button
        id="zoom-menu-button"
        aria-label="magnification-selection"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        sx={{ width: 80 }}
        onClick={handleOpen}
        startIcon={<ZoomInIcon />}
      >
        <Typography variant="subtitle1">X{formatMagnification(magnificationValue)}</Typography>
      </Button>
      <Menu
        id="zoom-menu"
        MenuListProps={{ 'aria-labelledby': 'zoom-menu-button' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {map(presetMagnificationLevels, (presetMagnification) => (
          <MenuItem
            key={presetMagnification}
            onClick={() => {
              onChangeMagnification(presetMagnification);
              handleClose();
            }}
          >
            X{presetMagnification}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

export default SlideMagnification;
