import { FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Skeleton } from '@mui/material';
import CohortWithQuery from 'interfaces/cohort_old';
import { castArray, concat, first, map } from 'lodash';
import { pull } from 'lodash/fp';
import React from 'react';

interface Props {
  cohorts: CohortWithQuery[];
  selectedCohortIds: string[];
  updateSelectedCohortIds(cohortIds: string[]): void;
  isLoading: boolean;
}

const CohortSelect: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  cohorts,
  selectedCohortIds,
  updateSelectedCohortIds,
  isLoading,
}) => {
  const selectStudy = (cohortId: string) => () => {
    if (selectedCohortIds.includes(cohortId)) {
      updateSelectedCohortIds(pull(cohortId, selectedCohortIds));
    } else {
      updateSelectedCohortIds(concat(selectedCohortIds, cohortId));
    }
  };

  if (isLoading) {
    return <Skeleton variant="rectangular" />;
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="cohort-select-label">Select Source Cohort or Study</InputLabel>
      <Select
        label="Select Source Cohort or Study"
        labelId="cohort-select-label"
        onChange={(event: SelectChangeEvent) => updateSelectedCohortIds(castArray(event.target.value))}
        value={first(selectedCohortIds) || ''}
      >
        {map(cohorts, (cohort) => (
          <MenuItem
            key={cohort.id}
            value={cohort.id}
            onClick={selectStudy(cohort.id)}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ListItemText primary={cohort.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CohortSelect;
