import { DrawerToggleButton } from 'components/atoms/DrawerToggleButton';
import React from 'react';

export const ThumbnailsSlider: React.FunctionComponent<
  React.PropsWithChildren<{
    onClick(): void;
    collapsed: boolean;
    drawerHeight: string | number;
    marginLeft: string | number;
    marginRight: string | number;
    text?: string;
  }>
> = ({ text, onClick, collapsed, drawerHeight, marginLeft, marginRight }) => {
  return (
    <DrawerToggleButton
      text={text}
      onClick={onClick}
      collapsed={collapsed}
      direction="bottom"
      drawerHeight={drawerHeight}
      leftOffset={`calc(50vw${marginLeft ? ` + ${marginLeft} / 2` : ''}${
        marginRight ? ` - ${marginRight ?? '0px'} / 2` : ''
      })`}
      centerDirection="left"
    />
  );
};
