import { Autocomplete, TextField } from '@mui/material';
import { MultiTextField } from 'interfaces/genericFields';
import { compact, isEmpty, last } from 'lodash';
import React, { SyntheticEvent } from 'react';
import { HeaderCellEditorProps } from '.';

const HeaderMultiTextInput = <R, V, Context extends {} = {}>({
  onChange,
  value,
  field,
}: HeaderCellEditorProps<R, V, Context, MultiTextField>) => {
  const handleChange = (event: SyntheticEvent<Element, Event>, nextValue: string | string[]) => {
    if (last(nextValue) === '') {
      onChange([]);
      return;
    }
    const effectiveSelection = compact(nextValue);
    onChange(effectiveSelection);
  };

  return (
    <Autocomplete<string | number, boolean, boolean, boolean>
      multiple
      value={isEmpty(value) ? [] : value || ''}
      onChange={handleChange}
      onBlur={(e) => {
        // https://github.com/mui/mui-x/issues/3393 - MUI Grid has a bug with components that use portals in header cells
        e.stopPropagation();
      }}
      size="small"
      freeSolo={true}
      open={false}
      options={['']}
      renderInput={(params) => <TextField {...params} label={field.label} />}
    />
  );
};

export default HeaderMultiTextInput;
