import HistogramChartIcon from '@mui/icons-material/BarChart';
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
import ExpandIcon from '@mui/icons-material/Expand';
import PieChartIcon from '@mui/icons-material/PieChart';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import { Grid, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useQueryParam } from 'use-query-params';

import { ControlledChartOptions } from 'components/FeaturesDashboard/ControlledChart';
import { highlightedFeaturesToCharts } from 'components/FeaturesDashboard/chart.util';
import { ChartType } from 'interfaces/chart';
import { CohortWithSelectedFeatures } from 'interfaces/cohort_old';
import { OverviewSubSectionTitle } from '.';
import ChartsSection, { ChartConfigurationParam } from '../../ChartsSection';

interface Props {
  cohort: CohortWithSelectedFeatures;
  setCohort: (setCohort: (prevCohort: any) => any) => void;
  cohortAllFeatures: string[];
  isLoading: boolean;
}

const TopFeaturesSection: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  cohort,
  setCohort,
  cohortAllFeatures,
  isLoading,
}) => {
  const [, setExistingChartsMap] = useQueryParam<Record<number, ControlledChartOptions>>(
    'overviewCharts',
    ChartConfigurationParam
  );
  useEffect(() => {
    const defaultCharts = highlightedFeaturesToCharts(cohort?.highlightedFeatures, cohort?.inferredFeaturesConfig);
    setExistingChartsMap(defaultCharts);
  }, [cohort?.highlightedFeatures]);

  const theme = useTheme();

  return (
    <Grid container direction="column" spacing={1} px={5}>
      <Grid item>
        <OverviewSubSectionTitle>Top Features</OverviewSubSectionTitle>
      </Grid>
      <Grid
        item
        sx={{
          '& .MuiCard-root': {
            borderColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 2,
          },
        }}
      >
        <ChartsSection
          cohort={cohort}
          setCohort={setCohort}
          cohortAllFeatures={cohortAllFeatures}
          isLoading={isLoading}
          addChartActions={addChartActions}
          layout="large"
        />
      </Grid>
    </Grid>
  );
};

const addChartActions: { key: ChartType; icon: any; name: string }[] = [
  { key: ChartType.Histogram, icon: <HistogramChartIcon />, name: 'Histogram' },
  { key: ChartType.Pie, icon: <PieChartIcon />, name: 'Pie' },
  { key: ChartType.Box, icon: <CandlestickChartOutlinedIcon />, name: 'Box Plot' },
  { key: ChartType.Scatter, icon: <ScatterPlotIcon />, name: 'Scatter Plot' },
  { key: ChartType.DistanceBased, icon: <ExpandIcon />, name: 'Distance Based' },
];

export default TopFeaturesSection;
