import { Permission } from 'interfaces/permissionOption';

export interface Role {
  id: string;
  name: string;
  description: string;
  labSpecific: boolean;
  permissions: Permission[];
}

export const roleToTitle = (role?: Role) => {
  return role
    ? `${role.name || role.description || role.id}${role.labSpecific ? ' (lab specific)' : ''}`
    : 'Deprecated role';
};
