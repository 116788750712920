import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FilterKey, QueryFilter } from 'interfaces/cohort';
import { Field, FieldGroup } from 'interfaces/genericFields';
import { map, partialRight } from 'lodash';
import React from 'react';
import QueryFieldInput from '../QueryFieldInput';

interface FieldGroupProps {
  isLast: boolean;
  onFieldChange: (type: string, key: FilterKey, value: QueryFilter['value'], category: Field['dataCategory']) => void;
  fieldGroup: FieldGroup;
  advancedInputs: Partial<Record<string, QueryFilter>>;
}

export const AdvancedSearchFieldGroup: React.FunctionComponent<React.PropsWithChildren<FieldGroupProps>> = ({
  advancedInputs,
  fieldGroup,
  isLast,
  onFieldChange,
}) => {
  const theme = useTheme();

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        borderRight: !isLast ? `1px solid ${theme.palette.grey[100]}` : 'none',
        pr: 2,
      }}
      justifyContent="center"
    >
      <Grid container item spacing={1}>
        {map(fieldGroup.fields, (field) => {
          return (
            <Grid container item direction="column" key={field.dataKey}>
              <Grid item>
                <Typography variant="caption">{field?.label}</Typography>
              </Grid>
              <Grid item sx={{ maxWidth: '100%', ...(field?.filterType === 'range' && { mt: 1 }) }}>
                <QueryFieldInput
                  field={field}
                  onChange={partialRight(onFieldChange, field?.dataCategory)}
                  value={advancedInputs[field.dataKey]?.value}
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
