import { Grid, Typography } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import TaxonomyTreeViewItem from 'components/Pages/OperationsSettings/Taxonomy/TaxonomyTreeViewItem';
import { TaxonomyTree } from 'interfaces/taxonomy';
import { concat, isEmpty, map } from 'lodash';
import React, { useState } from 'react';
import useTaxonomy from 'utils/queryHooks/taxonomy/useTaxonomy';
import useTaxonomyTags from 'utils/queryHooks/taxonomy/useTaxonomyTags';
import TreeViewSkeleton from './Skeletons/TreeViewSkeleton';
import TaxonomySearchPanel from './Taxonomy/TaxonomySearchPanel';

interface TreeCheckboxMultiSelectProps {
  selectedTaxonomyIds: string[];
  onSelectTaxonomyIds: (taxonomiesIds: string[]) => void;
  isLoading?: boolean;
  isError?: boolean;
}

const TreeCheckboxMultiSelect: React.FC<TreeCheckboxMultiSelectProps> = ({
  selectedTaxonomyIds,
  onSelectTaxonomyIds,
  isLoading,
  isError,
}) => {
  const { data: taxonomies, isLoading: isTaxonomyLoading, isError: isErrorGettingTaxonomy } = useTaxonomy();
  const { isLoading: isLoadingTags, isError: isErrorGettingTags } = useTaxonomyTags();
  const [expandedItems, setExpandedItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [taxonomyTreesFiltered, setTaxonomyTreesFiltered] = useState<TaxonomyTree[]>([]);

  const applySearch = (value: string[]) => {
    setExpandedItems(value);
  };

  const handleExpandedItemsChange = (event: React.SyntheticEvent, itemIds: string[]) => {
    setExpandedItems(itemIds);
  };

  return (
    <Grid item container direction="column" spacing={1} p={2}>
      <TaxonomySearchPanel
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        taxonomies={taxonomies}
        onSearch={applySearch}
        expandedItems={expandedItems}
        setExpandedItems={setExpandedItems}
        setTaxonomyTreesFiltered={setTaxonomyTreesFiltered}
      />

      <Grid item xs={true} sx={{ overflowY: 'auto' }}>
        {isErrorGettingTaxonomy || isErrorGettingTags || isError ? (
          <Typography variant="body2" color="text.secondary">
            Failed to load taxonomies
          </Typography>
        ) : isTaxonomyLoading || isLoadingTags || isLoading ? (
          <TreeViewSkeleton />
        ) : isEmpty(taxonomyTreesFiltered) && !isEmpty(searchValue) ? (
          <Typography variant="body1" color="text.secondary" p={2}>
            No results found
          </Typography>
        ) : (
          <SimpleTreeView
            multiSelect
            checkboxSelection
            expandedItems={expandedItems}
            onExpandedItemsChange={handleExpandedItemsChange}
            selectedItems={selectedTaxonomyIds}
            onSelectedItemsChange={(event: React.SyntheticEvent, ids: string[]) => onSelectTaxonomyIds(ids)}
          >
            {map(taxonomyTreesFiltered, (tree) => (
              <TaxonomyTreeViewItem
                key={tree.path}
                taxonomyTree={tree}
                searchValue={searchValue}
                withTags
                onAddClassWithTags={(newClass) => onSelectTaxonomyIds(concat(selectedTaxonomyIds, [newClass]))}
              />
            ))}
          </SimpleTreeView>
        )}
      </Grid>
    </Grid>
  );
};

export default TreeCheckboxMultiSelect;
