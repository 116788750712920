import { SET_PREFERENCES } from '../actionTypes/preferences';

export const setPreferences = (showNavigator: boolean) => ({
  type: SET_PREFERENCES,
  payload: {
    showNavigator,
  },
});

export default setPreferences;
