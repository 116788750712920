import { degreesToRadians } from '@turf/helpers';
import { map, reduce } from 'lodash';
import * as math from 'mathjs';

export interface Point {
  x: number;
  y: number;
}

/**
 * Compute the modulo of a number but makes sure to always return
 * a positive value (also known as Euclidean modulo).
 * @param {Number} number the number to compute the modulo of
 * @param {Number} modulo the modulo
 * @returns {Number} the result of the modulo of number
 */
export const positiveModulo = (number: number, modulo: number) => {
  let result = number % modulo;
  if (result < 0) {
    result += modulo;
  }
  return result;
};

/**
 * Rotates the point around the specified pivot
 * From http://stackoverflow.com/questions/4465931/rotate-rectangle-around-a-point
 * @function
 * @param {Number} degress to rotate around the pivot.
 * @param {Point} [pivot=(0,0)] Point around which to rotate.
 * Defaults to the origin.
 * @returns {Point}. A new point representing the point rotated around the specified pivot
 */
export const rotatePoint = (pt: Point, degrees: number, pivot: Point = { x: 0, y: 0 }): Point => {
  let cos = 1;
  let sin = 0;
  // Avoid float computations when possible
  if (degrees % 90 === 0) {
    let d = positiveModulo(degrees, 360);
    switch (d) {
      case 0:
        cos = 1;
        sin = 0;
        break;
      case 90:
        cos = 0;
        sin = 1;
        break;
      case 180:
        cos = -1;
        sin = 0;
        break;
      case 270:
        cos = 0;
        sin = -1;
        break;
      default:
        console.warn(`Unexpected angle ${d} [${degrees}] (not multiple of 90) in rotatePoint.`);
        break;
    }
  } else {
    const angle = degreesToRadians(degrees);
    cos = Math.cos(angle);
    sin = Math.sin(angle);
  }
  const x = cos * (pt.x - pivot.x) - sin * (pt.y - pivot.y) + pivot.x;
  const y = sin * (pt.x - pivot.x) + cos * (pt.y - pivot.y) + pivot.y;
  return { x, y };
};

/**
 * Converts a point to a vector (mathjs matrix)
 * @param {Point} pt the point to convert
 * @returns {math.Matrix} the vector representation of the point
 */
const pointToVector = (pt: Point): math.Matrix => math.matrix([pt.x, pt.y]);

/**
 * Compute the average of an array of numbers
 * @param {Number[]} arr the array of numbers
 * @returns {Number} the average of the array
 */
const average = (arr: math.MathType[]) =>
  math.divide(
    reduce(arr, (a, b) => math.add(a, b), 0),
    arr.length
  );

/**
 * Sum the points along axis 1
 * @param {math.MathArray[]} arr the array of points
 * @returns {Point} the sum of the points along axis 1
 */
const sumPointsAxis1 = (arr: math.MathArray[]): Point =>
  reduce(
    arr,
    (acc, pt) =>
      ({
        x: math.add(acc.x, pt[0]),
        y: math.add(acc.y, pt[1]),
      } as Point),
    { x: 0, y: 0 } as Point
  );

/**
 * Calculate the weighted points
 * @param {Point[]} pt1 the points to weight
 * @param {Number[]} weights the weights to apply to the points
 * @returns {math.MathArray[]} the weighted points
 */
const calcWeightedPoints = (pt1: Point[], weights: math.MathNumericType[]) =>
  map(pt1, (pt, i) => {
    const pMatrix = pointToVector(pt);
    return math.multiply(weights[i], pMatrix).toArray();
  });

/**
 * Apply an affine transformation to a point
 * @param {Number} angleDeg the angle in degrees to rotate the point
 * @param {Point[]} pt1 the first set of registration points
 * @param {Point[]} pt2 the second set of registration points
 * @param {Point} pt the point to transform
 * @returns {Point} the transformed point
 * @example
 * const pt1 = [{x: 0, y: 0}, {x: 1, y: 0}, {x: 0, y: 1}];
 * const pt2 = [{x: 0, y: 0}, {x: 0, y: 1}, {x: -1, y: 0}];
 * const pt = {x: 1, y: 1};
 * const result = affineTransform(90, pt1, pt2, pt);
 * // returns {x: -1, y: 1}
 */
const affineTransform = (angleDeg: number, pt1: Point[], pt2: Point[], pt: Point) => {
  // prepare the rotation matrix (shape=2x2)
  const angle = degreesToRadians(angleDeg);
  const sinAngle = Math.sin(angle);
  const cosAngle = Math.cos(angle);
  const rotMat = math.matrix([
    [cosAngle, -sinAngle],
    [sinAngle, cosAngle],
  ]);

  const ptVector = pointToVector(pt);

  // the euclidian distances between pt and each point in pts1 (shape=N)
  const distances = map(pt1, (pFromPt1) => {
    const pointArr = math.subtract(ptVector, pointToVector(pFromPt1));
    const [x, y] = pointArr.toArray();
    return math.pow(math.add(math.pow(x, 2), math.pow(y, 2)), 0.5) as math.MathNumericType;
  });

  // apply softmax to convert the distances to weights (shape=N)
  const averageDistance = average(distances);
  // this value affect how many registration points are chosen to use in the calculations of the
  // transformed points. The lower the number, more registration points will be used, the higher less points
  // will be used.
  const neighborsWeightFactor = 20;
  const normalizedDistance = math.dotDivide<math.MathNumericType[]>(
    math.dotMultiply<math.MathNumericType[]>(distances, -1),
    math.divide(averageDistance, neighborsWeightFactor)
  );
  const expNormalizedDistance = math.map(normalizedDistance, math.exp);
  const weights = math.dotDivide(expNormalizedDistance, math.sum(expNormalizedDistance));

  // compute the pair of average reference points (shape=2)
  const weightedPointArr1 = calcWeightedPoints(pt1, weights);
  const averagePt1 = sumPointsAxis1(weightedPointArr1);

  const weightedPointArr2 = calcWeightedPoints(pt2, weights);
  const averagePt2 = sumPointsAxis1(weightedPointArr2);

  // transform pt according to the average reference points (shape=2)
  const pointMat = math.subtract(ptVector, pointToVector(averagePt1));
  const rotationMultiplication = math.multiply(rotMat, pointMat);
  const result = math.add(rotationMultiplication, math.matrix([averagePt2.x, averagePt2.y]));
  const [x, y] = result.toArray();
  return { x, y } as Point;
};

// Calculate the maximum zoom offset for a given tile size and largest dimension
// This should be the number of zoom levels required to fit the largest dimension
// within the tile size
// i.e. largestDim / (2 ** y) = tileSize -> y = log2(largestDim / tileSize)
export function calculateMaxZoomOffset(largestDim: number, tileSize: number): number {
  return Math.max(Math.ceil(Math.log2(largestDim / tileSize)), 0);
}

// Calculate the maximum zoom level for a given largest dimension
// This should be the number of times we can divide the largest dimension by 2
// before we reach a dimension of 1
// i.e. largestDim / (2 ** y) = 1 -> y = log2(largestDim)
export function calculateMaxZoom(largestDim: number): number {
  return Math.min(24, Math.ceil(Math.log2(largestDim)));
}

// For slide resolution 0.25, 40x magnification is the maximum zoom level
// For slide resolution 0.5, 20x magnification is the maximum zoom level
// For slide resolution 1, 10x magnification is the maximum zoom level
export function getMaxMagnificationFromResolution(resolution: number): number {
  return (1 / resolution) * 10;
}

export function calculateMagnificationFromZoom(zoom: number, maxResolution: number): number {
  const maxMagnification = getMaxMagnificationFromResolution(maxResolution);
  return Math.pow(2, zoom) * maxMagnification;
}

export function calculateZoomFromMagnification(magnification: number, maxResolution: number): number {
  const maxMagnification = getMaxMagnificationFromResolution(maxResolution);
  return Math.log2(magnification / maxMagnification);
}

export default affineTransform;
