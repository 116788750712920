export enum CreatedAt {
  All = 'All',
  Today = 'Today',
  Yesterday = 'Yesterday',
  LastWeek = 'Last Week',
  LastMonth = 'Last Month',
}

export enum Approval {
  All = 'All',
  Approved = 'Approved',
  Disapproved = 'Disapproved',
}
