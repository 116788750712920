export const scannerModels = [
  'Aperio Digital Slide; ',
  'Aperio Format Scanned By KFBIO; ',
  'Aperio Image Library v10.0.44; ',
  'Aperio Image Library v10.0.46; ',
  'Aperio Image Library v10.0.50; ',
  'Aperio Image Library v10.0.51; ',
  'Aperio Image Library v10.2.20; ',
  'Aperio Image Library v10.2.23; ',
  'Aperio Image Library v10.2.24; ',
  'Aperio Image Library v10.2.24; Aperio Image Library v10.2.41\r\n42000x25688 [0,100 40288x25588] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v10.2.24; Aperio Image Library v10.2.41\r\n49000x43243 [0,100 47003x43143] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v10.2.24; Aperio Image Library v10.2.41\r\n57000x37976 [0,100 54677x37876] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v10.2.24; Aperio Image Library vFS90 01\r\n112000x79916 [0,100 107308x79816] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v10.2.24; Aperio Image Library vFS90 01\r\n118000x83046 [0,100 113056x82946] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v10.2.24; Aperio Image Library vFS90 01\r\n122000x91867 [0,100 116889x91767] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v10.2.24; Aperio Image Library vFS90 01\r\n136000x74556 [0,100 130302x74456] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v10.2.41; ',
  'Aperio Image Library v10.2.41; Aperio Image Library v12.0.11   112096x70779 [0,100 107554x70679] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v10.2.41; Aperio Image Library v12.0.11   123008x85352 [0,100 118024x85252] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v10.2.41; Mirax Digital Slide',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.0.50\r\n48960x31311 [0,100 47935x31211] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.0.50\r\n51000x36989 [0,100 49933x36889] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.0.50\r\n53040x37200 [0,100 51930x37100] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.0.50\r\n53040x38885 [0,100 51930x38785] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.0.50\r\n55080x43332 [0,100 53927x43232] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.0.50\r\n59160x26560 [0,100 57922x26460] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.0.50\r\n59160x38960 [0,100 57922x38860] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.0.50\r\n59160x43314 [0,100 57922x43214] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.0.50\r\n59160x44318 [0,100 57922x44218] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.0.50\r\n61200x43740 [0,100 59919x43640] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.0.50\r\n65280x41037 [0,100 63914x40937] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.0.50\r\n67320x43377 [0,100 65911x43277] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.0.50\r\n79560x36374 [0,100 77895x36274] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.0.50\r\n87720x43254 [0,100 85885x43154] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.23\r\n102000x85254 [0,100 98053x85154] (256x256) JPEG/RGB Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.23\r\n107000x75957 [0,100 102859x75857] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.23\r\n115000x88956 [0,100 110549x88856] (256x256) JPEG/RGB Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.23\r\n118000x79417 [0,100 113433x79317] (256x256) JPEG/RGB Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.23\r\n125000x44041 [0,100 120163x43941] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.23\r\n30000x38712 [0,100 28839x38612] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.23\r\n42000x37654 [0,100 40374x37554] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.23\r\n50000x50903 [0,100 48065x50803] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.23\r\n54000x70436 [0,100 51910x70336] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.23\r\n66000x67875 [0,100 63446x67775] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.41\r\n108128x67771 [0,100 103767x67671] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.41\r\n125000x85679 [0,100 119905x85579] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.41\r\n127968x61449 [0,100 122807x61349] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.41\r\n129952x88208 [0,100 124712x88108] (240x240) JPEG/RGB Q=30',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.41\r\n130944x40713 [0,100 125663x40613] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.41\r\n134000x86768 [0,100 128538x86668] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.41\r\n142848x66253 [0,100 137087x66153] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.41\r\n147808x74092 [0,100 141847x73992] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.41\r\n149792x64736 [0,100 143751x64636] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.41\r\n159000x90510 [0,100 152520x90410] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.41\r\n163680x64483 [0,100 157079x64383] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.41\r\n55552x75610 [0,100 53311x75510] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v10.2.41\r\n92256x80161 [0,100 88535x80061] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; Aperio Image Library v11.0.37\r\n104719x71717 (256x256) JPEG/RGB Q=70;Aperio Image Library v10.2.41\r\n109120x71817 [0,100 104719x71717] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.0.37; BioImagene iScan',
  'Aperio Image Library v11.0.37; Mirax Digital Slide',
  'Aperio Image Library v11.0.37; NanoZoomer Digital Pathology Image',
  'Aperio Image Library v11.1.6; ',
  'Aperio Image Library v11.1.6; Aperio Image Library v11.1.6\r\n103000x64527 [0,100 98874x64427] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.6; Aperio Image Library v11.1.6\r\n103000x89910 [0,100 98874x89810] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.6; Aperio Image Library v11.1.6\r\n105000x80601 [0,100 100794x80501] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.6; Aperio Image Library v11.1.6\r\n113000x89604 [0,100 108474x89504] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.6; Aperio Image Library v11.1.6\r\n114000x90604 [0,100 109434x90504] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.6; Aperio Image Library v11.1.6\r\n117000x83045 [0,100 112314x82945] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.6; Aperio Image Library v11.1.6\r\n118000x72991 [0,100 113274x72891] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.6; Aperio Image Library v11.1.6\r\n128000x73436 [0,100 122873x73336] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.6; Aperio Image Library v11.1.6\r\n131000x75178 [0,100 125753x75078] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.6; Aperio Image Library v11.1.6\r\n68000x84827 [0,100 65276x84727] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.6; Aperio Image Library v11.1.6\r\n96000x84172 [0,100 92155x84072] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.6; Aperio Image Library v11.1.6\r\n98000x80387 [0,100 94075x80287] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.0.46\r\n22440x23181 [0,100 21987x23081] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.0.46\r\n57120x24688 [0,100 55968x24588] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.0.46\r\n59160x21355 [0,100 57967x21255] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.0.50\r\n44880x33569 [0,100 43941x33469] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.0.50\r\n44880x36800 [0,100 43941x36700] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.0.50\r\n44880x41165 [0,100 43941x41065] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.0.50\r\n46920x38131 [0,100 45938x38031] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.0.50\r\n48960x42813 [0,100 47935x42713] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.0.50\r\n61200x43072 [0,100 59919x42972] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.0.50\r\n67320x39685 [0,100 65911x39585] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.20\r\n114000x79120 [0,100 109371x79020] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.20\r\n114000x81910 [0,100 109371x81810] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.20\r\n116000x77852 [0,100 111290x77752] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.20\r\n121000x89519 [0,100 116087x89419] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.23\r\n18360x15590 [0,100 17989x15490] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.23\r\n22440x22409 [0,100 21998x22309] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.23\r\n24480x29094 [0,100 23986x28994] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.23\r\n26520x23448 [0,100 25998x23348] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.23\r\n36720x22834 [0,100 35997x22734] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.23\r\n61200x36262 [0,100 59966x36162] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.23\r\n63240x25348 [0,100 61996x25248] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.23\r\n65280x16491 [0,100 63996x16391] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.23\r\n67320x34735 [0,100 65996x34635] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n10160x14437 [0,100 9959x14337] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n12192x12136 [0,100 11951x12036] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n12192x14517 [0,100 11951x14417] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n12192x14926 [0,100 11951x14826] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n12192x17226 [0,100 11951x17126] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n12192x17553 [0,100 11951x17453] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n12192x21494 [0,100 11951x21394] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n12192x21988 [0,100 11951x21888] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n14224x10901 [0,100 13943x10801] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n14224x18371 [0,100 13943x18271] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n14224x20260 [0,100 13943x20160] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n14224x25186 [0,100 13943x25086] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n18288x16889 [0,100 17927x16789] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n38608x26387 [0,100 37847x26287] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n40640x21867 [0,100 39839x21767] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n42672x12670 [0,100 41831x12570] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n44704x24573 [0,100 43823x24473] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n6096x13784 [0,100 5975x13684] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n8128x11074 [0,100 7967x10974] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v10.2.41\r\n8128x14521 [0,100 7967x14421] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v12.0.11 \r\n103168x78636 [0,100 99008x78536] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v12.0.11 \r\n131936x76245 [0,100 126616x76145] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v12.0.11 \r\n132928x81021 [0,100 127568x80921] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v12.0.11 \r\n20400x18745 [0,100 20000x18645] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v12.0.11 \r\n20400x23212 [0,100 20000x23112] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v12.0.11 \r\n24480x25123 [0,100 24000x25023] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v12.0.11 \r\n32640x23713 [0,100 32000x23613] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v12.0.11 \r\n34680x26647 [0,100 34000x26547] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v12.0.11 \r\n68448x71794 [0,100 65688x71694] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library v12.0.11 \r\n92256x81025 [0,100 88536x80925] (240x240) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n101000x81420 [0,0 97105x81270] (256x256) J2K/KDU Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n101000x95716 [0,0 97105x95566] (256x256) J2K/KDU Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n104000x73190 [0,0 99989x73040] (256x256) J2K/KDU Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n105000x79690 [0,100 100720x79590] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n105000x83622 [0,0 100950x83472] (256x256) J2K/KDU Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n32512x13447 [0,100 31872x13347] (240x240) JPEG/RGB Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n34000x26895 [0,100 32614x26795] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n34544x25880 [0,100 33863x25780] (240x240) JPEG/RGB Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n35000x36281 [0,100 33573x36181] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n37000x30720 [0,100 35492x30620] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n38608x38758 [0,100 37848x38658] (240x240) JPEG/RGB Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n42672x22594 [0,100 41831x22494] (240x240) J2K/YUV16 Q=30',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n44704x27891 [0,100 43823x27791] (240x240) J2K/YUV16 Q=30',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n46736x30822 [0,100 45815x30722] (240x240) J2K/YUV16 Q=30',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n46736x43065 [0,100 45816x42965] (240x240) JPEG/RGB Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n48768x37914 [0,100 47807x37814] (240x240) J2K/YUV16 Q=30',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n52832x35950 [0,100 51791x35850] (240x240) J2K/YUV16 Q=30',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n52832x42879 [0,100 51791x42779] (240x240) J2K/YUV16 Q=30',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n52832x45570 [0,100 51791x45470] (240x240) J2K/YUV16 Q=30',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n52832x46383 [0,100 51791x46283] (240x240) J2K/YUV16 Q=30',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n53000x65334 [0,100 50840x65234] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n54864x29993 [0,100 53783x29893] (240x240) J2K/YUV16 Q=30',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n54864x70994 [0,100 53784x70894] (240x240) JPEG/RGB Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n56896x44912 [0,100 55775x44812] (240x240) J2K/YUV16 Q=30',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n56896x46216 [0,100 55775x46116] (240x240) J2K/YUV16 Q=30',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n65024x45551 [0,100 63743x45451] (240x240) J2K/YUV16 Q=30',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n84000x81944 [0,0 80760x81794] (256x256) J2K/KDU Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n87000x68837 [0,0 83645x68687] (256x256) J2K/KDU Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n91000x75767 [0,0 87490x75617] (256x256) J2K/KDU Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n92000x65783 [0,0 88452x65633] (256x256) J2K/KDU Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n93000x69552 [0,0 89413x69402] (256x256) J2K/KDU Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n94000x91518 [0,0 90375x91368] (256x256) J2K/KDU Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n95000x85667 [0,0 91336x85517] (256x256) J2K/KDU Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n95000x87000 [0,0 91336x86850] (256x256) J2K/KDU Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n97000x62904 [0,0 93259x62754] (256x256) J2K/KDU Q=70',
  'Aperio Image Library v11.1.9; Aperio Image Library vFS90 01\r\n98000x85302 [0,0 94220x85152] (256x256) J2K/KDU Q=70',
  'Aperio Image Library v11.1.9; BioImagene iScan',
  'Aperio Image Library v11.1.9; Mirax Digital Slide',
  'Aperio Image Library v11.1.9; Recompressed, arrived on DVD corrupt;Aperio Image Library v11.2.1 \r\n86750x72425 (256x256) JPEG/RGB Q=30;Aperio Image Library vFS90 01\r\n90272x72525 [0,100 86750x72425] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library v12.0.11 \r\n16320x19896 [0,100 16000x19796] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library v12.0.11 \r\n16320x23215 [0,100 16000x23115] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library v12.0.11 \r\n18360x27552 [0,100 18000x27452] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library v12.0.11 \r\n22440x18233 [0,100 22000x18133] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library v12.0.11 \r\n34680x40558 [0,100 34000x40458] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library v12.0.11 \r\n53040x43059 [0,100 51930x42959] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library v12.0.11 \r\n57120x38628 [0,100 56000x38528] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library v12.0.11 \r\n57120x39524 [0,100 55925x39424] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library v12.0.11 \r\n59160x32501 [0,100 58000x32401] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library v12.0.11 \r\n99960x43059 [0,100 97869x42959] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n101184x76736 [0,100 97237x76636] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n102176x64197 [0,100 98190x64097] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n111000x73146 [0,100 106560x73046] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n112096x78441 [0,100 107723x78341] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n112096x80196 [0,100 107723x80096] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n112096x87966 [0,100 107723x87866] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n115000x83009 [0,100 110400x82909] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n115072x86697 [0,100 110583x86597] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n118048x84177 [0,100 113443x84077] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n119000x91600 [0,100 114240x91500] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n122016x84914 [0,100 117256x84814] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n123000x91578 [0,100 118080x91478] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n123008x84406 [0,100 118209x84306] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n125000x71553 [0,100 120000x71453] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n126000x91562 [0,100 120960x91462] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n129000x85465 [0,100 123840x85365] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n132000x89757 [0,100 126720x89657] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n138880x87340 [0,100 133462x87240] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n144000x59795 [0,100 138240x59695] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n149000x60276 [0,100 143040x60176] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n150000x71672 [0,100 144000x71572] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n181000x86963 [0,100 173760x86863] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n186000x52225 [0,100 178560x52125] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n196000x21515 [0,100 188160x21415] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n211000x77684 [0,100 202560x77584] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n22352x22423 [0,100 21915x22323] (256x256) JPEG/RGB Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n32512x41750 [0,100 31877x41650] (256x256) JPEG/RGB Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n34544x30797 [0,100 33869x30697] (256x256) JPEG/RGB Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n36576x32295 [0,100 35862x32195] (256x256) JPEG/RGB Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n42672x24340 [0,100 41839x24240] (256x256) JPEG/RGB Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n48768x39164 [0,100 47816x39064] (256x256) JPEG/RGB Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n48768x45322 [0,100 47816x45222] (256x256) JPEG/RGB Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n52832x30460 [0,100 51800x30360] (256x256) JPEG/RGB Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n52832x42412 [0,100 51800x42312] (256x256) JPEG/RGB Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n54864x38882 [0,100 53793x38782] (256x256) JPEG/RGB Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n54864x41522 [0,100 53793x41422] (256x256) JPEG/RGB Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n63488x54851 [0,100 61011x54751] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n78000x67241 [0,100 74880x67141] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n79000x71288 [0,100 75840x71188] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n80352x58030 [0,100 77217x57930] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n85000x63653 [0,100 81600x63553] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n89000x63888 [0,100 85440x63788] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n89000x67940 [0,100 85440x67840] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n93000x80589 [0,100 89280x80489] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v11.2.1; Aperio Image Library vFS90 01\r\n95000x73991 [0,100 91200x73891] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v12.0.0.1; ',
  'Aperio Image Library v12.0.11; ',
  'Aperio Image Library v12.0.15; ',
  'Aperio Image Library v12.0.15  101600x83546 [0,100 99600x83446] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  103632x37236 [0,100 101592x37136] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  103632x76911 [0,100 101592x76811] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  103632x85008 [0,100 101592x84908] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  103632x92299 [0,100 101592x92199] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  105664x91009 [0,100 103584x90909] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  105664x92630 [0,100 103584x92530] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  107696x81786 [0,100 105576x81686] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  109728x69158 [0,100 107568x69058] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  109728x88266 [0,100 107568x88166] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  109728x89237 [0,100 107568x89137] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  109728x90050 [0,100 107568x89950] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  109728x92641 [0,100 107568x92541] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  109728x93128 [0,100 107568x93028] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  111760x79688 [0,100 109560x79588] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  111760x80177 [0,100 109560x80077] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  111760x89409 [0,100 109560x89309] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  111760x92365 [0,100 109559x92265] (240x240) JPEG/RGB Q=30; ',
  'Aperio Image Library v12.0.15  113792x60104 [0,100 111552x60004] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  113792x70626 [0,100 111552x70526] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  113792x77109 [0,100 111552x77009] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  113792x77755 [0,100 111552x77655] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  113792x82286 [0,100 111552x82186] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  113792x89411 [0,100 111552x89311] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  113792x92365 [0,100 111551x92265] (240x240) JPEG/RGB Q=30; ',
  'Aperio Image Library v12.0.15  113792x92813 [0,100 111552x92713] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  115824x71447 [0,100 113544x71347] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  115824x72579 [0,100 113544x72479] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  115824x85048 [0,100 113544x84948] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  115824x91522 [0,100 113544x91422] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  115824x93145 [0,100 113544x93045] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  115824x93146 [0,100 113544x93046] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  115824x93147 [0,100 113544x93047] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  117856x77442 [0,100 115536x77342] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  117856x82302 [0,100 115536x82202] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  117856x84729 [0,100 115536x84629] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  117856x86834 [0,100 115536x86734] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  117856x87645 [0,100 115536x87545] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  117856x90559 [0,100 115536x90459] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  117856x90723 [0,100 115536x90623] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  117856x92365 [0,100 115535x92265] (240x240) JPEG/RGB Q=30; ',
  'Aperio Image Library v12.0.15  117856x92665 [0,100 115536x92565] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  117856x92829 [0,100 115536x92729] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  117856x92991 [0,100 115536x92891] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  117856x93151 [0,100 115536x93051] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  117856x93152 [0,100 115536x93052] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  117856x93154 [0,100 115536x93054] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  119888x58663 [0,100 117528x58563] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  119888x73561 [0,100 117528x73461] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  119888x76317 [0,100 117528x76217] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  119888x86196 [0,100 117528x86096] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  119888x87166 [0,100 117528x87066] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  119888x89595 [0,100 117528x89495] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  119888x91377 [0,100 117528x91277] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  119888x91863 [0,100 117528x91763] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  119888x92024 [0,100 117528x91924] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  119888x92345 [0,100 117528x92245] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  119888x92365 [0,100 117527x92265] (240x240) JPEG/RGB Q=30; ',
  'Aperio Image Library v12.0.15  119888x92992 [0,100 117528x92892] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  119888x93156 [0,100 117528x93056] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  119888x93159 [0,100 117528x93059] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  121920x76486 [0,100 119520x76386] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  121920x77941 [0,100 119520x77841] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  121920x87980 [0,100 119520x87880] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  121920x90884 [0,100 119519x90784] (240x240) JPEG/RGB Q=30; ',
  'Aperio Image Library v12.0.15  121920x91703 [0,100 119520x91603] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  121920x92030 [0,100 119520x91930] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  121920x92366 [0,100 119519x92266] (240x240) JPEG/RGB Q=30; ',
  'Aperio Image Library v12.0.15  121920x93163 [0,100 119520x93063] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  121920x93164 [0,100 119520x93064] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  123952x89281 [0,100 121512x89181] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  123952x89606 [0,100 121512x89506] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  123952x89608 [0,100 121512x89508] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  123952x91391 [0,100 121512x91291] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  123952x93007 [0,100 121512x92907] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  123952x93168 [0,100 121512x93068] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  123952x93170 [0,100 121512x93070] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  125984x64675 [0,100 123504x64575] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  125984x90582 [0,100 123504x90482] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  125984x92205 [0,100 123504x92105] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  125984x92366 [0,100 123503x92266] (240x240) JPEG/RGB Q=30; ',
  'Aperio Image Library v12.0.15  125984x92525 [0,100 123504x92425] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  125984x92853 [0,100 123504x92753] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  125984x93177 [0,100 123504x93077] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  125984x93178 [0,100 123504x93078] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  125984x93179 [0,100 123504x93079] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  128016x87835 [0,100 125496x87735] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  128016x93183 [0,100 125496x93083] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  128016x93184 [0,100 125496x93084] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  130048x87843 [0,100 127488x87743] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  130048x93024 [0,100 127488x92924] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  130048x93186 [0,100 127488x93086] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  132080x90600 [0,100 129480x90500] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  132080x91734 [0,100 129480x91634] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  132080x93196 [0,100 129480x93096] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  134112x89150 [0,100 131472x89050] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  134112x90121 [0,100 131472x90021] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  134112x93198 [0,100 131472x93098] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  136144x83977 [0,100 133464x83877] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  136144x93205 [0,100 133464x93105] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  138176x93213 [0,100 135456x93113] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  79248x93034 [0,100 77688x92934] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  91440x86752 [0,100 89640x86652] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  91440x91612 [0,100 89640x91512] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  95504x68307 [0,100 93624x68207] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  97536x58759 [0,100 95616x58659] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  97536x85802 [0,100 95616x85702] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.15  99568x83538 [0,100 97608x83438] (240x240) JPEG/RGB Q=70; ',
  'Aperio Image Library v12.0.16; ',
  'Aperio Image Library v12.0.17; Aperio Image Library v12.0.11 \r\n20320x28930 [0,100 19920x28830] (240x240) J2K/KDU Q=70',
  'Aperio Image Library v12.0.17; Aperio Image Library v12.0.11 \r\n207264x97599 [0,100 203183x97499] (240x240) J2K/KDU Q=70',
  'Aperio Image Library v12.0.17; Aperio Image Library v12.0.11 \r\n22352x19884 [0,100 21912x19784] (240x240) J2K/KDU Q=70',
  'Aperio Image Library v12.0.17; Aperio Image Library v12.0.11 \r\n50800x33852 [0,100 49800x33752] (240x240) J2K/KDU Q=70',
  'Aperio Image Library v12.0.17; Aperio Image Library v12.0.11 \r\n56896x47117 [0,100 55776x47017] (240x240) J2K/KDU Q=70',
  'Aperio Image Library v12.0.17; Mirax Digital Slide',
  'Aperio Image Library v12.0.17; NanoZoomer Digital Pathology Image',
  'Aperio Image Library v12.1.3; Aperio Image Library v10.2.23\r\n28560x34760 [0,100 27984x34660] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v12.1.3; Aperio Image Library v12.0.15 \r\n140208x92613 [0,100 137516x92513] (240x240) JPEG/RGB Q=70',
  'Aperio Image Library v12.1.3; Aperio Image Library v12.0.15 \r\n154432x96974 [0,100 151467x96874] (240x240) JPEG/RGB Q=70',
  'Aperio Image Library v12.1.3; Aperio Image Library v12.0.15 \r\n168656x89324 [0,100 165418x89224] (240x240) JPEG/RGB Q=70',
  'Aperio Image Library v12.1.3; Aperio Image Library v12.0.15 \r\n71400x25244 [0,100 69996x25144] (256x256) J2K/YUV16 Q=70',
  'Aperio Image Library v12.1.3; Leica SCN400;Leica SCN ver.1.5.1.10804 2012/05/10 13:29:07;1.5.1.10864',
  'Aperio Image Library v12.1.3; Mirax Digital Slide',
  'Aperio Image Library v12.1.3; NanoZoomer Digital Pathology Image',
  'Aperio Image Library v12.4.0; Aperio Image Library v12.0.15 \r\n12896x15876 [0,100 12376x15776] (256x256) J2K/KDU Q=70',
  'Aperio Image Library v12.4.0; Aperio Image Library v12.0.15 \r\n23808x27379 [0,100 22848x27279] (256x256) J2K/KDU Q=70',
  'Aperio Image Library v12.4.0; Aperio Image Library v12.0.15 \r\n37696x52488 [0,100 36176x52388] (256x256) J2K/KDU Q=70',
  'Aperio Image Library v12.4.0; Versa ver.1.0',
  'Aperio Image Library v8.2.43; ',
  'Aperio Image Library v9.0.22; ',
  'Aperio Image Library v9.1.4; ',
  'Aperio Image Library vFS90 01; ',
  'Aperio Leica Biosystems GT450 DX v1.0.1; ',
  'Aperio Leica Biosystems GT450 v1.0.0; ',
  'Aperio Leica Biosystems GT450 v1.0.1; ',
  'Hamamatsu C13220-21MDEU NDP.toolkit 2.2.2',
  'Hamamatsu C13220 NZAcquire 2.0.10.46',
  'Hamamatsu C9600-12 NDP.scan 2.3.27',
  'Hamamatsu C9600-12 NDP.scan 3.3.0',
  'Hamamatsu C9600-12 NDP.scan 3.4.1',
  'Hamamatsu NanoZoomer NZUtil',
  'iScan HT; C:\\Program Files\\Ventana Medical Systems Inc\\iScanHT\\bif2img.exe  v0.1 17/06/2018 10:33:04 1.0.2.2',
  'iScan HT; C:\\Program Files\\Ventana Medical Systems Inc\\iScanHT\\StitchEncode.exe  v0.1 3/21/2019 10:23:14 AM 1.1.3.0',
  'iScan HT; C:\\Ventana Medical Systems Inc\\iScanHT\\StitchEncode.exe  v0.1 12/2/2014 7:46:16 AM 1.1.1.0',
  'Motic V1.0.0',
  'None; None January, 16 2014 3.4.0.7',
  'Pannoramic 1000',
  'Pannoramic 250',
  'Pannoramic DESK',
  'Pannoramic MIDI',
  'Pannoramic SCAN 150',
  'Philips DP v1.0',
  'VENTANA DP 200; ScanOutputManager 1.0.0.15140 2/1/2018 9:42:10 AM 1.0.0.15140',
  'VENTANA DP 200; ScanOutputManager 1.1.0.15854 11/27/2019 11:6:28 AM 1.1.0.15854',
];
