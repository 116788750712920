import { Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';

interface ModelDetailsRowProps {
  fieldName: string;
  fieldValue: string;
}

export const ModelDetailsRow: React.FC<React.PropsWithChildren<ModelDetailsRowProps>> = ({ fieldName, fieldValue }) => {
  if (isEmpty(fieldValue)) return <></>;

  return (
    <Grid item container spacing={1} alignItems="center">
      <Grid item xs={4}>
        <Typography variant="body2">{fieldName}:</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body2"> {fieldValue}</Typography>
      </Grid>
    </Grid>
  );
};
