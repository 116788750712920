import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import { MultiplexOpacityToggle } from './MultiplexOpacityToggle';

export const MainSettings: React.FC<
  React.PropsWithChildren<{
    markerType: string;
    channelId: string;
    channelName: string;
    selected: boolean;
    viewerIndex: number;
    slideId: string;
  }>
> = ({ markerType, channelId, channelName, selected, viewerIndex, slideId }) => {
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  return (
    <>
      <Grid item container direction="column" md={6} alignItems="start" sx={{ lineHeight: 1 }}>
        <Grid item>
          <Typography variant="caption">
            {!isLoadingStainTypeOptions && markerType ? stainTypeIdToDisplayName(markerType) : 'Loading...'}
          </Typography>
        </Grid>
        <Grid item container justifyContent="start">
          <Grid sx={{ color: '#555' }} item md={2}>
            <Typography sx={{ color: '#555', fontSize: 9 }} variant="caption">
              {channelId}
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: '#555', fontSize: 9 }} variant="caption">
              {channelName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <MultiplexOpacityToggle
        markerType={markerType}
        selected={selected}
        viewerIndex={viewerIndex}
        slideId={slideId}
        channelId={channelId}
      />
    </>
  );
};
