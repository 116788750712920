import { Box, Button, Grid, Toolbar, Typography, useTheme } from '@mui/material';
import SiteHeader from 'components/SiteHeader/SiteHeader';
import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { MainPageAnnotationDrawer } from 'components/AssignmentsList/MainPageAnnotationDrawer';
import { useNavigateBackToDashboard } from './useNavigateBackToDashboard';

export const NotFoundContents = () => {
  const theme = useTheme();
  const [slidesMode] = useQueryParam('slidesMode', BooleanParam);

  const navigateBackToDashboard = useNavigateBackToDashboard();
  return (
    <Grid alignSelf="center">
      <Typography variant="h1" color={theme.palette.primary.main} lineHeight="60px">
        The {slidesMode ? 'slide' : 'case'} you are looking for is not available.
      </Typography>
      <Box mt={5} color={theme.palette.secondary.main}>
        <Typography variant="body1" fontSize={24}>
          Most likely this {slidesMode ? 'slide' : 'case'} is still being processed.
        </Typography>
        <Typography variant="body1" fontSize={24}>
          Please try again shortly, or contact us for any issue.
        </Typography>
      </Box>
      <Grid item container spacing={2} mt={3}>
        <Grid item>
          <Button size="large" color="primary" variant="contained" onClick={navigateBackToDashboard}>
            back to {slidesMode ? 'slide' : 'case'}s list
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="large"
            color="primary"
            variant="contained"
            href="mailto:support@nucleaimd.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact us
          </Button>
        </Grid>
      </Grid>
      <Typography mt={2} variant="body1">
        status code: 404
      </Typography>
    </Grid>
  );
};

export const NotFound: React.FC<
  React.PropsWithChildren<{
    hideHeader?: boolean;
  }>
> = ({ hideHeader }) => {
  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%" justifyContent="center" alignItems="center">
      {!hideHeader && (
        <>
          <SiteHeader />
          <Toolbar />
        </>
      )}
      <NotFoundContents />
      <MainPageAnnotationDrawer />
    </Box>
  );
};

export default NotFound;
