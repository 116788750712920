import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RateReviewRoundedIcon from '@mui/icons-material/RateReviewRounded';

import { Box, Link, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Annotation, AnnotationAssignment, AnnotationWorkingStates } from 'interfaces/annotation';
import { map, sum, values } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';
import AnnotationAssignmentToolbar from './AnnotationAssignmentTableToolbar';

interface AnnotationAssignmentTableProps {
  studyId: string;
  annotationAssignment: AnnotationAssignment;
}

const ANNOTATIONS_PAGE_SIZE = 10;
const ANNOTATIONS_TABLE_HEIGHT = 515; // the height of the table with 10 rows in compact density

const AnnotationAssignmentTable: React.FC<AnnotationAssignmentTableProps> = ({ studyId, annotationAssignment }) => {
  const theme = useTheme();
  const { getLinkToSlidePage } = useNavigationToViewerPage();

  const rows = annotationAssignment?.annotations;

  const dataGridSx = useMemo(
    () => ({
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: theme.palette.mode == 'light' ? theme.palette.grey[200] : theme.palette.grey[900],
        borderRadius: 0,
        borderTop: `1px solid ${theme.palette.mode == 'light' ? 'lightgrey' : 'rgb(81, 81, 81)'}`,
      },
      '.annotating': {
        color: theme.palette.primary.main,
      },
      '.qa': {
        color: theme.palette.info.main,
      },
      '.done': {
        color: theme.palette.success.main,
      },
      backgroundColor: theme.palette.background.paper,
    }),
    [theme]
  );

  const renderSlideIdCell: GridColDef['renderCell'] = useCallback(
    (params) => {
      return (
        <Link
          component={RouterLink}
          color="secondary"
          underline="none"
          to={getLinkToSlidePage({
            slideId: params.value,
            caseStudyId: studyId,
          })}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <OpenInNewIcon fontSize="small" sx={{ mr: 0.5 }} />
          <u>{params.value}</u>
        </Link>
      );
    },
    [getLinkToSlidePage, studyId]
  );

  const columnsWithLink = useMemo(() => {
    return map(columns, (column) => {
      if (column.field === 'slideId') {
        return {
          ...column,
          renderCell: renderSlideIdCell,
        };
      }
      return column;
    });
  }, [renderSlideIdCell]);

  const autoHeight = (rows ? rows.length : 0) < ANNOTATIONS_PAGE_SIZE;

  return (
    <Box sx={{ width: '100%', height: autoHeight ? undefined : ANNOTATIONS_TABLE_HEIGHT }}>
      <DataGrid
        slots={{ toolbar: AnnotationAssignmentToolbar }}
        slotProps={{
          toolbar: annotationAssignment,
          pagination: {
            SelectProps: {
              sx: { width: 'auto' },
            },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: ANNOTATIONS_PAGE_SIZE,
            },
          },
        }}
        pageSizeOptions={[ANNOTATIONS_PAGE_SIZE]}
        getCellClassName={getCellClassName}
        rows={rows}
        columns={columnsWithLink}
        getRowId={getRowId}
        sx={dataGridSx}
        density="compact"
        autoHeight={autoHeight}
      />
    </Box>
  );
};

function getRowId(row: Annotation) {
  return row.annotationId;
}

function getCellClassName(params: { field: string; row: Annotation }) {
  if (params.field === 'workingState') {
    const state: AnnotationWorkingStates = params.row.workingState as AnnotationWorkingStates;
    return state === AnnotationWorkingStates.Annotating
      ? 'annotating'
      : state === AnnotationWorkingStates.QA
      ? 'qa'
      : 'done';
  }
  return '';
}

const renderStatusCell: GridColDef['renderCell'] = (params) => {
  const state: AnnotationWorkingStates = params.row.workingState;
  return (
    <>
      {state === AnnotationWorkingStates.Annotating ? (
        <DescriptionRoundedIcon />
      ) : state === AnnotationWorkingStates.QA ? (
        <RateReviewRoundedIcon />
      ) : (
        <CheckCircleRoundedIcon />
      )}
      <Typography sx={{ ml: 1 }}>{state}</Typography>
    </>
  );
};

const columns: GridColDef[] = [
  { field: 'slideId', headerName: 'Slide ID', width: 350 },
  {
    field: '# of Annotations',
    headerName: '# of Annotations',
    width: 200,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    valueGetter: (params) => sum(values(params.row.annotationSummary)),
  },
  {
    field: 'tagger',
    headerName: 'Assignee',
    width: 200,
    valueGetter: (params) => params.row.tagger?.name,
  },
  {
    field: 'workingState',
    headerName: 'Status',
    width: 200,
    renderCell: renderStatusCell,
  },
];

export default AnnotationAssignmentTable;
