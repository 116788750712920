import Visualization, { VisualizationPreset } from 'interfaces/visualization';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getVisualizationsForCalculateFeatures = (input?: string) => {
  return apiRequestHandlerPromise<Visualization[]>({
    url: `visualizations?${stringify({ input, calculateFeaturesAllowed: true })}`,
    method: 'GET',
  });
};

export const getVisualizationPresets = (stain: string) => {
  return apiRequestHandlerPromise<VisualizationPreset[]>({
    url: `visualization-preset?${stringify({ stain })}`,
    method: 'GET',
  });
};

export const getVisualizationTypeOptions = () => {
  return apiRequestHandlerPromise<Visualization[]>({
    url: `visualization_types`,
    method: 'GET',
  });
};
