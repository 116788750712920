import { Checkbox, FormControlLabel, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getStainTypeFilteredIds } from 'api/stainTypes';
import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import { Study } from 'interfaces/study';
import { first, map } from 'lodash';
import { stringify } from 'qs';
import React from 'react';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';

export const defaultStudySlideViewMode = 'single';

export const GeneralSettings: React.FC<
  React.PropsWithChildren<{
    studyId: Study['id'];
    updatedSettings: Study['settings'];
    setUpdatedSettings: React.Dispatch<React.SetStateAction<Study['settings']>>;
    editable?: boolean;
  }>
> = ({ studyId, updatedSettings, setUpdatedSettings, editable = true }) => {
  const { labId } = useCurrentLabId();
  const encodedParamsForStudy = stringify({ labId, filters: JSON.stringify({ studyId }) });
  const { data: stains, isLoading } = useQuery({
    queryKey: ['slidesStainsTypes', encodedParamsForStudy],
    queryFn: ({ signal }) => getStainTypeFilteredIds(encodedParamsForStudy, signal),
  });
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  const stainsOptions = [
    ...map(
      stains || (updatedSettings.defaultStainingType ? [updatedSettings.defaultStainingType] : []),
      (currStain) => ({
        value: currStain,
        text: stainTypeIdToDisplayName(currStain),
      })
    ),
    {
      value: '',
      text: 'None',
    },
  ];

  const unsetStainingTypeValue = stainsOptions.length === 1 ? first(stainsOptions).value : '';
  const currentValue = updatedSettings.defaultStainingType || unsetStainingTypeValue;

  return (
    <Grid
      sx={{
        p: 2,
      }}
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant="subtitle2">Default Slide Viewing Mode</Typography>
      </Grid>
      <Grid item xs={8}>
        <ToggleButtonGroup
          disabled={!editable}
          size="small"
          color="primary"
          value={updatedSettings.defaultSlideViewMode || defaultStudySlideViewMode}
          exclusive
          onChange={(_, newViewMode) => {
            setUpdatedSettings((prevSettings) => ({
              ...prevSettings,
              defaultSlideViewMode: newViewMode,
            }));
          }}
          aria-label="Slide-Default-View-Mode"
        >
          <ToggleButton value="single">Single</ToggleButton>
          <ToggleButton value="side-by-side">Side-By-Side</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">Default Staining Type</Typography>
      </Grid>
      <Grid item xs={12}>
        <LabelledDropdown
          key={stainsOptions?.length}
          disabled={stainsOptions?.length < 2 || !editable}
          label="Stain Type Selection"
          options={stainsOptions}
          value={currentValue}
          onOptionSelected={(optionValue) => {
            setUpdatedSettings((prevSettings) => ({
              ...prevSettings,
              defaultStainingType: optionValue,
            }));
          }}
          loading={isLoading || isLoadingStainTypeOptions}
          placeholder={currentValue}
        />
      </Grid>

      <Grid item xs={8}>
        <FormControlLabel
          label="Use WebGL Viewer (Beta)"
          control={
            <Checkbox
              disabled={!editable}
              checked={Boolean(updatedSettings.useDeckGL)}
              onChange={(e) =>
                setUpdatedSettings((prevSettings) => ({
                  ...prevSettings,
                  useDeckGL: e.target.checked,
                }))
              }
            />
          }
        />
      </Grid>
    </Grid>
  );
};
