import React from 'react';
import './LabelledTextArea.scss';

interface Props {
  label: string;
  defaultValue?: string;
  placeholder?: string;
  onChange(e: React.ChangeEvent<HTMLTextAreaElement>): any;
  cols?: number;
  rows?: number;
}

const LabelledTextArea: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  label,
  defaultValue,
  placeholder,
  onChange,
  cols,
  rows,
}) => {
  return (
    <div className="labelled-textarea">
      <label className="labelled-textarea-label">{label}</label>
      <textarea
        className="labelled-textarea-input"
        cols={cols || 100}
        rows={rows || 6}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default LabelledTextArea;
