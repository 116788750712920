import { validateCondition } from 'components/atoms/ConditionBuilder/utils';
import { ExternalLabel } from 'interfaces/externalLabel';
import { QualityControlLabel } from 'interfaces/qualityControlLabels';
import { find } from 'lodash';

export const defaultLabel: Omit<QualityControlLabel, 'id'> = {
  filterLabel: '',
  rejectLabel: '',
  attachFilterLabel: false,
  attachRejectLabel: false,
  filterLabelExternal: false,
  rejectLabelExternal: false,
  condition: null,
  flaggedSlideTagIds: [],
  excludeOption: null,
};

export const validateLabel = (label: QualityControlLabel, externalLabels: ExternalLabel[]): [boolean, string] => {
  if (!label) return [false, 'Please fill in all fields'];
  const { filterLabel, filterLabelExternal, rejectLabel, rejectLabelExternal, attachRejectLabel, condition } = label;
  if (!filterLabel || (attachRejectLabel && !rejectLabel)) {
    return [false, 'Please fill in all fields'];
  }

  if (filterLabelExternal && !find(externalLabels, { id: filterLabel })) {
    return [false, 'Filter label must be an external label'];
  }

  if (rejectLabelExternal && !find(externalLabels, { id: rejectLabel })) {
    return [false, 'Reject label must be an external label'];
  }

  if (filterLabel === rejectLabel) {
    return [false, 'Filter and Reject labels must be different'];
  }

  if (!condition) {
    return [false, 'Please add a condition'];
  }

  return validateCondition(condition);
};
