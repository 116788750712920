import useLocalStorage from 'use-local-storage';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';

export const useCasesViewMode = () => {
  const [userSelectedProcedureViewMode, setUserSelectedProcedureViewMode] = useLocalStorage<
    'card' | 'table' | 'gallery'
  >('userSelectedProcedureViewMode', 'card');
  const [casesViewModeParam, setCasesViewModeQueryParamParam] = useQueryParam(
    'casesViewMode',
    withDefault(StringParam, userSelectedProcedureViewMode)
  );

  return {
    casesViewMode: casesViewModeParam as 'card' | 'table' | 'gallery',
    setCasesViewMode: (casesViewMode: 'card' | 'table' | 'gallery') => {
      setUserSelectedProcedureViewMode(casesViewMode);
      setCasesViewModeQueryParamParam(casesViewMode);
    },
    defaultViewMode: userSelectedProcedureViewMode,
  };
};
