import { handleActions } from 'redux-actions';
import { PreferencesActionTypes, PreferencesState, SET_PREFERENCES } from 'redux/actionTypes/preferences';

const initialState: PreferencesState = {
  showNavigator: true,
};

const preferencesReducer = handleActions(
  {
    [SET_PREFERENCES]: (state: PreferencesState, action: PreferencesActionTypes) => ({
      showNavigator: action.payload.showNavigator,
    }),
  },
  initialState
);

export default preferencesReducer;
