import Feed from '@mui/icons-material/Feed';
import { findAvailableForm } from 'components/ReviewPanel/form.util';
import { Permission } from 'interfaces/permissionOption';
import { FormType, ResponseContext } from 'interfaces/reviewForm';
import { filter, map } from 'lodash';
import React, { useMemo } from 'react';
import { usePermissions } from 'utils/usePermissions';
import { useForms } from './useForm';

const availableFormButtons = [
  {
    tab: FormType.ScoreByPathologist,
    label: 'Pathologist Evaluation',
    icon: <Feed />,
  },
] as const;

export const useFormButtons = (
  formContext: ResponseContext,
  {
    enabled = true,
  }: {
    enabled?: boolean;
  } = {}
) => {
  const { data: forms, isSuccess } = useForms(formContext, { enabled });

  const { hasSubPermission, permissions } = usePermissions();

  const permissibleFormButtons = useMemo(
    () => filter(availableFormButtons, (formTab) => hasSubPermission(Permission.FillForm, formTab.tab)),
    [permissions]
  );

  return isSuccess
    ? map(permissibleFormButtons, (formTab) => ({
        ...formTab,
        form: findAvailableForm(forms, formTab.tab),
      }))
    : [];
};
