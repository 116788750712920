import { ChartOptions } from 'chart.js';
import { cohortToDataset, formatNumber, getHorizontalScaleOptions, getVerticalScaleOptions } from '../chart.util';

export const cohortToScatterDataset = cohortToDataset('scatter');

export const scatterChartOptions = (horizontalKeyName: string, verticalKeyName: string): ChartOptions<'scatter'> => ({
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        font: {
          size: 9,
        },
        boxHeight: 10,
        boxWidth: 10,
      },
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          const label = context.dataset?.label || '';
          const x = context.parsed.x;
          const y = context.parsed.y;
          return `${label} (${formatNumber(x)}, ${formatNumber(y)})`;
        },
      },
    },
  },
  scales: {
    x: getHorizontalScaleOptions(horizontalKeyName),
    y: getVerticalScaleOptions(verticalKeyName),
  },
});
