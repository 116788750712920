import { replace } from 'lodash';
import { DziTileSource, TileSource } from 'openseadragon';

export interface DziMetadata {
  Image: {
    xmlns: string;
    Url: string | null;
    Format: string;
    DisplayRect: string;
    Overlap: number;
    TileSize: number;
    Size: {
      Height: number;
      Width: number;
    };
  };
  displayRects: any[];
  fileFormat: string;
  height: number;
  maxLevel: number | null;
  minLevel: number | null;
  tileOverlap: number;
  tilesUrl: string | null;
  width: number;
  tileSize: number;
}

export interface DziChannel {
  source: TileSource;
  baseUrl: string;
  metadata: DziMetadata;
  label?: string;
  isRgb?: boolean;
}

export const dziChannelFromManifestUrl = async (dziManifestUrl: string, signal: AbortSignal): Promise<DziChannel> => {
  dziManifestUrl = replace(dziManifestUrl, 'imageapi.nucleaimd.com', 'image.nucleai.cloud');
  const dziText = await fetch(dziManifestUrl, signal ? { signal } : undefined).then((response) => response.text());
  if (signal?.aborted) {
    throw new Error('Aborted');
  }
  const slideDzi: any = new window.DOMParser().parseFromString(dziText, 'text/xml');
  const metadata = DziTileSource.prototype.configure(slideDzi) as DziMetadata;
  // @ts-ignore
  const source = new DziTileSource(metadata);
  const baseUrl = replace(dziManifestUrl, /.dzi$/, '_files');
  return { source, metadata, baseUrl };
};
