import { useQueryClient } from '@tanstack/react-query';
import { createCellPanel, deleteCellRulePanel, updateCellPanel, updateCellRule } from 'api/cellRules';
import { CellRulePanelWithRules, globalStudyId } from 'interfaces/cellRule';
import { filter, map, reject } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { cellRuleQueryKey } from './useCellRules';

export const useUpdateCellRuleMutation = () => {
  const queryClient = useQueryClient();

  return useMutationWithErrorSnackbar({
    mutationDescription: 'Update Cell Rule',
    showRetryButton: false,
    mutationFn: updateCellRule,
    onMutate: (variables) => {
      const key = [...cellRuleQueryKey, variables.panelId, variables.studyId ?? globalStudyId];

      queryClient.setQueryData(key, (oldData: CellRulePanelWithRules) => {
        const newCellRules = reject(oldData.rules, {
          cellTypeId: variables.updateModel.cellTypeId,
          stainTypeId: variables.updateModel.stainTypeId,
        });

        newCellRules.push(variables.updateModel);

        return {
          ...oldData,
          rules: newCellRules,
        };
      });
    },
    onSuccess() {
      enqueueSnackbar('Cell Rule Updated', {
        variant: 'success',
      });
    },
    onSettled: (data, error, variables) => {
      const key = [...cellRuleQueryKey, variables.panelId, variables.studyId ?? globalStudyId];

      queryClient.invalidateQueries(key);
    },
  });
};

export const useCreateCellRulePanelMutation = () => {
  const queryClient = useQueryClient();

  return useMutationWithErrorSnackbar({
    mutationDescription: 'Create Cell Rule Panel',
    showRetryButton: false,
    mutationFn: createCellPanel,
    onSuccess(data, variables) {
      const key = ['cellPanels', variables.studyId];
      queryClient.setQueryData(key, (oldData: CellRulePanelWithRules[]) => {
        return [...oldData, data];
      });
      enqueueSnackbar('Cell Rule Panel Created', {
        variant: 'success',
      });
    },
    onSettled: (data, error, variables) => {
      const key = ['cellPanels', variables.studyId];

      queryClient.invalidateQueries(key);
    },
  });
};

export const useUpdateCellRulePanelMutation = () => {
  const queryClient = useQueryClient();

  return useMutationWithErrorSnackbar({
    mutationDescription: 'Update Cell Rule Panel',
    showRetryButton: false,
    mutationFn: updateCellPanel,
    onSuccess: (data, variables) => {
      const key = ['cellPanels', variables.studyId];
      queryClient.setQueryData(key, (oldData: CellRulePanelWithRules[]) => {
        return map(oldData, (panel) => {
          return panel.id === variables.panelId ? data : panel;
        });
      });
      enqueueSnackbar('Cell Rule Panel Updated', {
        variant: 'success',
      });
    },
    onSettled: (data, error, variables) => {
      const cellPanelsKey = ['cellPanels', variables?.studyId];
      const panelRulesKey = [...cellRuleQueryKey, variables?.panelId, variables?.studyId];

      queryClient.invalidateQueries(cellPanelsKey);
      queryClient.invalidateQueries(panelRulesKey);
    },
  });
};

export const useDeleteCellRulePanelMutation = () => {
  const queryClient = useQueryClient();

  return useMutationWithErrorSnackbar({
    mutationDescription: 'Delete Cell Rule Panel',
    showRetryButton: false,
    mutationFn: deleteCellRulePanel,
    onSuccess: (data, variables) => {
      const key = ['cellPanels', variables?.studyId];
      queryClient.setQueryData(key, (oldData: CellRulePanelWithRules[]) => {
        return filter(oldData, (panel) => panel.id !== variables?.panelId);
      });
      const panelRulesKey = [...cellRuleQueryKey, variables?.panelId, variables?.studyId];
      if (data?.rules) {
        queryClient.setQueryData(panelRulesKey, data.rules);
      }
      enqueueSnackbar('Cell Rule Panel Deleted', {
        variant: 'success',
      });
    },
    onSettled: (data, error, variables) => {
      const cellPanelsKey = ['cellPanels', variables?.studyId];

      const panelRulesKey = [...cellRuleQueryKey, variables?.panelId, variables?.studyId];

      queryClient.invalidateQueries(cellPanelsKey);
      queryClient.invalidateQueries(panelRulesKey);
    },
  });
};
