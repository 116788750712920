/**
 * TODO: make the channel texture assignment filo rather than deterministic
 * to support a more efficient use of the texture atlas (minimum number of textures)
 */
import { COORDINATE_SYSTEM, Color, Layer, LayerContext, LayerExtension, picking, project32 } from '@deck.gl/core/typed';
import GL from '@luma.gl/constants';
import { Geometry, Model, ProgramManager } from '@luma.gl/engine';
import { Texture2D } from '@luma.gl/webgl';
import {
  castArray,
  every,
  filter,
  forEach,
  fromPairs,
  includes,
  isEmpty,
  isEqual,
  keys,
  map,
  some,
  times,
  uniq,
} from 'lodash';

import {
  MAX_CHANNELS,
  MAX_TEXTURES,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/constants';
import {
  BoundingBox,
  MultiScaleImageLayerProps,
  PhotometricInterpretation,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/types';
import { MultiScaleImageData, MultiScaleImageIndex } from '../multiScaleImageLayer/utils';
import { getDtypeValues } from '../utils';
import { populateChannelAtlasesWithImageData } from './channelAtlas';
import {
  ChannelAtlasAssignment,
  getChannelAtlasAssignment,
  initializeSingleChannelAtlas,
  isRectangularBounds,
  shouldLoadChannel,
} from './helpers';
import createMesh, { Bounds } from './mesh';
import channels from './shader-modules/channel-intensity';
import { getRenderingAttrs } from './utils';

const defaultProps = {
  redOnlyOnCPU: { type: 'boolean', value: false, compare: true },
  pickable: { type: 'boolean', value: true, compare: true },
  coordinateSystem: COORDINATE_SYSTEM.DEFAULT,
  interpolation: { type: 'number', value: GL.NEAREST, compare: true },
  numChannels: { type: 'number', value: MAX_CHANNELS, compare: true }, // No using layersVisible.length; since some shader functions will be recreated based on array sizes,
};

const processStr = `fs:DECKGL_PROCESS_INTENSITY(inout float intensity, vec2 contrastLimits, float gamma, float opacity)`;

const UNASSIGNED_CHANNEL_INDEX = -1;
const UNDEFINED_CHANNEL_OFFSET = -1;
export interface XRLayerProps<S extends string[] = []> extends MultiScaleImageLayerProps<S> {
  redOnlyOnCPU?: boolean; // Whether to preprocess the data to only use the red channel or let the shader do it.
  tile?: { index: MultiScaleImageIndex; bbox: BoundingBox; isVisible?: boolean; isSelected?: boolean };
  bounds: [number, number, number, number];
  channelData: MultiScaleImageData;
  domain?: [min: number, max: number]; // Override for the possible max/min values (i.e something different than 65535 for uint16/'<u2').
  interpolation?: number; // The TEXTURE_MIN_FILTER and TEXTURE_MAG_FILTER for WebGL rendering (see https://developer.mozilla.org/en-US/docs/Web/API/WebGLRenderingContext/texParameter) - default is GL.NEAREST
  transparentColor?: Color;
  transparentColorInHook?: Color;
  photometricInterpretation: PhotometricInterpretation;
  width: number;
  height: number;
  debug?: boolean;
}

export interface XRLayerState {
  numInstances?: number;
  channelAtlases?: Texture2D[];
  numChannels?: number;
  channelWidth?: number;
  channelHeight?: number;
  maxTextures?: number;
  model?: Model;
  paddedContrastMins?: Float32Array;
  paddedContrastMaxes?: Float32Array;
  paddedGammaValues?: Float32Array;
  paddedChannelOpacities?: Float32Array;
  channelLoadedFlags?: boolean[];
  channelsWithData?: number[];
  channelAtlasIndices?: number[];
  channelAtlasXOffsets?: number[];
  channelAtlasYOffsets?: number[];
  mesh?: ReturnType<typeof createMesh>;
}

class XRLayer<S extends string[] = []> extends Layer<XRLayerProps<S>> {
  state: XRLayerState;

  /**
   * This function replaces `usampler` with `sampler` if the data is not an unsigned integer
   * and adds a standard ramp function default for DECKGL_PROCESS_INTENSITY.
   */
  getShaders() {
    const { maxTextures } = this.state;
    const { interpolation, redOnlyOnCPU, maxChannels } = this.props;
    const { shaderModule, sampler } = getRenderingAttrs(this.context.gl, {
      interpolation,
      redOnlyOnCPU,
      maxChannels,
      maxTextures,
      is16Bit: this.props.dtype === 'Uint16',
    });
    const extensionDefinesDeckglProcessIntensity = this._isHookDefinedByExtensions('fs:DECKGL_PROCESS_INTENSITY');
    const newChannelsModule = { ...channels, inject: {} as any };
    if (!extensionDefinesDeckglProcessIntensity) {
      newChannelsModule.inject['fs:DECKGL_PROCESS_INTENSITY'] = `
        intensity = apply_contrast_limits_gamma_and_opacity(intensity, contrastLimits, gamma, opacity);
      `;
    }
    return super.getShaders({
      ...shaderModule,
      defines: { SAMPLER_TYPE: sampler },
      modules: [project32, picking, newChannelsModule],
    });
  }

  _isHookDefinedByExtensions(hookName: string) {
    const { extensions } = this.props;
    return some(extensions, (e: LayerExtension) => {
      // @ts-ignore
      const shaders = e.getShaders();
      const { inject = {}, modules = [] } = shaders;
      const definesInjection = inject[hookName];
      const moduleDefinesInjection = some(modules, (m) => m?.inject[hookName]);
      return definesInjection || moduleDefinesInjection;
    });
  }

  calculatePixelChannelIntensities(attribute: any) {
    const { value } = attribute;
    const { numChannels = MAX_CHANNELS } = this.state;

    for (let i = 0; i < numChannels; i++) {
      // TODO: Calculate the intensity for each channel
      value[i] = 0;
    }
  }

  /**
   * This function initializes the internal state.
   */
  initializeState() {
    if (this.props.debug) {
      console.debug(
        `Initializing XRLayer at index ${JSON.stringify(this.props?.tile?.index || {})} with props:`,
        this.props
      );
    }
    const { gl } = this.context;
    // This tells WebGL how to read row data from the texture.  For example, the default here is 4 (i.e for RGBA, one byte per channel) so
    // each row of data is expected to be a multiple of 4.  This setting (i.e 1) allows us to have non-multiple-of-4 row sizes.  For example, for 2 byte (16 bit data),
    // we could use 2 as the value and it would still work, but 1 also works fine (and is more flexible for 8 bit - 1 byte - textures as well).
    // https://stackoverflow.com/questions/42789896/webgl-error-arraybuffer-not-big-enough-for-request-in-case-of-gl-luminance
    gl.pixelStorei(GL.UNPACK_ALIGNMENT, 1);
    gl.pixelStorei(GL.PACK_ALIGNMENT, 1);
    const attributeManager = this.getAttributeManager();
    const noAlloc = true;
    attributeManager.add({
      pixelChannelIntensities: {
        size: MAX_CHANNELS,
        type: GL.UNSIGNED_INT,
        update: this.calculatePixelChannelIntensities,
      },
      positions: {
        size: 3,
        type: GL.DOUBLE,
        fp64: this.use64bitPositions(),
        update: (attribute) => this.state?.mesh?.positions && (attribute.value = this.state?.mesh?.positions),
        noAlloc,
      },
      indices: {
        size: 1,
        isIndexed: true,
        update: (attribute) => this.state.mesh && (attribute.value = this.state.mesh.indices),
        noAlloc,
      },
      texCoords: {
        size: 2,
        update: (attribute) => this.state.mesh && (attribute.value = this.state.mesh.texCoords),
        noAlloc,
      },
    });
    const maxTextures = Math.min(gl.getParameter(gl.MAX_TEXTURE_SIZE), MAX_TEXTURES);
    this.setState({
      numInstances: 1,
      maxTextures,
      // Initialize buffers to avoid re-allocating them on every draw call
      paddedChannelOpacities: new Float32Array(MAX_CHANNELS),
      paddedContrastMins: new Float32Array(MAX_CHANNELS),
      paddedContrastMaxes: new Float32Array(MAX_CHANNELS),
      paddedGammaValues: new Float32Array(MAX_CHANNELS),
    });
    const programManager: ProgramManager & {
      _hookFunctions: string[];
    } = ProgramManager.getDefaultProgramManager(gl);

    // Only initialize shader hook functions _once globally_
    // Since the program manager is shared across all layers, but many layers
    // might be created, this solves the performance issue of always adding new
    // hook functions.
    // See https://github.com/kylebarron/deck.gl-raster/blob/2eb91626f0836558f0be4cd201ea18980d7f7f2d/src/deckgl/raster-layer/raster-layer.js#L21-L40
    const mutateStr = `fs:DECKGL_MUTATE_COLOR(inout vec4 rgba, float intensities[${this.props.maxChannels}], vec2 vTexCoord)`;

    if (!includes(programManager._hookFunctions, mutateStr)) {
      programManager.addShaderHook(mutateStr, undefined);
    }
    if (!includes(programManager._hookFunctions, processStr)) {
      programManager.addShaderHook(processStr, undefined);
    }
  }

  /**
   * This function finalizes state by clearing all textures from the WebGL context
   */
  finalizeState(context: LayerContext) {
    if (this.props.debug) {
      console.debug(
        `Finalizing XRLayer at index ${JSON.stringify(this.props?.tile?.index || {})} with props:`,
        this.props
      );
    }
    super.finalizeState(context);
    forEach(this.state.channelAtlases, (atlas) => atlas?.delete?.());
    const numChannels = this.state.numChannels || 0;
    this.setState({
      channelLoadedFlags: times(numChannels, () => false),
      channelAtlasIndices: times(numChannels, () => UNASSIGNED_CHANNEL_INDEX),
      channelAtlasXOffsets: times(numChannels, () => UNDEFINED_CHANNEL_OFFSET),
      channelAtlasYOffsets: times(numChannels, () => UNDEFINED_CHANNEL_OFFSET),
    });
  }

  /**
   * This function updates state by retriggering model creation (shader compilation and attribute binding)
   * and loading any textures that need be loading.
   */
  updateState({
    props,
    oldProps,
    changeFlags,
    ...rest
  }: {
    props: XRLayerProps<S>;
    oldProps: XRLayerProps<S>;
    changeFlags: any;
    [key: string]: any;
  }) {
    if (this.props.debug) {
      console.debug(`Updating XRLayer at index ${JSON.stringify(this.props?.tile?.index || {})} with props:`, {
        props,
        oldProps,
        changeFlags,
        ...rest,
      });
    }
    // @ts-ignore
    super.updateState({ props, oldProps, changeFlags, ...rest });

    const interpolationChanged = props.interpolation !== oldProps.interpolation;

    // setup model first
    if (changeFlags.extensionsChanged || interpolationChanged) {
      const attributeManager = this.getAttributeManager()!;
      if ((this.state.model as any)?.destroy) {
        (this.state.model as any)?.destroy();
      } else {
        this.state.model?.delete?.();
      }
      const { gl } = this.context;
      this.state.model = this._getModel(gl);
      attributeManager.invalidateAll();
    }

    const boundsChanged = !isEqual(props.bounds, oldProps.bounds);
    const oldMesh = this.state.mesh;
    if (this.state.model && (!oldMesh || boundsChanged)) {
      const attributeManager = this.getAttributeManager()!;
      const mesh = this._createMesh();
      this.state.model.setVertexCount(mesh.vertexCount);
      forEach(keys(mesh), (key: keyof typeof mesh) => {
        if (oldMesh && oldMesh[key] !== mesh[key]) {
          attributeManager.invalidate(key);
        }
      });
      this.state.mesh = mesh;
    }

    const oldNumChannels = Math.min(MAX_CHANNELS, oldProps?.channelData?.data?.length || 0);
    const newNumChannels = Math.min(MAX_CHANNELS, props?.channelData?.data?.length || 0);
    const numChannelsChanged = oldNumChannels !== newNumChannels;

    if (
      props.contrastLimits !== oldProps.contrastLimits ||
      props.layersVisible !== oldProps.layersVisible ||
      props.layerOpacities !== oldProps.layerOpacities ||
      props.gammaValues !== oldProps.gammaValues ||
      numChannelsChanged ||
      props.domain !== oldProps.domain
    ) {
      const maxContrastValue = props.domain?.[1] || getDtypeValues(props.dtype).max;
      const paddedContrastMins = this.state.paddedContrastMins || new Float32Array(MAX_CHANNELS);
      const paddedContrastMaxes = this.state.paddedContrastMaxes || new Float32Array(MAX_CHANNELS);
      const paddedChannelOpacities = this.state.paddedChannelOpacities || new Float32Array(MAX_CHANNELS);
      const paddedGammaValues = this.state.paddedGammaValues || new Float32Array(MAX_CHANNELS);
      for (let i = 0; i < newNumChannels; i++) {
        paddedContrastMins[i] = props.contrastLimits?.[i]?.[0] ?? maxContrastValue;
        paddedContrastMaxes[i] = props.contrastLimits?.[i]?.[1] ?? maxContrastValue;
        paddedChannelOpacities[i] = castArray(props.layerOpacities)?.[i] ?? 0;
        paddedGammaValues[i] = castArray(props.gammaValues)?.[i] ?? 1;
      }
      if (this.props.debug) {
        console.debug(
          `XRLayer at index ${JSON.stringify(this.props?.tile?.index || {})} updating contrast and gamma values`,
          {
            paddedContrastMins,
            paddedContrastMaxes,
            paddedChannelOpacities,
            paddedGammaValues,
          }
        );
      }
      this.setState({
        paddedContrastMins,
        paddedContrastMaxes,
        paddedChannelOpacities,
        paddedGammaValues,
      });
    }

    const hasData = !isEmpty(props?.channelData?.data);

    if ((props.visible || props.tile?.isVisible) && hasData) {
      const shouldLoadChannels = map(props.channelData.data, (channelImage, channelIndex) =>
        shouldLoadChannel({
          channelImage,
          channelIndex,
          layersVisible: castArray(props.layersVisible),
          layerOpacities: castArray(props.layerOpacities),
        })
      );
      const tileSizeChanged =
        oldProps.channelData && (props.width !== oldProps.width || props.height !== oldProps.height);
      if (boundsChanged || interpolationChanged || tileSizeChanged) {
        if (this.props.debug) {
          console.debug(
            `XRLayer at index ${JSON.stringify(this.props?.tile?.index || {})} reinitializing channel atlases`,
            {
              boundsChanged,
              interpolationChanged,
              tileSizeChanged,
            }
          );
        }
        this.loadChannelsAsTextureSubImages({
          channelData: props.channelData,
          shouldLoadChannels,
          numChannels: newNumChannels,
          width: props.width,
          height: props.height,
          channelAssignments: getChannelAtlasAssignment({
            channelData: props.channelData,
            numChannels: newNumChannels,
            shouldLoadChannels,
            maxTextures: this.state.maxTextures,
          }),
          reInitializeChannelAtlases: true,
        });
      } else {
        const channelAssignments = getChannelAtlasAssignment({
          channelData: props.channelData,
          numChannels: newNumChannels,
          shouldLoadChannels,
          maxTextures: this.state.maxTextures,
          previousAssignments: times(newNumChannels, (channelIndex) =>
            (this.state.channelAtlasIndices?.[channelIndex] ?? UNASSIGNED_CHANNEL_INDEX) !== UNASSIGNED_CHANNEL_INDEX &&
            (this.state.channelAtlasXOffsets?.[channelIndex] ?? UNDEFINED_CHANNEL_OFFSET) !==
              UNDEFINED_CHANNEL_OFFSET &&
            (this.state.channelAtlasYOffsets?.[channelIndex] ?? UNDEFINED_CHANNEL_OFFSET) !== UNDEFINED_CHANNEL_OFFSET
              ? ({
                  atlasIndex: this.state.channelAtlasIndices?.[channelIndex] ?? UNASSIGNED_CHANNEL_INDEX,
                  x: this.state.channelAtlasXOffsets?.[channelIndex] ?? UNDEFINED_CHANNEL_OFFSET,
                  y: this.state.channelAtlasYOffsets?.[channelIndex] ?? UNDEFINED_CHANNEL_OFFSET,
                } as ChannelAtlasAssignment)
              : null
          ),
        });
        const isChannelAssignmentUpToDate = map(channelAssignments, (assignment, channelIndex) =>
          Boolean(assignment && this.state.channelLoadedFlags?.[channelIndex])
        );
        const newDataToLoad =
          numChannelsChanged ||
          some(
            props.channelData?.data,
            (channelImage, channelIndex) =>
              shouldLoadChannels[channelIndex] &&
              Boolean(channelAssignments[channelIndex]) &&
              // If the channel is already loaded, don't load it again
              !this.state.channelLoadedFlags?.[channelIndex]
          );

        if (newDataToLoad) {
          if (this.props.debug) {
            console.debug(
              `XRLayer at index ${JSON.stringify(this.props?.tile?.index || {})} loading new channel data`,
              {
                shouldLoadChannels,
                isChannelAssignmentUpToDate,
                channelAssignments,
              }
            );
          }
          this.loadChannelsAsTextureSubImages({
            channelData: props.channelData,
            numChannels: newNumChannels,
            width: props.width,
            height: props.height,
            isChannelAssignmentUpToDate,
            channelAssignments,
            shouldLoadChannels,
          });
        } else {
          if (this.props.debug) {
            console.debug(`XRLayer at index ${JSON.stringify(this.props?.tile?.index || {})} has no new data to load`, {
              shouldLoadChannels,
              isChannelAssignmentUpToDate,
              channelAssignments,
              numChannelsChanged,
              'this.state.channelLoadedFlags': this.state.channelLoadedFlags,
              'props.channelData?.data': props.channelData?.data,
            });
          }
        }
      }
    } else {
      if (this.props.debug) {
        console.debug(
          `XRLayer at index ${JSON.stringify(this.props?.tile?.index || {})} did not load new channel data`,
          {
            hasData,
            visible: props.visible,
            tileVisible: props.tile?.isVisible,
            tile: props.tile,
            channelData: props.channelData,
          }
        );
      }
    }
  }

  protected _createMesh() {
    const { bounds } = this.props;

    /*
      (minX0, maxY3) ---- (maxX2, maxY3)
             |                  |
             |                  |
             |                  |
      (minX0, minY1) ---- (maxX2, minY1)
   */
    const normalizedBounds: Bounds = isRectangularBounds(bounds)
      ? [
          [bounds[0], bounds[1]],
          [bounds[0], bounds[3]],
          [bounds[2], bounds[3]],
          [bounds[2], bounds[1]],
        ]
      : (bounds as Bounds);

    // const maxResolution = (this.props.layerSource as any)._layerSources?.[0]?.metadata?.maxResolution;
    return createMesh(normalizedBounds, this.context.viewport.resolution);
  }

  protected _getModel(gl: WebGLRenderingContext): Model {
    if (!gl) {
      return null;
    }

    /*
      0,0 --- 1,0
       |       |
      0,1 --- 1,1
    */
    return new Model(gl, {
      ...this.getShaders(),
      id: this.props.id,
      geometry: new Geometry({ drawMode: GL.TRIANGLES, vertexCount: 6 }),
      isInstanced: false,
    });
  }

  /**
   * This function runs the shaders and draws to the canvas
   */
  draw({ uniforms }: { uniforms: Record<string, any> }) {
    const {
      channelAtlases,
      numChannels,
      channelWidth,
      channelHeight,
      model,
      paddedContrastMins,
      paddedContrastMaxes,
      paddedChannelOpacities,
      paddedGammaValues,
      channelAtlasIndices,
      channelAtlasXOffsets,
      channelAtlasYOffsets,
    } = this.state;
    if (
      (this.props.visible || this.props.tile?.isVisible) &&
      !isEmpty(channelAtlases) &&
      model &&
      paddedContrastMins &&
      paddedContrastMaxes &&
      paddedChannelOpacities &&
      paddedGammaValues &&
      channelAtlasIndices &&
      channelAtlasXOffsets &&
      channelAtlasYOffsets
    ) {
      if (
        some(
          channelAtlasIndices,
          (atlasIndex) => atlasIndex !== UNASSIGNED_CHANNEL_INDEX && !channelAtlases[atlasIndex]
        )
      ) {
        throw new Error(
          `Channel atlas index is not ${UNASSIGNED_CHANNEL_INDEX} but no atlas found. Available atlases: ${map(
            channelAtlases,
            (atlas, index) => `${index}: ${Boolean(atlas)}`
          )}`
        );
      }

      model
        .setUniforms(uniforms)
        .setUniforms({
          channelOpacities: paddedChannelOpacities,
          gammaValues: paddedGammaValues,
          contrastMins: paddedContrastMins,
          contrastMaxes: paddedContrastMaxes,
          numChannels,
          channelWidth,
          channelHeight,
          channelAtlasIndices,
          channelAtlasXOffsets,
          channelAtlasYOffsets,
          ...fromPairs(map(channelAtlases, (atlas, i) => [`channelsAtlas${i}`, atlas])),
        })
        .draw();
    }
  }

  /**
   * This function creates an initial texture atlas for the data
   */
  initializeChannelAtlases(
    atlasIndicesWithChannels: number[],
    width: number,
    height: number,
    numChannels: number,
    channelAtlases?: Texture2D[]
  ): Texture2D[] {
    const { maxTextures } = this.state;
    const { interpolation, redOnlyOnCPU } = this.props;
    const attrs = getRenderingAttrs(this.context.gl, {
      interpolation,
      redOnlyOnCPU,
      maxChannels: numChannels,
      maxTextures,
      is16Bit: this.props.dtype === 'Uint16',
    });
    const channelsPerAtlas = Math.ceil(numChannels / maxTextures);
    return times(
      maxTextures,
      (atlasIndex) =>
        // Previously initialized
        channelAtlases?.[atlasIndex] ||
        // Or we have channels to load
        (includes(atlasIndicesWithChannels, atlasIndex)
          ? initializeSingleChannelAtlas(this.context.gl, width, height, channelsPerAtlas, attrs)
          : null)
    );
  }

  /**
   * This function creates textures from the data
   */
  async loadChannelsAsTextureSubImages({
    channelData,
    shouldLoadChannels,
    numChannels,
    channelAssignments,
    isChannelAssignmentUpToDate = times(numChannels, () => false),
    width,
    height,
    reInitializeChannelAtlases = false,
  }: {
    channelData: MultiScaleImageData;
    shouldLoadChannels: boolean[];
    numChannels: number;
    channelAssignments: ChannelAtlasAssignment[];
    isChannelAssignmentUpToDate?: boolean[];
    width: number;
    height: number;
    reInitializeChannelAtlases?: boolean;
  }) {
    if (!some(shouldLoadChannels) || (isChannelAssignmentUpToDate && every(isChannelAssignmentUpToDate))) {
      if (this.props.debug) {
        console.debug(`XRLayer at index ${JSON.stringify(this.props?.tile?.index || {})} has no channel to load`, {
          shouldLoadChannels,
          isChannelAssignmentUpToDate,
        });
      }
      return;
    }
    const atlasIndicesWithChannels = uniq(filter(map(channelAssignments, 'atlasIndex'), (n) => !isNaN(Number(n))));
    const channelAtlases = this.initializeChannelAtlases(
      atlasIndicesWithChannels,
      width,
      height,
      numChannels,
      reInitializeChannelAtlases ? null : this.state.channelAtlases
    );

    const { interpolation, redOnlyOnCPU } = this.props;

    const channelAtlasParams = {
      channelAtlases,
      channelAssignments,
      atlasChannelWidth: width,
      atlasChannelHeight: height,
      perChannelData: channelData.data,
      redOnlyOnCPU,
      numChannels,
      shouldLoadChannels,
      isChannelAssignmentUpToDate,
      interpolation,
      isDecodedPng: channelData.isDecodedPng,
    };

    populateChannelAtlasesWithImageData(channelAtlasParams);

    const previousChannelAtlases = this.state.channelAtlases;
    if (this.props.debug) {
      console.debug(`XRLayer at index ${JSON.stringify(this.props?.tile?.index || {})} loaded channel atlases`, {
        channelAtlases,
        channelAssignments,
        atlasIndicesWithChannels,
        width,
        height,
        numChannels,
      });
    }
    this.setState({
      ...this.state,
      channelAtlases,
      channelWidth: width,
      channelHeight: height,
      numChannels,
      channelLoadedFlags: times(numChannels, (channelIndex) => Boolean(channelAssignments[channelIndex])),
      channelAtlasIndices: times(
        numChannels,
        (channelIndex) => channelAssignments[channelIndex]?.atlasIndex ?? UNASSIGNED_CHANNEL_INDEX
      ),
      channelAtlasXOffsets: times(
        numChannels,
        (channelIndex) => channelAssignments[channelIndex]?.x ?? UNDEFINED_CHANNEL_OFFSET
      ),
      channelAtlasYOffsets: times(
        numChannels,
        (channelIndex) => channelAssignments[channelIndex]?.y ?? UNDEFINED_CHANNEL_OFFSET
      ),
    });
    if (reInitializeChannelAtlases) {
      forEach(previousChannelAtlases, (atlas) => atlas?.delete?.());
    }
  }
}

XRLayer.layerName = 'XRLayer';
XRLayer.defaultProps = defaultProps;
export default XRLayer;
