import Grid from '@mui/material/Grid';
import { RoundedButton } from 'components/atoms/Buttons/MainButton';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import CasesOverviewButton from 'components/CasesOverview/CasesOverviewButton';
import AdvancedSearchButton from 'components/SearchFilters/AdvancedSearch/AdvancedSearchButton';
import FilterChipList from 'components/SearchFilters/FilterChipList';
import ProcedureFilter from 'components/SearchFilters/MainFilters';
import { Permission } from 'interfaces/permissionOption';
import { Study } from 'interfaces/study';
import { every, isEmpty, isNumber, omit, values } from 'lodash';
import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { exportQcResultsFiltered } from 'utils/exports';
import { useFormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { allFiltersHasValue } from 'utils/helpers';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import useSearchFiltersFromQueryParams from '../../SearchFilters/hooks/useSearchFiltersFromQueryParams';
import { useCasesViewMode } from './useCasesViewMode';

export const FiltersAndExport: React.FC<
  React.PropsWithChildren<{
    totalCases: number;
    qcLabelConfig?: Study['qcLabelsConfig'];
    specificStudySelected?: boolean;
    hasLabelsConfigured?: boolean;
    setIsSaveCohortModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }>
> = ({ totalCases, setIsSaveCohortModalOpen, qcLabelConfig, specificStudySelected, hasLabelsConfigured }) => {
  const { hasPermission } = usePermissions();

  const showApprovedFilter = hasPermission(Permission.ApproveCases);

  const { queryParams, setQueryParams, encodedFilters } = useEncodedFilters();
  const [advancedSearchDrawerOpen] = useQueryParam('advancedSearchDrawerOpen', BooleanParam);

  const formatBracketsOptions = useFormatBracketsOptions(false);
  const { searchFiltersFromQueryParams, featuresFromQueryParams } = useSearchFiltersFromQueryParams(false);

  const { bulkEditMode } = useTableEditingContext();
  const { casesViewMode } = useCasesViewMode();

  const withinEditCards = bulkEditMode && casesViewMode !== 'table';

  const onResetFilters = () => {
    setQueryParams({
      filters: { studyId: queryParams.filters?.studyId },
      features: null,
      clinicalData: null,
      queryId: null,
      searchTerm: null,
    });
  };

  const { studyId: filteredStudyId = undefined, ...filtersCopy } = {
    ...queryParams.filters,
    ...queryParams.features,
    ...queryParams.clinicalData,
    ...(queryParams.searchTerm ? { searchTerm: queryParams.searchTerm } : {}),
  };

  // TODO: Do we need this function? It's not used anywhere
  const downloadResults = () => {
    exportQcResultsFiltered({
      context: formatBracketsOptions,
      encodedFilters,
    });
  };

  const filtersForSavingCohort = omit(filtersCopy, ['cohortIds']);

  const hasFilters = allFiltersHasValue(filtersCopy) || (filteredStudyId && filteredStudyId !== 0);

  const shouldAllowSaveCohort =
    !every(values(filtersForSavingCohort), (value: any) => !isNumber(value) && isEmpty(value)) && totalCases > 0;

  const hasSomeNonMainFilters = !isEmpty(searchFiltersFromQueryParams) || !isEmpty(featuresFromQueryParams);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid container item xs={12} direction="row" justifyContent="space-between" alignItems={'start'} wrap="wrap">
        {!advancedSearchDrawerOpen && (
          <Grid item sx={{ display: 'flex', justifyContent: 'start' }} mr={1}>
            <AdvancedSearchButton text="Filter Cases" isIconButton filled={hasFilters} />
          </Grid>
        )}
        <Grid item xs={true}>
          <ProcedureFilter showApprovedFilter={showApprovedFilter} isDrawer={false} />
        </Grid>
        <Grid item sx={{ display: 'flex', justifyContent: 'end' }} ml={1}>
          <CasesOverviewButton disabled={!specificStudySelected || withinEditCards} />
        </Grid>
      </Grid>
      {(hasSomeNonMainFilters || shouldAllowSaveCohort) && (
        <Grid container item justifyContent="space-between" spacing={1}>
          <Grid item>
            <FilterChipList
              allFilters={searchFiltersFromQueryParams}
              allFeatures={featuresFromQueryParams}
              qcLabelConfig={qcLabelConfig}
            />
          </Grid>
          <Grid item container direction="row" spacing={1} justifyContent={'flex-end'} xs={3}>
            {hasSomeNonMainFilters && (
              <Grid item>
                <RoundedButton variant="outlined" color="secondary" onClick={onResetFilters}>
                  Clear
                </RoundedButton>
              </Grid>
            )}
            {shouldAllowSaveCohort && (
              <Grid item>
                <RoundedButton variant="contained" onClick={() => setIsSaveCohortModalOpen(true)}>
                  Save Search
                </RoundedButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
