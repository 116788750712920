import { Color, CompositeLayer, LayersList, UpdateParameters } from '@deck.gl/core/typed';

import { MultiScaleImageLayerProps } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/types';

export interface BackgroundLayerProps<S extends string[]> extends MultiScaleImageLayerProps<S> {
  useNativeBitmaps?: boolean;
  layersVisible: boolean[] | boolean;
  contrastLimits: [begin: number, end: number][];
  layerOpacities: number[] | number;
  transparentColor: Color;
  colors: Color[];
  tileSize: number;
  debug?: boolean;
  data: any;
}

/**
 * A layer that renders the background of the multi-scale image - i.e. the most zoomed out 'tile'.
 */
export class BackgroundLayer<S extends string[]> extends CompositeLayer<BackgroundLayerProps<S>> {
  static layerName = 'BackgroundLayer';

  updateState(params: UpdateParameters<CompositeLayer<BackgroundLayerProps<S>>>): void {
    super.updateState(params);

    // If the tile size or zoom has changed, we need to update the background 'tile'
    if (
      !this.state.tile ||
      params.props.tileSize !== params.oldProps.tileSize ||
      params.props.minZoom !== params.oldProps.minZoom
    ) {
      this.setState({
        tile: {
          index: { x: 0, y: 0, z: params.props.minZoom ?? 0 },
          bbox: [
            0,
            0,
            params.props.tileSize * 2 ** -(params.props.minZoom ?? 0),
            params.props.tileSize * 2 ** -(params.props.minZoom ?? 0),
          ],
          isVisible: true,
          isSelected: true,
        },
      });
    }
  }

  renderLayers(): LayersList {
    const layerSource = this.props.layerSource;

    if (!layerSource || !this.props.data || !this.state.tile) {
      return null;
    }

    return this.props.renderSubLayers({
      ...this.props,
      data: this.props.data as any,
      tile: this.state.tile,
      // @ts-ignore
      isBackground: true,
      _offset: 0,
    }) as LayersList;
  }
}
