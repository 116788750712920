import { ChartKeyType } from 'components/FeaturesDashboard/chart.util';
import { ControlledChartOptions } from 'components/FeaturesDashboard/ControlledChart';
import { flatMap, uniq } from 'lodash';
import { BasePreset } from './basePreset';

export interface OverviewPreset extends BasePreset {
  updatedAt?: string;
  preset: Record<number, ControlledChartOptions>;
  studyId: string;
}

export const getFeaturesInPresetCharts = (preset: Record<number, ControlledChartOptions>): string[] | undefined => {
  if (!preset) {
    return undefined;
  }
  return uniq(
    flatMap(preset, (chartOptions: ControlledChartOptions) => {
      const chartFeatureKeys: string[] = [];
      if (chartOptions.horizontalKey?.type === ChartKeyType.Numerical) {
        chartFeatureKeys.push(chartOptions.horizontalKey.name);
      }
      if (chartOptions.verticalKey?.type === ChartKeyType.Numerical) {
        chartFeatureKeys.push(chartOptions.verticalKey.name);
      }
      if (chartOptions.splittingKey?.type === ChartKeyType.Numerical) {
        chartFeatureKeys.push(chartOptions.splittingKey.name);
      }
      return chartFeatureKeys;
    })
  );
};
