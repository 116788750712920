import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import ControlledGalleryViewer from './ControlledGalleryViewer';
import { useGallerySlides } from './useGallerySlides';

export const GalleryViewer: React.FC<
  React.PropsWithChildren<{
    totalCases: number;
    totalSlides: number;
  }>
> = ({ totalCases, totalSlides }) => {
  const [, setShowGallery] = useQueryParam('showGallery', BooleanParam);
  const handleClose = () => setShowGallery(false);
  const [fullScreenActive, setFullScreenActive] = React.useState(false);

  const {
    changeImageIndex,
    isLoadingCasesInPage,
    slideIndexInCurrentPage,
    currentImageIndex,
    currentCase,
    currentSlide,
    slideImages,
    currentSlideIndex,
    disableLeftNav,
    disableRightNav,
  } = useGallerySlides({ fullScreenActive, totalCases, totalSlides });

  return (
    <ControlledGalleryViewer
      changeImageIndex={changeImageIndex}
      isLoading={isLoadingCasesInPage && slideIndexInCurrentPage < 0}
      currentImageIndex={currentImageIndex}
      currentCase={currentCase}
      currentSlide={currentSlide}
      slideImages={slideImages}
      currentSlideIndex={currentSlideIndex}
      disableLeftNav={disableLeftNav}
      disableRightNav={disableRightNav}
      fullScreenActive={fullScreenActive}
      handleClose={handleClose}
      setFullScreenActive={setFullScreenActive}
      totalSlides={totalSlides}
    />
  );
};
