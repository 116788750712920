import { Grid, Tooltip, Typography, TypographyProps, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import React, { ReactNode } from 'react';
import { useClipboard } from 'use-clipboard-copy';

interface Props {
  label: string;
  value?: ReactNode;
  typographyVariant?: TypographyProps['variant'];
  suppressCopy?: boolean;
}

const CaseDataEntry: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  label,
  value,
  typographyVariant = 'body2',
  suppressCopy,
}) => {
  const theme = useTheme();

  const clipboard = useClipboard({ copiedTimeout: 1000 });

  const copyEnabled = !suppressCopy && !isNil(value);

  return (
    <>
      <Grid item xs={3}>
        <Typography variant={typographyVariant} component="span" fontWeight={theme.typography.fontWeightBold}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={2} onClick={copyEnabled ? () => clipboard.copy(value) : undefined}>
        <Tooltip title={copyEnabled ? (clipboard.copied ? 'Copied!' : `Copy ${label}`) : undefined} placement="top">
          <Typography variant={typographyVariant} component="span">
            {value ?? 'N/A'}
          </Typography>
        </Tooltip>
      </Grid>
    </>
  );
};

export default CaseDataEntry;
