import { IconButton, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import React from 'react';

export const StyledCard = styled(Card, { shouldForwardProp: (prop) => prop !== 'editMode' })<{
  editMode?: boolean;
}>(({ theme, editMode }) => ({
  padding: '30px',
  cursor: editMode ? 'default' : 'pointer',
  transition: '0.1s background-color linear',
  marginBottom: '20px',
  color: theme.palette.text.primary,

  'a:link': {
    color: theme.palette.text.primary,
  },
  ...(!editMode && {
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
    },
  }),

  '&.deleted': {
    opacity: 0,
    transform: 'translateX(-2000px);',
    transition: 'transform 0.8s ease, opacity 0.8s',
  },
}));

export const TypeIconBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50px',
  height: '50px',
  borderRadius: '50%',

  img: {
    width: '24px',
    height: '24px',
  },
}));

export const CardDataBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid container flexDirection="column" sx={{ width: 'initial', mr: 3 }}>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export const SaveIconButton = styled(IconButton)(({ theme }) => ({
  '&.active': {
    color: theme.palette.primary.main,
  },
}));
