import { Role } from 'interfaces/roles';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getRoles = (labId: string) => {
  return apiRequestHandlerPromise<Role[]>({
    url: `users/roles?${stringify({ lab_id: labId })}`,
    method: 'GET',
  });
};

export const createRole = ({ role, labId }: { role: Omit<Role, 'id'>; labId?: string }) => {
  return apiRequestHandlerPromise<Role>({
    url: `users/roles?${stringify({ lab_id: labId })}`,
    method: 'POST',
    data: JSON.stringify(role),
  });
};

export const updateRole = ({ role: { id, ...role }, labId }: { role: Role; labId?: string }) => {
  return apiRequestHandlerPromise<Role>({
    url: `users/roles/${id}?${stringify({ lab_id: labId })}`,
    method: 'POST',
    data: JSON.stringify(role),
  });
};

export const deleteRole = ({ id, labId }: { id: string; labId?: string }) => {
  return apiRequestHandlerPromise<Role>({
    url: `users/roles/${id}?${stringify({ lab_id: labId })}`,
    method: 'DELETE',
  });
};
