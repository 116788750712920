import Button, { ButtonProps } from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { isEmpty } from 'lodash';
import React, { ReactElement, useState } from 'react';

export interface ConfirmationModalProps {
  onClose?: DialogProps['onClose'];
  onConfirm: () => void;
  onCancel: () => void;
  ariaHideApp?: boolean;
  title: string;
  text?: React.ReactNode;
  content?: React.ReactNode;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
}

const ConfirmationModal = ({
  title,
  onConfirm,
  onCancel,
  onClose,
  text,
  content,
  confirmButtonProps = {},
  cancelButtonProps = {},
}: ConfirmationModalProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const onClickConfirm = () => {
    setIsLoading(true);
    onConfirm();
  };
  return (
    <Dialog
      open={true}
      onClose={onClose || onCancel}
      onClick={(e) => e.stopPropagation()}
      aria-labelledby="responsive-dialog-title"
      data-testid="confirmation-modal"
      data-cy="confirmation-modal"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {!isEmpty(text) && <DialogContentText>{text}</DialogContentText>}
        {content}
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="cancel-button"
          data-cy="confirmation-modal-cancel-button"
          disableElevation
          onClick={onCancel}
          variant="text"
          disabled={isLoading}
          {...(cancelButtonProps || {})}
        >
          Cancel
        </Button>
        <Button
          data-testid="confirm-button"
          data-cy="confirmation-modal-confirm-button"
          onClick={onClickConfirm}
          disableElevation
          variant="contained"
          disabled={isLoading}
          {...(confirmButtonProps || {})}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
