import { Typography } from '@mui/material';
import { FilterKey } from 'interfaces/cohort';
import { Study } from 'interfaces/study';
import { isEmpty, map } from 'lodash';
import React, { FunctionComponent } from 'react';
import SingleInferredFeatureFilter from './SingleInferredFeatureFilter';

interface Props {
  study?: Study;
  onMainFilterChange: (filterKey: FilterKey, value: any) => void;
  advancedInputs: Record<string, any>;
}

const InferredFeaturesFilters: FunctionComponent<React.PropsWithChildren<Props>> = ({
  study,
  onMainFilterChange,
  advancedInputs,
}) => {
  if (!study) {
    return <Typography variant="body2">No study selected</Typography>;
  }

  if (!study.inferredFeaturesConfig || isEmpty(study.inferredFeaturesConfig)) {
    return <Typography variant="body2">No inferred features configured</Typography>;
  }

  const { inferredFeaturesConfig } = study;

  return (
    <>
      {map(inferredFeaturesConfig, (config) => (
        <SingleInferredFeatureFilter
          key={config.id}
          inferredFeatureConfig={config}
          setSelectedValueId={(id: string) => {
            if (id) {
              onMainFilterChange('inferredFeatureValueId', id);
              onMainFilterChange('inferredFeatureId', config.id);
            } else {
              onMainFilterChange('inferredFeatureValueId', undefined);
              onMainFilterChange('inferredFeatureId', undefined);
            }
          }}
          selectedValueId={advancedInputs['inferredFeatureValueId']}
        />
      ))}
    </>
  );
};

export default InferredFeaturesFilters;
