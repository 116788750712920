import { useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';

interface Props {
  disabled: boolean;
  savedValue: string;
  onSave: (newValue: string) => void;
  onEditModeChange: (state: boolean) => void;
  placeholder?: string;
}

const CardContentEditable: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  disabled,
  savedValue,
  onSave,
  onEditModeChange,
  placeholder,
}: Props) => {
  const theme = useTheme();
  const editCanceled = useRef(false);
  const [mouseOverContent, setMouseOverContent] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentValue, setCurrentValue] = useState(savedValue);

  useEffect(() => {
    setCurrentValue(savedValue);
  }, [savedValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!editMode) {
      // If the edit mode was not active, do nothing
      console.warn('handleBlur called when editMode is false');
      return;
    }
    const valueToSave = e.target.innerText;

    setEditMode(false);

    if (editCanceled.current || valueToSave === savedValue || valueToSave === placeholder) {
      editCanceled.current = false;
      return;
    }

    onSave(valueToSave);
  };

  const cancelEdit = () => {
    setCurrentValue(savedValue);
    // The current value is not yet updated when entering the handleBlur function, so a ref is needed
    editCanceled.current = true;

    onEditModeChange(false);
    (document.activeElement as HTMLInputElement).blur();
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      cancelEdit();
    } else if (event.key === 'Enter') {
      event.preventDefault();
      onEditModeChange(false);
      (document.activeElement as HTMLInputElement).blur();
    }
  };

  const contentEditableStyle = {
    width: 'fit-content',
    minWidth: 16,
    padding: '0 2px',
    borderStyle: 'dashed',
    outline: 'none',
    backgroundColor: editMode ? theme.palette.grey[200] : 'transparent',
    margin: (mouseOverContent || editMode) && !disabled ? 0 : 1,
    borderWidth: (mouseOverContent || editMode) && !disabled ? 1 : 0,
    cursor: mouseOverContent && !disabled ? 'text' : 'pointer',
  };

  return (
    <>
      <ContentEditable
        style={contentEditableStyle}
        onClick={(e) => {
          if (!disabled) {
            e.stopPropagation();
            setEditMode(true);
            onEditModeChange(true);
          }
        }}
        html={currentValue || (!editMode && placeholder) || ''}
        disabled={disabled}
        onChange={handleChange}
        onMouseEnter={() => setMouseOverContent(true)}
        onMouseLeave={() => setMouseOverContent(false)}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
      />
    </>
  );
};

export default CardContentEditable;
