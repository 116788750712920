import { Slider as SlideMui, SliderProps } from '@mui/material';
import React from 'react';

interface Props extends SliderProps {
  muted?: boolean;
  onValueChange: (value: number | number[]) => void;
  range?: boolean;
  alwaysShowValue?: boolean;
}

const Slider: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  value = 50,
  muted = false,
  disabled,
  onValueChange,
  range = false,
  max = 100,
  step = 1,
  alwaysShowValue,
  ...rest
}) => {
  const calculatedValue = alwaysShowValue || (!disabled && !muted) ? value : range ? [0, 255] : 0;

  const handleChange = (event: Event, newValue: number | number[]) => {
    onValueChange(newValue);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <SlideMui
        valueLabelDisplay="auto"
        disableSwap
        max={max}
        sx={{ display: 'flex' }}
        disabled={disabled}
        size="small"
        value={calculatedValue}
        onChange={handleChange}
        step={step}
        {...rest}
      />
    </div>
  );
};

export default Slider;
