import { TileJSONLayer } from '@loaders.gl/mvt/dist/lib/parse-tilejson';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { compact, every, flatMap, forEach, includes, isEmpty, keyBy, keys, lowerCase, map, uniq } from 'lodash';
import React, { useEffect, useMemo } from 'react';

import { defaultLayerColors } from 'components/theme/theme';
import { useGeoJSONFile } from 'utils/useGeoJSONFile';
import usePrevious from 'utils/usePrevious';
import { GroupedLayersVisualControls } from '../../../GroupedLayersVisualControls';
import {
  LayerVisualizationChange,
  LayerVisualizationSettings,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
  useGetLayerSettingsFromUrl,
} from '../../../slidesVisualizationAndConfiguration';

export const computeDefaultGeoJSONTestSettings = (geoJSONUrl: string, layers: string[]) => {
  const layerSettingsList: LayerVisualizationSettings[] = flatMap(layers, (layer, layerIndex) => {
    return [
      {
        id: `${geoJSONUrl}-cells-${layer}`,
        color: defaultLayerColors[+layerIndex % defaultLayerColors.length],
        opacity: 100,
        show: false,
        select: false,
      },
      {
        id: `${geoJSONUrl}-heatmap-${layer}`,
        color: defaultLayerColors[+layerIndex % defaultLayerColors.length],
        opacity: 100,
        show: false,
        select: false,
      },
    ];
  });

  return keyBy(layerSettingsList, 'id');
};

export const SingleGeoJSONTestControl: React.FC<{
  slideId: string;
  viewerIndex: number;
  geoJSONUrl: string;
  stainTypeId: string;
  filterText: string;
}> = ({ slideId, viewerIndex, geoJSONUrl, stainTypeId, filterText }) => {
  useSignals();
  const { data: parsedGeoJSON } = useGeoJSONFile(geoJSONUrl);
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
  const geoJsonLayers: string[] = useMemo(() => {
    if (!parsedGeoJSON) return [];
    return uniq(compact(map(parsedGeoJSON.features, 'properties.class_name')));
  }, [JSON.stringify(parsedGeoJSON?.features)]);

  const getLayerSettingsFromUrl = useGetLayerSettingsFromUrl();
  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  const previousSlideParams = usePrevious({ slideId, viewerIndex });
  useEffect(() => {
    if (previousSlideParams?.slideId === slideId && previousSlideParams?.viewerIndex === viewerIndex) {
      return;
    }
    if (!viewerSlideLayerVisualizationSettings) {
      console.warn(`Invalid viewerIndex: ${viewerIndex}`);
      return;
    }
    const previousSettings = viewerSlideLayerVisualizationSettings.value;
    const changes: LayerVisualizationChange[] = [];
    if (
      slideId &&
      !isEmpty(geoJsonLayers) &&
      // We don't have any settings for all the layers
      !every(geoJsonLayers, (geoJSONLayer: TileJSONLayer) =>
        includes(keys(previousSettings?.[slideId]), geoJSONLayer.name)
      )
    ) {
      const newGeoJSONSettings = computeDefaultGeoJSONTestSettings(geoJSONUrl, geoJsonLayers);
      forEach(newGeoJSONSettings, (newLayerSettings, layerSettingsKey) => {
        const settingsFromUrl = getLayerSettingsFromUrl({
          layerUrlKey: layerSettingsKey,
          stainTypeId,
          viewerIndex,
        });
        changes.push({
          layerId: layerSettingsKey,
          newSettings: { ...newLayerSettings, ...settingsFromUrl },
        });
      });
    }
    applyChangesToSlideLayerVisualizationSettings({
      slideId,
      viewerIndex,
      changes,
      skipUrlUpdate: true,
      changeFlow: `GeoJSONTestControl-initial-${geoJSONUrl}`,
    });
  }, [viewerIndex, slideId, geoJSONUrl, geoJsonLayers, viewerSlideLayerVisualizationSettings, getLayerSettingsFromUrl]);

  const groupedFilterLayers = useMemo(() => {
    const layerIncludesFilterText = (geoJSONLayer: string) => {
      if (filterText === '') return true;

      return includes(lowerCase(geoJSONLayer), lowerCase(filterText));
    };

    const filteredLayers =
      filterText !== ''
        ? geoJsonLayers?.filter((geoJSONLayer) => {
            if (layerIncludesFilterText(geoJSONLayer)) return geoJSONLayer;
          })
        : geoJsonLayers;
    return {
      [`${geoJSONUrl}-cells`]: filteredLayers,
      [`${geoJSONUrl}-heatmap`]: filteredLayers,
    };
  }, [filterText, geoJsonLayers, geoJSONUrl]);

  return (
    <GroupedLayersVisualControls
      viewerIndex={viewerIndex}
      slideId={slideId}
      groupedLayers={groupedFilterLayers}
      stainTypeId={stainTypeId}
    />
  );
};

export const GeoJSONTestControl: React.FC<{
  geoJSONUrls: string[];
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  textSearch: string;
}> = ({ geoJSONUrls, slideId, viewerIndex, stainTypeId, textSearch }) => {
  const [expandAccordion, setExpandAccordion] = React.useState(true);

  return (
    <Accordion square expanded={expandAccordion} onChange={() => setExpandAccordion(!expandAccordion)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={12}>
            <Typography variant="h4">GeoJSON Tile / Heatmap Tests (Beta)</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 1 }}>
        {map(geoJSONUrls, (geoJSONUrl, urlIdx) => (
          <React.Fragment key={`${urlIdx}-${geoJSONUrl}`}>
            <Grid item>
              <SingleGeoJSONTestControl
                geoJSONUrl={geoJSONUrl}
                slideId={slideId}
                viewerIndex={viewerIndex}
                stainTypeId={stainTypeId}
                filterText={textSearch}
              />
            </Grid>
            <Divider sx={{ borderBottomWidth: 3 }} />
          </React.Fragment>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
