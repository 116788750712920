export const SLIDE_IDENTIFIER_HEADER_OPTIONS = ['Slide ID', 'File Name'];
export const MANDATORY_HEADERS = ['Case ID', 'Stain Type', 'Cancer Type'];
export const METASTASIS_HEADER = 'Metastasis (Y/N)';
export const POSITIVE_CONTROL_HEADER = 'Positive Control on Slide (Y/N)';
export const NEGATIVE_CONTROL_HEADER = 'Negative Control on Slide (Y/N)';

export const MANIFEST_HEADERS = [
  ...MANDATORY_HEADERS,
  ...SLIDE_IDENTIFIER_HEADER_OPTIONS,
  'Biopsy Site',
  'Cancer Subtype',
  'Biopsy Type',
  METASTASIS_HEADER,
  POSITIVE_CONTROL_HEADER,
  NEGATIVE_CONTROL_HEADER,
];
export const LOWER_CASE_SLIDE_IDENTIFIER_HEADER_OPTIONS = SLIDE_IDENTIFIER_HEADER_OPTIONS.map((header) =>
  header.toLowerCase()
);
export const LOWER_CASE_MANDATORY_HEADERS = MANDATORY_HEADERS.map((header) => header.toLowerCase());
export const LOWER_CASE_MANIFEST_HEADERS = MANIFEST_HEADERS.map((header) => header.toLowerCase());

export const BOOLEAN_OPTIONS = ['y', 'n', 'yes', 'no'];
