import { Grid, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { type Dayjs } from 'dayjs';
import { DateRangeField } from 'interfaces/genericFields';
import React from 'react';
import { HeaderCellEditorProps } from '.';

const HeaderDateRangeInput = <R, V, Context extends {} = {}>(
  props: HeaderCellEditorProps<R, V, Context, DateRangeField> // DateRangeField>
) => {
  const { onChange, value, field } = props;
  const { isFullDate, views = ['day', 'month', 'year'] } = field;
  const startDate = new Date(value?.start?.toString() || '');
  const endDate = new Date(value?.end?.toString() || '');

  const inputFormat = isFullDate ? 'DD/MM/YYYY' : 'YYYY';
  const queryFormat = isFullDate ? 'YYYY-MM-DD' : 'YYYY';
  const openTo = isFullDate ? 'day' : 'year';

  const handleStartDateChange = (date: Dayjs | null) => {
    const start = date.format(queryFormat) || '';
    onChange({ start, end: value?.end || '' });
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    const end = date.format(queryFormat) || '';
    onChange({ start: value?.start || '', end });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={1} alignItems="center" justifyContent="space-between" flexWrap="nowrap">
        <Grid item flexGrow={2}>
          <DesktopDatePicker
            inputFormat={inputFormat}
            value={startDate > endDate ? endDate : startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => <TextField fullWidth {...params} size="small" error={false} />}
            views={views}
            disableFuture
            openTo={openTo}
            mask={isFullDate ? '__/__/____' : '____'}
          />
        </Grid>
        <Grid item>
          <Typography variant="body1">
            <strong>-</strong>
          </Typography>
        </Grid>
        <Grid item flexGrow={2}>
          <DesktopDatePicker
            inputFormat={inputFormat}
            value={endDate < startDate ? startDate : endDate}
            onChange={handleEndDateChange}
            renderInput={(params) => <TextField fullWidth {...params} size="small" error={false} />}
            views={views}
            disableFuture
            openTo={openTo}
            mask={isFullDate ? '__/__/____' : '____'}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default HeaderDateRangeInput;
