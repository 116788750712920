import { find } from 'lodash';
import { useCallback } from 'react';
import { useStainTypeOptions } from 'utils/queryHooks/uiConstantsHooks';

export const useStainTypeIdToIndex = () => {
  const { stainTypeOptions, isLoadingStainTypeOptions } = useStainTypeOptions();
  const stainTypeIdToIndex = useCallback(
    (stainTypeId: string) => find(stainTypeOptions, { id: stainTypeId })?.index,
    [stainTypeOptions]
  );
  return { stainTypeIdToIndex, isLoadingStainTypeOptions };
};
