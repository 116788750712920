import { CardContent, CardHeader, Typography } from '@mui/material';
import { cohortToSlides } from 'components/FeaturesDashboard/chart.util';
import { pieChartOptionsForSmallChart } from 'components/FeaturesDashboard/charts/pie.util';
import { borderColorPalette, colorPalette } from 'components/FeaturesDashboard/chartsColorPallete';
import Loader from 'components/Loader';
import { CohortWithSelectedFeatures } from 'interfaces/cohort_old';
import { partition } from 'lodash';
import pattern from 'patternomaly';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { isSlideExcluded, isSlideFlagged } from 'utils/qcLabels';
import { CardWithBorder } from '..';

interface Props {
  cohort: CohortWithSelectedFeatures;
  isLoading: boolean;
}

const dataSetStatic = {
  backgroundColor: [
    colorPalette[0],
    pattern.draw('diagonal-right-left', colorPalette[0], borderColorPalette[0], 5),
    colorPalette[5],
  ],
  borderColor: [borderColorPalette[0], borderColorPalette[0], borderColorPalette[5]],
  borderWidth: 1,
};

const labels = ['Usable', 'Flagged', 'Excluded'];

const CohortSlideUsableChart: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ cohort, isLoading }) => {
  const slides = cohortToSlides(cohort);

  const [unusableSlides, usableSlides] = partition(slides, isSlideExcluded);
  const [flaggedSlides, nonFlaggedSlides] = partition(usableSlides, isSlideFlagged);

  const dataSet = {
    labels,
    datasets: [
      {
        data: [nonFlaggedSlides.length, flaggedSlides.length, unusableSlides.length],
        ...dataSetStatic,
      },
    ],
  };

  return (
    <CardWithBorder
      elevation={0}
      sx={{
        position: 'relative',
      }}
    >
      <CardHeader title={<Typography color="black">Slides quality</Typography>}></CardHeader>
      <CardContent
        sx={{
          flexGrow: 1,
        }}
      >
        {isLoading ? <Loader /> : <Doughnut data={dataSet} options={pieChartOptionsForSmallChart()}></Doughnut>}
      </CardContent>
    </CardWithBorder>
  );
};

export default CohortSlideUsableChart;
