import { CellType } from 'interfaces/cellType';
import { last, map, split } from 'lodash';
import { useMemo } from 'react';
import useTaxonomy, { cellTaxonomy } from './taxonomy/useTaxonomy';

export const useCellTypeOptions = () => {
  const query = useTaxonomy(cellTaxonomy);

  const mappedCellTypes = useMemo(() => {
    if (!query.data) return [];
    return map(
      query.data,
      (taxonomy): CellType => ({
        shortId: last(split(taxonomy.path, '.')),
        id: taxonomy.path,
        displayName: taxonomy.name,
        selectable: !taxonomy.isAbstract,
      })
    );
  }, [query.data]);

  return { ...query, data: mappedCellTypes };
};
