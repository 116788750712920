import { load } from '@loaders.gl/core';
import { ImageLoader } from '@loaders.gl/images';
import GL from '@luma.gl/constants';
import { signal } from '@preact/signals-react';
import { DecodedPng, decode as decodePng } from 'fast-png';
import { Dictionary, compact, isEmpty, isNumber, join, map, times } from 'lodash';

import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { MultiScaleImageData } from './utils';

export const multiScaleLayerLoadingStates = times(MAX_VIEWERS, () => signal<Dictionary<boolean>>({}));

export async function getImageDataFromUrl({
  url,
  usePngDecoder,
  tileCoordinates,
  overlapPixels,
  tileSize,
}: {
  url: string | null;
  usePngDecoder: boolean;
  tileCoordinates: { x: number; y: number; z: number };
  overlapPixels: number;
  tileSize: number;
}): Promise<(ImageData | DecodedPng) | null> {
  try {
    if (!url) {
      return null;
    }
    if (usePngDecoder) {
      if (!url.endsWith('.png')) {
        console.warn('PNG decoding requested but not provided a url to a png file', { url });
      }
      const fetchResponse = await fetch(url);
      if (!fetchResponse.ok) {
        return null;
      }
      return decodePng(await fetchResponse.arrayBuffer());
    }
    const image: ImageData = await load(url, ImageLoader, {
      fetch: { cache: 'force-cache' },
      loadOptions: {
        image: { type: 'image' },
        imagebitmap: { premultiplyAlpha: 'premultiply' },
      },
      nothrow: true,
    });
    if (!isNumber(overlapPixels) || isNaN(overlapPixels) || overlapPixels <= 0) {
      return image;
    } else {
      /*
        Example of a grid of tiles with overlapping pixels:

        +-----+-+-----+-+-----+-+-----+
        |     :o:     :o:     :o:     |
        |  T  :o:  T  :o:  T  :o:  T  |
        |     :o:     :o:     :o:     |
        +-----+=+-----+=+-----+=+-----+
        |ooooo:o:ooooo:o:ooooo:o:ooooo|
        +-----+=+-----+=+-----+=+-----+
        |     :o:     :o:     :o:     |
        |  T  :o:  T  :o:  T  :o:  T  |
        |     :o:     :o:     :o:     |
        +-----+=+-----+=+-----+=+-----+
        |ooooo:o:ooooo:o:ooooo:o:ooooo|
        +-----+=+-----+=+-----+=+-----+
        |     :o:     :o:     :o:     |
        |  T  :o:  T  :o:  T  :o:  T  |
        |     :o:     :o:     :o:     |
        +-----+=+-----+=+-----+=+-----+
        |ooooo:o:ooooo:o:ooooo:o:ooooo|
        +-----+=+-----+=+-----+=+-----+
        |     :o:     :o:     :o:     |
        |  T  :o:  T  :o:  T  :o:  T  |
        |     :o:     :o:     :o:     |
        +-----+-+-----+-+-----+-+-----+

        T = Tile
        o = Overlap pixels

        - / | = Tile boundary
        : = Tile overlap boundary

        You can see that the overlap pixels are only present in the interior of the grid, not on the edges.

        More info: https://www.gasi.ch/blog/inside-deep-zoom-2
        */
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      // Don't read / write more than the tile size
      const width = Math.min(tileSize, image.width);
      canvas.width = width;
      const height = Math.min(tileSize, image.height);
      canvas.height = height;

      const isFirstColumn = tileCoordinates.x === 0;
      const isFirstRow = tileCoordinates.y === 0;

      // Validate size of first row / column images includes 1 overlap pixel at max.
      const maxExpectedFirstRowColumnSize = tileSize + overlapPixels;
      if (isFirstColumn && image.width > maxExpectedFirstRowColumnSize) {
        console.warn(
          `First column image width is greater than expected: ${image.width} > ${maxExpectedFirstRowColumnSize}`
        );
      }
      if (isFirstRow && image.height > maxExpectedFirstRowColumnSize) {
        console.warn(
          `First row image height is greater than expected: ${image.height} > ${maxExpectedFirstRowColumnSize}`
        );
      }

      // Skip reading overlap pixels if it's not the first row or column (since in that case there are no overlap pixels on the top / left).
      const startX = isFirstColumn ? 0 : overlapPixels;
      const startY = isFirstRow ? 0 : overlapPixels;

      context.drawImage(
        await createImageBitmap(image),
        startX,
        startY,
        canvas.width,
        canvas.height,
        0,
        0,
        canvas.width,
        canvas.height
      );

      return context.getImageData(0, 0, canvas.width, canvas.height);
    }
  } catch (err) {
    if (
      err instanceof ProgressEvent ||
      err.message?.startsWith?.('Failed to fetch') ||
      err.message?.startsWith('The user aborted a request')
    ) {
      return null;
    } else {
      console.error("Couldn't load tile", url, err);
      return null;
    }
  }
}

const generateEmptyResponse = (selectionUrls: Array<string | null>, usePngDecoder: boolean): MultiScaleImageData => ({
  data: times(selectionUrls?.length ?? 0, () => null),
  format: undefined,
  dataFormat: undefined,
  isDecodedPng: usePngDecoder,
});

export const getTileDataFromUrls = async ({
  selectionUrls,
  isRgb,
  usePngDecoder,
  viewerIndex,
  tileCoordinates,
  overlapPixels,
  tileSize,
}: {
  selectionUrls: Array<string | null>;
  isRgb: boolean;
  usePngDecoder: boolean;
  viewerIndex: number;
  tileCoordinates: { x: number; y: number; z: number };
  overlapPixels: number;
  tileSize: number;
}): Promise<MultiScaleImageData> => {
  // Early return if no selections
  if (isEmpty(selectionUrls)) {
    console.warn('No selections provided to getTileDataFromUrls');
    return null;
  }

  try {
    if (!multiScaleLayerLoadingStates[viewerIndex]) {
      console.warn('No loading states for viewer', viewerIndex);
      return null;
    } else {
      multiScaleLayerLoadingStates[viewerIndex].value = {
        ...multiScaleLayerLoadingStates[viewerIndex].value,
        [join(selectionUrls, ',')]: true,
      };
    }
    const tiles = await Promise.all(
      map(selectionUrls, (url) => getImageDataFromUrl({ url, usePngDecoder, tileCoordinates, overlapPixels, tileSize }))
    );

    if (!multiScaleLayerLoadingStates[viewerIndex]) {
      console.warn('No loading states for viewer', viewerIndex);
      return null;
    } else {
      multiScaleLayerLoadingStates[viewerIndex].value = {
        ...multiScaleLayerLoadingStates[viewerIndex].value,
        [join(selectionUrls, ',')]: false,
      };
    }

    if (isEmpty(compact(tiles))) {
      return null;
    }

    const tile = {
      data: tiles,
      format: isRgb ? GL.RGB : undefined,
      dataFormat: isRgb ? GL.RGB : undefined,
      isDecodedPng: usePngDecoder,
    };

    return tile;
  } catch (err) {
    console.error('Error fetching tile', err);

    return generateEmptyResponse(selectionUrls, usePngDecoder);
  }
};

export default getTileDataFromUrls;
