import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getChannelMarkers } from 'api/markerTypes';
import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import {
  NormalizationConfig,
  NormalizationParamOverrideChannelGroup,
} from 'interfaces/jobs/multiplex/normalizationParams';
import { filter, includes, isEmpty, join, map } from 'lodash';
import React from 'react';
import { encodeQueryParamsUsingSchema } from 'utils/useEncodedFilters';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import { useCasesParams } from '../../../../../../utils/useCasesParams';
import NormalizationParamsForm from './NormalizationParamsForm';

interface ChannelGroupsOverrideFormProps {
  channelGroupOverride: NormalizationParamOverrideChannelGroup;
  setChannelGroupOverride: (channelGroupOverride: NormalizationParamOverrideChannelGroup) => void;
  show8bitParams?: boolean;
  show16bitParams?: boolean;
  alreadySelectedChannels?: string[];
}

const ChannelGroupsOverride: React.FC<React.PropsWithChildren<ChannelGroupsOverrideFormProps>> = ({
  channelGroupOverride,
  setChannelGroupOverride,
  show8bitParams = true,
  show16bitParams = true,
  alreadySelectedChannels,
}) => {
  const { name, channels, overrides } = channelGroupOverride;

  const setChannelsOverrideConfig = (newOverrides: NormalizationConfig) => {
    setChannelGroupOverride({ ...channelGroupOverride, overrides: newOverrides });
  };

  const setName = (newName: string) => {
    setChannelGroupOverride({ ...channelGroupOverride, name: newName });
  };

  const setChannels = (newChannels: (number | string)[]) => {
    setChannelGroupOverride({ ...channelGroupOverride, channels: newChannels });
  };

  const { casesParams, schema, options } = useCasesParams();

  const getEncodedParams = () => encodeQueryParamsUsingSchema(casesParams, schema, options);

  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  const { data: channelMarkersResponse, isLoading: isLoadingChannelMarkers } = useQuery({
    queryKey: ['multiplex_marker_types', getEncodedParams()],
    queryFn: ({ signal }) => getChannelMarkers(getEncodedParams(), signal),
  });

  const channelMarkers = channelMarkersResponse?.channelMarkerTypes;

  const channelMarkersOptions = filter(
    map(channelMarkers, (channelMarker) => ({
      text: stainTypeIdToDisplayName(channelMarker) ?? channelMarker,
      value: channelMarker,
    })),
    (option) => !includes(alreadySelectedChannels, option.value)
  );

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item container spacing={1}>
        <Grid item xs={6}>
          <TextField required label="Name" value={name ?? ''} onChange={(event) => setName(event.target.value)} />
        </Grid>
        <Grid item xs={6}>
          <LabelledDropdown
            required
            multiple
            label="Channels"
            options={channelMarkersOptions}
            value={channels ?? []}
            onOptionSelected={(optionValue) => {
              setChannels(Array.isArray(optionValue) ? optionValue : [optionValue]);
            }}
            loading={isLoadingChannelMarkers || isLoadingStainTypeOptions}
          />
        </Grid>
      </Grid>
      <Grid item>
        <NormalizationParamsForm
          normalizationConfig={overrides ?? ({} as NormalizationConfig)}
          setNormalizationConfig={setChannelsOverrideConfig}
          show8bitParams={show8bitParams}
          show16bitParams={show16bitParams}
        />
      </Grid>
    </Grid>
  );
};

interface ChannelGroupsOverrideAccordionProps extends ChannelGroupsOverrideFormProps {
  index: number;
  removeOverride: (index: number) => void;
  open?: boolean;
  onChange: (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  alreadySelectedChannels?: string[];
}

export const ChannelGroupsOverrideAccordion: React.FC<React.PropsWithChildren<ChannelGroupsOverrideAccordionProps>> = ({
  channelGroupOverride,
  setChannelGroupOverride,
  show8bitParams = true,
  show16bitParams = true,
  index,
  removeOverride,
  open,
  onChange,
  alreadySelectedChannels,
}) => {
  return (
    <Accordion expanded={open} onChange={onChange}>
      <AccordionSummary id={`${index}`}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs container direction="row" spacing={1}>
            <Grid item container xs={2} alignContent="center">
              <Typography variant="h4">{`Override ${index + 1}:`}</Typography>
            </Grid>

            {!open && (
              <Grid item container xs={9}>
                <Grid item container direction="column" xs={1}>
                  <Grid item>
                    <Typography variant="caption" color={channelGroupOverride.name === '' ? 'red' : ''}>
                      Name
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant="caption">{channelGroupOverride.name}</Typography>
                  </Grid>
                </Grid>

                <Grid item container direction="column" xs={1}>
                  <Grid item>
                    <Typography variant="caption" color={isEmpty(channelGroupOverride.channels) ? 'red' : ''}>
                      Channels
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography variant="caption">{join(channelGroupOverride.channels, ',')}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <DeleteOutlineIcon
              sx={{
                cursor: 'pointer',
              }}
              onClick={(event) => {
                event.stopPropagation();
                removeOverride(index);
              }}
            />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <ChannelGroupsOverride
          channelGroupOverride={channelGroupOverride}
          setChannelGroupOverride={setChannelGroupOverride}
          show8bitParams={show8bitParams}
          show16bitParams={show16bitParams}
          alreadySelectedChannels={alreadySelectedChannels}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ChannelGroupsOverride;
