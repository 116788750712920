import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TreeView } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import {
  ClassificationBinningParams,
  InferenceModelData,
  ModelInference,
  OrchestrationInference,
  SlideInferenceResults,
} from 'interfaces/calculateFeatures';
import { Dictionary, isEmpty, map } from 'lodash';
import React from 'react';
import { ModelClassificationById, OrchestrationBySlideByFlowClassName, OrchestrationBySlideByType } from '..';
import { modelTypesByApiModelValue } from '../../Jobs/inferenceFieldsOptions';
import OrchestrationRow from './OrchestrationRow';
import ModelRow from './model/ModelRow';

export interface InferenceModelProps {
  studyId: string;
  stain: string;
  modelType: string;
  modelInferences?: {
    // model url
    [key: string]: ModelInference;
  };
  orchestrations?: OrchestrationInference[];
  slides: Dictionary<SlideInferenceResults>;
  selectedOrchestrations?: OrchestrationBySlideByType;
  setSelectedOrchestrations?: (
    slideIds: string[],
    model: InferenceModelData,
    modelType: string,
    orchestration: OrchestrationInference
  ) => void;
  selectedPostprocessedOrchestrations?: OrchestrationBySlideByFlowClassName;
  setSelectedPostprocessedOrchestrations?: (
    slideIds: string[],
    flowClassName: string,
    orchestration: OrchestrationInference
  ) => void;
  setModelClassificationByModelId?: (modelId: string, classification: string) => void;
  modelClassificationByModelId?: ModelClassificationById;
  setSelectedOrchestrationsByClassificationType?: (modelType: string, previousModelType?: string) => void;
  setIntensityClassificationBinning?: (modelId: string, binning: ClassificationBinningParams) => void;
}

const InferenceModel: React.FC<React.PropsWithChildren<InferenceModelProps>> = ({
  studyId,
  stain,
  modelType,
  modelInferences,
  orchestrations,
  slides,
  selectedOrchestrations,
  setSelectedOrchestrations,
  selectedPostprocessedOrchestrations,
  setSelectedPostprocessedOrchestrations,
  modelClassificationByModelId,
  setModelClassificationByModelId,
  setSelectedOrchestrationsByClassificationType,
  setIntensityClassificationBinning,
}) => {
  return (
    <Accordion disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {modelTypesByApiModelValue[modelType]?.text ?? modelType}
      </AccordionSummary>
      <AccordionDetails>
        <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
          {!isEmpty(modelInferences)
            ? map(modelInferences, (modelInference, modelInferenceUrl) => (
                <ModelRow
                  key={modelInferenceUrl}
                  stain={stain}
                  modelType={modelType}
                  modelInference={modelInference}
                  slides={slides}
                  selectedOrchestrations={selectedOrchestrations}
                  setSelectedOrchestrations={setSelectedOrchestrations}
                  studyId={studyId}
                  modelClassificationByModelId={modelClassificationByModelId}
                  setModelClassificationByModelId={setModelClassificationByModelId}
                  setSelectedOrchestrationsByClassificationType={setSelectedOrchestrationsByClassificationType}
                  setIntensityClassificationBinning={setIntensityClassificationBinning}
                />
              ))
            : !isEmpty(orchestrations)
            ? map(orchestrations, (orchestration) => {
                return (
                  <OrchestrationRow
                    key={orchestration.orchestrationId}
                    modelType={modelType}
                    orchestration={orchestration}
                    selectedOrchestrations={selectedPostprocessedOrchestrations}
                    onCheck={(newOrchestration: OrchestrationInference, slideIds: string[], checked: boolean) => {
                      setSelectedPostprocessedOrchestrations(slideIds, modelType, checked ? newOrchestration : null);
                    }}
                    slides={slides}
                    studyId={studyId}
                  />
                );
              })
            : null}
        </TreeView>
      </AccordionDetails>
    </Accordion>
  );
};

export default InferenceModel;
