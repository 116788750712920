import { Auth0Error, Auth0Result } from 'auth0-js';
import Auth0Lock from 'auth0-lock';
import nucleaiLogo from 'nucleai_logo.svg';
import { loginSuccess } from 'redux/actions/auth';
// eslint-disable-next-line import/no-cycle
import store from 'redux/store';

declare const AUTH_CLIENT_ID: string;
declare const AUTH_DOMAIN: string;
declare const ALLOWED_CONNECTION: string;
declare const AUTH_AUDIENCE: string;

const DUMMY_AUTH0_NAMESPACE = 'https://nucleai-dummy-url.com';

export interface Auth0Profile {
  labId: string;
  name: string;
  roles: string[];

  userId: string;
  userName: string;
  userNickname: string;
  backOfficeRole: string;
}

class AuthService {
  // eslint-disable-next-line no-undef
  declare lock: Auth0LockStatic;

  constructor() {
    this.lock = new Auth0Lock(AUTH_CLIENT_ID, AUTH_DOMAIN, {
      auth: {
        responseType: 'token id_token',
        redirectUrl: `${window.location.origin}/#/login`,
        sso: false,
        redirect: false,
        audience: AUTH_AUDIENCE,
      },
      container: 'lock-container',
      theme: {
        primaryColor: '#5A4FCF',
        logo: nucleaiLogo,
      },
      allowedConnections: [ALLOWED_CONNECTION],
      allowForgotPassword: true,
      allowAutocomplete: true,
      configurationBaseUrl: 'https://cdn.auth0.com',
      autofocus: true,
      languageDictionary: {
        title: 'Login',
        forgotPasswordAction: 'Create / reset your password',
      },
    });
    this.handleLockAuthenticated = this.handleLockAuthenticated.bind(this);
    this.lock.on('authenticated', this.handleLockAuthenticated);
  }

  login() {
    this.lock.show();
  }

  logout() {
    this.lock.logout({
      returnTo: `${window.location.origin}/#/login`,
    });
  }

  handleLockAuthenticated({
    accessToken,
    expiresIn,
    scope,
  }: {
    accessToken: string;
    expiresIn: number;
    scope: string;
  }) {
    const expiresAt = expiresIn * 1000 + new Date().getTime();
    this.getUserInfo(accessToken).then((userInfo) => {
      store.dispatch(loginSuccess(userInfo, scope, accessToken, expiresAt));
    });
  }

  getUserInfo = async (accessToken: string): Promise<Auth0Profile> => {
    let getUserInfoError: Auth0Error | null = null;
    for (const requestNum of [0, 1, 2]) {
      await new Promise((resolve) => setTimeout(resolve, requestNum * 1000));
      const { error, profile } = await new Promise<{ error?: Auth0Error; profile?: Auth0Profile }>((resolve) => {
        this.lock.getUserInfo(accessToken, (auth0Error, auth0Profile: { name: string; [propName: string]: any }) => {
          if (auth0Error) {
            resolve({ error: auth0Error });
          } else {
            resolve({
              profile: {
                labId: auth0Profile[`${DUMMY_AUTH0_NAMESPACE}/lab_id`],
                name: auth0Profile.name,
                roles: auth0Profile[`${DUMMY_AUTH0_NAMESPACE}/roles`] || [],

                userId: auth0Profile.sub,
                userName: auth0Profile.name,
                userNickname: auth0Profile.nickname,
                backOfficeRole: auth0Profile[`${DUMMY_AUTH0_NAMESPACE}/backoffice_role`],
              },
            });
          }
        });
      });
      if (profile) {
        return profile;
      } else if (error.statusCode === 429) {
        // Retry on rate limit error
        getUserInfoError = error;
      } else {
        // Throw on other errors
        throw Error(error.description);
      }
    }
    throw Error(getUserInfoError.description);
  };

  getNewSession(): Promise<Auth0Result> {
    return new Promise<Auth0Result>((resolve, reject) => {
      this.lock.checkSession({ scope: 'openid profile email' }, (err, result) => {
        if (err) {
          reject(err);
        } else {
          // @ts-ignore
          result.expiresAt = result.expiresIn * 1000 + new Date().getTime();
          resolve(result);
        }
      });
    });
  }
}

const authService = new AuthService();
export default authService;
