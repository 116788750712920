import { Grid, Grow, Paper } from '@mui/material';
import { map } from 'lodash';
import React from 'react';

import ColorMapLegend, { getHeatmapLegends } from 'components/Procedure/SlidesViewer/ColorMapLegend';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';

interface Props {
  slide: SlideWithChannelAndResults;
}

export const ColorMapLegendOverlay: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ slide }) => {
  const heatmapLegends = getHeatmapLegends(slide);

  return (
    <Grid container style={{ position: 'absolute', bottom: 30 }}>
      {map(heatmapLegends, (legend, index) => (
        <Grid item key={index}>
          <Grow in>
            <Paper elevation={0} style={{ opacity: 0.95 }}>
              <ColorMapLegend variant={legend.colorMap} colorMapRange={legend.range} nshades={100} />
            </Paper>
          </Grow>
        </Grid>
      ))}
    </Grid>
  );
};

export default ColorMapLegendOverlay;
