import { forEach, isEmpty } from 'lodash';

export const getFilesPromiseFromDataTransferItemList = async (items: DataTransferItemList): Promise<File[]> => {
  const promises: Promise<File[]>[] = [];
  for (let i = 0; i < items.length; i++) {
    promises.push(getFilesPromiseFromDataTransferItem(items[i]));
  }

  return Promise.all(promises).then((arrayOfArrays) => {
    return [].concat.apply([], arrayOfArrays);
  });
};

const getFilesPromiseFromDataTransferItem = async (item: DataTransferItem): Promise<File[]> => {
  const promises: Promise<File[]>[] = [];
  const entry: FileSystemEntry = item?.webkitGetAsEntry?.();

  if (entry) {
    if (entry.isFile) {
      promises.push(fileAsPromise(entry as FileSystemFileEntry));
    } else if (entry.isDirectory) {
      promises.push(getEntriesAsPromise(entry as FileSystemDirectoryEntry));
    }
  }

  return Promise.all(promises).then((arrayOfArrays) => {
    return [].concat.apply([], arrayOfArrays);
  });
};

const getEntriesAsPromise = async (dirEntry: FileSystemDirectoryEntry): Promise<File[]> => {
  return new Promise((resolve, reject) => {
    const promises: Promise<File[]>[] = [];
    const reader = dirEntry.createReader();
    const doBatch = () => {
      reader.readEntries((entries: FileSystemEntry[]) => {
        if (!isEmpty(entries)) {
          forEach(entries, (entry: FileSystemEntry) => {
            const fullPath: string = entry?.fullPath?.startsWith('/') ? entry.fullPath.slice(1) : entry.fullPath;
            if (entry?.isFile) {
              promises.push(fileWithFullPathAsPromise(entry as FileSystemFileEntry, fullPath));
            } else if (entry?.isDirectory) {
              promises.push(getEntriesAsPromise(entry as FileSystemDirectoryEntry));
            }
          });
          doBatch();
        } else {
          resolve(
            Promise.all(promises).then((arrayOfArrays) => {
              return [].concat.apply([], arrayOfArrays);
            })
          );
        }
      }, reject);
    };
    doBatch();
  });
};

const fileAsPromise = async (entry: FileSystemFileEntry): Promise<File[]> => {
  return new Promise<File[]>((resolve, reject) => {
    entry.file((file: File) => resolve([file]), reject);
  });
};

const fileWithFullPathAsPromise = async (entry: FileSystemFileEntry, fullPath: string): Promise<File[]> => {
  return new Promise<File[]>((resolve, reject) => {
    entry.file((file: File) => resolve([new File([file], fullPath + '/' + file.name, { type: file.type })]), reject);
  });
};
