import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';
import React, { RefObject } from 'react';

interface Props extends BaseTextFieldProps {
  label: string;
  className?: string;
  value?: string;
  placeholder?: string;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  onReset?(): void;
  onKeyDown?(event: React.KeyboardEvent<HTMLInputElement>): void;
  required?: boolean;
  dataTestClass?: string;
  inputRef?: RefObject<Element>;
}

const LabelledInput: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  label,
  onChange,
  onReset = null,
  onKeyDown,
  placeholder,
  value,
  size,
  variant,
  required,
  inputRef,
  dataTestClass,
}: Props) => {
  return (
    <TextField
      data-test-class={dataTestClass}
      fullWidth
      size={size}
      label={label}
      variant={variant}
      inputRef={inputRef}
      placeholder={placeholder}
      onChange={onChange}
      onKeyDown={onKeyDown}
      InputProps={{
        ...(onReset && {
          endAdornment: (
            <IconButton onClick={onReset} sx={{ padding: 0 }}>
              <CloseOutlinedIcon fontSize="small" />
            </IconButton>
          ),
        }),
      }}
      value={value}
      required={required}
    />
  );
};
export default LabelledInput;
