import { COORDINATE_SYSTEM } from '@deck.gl/core/typed';
import { ClipExtension } from '@deck.gl/extensions/typed';
import { TileLoadProps } from '@deck.gl/geo-layers/typed/tileset-2d';
import { GeoJsonLayer, PolygonLayer, TextLayer } from '@deck.gl/layers/typed';
import { TileJSONLayer } from '@loaders.gl/mvt/dist/lib/parse-tilejson';
import { PMTilesMetadata, PMTilesSource } from '@loaders.gl/pmtiles';
import { FeatureCollection } from '@turf/helpers';
import { Dictionary, compact, difference, isEmpty, map, some, values } from 'lodash';
// @ts-ignore
import { reproject } from 'reproject';

import { LayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import CustomTileLayer from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/layers/multiScaleImageLayer/customTileLayer';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { defaultLayerColors } from 'components/theme/theme';
import { hexToRgb } from 'utils/helpers';

/**
 * Convert a deck.gl tile {x ,y ,z} coordinates to a PMTiles tile {x ,y ,z} coordinates.
 * We perform the following transformations:
 * - The origin of the PMTiles tile is at the center of the map, while the origin of the deck.gl tile is at the top left.
 * - The y axis is flipped in PMTiles (negative values in the bottom, positive on top).
 * - In deck GL, we zoom out of the base layer, so we need to add the maximum zoom level of the base layer to the zoom level.
 * @param x The x coordinate of the tile.
 * @param y The y coordinate of the tile.
 * @param z The zoom level of the tile.
 * @param maxLevel The maximum zoom level of the pmt source.
 * @returns The transformed tile {x ,y ,z} coordinates.
 */
const deckGLToPMTTileIndex = ({ x, y, z, maxLevel }: { x: number; y: number; z: number; maxLevel: number }) => {
  const actualZoom = z + maxLevel;
  // PMTiles uses a geographic coordinate system, where the origin is at the center of the map,
  // and the y axis is flipped (negative values in the bottom, positive on top).
  const actualX = x + 2 ** (actualZoom - 1);
  const actualY = 2 ** (actualZoom - 1) - y - 1;

  return { x: actualX, y: actualY, z: actualZoom };
};

/**
 * Generate a tile size for the PMTiles source.
 *
 * This tile size will help match a tile in each zoom 'coordinate' to the matching data in the web mercator projection.
 * This is required because the PMTiles source uses a geographic coordinate system, while deck.gl uses a pixel projection.
 *
 * The relevant difference stems from the fact that in Deck GL we define zooms from the original image, meaning that at zoom 0 the pixels are in 'true size'.
 * Meanwhile, in PMTiles, the zooms are defined from the geographic coordinate system, meaning that we start at zoom 0 which is defined as a single tile of
 * size 256 pixels containing the entire length of the equator (see https://docs.maptiler.com/google-maps-coordinates-tile-bounds-projection/).
 *
 * To calculate the tile size, we need to create a tile that would have a pixel resolution of 1 meter per pixel (since we encode pixels as meters in the PMTiles source).
 *
 * @param maxLevel The maximum zoom level of the pmt source.
 * @returns The tile size in pixels.
 *
 * @example: getTileSizeByMaxZoom(16) == 611.49622628141;
 */
const getTileSizeByMaxZoom = (maxLevel: number) => {
  const MERCATOR_ZOOM_0_TILE_PIXELS_WIDTH = 256;
  const EQUATOR_LENGTH_METERS = 40075016.68557849;
  const MERCATOR_ZOOM_0_RESOLUTION_METERS_PER_PIXEL = EQUATOR_LENGTH_METERS / MERCATOR_ZOOM_0_TILE_PIXELS_WIDTH;
  const MERCATOR_MAX_ZOOM_RESOLUTION_METERS_PER_PIXEL = MERCATOR_ZOOM_0_RESOLUTION_METERS_PER_PIXEL / 2 ** maxLevel;
  return MERCATOR_MAX_ZOOM_RESOLUTION_METERS_PER_PIXEL * MERCATOR_ZOOM_0_TILE_PIXELS_WIDTH;
};

/**
 * Get the tile data for the PMTiles layers.
 * This function will fetch the tile data from the PMTiles source, and reproject it to the web mercator projection
 * since we are encoding pixel values in the PMTiles source as meters.
 * @param options The options for the function.
 * @param options.tile The tile coordinates to fetch. Can be either a tile index object {x, y, z} or a tile object with an index property.
 * @param options.maxLevel The maximum zoom level of the base layer.
 * @param options.pmtTileSource The PMTiles source to fetch the data from.
 * @param options.pmtLayers The layers to fetch from the PMTiles source.
 * @returns The tile data for the PMTiles layers.
 */
const getTileDataForPmtLayers = async ({
  tile,
  maxLevel,
  pmtTileSource,
  pmtLayers,
}: {
  tile: TileLoadProps;
  maxLevel: number;
  pmtTileSource: PMTilesSource;
  pmtLayers: TileJSONLayer[];
}): Promise<FeatureCollection> => {
  const { x, y, z } = (tile.index ? tile.index : tile) as { x: number; y: number; z: number };

  const {
    x: actualX,
    y: actualY,
    z: actualZoom,
  } = deckGLToPMTTileIndex({
    x,
    y,
    z,
    maxLevel,
  });

  // EPSG code is a unique identifier for different coordinate systems
  const originProjection = 'EPSG:4326';
  const targetProjection = 'EPSG:3857';

  const baseResponse = (await pmtTileSource.getVectorTile({
    x: actualX,
    y: actualY,
    zoom: actualZoom,
    // Not used yet (unfortunately), but required by the type definition.
    layers: [],
  })) as FeatureCollection;

  const response = baseResponse
    ? (reproject(baseResponse, originProjection, targetProjection) as FeatureCollection)
    : null;

  if (response) {
    response.features = map(response.features, (feature, featureIdx) => ({
      ...feature,
      properties: { ...pmtLayers?.[featureIdx], ...feature.properties },
    }));
  }

  return response;
};

/**
 * Generate debug layers for the PMTiles layer.
 * This function will generate a text layer with the tile's coordinates and the
 * number of features found in the tile,
 * @param options The options for the function.
 * @param options.id The id of the tile layer.
 * @param options.maxLevel The maximum zoom level of the base layer.
 * @param options.tileCoordinates The tile coordinates of the tile.
 * @param options.boundingBox The bounding box of the tile.
 * @param options.numFeatures The number of features found in the tile.
 * @returns The debug layers.
 */
const generateDebugLayers = ({
  id,
  maxLevel,
  tileCoordinates,
  boundingBox,
  numFeatures,
}: {
  id: string;
  maxLevel: number;
  tileCoordinates: { x: number; y: number; z: number };
  boundingBox: [number[], number[]];
  numFeatures: number;
}) => {
  const topLeft = boundingBox[0];
  const bottomRight = boundingBox[1];
  const { x, y, z } = tileCoordinates;
  const {
    x: actualX,
    y: actualY,
    z: actualZoom,
  } = deckGLToPMTTileIndex({
    x,
    y,
    z,
    maxLevel,
  });

  return [
    // Text layer with the tiles' coordinates.
    new TextLayer({
      id: `${id}-text`,
      data: [
        {
          text: `PMT Tile ${x}, ${y}, ${z}\n(actual ${actualX}, ${actualY}, ${actualZoom})\nloaded - ${numFeatures} features found`,
          // Get the center of the tile.
          position: [(topLeft[0] + bottomRight[0]) / 2, (topLeft[1] + bottomRight[1]) / 2],
          size: 16,
          color: numFeatures > 0 ? [255, 255, 0] : [255, 0, 0],
        },
      ],
      getPosition: (dataEntry) => dataEntry.position,
      getText: (dataEntry) => dataEntry.text,
      getSize: (dataEntry) => dataEntry.size,
      getColor: (dataEntry) => dataEntry.color,
      sizeScale: 1,
    }),
    new PolygonLayer({
      id: `${id}-bounding-box`,
      data: [
        {
          polygon: [
            [topLeft[0], topLeft[1]],
            [topLeft[0], bottomRight[1]],
            [bottomRight[0], bottomRight[1]],
            [bottomRight[0], topLeft[1]],
          ],
        },
      ],
      getPolygon: (dataEntry) => dataEntry.polygon,
      getFillColor: [0, 0, 0, 0],
      getLineColor: numFeatures > 0 ? [255, 255, 0] : [255, 0, 0],
      getLineWidth: 2 ** Math.max(1, 1 - tileCoordinates.z), // Scale the line width based on the zoom level.
      lineWidthScale: 1,
    }),
  ];
};

/**
 * Create a deck.gl layer for the PMTiles source.
 * This layer will fetch the PMTiles source and display the features on the map.
 * @param options The options for the function.
 * @param options.idPrefix The prefix to add to the layer id.
 * @param options.pmtHeatmap The heatmap to display.
 * @param options.maxLevel The maximum zoom level of the base layer.
 * @param options.pmtTileSource The PMTiles source to fetch the data from.
 * @param options.pmtMetadata The metadata of the PMTiles source.
 * @param options.slideLayerVisualizationSettings The visualization settings for the layer.
 * @param options.debug Whether to display debug layers.
 * @returns The deck.gl layers for the PMTiles source.
 */
export const deckGLPMTLayer = ({
  idPrefix = '',
  visualSettings,
  maxLevel,
  pmtHeatmap,
  pmtTileSource,
  pmtMetadata,
  rescaleFactor,
  debug = false,
}: {
  idPrefix?: string;
  visualSettings: Dictionary<LayerVisualizationSettings>;
  maxLevel: number;
  pmtHeatmap: FeatureMetadata;
  pmtTileSource: PMTilesSource;
  pmtMetadata: PMTilesMetadata;
  rescaleFactor?: number;
  debug?: boolean;
}) => {
  const pmtHeatmapId = pmtHeatmap?.id;
  if (!pmtHeatmapId) {
    console.warn('Invalid PMTiles heatmap id', { pmtHeatmap, visualSettings, pmtMetadata, pmtTileSource });
    return undefined;
  }
  const pointRadiusAtMaxZoom = Math.max(1, 5 / (rescaleFactor || 1));
  const pmtLayers = pmtMetadata?.tilejson?.layers;
  const hasActivePmtSource = some(values(visualSettings), 'selected');

  if (!pmtTileSource || !pmtMetadata || !hasActivePmtSource) {
    if (hasActivePmtSource && (!pmtTileSource || !pmtMetadata)) {
      console.warn('Invalid PMTiles source or metadata', { pmtTileSource, pmtMetadata });
    }
    return undefined;
  }

  // Check that the PMT classes in the metadata match the options in the pmtHeatmap.
  const pmtClasses = map(pmtMetadata?.tilejson?.layers, 'id');
  const pmtHeatmapClasses = map(pmtHeatmap?.options, 'key');

  const unmatchedPmtClasses = difference(pmtClasses, pmtHeatmapClasses);
  const unmatchedHeatmapClasses = difference(pmtHeatmapClasses, pmtClasses);

  if (!isEmpty(unmatchedPmtClasses) || !isEmpty(unmatchedHeatmapClasses)) {
    console.warn('Mismatch between PMTiles classes and heatmap options', {
      unmatchedPmtClasses,
      unmatchedHeatmapClasses,
      pmtMetadata,
      pmtHeatmap,
    });
  }

  const maxZoom = pmtMetadata.maxZoom - maxLevel;
  const minZoom = Math.min(maxZoom, pmtMetadata.minZoom - maxLevel);

  return new CustomTileLayer<FeatureCollection>({
    id: `${idPrefix}${pmtHeatmapId}`,
    minZoom,
    maxZoom,

    coordinateSystem: COORDINATE_SYSTEM.DEFAULT,

    // Convert web mercator tile to PMTiles tile.
    tileSize: getTileSizeByMaxZoom(maxLevel),

    updateTriggers: {
      getTileData: [pmtHeatmapId],
      renderSubLayers: [pmtHeatmapId, JSON.stringify(visualSettings)],
    },
    // Assume the pmtiles file support HTTP/2, so we aren't limited by the browser to a certain number per domain.
    maxRequests: 20,

    pickable: true,

    getTileData: async (tile) => getTileDataForPmtLayers({ tile, maxLevel, pmtTileSource, pmtLayers }),
    renderSubLayers: (props) => {
      const features = props.data?.features;
      const debugLayers = debug
        ? generateDebugLayers({
            id: props.id,
            maxLevel,
            tileCoordinates: (props.tile.index || props.tile) as { x: number; y: number; z: number },
            boundingBox: props.tile.boundingBox,
            numFeatures: features?.length,
          })
        : [];
      if (isEmpty(features)) {
        return debugLayers;
      }

      // Add a clip extension to the layer to clip the features to the tile's bounding box.
      // Without this extension, features extending beyond the tile's bounding box are displayed.
      props.extensions = [...(props.extensions || []), new ClipExtension()];
      // Set the clip bounds to the tile's bounding box. The bounds are in the form [minX, minY, maxX, maxY].
      const topLeft = props.tile.boundingBox[0];
      const bottomRight = props.tile.boundingBox[1];
      // @ts-ignore
      props.clipBounds = [topLeft[0], topLeft[1], bottomRight[0], bottomRight[1]];

      return compact([
        map(features, (feature, dataIdx) => {
          const featureSettings =
            visualSettings?.[`${pmtHeatmapId}-${feature?.properties?.class_name || feature?.properties?.name}`];
          if (!featureSettings?.selected) {
            return undefined;
          }
          const isFullShape = feature?.geometry?.type === 'Polygon' || feature?.geometry?.type === 'MultiPolygon';
          const isPoint = feature?.geometry?.type === 'Point' || feature?.geometry?.type === 'MultiPoint';
          return new GeoJsonLayer({
            ...props,
            pickable: true,
            id: `${props.id}-geojson-${
              feature?.properties?.class_name || feature.properties?.name || 'all-layers'
            }-${dataIdx}`,
            data: feature,
            opacity: featureSettings?.show ? featureSettings?.opacity / 100 : 0,
            ...(isFullShape
              ? {
                  getFillColor: hexToRgb(
                    featureSettings?.color?.hex ||
                      featureSettings?.color ||
                      defaultLayerColors[dataIdx % defaultLayerColors.length]
                  ),
                  getLineWidth: 0,
                }
              : isPoint
              ? {
                  // The point radius is scaled based on the zoom level - the higher the zoom level, the higher the point radius, up to the pointRadiusAtMaxZoom.
                  getPointRadius: pointRadiusAtMaxZoom * (1 / 2 ** (maxZoom - props.tile.index.z)),
                  pointRadiusMinPixels: 1,
                  // We don't use the pointRadiusMaxPixels because we can zoom beyond the maximum zoom level of the base layer.
                  pointRadiusScale: rescaleFactor ? 1 / rescaleFactor : 1,
                  getFillColor: hexToRgb(
                    featureSettings?.color?.hex ||
                      featureSettings?.color ||
                      defaultLayerColors[dataIdx % defaultLayerColors.length]
                  ),
                  getLineWidth: 0,
                }
              : {
                  getLineColor: hexToRgb(
                    featureSettings?.color?.hex ||
                      featureSettings?.color ||
                      defaultLayerColors[dataIdx % defaultLayerColors.length]
                  ),
                  getLineWidth: 2 ** Math.max(1, 1 - props.tile.index.z) / (rescaleFactor || 1),
                  lineWidthMinPixels: 1,
                  lineWidthMaxPixels: 5,
                  lineWidthScale: rescaleFactor ? 1 / rescaleFactor : 1,
                }),
          });
        }),
        // Display the bounding box and the tile coordinates on top of the features for debugging purposes.
        ...debugLayers,
      ]);
    },
  });
};
