import { map, times } from 'lodash';
import OpenSeadragon from 'openseadragon';
import OSDCanvasOverlayPlugin from './OSDCanvasOverlayPlugin';
import OSDFilteringPlugin from './OSDFilteringPlugin';
import OSDScalebarPlugin from './OSDScalebarPlugin';
import OSDSvgOverlayPlugin from './OSDSvgOverlayPlugin';

OSDFilteringPlugin.wrap(OpenSeadragon);
OSDCanvasOverlayPlugin.wrap(OpenSeadragon);
OSDSvgOverlayPlugin.wrap(OpenSeadragon);

class OSDViewer {
  constructor(config, openSlideCb = null, zoomCb = null, panCb = null, failedSlideCb = null) {
    const defaults = {
      crossOriginPolicy: 'Anonymous',
      prefixUrl: 'static/osd/',
      degrees: 0,
      timeout: 120000,
      animationTime: 1,
      blendTime: 0,
      constrainDuringPan: true,
      maxZoomPixelRatio: 4,
      minZoomLevel: 0.7,
      defaultZoomLevel: 1,
      visibilityRatio: 0.6,
      zoomPerScroll: 2,
      showNavigator: config.showNavigator,
      navigatorPosition: 'TOP_LEFT',
      navigatorAutoFade: false,
      showZoomControl: false,
      showHomeControl: false,
      showFullPageControl: false,
      navigatorDisplayRegionColor: 'black',
    };

    OSDScalebarPlugin.mountPlugin(OpenSeadragon);

    this.osd = OpenSeadragon({ ...defaults, ...config });

    this.osd.scalebar({
      type: OpenSeadragon.ScalebarType.MICROSCOPY,
      pixelsPerMeter: 10000000,
      minWidth: '50px',
      location: OpenSeadragon.ScalebarLocation.BOTTOM_LEFT,
      xOffset: 5,
      yOffset: 10,
      stayInsideImage: true,
      color: 'transparent',
      fontColor: 'transparent',
      backgroundColor: 'transparent',
      fontSize: '0px',
      barThickness: 3,
    });
    this.initCanvasOverlay();
    this.initEvents(openSlideCb, zoomCb, panCb, failedSlideCb);
  }

  initCanvasOverlay() {
    this.canvasOverlay = this.osd.canvasOverlay({ clearBeforeRedraw: true });
  }

  initEvents(openSlideCb, zoomCb, panCb, failedSlideCb) {
    this.osd.addHandler('open', () => {
      if (openSlideCb) {
        openSlideCb();
      }
    });
    this.osd.addHandler('zoom', (zoom) => {
      if (zoomCb) {
        zoomCb(zoom);
      }
    });
    this.osd.addHandler('pan', (point) => {
      if (panCb) {
        panCb(point);
      }
    });
    this.osd.addHandler('open-failed', () => {
      if (failedSlideCb) {
        failedSlideCb(this.osd);
      }
    });
  }

  openSlide(manifest) {
    this.osd.open(manifest.slide_url);
  }

  get internalOSD() {
    return this.osd;
  }

  fitBoundsToRect(rectangle, convertToViewportCoordinates = false) {
    const bounds = convertToViewportCoordinates
      ? this.osd.viewport.imageToViewportRectangle(rectangle.x, rectangle.y, rectangle.width, rectangle.height)
      : rectangle;
    this.osd.viewport.fitBoundsWithConstraints(bounds, true);
  }

  setRotation(degrees) {
    this.osd.viewport.setRotation(degrees);
  }

  zoomTo(zoom) {
    this.osd.viewport.zoomTo(zoom);
  }

  panTo(point) {
    this.osd.viewport.panTo(point);
  }

  getZoom() {
    return this.osd.viewport.getZoom();
  }

  getCenter() {
    return this.osd.viewport.getCenter();
  }

  viewportToImageRectangle(point) {
    // when there are multiple images (for example in demo where the categories/overlays are slides)
    // we should refer to which TiledImage we want to perform the coordinates transformation.
    // the real slide is always the first item in the viewer so that's why we're using index 0
    if (this.osd.world.getItemCount() > 1) {
      return this.osd.world.getItemAt(0).viewportToImageRectangle(point.x, point.y);
    }
    return this.osd.viewport.viewportToImageRectangle(point.x, point.y);
  }

  viewportToImageCoordinates(point) {
    // when there are multiple images (for example in demo where the categories/overlays are slides)
    // we should refer to which TiledImage we want to perform the coordinates transformation.
    // the real slide is always the first item in the viewer so that's why we're using index 0
    if (this.osd.world.getItemCount() > 1) {
      return this.osd.world.getItemAt(0).viewportToImageCoordinates(point.x, point.y);
    }
    return this.osd.viewport.viewportToImageCoordinates(point.x, point.y);
  }

  imageToViewportPoint(point) {
    if (this.osd.world.getItemCount() > 1) {
      return this.osd.world.getItemAt(0).imageToViewportCoordinates(point.x, point.y);
    }
    // in some cases there are 0 items and the viewport method knows how to handle that
    return this.osd.viewport.imageToViewportCoordinates(point.x, point.y);
  }

  // end public api

  addHandler(eventName, cb) {
    this.osd.addHandler(eventName, cb);
  }

  setColorMap(filterItems) {
    const filters = map(filterItems, (filterItem) => {
      const colorRangeMin = filterItem.range[0];
      const colorRangeMax = filterItem.range[1];

      let hexColor = filterItem?.hexColor?.hex || filterItem?.hexColor;
      const rgbcolor = hexToRgb(hexColor);

      const colorMap = times(256, (x) => {
        const red = ((rgbcolor?.r ?? 0) / 255) * x;
        const redRange = Math.min(Math.pow(rangeColor(red, colorRangeMax, colorRangeMin), filterItem.gamma), 255);
        const green = ((rgbcolor?.g ?? 0) / 255) * x;
        const greenRange = Math.min(Math.pow(rangeColor(green, colorRangeMax, colorRangeMin), filterItem.gamma), 255);
        const blue = ((rgbcolor?.b ?? 0) / 255) * x;
        const blueRange = Math.min(Math.pow(rangeColor(blue, colorRangeMax, colorRangeMin), filterItem.gamma), 255);
        return [redRange, greenRange, blueRange, 1];
      });

      return {
        items: this.osd.world.getItemAt(filterItem.indexItem),
        processors: [OpenSeadragon.Filters.COLORMAP(colorMap)],
      };
    });

    this.osd.setFilterOptions({
      filters: filters,
    });
  }
}

function rangeColor(color, colorRangeMax, colorRangeMin) {
  if (color <= colorRangeMin) {
    return 0;
  }
  return (color * 255) / colorRangeMax;
}

function hexToRgb(hex) {
  if (!hex) {
    return null;
  }
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export default OSDViewer;
