import { GridRowId, GridRowModesModel } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { reject } from 'lodash';
import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { createModelTypeOption, deleteModelTypeOption, updateModelTypeOption } from 'api/modelTypes';
import { useCrudControlsColumns } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { ModelType } from 'interfaces/modelType';
import { modelTypeFields } from 'interfaces/modelType/modelTypeFields';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { ModelTypeRowChangesSummary } from './ModelTypeRowChangesSummary';
import { ModelTypeDraft } from './types';
import { getModelTypeError, getModelTypeId } from './utils';

export const useModelTypesColumns = ({
  apiRef,
  noRows,
  modelTypes,
  draftModelTypes,
  rowModesModel,
  setDraftModelTypes,
  setRowModesModel,
}: {
  noRows?: boolean;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  modelTypes: Array<ModelType>;
  draftModelTypes: ModelTypeDraft[];
  rowModesModel: GridRowModesModel;
  setDraftModelTypes: React.Dispatch<React.SetStateAction<Array<ModelType>>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();
  const commonMutationOptions = React.useMemo(() => ({ onError: () => setMutatingRowId(undefined) }), []);

  const queryClient = useQueryClient();

  const createModelTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (newModelType) => {
      queryClient.invalidateQueries(['modelTypes']);
      queryClient.setQueryData(['modelTypes'], (oldData: ModelType[]) => [...oldData, newModelType]);
    },
    mutationFn: createModelTypeOption,
    mutationDescription: 'create model type',
  });

  const updateModelTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['modelTypes']);
      queryClient.setQueryData(['modelTypes'], (oldData: ModelType[]) => {
        return [...reject(oldData, { id: mutatingRowId }), variables];
      });
    },
    mutationFn: updateModelTypeOption,
    mutationDescription: 'update model type',
  });

  const deleteModelTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess(data, variables) {
      queryClient.invalidateQueries(['modelTypes']);
      queryClient.setQueryData(['modelTypes'], (oldData: ModelType[]) => reject(oldData, { id: variables }));
    },
    mutationFn: deleteModelTypeOption,
    mutationDescription: 'delete model type',
  });

  const columns = useCrudControlsColumns<ModelType, ModelTypeDraft>({
    createMutation: createModelTypeMutation,
    deleteMutation: deleteModelTypeMutation,
    updateMutation: updateModelTypeMutation,
    apiRef,
    rowModesModel,
    setRowModesModel,
    setDraftRows: setDraftModelTypes,
    draftRows: draftModelTypes,
    currentRows: modelTypes,
    rowTypeFields: modelTypeFields,
    getRowError: getModelTypeError,
    noRows,
    getCancelEditConfirmationModalOptions: ({ id, newRowValue, isDraft, changes }) => ({
      title: `Cancel model type ${isDraft ? 'creation' : 'update'}`,
      text: (
        <ModelTypeRowChangesSummary
          modelTypeDisplayString={`Model Type "${newRowValue?.displayName}" (${newRowValue?.id ?? id})`}
          changes={changes}
        />
      ),
    }),
    getSaveConfirmationModalOptions: ({ id, newRowValue, isDraft, changes }) => ({
      title: `${isDraft ? 'Create' : 'Update'} model type`,
      text: (
        <ModelTypeRowChangesSummary
          modelTypeDisplayString={`Model Type "${newRowValue?.displayName}" (${newRowValue?.id ?? id})`}
          changes={changes}
        />
      ),
    }),
    getDeleteConfirmationModalOptions: () => ({
      title: 'Delete Model Type',
      text: 'Are you sure you want to delete this model type?',
    }),
    idGetter: getModelTypeId,
    draftIdField: 'draftId',
    mutatingRowId,
    setMutatingRowId,
  });

  return columns;
};
