import { Tooltip } from '@mui/material';
import { ConditionalTextEditor } from 'components/atoms/DataGridRenderers/ConditionalTextEditor';
import { AreaType } from 'interfaces/areaType';
import { DisplayedField } from 'interfaces/genericFields';
import { map } from 'lodash';
import React from 'react';

const idRegex = /^[a-z\d/]+(?:[-_][a-z\d/]+)*$/;

export const idField: DisplayedField<AreaType & { draftId?: string }, string> = {
  cellEditType: 'text',
  filterType: 'text',
  dataKey: 'id',
  label: 'Area Type ID',
  columnWidth: { width: 200 },
  getError: ({ value }) =>
    !idRegex.test(value)
      ? 'Area type id must be lowercase and contain only letters, numbers. ' +
        'Hyphens, and underscores are allowed as separators.'
      : undefined,
  customCellEditor:
    (field, context) =>
    ({ isEditable: isEditableParam, ...params }) => {
      const { value, row } = params;
      const isEditable = (area: AreaType & { draftId?: string }) => Boolean(isEditableParam && area.draftId);
      const error = field.getError?.({ context, value, row });
      const showError = isEditable(row) && Boolean(error);
      return (
        <Tooltip open={Boolean(showError)} color="error" title={showError ? error : undefined}>
          <ConditionalTextEditor {...params} isEditable={isEditable} />
        </Tooltip>
      );
    },
};

export const displayNameField: DisplayedField<AreaType, string> = {
  cellEditType: 'text',
  filterType: 'text',
  dataKey: 'displayName',
  label: 'Display Name',
  columnWidth: { width: 200 },
};

export const postProcessedField: DisplayedField<AreaType, boolean> = {
  cellEditType: 'checkbox',
  filterType: 'checkbox',
  dataKey: 'postprocessed',
  label: 'Post Processed',
  columnWidth: { width: 200 },
};

export const sourceTypeField: DisplayedField<AreaType, string, { sourceTypes: string[] }> = {
  cellEditType: 'select',
  filterType: 'select',
  optionsGetter: (context) => {
    return map(context?.sourceTypes, (value) => ({
      value,
      label: value,
    }));
  },
  dataKey: 'sourceType',
  label: 'Source Type',
  columnWidth: { width: 150 },
};

export const indexField: DisplayedField<AreaType, number> = {
  filterType: 'multiSelect',
  dataKey: 'index',
  label: 'Index',
  columnWidth: { width: 100 },
};

export const areaTypeFields: Array<DisplayedField<AreaType, any>> = [
  idField,
  displayNameField,
  sourceTypeField,
  postProcessedField,
  indexField,
];
