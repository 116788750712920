import { useQuery } from '@tanstack/react-query';
import { searchSlides } from 'api/slides';
import { useDeferredValue } from 'react';

const useSlideSearch = (searchQuery: string, enabled: boolean = true) => {
  const search = useDeferredValue(searchQuery);

  const query = useQuery({
    queryKey: ['slidesSearch', search],
    queryFn: async ({ signal }) => searchSlides(search, signal),
    enabled: enabled && search !== '',
  });

  return query;
};

export default useSlideSearch;
