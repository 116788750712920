import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { logout } from 'redux/actions/auth';
import { useAppDispatch } from 'redux/hooks';
import { ResponseError } from 'superagent';

import { useErrorSnackbarWithRetry } from 'components/Snackbars/useErrorSnackbarWithRetry';

export interface MutationOptions<TData = unknown, TVariables = void, TContext = unknown>
  extends UseMutationOptions<TData, ResponseError, TVariables, TContext> {
  mutationDescription: string;
  errorMessageGetter?: (error: ResponseError) => string;
  doNotLogoutOnUnauthorized?: boolean;
  showRetryButton?: boolean;
}

export const useMutationWithErrorSnackbar = <TData = unknown, TVariables = void, TContext = unknown>({
  showRetryButton,
  mutationDescription,
  errorMessageGetter = (error: ResponseError) => error?.response?.body?.message || error?.message,
  doNotLogoutOnUnauthorized,
  ...options
}: MutationOptions<TData, TVariables, TContext>): ReturnType<
  typeof useMutation<TData, ResponseError, TVariables, TContext>
> => {
  const dispatch = useAppDispatch();
  const { enqueueErrorSnackbar } = useErrorSnackbarWithRetry({ errorMessageGetter });

  const { mutate, mutateAsync, ...returnValue } = useMutation<TData, ResponseError, TVariables, TContext>({
    ...options,
    onError: (error, variables, context) => {
      if (options.onError) {
        options.onError(error, variables, context);
      }
      const message = errorMessageGetter(error);
      if (error.status === 500 || error.status === undefined) {
        console.error('API error', error, message);
      }
      if (error.status === 401 && !doNotLogoutOnUnauthorized) {
        console.warn('user unauthorized');
        dispatch(logout());
      }
      enqueueErrorSnackbar(error, mutationDescription, showRetryButton ? () => mutate(variables) : undefined);
    },
  });

  return { ...returnValue, mutate, mutateAsync };
};
