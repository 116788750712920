import browserStorage from 'store';

const nucleaiStatePrefix = 'nucleaiState';

const nucleaiPrefix = (field: LocalStorageFields) => `${nucleaiStatePrefix}.${field}`;

export enum LocalStorageFields {
  Preferences = 'preferences',
}

export const loadState = (stateField: LocalStorageFields) => {
  try {
    const serializedState = browserStorage.get(nucleaiPrefix(stateField));
    if (!serializedState) {
      return undefined;
    }
    const jsonState = { [stateField]: JSON.parse(serializedState) };
    return jsonState;
  } catch (err) {
    console.error('load state from local storage error', err);
    return undefined;
  }
};

export const saveState = (stateValue: any, stateField: LocalStorageFields) => {
  try {
    const serializedState = JSON.stringify(stateValue[stateField]);
    browserStorage.set(nucleaiPrefix(stateField), serializedState);
  } catch (err) {
    console.error('save state to local storage error', err);
  }
};
