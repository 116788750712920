import { ResponseContext, ReviewForm } from 'interfaces/reviewForm';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getForms = (params: ResponseContext, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<{ forms: ReviewForm[] }>({
    url: `forms?${stringify(params)}`,
    method: 'GET',
    signal,
  });
};

export const getFormById = (formId: string, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<ReviewForm>({
    url: `forms/${formId}`,
    method: 'GET',
    signal,
  });
};

export const createForm = (form: ReviewForm, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<ReviewForm>({
    url: `forms`,
    method: 'POST',
    signal,
    data: JSON.stringify(form),
  });
};
