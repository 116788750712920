import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Slide } from 'interfaces/slide';
import { isEmpty, map } from 'lodash';
import React, { FunctionComponent } from 'react';

interface StudyManifestConfirmationDialogProps {
  open: boolean;
  overrideCases: boolean;
  slidesMovedBetweenCases: (Slide & {
    newCaseLabel: string;
    oldCaseLabel: string;
  })[];
  emptyCases: string[];
  onClose: () => void;
  onExecuteManifest: ({ overrideStudy }?: { overrideStudy?: boolean }) => void;
}

export const StudyManifestConfirmationDialog: FunctionComponent<
  React.PropsWithChildren<StudyManifestConfirmationDialogProps>
> = ({ open, overrideCases, slidesMovedBetweenCases, emptyCases, onClose, onExecuteManifest }) => {
  const hasSlidesThatMovedBetweenCases = !isEmpty(slidesMovedBetweenCases);
  const hasEmptyCases = !isEmpty(emptyCases);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Run Manifest</DialogTitle>
      <DialogContent>
        {hasSlidesThatMovedBetweenCases && (
          <Box>
            <DialogContentText>{`Some slides will be moved from one case to another:`}</DialogContentText>
            <DataGrid
              rows={slidesMovedBetweenCases}
              columns={[
                { field: 'originalFileName', headerName: 'Slide Name', flex: 1 },
                { field: 'newCaseLabel', headerName: 'New Case Label', flex: 1 },
                { field: 'oldCaseLabel', headerName: 'Old Case Label', flex: 1 },
              ]}
              autoHeight
            />
          </Box>
        )}
        {hasEmptyCases && (
          <Box>
            <DialogContentText>{`Some cases remained empty and will be deleted:`}</DialogContentText>
            <List>
              {map(emptyCases, (caseLabel) => (
                <ListItem key={caseLabel}>
                  <ListItemText primary={caseLabel} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        <DialogContentText>
          {overrideCases
            ? `You are on OVERRIDE MODE.
             Are you sure you want to override the existing cases? It means that only the slides that were in this manifest will attach to the cases,
             You can choose to only override the current cases, meaning that the rest of the study's cases that were not in the manifest will still exist,
             or override whole study, meaning cases that was not in this manifest will be deleted.`
            : `This action will create new cases / add slides to existing cases, and update their metadata, are you sure?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          Cancel
        </Button>
        {overrideCases ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                onClose();
                onExecuteManifest({ overrideStudy: true });
              }}
            >
              Override Whole Study
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                onClose();
                onExecuteManifest();
              }}
            >
              Override Only Current Cases
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              onClose();
              onExecuteManifest();
            }}
          >
            Run Manifest
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
