import { CircularProgress, Grid } from '@mui/material';
import React from 'react';

interface LoaderProps {
  size?: 'big' | 'small';
}

const Loader: React.FunctionComponent<React.PropsWithChildren<LoaderProps>> = ({ size = 'big' }) => (
  <Grid container direction="column" sx={{ width: '100%', height: '100%' }} alignItems="center" justifyContent="center">
    <Grid item>
      <CircularProgress size={size == 'big' ? 60 : 16} />
    </Grid>
  </Grid>
);

export default Loader;
