import {
  SecondaryAnalysisEditType,
  secondaryAnalysisInclusionModes,
  secondaryAnalysisPolygons,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/SecondaryAnalysisLayer/useSecondaryAnalysisLayer';
import { useSecondaryAnalysisOrchestrationIdQueryParams } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/SecondaryAnalysisLayer/useSecondaryAnalysisQueryParams';
import { compact, flatMap, forEach, fromPairs, includes, isEmpty, map, some } from 'lodash';
import { useCallback } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ImageAspectRatioIcon from '@mui/icons-material/ImageAspectRatio';
import ImageAspectRatioTwoToneIcon from '@mui/icons-material/ImageAspectRatioTwoTone';
import PolylineIcon from '@mui/icons-material/Polyline';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import { useSignals } from '@preact/signals-react/runtime';
import { SlideInteractionMenuOptions } from './SlideInteractionOption';

export const secondaryAnalysisOptionsWithoutPolygons: SlideInteractionMenuOptions[] = [
  {
    editType: SecondaryAnalysisEditType.IncludeRoi,
    title: 'Include ROI',
    icon: ImageAspectRatioIcon,
    isDefault: true,
  },
  {
    editType: SecondaryAnalysisEditType.ExcludeRoi,
    title: 'Exclude ROI',
    icon: ImageAspectRatioTwoToneIcon,
    sx: { transform: 'scale(-100%, 100%)' },
  },
  {
    editType: SecondaryAnalysisEditType.IncludePolygon,
    title: 'Include Polygon',
    icon: PolylineIcon,
  },
  {
    editType: SecondaryAnalysisEditType.ExcludePolygon,
    title: 'Exclude Polygon',
    icon: PolylineOutlinedIcon,
  },
];

export const secondaryAnalysisOptionsWhenIncluding: SlideInteractionMenuOptions[] = [
  {
    editType: SecondaryAnalysisEditType.DeletePolygon,
    title: 'Delete Individual Polygon / ROI',
    icon: DeleteOutlineIcon,
  },
  {
    editType: SecondaryAnalysisEditType.ModifyPolygon,
    title: 'Modify Polygon / ROI',
    icon: DriveFileRenameOutlineIcon,
  },
  {
    editType: SecondaryAnalysisEditType.IncludeRoi,
    title: 'Include ROI',
    icon: ImageAspectRatioIcon,
    isDefault: true,
  },
  {
    editType: SecondaryAnalysisEditType.CutRoi,
    title: 'Remove ROI from Current Selection',
    icon: ImageAspectRatioTwoToneIcon,
    sx: { transform: 'scale(-100%, 100%)' },
  },
  {
    editType: SecondaryAnalysisEditType.IncludePolygon,
    title: 'Include Polygon',
    icon: PolylineIcon,
  },
  {
    editType: SecondaryAnalysisEditType.CutPolygon,
    title: 'Remove Polygon from Current Selection',
    icon: PolylineOutlinedIcon,
  },
];

export const secondaryAnalysisOptionsWhenExcluding: SlideInteractionMenuOptions[] = [
  {
    editType: SecondaryAnalysisEditType.DeletePolygon,
    title: 'Delete Individual Polygon / ROI',
    icon: DeleteOutlineIcon,
  },
  {
    editType: SecondaryAnalysisEditType.ModifyPolygon,
    title: 'Modify Polygon / ROI',
    icon: DriveFileRenameOutlineIcon,
  },
  {
    editType: SecondaryAnalysisEditType.CutRoi,
    title: 'Remove ROI from Current Selection',
    icon: ImageAspectRatioIcon,
    sx: { transform: 'scale(-100%, 100%)' },
  },
  {
    editType: SecondaryAnalysisEditType.ExcludeRoi,
    title: 'Exclude ROI',
    icon: ImageAspectRatioTwoToneIcon,
    sx: { transform: 'scale(-100%, 100%)' },
  },
  {
    editType: SecondaryAnalysisEditType.CutPolygon,
    title: 'Remove Polygon from Current Selection',
    icon: PolylineIcon,
  },
  {
    editType: SecondaryAnalysisEditType.ExcludePolygon,
    title: 'Exclude Polygon',
    icon: PolylineOutlinedIcon,
  },
];

export const useClearSecondaryAnalysisSelections = () => {
  useSignals();
  // Check if any of the viewers is currently performing a secondary analysis
  const { secondaryAnalysisQueryKeys, setSecondaryAnalysisQueryParams } =
    useSecondaryAnalysisOrchestrationIdQueryParams();
  const [, setSecondaryAnalysisAreaSelectionMode] = useQueryParam('secondaryAnalysisAreaSelectionMode', StringParam);

  const clearSecondaryAnalysisSelections = useCallback(() => {
    setSecondaryAnalysisQueryParams(fromPairs(map(secondaryAnalysisQueryKeys, (key) => [key, null])));
    forEach(secondaryAnalysisPolygons, (secondaryAnalysisSignal) => {
      secondaryAnalysisSignal.value = null;
    });
    setSecondaryAnalysisAreaSelectionMode(null);
  }, [setSecondaryAnalysisQueryParams, setSecondaryAnalysisAreaSelectionMode]);

  return clearSecondaryAnalysisSelections;
};

export const useCurrentSecondaryAnalysisOptions = () => {
  useSignals();
  const secondaryAnalysisFeatures = compact(
    flatMap(secondaryAnalysisPolygons, (secondaryAnalysisPolygon) => secondaryAnalysisPolygon.value?.features)
  );

  const noSecondaryAnalysisSelections = isEmpty(secondaryAnalysisFeatures);

  const secondaryAnalysisHasInclusion = some(secondaryAnalysisFeatures, (feature) =>
    includes(secondaryAnalysisInclusionModes, feature?.properties?.featureType)
  );
  const secondaryAnalysisOptions = noSecondaryAnalysisSelections
    ? secondaryAnalysisOptionsWithoutPolygons
    : secondaryAnalysisHasInclusion
    ? secondaryAnalysisOptionsWhenIncluding
    : secondaryAnalysisOptionsWhenExcluding;
  return secondaryAnalysisOptions;
};
