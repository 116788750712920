import React from 'react';

import { RowsChangesSummary } from 'components/atoms/EditableDataGrid/TableEditingContext/ChangesSummary';
import { ClusterType } from 'interfaces/clusterType';
import { clusterTypeFields } from 'interfaces/clusterType/clusterTypeFields';
import { find } from 'lodash';

export const ClusterTypeRowChangesSummary: React.FC<
  React.PropsWithChildren<{
    clusterTypeDisplayString?: string;
    changes: Partial<ClusterType>;
  }>
> = ({ changes, clusterTypeDisplayString }) => {
  return (
    <RowsChangesSummary
      rowsChanges={{ changes }}
      rowToDisplayString={() => clusterTypeDisplayString || `Cluster type "${changes?.displayName}"`}
      fieldToDisplayString={(fieldId) => find(clusterTypeFields, (f) => f.dataKey === fieldId)?.label || fieldId}
      valueToDisplayString={(value, fieldId) => {
        const field = find(clusterTypeFields, (f) => f.dataKey === fieldId);
        return field?.valueFormatter?.({ value }) ?? `${value}`;
      }}
    />
  );
};
