import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';

export const ErrorMessage = () => {
  const theme = useTheme();

  return (
    <Grid alignSelf="center">
      <Typography variant="h1" color={theme.palette.primary.main} lineHeight="60px">
        Something went wrong, please try again later or contact support
      </Typography>
    </Grid>
  );
};
