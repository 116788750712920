import { filter, includes, map } from 'lodash';

import { FormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { findFormatterByKey, getFeatureDisplayName } from '.';

// Get the keys of the features and return an object with the key, displayName, and nameOverride
// Context is passed explicitly to support web workers
export const getFeaturesKeysObj = (
  featuresKeys: string[],
  context: FormatBracketsOptions,
  stainIndexFilter?: string
) => {
  const filteredFeatureKeys = stainIndexFilter
    ? filter(featuresKeys, (key) => includes(key, `<s:${stainIndexFilter}>`))
    : featuresKeys;

  const result = map(filteredFeatureKeys, (featureKey: string) => {
    const featureDisplayName = getFeatureDisplayName(featureKey, context);

    const formatter = findFormatterByKey(featureKey, { featureDisplayName, context });

    return {
      key: featureKey,
      displayName: featureDisplayName,
      nameOverride: formatter?.nameOverride,
    };
  });

  return result;
};
