import { BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { first, flatMap, isEmpty, keys, map, uniq } from 'lodash';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import { PartialCohort } from 'interfaces/cohort_old';
import { useProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { displayProcedureFieldValueName, NULL_VALUE_DISPLAY_NAME } from '../../../utils/helpers';
import { CategoricalChartKey, CountBy, getGroupByCount } from '../chart.util';
import { categoricalHistogramChartOptions, cohortToCategoricalHistogramDataset } from './histogram.util';
import { NoDataChartPlaceholder } from './NoDataChartPlaceholder';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
interface Props {
  cohorts: PartialCohort[];
  horizontalKey: CategoricalChartKey;
  countBy?: CountBy;
}

const CategoricalBarChart: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  cohorts,
  horizontalKey,
  countBy,
}) => {
  const procedureFieldContext = useProceduresFieldsContext();

  const displayFieldName = displayProcedureFieldValueName(procedureFieldContext);

  const labels: string[] = uniq(
    flatMap(cohorts, (cohort) => keys(getGroupByCount(horizontalKey, cohort.procedures, countBy)))
  );

  const displayLabels = map(labels, (label) => displayFieldName(horizontalKey.name, label));

  const cohortToDataset = (cohort: any, index: number) =>
    cohortToCategoricalHistogramDataset(horizontalKey, labels, countBy, index, cohort);

  const datasets = map(cohorts, (cohort: any, index: number) => cohortToDataset(cohort, index));

  const data = { labels: displayLabels, datasets };

  if (!isEmpty(displayLabels) && displayLabels.length === 1 && first(displayLabels) === NULL_VALUE_DISPLAY_NAME) {
    return <NoDataChartPlaceholder />;
  }

  return (
    <Bar style={{ minHeight: 120, maxHeight: 300 }} data={data} options={categoricalHistogramChartOptions(countBy)} />
  );
};

export default CategoricalBarChart;
