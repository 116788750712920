import { useQuery } from '@tanstack/react-query';
import {
  getProcedureSlidePinComments,
  getProcedureSlidePinCommentsUrl,
  procedureSlidePinCommentsQueryKey,
} from 'api/procedureSlidePinComments';
import Pin from 'interfaces/pin';
import { filter, map } from 'lodash';
import { Viewer } from 'openseadragon';
import React, { useState } from 'react';
import apiRequestHandler from 'utils/apiRequestHandler';
import { SlidePinComments } from './SlidePinComments';

interface Props {
  viewer: Viewer;
  procedureId: number;
  slideId: string;
}

const setDraftFlag = (pin: Pin) => {
  pin.draft = false;
  return pin;
};

const PinCommentsHandler: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  procedureId,
  slideId,
  viewer,
}) => {
  const [draftPins, setDraftPins] = useState([]);
  const pinUrl = getProcedureSlidePinCommentsUrl(procedureId, slideId);
  const { data: result } = useQuery(procedureSlidePinCommentsQueryKey(procedureId, slideId), {
    queryFn: ({ signal }) => getProcedureSlidePinComments({ procedureId, slideId, signal }),
  });
  const pins = React.useMemo(() => [...map(result?.pins, setDraftFlag), ...draftPins], [result?.pins]);

  const handleAddPin = (pin: Pin) => {
    const jsonPin = JSON.parse(JSON.stringify(pin));
    apiRequestHandler({
      url: pinUrl,
      onSuccess: (_) => {
        handleAddPinToView(setDraftFlag(pin));
      },
      onFailure: (error: any) => {
        console.error('Got an error creating pin', error);
      },
      data: jsonPin,
      method: 'POST',
    });
  };

  const handleAddPinToView = (pin: Pin) => {
    setDraftPins([...draftPins, pin]);
  };

  const handleRemovePin = (pin: Pin) => {
    apiRequestHandler({
      url: `${pinUrl}/${pin.id}`,
      onSuccess: (_) => {
        handleRemovePinFromView(pin);
      },
      onFailure: (error: any) => {
        console.error('Got an error deleting pin', error);
      },
      method: 'DELETE',
    });
  };

  const handleRemovePinFromView = (pin: Pin) => {
    const newPins = filter([...draftPins], (existingPin: Pin) => existingPin.id !== pin.id);
    setDraftPins(newPins);
  };

  const handleSavePin = (pin: Pin) => {
    const newPins = [...draftPins];
    const savedPinIndex = newPins.findIndex((existingPin) => existingPin.id === pin.id);
    newPins[savedPinIndex] = pin;
    const jsonPin = JSON.parse(JSON.stringify(pin));
    apiRequestHandler({
      url: `${pinUrl}/${pin.id}`,
      onSuccess: (_) => {
        newPins[savedPinIndex] = setDraftFlag(newPins[savedPinIndex]);
        setDraftPins(newPins);
      },
      onFailure: (error: any) => {
        console.error('Got an error updating pin', error);
      },
      data: jsonPin,
      method: 'PUT',
    });
  };

  return (
    <SlidePinComments
      key={slideId}
      viewer={viewer}
      pins={pins}
      onAddPin={handleAddPin}
      onAddPinToView={handleAddPinToView}
      onRemovePin={handleRemovePin}
      onRemovePinFromView={handleRemovePinFromView}
      onSavePin={handleSavePin}
    />
  );
};

export default PinCommentsHandler;
