import React from 'react';
import ModalWrapper from 'components/ModalWrapper';
import { DialogContent, DialogTitle } from '@mui/material';

const BroswerNotSupported: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => (
  <ModalWrapper onClose={null} isOpen>
    <div className="browser-message">
      <DialogTitle variant="h1">This browser is not supported</DialogTitle>
      <DialogContent>
        <p>We only support up-to-date versions of Google Chrome, Microsoft Edge, and Firefox browsers.</p>
        <p>Please try updating your browser to the latest version.</p>
      </DialogContent>
    </div>
  </ModalWrapper>
);

export default BroswerNotSupported;
