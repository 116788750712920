import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import HelpIcon from '@mui/icons-material/Help';
import PolicyIcon from '@mui/icons-material/Policy';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import { Grid, IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

interface Window {
  zE: any;
}

declare const window: Window;

const SupportMenu = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openZendesk = () => {
    if (window.zE('webWidget:get', 'display') === 'hidden') {
      window.zE.activate();
    } else {
      window.zE.hide();
    }
  };

  return (
    <>
      <IconButton onClick={handleClick} color="inherit">
        <HelpIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ '& .MuiMenu-paper': { minWidth: 180 } }}>
        <MenuItem onClick={() => navigate('/privacy-policy', { state: { fromExternalLink: false } })}>
          <Grid container spacing={2}>
            <Grid item>
              <PrivacyTipIcon />
            </Grid>
            <Grid item>Privacy Policy</Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={() => navigate('/terms-and-conditions', { state: { fromExternalLink: false } })}>
          <Grid container spacing={2}>
            <Grid item>
              <PolicyIcon />
            </Grid>
            <Grid item>Terms of Use</Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={openZendesk}>
          <Grid container spacing={2}>
            <Grid item>
              <AlternateEmailIcon />
            </Grid>
            <Grid item>Contact Us</Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SupportMenu;
