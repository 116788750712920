import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import Loader from 'components/Loader';
import { TaxonomyTree } from 'interfaces/taxonomy';
import React, { useState } from 'react';
import { useCreateTaxonomyMutation } from 'utils/queryHooks/taxonomy/useTaxonomyMutations';

interface AddTaxonomyButtonProps {
  taxonomyTree: TaxonomyTree;
}

const AddTaxonomyButton: React.FC<AddTaxonomyButtonProps> = ({ taxonomyTree }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [subpath, setSubpath] = useState('');
  const [isAbstract, setIsAbstract] = useState(false);
  const [name, setName] = useState('');

  const path = `${taxonomyTree.path}.${subpath}`;

  const mutation = useCreateTaxonomyMutation();

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleAddTaxonomy = () => {
    const newTaxonomy = {
      path,
      name,
      isAbstract,
    };

    mutation.mutate(newTaxonomy, {
      onSuccess: () => {
        setDialogOpen(false);
      },
    });
  };

  return (
    <>
      <IconButton aria-label="Add" onClick={handleOpenDialog} color="primary">
        <AddIcon />
      </IconButton>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Add Taxonomy</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} direction="column">
            <Grid item container alignItems="center">
              <Grid item>
                <Typography variant="body1">{taxonomyTree.path}.</Typography>
              </Grid>
              <Grid item>
                <FormControl>
                  <TextField
                    label="Subpath"
                    id="subpath-input"
                    value={subpath}
                    size="small"
                    onChange={(e) => setSubpath(e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>
              <FormControl>
                <TextField
                  label="Name"
                  id="name-input"
                  value={name}
                  size="small"
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <FormLabel>is abstract?</FormLabel>
                <Checkbox checked={isAbstract} onChange={(e) => setIsAbstract(e.target.checked)} />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleAddTaxonomy} variant="contained" color="primary">
            Add{mutation.isLoading && <Loader size="small" />}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddTaxonomyButton;
