import { ChartOptions } from 'chart.js';
import { CohortWithSelectedFeatures } from 'interfaces/cohort_old';
import { curry, map } from 'lodash';
import { CategoricalChartKey, CountBy, getDataLabelsOptions, getGroupByCount, getLegendOptions } from '../chart.util';
import { borderColorPalette, colorPalette } from '../chartsColorPallete';

export const cohortToPieDataset = curry(
  (
    categoricalKey: CategoricalChartKey,
    labels: string[],
    countBy: CountBy,
    index: number,
    cohort: CohortWithSelectedFeatures
  ) => {
    const groupByCount: Record<string, number> = getGroupByCount(categoricalKey, cohort.procedures, countBy);
    const data: number[] = labels.map((label) => groupByCount[label] || 0);
    const backgroundColor: string[] = map(
      labels,
      (label, labelIndex) => colorPalette[(index + labelIndex) % colorPalette.length]
    );
    const borderColor: string[] = map(
      labels,
      (label, labelIndex) => borderColorPalette[(index + labelIndex) % borderColorPalette.length]
    );

    return {
      label: cohort.name,
      data,
      backgroundColor: backgroundColor,
      borderColor: borderColor,
    };
  }
);

export const pieChartOptionsForSmallChart = (): ChartOptions<'doughnut'> => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    cutout: '70%',
    plugins: {
      legend: getLegendOptions('right'),
      datalabels: getDataLabelsOptions(false),
    },
  };
};

export const defaultPieChartOptions = (): ChartOptions<'doughnut'> => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    cutout: '50%',
    plugins: {
      legend: getLegendOptions('bottom'),
      datalabels: getDataLabelsOptions(true),
      tooltip: getTooltipOption(),
    },
  };
};

const getTooltipOption = () => ({
  callbacks: {
    label: (context: any) => {
      const datasetLabel = context.dataset.label || '';
      const value = context.formattedValue;

      return `${datasetLabel} - ${value}`;
    },
  },
});
