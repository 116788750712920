import React from 'react';

import { RowsChangesSummary } from 'components/atoms/EditableDataGrid/TableEditingContext/ChangesSummary';
import { ModelType } from 'interfaces/modelType';
import { modelTypeFields } from 'interfaces/modelType/modelTypeFields';
import { find } from 'lodash';

export const ModelTypeRowChangesSummary: React.FC<
  React.PropsWithChildren<{
    modelTypeDisplayString?: string;
    changes: Partial<ModelType>;
  }>
> = ({ changes, modelTypeDisplayString }) => {
  return (
    <RowsChangesSummary
      rowsChanges={{ changes }}
      rowToDisplayString={() => modelTypeDisplayString || `Model type "${changes?.displayName}"`}
      fieldToDisplayString={(fieldId) => find(modelTypeFields, (f) => f.dataKey === fieldId)?.label || fieldId}
      valueToDisplayString={(value, fieldId) => {
        const field = find(modelTypeFields, (f) => f.dataKey === fieldId);
        return field?.valueFormatter?.({ value }) ?? `${value}`;
      }}
    />
  );
};
