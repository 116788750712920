import { Grid, TextField } from '@mui/material';
import React from 'react';
import AddRowButton from './AddRowButton';

export interface GridActionToolbarProps {
  addText: string;
  handleAdd: React.MouseEventHandler<HTMLButtonElement>;
  localSearch: string;
  setLocalSearch: (search: string) => void;
}

export const GridActionToolbar: React.FunctionComponent<React.PropsWithChildren<GridActionToolbarProps>> = ({
  addText,
  handleAdd,
  localSearch,
  setLocalSearch,
}) => {
  return (
    <Grid container spacing={2} p={2} justifyContent="space-between">
      <Grid item md={4}>
        <TextField
          fullWidth
          size="small"
          label="Search"
          value={localSearch}
          onChange={(e) => setLocalSearch(e.target.value)}
        />
      </Grid>
      <Grid item>
        <AddRowButton addText={addText} handleAdd={handleAdd} />
      </Grid>
    </Grid>
  );
};
