import { CompositeMode, DrawPointMode, DrawPolygonByDraggingMode, DrawRectangleMode } from 'nebula.gl';

import { ModifyOrthographicMode } from './ModifyOrthographicMode';

class AnnotationCompositeMode extends CompositeMode {
  handleClick(clickEvent: any, props: any): void {
    if (clickEvent?.sourceEvent?.button !== 0) {
      return;
    }
    super.handleClick(clickEvent, props);
  }
}

// This class is without modify mode because according to this line writing-
// there is a bug modifying polygon while also in dragging mode.
// Sometimes it modifies and creates a new polygon at the same time
export class AnnotationPolygonByDraggingMode extends DrawPolygonByDraggingMode {
  handleClick(clickEvent: any, props: any): void {
    if (clickEvent?.sourceEvent?.button !== 0) {
      return;
    }
    super.handleClick(clickEvent, props);
  }
}

export class AnnotationRectangleMode extends AnnotationCompositeMode {
  constructor() {
    super([new DrawRectangleMode(), new ModifyOrthographicMode()]);
  }
}

export class AnnotationPointMode extends AnnotationCompositeMode {
  constructor() {
    super([new DrawPointMode(), new ModifyOrthographicMode()]);
  }
}
