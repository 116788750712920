import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { IconButton } from '@mui/material';
import React from 'react';

import { BooleanParam, useQueryParam } from 'use-query-params';

export const ToggleFullScreen: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [fullscreenViewer, setFullScreenViewer] = useQueryParam('fullscreenViewer', BooleanParam);

  return (
    <IconButton onClick={() => setFullScreenViewer(true, 'replaceIn')} color="inherit" title="Full Screen">
      <FullscreenIcon />
    </IconButton>
  );
};
