import { Cohort } from 'interfaces/cohort';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getCohorts = (labId: string) => {
  return apiRequestHandlerPromise<Cohort[]>({
    url: `cohorts?${stringify({ labId })}`,
    method: 'GET',
  });
};

export const saveCohort = (cohort: Partial<Cohort>) => {
  return apiRequestHandlerPromise<Cohort>({
    url: 'cohorts',
    method: 'POST',
    data: JSON.stringify(cohort),
  });
};

export const deleteCohort = (cohortId: string) => {
  return apiRequestHandlerPromise({
    url: `cohorts/${cohortId}`,
    method: 'DELETE',
  });
};

export const updateCohort = (cohort: Partial<Cohort>) => {
  return apiRequestHandlerPromise({
    url: `cohorts/${cohort.id}`,
    method: 'PUT',
    data: JSON.stringify(cohort),
  });
};

// get cohort by id
export const getCohort = (queryParams: string) => {
  return apiRequestHandlerPromise({
    url: `cohorts/${queryParams}`,
    method: 'GET',
  });
};
