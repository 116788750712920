export interface Tagger {
  taggerId: number;
  name: string;
  authUserId: string;
}

export const TAGGERS = {
  ROLES: {
    TAGGER: 'tagger',
    SUPERTAGGER: 'supertagger',
    EXTERNAL_TAGGER: 'external_tagger',
    DEMO_ALGO_RESULTS: 'demo_algo_results',
  },
};
