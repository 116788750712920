import { Grid } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import React from 'react';

import ToggleableSlider from 'components/Procedure/ToggleableSlider/ToggleableSlider';
import {
  getSlideChannelColorSettingsSignal,
  slidesChannelToggles,
  useUpdateSlideChannelColorSettings,
} from '../colorSettings';

export const MultiplexOpacityToggle: React.FC<
  React.PropsWithChildren<{
    viewerIndex: number;
    slideId: string;
    channelId: string;
    selected: boolean;
    markerType?: string;
  }>
> = ({ viewerIndex, slideId, channelId, selected, markerType }) => {
  useSignals();
  const slideChannelSettingsSignal = getSlideChannelColorSettingsSignal(viewerIndex, slideId, channelId);
  const viewerSlidesChannelsToggles = slidesChannelToggles[viewerIndex];

  const { handleOpacityChange, handleShowChange } = useUpdateSlideChannelColorSettings();

  return (
    <Grid item md={4}>
      <ToggleableSlider
        sliderProps={{ color: 'secondary' }}
        value={selected ? slideChannelSettingsSignal?.value?.opacity ?? 0 : 0}
        disabled={!selected}
        checked={Boolean(selected && viewerSlidesChannelsToggles?.value?.[slideId]?.[channelId])}
        onToggleChange={() => {
          handleShowChange(viewerIndex, slideId, channelId, markerType);
        }}
        onValueChange={(value: number) => {
          handleOpacityChange(viewerIndex, slideId, channelId, value, markerType);
        }}
      />
    </Grid>
  );
};
