import { first, join, map } from 'lodash';
import React from 'react';
import useCellRulePanels from 'utils/queryHooks/cellRule/useCellRulePanels';
import { CellRulesPanel, CellRulesPanelSkeleton } from './CellRulesPanel';

const GLOBAL_PANEL_STUDY_ID = 'global';

export const GlobalCellRulesPanel: React.FC = () => {
  const { data: panels, isLoading: isLoadingPanels } = useCellRulePanels(GLOBAL_PANEL_STUDY_ID);

  if (panels && panels.length > 1) {
    console.warn(
      `More than one global panel found, panel ids: ${join(map(panels, 'id'), ',')}. taking the first one: ${
        first(panels)?.id
      }`
    );
  }
  const globalPanelId = first(panels)?.id;

  return isLoadingPanels ? (
    <CellRulesPanelSkeleton />
  ) : (
    <CellRulesPanel studyId={GLOBAL_PANEL_STUDY_ID} panelId={globalPanelId} />
  );
};
