import { filter, findIndex, includes, indexOf, isNumber, map, uniq } from 'lodash';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFilteredCaseSlideThumbnailsData } from 'components/Procedure/useFilteredCaseIds';
import { DEFAULT_PAGE_SIZE } from 'components/StudyDashboard/ProceduresPage/ProcedurePagination';
import { Accession } from 'interfaces/accession';
import { CaseSlideThumbnailData, Procedure } from 'interfaces/procedure';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useSelectedSlideIds } from 'utils/useCurrentSlideIds';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';

export interface ThumbnailSlidesState {
  isLoading: boolean; // Are any of the pages being loaded?
  changeImageIndex: (newIndex: number) => void; // Change the image index when the user clicks on the thumbnail
  currentImageIndex: number; // 0-indexed index of the current thumbnail image
  caseSlideThumbnailsData: CaseSlideThumbnailData[];
}

/**
 *
 * @param caseData a case or accession currently being viewed
 * @param selectedSlideIndex the index of the slide currently being viewed in the case
 * @returns state needed to display the thumbnails of the slides in the currently filtered cases
 */
export const useThumbnailSlides = (
  caseData: Procedure | Accession,
  selectedSlideId: string,
  {
    enabled = true,
  }: {
    enabled?: boolean;
  } = {}
): ThumbnailSlidesState => {
  const navigate = useNavigate();
  const [selectedSlideIds] = useSelectedSlideIds(caseData);

  const { queryParams } = useEncodedFilters({
    // To hit the cache for the cases in the paginated queries
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });
  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);

  // when on pending slides mode - get rid of the mock case id
  const currentCaseId: number | null = pendingSlidesMode ? null : caseData?.id;
  const { getUrlToCasePage, getUrlToSlidePage } = useNavigationToViewerPage();

  // Slide mode affects the pagination logic (per number of slides instead of per number of cases)
  const slidesMode = Boolean(queryParams?.slidesMode);

  const currentSlideId = selectedSlideId;

  // Get the filtered case slide arguments to generate thumbnails for the current study. Used in slide mode to determine the current page
  const { isInitialLoading: isLoading, data: allSlideThumbnailsData } = useFilteredCaseSlideThumbnailsData({
    studyId: caseData?.studyId,
    enabled,
  });

  const caseSlideThumbnailsData = useMemo(
    () => filter(allSlideThumbnailsData, ({ caseId }) => (pendingSlidesMode ? !isNumber(caseId) : isNumber(caseId))),
    [allSlideThumbnailsData, pendingSlidesMode]
  );

  // Find the index of the current thumbnail image by matching the case and slide ids
  const currentImageIndex = findIndex(
    caseSlideThumbnailsData,
    (slideThumbnailData) => slideThumbnailData.caseId === currentCaseId && slideThumbnailData.slideId === currentSlideId
  );

  const pageSize = queryParams?.pageSize || DEFAULT_PAGE_SIZE;

  // Change the thumbnail index and navigate to corresponding viewer page when the user clicks on the thumbnail
  const onThumbnailClick = React.useCallback(
    (newIndex: number) => {
      const newSlideThumbnailData = caseSlideThumbnailsData[newIndex];

      if (!newSlideThumbnailData) {
        console.warn(`could not find slide thumbnail data for index ${newIndex}`);
        return;
      }

      const newPage = caseSlideThumbnailsData
        ? Math.max(
            1,
            Math.floor(
              (slidesMode ? newIndex : indexOf(uniq(map(caseSlideThumbnailsData, 'caseId')), currentCaseId)) / pageSize
            ) + 1
          )
        : null;

      const newCaseId = newSlideThumbnailData.caseId;
      const newSlideId = newSlideThumbnailData.slideId;
      if (!pendingSlidesMode && !newCaseId) {
        console.warn('caseId is missing for slide', newSlideId, { newSlideThumbnailData });
        return;
      }
      const newUrl = slidesMode
        ? getUrlToSlidePage({
            slideId: newSlideId,
            selectedSlideIds:
              newCaseId === currentCaseId && includes(selectedSlideIds, newSlideId)
                ? [newSlideId, ...filter(selectedSlideIds, (slideId) => slideId !== currentSlideId)]
                : [newSlideId],
            caseId: pendingSlidesMode ? undefined : newCaseId, // caseId is optional because pending slide doesn't have a case
            newPage,
          })
        : getUrlToCasePage({
            caseId: newCaseId,
            selectedSlideIds: [newSlideId],
            newPage,
          });
      navigate(newUrl);
    },
    [
      navigate,
      getUrlToSlidePage,
      getUrlToCasePage,
      caseSlideThumbnailsData,
      currentCaseId,
      currentSlideId,
      selectedSlideIds,
      pendingSlidesMode,
      slidesMode,
      pageSize,
    ]
  );

  return {
    isLoading,
    changeImageIndex: onThumbnailClick,
    currentImageIndex,
    caseSlideThumbnailsData,
  };
};
