import { darken, lighten, useTheme } from '@mui/material';
import { DataGridProps } from '@mui/x-data-grid';

export const useCommonDataGridStyles = (): DataGridProps['sx'] => {
  const theme = useTheme();

  return {
    width: '100%',
    minHeight: '100%',
    // Mark required cells or duplicate cells with a red background
    '& .MuiDataGrid-row--editing .MuiDataGrid-cell.missing-required-cell, & .MuiDataGrid-row--editing .MuiDataGrid-cell.duplicate-cell, & .MuiDataGrid-row--editing .MuiDataGrid-cell.error-cell':
      {
        backgroundColor: 'rgba(255, 0, 0, 0.1)',
      },

    // Mark draft cells with a blue background
    '& .MuiDataGrid-row--editing .MuiDataGrid-cell.draft-cell': {
      backgroundColor: 'rgba(0, 200, 255, 0.1)',
    },

    // Mark deleted rows with a darker red background
    '& .deleted-row': {
      backgroundColor: theme.palette.mode === 'light' ? theme.palette.error.light : theme.palette.error.dark,
      transition: 'background-color 0.2s ease',
      '&:hover': {
        backgroundColor:
          theme.palette.mode == 'light'
            ? darken(theme.palette.error.light, 0.2)
            : lighten(theme.palette.error.dark, 0.2),
      },
    },

    // Mark header cells with a bold font and a grey background
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
      '& .MuiDataGrid-columnHeaderTitleContainerContent': {
        '&, &>.MuiDataGrid-columnHeaderTitle, & .MuiTypography-root': {
          fontWeight: 'bold',
        },
      },
    },
  };
};
