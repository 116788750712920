import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import { FieldWitCheckboxGroupFilter } from 'interfaces/genericFields';
import { filter, includes, map } from 'lodash';
import React from 'react';
import { useUiSettings } from 'utils/queryHooks/uiConstantsHooks';
import { QueryFieldProps } from '../QueryFieldInput';

const CheckboxGroup = (props: QueryFieldProps<FieldWitCheckboxGroupFilter<any>>) => {
  const { onChange, value = [], field } = props;
  const { enumType, options: manualOptions, filterType, dataKey } = field;

  const { uiSettings } = useUiSettings();

  const options = enumType ? uiSettings?.enumDisplayNames?.[enumType] || [] : manualOptions;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, itemKey: string | number) => {
    onChange(filterType, dataKey, checked ? [...value, itemKey] : filter(value, (key) => key !== itemKey));
  };

  return (
    <FormGroup>
      <Grid container sx={{ px: 1 }}>
        {map(options, (option) => {
          return (
            <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} alignItems="center" key={option.value}>
              <Grid item>
                <FormControlLabel
                  label={option.label}
                  control={
                    <Checkbox
                      checked={includes(value, option.value)}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                        handleChange(e, checked, option.value)
                      }
                    />
                  }
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </FormGroup>
  );
};

export default CheckboxGroup;
