import WarningIcon from '@mui/icons-material/Warning';
import { Grid, styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { Slide } from 'interfaces/slide';
import { find, map, some } from 'lodash';

import { useQuery } from '@tanstack/react-query';
import { fetchStudies } from 'api/study';
import { filtersDrawerWidth, overviewDrawerWidth } from 'components/atoms/FlexContainers/FlexContainers';
import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { CardMode } from '..';
import { useProcedurePageColumns } from '../../useProcedurePageColumns';
import SlideIdentifierBadge from './SlideIdentifierBadge';
import SlideThumbnailImage from './SlideThumbnailImage';
import SlideZoomBadge from './SlideZoomBadge';

const mainHorizontalPadding = 64;

const Label = styled(Box)(({ theme }) => ({
  padding: '3px 10px',
  float: 'left',
  margin: '10px',
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 3,
}));

const QCLabel = styled(Label)(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
}));

const QALabel = styled(Label)(() => ({
  backgroundColor: '#607188',
}));

const carouselHeightByColumnsAndSideComponents = (columns: number, usedWidth: number, gapSize: number = 3) => {
  const gapSizeInPx = gapSize * 8;
  const gapSizeStep = columns > 1 ? ` - (${columns - 1} * ${gapSizeInPx}px)` : '';
  const availableWidth = usedWidth > 0 ? `(100vw - ${usedWidth}px)` : '100vw';
  const heightCalculation = `calc(${availableWidth} / ${columns}${gapSizeStep})`;
  return {
    height: heightCalculation,
    maxHeight: heightCalculation,
  };
};

const useCarouselItemStyle = (slide: Slide) => {
  const theme = useTheme();

  const [advancedSearchDrawerOpen] = useQueryParam('advancedSearchDrawerOpen', BooleanParam);
  const [casesOverviewDrawerOpen] = useQueryParam('casesOverviewDrawerOpen', BooleanParam);

  const usedWidth =
    mainHorizontalPadding +
    (advancedSearchDrawerOpen ? filtersDrawerWidth : 0) +
    (casesOverviewDrawerOpen ? overviewDrawerWidth : 0);

  const { xs, sm, md, lg } = useProcedurePageColumns();

  return {
    flexShrink: 0,
    position: 'relative',
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('lg')]: carouselHeightByColumnsAndSideComponents(lg, usedWidth),
    [theme.breakpoints.between('md', 'lg')]: carouselHeightByColumnsAndSideComponents(md, usedWidth),
    [theme.breakpoints.between('sm', 'md')]: carouselHeightByColumnsAndSideComponents(sm, usedWidth),
    [theme.breakpoints.down('sm')]: carouselHeightByColumnsAndSideComponents(xs, usedWidth),
  };
};

interface Props {
  slide: Slide;
  active?: boolean;
  galleryView?: boolean;
  mode?: CardMode;
}

const SlideCarouselItem: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  slide,
  active,
  galleryView,
  mode = 'full',
}) => {
  const { queryParams } = useEncodedFilters();
  const { cheatMode, cheatStage } = useAppSelector((state) => state.cheatMode);
  const { labId } = useCurrentLabId();
  const { data: studies } = useQuery(['studies', labId], fetchStudies(labId));
  const currentStudy = find(studies, { id: queryParams?.filters?.studyId });

  const [hovered, setHovered] = React.useState(false);

  const carouselItemStyle = useCarouselItemStyle(slide);

  const hasUnusableTag = some(
    map(slide?.slideTagAssignments, 'slideMetadataTag'),
    (tag) => tag.tagValue === 'Unusable'
  );

  return (
    <Box
      className={`carousel-item${active ? ' active' : ''}`}
      data-testid="carousel-item"
      sx={carouselItemStyle}
      onMouseEnter={galleryView && active ? () => setHovered(true) : undefined}
      onMouseLeave={galleryView && active ? () => setHovered(false) : undefined}
    >
      <SlideThumbnailImage slide={slide} />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        {children}
      </Box>
      <Grid
        container
        sx={{ height: '100%', width: '100%', position: 'relative' }}
        justifyContent="center"
        alignItems="center"
      >
        {mode === 'full' && (
          <Grid item>
            {' '}
            <SlideIdentifierBadge absolutePositioning>{slide.id}</SlideIdentifierBadge>
          </Grid>
        )}
        {mode === 'full' && !(cheatMode.includes(currentStudy?.id) || cheatStage >= 3) && (
          <Grid item container direction="column" sx={{ height: '100%' }} justifyContent="flex-end">
            <Grid container item>
              {(slide.qcFailed || hasUnusableTag) && (
                <Grid item>
                  <QCLabel>
                    <WarningIcon />
                    QC
                  </QCLabel>
                </Grid>
              )}

              {find(slide.experimentResults, { qaFailed: true }) && (
                <Grid item>
                  <QALabel className="label qa">QA</QALabel>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}

        {galleryView && (
          <Grid item>
            <SlideZoomBadge hovered={active && hovered} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SlideCarouselItem;
