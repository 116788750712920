import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid } from '@mui/material';
import {
  ClassificationBinningParams,
  InferenceModelData,
  ModelInference,
  OrchestrationInference,
  SlideInferenceResults,
} from 'interfaces/calculateFeatures';
import { Dictionary, isEmpty, map } from 'lodash';
import React from 'react';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import { ModelClassificationById, OrchestrationBySlideByFlowClassName, OrchestrationBySlideByType } from '..';
import InferenceModel from './InferenceModel';

export interface InferenceStainProps {
  studyId: string;
  stain: string;
  stainModelInferences?: {
    [modelType: string]: {
      [modelUrl: string]: ModelInference;
    };
  };
  orchestrationByFlowClassName?: {
    [flowClassName: string]: OrchestrationInference[];
  };
  slides: Dictionary<SlideInferenceResults>;
  selectedOrchestrations?: OrchestrationBySlideByType;
  setSelectedOrchestrations?: (
    slideIds: string[],
    model: InferenceModelData,
    modelType: string,
    orchestration: OrchestrationInference
  ) => void;
  selectedPostprocessedOrchestrations?: OrchestrationBySlideByFlowClassName;
  setSelectedPostprocessedOrchestrations?: (
    slideIds: string[],
    flowClassName: string,
    orchestration: OrchestrationInference
  ) => void;
  setModelClassificationByModelId?: (modelId: string, classification: string) => void;
  modelClassificationByModelId?: ModelClassificationById;
  setSelectedOrchestrationsByClassificationType?: (modelType: string, previousModelType?: string) => void;
  setIntensityClassificationBinning?: (modelId: string, binning: ClassificationBinningParams) => void;
  defaultExpanded?: boolean;
}

const InferenceStain: React.FC<React.PropsWithChildren<InferenceStainProps>> = ({
  studyId,
  stain,
  stainModelInferences,
  orchestrationByFlowClassName,
  slides,
  selectedOrchestrations,
  setSelectedOrchestrations,
  selectedPostprocessedOrchestrations,
  setSelectedPostprocessedOrchestrations,
  modelClassificationByModelId,
  setModelClassificationByModelId,
  setSelectedOrchestrationsByClassificationType,
  setIntensityClassificationBinning,
  defaultExpanded = false,
}) => {
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  return (
    <Accordion disableGutters defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {isLoadingStainTypeOptions ? <CircularProgress /> : stainTypeIdToDisplayName(stain)}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          {!isEmpty(stainModelInferences)
            ? map(stainModelInferences, (modelInferences, modelType) => {
                return (
                  <Grid item xs={12} key={modelType}>
                    <InferenceModel
                      key={modelType}
                      stain={stain}
                      modelType={modelType}
                      modelInferences={modelInferences}
                      slides={slides}
                      selectedOrchestrations={selectedOrchestrations}
                      setSelectedOrchestrations={setSelectedOrchestrations}
                      studyId={studyId}
                      modelClassificationByModelId={modelClassificationByModelId}
                      setModelClassificationByModelId={setModelClassificationByModelId}
                      setSelectedOrchestrationsByClassificationType={setSelectedOrchestrationsByClassificationType}
                      setIntensityClassificationBinning={setIntensityClassificationBinning}
                    />
                  </Grid>
                );
              })
            : !isEmpty(orchestrationByFlowClassName)
            ? map(orchestrationByFlowClassName, (orchestrations, flowClassName) => {
                return (
                  <Grid item xs={12} key={flowClassName}>
                    <InferenceModel
                      key={flowClassName}
                      stain={stain}
                      modelType={flowClassName}
                      orchestrations={orchestrations}
                      slides={slides}
                      selectedPostprocessedOrchestrations={selectedPostprocessedOrchestrations}
                      setSelectedPostprocessedOrchestrations={setSelectedPostprocessedOrchestrations}
                      studyId={studyId}
                    />
                  </Grid>
                );
              })
            : null}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default InferenceStain;
