import React from 'react';
import './PrimaryButton.scss';

const PrimaryButton: React.FunctionComponent<
  React.PropsWithChildren<{
    caption: string;
    onClick(): void;
    className?: string;
  }>
> = ({ caption, onClick, className = '' }) => (
  <button type="button" className={`button--primary ${className}`} onClick={onClick}>
    {caption}
  </button>
);

export default PrimaryButton;
