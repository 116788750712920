import { Autocomplete, CircularProgress, FormControl, FormLabel, TextField } from '@mui/material';
import { FieldSourceOption, MultiSelectReviewField } from 'interfaces/reviewForm';
import { find, map } from 'lodash';
import React from 'react';
import { useFormSource } from 'utils/queryHooks/form/useFormSource';

type FormMultiSelectFieldProps = {
  field: MultiSelectReviewField;
  value: string[];
  onChange: (value: string[]) => void;
};

const FormMultiSelectField: React.FC<FormMultiSelectFieldProps> = ({ field, value, onChange }) => {
  const { data: source, isLoading } = useFormSource(field.options.source);

  const selectedFields = map(value, (val) => find(source?.options, (option) => option.id === val));

  return (
    <FormControl fullWidth>
      <FormLabel
        sx={{
          marginBottom: 1,
        }}
      >
        {field.label}
      </FormLabel>
      <Autocomplete
        fullWidth
        size="small"
        value={selectedFields ?? []}
        onChange={(_, newValue) => onChange(map(newValue, (val) => val.id))}
        multiple
        options={source?.options ?? []}
        getOptionLabel={(option) => (option as FieldSourceOption)?.label}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        loading={isLoading}
      />
    </FormControl>
  );
};

export default FormMultiSelectField;
