import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { cloneDeep, find, times } from 'lodash';
import { useMemo } from 'react';

import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { Annotation } from 'interfaces/annotation';
import { Permission } from 'interfaces/permissionOption';
import markerAnnotationService from 'services/annotations/markerAnnotationService';
import { useActiveAnnotationAssignmentForViewer } from 'services/annotations/useAnnotationQueryParams';
import { usePermissions } from 'utils/usePermissions';
import { FeatureCollection } from '../NebulaGLExtensions/geojson-types';
import { useSlideAnnotationLayersData } from '../useSlideAnnotationLayersData';

export const viewerAnnotationData = times(MAX_VIEWERS, () => signal<FeatureCollection | null>(null));

export const useActiveAnnotationDraft = ({
  slideId,
  viewerIndex,
}: {
  slideId: string;
  viewerIndex: number;
}): {
  slideAnnotations: Annotation[];
  activeAnnotationData: FeatureCollection | null;
  isLoading: boolean;
} => {
  useSignals();
  const { hasPermission } = usePermissions();
  const canViewAnnotations = hasPermission(Permission.ViewAnnotations);

  const { slideAnnotations, isLoading } = useSlideAnnotationLayersData({ slideId });

  const [activeAnnotationAssignmentId] = useActiveAnnotationAssignmentForViewer(viewerIndex);

  const liveAnnotationData = viewerAnnotationData[viewerIndex]?.value;

  const savedAnnotation = canViewAnnotations
    ? find(slideAnnotations, { annotationAssignment: { annotationAssignmentId: activeAnnotationAssignmentId } })
    : undefined;

  const featureCollectionFromAnnotationData: FeatureCollection = useMemo(() => {
    return markerAnnotationService.convertAnnotationToGeoJson({
      annotation: cloneDeep(savedAnnotation),
      draft: true,
    });
  }, [savedAnnotation]);

  return {
    activeAnnotationData:
      !isLoading && canViewAnnotations && savedAnnotation
        ? liveAnnotationData || featureCollectionFromAnnotationData
        : null,
    isLoading,
    slideAnnotations,
  };
};
