import { ClusterType } from 'interfaces/clusterType';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getClusterTypeOptions = () => {
  return apiRequestHandlerPromise<ClusterType[]>({
    url: `cluster_types`,
    method: 'GET',
  });
};

export const createClusterTypeOption = ({ id, displayName }: Omit<ClusterType, 'index'>) => {
  return apiRequestHandlerPromise<ClusterType>({
    url: 'cluster_types',
    method: 'POST',
    data: JSON.stringify({ id, displayName }),
  });
};

export const updateClusterTypeOption = ({ id, displayName }: Omit<ClusterType, 'index'>) => {
  return apiRequestHandlerPromise({
    url: `cluster_types/${id}`,
    method: 'POST',
    data: JSON.stringify({ displayName }),
  });
};

export const deleteClusterTypeOption = (id: string) => {
  return apiRequestHandlerPromise({
    url: `cluster_types/${id}`,
    method: 'DELETE',
  });
};
