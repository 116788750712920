import { ActionModal } from 'components/StudyDashboard/ProceduresPage/Actions/ActionModal';
import { RunMultiplexBinaryClassifier } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexBinaryClassifier';
import { RunMultiplexCellSegmentation } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexCellSegmentation';
import { RunMultiplexCellTyping } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexCellTyping';
import { RunMultiplexHistogram } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexHistogram';
import { RunMultiplexNormalization } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexNormalization';
import { RunMultiplexThreshold } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexThreshold';
import { RunInferenceSteps } from 'components/StudyDashboard/ProceduresPage/Actions/RunInference/RunInferenceSteps';
import { JobType } from 'interfaces/job';
import { Permission } from 'interfaces/permissionOption';
import React from 'react';
import { CasesParams } from 'utils/useCasesParams';
import { usePermissions } from 'utils/usePermissions';

interface Props {
  jobType: JobType;
  onClose: () => void;
  casesParams: CasesParams;
  jobId?: string;
}

const JobModal: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  jobType,
  onClose,
  casesParams,
  jobId,
}) => {
  const { hasPermission } = usePermissions();
  const canRunInference = hasPermission(Permission.RunInference);
  const canRunCalculateFeatures = hasPermission(Permission.RunCalculateFeatures);
  const canRunMultiplexCellSegmentation = hasPermission(Permission.RunMultiplexCellSegmentation);
  const canRunMultiplexBinaryClassifier = hasPermission(Permission.ExecuteMultiplexBinaryClassifier);
  const canRunMultiplexNormalization = hasPermission(Permission.ExecuteMultiplexNormalization);
  const canRunMultiplexHistogram = hasPermission(Permission.ExecuteMultiplexHistogram);
  const canRunMultiplexThreshold = hasPermission(Permission.ExecuteMultiplexThreshold);
  const canRunMultiplexCellTyping = hasPermission(Permission.ExecuteMultiplexCellTyping);

  const canViewJobModal =
    jobType === JobType.Inference
      ? canRunInference
      : jobType === JobType.CalculateFeatures
      ? canRunCalculateFeatures
      : jobType === JobType.MultiplexCellSegmentation
      ? canRunMultiplexCellSegmentation
      : jobType === JobType.BinaryClassifier
      ? canRunMultiplexBinaryClassifier
      : jobType === JobType.MultiplexNormalization
      ? canRunMultiplexNormalization
      : jobType === JobType.MultiplexHistogram
      ? canRunMultiplexHistogram
      : jobType === JobType.MultiplexThreshold
      ? canRunMultiplexThreshold
      : jobType === JobType.MultiplexCellTyping
      ? canRunMultiplexCellTyping
      : false;

  if (canViewJobModal && open) {
    if (jobType === JobType.Inference) {
      return (
        <ActionModal header="Run Inference" onClose={onClose}>
          <RunInferenceSteps jobId={jobId} casesParams={casesParams} onClose={onClose} />
        </ActionModal>
      );
    }
    if (jobType === JobType.MultiplexCellSegmentation) {
      return (
        <ActionModal header="Run Multiplex Cell Segmentation" onClose={onClose}>
          <RunMultiplexCellSegmentation onClose={onClose} />
        </ActionModal>
      );
    }
    if (jobType === JobType.BinaryClassifier) {
      return (
        <ActionModal header="Run Multiplex Binary Classifier" onClose={onClose}>
          <RunMultiplexBinaryClassifier onClose={onClose} />
        </ActionModal>
      );
    }
    if (jobType === JobType.MultiplexHistogram) {
      return (
        <ActionModal header="Run Multiplex Histogram" onClose={onClose}>
          <RunMultiplexHistogram jobId={jobId} casesParams={casesParams} onClose={onClose} />
        </ActionModal>
      );
    }
    if (jobType === JobType.MultiplexNormalization) {
      return (
        <ActionModal header="Run Multiplex Normalization" onClose={onClose}>
          <RunMultiplexNormalization jobId={jobId} casesParams={casesParams} onClose={onClose} />
        </ActionModal>
      );
    }
    if (jobType === JobType.MultiplexThreshold) {
      return (
        <ActionModal header="Run Multiplex Threshold" onClose={onClose}>
          <RunMultiplexThreshold onClose={onClose} />
        </ActionModal>
      );
    }
    if (jobType === JobType.MultiplexCellTyping) {
      return (
        <ActionModal header="Run Multiplex Cell Typing" onClose={onClose}>
          <RunMultiplexCellTyping onClose={onClose} />
        </ActionModal>
      );
    }
  }
};

export default JobModal;
