import { TreeItem } from '@mui/lab';
import { Checkbox, Grid, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSignals } from '@preact/signals-react/runtime';
import { compact, filter, forEach, includes, isEmpty, isString, lowerCase, map } from 'lodash';
import React, { useState, useTransition } from 'react';

import {
  LayerVisualizationChange,
  LayerVisualizationSettings,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import ToggleableSlider from 'components/Procedure/ToggleableSlider/ToggleableSlider';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { Permission } from 'interfaces/permissionOption';
import { usePermissions } from 'utils/usePermissions';
import LegendRow from '../LegendRow';
import { getHeatmapUrlKeyFromFeatureMetadata } from '../helpers';

interface Props {
  heatmap: FeatureMetadata;
  filterText: string;
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  isExpanded: boolean;
  addOrchestrationIdToUrl: boolean;
  hideOrchestrationId?: boolean;
  hideOpacityWhenNotSelected?: boolean;
  handleChangeSelectHeatmap: (heatmapId: string, checked: boolean) => void;
}

const RasterHeatmapItem: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  heatmap,
  filterText,
  slideId,
  viewerIndex,
  stainTypeId,
  isExpanded,
  addOrchestrationIdToUrl,
  hideOrchestrationId,
  hideOpacityWhenNotSelected,
  handleChangeSelectHeatmap,
}) => {
  useSignals();
  const heatmapId = heatmap?.id;

  const [overriddenSettings, setOverriddenSettings] = useState<Partial<LayerVisualizationSettings>>({});

  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];

  const slideLayerVisualizationSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId];
  const heatmapSettings = { ...(slideLayerVisualizationSettings?.[heatmapId]?.value || {}), ...overriddenSettings };

  const displaySelectedItems = !heatmapSettings?.selected && !isExpanded;
  const { hasPermission } = usePermissions();
  const canSeeOrchestrationId = !hideOrchestrationId && hasPermission(Permission.SeeOrchestrationId);

  const selectedNestedItemsLength = filter(
    heatmap?.nestedItems,
    ({ id }) => slideLayerVisualizationSettings?.[id]?.value?.selected
  ).length;
  const showNestedItemsLength = filter(
    heatmap?.nestedItems,
    ({ id }) => slideLayerVisualizationSettings?.[id]?.value?.show
  ).length;
  const theme = useTheme();
  const {
    palette: { mode, grey },
  } = theme;

  const defaultColor = mode === 'light' ? grey[300] : grey[600];
  const border = `1px solid ${mode === 'light' ? 'none' : grey[300]}`;

  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  const [, startTransition] = useTransition();

  const handleChangeWithNestedItems = (newSettings: Partial<LayerVisualizationSettings>) => {
    const changes: LayerVisualizationChange[] = [];
    forEach(compact([heatmap, ...(heatmap?.nestedItems || [])]), (nestedHeatmap) => {
      changes.push({
        layerId: nestedHeatmap.id,
        newSettings,
        layerUrlKey: getHeatmapUrlKeyFromFeatureMetadata({
          heatmap: nestedHeatmap,
          parentHeatmap: heatmap,
          addOrchestrationId: addOrchestrationIdToUrl,
        }),
      });
    });
    setOverriddenSettings((currentSettings) => ({ ...currentSettings, ...newSettings }));
    startTransition(() => {
      applyChangesToSlideLayerVisualizationSettings({
        slideId,
        viewerIndex,
        changes,
        stainTypeId,
        changeFlow: `RasterHeatmapItem-${heatmapId}`,
      });
      setOverriddenSettings({});
    });
  };

  const handleChangeShowHeatmap = () => handleChangeWithNestedItems({ show: !heatmapSettings?.show });

  const handleChangeHeatmapOpacity = (opacity: number) => handleChangeWithNestedItems({ opacity });

  const opacityDisabled = !heatmapSettings?.selected && selectedNestedItemsLength === 0;

  const lowerCaseFilter = lowerCase(filterText);

  return (
    <>
      <TreeItem
        key={heatmapId}
        nodeId={heatmapId}
        endIcon={
          <Typography
            sx={{
              width: 15,
              height: 15,
              backgroundColor: heatmapSettings?.selected && isString(heatmap?.color) ? heatmap?.color : defaultColor,
              border,
            }}
            variant="subtitle2"
          />
        }
        label={
          <Grid container wrap="nowrap" alignItems="center">
            <Grid item md={2}>
              {heatmap?.heatmapUrl && (
                <Checkbox
                  disabled={!heatmap?.heatmapUrl}
                  checked={Boolean(heatmapSettings?.selected)}
                  indeterminate={!includes([0, heatmap?.nestedItems?.length ?? 0], selectedNestedItemsLength)}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeSelectHeatmap(heatmapId, event.target.checked)
                  }
                  onClick={(e) => e.stopPropagation()}
                />
              )}
            </Grid>
            <Grid
              item
              container
              md={5}
              direction="column"
              sx={{
                '&>.MuiGrid-item': {
                  maxWidth: '100%',
                },
              }}
            >
              <Grid item container direction="column" overflow="hidden">
                <Grid maxWidth="100%">
                  <Tooltip title={heatmap?.displayName} placement="top">
                    <Typography variant="caption" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                      {heatmap?.displayName}
                    </Typography>
                  </Tooltip>
                </Grid>
                {canSeeOrchestrationId && (
                  <Grid maxWidth="100%">
                    <Tooltip title={heatmap?.orchestrationId} placement="top">
                      <Typography
                        variant="caption"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        component="div"
                      >
                        {heatmap?.orchestrationId}
                      </Typography>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {(!opacityDisabled || !hideOpacityWhenNotSelected) && (
              <Grid item md={5} pl={1}>
                <ToggleableSlider
                  value={heatmapSettings?.opacity}
                  disabled={!heatmapSettings?.selected && selectedNestedItemsLength === 0}
                  checked={Boolean(heatmapSettings?.show || showNestedItemsLength !== 0)}
                  onToggleChange={() => handleChangeShowHeatmap()}
                  onValueChange={(value: number) => {
                    handleChangeHeatmapOpacity(value);
                  }}
                />
              </Grid>
            )}
          </Grid>
        }
      >
        {!isEmpty(heatmap?.nestedItems)
          ? map(
              filter(heatmap?.nestedItems, (item) => includes(lowerCase(item.displayName), lowerCaseFilter)),
              (item) => {
                const nestedLayerVisualizationSettings = slideLayerVisualizationSettings?.[item.id]?.value;
                return (
                  <TreeItem
                    key={item.id}
                    nodeId={item.id}
                    label={
                      <LegendRow
                        heatmapSettings={nestedLayerVisualizationSettings}
                        handleChangeSelectHeatmap={handleChangeSelectHeatmap}
                        key={item.id}
                        heatmap={item}
                      />
                    }
                  />
                );
              }
            )
          : null}
      </TreeItem>
      {/* When not expanded, show selected nested heatmaps if the parent is not selected */}
      {displaySelectedItems &&
        map(heatmap?.nestedItems, (item) => {
          const nestedLayerVisualizationSettings = slideLayerVisualizationSettings?.[item.id]?.value;
          return (
            nestedLayerVisualizationSettings?.selected && (
              <Grid key={item.id} container wrap="nowrap" alignItems="center" pl={6}>
                <Grid item>
                  <LegendRow
                    heatmap={item}
                    heatmapSettings={nestedLayerVisualizationSettings}
                    handleChangeSelectHeatmap={handleChangeSelectHeatmap}
                  />
                </Grid>
              </Grid>
            )
          );
        })}
    </>
  );
};

export default RasterHeatmapItem;
