import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import OrchestrationIdAutoComplete from 'components/SearchFilters/AdvancedSearch/OrchestrationIdAutoComplete';
import useFeatureFilters from 'components/SearchFilters/hooks/useFeatureFilters';
import { InferredFeatureConfig } from 'interfaces/inferredFeatures';
import { Study } from 'interfaces/study';
import React, { FunctionComponent, useState } from 'react';
import InferredFeatureRulesSettings from './InferredFeatureRulesSettings';

interface InferredFeatureConfigProps {
  config: InferredFeatureConfig;
  updateConfig: (newConfig: InferredFeatureConfig) => void;
  study: Study;
  removeFeatureConfig: () => void;
}

const InferredFeatureConfigSettings: FunctionComponent<React.PropsWithChildren<InferredFeatureConfigProps>> = ({
  config,
  study,
  updateConfig,
  removeFeatureConfig,
}) => {
  const [orchestrationId, setOrchestrationId] = useState<string>(null);
  const { orchestrationIdOptions, orchestrationsLoading } = useFeatureFilters({
    studyId: study.id,
    orchestrationId,
    onSuccessGetExperimentDefinitions: ({ orchestrations }) =>
      orchestrations && setOrchestrationId(orchestrations[0]?.orchestrationId),
  });

  return (
    <ListItem key={config.id} sx={{ width: '100%' }}>
      <Accordion>
        <AccordionSummary>
          <ListItemIcon sx={{ alignItems: 'center' }}>
            <AutoAwesomeIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography>{config.featureName || 'New Feature'}</Typography>

            <Typography variant="subtitle2">ID: {config.id}</Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                removeFeatureConfig();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item>
              <TextField
                label="Feature Name"
                value={config.featureName}
                onChange={(e) => updateConfig({ ...config, featureName: e.target.value })}
              />
            </Grid>
            <Grid item>
              <OrchestrationIdAutoComplete
                onChange={setOrchestrationId}
                options={orchestrationIdOptions}
                disabled={false}
                loading={orchestrationsLoading}
                value={orchestrationId}
                helperText="Select an orchestration to use its features (no orchestration = approved features)"
              />
            </Grid>
          </Grid>
          <InferredFeatureRulesSettings
            orchestrationId={orchestrationId}
            config={config}
            updateConfig={updateConfig}
            study={study}
          />
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
};

export default InferredFeatureConfigSettings;
