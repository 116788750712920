import { GridRowId, GridRowModesModel } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { omit, reject } from 'lodash';
import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { createAreaTypeOption, deleteAreaTypeOption, updateAreaTypeOption } from 'api/areaTypes';
import { useCrudControlsColumns } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { AreaType } from 'interfaces/areaType';
import { areaTypeFields } from 'interfaces/areaType/areaTypeFields';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { AreaTypeRowChangesSummary } from './AreaTypeRowChangesSummary';
import { AreaTypeDraft } from './types';
import { getAreaTypeError, getAreaTypeId } from './utils';

export const useAreaTypesColumns = ({
  apiRef,
  noRows,
  areaTypes,
  draftAreaTypes,
  rowModesModel,
  setDraftAreaTypes,
  setRowModesModel,
}: {
  noRows?: boolean;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  areaTypes: Array<AreaType | AreaTypeDraft>;
  draftAreaTypes: AreaTypeDraft[];
  rowModesModel: GridRowModesModel;
  setDraftAreaTypes: React.Dispatch<React.SetStateAction<Array<Omit<AreaType, 'index'>>>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();
  const commonMutationOptions = React.useMemo(() => ({ onError: () => setMutatingRowId(undefined) }), []);

  const queryClient = useQueryClient();

  const createAreaTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (newAreaType) => {
      queryClient.invalidateQueries(['areaTypes']);
      queryClient.setQueryData(['areaTypes'], (oldData: AreaType[]) => [...oldData, newAreaType]);
    },
    mutationFn: (areaType: AreaType) => createAreaTypeOption(omit(areaType, 'index')),
    mutationDescription: 'create area type',
  });

  const updateAreaTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['areaTypes']);
      queryClient.setQueryData(['areaTypes'], (oldData: AreaType[]) => {
        return [...reject(oldData, { id: mutatingRowId }), variables];
      });
    },
    mutationFn: (areaType: AreaType) => updateAreaTypeOption(omit(areaType, 'index')),
    mutationDescription: 'update area type',
  });

  const deleteAreaTypeMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess(data, variables) {
      queryClient.invalidateQueries(['areaTypes']);
      queryClient.setQueryData(['areaTypes'], (oldData: AreaType[]) => reject(oldData, { id: variables }));
    },
    mutationFn: deleteAreaTypeOption,
    mutationDescription: 'delete area type',
  });

  const columns = useCrudControlsColumns<AreaType, AreaTypeDraft>({
    createMutation: createAreaTypeMutation,
    deleteMutation: deleteAreaTypeMutation,
    updateMutation: updateAreaTypeMutation,
    apiRef,
    rowModesModel,
    setRowModesModel,
    setDraftRows: setDraftAreaTypes,
    draftRows: draftAreaTypes,
    currentRows: areaTypes,
    rowTypeFields: areaTypeFields,
    getRowError: getAreaTypeError,
    noRows,
    getCancelEditConfirmationModalOptions: ({ id, newRowValue, isDraft, changes }) => ({
      title: `Cancel area type ${isDraft ? 'creation' : 'update'}`,
      text: (
        <AreaTypeRowChangesSummary
          areaTypeDisplayString={`Area Type "${newRowValue?.displayName}" (${newRowValue?.id ?? id})`}
          changes={changes}
        />
      ),
    }),
    getSaveConfirmationModalOptions: ({ id, newRowValue, isDraft, changes }) => ({
      title: `${isDraft ? 'Create' : 'Update'} area type`,
      text: (
        <AreaTypeRowChangesSummary
          areaTypeDisplayString={`Area Type "${newRowValue?.displayName}" (${newRowValue?.id ?? id})`}
          changes={changes}
        />
      ),
    }),
    getDeleteConfirmationModalOptions: () => ({
      title: 'Delete Area Type',
      text: 'Are you sure you want to delete this area type?',
    }),
    idGetter: getAreaTypeId,
    draftIdField: 'draftId',
    mutatingRowId,
    setMutatingRowId,
  });
  return columns;
};
