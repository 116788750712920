import { compact, last, map } from 'lodash';
import React from 'react';
import { QueryFieldProps } from '../QueryFieldInput';

import { FieldWithMultiselectFilter } from 'interfaces/genericFields';
import { Procedure } from 'interfaces/procedure';
import { ProceduresFieldsContext, useProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { isBooleanValuesArray } from 'utils/advancedFilterAdapter';
import AutocompleteFilter from '../MainFilters/AutocompleteFilter';

const MultiSelect = ({
  onChange,
  value,
  field,
}: QueryFieldProps<FieldWithMultiselectFilter<Procedure, ProceduresFieldsContext>>) => {
  const { enumType, options: manualOptions, filterType, dataKey, optionsGetter } = field;

  const proceduresFieldsContext = useProceduresFieldsContext();

  const options = enumType
    ? proceduresFieldsContext?.enumDisplayNames?.[enumType] || []
    : optionsGetter
    ? optionsGetter(proceduresFieldsContext)
    : manualOptions;

  const handleChange = (nextValue: typeof value) => {
    if (last(nextValue) === '') {
      onChange(filterType, dataKey, []);
      return;
    }

    const effectiveSelection = isBooleanValuesArray(nextValue) ? nextValue : compact(nextValue);
    onChange(filterType, dataKey, effectiveSelection);
  };

  const autoCompleteOptions = map(options, (option) => ({
    text: option.label,
    value: option.value,
  }));

  return <AutocompleteFilter value={value} handleChange={handleChange} options={autoCompleteOptions} />;
};

export default MultiSelect;
