import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DisplayedField, FieldOption, FieldType, FieldWithSelectEditCell } from 'interfaces/genericFields';
import { find, findIndex, isArray, isEmpty, map, some } from 'lodash';
import React from 'react';

import { EnumDisplayNames } from 'utils/queryHooks/uiConstantsHooks';
import { AutocompleteEditInputCell } from '../AutocompleteCellEditor';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CheckboxAutocompleteOptions: React.FC<
  React.PropsWithChildren<
    React.HTMLAttributes<HTMLLIElement> & {
      selected: boolean;
      title: string;
      disabled?: boolean;
    }
  >
> = ({ selected, title, disabled, ...props }) => (
  <li {...props}>
    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} disabled={disabled} />
    {title}
  </li>
);

const autocompleteCellEditor =
  <
    R extends any,
    Context extends { enumDisplayNames?: EnumDisplayNames } = { enumDisplayNames?: EnumDisplayNames },
    V = any
  >(
    field: DisplayedField<R, V, Context>,
    context?: Context,
    { checkboxSelection }: { checkboxSelection?: boolean } = {}
  ): GridColDef<R, V, string>['renderEditCell'] =>
  (props) => {
    if (field.cellEditType !== 'multiSelect' && field.cellEditType !== 'checkbox-group') {
      throw new Error('Invalid cellEditType');
    }
    const baseOptions = field.enumType
      ? context?.enumDisplayNames?.[field.enumType] || []
      : field?.optionsGetter
      ? field.optionsGetter(context)
      : field.options || [];

    const options: FieldOption[] = [
      ...(isArray(baseOptions) ? baseOptions : []),
      ...(isEmpty(baseOptions)
        ? [
            {
              value: field.missingValueOption ?? '',
              label: field.missingValueLabel ?? 'No Selection',
            },
          ]
        : []),
    ];

    const fixedOptions = field?.fixedOptionsGetter?.(props, options, context) || [];

    return (
      <AutocompleteEditInputCell<string | number, true, false, false>
        multiple
        fixedOptions={map(fixedOptions, 'value')}
        renderOption={
          checkboxSelection
            ? (renderOptionProps, value, { selected }) => {
                const isFixed = findIndex(fixedOptions, { value }) >= 0;
                return (
                  <CheckboxAutocompleteOptions
                    title={find(options, { value }).label}
                    selected={selected || isFixed}
                    disabled={isFixed}
                    {...renderOptionProps}
                    onClick={isFixed ? () => {} : renderOptionProps.onClick}
                  />
                );
              }
            : undefined
        }
        params={props}
        maxSelections={field?.getNumOfEntries?.(props.row, context)}
        options={map(options, ({ value }) => value)}
        getOptionLabel={(optionVal) =>
          find(options, { value: optionVal })?.label || `${optionVal ?? field.missingValueLabel ?? ''}`
        }
        freeSolo={false}
      />
    );
  };

export const fieldColEditTypeToCustomEditComponent = <
  R extends any,
  Context extends { enumDisplayNames?: EnumDisplayNames } = { enumDisplayNames?: EnumDisplayNames },
  V = any
>(
  fieldType: FieldType
):
  | ((field: DisplayedField<R, V, Context>, context?: Context) => GridColDef<R, V, string>['renderEditCell'])
  | undefined =>
  ({
    custom: undefined,
    text: undefined,
    checkbox: undefined,
    date: undefined,
    number: undefined,
    select:
      (field: DisplayedField<R, V, Context>, context?: Context): GridColDef<R, V, string>['renderEditCell'] =>
      (props) => {
        if (field.cellEditType !== 'select' && field?.unwoundRowCellEditType !== 'select') {
          throw new Error('Invalid cellEditType');
        }
        const baseOptions = (field as FieldWithSelectEditCell<R, Context>).enumType
          ? context?.enumDisplayNames?.[(field as FieldWithSelectEditCell<R, Context>).enumType] || []
          : (field as FieldWithSelectEditCell<R, Context>)?.optionsGetter
          ? (field as FieldWithSelectEditCell<R, Context>).optionsGetter(context)
          : (field as FieldWithSelectEditCell<R, Context>).options || [];

        const options: FieldOption[] = [
          ...baseOptions,
          ...(!some(baseOptions, { value: (field as FieldWithSelectEditCell<R, Context>).missingValueOption ?? '' })
            ? [
                {
                  value: (field as FieldWithSelectEditCell<R, Context>).missingValueOption ?? '',
                  label: (field as FieldWithSelectEditCell<R, Context>).missingValueLabel ?? 'No Selection',
                },
              ]
            : []),
        ];

        return (
          <AutocompleteEditInputCell<string | number, false, false, false>
            params={props}
            options={map(options, 'value')}
            getOptionLabel={(optionVal) =>
              // Use the valueFormatter if available
              (field as any)?.singleObjectFormatter?.(optionVal as any, context) ||
              // Use the valueFormatter if available
              field?.valueFormatter?.({ value: optionVal as any }, context) ||
              // Otherwise use the label from the options
              find(options, { value: optionVal })?.label ||
              // Otherwise use the value itself
              `${
                optionVal ??
                // If the value is undefined, use the missingValueLabel if available
                (field as FieldWithSelectEditCell<R, Context>).missingValueLabel ??
                'No Selection'
              }`
            }
            freeSolo={false}
          />
        );
      },
    multiSelect: (
      field: DisplayedField<R, V, Context>,
      context?: Context
    ): GridColDef<R, V, string>['renderEditCell'] => autocompleteCellEditor(field, context),
    multiText:
      (field: DisplayedField<R, V, Context>, context?: Context): GridColDef<R, V, string>['renderEditCell'] =>
      (props) => {
        if (field.cellEditType !== 'multiText') {
          throw new Error('Invalid cellEditType');
        }
        return (
          <Tooltip title="Press Enter to add a new value">
            <AutocompleteEditInputCell<string, true, false, true>
              freeSolo
              multiple
              open
              openOnFocus
              params={props}
              options={[]}
              maxSelections={field?.getNumOfEntries?.(props.row, context)}
            />
          </Tooltip>
        );
      },
    range: undefined,
    'checkbox-group': (
      field: DisplayedField<R, V, Context>,
      context?: Context
    ): GridColDef<R, V, string>['renderEditCell'] =>
      autocompleteCellEditor(field, context, { checkboxSelection: true }),
    'date-range': undefined,
  }[fieldType]);
