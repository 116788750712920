import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid, GridInputRowSelectionModel, GridRowParams } from '@mui/x-data-grid';
import { SlideResult } from 'api/experimentResults';
import { SlideResultsSummaryData } from 'interfaces/experimentResults';
import { castArray, filter, find, includes, isEmpty, map, orderBy } from 'lodash';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useSlideResults } from '../../useSlideResults';
import OrchestrationView from './OrchestrationView';

const getSlideResultRowId = (row: SlideResult | SlideResultsSummaryData) =>
  `${row.slideId || ''} ${row.flowClassName || ''}`;

interface SelectSlidesAndOrchestrationsForReassignmentProps {
  selectedSlideResults: SlideResultsSummaryData[];
  setSelectedSlideResults: React.Dispatch<React.SetStateAction<SlideResultsSummaryData[]>>;
  selectedOrchestrationId: string | null;
  setSelectedOrchestrationId: React.Dispatch<React.SetStateAction<string | null>>;
}

const SelectSlidesAndOrchestrationsForReassignment: FunctionComponent<
  React.PropsWithChildren<SelectSlidesAndOrchestrationsForReassignmentProps>
> = ({ selectedSlideResults, selectedOrchestrationId, setSelectedSlideResults, setSelectedOrchestrationId }) => {
  const [selectableRowIds, setSelectableRowIds] = useState<string[]>([]);

  const { calculatedFeaturesOrchestrationData, slideResultsSummary, isLoading, isError } = useSlideResults();

  const rowSelectionModel: GridInputRowSelectionModel = useMemo(
    () => map(selectedSlideResults, getSlideResultRowId),
    [selectedSlideResults]
  );

  const setRowSelectionModel = useCallback(
    (newRowSelectionModel: GridInputRowSelectionModel) => {
      setSelectedSlideResults(
        filter(slideResultsSummary, (result) => includes(castArray(newRowSelectionModel), getSlideResultRowId(result)))
      );
    },
    [slideResultsSummary, setSelectedSlideResults]
  );

  const onOrchestrationOpen = (orchestrationId: string) => {
    setSelectedOrchestrationId(orchestrationId);
    const orchestration = find(calculatedFeaturesOrchestrationData, { orchestrationId });
    if (orchestration) {
      const newSelectedRowIds = map(orchestration?.slidesData, getSlideResultRowId);
      setSelectableRowIds(newSelectedRowIds);
      setRowSelectionModel(newSelectedRowIds);
    }
  };

  const onOrchestrationClose = () => {
    setSelectedOrchestrationId(null);
    setSelectableRowIds([]);
    setSelectedSlideResults([]);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Box sx={{ overflowY: 'auto' }} height={'65vh'}>
          {isLoading ? (
            <Typography variant="subtitle1">Loading...</Typography>
          ) : isError ? (
            <Typography variant="subtitle1">Error loading orchestrations</Typography>
          ) : isEmpty(calculatedFeaturesOrchestrationData) ? (
            <Typography variant="subtitle1">No orchestrations found</Typography>
          ) : (
            map(orderBy(calculatedFeaturesOrchestrationData, 'createdAtMinimum', 'desc'), (orchestration) => (
              <OrchestrationView
                key={orchestration?.orchestrationId}
                orchestration={orchestration}
                expanded={selectedOrchestrationId === orchestration?.orchestrationId}
                onOpen={onOrchestrationOpen}
                onClose={onOrchestrationClose}
              />
            ))
          )}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <DataGrid
          sx={{ height: '65vh' }}
          rows={slideResultsSummary}
          getRowId={getSlideResultRowId}
          columns={[
            { field: 'caseName', headerName: 'Case Name', width: 100 },
            { field: 'slideId', headerName: 'Slide ID', width: 150 },
            { field: 'flowClassName', headerName: 'Flow Class Name', width: 200 },

            {
              field: 'internallyApprovedOrchestrationId',
              headerName: 'Internally Approved Orchestration',
              width: 250,
            },
            { field: 'isPublished', headerName: 'Published', width: 100 },
          ]}
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          loading={isLoading}
          pageSizeOptions={[100]}
          checkboxSelection
          isRowSelectable={(params: GridRowParams) => selectableRowIds.indexOf(params.id as string) !== -1}
          onRowSelectionModelChange={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
        />
      </Grid>
    </Grid>
  );
};

export default SelectSlidesAndOrchestrationsForReassignment;
