import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { groupBy, isEmpty, map, sortBy } from 'lodash';
import React, { useState } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { CellTableControl } from './CellTableControl';
import { ProtomapTree } from './ProtomapTileControl';
import { RasterHeatmapTree } from './RasterHeatmaps';
import { UnpublishResultsButton } from './UnpublishResultsButton';
import { useInitialExpandIfHeatmapSelected } from './helpers';

interface HeatmapsProps {
  viewerIndex: number;
  title: string;
  heatmaps: FeatureMetadata[];
  slideId: string;
  stainTypeId: string;
  studyId: string;
  filterText: string;
  expandByDefault?: boolean;
  internalHeatmaps?: boolean;
  hideOrchestrationId?: boolean;
}

const Heatmaps: React.FunctionComponent<React.PropsWithChildren<HeatmapsProps>> = (props) => {
  useSignals();
  const {
    viewerIndex,
    title,
    heatmaps,
    stainTypeId,
    slideId,
    studyId,
    filterText,
    expandByDefault,
    internalHeatmaps = false,
    hideOrchestrationId,
  } = props;
  const {
    dzi: dziHeatmaps,
    pmt: pmtHeatmaps,
    parquet: parquetHeatmaps,
  } = groupBy(sortBy(heatmaps, 'displayName'), 'heatmapType');

  const parquetHeatmapsPerKey = groupBy(parquetHeatmaps, 'key');

  const [expandAccordion, setExpandAccordion] = useState(Boolean(expandByDefault));

  // On first render, set the default expanded state based on selected heatmaps
  useInitialExpandIfHeatmapSelected({
    expandAccordion,
    setExpandAccordion,
    heatmaps,
    viewerIndex,
    slideId,
  });

  const [isPublishMode] = useQueryParam(`isPublishModeViewer${viewerIndex}`, BooleanParam);
  const [useDeckGL] = useQueryParam('useDeckGL', BooleanParam);

  const showDziHeatmaps = !isEmpty(dziHeatmaps);
  const showPmtHeatmaps = useDeckGL && !isEmpty(pmtHeatmaps);
  const showParquetHeatmaps = useDeckGL && !isEmpty(parquetHeatmaps);
  return (
    <Accordion square expanded={expandAccordion} onChange={() => setExpandAccordion(!expandAccordion)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={isPublishMode && !internalHeatmaps ? 7 : 12}>
            <Typography variant={internalHeatmaps ? 'subtitle2' : 'h4'}>{title}</Typography>
          </Grid>
          <UnpublishResultsButton
            slideId={slideId}
            viewerIndex={viewerIndex}
            studyId={studyId}
            internalHeatmaps={internalHeatmaps}
            heatmaps={heatmaps}
          />
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 1 }}>
        {showDziHeatmaps && (
          <RasterHeatmapTree
            addOrchestrationIdToUrl={internalHeatmaps}
            viewerIndex={viewerIndex}
            slideId={slideId}
            heatmaps={dziHeatmaps}
            stainTypeId={stainTypeId}
            filterText={filterText}
            onEmptyFilter={() => setExpandAccordion(true)}
            hideOrchestrationId={hideOrchestrationId}
          />
        )}
        {showPmtHeatmaps && (
          <ProtomapTree
            addOrchestrationIdToUrl={internalHeatmaps}
            viewerIndex={viewerIndex}
            slideId={slideId}
            pmtHeatmaps={pmtHeatmaps}
            stainTypeId={stainTypeId}
            filterText={filterText}
            onEmptyFilter={() => setExpandAccordion(true)}
            hideOrchestrationId={hideOrchestrationId}
          />
        )}
        {showParquetHeatmaps &&
          map(
            parquetHeatmapsPerKey,
            (parquetHeatmapsGroup, key) =>
              !isEmpty(parquetHeatmapsGroup) && (
                <CellTableControl
                  key={key}
                  viewerIndex={viewerIndex}
                  slideId={slideId}
                  heatmaps={parquetHeatmapsGroup}
                  filterText={filterText}
                  hideOrchestrationId={hideOrchestrationId}
                  stainTypeId={stainTypeId}
                />
              )
          )}
      </AccordionDetails>
    </Accordion>
  );
};

export default Heatmaps;
