import { Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { fetchStudies } from 'api/study';
import { getUploadList } from 'api/uploads';
import wrapPage from 'components/atoms/wrapPage/wrapPage';
import Loader from 'components/Loader';
import PageHeader from 'components/PageHeader';
import { NON_SPECIFIC_STUDY_VALUES } from 'components/SearchFilters/hooks/useMainFilters';
import { concat, find, includes, toString } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import UploadsTable from './UploadsTable';

const UploadsDashboard: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const { labId, stringifyWithLab } = useCurrentLabId();
  const { encodedFilters, queryParams } = useEncodedFilters();
  const { data: studies } = useQuery(['studies', labId], fetchStudies(labId));
  const currentStudy = find(studies, { id: queryParams.filters?.studyId });
  const fileListSearch = stringifyWithLab({ studyId: queryParams.filters?.studyId });

  const canLoadUploads =
    Boolean(queryParams.filters?.studyId) &&
    !includes(NON_SPECIFIC_STUDY_VALUES, toString(queryParams.filters?.studyId));
  const { data, isLoading } = useQuery(['uploadedFilesList', fileListSearch], () => getUploadList(fileListSearch), {
    refetchInterval: 120000,
    enabled: canLoadUploads,
  });

  const slidesInReview = concat(data?.slides?.pending, data?.slides?.processed);

  return (
    <Grid container direction="column" sx={{ my: 4 }}>
      <Grid item>
        <PageHeader
          pageTitle={`Study ${currentStudy?.name || ''} - Pending Slides`}
          onBackClick={() => navigate(`/procedures?${encodedFilters}`)}
        />
      </Grid>
      <Grid item sx={{ my: 4 }}>
        {canLoadUploads ? (
          isLoading ? (
            <Loader />
          ) : (
            <UploadsTable rows={slidesInReview} />
          )
        ) : (
          <Typography variant="caption">Please select a study to view the uploaded file list.</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default wrapPage(UploadsDashboard);
