import { Query, QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import { getFormById, getForms } from 'api/forms';
import { distinctFormsByType } from 'components/ReviewPanel/form.util';
import { Permission } from 'interfaces/permissionOption';
import { ResponseContext, ReviewForm } from 'interfaces/reviewForm';
import { filter, flatMap, includes, isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { usePermissions } from 'utils/usePermissions';

export const useForm = (formId: string) => {
  return useQuery<ReviewForm>({
    queryKey: ['form', formId],
    queryFn: () => getFormById(formId),
  });
};

type FormQuery = Query<
  ReviewForm[],
  unknown,
  ReviewForm[],
  [
    'forms',
    {
      labId: string;
      studyId?: string;
      slideId?: string;
    }
  ]
>;

const isFormQuery = (query: Query<unknown, unknown, unknown, QueryKey>): query is FormQuery => {
  return query.queryKey[0] === 'forms';
};

const isFormQueryWithData = (query: Query<unknown, unknown, unknown, QueryKey>): query is FormQuery => {
  if (!isFormQuery(query)) {
    return false;
  }
  const formsQuery = query as FormQuery;

  return Boolean(formsQuery.state.data);
};

const findMatchingForms = (currentContext: ResponseContext, forms: ReviewForm[]) => {
  const formsThatCanBeReused = filter(forms, (form) => {
    const { applicableContext } = form;
    const matchingLabId = isEmpty(applicableContext.labIds) || includes(applicableContext.labIds, currentContext.labId);
    const matchingStudyId =
      isEmpty(applicableContext.studyIds) || includes(applicableContext.studyIds, currentContext.studyId);
    const matchingSlideId =
      isEmpty(applicableContext.slideIds) || includes(applicableContext.slideIds, currentContext.slideId);
    return matchingLabId && matchingStudyId && matchingSlideId;
  });

  return formsThatCanBeReused;
};

export const useForms = (
  params: Partial<{ slideId: string; caseId: number; studyId: string }>,
  { enabled = true }: { enabled?: boolean } = {}
) => {
  const { labId } = useCurrentLabId();

  const { hasSubPermission, hasPermission, permissions } = usePermissions();

  const queryClient = useQueryClient();

  const { slideId, caseId, studyId } = params;
  const formQuery = useQuery<ReviewForm[]>({
    queryKey: [
      'forms',
      {
        slideId,
        caseId,
        studyId,
        labId,
      },
    ],
    queryFn: ({ signal }) =>
      getForms(
        {
          slideId,
          caseId,
          studyId,
          labId,
        },
        signal
      ).then((response) => distinctFormsByType(response.forms)),
    placeholderData: () => {
      const relevantQueriesData = queryClient.getQueriesData<ReviewForm[]>({
        predicate: isFormQueryWithData,
      });

      const reusableForms = flatMap(relevantQueriesData, ([, forms]) => {
        return findMatchingForms({ labId, slideId, studyId, caseId }, forms);
      });

      return distinctFormsByType(reusableForms);
    },
    enabled: enabled && hasPermission(Permission.ViewReviewMenu), // will update when new more specific forms permissions come
  });

  const formsWithViewableResponses = useMemo(() => {
    return (
      formQuery.isSuccess &&
      filter(formQuery.data, (form) => {
        return hasSubPermission(Permission.ViewFormResponses, form.type);
      })
    );
  }, [formQuery.isSuccess, formQuery.data, permissions]);

  return { ...formQuery, formsWithViewableResponses: formsWithViewableResponses || [] };
};
