import BackIcon from '@mui/icons-material/ArrowBack';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { UseQueryResult, useQueries, useQuery } from '@tanstack/react-query';
import { fetchStudies } from 'api/study';
import Loader from 'components/Loader';
import wrapPage from 'components/atoms/wrapPage/wrapPage';
import CohortWithQuery, { CohortDefinition, CohortWithSelectedFeatures } from 'interfaces/cohort_old';
import { Features } from 'interfaces/experimentResults';
import { compact, filter, first, forEach, isEmpty, map, partialRight, some } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { setCohorts } from 'redux/modules/chartSlice';
import { DelimitedArrayParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { fetchCohort, studyToCohort } from 'utils/cohort.util';
import { useCurrentLabId } from 'utils/useCurrentLab';
import CohortSelect from './CohortSelect/CohortSelect';
import ExploratoryAnalysis from './ExploratoryAnalysis';
import FeaturesTable from './FeaturesTable';
import DendrogramChart from './charts/DendrogramChart';

const SelectedCohortIdsParam = withDefault(DelimitedArrayParam, []);

export const useFilledCohorts = (
  selectedCohorts: CohortDefinition[]
): [CohortWithSelectedFeatures[], boolean, () => void] => {
  const queries = {
    queries: map(selectedCohorts, (cohort) => {
      return {
        queryKey: ['fill_cohort', cohort.id],
        queryFn: () => fetchCohort(cohort),
      };
    }),
  };

  const cohortQueries = useQueries(queries);

  const filledCohorts = compact(map(cohortQueries, 'data'));

  const isAnyLoading = some(
    cohortQueries,
    (query: UseQueryResult<CohortWithSelectedFeatures, unknown>) => query.isFetching
  );

  const refetch = () => {
    forEach(cohortQueries, (query: UseQueryResult<CohortWithSelectedFeatures, unknown>) => query.refetch());
  };

  return [filledCohorts, isAnyLoading, refetch];
};

const FeaturesDashboard: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const cohorts = useAppSelector((state) => state.charts?.cohorts);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { labId, labSearch } = useCurrentLabId();
  const [selectedCohortIds, setSelectedCohortIds] = useQueryParam('selectedCohortIds', SelectedCohortIdsParam);

  const [tab = '1', setTab] = useQueryParam('analysisTab', StringParam);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const { data: studies, isFetching: isLoadingStudies } = useQuery(['studies', labId], fetchStudies(labId));

  const selectedCohorts = filter(cohorts, (cohort: CohortWithQuery) => selectedCohortIds.includes(cohort.id));

  const [filledCohorts, isLoading] = useFilledCohorts(selectedCohorts);

  useEffect(() => {
    const newCohorts = compact(map(studies, (study) => studyToCohort({ labId, ...study })));

    dispatch(setCohorts(newCohorts));
  }, [labId, JSON.stringify(studies)]);

  if (isLoadingStudies) {
    return <Loader />;
  }

  // (x) => f(x, y)
  const updateSelectedCohortIds = partialRight(setSelectedCohortIds, 'replaceIn');

  const showDendogramHeatmap = Boolean(!isEmpty(filledCohorts) && first(filledCohorts)?.dendrogram?.correlationMatrix);

  const cohortFeatures: Features[] = map(first(filledCohorts)?.procedures, 'slides.0.experimentResults[0].features');

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item container my={4} spacing={1} alignItems="center">
        <Grid item>
          <IconButton
            onClick={() => {
              navigate(`/${labSearch}`);
            }}
          >
            <BackIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h1">Analysis Studio</Typography>
        </Grid>
      </Grid>
      <Grid item mx={4}>
        <CohortSelect
          cohorts={cohorts}
          isLoading={isLoadingStudies}
          selectedCohortIds={selectedCohortIds}
          updateSelectedCohortIds={updateSelectedCohortIds}
        />
      </Grid>
      {(isLoading || !isEmpty(selectedCohortIds)) && (
        <Grid item container direction="column">
          <TabContext value={tab}>
            <Grid item px={4}>
              <TabList onChange={handleChangeTab} aria-label="feature analysis selector" variant="fullWidth" centered>
                <Tab label="Features Exploration" value="1" />
                <Tab label="Data Insights" value="2" />
                <Tab label="Saved Results" value="3" />
              </TabList>
            </Grid>
            <TabPanel value="1" sx={{ width: '100%' }}>
              <Grid item container direction="column" spacing={4}>
                <Grid item>
                  <Card>
                    <CardHeader title="Features Heatmap" />
                    <CardContent>
                      <Box flex="1" textAlign="center">
                        {isLoading && <CircularProgress />}
                        {showDendogramHeatmap && (
                          <DendrogramChart data={first(filledCohorts)?.dendrogram.correlationMatrix} />
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item sx={{ width: '100%' }}>
                  <Card>
                    <CardHeader title="Features General Overview" />
                    <CardContent>
                      <FeaturesTable data={cohortFeatures} loading={isLoading} />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value="2">
              <ExploratoryAnalysis selectedCohortIds={selectedCohortIds} />
            </TabPanel>
            <TabPanel value="3"></TabPanel>
          </TabContext>
        </Grid>
      )}
    </Grid>
  );
};

export default wrapPage(FeaturesDashboard);
