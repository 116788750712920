import BasicDialog, { DialogProps } from 'components/atoms/Dialog/Dialog';
import React, { FunctionComponent, useEffect } from 'react';

interface ModalProps extends DialogProps {}

const ModalWrapper: FunctionComponent<React.PropsWithChildren<ModalProps>> = ({
  children,
  onClose,
  isOpen,
  sx,
  maxWidth,
  fullWidth,
}) => {
  useEffect(() => {
    const onEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', onEscape);

    return () => {
      document.removeEventListener('keydown', onEscape);
    };
  }, [onClose]);

  return (
    <BasicDialog onClose={onClose} isOpen={isOpen} sx={sx} maxWidth={maxWidth} fullWidth={fullWidth}>
      {children}
    </BasicDialog>
  );
};

export default ModalWrapper;
