import { TreeView } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid } from '@mui/material';
import { filter, isEmpty, join, map, size } from 'lodash';
import React from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { isCell, isLogical } from 'components/atoms/ConditionBuilder/utils';
import { AutomaticCondition, AutomaticConditionCell } from 'interfaces/automaticCondition';
import { MappingFilterMetadata } from 'interfaces/postProcessingAction';
import { uuidv4 } from 'utils/helpers';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import { NewCell as NewCellInterface } from '..';
import NewCell from './NewCell';

export interface NewCellsStainProps {
  stain: string;
  newCellsCreated: Record<string, NewCellInterface>;
  addNewCell: (newCellId: string, newCellName: string, newCellValue: AutomaticCondition, stain: string) => void;
  removeNewCell: (newCellId: string) => void;
  getMappingFiltersMetadataForLogicalQuery: (stain: string) => MappingFilterMetadata[];
}

const NewCellsStain: React.FC<React.PropsWithChildren<NewCellsStainProps>> = ({
  stain,
  newCellsCreated,
  addNewCell,
  removeNewCell,
  getMappingFiltersMetadataForLogicalQuery,
}) => {
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();
  const mappingFiltersMetadataForLogicalQuery = getMappingFiltersMetadataForLogicalQuery(stain);
  const mappingFiltersMetadataWithoutArea = filter(
    mappingFiltersMetadataForLogicalQuery,
    (metadata) => metadata.sourceName !== 'area'
  );

  const createNewCell = () => {
    const newCellName = getNewName();
    addNewCell(uuidv4(), newCellName, null, stain);
  };

  const updateNewCell = (newCellId: string, newCellName: string, newCellValue: AutomaticCondition) => {
    const nameByValue = getNewName(newCellValue, newCellName);
    addNewCell(newCellId, nameByValue, newCellValue, stain);
  };

  const getNewName = (newCellValue?: AutomaticCondition, newCellName?: string) => {
    if (newCellValue) {
      return getNameByValue(newCellValue);
    }
    return newCellName ?? `New Cell ${size(newCellsCreated) + 1}`;
  };

  return (
    <Accordion disableGutters expanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {isLoadingStainTypeOptions ? <CircularProgress /> : stainTypeIdToDisplayName(stain)}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} direction="column">
          <Grid item xs={12}>
            <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
              {map(newCellsCreated, (newCell, newCellId) => {
                return (
                  <NewCell
                    key={newCellId}
                    cellName={newCell.name}
                    cellValue={newCell.value}
                    mappingFiltersMetadata={mappingFiltersMetadataWithoutArea}
                    removeNewCell={() => removeNewCell(newCellId)}
                    updateNewCell={(newCellName: string, newCellValue: AutomaticCondition) =>
                      updateNewCell(newCellId, newCellName, newCellValue)
                    }
                  />
                );
              })}
            </TreeView>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} justifyContent="flex-start">
              <Grid item>
                <Button aria-describedby="simple-popover" onClick={() => createNewCell()}>
                  Add New Cell Filter
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const getNameByValueRecursive = (condition: AutomaticCondition): string => {
  if (isCell(condition)) {
    return condition.displayValue;
  } else if (isLogical(condition)) {
    const cellNames = map(condition.operands, (operand: AutomaticConditionCell) => getNameByValueRecursive(operand));
    if (!isEmpty(cellNames)) {
      return join(cellNames, ` ${condition.operator} `);
    }
  }

  return '';
};

export const getNameByValue = (newCellValue?: AutomaticCondition) => {
  return getNameByValueRecursive(newCellValue);
};

export default NewCellsStain;
