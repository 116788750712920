import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Badge, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import React from 'react';

import Slider from 'components/atoms/Slider';
import { NormalizationResult } from 'interfaces/experimentResults';
import { isEqual } from 'lodash';
import { JsonParam, useQueryParam } from 'use-query-params';
import { MAX_UINT16, MAX_UINT8 } from 'utils/constants';
import {
  computeDynamicRangeFromNormalizationOrChannelHistogram,
  NormalizationRange,
  slidesChannelNormalizationSettings,
} from '../channelNormalizations';
export const MultiplexDynamicRange: React.FC<
  React.PropsWithChildren<{
    viewerIndex: number;
    slideId: string;
    encoding: string;
    channelId: string;
    selected: boolean;
    channelHistogram?: number[];
    normalizationData?: NormalizationResult;
    markerType?: string;
    resetChannelNormalizationSettings: (channelIndex: number, markerType?: string) => void;
  }>
> = ({
  viewerIndex,
  slideId,
  encoding,
  channelId,
  channelHistogram,
  normalizationData,
  selected,
  markerType,
  resetChannelNormalizationSettings,
}) => {
  useSignals();
  const viewerChannelNormalizationSettings = slidesChannelNormalizationSettings[viewerIndex];
  const viewerSlideChannelNormalizationSettings = viewerChannelNormalizationSettings?.value?.[slideId];
  const slideChannelNormalizationSettingsSignal = viewerSlideChannelNormalizationSettings?.[channelId];

  const [, startTransition] = React.useTransition();

  const maxChannelRange = encoding === 'uint16' ? MAX_UINT16 : MAX_UINT8;

  const currentRange = slideChannelNormalizationSettingsSignal?.value ?? [0, maxChannelRange];

  const currentPersistedRange = computeDynamicRangeFromNormalizationOrChannelHistogram(
    Number(channelId),
    channelHistogram,
    normalizationData,
    encoding === 'uint16'
  );

  const [, setUrlMarkerNormalizationValues] = useQueryParam<{
    [markerType: string]: { [viewerIndex: number]: NormalizationRange };
  }>('markerNormalizationValues', JsonParam);

  const isOverridden = currentRange[0] !== currentPersistedRange[0] || currentRange[1] !== currentPersistedRange[1];

  const updateDynamicRangeGlobalState = (newRange: NormalizationRange) => {
    if (!isEqual(newRange, currentRange)) {
      slideChannelNormalizationSettingsSignal.value = newRange;
      startTransition(() => {
        if (markerType) {
          setUrlMarkerNormalizationValues((prev) => {
            return {
              ...prev,
              [markerType]: {
                ...prev?.[markerType],
                [viewerIndex]: newRange,
              },
            };
          }, 'replaceIn');
        }
      });
    }
  };

  return (
    <>
      <Grid item container direction="row" wrap="nowrap" alignItems="center" sx={{ pt: 0 }}>
        <Grid item md={2} sx={{ pt: 0 }}>
          <Grid item xs="auto" sx={{ pt: 0 }}>
            <Tooltip title={isOverridden ? 'Overridden by user' : undefined}>
              <Badge
                color="primary"
                variant="dot"
                invisible={!isOverridden}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                <Typography variant="caption">Range</Typography>
              </Badge>
            </Tooltip>
          </Grid>
          <Grid item xs="auto" sx={{ pt: 0 }}>
            <IconButton
              sx={{ pt: 0 }}
              size="small"
              onClick={() => {
                resetChannelNormalizationSettings(Number(channelId), markerType);
              }}
            >
              <RestartAltIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item md={10} container direction="row">
          <Grid item md={2}>
            <TextField
              sx={{
                alignItems: 'center',
                '& input': { textAlign: 'center', fontSize: 10, paddingBottom: 0 },
              }}
              inputProps={{ min: 0, max: maxChannelRange, step: 1 }}
              InputProps={{ disableUnderline: true, sx: { fontSize: 10.5 } }}
              InputLabelProps={{ sx: { width: '100%', textAlign: 'center' } }}
              size="small"
              label="Min"
              type="number"
              variant="standard"
              value={currentRange?.[0] ?? 0}
              disabled={!selected}
              onChange={(event) => {
                const newRange: NormalizationRange = [Number(event.target.value), currentRange[1]];
                updateDynamicRangeGlobalState(newRange);
              }}
            />
          </Grid>
          <Grid item md={8} paddingInline={1}>
            <Slider
              alwaysShowValue
              value={currentRange}
              disabled={!selected}
              onValueChange={updateDynamicRangeGlobalState}
              range={true}
              step={1}
              max={maxChannelRange}
              disableSwap={false}
              color="secondary"
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              sx={{
                alignItems: 'center',
                '& input': { textAlign: 'center', fontSize: 10, paddingBottom: 0 },
              }}
              inputProps={{ min: 0, max: maxChannelRange, step: 1 }}
              InputProps={{ disableUnderline: true, sx: { fontSize: 10.5 } }}
              InputLabelProps={{ sx: { width: '100%', textAlign: 'center' } }}
              size="small"
              label="Max"
              type="number"
              variant="standard"
              value={currentRange?.[1] ?? maxChannelRange}
              disabled={!selected}
              onChange={(event) => {
                const newRange: NormalizationRange = [currentRange[0], Number(event.target.value)];
                updateDynamicRangeGlobalState(newRange);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
