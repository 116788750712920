import { Checkbox, Grid } from '@mui/material';
import React from 'react';

import { LayerColor } from './LayerColor';
import { MainSettings } from './MainSettings';
import { useGroupedLayerChanges } from './handleChanges';

interface Props {
  layerName: string;
  layerDisplayName?: string;
  layerDescription?: string;
  layerUrlKey?: string;
  parentKey: string;
  selected: boolean;
  disabled?: boolean;
  slideId: string;
  stainTypeId: string;
  viewerIndex: number;
  disableToggle?: boolean;
  defaultOpacity?: number;
}

const LayerVisualizationControl: React.FunctionComponent<Props> = ({
  parentKey,
  layerName,
  layerDisplayName,
  layerDescription,
  selected,
  disabled = false,
  slideId,
  viewerIndex,
  layerUrlKey,
  stainTypeId,
  disableToggle,
  defaultOpacity = 100,
}) => {
  const { handleSelectLayer } = useGroupedLayerChanges(
    viewerIndex,
    slideId,
    parentKey,
    layerName,
    layerUrlKey,
    stainTypeId
  );
  return (
    <Grid container wrap="nowrap" alignItems="center">
      <Grid item>
        <Checkbox
          checked={disableToggle || Boolean(selected)}
          onChange={
            disableToggle
              ? undefined
              : (event: React.ChangeEvent<HTMLInputElement>) => handleSelectLayer(event.target.checked, defaultOpacity)
          }
          disabled={disableToggle || Boolean(disabled)}
        />
      </Grid>
      <LayerColor
        selected={disableToggle || selected}
        viewerIndex={viewerIndex}
        slideId={slideId}
        layerName={layerName}
        parentKey={parentKey}
        layerUrlKey={layerUrlKey}
        stainTypeId={stainTypeId}
      />
      <MainSettings
        parentKey={parentKey}
        layerName={layerName}
        layerDisplayName={layerDisplayName}
        layerDescription={layerDescription}
        selected={disableToggle || selected}
        viewerIndex={viewerIndex}
        slideId={slideId}
        layerUrlKey={layerUrlKey}
        stainTypeId={stainTypeId}
      />
    </Grid>
  );
};

export default LayerVisualizationControl;
