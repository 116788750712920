import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box, BoxProps, Tooltip, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';

export interface SlideZoomBadgeProps {
  hovered?: boolean;
}

const useSlideZoomBadgeStyle = (hovered: boolean) => {
  const theme = useTheme();
  return React.useMemo(
    () => ({
      position: 'absolute',
      bottom: 5,
      right: 5,
      padding: 0,
      margin: 0,
      zIndex: 1,
      pointerEvents: 'none',
      color: 'transparent',
      ...(hovered
        ? {
            color: theme.palette.primary.main,
            borderRadius: '100%',
          }
        : {}),
    }),
    [hovered, theme.palette.primary.main]
  );
};

const SlideZoomBadge: FunctionComponent<React.PropsWithChildren<SlideZoomBadgeProps>> = ({ hovered }) => {
  const slideZoomBadgeStyle: BoxProps['sx'] = useSlideZoomBadgeStyle(hovered);

  return (
    <Box sx={slideZoomBadgeStyle}>
      <Tooltip title="Zoom In" placement="bottom">
        <ZoomInIcon />
      </Tooltip>
    </Box>
  );
};

export default SlideZoomBadge;
