import { Checkbox, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSignals } from '@preact/signals-react/runtime';
import { isString } from 'lodash';
import React from 'react';

import { LayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { formatNumber } from 'utils/helpers';

interface Props {
  heatmap: FeatureMetadata;
  heatmapSettings: LayerVisualizationSettings;
  handleChangeSelectHeatmap(heatmap: string, checked: boolean): void;
}

const LegendRow: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  heatmap: item,
  heatmapSettings: itemSettings,
  handleChangeSelectHeatmap,
}) => {
  useSignals();
  const theme = useTheme();
  const {
    palette: { mode, grey },
  } = theme;
  const defaultColor = mode === 'light' ? grey[300] : grey[600];
  const border = `1px solid ${mode === 'light' ? 'none' : grey[300]}`;
  const color = itemSettings?.selected && isString(item?.color) ? item?.color : defaultColor;

  return (
    <Grid container wrap="nowrap" alignItems="center" columnSpacing={4}>
      <Grid item md={1}>
        {item.heatmapUrl && (
          <Checkbox
            sx={{ width: 15, height: 15 }}
            checked={Boolean(itemSettings?.selected)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeSelectHeatmap(item.id, event.target.checked)
            }
          />
        )}
      </Grid>
      <Grid item md={1}>
        <Typography sx={{ width: 15, height: 15, backgroundColor: color, border }} variant="subtitle1" />
      </Grid>
      <Grid item>
        <Typography sx={{}} variant="body2">
          {item.displayName}
        </Typography>
      </Grid>
      {item.value && (
        <Grid item container md={5} justifyContent="flex-end">
          <Grid item>
            <Typography variant="body2">
              <span>{typeof item.value === 'number' ? formatNumber(item.value) : item.value}</span>
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default LegendRow;
