import WarningIcon from '@mui/icons-material/Warning';
import { IconButton, Popover, Typography } from '@mui/material';
import React from 'react';

const ErrorPopupButton: React.FunctionComponent<
  React.PropsWithChildren<{
    content: string;
  }>
> = ({ content }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton size="small" color="error" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        <WarningIcon />
      </IconButton>
      <Popover
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography variant="body2">{content}</Typography>
      </Popover>
    </>
  );
};

export default ErrorPopupButton;
