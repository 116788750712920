import { replace } from 'lodash';
import React from 'react';

const pasteAndTransformDataTransfer = (
  data: DataTransfer,
  target: HTMLInputElement,
  value: string,
  setValue: (value: string) => void
) => {
  const pastedData = data.getData('Text');

  // replace newlines with commas
  // replace \r with nothing
  const transformedData = replace(pastedData, /[\n\r]+/g, ',');

  const { selectionStart: start, selectionEnd: end } = target;

  setValue(value.slice(0, start) + transformedData + value.slice(end));
};

export const useTransformedDataTransfer = (value: string, setValue: (value: string) => void) => {
  const handleOnPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const clipboardData: DataTransfer = event.clipboardData || (window as any).clipboardData;

    pasteAndTransformDataTransfer(clipboardData, event.target as HTMLInputElement, value, setValue);
  };

  const handleOnDrop = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault();

    const dataTransfer: DataTransfer = event.dataTransfer;
    pasteAndTransformDataTransfer(dataTransfer, event.target as HTMLInputElement, value, setValue);
  };
  return {
    handleOnPaste,
    handleOnDrop,
  };
};
