import { Color } from '@deck.gl/core/typed';
import { TileLayerProps } from '@deck.gl/geo-layers/typed';

import type {
  COLORMAPS,
  DTYPE_VALUES,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/constants';
import { CubicDeconvExtensionProps } from '../extensions/bitmapLayer/CubicDeconvExtension';
import { LinearDeconvExtensionProps } from '../extensions/bitmapLayer/LinearDeconvExtension';
import { CustomTileLayerProps } from '../layers/multiScaleImageLayer/customTileLayer';

export type SupportedDtype = keyof typeof DTYPE_VALUES;
// eslint-disable-next-line no-undef
export type SupportedTypedArray = InstanceType<(typeof globalThis)[`${SupportedDtype}Array`]>;

export enum PhotometricInterpretation {
  WhiteIsZero = 0,
  BlackIsZero = 1,
  RGB = 2,
  Palette = 3,
  TransparencyMask = 4,
  CMYK = 5,
  YCbCr = 6,
  CIELab = 8,
  ICCLab = 9,
}

export interface PixelData {
  data: SupportedTypedArray;
  width: number;
  height: number;
}

export type PixelSourceSelection<S extends string[]> = {
  [K in S[number]]: number;
};

export interface RasterSelection<S extends string[]> {
  selection: PixelSourceSelection<S>;
  signal?: AbortSignal;
}

export interface TileSelection<S extends string[]> {
  x: number;
  y: number;
  z: number;
  selection: PixelSourceSelection<S>;
  signal?: AbortSignal;
}

export interface PixelSourceMeta {
  photometricInterpretation?: number;
  usePngDecoder?: boolean;
}

export type Labels<S extends string[]> = [...S, 'z', 'y', 'x'] | [...S, 'z', 'y', 'x', '_c'];

export interface PixelSource<S extends string[]> {
  getUniqueId: () => string;
  getTileSize: () => number;
  getOverlapPixels: () => number;
  getImageSize: () => { width: number; height: number };
  getTileURL: (sel: PixelSourceSelection<Labels<S>>, debug?: boolean) => string | null;
  onTileError(err: Error): void;
  shape: number[];
  labels: Labels<S>;
  meta?: PixelSourceMeta;
  minLevel: number;
  maxLevel: number;
}

export type ColorPerChannelExtensionProps = {
  colors?: Color[]; // List of colors to use for each channel.
  transparentColor?: Color; // Color to use for transparent pixels.
  useTransparentColor?: boolean;
};

export type AdditiveColormapExtensionProps = {
  colormap?: (typeof COLORMAPS)[number];
  opacity?: number;
  useTransparentColor?: boolean;
};

export type LensExtensionProps = {
  lensEnabled?: boolean;
  lensSelection?: number;
  lensRadius?: number;
  lensBorderRadius?: number;
  colors?: Color[]; // List of colors to use for the color palette.
  lensBorderColor?: Color;
};

export type VivExtensionProps = ColorPerChannelExtensionProps &
  AdditiveColormapExtensionProps &
  LensExtensionProps &
  LinearDeconvExtensionProps &
  CubicDeconvExtensionProps & { deconvolutionType?: 'linear' | 'cubic' };

// types to be refined _if_ on LayerProps
export type PreciseLayerProps<S extends string[]> = {
  selections: PixelSourceSelection<S>[]; // Selection to be used for fetching data.
  contrastLimits: [begin: number, end: number][]; // List of [begin, end] values to control each channel's ramp function.
  dtype?: keyof typeof DTYPE_VALUES; // Data type of the image.
};

export interface MultiScaleImageLayerProps<S extends string[] = []>
  extends CustomTileLayerProps,
    Partial<Omit<TileLayerProps, 'getTileData' | 'onTileLoad' | 'onTileError' | 'onTileUnload'>>,
    PreciseLayerProps<S>,
    VivExtensionProps {
  layerOpacities: number[] | number; // List of opacities for each channel.
  layersVisible: boolean[] | boolean; // List of boolean values for each channel for whether or not it is visible.
  gammaValues?: number[] | number; // List of gamma values to use for each channel.
  contrastValues?: number[] | number; // List of contrast values to use for each channel (currently only used in bitmap layers)
  brightnessValues?: number[] | number; // List of brightness values to use for each channel (currently only used in bitmap layers)
  layerSource: PixelSource<S>; // Image pyramid to render.
  domain?: [min: number, max: number]; // Override for the possible max/min values (i.e something different than 65535 for uint16/'<u2').
  id: string; // Unique identifier for this layer.
  onTileError?: any; // Custom override for handle tile fetching errors.
  onHover?: any; // Hook function from deck.gl to handle hover objects.
  maxRequests?: number; // Maximum parallel ongoing requests allowed before aborting.
  onClick?: any; // Hook function from deck.gl to handle clicked-on objects.
  excludeBackground?: boolean; // Whether to exclude the background image. The background image is also excluded for opacity!=1.
  extensions?: any[]; // [deck.gl extensions](https://deck.gl/docs/developer-guide/custom-layers/layer-extensions) to add to the layers.
  skipWebWorker?: boolean; // Whether to skip using a web worker for fetching tiles.
  maxChannels: number; // Maximum number of channels to render.
  baseImageSource?: PixelSource<S>; // Image pyramid for the base image to scale against.
  loadChannelsWhenHidden?: boolean; // Whether to load channels when they are hidden.
  overviewLayer?: boolean; // Whether this layer is an overview layer.
  viewerIndex: number; // Index of the viewer.
  isBackground?: boolean; // Whether this layer is a background layer.
  isOverlay?: boolean; // Whether this layer is an overlay layer, or a base layer.
}

export type BoundingBox =
  | [number, number, number, number]
  | {
      left: number;
      top: number;
      right: number;
      bottom: number;
    }
  | {
      west: number;
      north: number;
      east: number;
      south: number;
    };
