import { Typography } from '@mui/material';
import { assignGenericClusters } from 'api/experimentResults';
import useMainFilters from 'components/SearchFilters/hooks/useMainFilters';
import { SlideResultsSummaryData } from 'interfaces/experimentResults';
import { find, get, isEmpty, isNumber, map, some, toNumber } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useMemo, useState } from 'react';
import queryClient from 'utils/queryClient';
import { useClusterTypeOptions } from 'utils/queryHooks/uiConstantsHooks';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { PlatformStepper } from '../PlatformStepper';
import { MapGenericClustersToClusterTypes } from './MapGenericClustersToClusterTypes';
import SelectSlidesAndOrchestrationsForReassignment from './SelectSlidesAndOrchestrationsForReassignment';

interface AssignGenericClustersFormProps {
  onClose: () => void;
}

const AssignGenericClustersForm: FunctionComponent<React.PropsWithChildren<AssignGenericClustersFormProps>> = ({
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    queryParams: { filters },
  } = useEncodedFilters();
  const { isStudyIdSpecific } = useMainFilters();

  const studyId = get(filters, 'studyId', undefined);
  const studyIdIsSpecific = isStudyIdSpecific(studyId);

  const [selectedOrchestrationId, setSelectedOrchestrationId] = useState<string | null>(null);
  const [selectedSlideResults, setSelectedSlideResults] = useState<SlideResultsSummaryData[]>([]);
  const [clusterAssignmentMap, setClusterClusterAssignmentMap] = useState<Record<number, string>>({});
  const [activeStep, setActiveStep] = useState(0);

  const noSelectedSlides = isEmpty(selectedSlideResults);
  const noOrchestrationSelected = !selectedOrchestrationId;
  const noClusterMappings = isEmpty(clusterAssignmentMap);
  const { clusterTypeOptions, isLoadingClusterTypes } = useClusterTypeOptions();

  const hasInvalidClusterMappings = some(
    clusterAssignmentMap,
    (clusterType, genericCluster) =>
      !isNumber(toNumber(genericCluster)) ||
      isNaN(toNumber(genericCluster)) ||
      toNumber(genericCluster) < 0 ||
      !clusterType ||
      !find(clusterTypeOptions, { id: clusterType })
  );

  const isStepFailed = useMemo(
    () => ({
      0: !studyIdIsSpecific || ((noSelectedSlides || noOrchestrationSelected) && activeStep === 1),
      1:
        !studyIdIsSpecific ||
        ((isLoadingClusterTypes || noClusterMappings || hasInvalidClusterMappings) && activeStep === 1),
    }),
    [
      studyIdIsSpecific,
      isLoadingClusterTypes,
      noSelectedSlides,
      noOrchestrationSelected,
      noClusterMappings,
      hasInvalidClusterMappings,
      activeStep,
    ]
  );

  const steps = [
    {
      label: 'Select Slides and Orchestrations',
      optional: false,
      content: (
        <SelectSlidesAndOrchestrationsForReassignment
          selectedSlideResults={selectedSlideResults}
          selectedOrchestrationId={selectedOrchestrationId}
          setSelectedSlideResults={setSelectedSlideResults}
          setSelectedOrchestrationId={setSelectedOrchestrationId}
        />
      ),
    },
    {
      label: 'Create Mappings between Generic Clusters and Cluster Types',
      content: (
        <MapGenericClustersToClusterTypes
          clusterAssignmentMap={clusterAssignmentMap}
          setClusterAssignmentMap={setClusterClusterAssignmentMap}
        />
      ),
    },
  ];

  const assignGenericClustersMutation = useMutationWithErrorSnackbar({
    mutationFn: assignGenericClusters,
    onSuccess: () => {
      queryClient.invalidateQueries(['procedure']);
      queryClient.invalidateQueries(['pendingSlide']);
    },
    mutationDescription: 'Assign Generic Clusters',
  });

  return studyIdIsSpecific ? (
    <PlatformStepper
      handleSubmit={async () => {
        try {
          await assignGenericClustersMutation.mutateAsync({
            slideIds: map(selectedSlideResults, 'slideId'),
            clusterAssignmentMap,
            orchestrationId: selectedOrchestrationId,
            studyId,
          });
          enqueueSnackbar('Generic Clusters assigned successfully', { variant: 'success' });
          onClose();
        } catch (error) {
          console.error(error);
        }
      }}
      steps={steps}
      setActiveStepForValidation={setActiveStep}
      isStepFailed={isStepFailed}
    />
  ) : (
    <Typography variant="subtitle1">Please select a specific study to assign generic clusters.</Typography>
  );
};

export default AssignGenericClustersForm;
