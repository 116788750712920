import useId from '@mui/material/utils/useId';
import React, { FunctionComponent, useEffect } from 'react';
import { useGetNameOverrideOrDisplayNameWithContext } from 'utils/features/contextHooks';
import { CorrelationMatrix, generateDendogram } from './dendogram';
import './dendogram.scss';

interface Props {
  data: CorrelationMatrix;
}

const DendrogramChart: FunctionComponent<React.PropsWithChildren<Props>> = ({ data }) => {
  const id = useId();
  const { getNameOverrideOrDisplayNameWithContext, isLoadingFormatterData } =
    useGetNameOverrideOrDisplayNameWithContext(false);

  useEffect(() => {
    if (isLoadingFormatterData) {
      return;
    }
    return generateDendogram({
      parent: '#' + id,
      data,
      getNameOverrideOrDisplayNameWithContext,
    });
  }, [data, id, isLoadingFormatterData, getNameOverrideOrDisplayNameWithContext]);

  return (
    <>
      <div id="d3tooltip">
        <div id="value" />
      </div>
      <div id={id} />
    </>
  );
};

export default DendrogramChart;
