import { Grid, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getTodoTypes } from 'api/annotationAssignments';
import TreeCheckboxMultiSelect from 'components/atoms/TreeCheckboxSelect';
import DashboardTabs from 'components/DashboardTabs';
import { TodoDefinition, TodoOption } from 'interfaces/annotation';
import { compact, find, flatMap, indexOf, map } from 'lodash';
import React, { useMemo, useState } from 'react';
import useTaxonomy from 'utils/queryHooks/taxonomy/useTaxonomy';
import { useCasesParams } from 'utils/useCasesParams';
import AnotherAssignment from './Tabs/AnotherAssignment';
import CellRules from './Tabs/CellRules';
import PresetsTodoTypes from './Tabs/PresetsTodoTypes';

interface ClassesToAnnotateSelectionProps {
  selectedClassesToAnnotate: TodoOption[];
  onClassesSelected: (classes: TodoOption[]) => void;
}

const ClassesToAnnotateSelection: React.FC<ClassesToAnnotateSelectionProps> = ({
  selectedClassesToAnnotate,
  onClassesSelected,
}) => {
  const theme = useTheme();
  const { casesParams } = useCasesParams();
  const studyId = casesParams.studyId ?? casesParams.filters?.studyId;

  const tabs = ['Preset', 'Taxonomy', 'Assignment', 'Cell Rules'];
  const [activeTab, setActiveTab] = useState<string>('Preset');
  const activeTabIndex = indexOf(tabs, activeTab) !== -1 ? indexOf(tabs, activeTab) : 0;

  const selectedClassesToAnnotateNames = useMemo(
    () => compact(map(selectedClassesToAnnotate, 'name')) ?? [],
    [selectedClassesToAnnotate]
  );

  const {
    data: todoTypes,
    isLoading: isLoadingTodoTypes,
    isError: isErrorTodoTypes,
  } = useQuery(['todoTypes'], getTodoTypes);
  const todoTypeOptions: TodoOption[] = useMemo(() => {
    return flatMap(todoTypes, 'options');
  }, [todoTypes]);

  const { data: taxonomies, isLoading: isLoadingTaxonomy, isError: isErrorTaxonomies } = useTaxonomy();

  const [selectedTodoType, setSelectedTodoType] = useState<TodoDefinition | null>(null);
  const [selectedTodoTypeOptions, setSelectedTodoTypeOptions] = useState<TodoOption[]>([]);
  const [assignmentId, setAssignmentId] = useState<number | null>(null);
  const [assignmentTodoOptions, setAssignmentTodoOptions] = useState<TodoOption[]>([]);
  const [selectedPanelId, setSelectedPanelId] = useState<string | null>(null);

  const changeTab = (newTab: string) => {
    setActiveTab(newTab);
  };

  const getClassToAnnotateFromTaxonomyId = (taxonomyId: string): TodoOption => {
    // after adding the type to the taxonomy table we should take the type from there
    const inferredClassName: 'polygon' | 'cell' =
      taxonomyId.includes('cell') && !taxonomyId.includes('segmentation') ? 'cell' : 'polygon';
    // search the name in todoTypes to get the color and className
    const todoType = find(todoTypeOptions, (todoTypeOption) => todoTypeOption.name === taxonomyId);
    const taxonomy = find(taxonomies, (curTaxonomy) => curTaxonomy.path === taxonomyId);
    return {
      name: taxonomyId,
      color: taxonomy?.defaultColor ?? todoType?.color ?? theme.palette.primary.main,
      className: todoType?.className ?? inferredClassName,
      displayName: taxonomy?.name || taxonomyId,
    };
  };

  // When taxonomy is selected- add it to the list of classes to annotate
  const handleTaxonomiesChange = (taxonomyIds: string[]) => {
    const newClassesToAnnotate: TodoOption[] = map(taxonomyIds, (taxonomyId) => {
      const previousClassToAnnotate = find(selectedClassesToAnnotate, { name: taxonomyId });
      if (previousClassToAnnotate) {
        return previousClassToAnnotate;
      }
      return getClassToAnnotateFromTaxonomyId(taxonomyId);
    });
    onClassesSelected(newClassesToAnnotate);
  };

  const border = `1px solid ${theme.palette.mode == 'light' ? 'lightgrey' : 'rgb(81, 81, 81)'}`;

  return (
    <Grid
      item
      xs={6}
      container
      direction="column"
      sx={{
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
        borderRadius: 0,
        borderLeft: border,
      }}
      height="100%"
    >
      <Grid
        item
        sx={{
          px: 1,
          borderRadius: 0,
          borderBottom: border,
        }}
      >
        <DashboardTabs tabsDisplayTexts={tabs} tabs={tabs} changeTab={changeTab} defaultValueIndex={activeTabIndex} />
      </Grid>
      <Grid item xs={true} container width="100%">
        {activeTab === 'Preset' && (
          <PresetsTodoTypes
            selectedClassesToAnnotate={selectedClassesToAnnotate}
            onClassesSelected={onClassesSelected}
            selectedTodoType={selectedTodoType}
            setSelectedTodoType={setSelectedTodoType}
            selectedTodoTypeOptions={selectedTodoTypeOptions}
            setSelectedTodoTypeOptions={setSelectedTodoTypeOptions}
          />
        )}
        {activeTab === 'Taxonomy' && (
          <TreeCheckboxMultiSelect
            selectedTaxonomyIds={selectedClassesToAnnotateNames}
            onSelectTaxonomyIds={handleTaxonomiesChange}
            isLoading={isLoadingTaxonomy || isLoadingTodoTypes}
            isError={isErrorTaxonomies || isErrorTodoTypes}
          />
        )}
        {activeTab === 'Assignment' && (
          <AnotherAssignment
            selectedClassesToAnnotate={selectedClassesToAnnotate}
            onClassesSelected={onClassesSelected}
            assignmentId={assignmentId}
            setAssignmentId={setAssignmentId}
            assignmentTodoOptions={assignmentTodoOptions}
            setAssignmentTodoOptions={setAssignmentTodoOptions}
          />
        )}
        {activeTab === 'Cell Rules' && (
          <CellRules
            selectedClassesToAnnotate={selectedClassesToAnnotate}
            onClassesSelected={onClassesSelected}
            studyId={studyId}
            getClassToAnnotateFromTaxonomyId={getClassToAnnotateFromTaxonomyId}
            isLoading={isLoadingTaxonomy || isLoadingTodoTypes}
            isError={isErrorTaxonomies || isErrorTodoTypes}
            selectedPanelId={selectedPanelId}
            setSelectedPanelId={setSelectedPanelId}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ClassesToAnnotateSelection;
