import { values } from 'lodash';

export interface FormFieldBase {
  id: string;
  label: string;
  type: string;
  options?: DefaultOptions;
}

type DefaultOptions = {
  required?: boolean;
};

export type TextReviewField = FormFieldBase & {
  type: 'text';
  options?: {
    numerical?: boolean;
    placeholder?: string;
  };
};

export type SelectReviewField = FormFieldBase & {
  type: 'select';
  options: {
    style: 'default' | 'toggle-button';
    source: FieldSourceId;
  };
};

export type MultiSelectReviewField = FormFieldBase & {
  type: 'multiselect';
  options: {
    source: FieldSourceId;
  };
};

export type CheckboxField = FormFieldBase & {
  type: 'checkbox';
  options?: {
    default?: boolean;
  };
};

// field that is a group of fields, like a section
export type GroupField = FormFieldBase & {
  type: 'group';
  options: {
    fields: FormField[];
  };
};

export type FormField = TextReviewField | SelectReviewField | MultiSelectReviewField | CheckboxField | GroupField;

export type FormFieldType = FormField['type'];

export interface FieldSourceOption {
  id: string;
  label: string;
}

export type FieldSourceId = string;

export interface FieldSource {
  id: FieldSourceId;
  options: FieldSourceOption[];
}

export type ApplicableContext = Partial<{
  slideIds: string[];
  labIds: string[];
  studyIds: string[];
  allowedEmails: string[];
}>;

export enum FormType {
  ScoreByPathologist = 'score_by_pathologist',
}

export const formTypes = values(FormType);

export interface ReviewFormBase {
  applicableContext: ApplicableContext;
  fields: FormField[];
  type: FormType;
}

export interface ReviewForm {
  id: string;
  type: FormType;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  isSlideSpecific: boolean;
  applicableContext: ApplicableContext;
  fields: FormField[];
}

export interface ReviewFormNewResponse {
  filledFields: FilledFormFields;
}

export interface FormResponse {
  id: string;
  formId: string;
  filledFields: FilledFormFields;
  context: ResponseContext;
  submissionDate: Date;
  deletedAt?: Date;
  authorId: string;
}

export interface ResponseAuthor {
  id: string;
  email?: string;
  name?: string;
}

export interface FormResponseWithAuthor extends FormResponse {
  author: ResponseAuthor;
}

export interface FilledFormFields {
  [key: string]: string | string[] | boolean;
}

export interface ResponseContext {
  slideId?: string;
  caseId: number;
  studyId: string;
  labId?: string;
}
