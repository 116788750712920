import { keyBy } from 'lodash';

export interface ClassConfig {
  id?: string;
  dataItems?: string[];
  ignore?: boolean;
  color?: string;
  isPoint?: boolean;
  hollowShape?: boolean;
}

export interface MetaModel {
  description?: string;
  projectName?: string;
  end2end?: boolean;
  studyId?: string;
  modelId?: string;
  tissueTypes?: string[];
  stainTypes?: string[];
  trainingType?: 'segmentation' | 'detection' | 'classification';
  modelType?: string;
  deps?: {
    assignmentIds?: string[];
    classesConfig?: Record<string, ClassConfig | { [key: string]: any }>;
  };
}

export interface Model {
  id?: string;
  date?: string;
  url: string;
  meta: MetaModel;
  hashData?: string;
  hashDeps?: string;
}

export interface ModelResponse {
  items: Model[];
  totalPages: number;
  count: number;
  pageSize: number;
  page: number;
}

// According to algo/src/primitives/schemas/training.py
export const trainingTypeOptions = [
  {
    text: 'Segmentation',
    value: 'segmentation',
  },
  {
    text: 'Detection',
    value: 'detection',
  },
  {
    text: 'Classification',
    value: 'classification',
  },
];

export const trainingTypesByValue = keyBy(trainingTypeOptions, 'value');
