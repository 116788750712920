import { useCallback } from 'react';
import { useFormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { findFormatterByKey, getFeatureDisplayName, getNameOverrideOrDisplayName } from '.';

export const useGetFeatureDisplayNameWithContext = (addStain: boolean) => {
  const formatBracketsOptions = useFormatBracketsOptions(addStain);

  const isLoadingFormatterData = formatBracketsOptions.isLoading;

  const getFeatureDisplayNameWithContext = useCallback(
    (featureKey: string) => {
      return getFeatureDisplayName(featureKey, formatBracketsOptions);
    },
    [formatBracketsOptions]
  );

  return { getFeatureDisplayNameWithContext, isLoadingFormatterData };
};

export const useFindFormatterByKeyWithContext = (addStain: boolean) => {
  const formatBracketsOptions = useFormatBracketsOptions(addStain);

  const isLoadingFormatterData = formatBracketsOptions.isLoading;

  const findFormatterByKeyWithContext = useCallback(
    (featureKey: string) => {
      return findFormatterByKey(featureKey, {
        featureDisplayName: getFeatureDisplayName(featureKey, {
          ...formatBracketsOptions,
          addStain,
        }),
        context: formatBracketsOptions,
      });
    },
    [formatBracketsOptions, addStain]
  );

  return { findFormatterByKeyWithContext, isLoadingFormatterData };
};

export const useGetNameOverrideOrDisplayNameWithContext = (addStain: boolean) => {
  const formatBracketsOptions = useFormatBracketsOptions(addStain);

  const isLoadingFormatterData = formatBracketsOptions.isLoading;

  const getNameOverrideOrDisplayNameWithContext = useCallback(
    (featureKey: string) => {
      return getNameOverrideOrDisplayName(featureKey, formatBracketsOptions);
    },
    [addStain, formatBracketsOptions]
  );
  return { getNameOverrideOrDisplayNameWithContext, isLoadingFormatterData };
};
