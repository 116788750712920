import { Grid, TextField, TextFieldProps, Typography } from '@mui/material';
import React from 'react';
import { HeaderCellEditorProps } from '.';
import { NumberField, RangeField } from 'interfaces/genericFields';

const HeaderNumericalRangeInput = <R, V, Context extends {}>(
  props: HeaderCellEditorProps<R, V, Context, NumberField | RangeField>
) => {
  const { onChange, value, field } = props;

  const handleStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ start: event.target.value, end: value?.end });
  };

  const handleEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ start: value?.start, end: event.target.value });
  };

  const standardInputProps: TextFieldProps['InputProps'] = {
    inputProps: {
      min: field.min,
      max: field.max,
      step: !isNaN(Number(field.step))
        ? field.step
        : !isNaN(Number(field.max)) && !isNaN(Number(field.max))
        ? Math.abs(field.max - field.min) / 100
        : undefined,
    },
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between" flexWrap="nowrap">
      <Grid item flexGrow={2}>
        <TextField
          fullWidth
          label="From"
          type="number"
          value={value?.start || ''}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={standardInputProps}
          size="small"
          onChange={handleStartChange}
        />
      </Grid>
      <Grid item>
        <Typography variant="body1" margin={1}>
          <strong>-</strong>
        </Typography>
      </Grid>
      <Grid item flexGrow={2}>
        <TextField
          fullWidth
          value={value?.end || ''}
          label="To"
          type="number"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={standardInputProps}
          onChange={handleEndChange}
        />
      </Grid>
    </Grid>
  );
};

export default HeaderNumericalRangeInput;
