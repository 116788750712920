import { Checkbox } from '@mui/material';
import { useRowSelectionContext } from 'components/atoms/RowSelectionContext';
import React from 'react';

interface Props {
  numRows: number;
}

const SelectAllCardsCheckbox = ({ numRows }: Props) => {
  const { allSelected, someSelected, selectAll, clearSelection } = useRowSelectionContext();

  return (
    <Checkbox
      data-cy="select-all-cards-checkbox"
      indeterminate={!allSelected(numRows) && someSelected(numRows)}
      checked={allSelected(numRows)}
      onClick={() => {
        if (allSelected(numRows)) {
          clearSelection();
        } else {
          selectAll();
        }
      }}
    />
  );
};

export default SelectAllCardsCheckbox;
