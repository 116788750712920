/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-this-alias */
// OpenSeadragon canvas Overlay plugin 0.0.2 based on svg overlay plugin

const OSDCanvasOverlayPlugin = {
  wrap(OpenSeadragon) {
    if (!OpenSeadragon) {
      console.error('[openseadragon-canvas-overlay] requires OpenSeadragon');
      return;
    }

    /* eslint-disable func-names */
    /* eslint-disable no-underscore-dangle */
    /* eslint-disable no-use-before-define */
    /* eslint-disable no-mixed-operators */
    OpenSeadragon.Viewer.prototype.canvasOverlay = function (options) {
      if (this._canvasOverlayInfo) {
        return this._canvasOverlayInfo;
      }

      this._canvasOverlayInfo = new Overlay(this, options);
      return this._canvasOverlayInfo;
    };

    const Overlay = function (viewer, options) {
      const self = this;
      this._viewer = viewer;

      this._containerWidth = 0;
      this._containerHeight = 0;

      this._canvasdiv = document.createElement('div');
      this._canvasdiv.style.position = 'absolute';
      this._canvasdiv.style.left = 0;
      this._canvasdiv.style.top = 0;
      this._canvasdiv.style.width = '100%';
      this._canvasdiv.style.height = '100%';

      this._viewer.canvas.appendChild(this._canvasdiv);

      this._canvas = document.createElement('canvas');

      this._canvasdiv.appendChild(this._canvas);

      this.onRedraw = options.onRedraw || function () {};
      this.clearBeforeRedraw = typeof options.clearBeforeRedraw !== 'undefined' ? options.clearBeforeRedraw : true;

      this._viewer.addHandler('update-viewport', () => {
        self.resize();
        self._updateCanvas();
      });

      this._viewer.addHandler('open', () => {
        self.resize();
        self._updateCanvas();
      });
    };

    Overlay.prototype = {
      canvas() {
        return this._canvas;
      },
      context2d() {
        return this._canvas.getContext('2d');
      },
      clear() {
        this._canvas.getContext('2d').clearRect(0, 0, this._containerWidth, this._containerHeight);
      },
      resize() {
        if (this._containerWidth !== this._viewer.container.clientWidth) {
          this._containerWidth = this._viewer.container.clientWidth;
          this._canvasdiv.setAttribute('width', this._containerWidth);
          this._canvas.setAttribute('width', this._containerWidth);
        }

        if (this._containerHeight !== this._viewer.container.clientHeight) {
          this._containerHeight = this._viewer.container.clientHeight;
          this._canvasdiv.setAttribute('height', this._containerHeight);
          this._canvas.setAttribute('height', this._containerHeight);
        }
        this._viewportOrigin = new OpenSeadragon.Point(0, 0);
        const boundsRect = this._viewer.viewport.getBounds(true);
        this._viewportOrigin.x = boundsRect.x;
        this._viewportOrigin.y = boundsRect.y * this.imgAspectRatio;

        this._viewportWidth = boundsRect.width;
        this._viewportHeight = boundsRect.height * this.imgAspectRatio;
        const image1 = this._viewer.world.getItemAt(0);
        this.imgWidth = image1.source.dimensions.x;
        this.imgHeight = image1.source.dimensions.y;
        this.imgAspectRatio = this.imgWidth / this.imgHeight;
      },
      _updateCanvas() {
        const viewportZoom = this._viewer.viewport.getZoom(true);
        const image1 = this._viewer.world.getItemAt(0);
        const zoom = image1.viewportToImageZoom(viewportZoom);

        const x =
          ((this._viewportOrigin.x / this.imgWidth - this._viewportOrigin.x) / this._viewportWidth) *
          this._containerWidth;
        const y =
          ((this._viewportOrigin.y / this.imgHeight - this._viewportOrigin.y) / this._viewportHeight) *
          this._containerHeight;

        if (this.clearBeforeRedraw) this.clear();
        this._canvas.getContext('2d').translate(x, y);
        this._canvas.getContext('2d').scale(zoom, zoom);
        this.onRedraw();
        this._canvas.getContext('2d').setTransform(1, 0, 0, 1, 0, 0);
      },
    };
  },
};

export default OSDCanvasOverlayPlugin;
