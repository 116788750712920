export default `\
/*
 * Each matrix will have 3 coefficients for each RGB channel:
 * coefficients0            | coefficients1             | coefficients2               | coefficients3               | coefficients4               | coefficients5               | coefficients6
 * [[ r_c_1, r_c_2, r_c_3], | [[ r_c_4, r_c_5, r_c_6],  | [[ r_c_7, r_c_8, r_c_9],    | [[ r_c_10, r_c_11, r_c_12], | [[ r_c_13, r_c_14, r_c_15], | [[ r_c_16, r_c_17, r_c_18], | [[ r_c_19, r_c_20, 0.0],
 *  [ g_c_1, g_c_2, g_c_3], |  [ g_c_4, g_c_5, g_c_6],  |  [ g_c_7, g_c_8, g_c_9],    |  [ g_c_10, g_c_11, g_c_12], |  [ g_c_13, g_c_14, g_c_15], |  [ g_c_16, g_c_17, g_c_18], |  [ g_c_19, g_c_20, 0.0],
 *  [ b_c_1, b_c_2, b_c_3]] |  [ b_c_4, b_c_5, b_c_6]]  |  [ b_c_7, b_c_8, b_c_9]]    |  [ b_c_10, b_c_11, b_c_12]] |  [ b_c_13, b_c_14, b_c_15]] |  [ b_c_16, b_c_17, b_c_18]] |  [ b_c_19, b_c_20, 0.0]]
 * 
 * Where every set of c_1-20 are the 20 coefficients for the cubic polynomial combination for each RGB channel:
 * (c_1      + c_2*r      + c_3*g      + c_4*b      + c_5*r^2    + c_6*r*g    + c_7*r*b  + c_8*g^2    + c_9*g*b    + c_10*b^2 +
 *  c_11*r^3 + c_12*r^2*g + c_13*r^2*b + c_14*r*g^2 + c_15*r*g*b + c_16*r*b^2 + c_17*g^3 + c_18*g^2*b + c_19*g*b^2 + c_20*b^3)
 */
// Optical density calculation
float odr1 = -log(max(color.rgb.r, 1e-6));  // avoid log(0)
float odg1 = -log(max(color.rgb.g, 1e-6));  // avoid log(0)
float odb1 = -log(max(color.rgb.b, 1e-6));  // avoid log(0)

// Calculate polynomial features for R, G, B in the optical density space
float odr2 = odr1 * odr1;
float odg2 = odg1 * odg1;
float odb2 = odb1 * odb1;

float odr3 = odr2 * odr1;
float odg3 = odg2 * odg1;
float odb3 = odb2 * odb1;

// Group features into vec3 to multiply them with their corresponding coefficient matrix
vec3 features0 = vec3(1.0,                 // [0,0,0] constant term
                      odr1,                // [1,0,0] r
                      odg1);               // [0,1,0] g

vec3 features1 = vec3(odb1,                // [0,0,1] b
                      odr2,                // [2,0,0] r²
                      odr1 * odg1);        // [1,1,0] r*g

vec3 features2 = vec3(odr1 * odb1,         // [1,0,1] r*b
                      odg2,                // [0,2,0] g²
                      odg1 * odb1);        // [0,1,1] g*b

vec3 features3 = vec3(odb2,                // [0,0,2] b²
                      odr3,                // [3,0,0] r³
                      odr2 * odg1);        // [2,1,0] r²*g

vec3 features4 = vec3(odr2 * odb1,         // [2,0,1] r²*b
                      odr1 * odg2,         // [1,2,0] r*g²
                      odr1 * odg1 * odb1); // [1,1,1] r*g*b
 
vec3 features5 = vec3(odr1 * odb2,         // [1,0,2] r*b²
                      odg3,                // [0,3,0] g³
                      odg2 * odb1);        // [0,2,1] g²*b

vec3 features6 = vec3(odg1 * odb2,         // [0,1,2] g*b²
                      odb3,                // [0,0,3] b³
                      0.0);                // [0,0,0] Padding

vec3 deconvolution = (
    features0 * coefficients0
    + features1 * coefficients1
    + features2 * coefficients2
    + features3 * coefficients3
    + features4 * coefficients4
    + features5 * coefficients5
    + features6 * coefficients6
) + intercept;

vec3 deconvolutionIntensities = exp(-deconvolution);
color.rgba = vec4(deconvolutionIntensities.r, deconvolutionIntensities.g, deconvolutionIntensities.b, color.rgba.a);
`;
