import { finishedTryToAuthenticateFromLocalStorage, loginSuccess, logout, renewToken } from 'redux/actions/auth';
import * as AT from 'redux/actionTypes/index';
import browserStorage from 'store';

// eslint-disable-next-line import/no-cycle
import AuthService from 'utils/auth/AuthService';

const ACCESS_TOKEN_KEY = 'accessToken';
const EXPIRES_AT_KEY = 'expiresAt';
const SCOPE_KEY = 'scope';
const PROFILE_KEY = 'profile';

let tokenRenewalTimeout = null;

const authMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case AT.LOGIN_SUCCESS:
      scheduleRenewal(action.payload.expiresAt, store.dispatch);
      storeInLocalStorage(
        action.payload.accessToken,
        action.payload.expiresAt,
        action.payload.scope,
        action.payload.profile
      );
      fullStoryIntegration(action.payload.profile.name, action.payload.profile.labId);
      return next(action);
    case AT.LOGOUT:
      AuthService.logout();
      if (tokenRenewalTimeout) {
        clearTimeout(tokenRenewalTimeout);
      }
      deleteFromLocalStorage();
      return next(action);
    case AT.TRY_AUTHENTICATE_FROM_LOCAL_STORAGE:
      tryAuthenticateFromLocalStorage(action, next, store);
      break;
    default:
  }
  return next(action);
};

const getCurrentProfile = async (accessToken) => {
  return browserStorage.get(PROFILE_KEY) || (await AuthService.getUserInfo(accessToken));
};

const tryAuthenticateFromLocalStorage = async (action, next, store) => {
  let accessToken = browserStorage.get(ACCESS_TOKEN_KEY);
  let expiresAt = Number(browserStorage.get(EXPIRES_AT_KEY));
  let scope = browserStorage.get(SCOPE_KEY);
  if (!accessToken || isNaN(expiresAt) || !scope) {
    next(finishedTryToAuthenticateFromLocalStorage());
    return;
  }
  try {
    const now = Date.now();
    const isExpired = expiresAt - now < 0;
    if (isExpired) {
      // Renew token
      const session = await AuthService.getNewSession();
      accessToken = session.accessToken;
      expiresAt = session.expiresAt;
      scope = session.scope;
    }
    scheduleRenewal(expiresAt, store.dispatch);
    const userInfo = action.payload || (await getCurrentProfile(accessToken));
    next(loginSuccess(userInfo, scope, accessToken, expiresAt));
  } catch (err) {
    next(finishedTryToAuthenticateFromLocalStorage());
  }
};

const storeInLocalStorage = (accessToken, expiresAt, scope, profile) => {
  browserStorage.set(ACCESS_TOKEN_KEY, accessToken);
  browserStorage.set(EXPIRES_AT_KEY, expiresAt);
  browserStorage.set(SCOPE_KEY, scope);
  browserStorage.set(PROFILE_KEY, profile);
};

const deleteFromLocalStorage = () => {
  browserStorage.remove(ACCESS_TOKEN_KEY);
  browserStorage.remove(EXPIRES_AT_KEY);
};

const scheduleRenewal = (expiresAt, dispatch) => {
  const delay = expiresAt - Date.now();
  tokenRenewalTimeout = setTimeout(() => {
    renewAccessToken(dispatch);
  }, delay);
};

const renewAccessToken = async (dispatch) => {
  try {
    const session = await AuthService.getNewSession();
    dispatch(renewToken(session.accessToken, session.scope));
    const profile = await getCurrentProfile(session.accessToken);
    storeInLocalStorage(session.accessToken, session.expiresAt, session.scope, profile);
    scheduleRenewal(session.expiresAt, dispatch);
  } catch (_) {
    dispatch(logout());
  }
};

const fullStoryIntegration = (userName, labId) => {
  const fullStory = window.FS;
  if (fullStory) {
    fullStory.identify(userName, {
      displayName: userName,
      // eslint-disable-next-line camelcase
      lab_id: labId,
    });
  }
};

export default authMiddleware;
