import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { AutocompleteNumbers } from 'components/atoms/Autocompletes/AutocompleteNumbers';
import GridBasedCalculationParams from 'interfaces/girdBasedCalculationParams';
import { every, get, isEmpty, map, omit, uniq } from 'lodash';
import React, { useMemo } from 'react';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';

interface GridBasedCalculationParamsStepProps {
  stain: string;
  gridBasedCalculationParams: GridBasedCalculationParams;
  setGridBasedCalculationParams: (params: GridBasedCalculationParams) => void;
}

const GridBasedCalculationParamsStep: React.FC<GridBasedCalculationParamsStepProps> = ({
  stain,
  gridBasedCalculationParams,
  setGridBasedCalculationParams,
}) => {
  const { isLoadingStainTypeOptions, stainTypeIdToDisplayName } = useStainTypeIdToDisplayName();
  const stainDisplayName = useMemo(() => stainTypeIdToDisplayName(stain), [stain, stainTypeIdToDisplayName]);

  const { gridSizeUm, minCellsThreshold, minAreaFractionThreshold } = gridBasedCalculationParams;

  const handleSizeChange = (selected: number[]) => {
    setGridBasedCalculationParams({
      ...gridBasedCalculationParams,
      gridSizeUm: uniq(selected),
    });
  };

  const handleMinCellsThresholdChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    size: number
  ) => {
    if (event.target.value === '') {
      const newMinCellsThresholdDict = { ...omit(minCellsThreshold, size) };
      setGridBasedCalculationParams({
        ...gridBasedCalculationParams,
        minCellsThreshold: newMinCellsThresholdDict,
      });
    } else {
      setGridBasedCalculationParams({
        ...gridBasedCalculationParams,
        minCellsThreshold: { ...minCellsThreshold, [size]: Number(event.target.value) },
      });
    }
  };

  const handleMinAreaFractionThreshold = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    size: number
  ) => {
    if (event.target.value === '') {
      const newMinAreaFractionThresholdDict = { ...omit(minAreaFractionThreshold, size) };
      setGridBasedCalculationParams({
        ...gridBasedCalculationParams,
        minAreaFractionThreshold: newMinAreaFractionThresholdDict,
      });
    } else {
      setGridBasedCalculationParams({
        ...gridBasedCalculationParams,
        minAreaFractionThreshold: { ...minAreaFractionThreshold, [size]: Number(event.target.value) },
      });
    }
  };

  const doesSizeHaveError = (size: number) => {
    return !areSizeParamsValid(get(minCellsThreshold, size.toString()), get(minAreaFractionThreshold, size.toString()));
  };

  const getSizeCellThresholdError = (size: number): string => {
    if (!isCellThresholdValueValid(get(minCellsThreshold, size.toString()))) {
      return 'Value must be greater than 1';
    } else {
      return '';
    }
  };

  const getSizeAreaFractionThresholdError = (size: number): string => {
    const value = get(minAreaFractionThreshold, size.toString());
    if (value !== undefined && value <= 0) {
      return 'Value must be greater than 0';
    } else if (value > 1) {
      return 'Value must be less or equal to 1';
    } else {
      return '';
    }
  };

  const hasGridSizeUmError = !isGridSizeUmValid(gridSizeUm);
  const hasSomeInnerSizeParamsError = !isGridBasedCalculationInnerSizesParamsValid(gridBasedCalculationParams);

  const hasSomeError = !isGridBasedCalculationParamsValid(gridBasedCalculationParams);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {isLoadingStainTypeOptions ? (
          <CircularProgress />
        ) : (
          <Typography color={hasSomeError ? 'error' : 'initial'}> {stainDisplayName}</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <AutocompleteNumbers
              value={gridSizeUm}
              label="Grid Sizes (um)"
              onChange={handleSizeChange}
              isError={hasGridSizeUmError}
              required
              limitTags={6}
            />
          </Grid>
          <Grid item>
            <Accordion disableGutters>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color={hasSomeInnerSizeParamsError ? 'error' : 'initial'}>
                  Min Cells and Area Fraction Threshold
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="column" spacing={2}>
                  {map(gridSizeUm, (size) => (
                    <Grid
                      key={`params-for-stain-${stainDisplayName}-size-${size}`}
                      item
                      container
                      direction="row"
                      spacing={2}
                    >
                      <Grid item>
                        <Typography key={size} variant="body2" color={doesSizeHaveError(size) ? 'red' : 'initial'}>
                          {`Grid Size ${size}:`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} container direction="column" spacing={1}>
                        <Grid item>
                          <TextField
                            key={`minCellsThreshold-${size}`}
                            label="Min Cells Threshold"
                            type="number"
                            value={minCellsThreshold[size] !== undefined ? minCellsThreshold[size] : ''}
                            onChange={(e) => handleMinCellsThresholdChange(e, size)}
                            error={Boolean(getSizeCellThresholdError(size))}
                            helperText={getSizeCellThresholdError(size)}
                            sx={{ maxWidth: 250 }}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            key={`minAreaFractionThreshold-${size}`}
                            label="Min Area Fraction Threshold"
                            type="number"
                            value={minAreaFractionThreshold[size] !== undefined ? minAreaFractionThreshold[size] : ''}
                            onChange={(e) => handleMinAreaFractionThreshold(e, size)}
                            inputProps={{ min: 0, max: 1 }}
                            error={Boolean(getSizeAreaFractionThresholdError(size))}
                            helperText={getSizeAreaFractionThresholdError(size)}
                            sx={{ maxWidth: 250 }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export const isGridSizeUmValid = (gridSizeUm: number[]) => {
  return !isEmpty(gridSizeUm);
};

export const isCellThresholdValueValid = (minCellsThresholdValue: number) => {
  return minCellsThresholdValue === undefined || minCellsThresholdValue > 1;
};

export const isAreaFractionThresholdValueValid = (minAreaFractionThresholdValue: number) => {
  return (
    minAreaFractionThresholdValue === undefined ||
    (minAreaFractionThresholdValue > 0 && minAreaFractionThresholdValue <= 1)
  );
};

export const areSizeParamsValid = (minCellsThresholdValue: number, minAreaFractionThresholdValue: number) =>
  isCellThresholdValueValid(minCellsThresholdValue) && isAreaFractionThresholdValueValid(minAreaFractionThresholdValue);

export const areInnerSizeParamsValid = (gridBasedCalculationParams: GridBasedCalculationParams, size: number) => {
  const { minCellsThreshold, minAreaFractionThreshold } = gridBasedCalculationParams;

  return areSizeParamsValid(get(minCellsThreshold, size.toString()), get(minAreaFractionThreshold, size.toString()));
};

export const isGridBasedCalculationInnerSizesParamsValid = (gridBasedCalculationParams: GridBasedCalculationParams) => {
  const { gridSizeUm } = gridBasedCalculationParams;
  return every(gridSizeUm, (size) => areInnerSizeParamsValid(gridBasedCalculationParams, size));
};

export const isGridBasedCalculationParamsValid = (gridBasedCalculationParams: GridBasedCalculationParams) => {
  const { gridSizeUm } = gridBasedCalculationParams;

  return isGridSizeUmValid(gridSizeUm) && isGridBasedCalculationInnerSizesParamsValid(gridBasedCalculationParams);
};

export default GridBasedCalculationParamsStep;
