import { ArcElement, Chart, ChartOptions, Title } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { PartialCohort } from 'interfaces/cohort_old';
import { useProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { first, flatMap, isEmpty, keys, map, uniq } from 'lodash';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { NULL_VALUE_DISPLAY_NAME, displayProcedureFieldValueName } from 'utils/helpers';
import { CategoricalChartKey, CountBy, getGroupByCount } from '../chart.util';
import { borderColorPalette, colorPalette } from '../chartsColorPallete';
import { NoDataChartPlaceholder } from './NoDataChartPlaceholder';
import { cohortToPieDataset, defaultPieChartOptions } from './pie.util';

Chart.register(ArcElement, Title, ChartDataLabels);
Chart.defaults.plugins.datalabels.display = false;
Chart.defaults.elements.arc.borderWidth = 1.5;

interface Props {
  cohorts: PartialCohort[];
  categoricalKey?: CategoricalChartKey;
  countBy?: CountBy;
  preview?: boolean;
  options?: ChartOptions<'doughnut'>;
}

const PieChart: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  cohorts,
  categoricalKey,
  countBy = 'Cases',
  preview = false,
  options,
}) => {
  const procedureFieldContext = useProceduresFieldsContext();

  const displayFieldName = displayProcedureFieldValueName(procedureFieldContext);

  const labels = uniq(flatMap(cohorts, (cohort) => keys(getGroupByCount(categoricalKey, cohort.procedures, countBy))));

  const displayLabelNames = map(labels, (label) => displayFieldName(categoricalKey.name, label));

  const cohortToDataset = (cohort: any, index: number) =>
    cohortToPieDataset(categoricalKey, labels, countBy, index, cohort);

  const datasets = map(cohorts, (cohort: any, index: number) => cohortToDataset(cohort, index));

  const data = { labels: displayLabelNames, datasets };

  if (preview) {
    return <PieChartPreview />;
  }

  if (
    isEmpty(displayLabelNames) ||
    (displayLabelNames.length === 1 && first(displayLabelNames) === NULL_VALUE_DISPLAY_NAME)
  ) {
    return <NoDataChartPlaceholder />;
  }

  return <Doughnut data={data} options={options || defaultPieChartOptions()} />;
};

const PieChartPreview: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const labels: string[] = [];
  const datasets = [
    {
      data: [1, 2],
      backgroundColor: [colorPalette[0], colorPalette[1]],
      borderColor: [borderColorPalette[0], borderColorPalette[1]],
    },
  ];
  const data = { labels, datasets };
  return <Doughnut data={data} width="150%" options={defaultPieChartOptions()} />;
};

export default PieChart;
