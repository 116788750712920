import { getSlideTags } from 'api/slides';
import { Permission } from 'interfaces/permissionOption';
import { SlideTagAssignment } from 'interfaces/slideTagAssignment';
import { usePermissions } from 'utils/usePermissions';
import { useQueryWithErrorAndRetrySnackbar } from 'utils/useQueryWithErrorAndRetrySnackbar';

export const getSlideTagsQueryKey = ({
  slideId,
  studyId,
  orchestrationId,
  labId,
}: {
  slideId: string;
  studyId?: string;
  orchestrationId?: string;
  labId: string;
}) => ['slideTags', slideId, { labId, studyId, orchestrationId }];

export const useQuerySlideTagsById = ({
  slideId,
  studyId,
  orchestrationId,
  labId,
  enabled = true,
}: {
  slideId: string;
  studyId?: string;
  orchestrationId?: string;
  labId: string;
  enabled: boolean;
}) => {
  const { hasPermission } = usePermissions();
  const hasTaggingPermissions = hasPermission(Permission.EditSlideTagsAssignments);

  const slideTagsQueryParams = { labId, studyId, orchestrationId };
  return useQueryWithErrorAndRetrySnackbar(
    getSlideTagsQueryKey({ slideId, ...slideTagsQueryParams }),
    async (): Promise<SlideTagAssignment[]> => getSlideTags(slideId, slideTagsQueryParams),
    {
      queriedEntityName: 'slide tags',
      enabled: enabled && hasTaggingPermissions && Boolean(slideId),
    }
  );
};
