import { GridRowId, GridRowModesModel, GridRowParams } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { reject } from 'lodash';
import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { createExternalLabelOption, deleteExternalLabelOption, updateExternalLabelOption } from 'api/externalLabels';
import { doesUpdatingRowHaveRequiredFields, isUpdatingRowUnique } from 'components/atoms/EditableDataGrid/helpers';
import { useCrudControlsColumns } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { ExternalLabel } from 'interfaces/externalLabel';
import { externalLabelFields } from 'interfaces/externalLabel/externalLabelFields';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { ExternalLabelRowChangesSummary } from './ExternalLabelRowChangesSummary';

const getExternalLabelError = ({
  id,
  apiRef,
}: GridRowParams<ExternalLabel> & {
  apiRef: React.MutableRefObject<GridApiCommunity>;
}) => {
  const hasDuplicateExternalLabel = !isUpdatingRowUnique({
    apiRef,
    rowId: id,
    uniqueFields: ['text'],
  });

  const missingRequiredFields = !doesUpdatingRowHaveRequiredFields({
    apiRef,
    rowId: id,
    requiredFields: ['text'],
  });

  return hasDuplicateExternalLabel
    ? 'Duplicate external label'
    : missingRequiredFields
    ? 'Missing required fields'
    : '';
};

export const useExternalLabelsColumns = ({
  apiRef,
  noRows,
  externalLabels,
  draftExternalLabels,
  rowModesModel,
  setDraftExternalLabels,
  setRowModesModel,
}: {
  noRows?: boolean;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  externalLabels: ExternalLabel[];
  draftExternalLabels: ExternalLabel[];
  rowModesModel: GridRowModesModel;
  setDraftExternalLabels: React.Dispatch<React.SetStateAction<ExternalLabel[]>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();

  const queryClient = useQueryClient();

  const commonMutationOptions = {
    onError: () => {
      setMutatingRowId(undefined);
    },
  };

  const createExternalLabelMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (newExternalLabel) => {
      queryClient.invalidateQueries(['externalLabels']);
      queryClient.setQueryData(['externalLabels'], (oldData: ExternalLabel[]) => [...oldData, newExternalLabel]);
    },
    mutationFn: createExternalLabelOption,
    mutationDescription: 'create external label',
  });
  const updateExternalLabelMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['externalLabels']);
      queryClient.setQueryData(['externalLabels'], (oldData: ExternalLabel[]) => {
        return [...reject(oldData, { id: mutatingRowId }), variables];
      });
    },
    mutationFn: updateExternalLabelOption,
    mutationDescription: 'update external label',
  });
  const deleteExternalLabelMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess(data, variables) {
      queryClient.invalidateQueries(['externalLabels']);
      queryClient.setQueryData(['externalLabels'], (oldData: ExternalLabel[]) => reject(oldData, { id: variables }));
    },
    mutationFn: deleteExternalLabelOption,
    mutationDescription: 'delete external label',
  });

  const columns = useCrudControlsColumns<ExternalLabel>({
    createMutation: createExternalLabelMutation,
    deleteMutation: deleteExternalLabelMutation,
    updateMutation: updateExternalLabelMutation,
    apiRef,
    rowModesModel,
    setRowModesModel,
    setDraftRows: setDraftExternalLabels,
    draftRows: draftExternalLabels,
    currentRows: externalLabels,
    rowTypeFields: externalLabelFields,
    getRowError: getExternalLabelError,
    noRows,
    getCancelEditConfirmationModalOptions: ({ isDraft, changes }) => ({
      title: `Cancel externalLabel ${isDraft ? 'creation' : 'update'}`,
      text: <ExternalLabelRowChangesSummary changes={changes} />,
    }),
    getSaveConfirmationModalOptions: ({ isDraft, changes }) => ({
      title: `${isDraft ? 'Create' : 'Update'} External Label`,
      text: <ExternalLabelRowChangesSummary changes={changes} />,
    }),
    getDeleteConfirmationModalOptions: () => ({
      title: 'Delete External Label',
      text: 'Are you sure you want to delete this external label?',
    }),
    mutatingRowId,
    setMutatingRowId,
  });

  return columns;
};
