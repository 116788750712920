import { Grid, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import CopyButton from 'components/atoms/Buttons/CopyButton/CopyButton';
import React, { ReactElement, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { getAwsTemporaryCredentials } from 'services/S3Utils';
import { useClipboard } from 'use-clipboard-copy';
import { useCurrentLabId } from 'utils/useCurrentLab';
import * as XLSX from 'xlsx';
import CLIInstructions from './CLIInstructions';
import UploadFiles from './UploadFiles';
import { IFormValues } from './UploadSlidesForm';
import { FieldContainer, FieldText, Label, SlideDirectoryFieldBox, UploadBox } from './UploadSlidesFormUI';

const MainBox = styled(UploadBox)(() => ({
  marginBottom: 0,
  gap: 10,
  padding: '0 10px 0px',
  marginTop: '20px',
}));

const ExcelBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fffbd7',
  fontSize: '12px',
  padding: '10px',

  'a:hover': {
    color: theme.palette.primary.dark,
  },
}));

const GenerateLink = styled(Box)(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '40px 20px',
  backgroundColor: theme.palette.grey[50],
}));

const GenerateLinkTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  width: '400px',
  'a:hover': {
    color: theme.palette.primary.dark,
  },
}));

interface Props {
  studyId: string;
  formProps: UseFormReturn<IFormValues, any> | any;
}

const GenerateCredentials = (props: Props): ReactElement => {
  const { studyId, formProps } = props;
  const location = useLocation();
  const clipboard = useClipboard();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [credentialsKeys, setCredentialsKeys] = useState({
    AccessKeyId: '',
    SecretAccessKey: '',
  });

  const [duringGenerate, setDuringGenerate] = useState(false);
  const [isGenerateFailed, setIsGenerateFailed] = useState(false);
  const [generateIsDisplay, setGenerateIsDisplay] = useState(true);

  const { labId } = useCurrentLabId();

  const slideDirectory = `s3://nuc-cst-us-east-2-${labId}/ATOM/${studyId}/Incoming/`;
  const region = 'us-east-2';

  const generateCredentials = async () => {
    setIsGenerateFailed(false);
    setDuringGenerate(true);
    const { Credentials } = await getAwsTemporaryCredentials(searchParams.get('labId'));
    setDuringGenerate(false);
    setGenerateIsDisplay(false);
    setCredentialsKeys(Credentials);
  };

  const exportExcelFile = () => {
    const data = [
      {
        AccessKeyId: credentialsKeys.AccessKeyId,
        SecretAccessKey: credentialsKeys.SecretAccessKey,
        SlideDirectory: slideDirectory,
        Region: region,
      },
    ];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'User Credentials');
    XLSX.writeFile(workbook, 'user-credentials.xlsx');
  };

  const credentials = () => {
    return (
      <>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{
            mb: 2,
          }}
        >
          <FieldContainer>
            <Label variant="body2">Copy link for slide directory</Label>
            <Grid container alignItems="center" justifyContent="space-between" sx={{ gap: '5px' }}>
              <SlideDirectoryFieldBox>{slideDirectory}</SlideDirectoryFieldBox>
              <CopyButton
                onCopy={() => {
                  clipboard.copy(slideDirectory);
                }}
              />
            </Grid>
          </FieldContainer>
        </Grid>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{
            mb: 2,
          }}
        >
          <div>
            <Label variant="body2">Access Key</Label>
            <Grid container alignItems="center" sx={{ gap: '5px' }}>
              <FieldText>{credentialsKeys?.AccessKeyId}</FieldText>
              <CopyButton
                onCopy={() => {
                  clipboard.copy(credentialsKeys?.AccessKeyId);
                }}
              />
            </Grid>
          </div>
          <div>
            <Label variant="body2">Secret Key</Label>
            <Grid container alignItems="center" sx={{ gap: '5px' }}>
              <FieldText>{credentialsKeys?.SecretAccessKey}</FieldText>
              <CopyButton
                onCopy={() => {
                  clipboard.copy(credentialsKeys?.SecretAccessKey);
                }}
              />
            </Grid>
          </div>
          <div>
            <Label variant="body2">Region</Label>
            <Grid container alignItems="center" sx={{ gap: '5px' }}>
              <FieldText>{region}</FieldText>
              <CopyButton
                onCopy={() => {
                  clipboard.copy(region);
                }}
              />
            </Grid>
          </div>
        </Grid>
        {credentialsKeys?.SecretAccessKey && credentialsKeys?.AccessKeyId && (
          <ExcelBox>
            <div>Please save your credentials in a secure place. </div>
            <div>
              Credentials will expire in 90 days.{' '}
              <Link color="primary" onClick={exportExcelFile} underline="none" sx={{ cursor: 'pointer' }}>
                Download as Excel
              </Link>
            </div>
          </ExcelBox>
        )}
      </>
    );
  };

  const generateLink = () => {
    return (
      <Box>
        <GenerateLink>
          <GenerateLinkTypography>
            {`By clicking "generate link" I confirm that I've read, understood and agree to the Nucleai `}
            <Link
              color="primary"
              underline="none"
              href="https://nucleai.ai/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              Terms and conditions
            </Link>
          </GenerateLinkTypography>
          <Button
            variant="contained"
            disableElevation
            disabled={duringGenerate}
            onClick={() => {
              generateCredentials();
            }}
          >
            {duringGenerate ? 'Generate...' : 'Generate a link'}
          </Button>
        </GenerateLink>
        {isGenerateFailed && <div className="failed-generate-credentials">Failed to generate credentials</div>}
      </Box>
    );
  };

  return (
    <>
      <MainBox data-testid="nucleai-storage-container">
        {generateIsDisplay ? generateLink() : credentials()}
        <CLIInstructions />
      </MainBox>
      <div className={classnames('section upload-slides-modal-textual-seperator')}>
        <span className="textual-seperator" />
        <span className="text">or</span>
        <span className="textual-seperator" />
      </div>
      <UploadFiles formProps={formProps} />
    </>
  );
};

export default GenerateCredentials;
