import { Checkbox, useTheme } from '@mui/material';
import { useRowSelectionContext } from 'components/atoms/RowSelectionContext';
import React from 'react';
interface Props {
  id: string | number;
}

const CardCheckbox = ({ id }: Props) => {
  const { isRowSelected, toggleSelection } = useRowSelectionContext();
  const theme = useTheme();

  return (
    <Checkbox
      data-cy={`card-checkbox-${id}`}
      sx={{
        background: `${theme.palette.custom.background.transparent.white} !important`,
        color: 'black',
        p: 1,
        borderRadius: 0,
        position: 'absolute',
        top: 5,
        left: 5,
      }}
      checked={isRowSelected(id)}
      onClick={(e) => {
        e.stopPropagation();
        toggleSelection(id);
      }}
    />
  );
};

export default CardCheckbox;
