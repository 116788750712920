import { useQuery } from '@tanstack/react-query';
import { getStainTypeFilteredIds } from 'api/stainTypes';
import { find, map } from 'lodash';
import { stringify } from 'qs';
import { useMemo } from 'react';
import { useStainTypeOptions } from 'utils/queryHooks/uiConstantsHooks';
import { useCurrentLabId } from 'utils/useCurrentLab';

export const useStudyStainTypeIds = (
  studyId: string,
  {
    enabled = true,
  }: {
    enabled?: boolean;
  } = {}
) => {
  const { labId } = useCurrentLabId();

  const paramsForStain = studyId ? { labId, filters: JSON.stringify({ studyId }) } : { labId };
  const encodedFiltersForStains = stringify(paramsForStain);

  const query = useQuery(
    ['stainsTypes', encodedFiltersForStains],
    ({ signal }) => getStainTypeFilteredIds(encodedFiltersForStains, signal),
    { enabled }
  );

  return query;
};

export const useStudyStainTypes = (
  studyId: string,
  {
    enabled = true,
  }: {
    enabled?: boolean;
  } = {}
) => {
  const query = useStudyStainTypeIds(studyId, { enabled });

  const { stainTypeOptions, isLoadingStainTypeOptions } = useStainTypeOptions();

  const stainTypeIds = query.data;

  const stainTypes = useMemo(() => {
    return map(stainTypeIds, (stainTypeId) => find(stainTypeOptions, (stainType) => stainType.id === stainTypeId));
  }, [stainTypeIds, stainTypeOptions]);

  return { ...query, stainTypes, isLoadingStainTypeOptions };
};
