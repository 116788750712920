import { join, times } from 'lodash';

import { MAX_CHANNELS } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/constants';

export default function generateChannelIntensityFragmentShader(numChannels, maxTextures) {
  const maxChannels = Math.min(MAX_CHANNELS, numChannels);
  const channelsPerAtlas = Math.ceil(maxChannels / maxTextures);
  let processIntensity = `\n  float intensities[${maxChannels}];\n`;
  processIntensity += `  int squareDim = ${Math.max(1, Math.ceil(Math.sqrt(channelsPerAtlas)))};\n`;
  for (let channelIndex = 0; channelIndex < maxChannels; channelIndex += 1) {
    processIntensity += `  if (\n`;
    processIntensity += `      ${channelIndex} < numChannels &&\n`;
    processIntensity += `      channelAtlasIndices[${channelIndex}] != -1 &&\n`;
    processIntensity += `      channelAtlasXOffsets[${channelIndex}] != -1 &&\n`;
    processIntensity += `      channelAtlasYOffsets[${channelIndex}] != -1`;
    processIntensity += `  ) {\n`;
    processIntensity += `      int atlasIndex = channelAtlasIndices[${channelIndex}];\n`;
    processIntensity += `      // Get the pixel position in the texture atlas\n`;
    processIntensity += `      int x = channelAtlasXOffsets[${channelIndex}];\n`;
    processIntensity += `      int y = channelAtlasYOffsets[${channelIndex}];\n`;
    processIntensity += `      float texX = (float(x) / float(squareDim)) + vTexCoord.x;\n`;
    processIntensity += `      float texY = (float(y) / float(squareDim)) + vTexCoord.y;\n`;
    processIntensity += `      float rValue = ${
      join(
        times(
          maxTextures,
          (textureIndex) =>
            `atlasIndex == ${textureIndex} ? float(texture(channelsAtlas${textureIndex}, vec2(texX,texY)).r) :`
        ),
        ' '
      ) + ' 0.;'
    }\n`;
    processIntensity += `      intensities[${channelIndex}] = rValue;\n`;
    processIntensity += `      DECKGL_PROCESS_INTENSITY(intensities[${channelIndex}], vec2(contrastMins[${channelIndex}], contrastMaxes[${channelIndex}]), gammaValues[${channelIndex}], channelOpacities[${channelIndex}] / 100.0);\n`;
    processIntensity += `  }\n`;
  }

  return `\
#define SHADER_NAME xr-layer-fragment-shader

precision highp float;
precision highp int;
precision highp SAMPLER_TYPE;

// our texture atlases (we limit them to match WebGL / uniform limits)
${join(
  times(maxTextures, (textureIndex) => `uniform SAMPLER_TYPE channelsAtlas${textureIndex};`),
  '\n'
)}
uniform int numChannels;
uniform int channelWidth;
uniform int channelHeight;

// range
uniform float contrastMins[${maxChannels}];
uniform float contrastMaxes[${maxChannels}];
uniform float channelOpacities[${maxChannels}];
uniform float gammaValues[${maxChannels}];

uniform int channelAtlasIndices[${maxChannels}];
uniform int channelAtlasXOffsets[${maxChannels}];
uniform int channelAtlasYOffsets[${maxChannels}];

in vec2 vTexCoord;


void main() {
  ${processIntensity}

  DECKGL_MUTATE_COLOR(gl_FragColor, intensities, vTexCoord);

  geometry.uv = vTexCoord;
  DECKGL_FILTER_COLOR(gl_FragColor, geometry);
}
`;
}
