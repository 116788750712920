import { GridRowParams } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import React from 'react';

import { doesUpdatingRowHaveRequiredFields, isUpdatingRowUnique } from 'components/atoms/EditableDataGrid/helpers';
import { ClusterType } from 'interfaces/clusterType';
import { idField } from 'interfaces/clusterType/clusterTypeFields';
import { uuidv4 } from 'utils/helpers';
import { ClusterTypeDraft } from './types';

export const generateDraftId = () => `draft-${uuidv4()}`;
export const getClusterTypeId = (row: ClusterType | ClusterTypeDraft) =>
  (row as ClusterTypeDraft).draftId ?? row.id ?? generateDraftId();

export const getClusterTypeError = ({
  id,
  apiRef,
}: GridRowParams<ClusterType> & {
  apiRef: React.MutableRefObject<GridApiCommunity>;
}) => {
  const hasDuplicateId = !isUpdatingRowUnique({
    apiRef,
    rowId: id,
    uniqueFields: ['id'],
    getId: getClusterTypeId,
  });
  const idError = idField.getError?.({ value: apiRef?.current?.getRowWithUpdatedValues?.(id, 'id').id });

  const hasDuplicateDisplayName = !isUpdatingRowUnique({
    apiRef,
    rowId: id,
    uniqueFields: ['displayName'],
    getId: getClusterTypeId,
  });

  const missingId = !doesUpdatingRowHaveRequiredFields({
    apiRef,
    rowId: id,
    requiredFields: ['id'],
  });

  const missingDisplayName = !doesUpdatingRowHaveRequiredFields({
    apiRef,
    rowId: id,
    requiredFields: ['displayName'],
  });

  return hasDuplicateId
    ? 'Duplicate ID'
    : missingId
    ? 'Missing ID'
    : idError
    ? idError
    : hasDuplicateDisplayName
    ? 'Duplicate displayName'
    : missingDisplayName
    ? 'Missing displayName'
    : '';
};
