import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle, Grid, IconButton } from '@mui/material';
import ModalWrapper from 'components/ModalWrapper';
import { noop } from 'lodash';
import React, { FunctionComponent } from 'react';
import LabelBuilder, { LabelBuilderProps } from '.';

interface LabelBuilderModalProps extends LabelBuilderProps {
  isOpen: boolean;
  onClose: () => void;
}

const LabelBuilderModal: FunctionComponent<React.PropsWithChildren<LabelBuilderModalProps>> = ({
  isOpen,
  onClose,
  ...builderProps
}) => {
  return (
    <ModalWrapper isOpen={isOpen} onClose={noop}>
      <Grid container justifyContent="space-between" sx={{ alignItems: 'center' }}>
        <DialogTitle variant="h1" sx={{ px: 0, py: 1 }}>
          Label Builder
        </DialogTitle>
        <IconButton onClick={onClose} sx={{ padding: 0 }}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Grid>
      <LabelBuilder {...builderProps} onClose={onClose} />
    </ModalWrapper>
  );
};

export default LabelBuilderModal;
