import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { TaxonomyTree } from 'interfaces/taxonomy';
import { map } from 'lodash';
import React from 'react';
import TaxonomyTreeViewItemLabel from './TaxonomyTreeViewItemLabel';

interface TaxonomyTreeViewItemProps {
  taxonomyTree: TaxonomyTree;
  searchValue?: string;
  isEditor?: boolean;
  withTags?: boolean;
  onAddClassWithTags?: (newClass: string) => void;
}

const TaxonomyTreeViewItem: React.FC<React.PropsWithChildren<TaxonomyTreeViewItemProps>> = ({
  taxonomyTree,
  searchValue,
  isEditor,
  withTags,
  onAddClassWithTags,
}) => {
  return (
    <TreeItem
      itemId={taxonomyTree.path}
      label={
        <TaxonomyTreeViewItemLabel
          taxonomyTree={taxonomyTree}
          searchValue={searchValue}
          isEditor={isEditor}
          withTags={withTags}
          onAddClassWithTags={onAddClassWithTags}
        />
      }
    >
      {map(taxonomyTree.children, (child) => (
        <TaxonomyTreeViewItem
          key={child.path}
          taxonomyTree={child}
          searchValue={searchValue}
          isEditor={isEditor}
          withTags={withTags}
          onAddClassWithTags={onAddClassWithTags}
        />
      ))}
    </TreeItem>
  );
};

export default TaxonomyTreeViewItem;
