import RemoveIcon from '@mui/icons-material/Remove';
import { Grid, IconButton, MenuItem, Paper, Select } from '@mui/material';
import { AutomaticConditionTag } from 'interfaces/automaticCondition';
import { map } from 'lodash';
import React, { FunctionComponent } from 'react';
import useSlideTagOptions from 'utils/queryHooks/useSlideTagOptions';
import { AutomaticConditionBuilderProps } from './AutomaticConditionBuilder';

type TagConditionBuilderProps = AutomaticConditionBuilderProps<AutomaticConditionTag>;

const TagConditionBuilder: FunctionComponent<React.PropsWithChildren<TagConditionBuilderProps>> = ({
  onRemove,
  editable,
  condition,
  onConditionChange,
}) => {
  const { slideTagOptions } = useSlideTagOptions();
  const { tagId, isAssigned } = condition;
  return (
    <Paper
      elevation={1}
      sx={{
        p: 1,
        mb: 1,
      }}
    >
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item>
          <IconButton disabled={!editable} onClick={() => onRemove()}>
            <RemoveIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Select
            size="small"
            error={false}
            disabled={!editable}
            value={tagId}
            onChange={(e) => onConditionChange({ ...condition, tagId: e.target.value as string })}
          >
            {map(slideTagOptions, (tagOption) => (
              <MenuItem key={tagOption.id} value={tagOption.id}>
                {tagOption.tagValue}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item>Tag</Grid>
        <Grid item>
          <Select
            size="small"
            disabled={!editable}
            value={isAssigned ? 'present' : 'non-present'}
            onChange={(e) => onConditionChange({ ...condition, isAssigned: e.target.value === 'present' })}
          >
            <MenuItem value={'present'}>is Present</MenuItem>
            <MenuItem value={'non-present'}>is not Present</MenuItem>
          </Select>
        </Grid>
        <Grid item>on slide</Grid>
      </Grid>
    </Paper>
  );
};

export default TagConditionBuilder;
