import {
  Collapse,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { BasePreset } from 'interfaces/basePreset';
import { includes, isEmpty, map, partition } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import PresetActionButtons from './PresetActionButtons';
import SavePreset from './SavePreset';

export interface PresetPermissions {
  canEditDefaultPreset?: boolean;
  canEditOthersPresets?: boolean;
  canDeleteOthersPreset?: boolean;
}
interface Props {
  defaultPreset?: Partial<BasePreset>;
  permissions?: PresetPermissions;
  selectedPreset: Partial<BasePreset>;
  presets: BasePreset[];
  description?: React.ReactNode;
  label?: React.ReactNode;
  isLoading?: boolean;
  onSavePreset?: (name: string) => void;
  onUpdatePreset?: () => void;
  onSelectPreset: (preset: Partial<BasePreset>) => void;
  onDeletePreset?: () => void;
  onUpdateDefaultPreset?: () => void;
}

const PresetSection: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  defaultPreset,
  permissions = { canDeleteOthersPreset: false, canEditDefaultPreset: false, canEditOthersPresets: false },
  selectedPreset,
  presets,
  description,
  label,
  isLoading,
  onSavePreset,
  onUpdatePreset,
  onSelectPreset,
  onDeletePreset,
  onUpdateDefaultPreset,
}) => {
  const { name: userName } = useAppSelector((state) => state.auth.profile);
  const [isAddingPreset, setIsAddingPreset] = useState(false);
  const shouldPresetBeInUpdateMode = (presetCreatedBy: string) => {
    return userName === presetCreatedBy || permissions.canEditOthersPresets;
  };
  const [isUpdateMode, setIsUpdateMode] = useState(shouldPresetBeInUpdateMode(selectedPreset?.createdBy));

  useEffect(() => {
    setIsUpdateMode(Boolean(onUpdatePreset) && shouldPresetBeInUpdateMode(selectedPreset?.createdBy));
  }, [Boolean(onUpdatePreset) && selectedPreset?.createdBy]);

  const savePreset = (name: string) => {
    onSavePreset(name);
    if (onUpdatePreset) {
      setIsUpdateMode(true);
    }
    setIsAddingPreset(false);
  };

  const updatePreset = onUpdatePreset
    ? () => {
        onUpdatePreset();
      }
    : undefined;

  const [myPresets, otherPresets] = partition(presets, { createdBy: userName });

  const presetSelectItems = (presetItems: BasePreset[]) => {
    return map(presetItems, (preset) =>
      preset ? (
        <MenuItem
          value={preset.id}
          key={preset.id}
          onClick={() => {
            onSelectPreset(preset);
          }}
        >
          {preset.displayName ?? preset.name}
        </MenuItem>
      ) : null
    );
  };

  const showSaveButton = Boolean(isUpdateMode ? onUpdatePreset : onSavePreset);

  return (
    <Grid container direction="column" spacing={1} pl={2}>
      <Collapse in={!isAddingPreset} sx={{ width: '100%' }}>
        <Grid container item justifyContent="space-between" alignItems="end">
          {description && (
            <Grid item md={12}>
              <Typography variant="caption">{description}</Typography>
              <Divider sx={{ marginBlock: 1 }} />
            </Grid>
          )}
          <Grid item md={showSaveButton ? 7 : 12}>
            <FormControl fullWidth variant="standard" size="small" disabled={isLoading}>
              <InputLabel id="preset-select-label">{label ?? 'Presets'}</InputLabel>
              <Select
                size="small"
                variant="standard"
                labelId="preset-select-label"
                value={selectedPreset?.id || defaultPreset?.id}
                label={label}
                disabled={isLoading}
              >
                {defaultPreset && !includes(presets, defaultPreset) && (
                  <MenuItem
                    value={defaultPreset.id}
                    key={defaultPreset.id}
                    onClick={() => {
                      setIsUpdateMode(false);
                      onSelectPreset(defaultPreset);
                    }}
                  >
                    {defaultPreset.name}
                  </MenuItem>
                )}
                {presetSelectItems(myPresets)}
                {!isEmpty(otherPresets) && <ListSubheader>All</ListSubheader>}
                {presetSelectItems(otherPresets)}
              </Select>
            </FormControl>
          </Grid>
          {showSaveButton && (
            <Grid item md={5} textAlign="end">
              <PresetActionButtons
                defaultPreset={defaultPreset}
                permissions={permissions}
                selectedPreset={selectedPreset}
                isLoading={isLoading}
                onUpdatePreset={updatePreset}
                onDeletePreset={onDeletePreset}
                onUpdateDefaultPreset={onUpdateDefaultPreset}
                setIsAddingPreset={setIsAddingPreset}
              />
            </Grid>
          )}
        </Grid>
      </Collapse>
      <SavePreset
        isAddingPreset={isAddingPreset}
        setIsAddingPreset={setIsAddingPreset}
        isLoading={isLoading}
        savePreset={savePreset}
      />
    </Grid>
  );
};

export default PresetSection;
