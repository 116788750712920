import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { type Dayjs } from 'dayjs';
import { DateField } from 'interfaces/genericFields';
import React from 'react';
import { HeaderCellEditorProps } from '.';

const HeaderDateInput = <R, V, Context extends {} = {}>(props: HeaderCellEditorProps<R, V, Context, DateField>) => {
  const { onChange, value, field } = props;
  const { isFullDate, views = ['day', 'month', 'year'] } = field;

  const inputFormat = isFullDate ? 'DD/MM/YYYY' : 'YYYY';
  const queryFormat = isFullDate ? 'YYYY-MM-DD' : 'YYYY';
  const openTo = isFullDate ? 'day' : 'year';

  const handleDateChange = (newDateObj: Dayjs | null) => {
    const newDate = newDateObj.format(queryFormat) || '';
    onChange(newDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        inputFormat={inputFormat}
        mask={isFullDate ? '__/__/____' : '____'}
        value={new Date(value?.toString() || '')}
        onChange={handleDateChange}
        renderInput={(params) => <TextField fullWidth {...params} size="small" error={false} />}
        views={views}
        disableFuture
        openTo={openTo}
      />
    </LocalizationProvider>
  );
};

export default HeaderDateInput;
