import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { closeSnackbar, SnackbarKey } from 'notistack';
import React from 'react';

interface CloseButtonProps {
  snackbarKey: SnackbarKey;
  onClick?: () => void;
}

export const SnackbarCloseButton = ({ snackbarKey, onClick }: CloseButtonProps) => {
  return (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) onClick();
        closeSnackbar(snackbarKey);
      }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};
