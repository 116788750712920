import { ArrowDropUp } from '@mui/icons-material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Menu, MenuList } from '@mui/material';
import Button from '@mui/material/Button';
import { MutationKey, useMutation } from '@tanstack/react-query';
import { typeToName } from 'components/ReviewPanel/form.util';
import { ResultsMode } from 'interfaces/experimentResults';
import { Permission } from 'interfaces/permissionOption';
import { ReviewForm } from 'interfaces/reviewForm';
import { Study } from 'interfaces/study';
import { isEmpty, map } from 'lodash';
import React from 'react';
import { exportCustomerResults, exportFeatures, exportFormResults, exportQcResults } from 'utils/exports';
import { useFormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import StudyExportMenuItem from './StudyExportMenuItem';

interface StudyExportMenuProps {
  children?: React.ReactNode;
  study?: Study;
  disabled: boolean;
  forms?: ReviewForm[];
}

const StudyExportMenu: React.FunctionComponent<StudyExportMenuProps> = ({ study, disabled, forms }) => {
  const activeStudyId = study?.id;

  const { queryParams } = useEncodedFilters();

  const orchestrationId = queryParams.filters?.orchestrationId;

  const disabledFeatures =
    (queryParams.resultsMode == ResultsMode.Published && !study?.hasFeatures) || !study?.allowExport;

  const { labId } = useCurrentLabId();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const { hasPermission } = usePermissions();

  const canViewInternalLabels = hasPermission(Permission.ViewInternalSlideLabels);

  const canViewReviewMenu = hasPermission(Permission.ViewReviewMenu);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formatBracketsOptions = useFormatBracketsOptions(false);

  const exportAllFeaturesMutation = useMutation({
    mutationKey: [
      'exportAllFeatures',
      {
        studyId: activeStudyId,
        includeHighlightedFeatures: false,
        resultsMode: queryParams.resultsMode,
      },
    ] as MutationKey,
    mutationFn: () =>
      exportFeatures({
        studyId: activeStudyId,
        context: formatBracketsOptions,
        resultsMode: queryParams.resultsMode,
        ...(queryParams.resultsMode === ResultsMode.Manual && { orchestrationId: orchestrationId }),
      }),
  });

  const exportHighlightedFeaturesMutation = useMutation({
    mutationKey: [
      'exportHighlightedFeatures',
      {
        studyId: activeStudyId,
        includeHighlightedFeatures: true,
        resultsMode: queryParams.resultsMode,
      },
    ] as MutationKey,
    mutationFn: () =>
      exportFeatures({
        studyId: activeStudyId,
        context: formatBracketsOptions,
        highlightedOnly: true,
        resultsMode: queryParams.resultsMode,
        ...(queryParams.resultsMode === ResultsMode.Manual && { orchestrationId: orchestrationId }),
      }),
  });

  const downloadLabelResultsMutation = useMutation({
    mutationKey: [
      'downloadLabelResults',
      {
        labId,
        studyId: activeStudyId,
      },
    ] as MutationKey,
    mutationFn: () =>
      exportQcResults({
        labId,
        studyId: activeStudyId,
        context: formatBracketsOptions,
        resultsMode: queryParams.resultsMode,
        ...(queryParams.resultsMode === ResultsMode.Manual && { orchestrationId: orchestrationId }),
      }),
  });

  const downloadCoordinatesMutation = useMutation({
    mutationKey: [
      'downloadCoordinates',
      {
        labId,
        studyId: activeStudyId,
      },
    ] as MutationKey,
    mutationFn: () => exportCustomerResults(labId, activeStudyId),
  });

  const exportFormTablesMutation = useMutation({
    mutationKey: [
      'exportFormTables',
      {
        labId,
        studyId: activeStudyId,
      },
    ] as MutationKey,
    mutationFn: (formId: string) => exportFormResults(formId),
  });

  const menuOpen = Boolean(anchorEl);

  return (
    <>
      <Button
        onClick={handleClick}
        color="secondary"
        disabled={disabled}
        endIcon={menuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
        variant="contained"
        size="small"
      >
        Export
      </Button>
      <Menu
        onClick={(event) => event.stopPropagation()}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <MenuList>
          <StudyExportMenuItem
            disabled={disabledFeatures}
            isLoading={formatBracketsOptions.isLoading || exportAllFeaturesMutation.isLoading}
            onClick={exportAllFeaturesMutation.mutate}
            title="All features"
            tooltip={`${
              queryParams.resultsMode === ResultsMode.InternallyApproved
                ? 'You are on Internally Approved mode- export internally approved results'
                : queryParams.resultsMode === ResultsMode.Manual
                ? orchestrationId
                  ? 'You are on Manual mode- export results for selected orchestration'
                  : 'You are on Manual mode- export latest result per slide'
                : ''
            }`}
            disabledMessage={study?.allowExport ? 'No features to export' : 'Exports are disabled for this study'}
          />
          {!isEmpty(study?.highlightedFeatures) && (
            <StudyExportMenuItem
              disabled={disabledFeatures}
              isLoading={exportHighlightedFeaturesMutation.isLoading}
              onClick={exportHighlightedFeaturesMutation.mutate}
              disabledMessage={
                study?.allowExport ? 'No highlighted features to export' : 'Exports are disabled for this study'
              }
              title="Highlighted features only"
              tooltip={`${
                queryParams.resultsMode === ResultsMode.InternallyApproved
                  ? 'You are on Internally Approved mode- export internally approved highlighted results'
                  : queryParams.resultsMode === ResultsMode.Manual
                  ? orchestrationId
                    ? 'You are on Manual mode- export highlighted results for selected orchestration'
                    : 'You are on Manual mode- export latest highlighted result per slide'
                  : ''
              }`}
            />
          )}
          {canViewInternalLabels && (
            <StudyExportMenuItem
              disabled={isEmpty(study?.qcLabelsConfig)}
              disabledMessage="No QC Labels Configured for this study"
              isLoading={downloadLabelResultsMutation.isLoading}
              onClick={downloadLabelResultsMutation.mutate}
              title="Label Results (.csv)"
            />
          )}
          <StudyExportMenuItem
            disabled={!study?.allowExport || !study?.hasResults}
            disabledMessage={study?.allowExport ? 'No results to export' : 'Exports are disabled for this study'}
            isLoading={downloadCoordinatesMutation.isLoading}
            onClick={downloadCoordinatesMutation.mutate}
            title="Masks & coordinates"
          />
          {canViewReviewMenu &&
            map(
              forms,
              (
                form // TODO: change when new form permissions are implemented
              ) => (
                <StudyExportMenuItem
                  key={form.id}
                  isLoading={exportFormTablesMutation.isLoading}
                  onClick={() => exportFormTablesMutation.mutate(form.id)}
                  title={`Export ${typeToName(form.type)} Responses`}
                />
              )
            )}
        </MenuList>
      </Menu>
    </>
  );
};

export default StudyExportMenu;
