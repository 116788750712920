import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { IconButton, MenuItem, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { stringify } from 'qs';
import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { encodeQueryParams, JsonParam, StringParam, useQueryParams } from 'use-query-params';
import { useCurrentLabId } from 'utils/useCurrentLab';

interface JobsControlProps {
  studyId: string;
  cameFrom?: '/' | '/procedures';
  menuItem?: boolean;
}

const JobsControl: FunctionComponent<React.PropsWithChildren<JobsControlProps>> = ({
  studyId,
  cameFrom = '/',
  menuItem,
}) => {
  const navigate = useNavigate();
  const { labId } = useCurrentLabId();

  const [queryParams] = useQueryParams({
    filters: JsonParam,
  });

  const navigateToJobs = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const encodedQuery = encodeQueryParams(
      { filters: JsonParam, labId: StringParam },
      { filters: { ...queryParams.filters, studyId: studyId }, labId }
    );

    navigate(
      {
        pathname: `/jobs`,
        search: `?${stringify({ ...encodedQuery })}`,
      },
      {
        state: { cameFrom },
      }
    );
  };

  return menuItem ? (
    <MenuItem onClick={navigateToJobs}>
      <Box>
        <IconButton>
          <WorkOutlineIcon />
        </IconButton>
      </Box>
      Jobs
    </MenuItem>
  ) : (
    <Tooltip title="Jobs" placement="top">
      <Box>
        <IconButton onClick={navigateToJobs}>
          <WorkOutlineIcon />
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default JobsControl;
