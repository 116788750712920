import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useSlideLabels } from 'components/StudiesDashboard/StudySettings/StudyLabel/useSlideLabels';
import { QualityControlLabel } from 'interfaces/qualityControlLabels';
import { map } from 'lodash';
import React, { FunctionComponent } from 'react';

interface QcFilterLabelProps {
  qcLabelConfig: QualityControlLabel;
  selectedLabel: string;
  handleChange: (event: React.MouseEvent<HTMLElement>, newValue: string, labelConfig: QualityControlLabel) => void;
}

const QcFilterLabel: FunctionComponent<React.PropsWithChildren<QcFilterLabelProps>> = ({
  qcLabelConfig,
  selectedLabel,
  handleChange,
}) => {
  const { getLabelDisplayName } = useSlideLabels();
  const filterLabelDisplayName: string = getLabelDisplayName(qcLabelConfig.filterLabel);
  const rejectLabelDisplayName: string = getLabelDisplayName(qcLabelConfig.rejectLabel);

  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      value={selectedLabel}
      onChange={(event, value) => handleChange(event, value, qcLabelConfig)}
      size="small"
      sx={{ paddingBottom: 1, width: '100%' }}
    >
      {map(
        [
          { value: qcLabelConfig.filterLabel, displayName: filterLabelDisplayName },
          {
            value: qcLabelConfig.rejectLabel,
            displayName: rejectLabelDisplayName === '' ? `Not ${filterLabelDisplayName}` : rejectLabelDisplayName,
          },
        ],
        ({ value, displayName }) => (
          <ToggleButton key={value} value={value} style={{ flex: 1 }}>
            {displayName}
          </ToggleButton>
        )
      )}
    </ToggleButtonGroup>
  );
};

export default QcFilterLabel;
