import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Collapse, Divider, Grid } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { cloneDeep, filter, fromPairs, includes, lowerCase, map, some } from 'lodash';
import React, { useState } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import OrchestrationApproval from 'components/Procedure/Infobar/SlideInfobar/Results/OrchestrationApproval';
import { MAX_CHANNELS } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/constants';
import { ChannelMetadata } from 'components/Procedure/useSlideChannelsAndResults/channelMetadata';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { ExperimentResultsResultType } from 'interfaces/experimentResults';
import { Permission } from 'interfaces/permissionOption';
import { usePermissions } from 'utils/usePermissions';
import { ChannelMarkersToolbar } from './ChannelMarkersToolbar';
import { ChannelsAccordionSummary } from './ChannelsAccordionSummary';
import MultiplexPresetSection from './MultiplexChannelColorPresetSection';
import MultiplexChannelControl from './MultiplexChannelControl';
import MultiplexChannelNormalizationSection from './MultiplexChannelNormalizationSection';
import { useMultiplexSlideChannelNormalization } from './channelNormalizations';
import { slidesChannelToggles, useSlideChannelViewSettings } from './colorSettings';
import { useChannelMarkerEditor } from './useChannelMarkerEditor';

interface Props {
  slide: SlideWithChannelAndResults;
  filterText?: string;
  fixed?: boolean;
  independentFilter?: boolean;
}

const Multiplex: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  slide,
  independentFilter,
  filterText: externalFilterText,
  fixed,
}) => {
  useSignals();
  useSlideChannelViewSettings(slide);

  const [expand, setExpand] = useState(true);
  const [filterTextState, setFilterText] = useState(externalFilterText || '');
  const filterText = independentFilter ? filterTextState : externalFilterText;

  const [editChannelColorPresetsMode, setEditChannelColorPresetsMode] = useState(false);
  const [editChannelNormalizationMode, setEditChannelNormalizationMode] = useState(false);

  const {
    defaultNormalization,
    normalizationOptions,
    normalizationData,
    currentNormalizationId,
    currentNormalization,
    isLoadingNormalizationData,
    isLoadingNormalizationOptions,
    setCurrentNormalizationId,
    resetChannelNormalizationSettings,
    refetchNormalization,
  } = useMultiplexSlideChannelNormalization({ slide });

  const isInternallyApproved = currentNormalization?.internallyApproved;
  const experimentResultId = currentNormalization?.experimentResultId;

  const normalizationStudyId = currentNormalization?.studyId;
  const isNormalizationFromDifferentStudy = normalizationStudyId !== slide.studyId;

  const {
    editChannelMarkerMode,
    hasMarkerChanges,
    hasMissingMarkers,
    hasDuplicateMarkers,
    isMissingChannelNames,
    draftAssignedMarkers,
    draftChannelMarkers,
    dbChannelMarkers,
    isSavingChannelMarkers,
    hasPreviouslyAssignedChannelsMissingMarkers,
    isCheckingStudyChannels,
    doAllSlidesHaveSameChannels,
    updateSlideChannelMarkers,
    updateStudyMarkers,
    setEditChannelMarkerMode,
    handleChannelMarkerTypeChange,
  } = useChannelMarkerEditor({ slide });

  const viewerSlidesChannelsToggles = slidesChannelToggles[slide.viewerIndex];
  const slideChannelToggles = viewerSlidesChannelsToggles?.value?.[slide.id];

  const slideChannelsMetadata = slide?.channelsMetadata;

  const displayedChannels = React.useMemo(
    () =>
      filter(
        slideChannelsMetadata,
        (channel) =>
          slideChannelToggles?.[channel.id] &&
          matchesChannelTextFilter(channel, slide.channelMarkerTypes?.[channel.id], filterText)
      ),
    [slideChannelToggles, slideChannelsMetadata, slide.channelMarkerTypes, filterText]
  );

  const hiddenChannels = React.useMemo(
    () =>
      filter(
        slideChannelsMetadata,
        (channel) =>
          !slideChannelToggles?.[channel.id] &&
          matchesChannelTextFilter(channel, slide.channelMarkerTypes?.[channel.id], filterText)
      ),
    [slideChannelToggles, slideChannelsMetadata, slide.channelMarkerTypes, filterText]
  );

  const areAllChannelsSelected = displayedChannels?.length === slide?.channelsMetadata?.length;
  // When selecting all channels, save the previous selection to allow restoring it
  const [previousManualSelection, setPreviousManualSelection] = React.useState(null);
  React.useEffect(() => {
    if (!areAllChannelsSelected && previousManualSelection !== null) {
      // Once selection has changed from all channels selected, clear previously saved selection
      setPreviousManualSelection(null);
    }
  }, [areAllChannelsSelected, previousManualSelection]);

  const { hasPermission } = usePermissions();
  const showSelectAllChannels = hasPermission(Permission.CanToggleAllMultiplexChannels);
  const isConfiguringChannels = editChannelColorPresetsMode || editChannelMarkerMode || editChannelNormalizationMode;

  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);
  // Pending slides mode doesn't allow publishing results since the endpoints currently require a caseId
  const canPublishResults = hasPermission(Permission.PublishResults) && !pendingSlidesMode;

  return (
    <Accordion
      disableGutters
      expanded={fixed || expand}
      square
      onChange={!fixed ? () => setExpand((prev) => !prev) : undefined}
    >
      <AccordionSummary
        expandIcon={!fixed ? <ExpandMoreIcon /> : undefined}
        sx={{
          paddingInline: 1,
          ...(fixed
            ? {
                position: 'sticky',
                top: 0,
                backgroundColor: 'background.paper',
                zIndex: 1,
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              }
            : {}),
        }}
      >
        <ChannelsAccordionSummary
          expand={expand}
          editChannelColorPresetsMode={editChannelColorPresetsMode}
          editChannelMarkerMode={editChannelMarkerMode}
          editChannelNormalizationMode={editChannelNormalizationMode}
          hasMultipleNormalizationChoices={(normalizationOptions || []).length > 1}
          isSavingChannelMarkers={isSavingChannelMarkers}
          isMissingChannelNames={isMissingChannelNames}
          hasMarkerChanges={hasMarkerChanges}
          setEditChannelColorPresetsMode={setEditChannelColorPresetsMode}
          setEditChannelNormalizationMode={setEditChannelNormalizationMode}
          setEditChannelMarkerMode={setEditChannelMarkerMode}
          numChannels={slide?.channelsMetadata?.length || 0}
          setFilterText={independentFilter ? setFilterText : undefined}
        />
      </AccordionSummary>
      <AccordionDetails sx={{ paddingInline: 1 }}>
        <Collapse in={isConfiguringChannels}>
          {editChannelNormalizationMode ? (
            <>
              <MultiplexChannelNormalizationSection
                slide={slide}
                normalizationData={normalizationData}
                normalizationOptions={normalizationOptions}
                currentNormalizationId={currentNormalizationId}
                isLoadingNormalizationData={isLoadingNormalizationData}
                isLoadingNormalizationOptions={isLoadingNormalizationOptions}
                defaultNormalization={defaultNormalization}
                isNormalizationFromDifferentStudy={isNormalizationFromDifferentStudy}
                setCurrentNormalizationId={setCurrentNormalizationId}
              />
              {canPublishResults &&
                !isLoadingNormalizationData &&
                // Cannot publish results from another study
                !isNormalizationFromDifferentStudy &&
                // If the slide doesn't have a saved normalization, it cannot be approved / published
                !isNaN(experimentResultId) &&
                !isNaN(currentNormalizationId) && (
                  <Grid item container direction="column">
                    <OrchestrationApproval
                      slideId={slide.id}
                      viewerIndex={slide.viewerIndex}
                      experimentResultIds={[experimentResultId]}
                      isInternallyApproved={isInternallyApproved}
                      isOrchestrationIdPublished={currentNormalization?.approved}
                      studyId={slide.studyId}
                      doesSlideHaveApprovedResults={some(normalizationOptions, 'approved')}
                      onApprovalMutation={() => refetchNormalization()}
                      resultTypes={[ExperimentResultsResultType.Normalization]}
                    />
                  </Grid>
                )}
              <Grid item pt={1} mb={3}>
                <Divider />
              </Grid>
            </>
          ) : editChannelColorPresetsMode ? (
            <>
              <MultiplexPresetSection slide={slide} />
              <Grid item pt={1} mb={3}>
                <Divider />
              </Grid>
            </>
          ) : editChannelMarkerMode ? (
            <ChannelMarkersToolbar
              isMissingChannelNames={isMissingChannelNames}
              hasChanges={hasMarkerChanges}
              hasDuplicates={hasDuplicateMarkers}
              hasMissingMarkers={hasMissingMarkers}
              isSaving={isSavingChannelMarkers}
              hasPreviouslyAssignedChannelsMissingMarkers={hasPreviouslyAssignedChannelsMissingMarkers}
              updateSlideChannelMarkers={updateSlideChannelMarkers}
              updateStudyMarkers={updateStudyMarkers}
              isCheckingStudyChannels={isCheckingStudyChannels}
              doAllSlidesHaveSameChannels={doAllSlidesHaveSameChannels}
            />
          ) : null}
        </Collapse>

        {showSelectAllChannels && (slideChannelsMetadata?.length ?? 0) <= MAX_CHANNELS && (
          <Button
            onClick={() => {
              const newSlideChannelToggles = areAllChannelsSelected
                ? cloneDeep(previousManualSelection || { '0': true })
                : fromPairs(map(slideChannelsMetadata, (channel) => [channel.id, true]));
              setPreviousManualSelection(areAllChannelsSelected ? null : slideChannelToggles);
              const currentViewerChannelToggles = viewerSlidesChannelsToggles?.value;
              viewerSlidesChannelsToggles.value = {
                ...currentViewerChannelToggles,
                [slide.id]: newSlideChannelToggles,
              };
            }}
            variant="outlined"
            color="primary"
            size="small"
          >
            {areAllChannelsSelected ? 'Restore Selection' : 'Select All'}
          </Button>
        )}
        {map(displayedChannels, (channel) => (
          <MultiplexChannelControl
            key={channel.id}
            slideId={slide.id}
            viewerIndex={slide.viewerIndex}
            encoding={slide.encoding}
            channelId={channel.id}
            channelName={channel.channelName}
            editChannelMarkerMode={editChannelMarkerMode}
            selected={true}
            draftAssignedMarkers={draftAssignedMarkers}
            disabled={channel.id === '0'}
            markerType={dbChannelMarkers?.[channel.id]}
            draftMarkerType={draftChannelMarkers?.[channel.id]}
            handleChannelMarkerTypeChange={handleChannelMarkerTypeChange}
            resetChannelNormalizationSettings={resetChannelNormalizationSettings}
            channelHistogram={slide.channels[channel.id]?.histogram}
            normalizationData={normalizationData}
          />
        ))}

        {map(hiddenChannels, (channel) => (
          <MultiplexChannelControl
            slideId={slide.id}
            viewerIndex={slide.viewerIndex}
            encoding={slide.encoding}
            key={channel.id}
            channelId={channel.id}
            channelName={channel.channelName}
            editChannelMarkerMode={editChannelMarkerMode}
            selected={false}
            draftAssignedMarkers={draftAssignedMarkers}
            markerType={dbChannelMarkers?.[channel.id]}
            draftMarkerType={draftChannelMarkers?.[channel.id]}
            handleChannelMarkerTypeChange={handleChannelMarkerTypeChange}
            resetChannelNormalizationSettings={resetChannelNormalizationSettings}
            channelHistogram={slide.channels[channel.id]?.histogram}
            normalizationData={normalizationData}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const matchesChannelTextFilter = (channel: ChannelMetadata, markerType: string, filterText: string) =>
  includes(lowerCase(channel.channelName), lowerCase(filterText)) ||
  includes(lowerCase(markerType), lowerCase(filterText));

export default Multiplex;
