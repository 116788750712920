import { CircularProgress, Grid, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getResultsExistence } from 'api/experimentResults';
import { runMultiplexBinaryClassifier } from 'api/platform';
import { FlowClassName } from 'interfaces/experimentResults';
import { filter, isEmpty, keys, map, some } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useState } from 'react';
import { ArrayParam } from 'use-query-params';
import { encodeQueryParamsUsingSchema } from 'utils/useEncodedFilters';
import { useCasesParams } from '../../../../../utils/useCasesParams';
import { PlatformStepper } from '../PlatformStepper';
import MultiplexProcessSlideIds from './MultiplexProcessSlideIds';

const SNACK_BAR_KEY_RUN_MULTIPLEX_BINARY_CLASSIFIER = 'RUN_MULTIPLEX_BINARY_CLASSIFIER';

export const RunMultiplexBinaryClassifier = (props: IFormProps): ReactElement => {
  const { onClose } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const { casesParams, schema, options } = useCasesParams();

  const getEncodedParams = () =>
    encodeQueryParamsUsingSchema(
      {
        ...casesParams,
        flowClassNames: [FlowClassName.CreateMultiplexHistogramFlow, FlowClassName.MultiplexCellSegmentationProcessor],
      },
      {
        ...schema,
        flowClassNames: ArrayParam,
      },
      options
    );

  const { data: resultsExist, isLoading: isLoadingResultsExist } = useQuery({
    queryKey: ['resultsExist', getEncodedParams()],
    queryFn: ({ signal }) => getResultsExistence(getEncodedParams(), casesParams.studyId, signal),
  });

  const runMultiplexBinaryClassifierMutation = useMutation(runMultiplexBinaryClassifier, {
    onError: () => {
      enqueueSnackbar('Error occurred, Multiplex Binary Classifier failed', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Multiplex Binary Classifier Started', { variant: 'success' });
    },
    onSettled() {
      closeSnackbar(SNACK_BAR_KEY_RUN_MULTIPLEX_BINARY_CLASSIFIER);
    },
  });

  const onSubmit = () => {
    runMultiplexBinaryClassifierMutation.mutate({
      ...casesParams,
    });

    enqueueSnackbar({
      variant: 'success',
      message: (
        <Grid container>
          <Grid item>
            <Typography>Waiting for Multiplex Binary Classifier to start</Typography>
          </Grid>
          <Grid item>
            <CircularProgress sx={{ marginLeft: 10 }} color="inherit" size={20} />
          </Grid>
        </Grid>
      ),
      key: SNACK_BAR_KEY_RUN_MULTIPLEX_BINARY_CLASSIFIER,
      autoHideDuration: null,
    });

    onClose();
  };

  const missingResults = filter(keys(resultsExist), (flowClassName) => {
    return some(resultsExist[flowClassName], (result) => result === false);
  });

  const steps = [
    {
      label: 'Check Histogram And Segmentation Processes',
      content: (
        <Grid item>
          {isLoadingResultsExist ? (
            <CircularProgress />
          ) : (
            <Typography>
              {!isEmpty(missingResults) ? (
                <Grid container spacing={1}>
                  <Grid item>
                    Some slides are missing results. You need to run Multiplex Cell Segmentation and Multiplex Histogram
                    processes
                  </Grid>
                  <Grid item xs={12}>
                    {map(missingResults, (flowClassName) => (
                      <MultiplexProcessSlideIds
                        flowClassName={flowClassName}
                        slideIds={filter(
                          keys(resultsExist[flowClassName]),
                          (slideId) => resultsExist[flowClassName][slideId] === false
                        )}
                      />
                    ))}
                  </Grid>

                  <Grid item>
                    If you have already run these processes not in the webapp and you are sure that all slides have
                    results , you can proceed with Multiplex Binary Classifier
                  </Grid>
                </Grid>
              ) : (
                'All slides have results. You can proceed with Multiplex Binary Classifier'
              )}
            </Typography>
          )}
        </Grid>
      ),
    },
  ];

  return (
    <PlatformStepper
      validateStep={async () => {
        return !isLoadingResultsExist;
      }}
      handleSubmit={onSubmit}
      steps={steps}
      setActiveStepForValidation={setActiveStep}
      isStepFailed={{ 1: isLoadingResultsExist }}
    />
  );
};

export interface IFormProps {
  onClose: () => void;
}
