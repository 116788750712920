import { Grid, useTheme } from '@mui/material';
import CaseDataEntry from 'components/Procedure/Header/CaseDataPopover/CaseDataEntry';
import { Slide } from 'interfaces/slide';
import moment from 'moment';
import React from 'react';
import { getSlideThumbnailUrl } from 'utils/helpers';
import SlideIdentifierBadge from './SlideIdentifierBadge';

interface VerticalCarouselItemProps {
  slide: Slide;
  isSelected: boolean;
  onSelected?: () => void;
}

const carouselItemDimensions = {
  width: 250,
  height: 220,
};

const VerticalCarouselItem: React.FC<React.PropsWithChildren<VerticalCarouselItemProps>> = ({
  slide,
  isSelected,
  onSelected,
}) => {
  const thumbnail = getSlideThumbnailUrl(slide);
  const theme = useTheme();
  return (
    <Grid
      sx={{
        padding: 1,
        border: isSelected ? `3px solid ${theme.palette.primary.main}` : '3px solid transparent',

        '&:hover': {
          border: `3px solid ${theme.palette.action.selected}`,
          cursor: 'pointer',
        },
      }}
      onClick={onSelected}
    >
      <Grid
        item
        sx={{
          position: 'relative',
        }}
      >
        <img src={thumbnail} alt={`Slide ${slide.id}`} style={carouselItemDimensions} />
        <SlideIdentifierBadge absolutePositioning>{slide.id}</SlideIdentifierBadge>
      </Grid>
      <Grid container item columns={5}>
        <Grid item xs={5}></Grid>
        <CaseDataEntry label="Stain Type" value={slide.stainingType} />
        <CaseDataEntry label="Scan Date" value={moment(slide.scanDate).format('ll')} />
      </Grid>
    </Grid>
  );
};

export default VerticalCarouselItem;
