import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, FilterOptionsState, TextField } from '@mui/material';
import Loader from 'components/Loader';
import { NamedFeature } from 'interfaces/features';
import React, { FunctionComponent } from 'react';

interface FeaturesDropdownProps {
  features: NamedFeature[];
  selectedFeatures: NamedFeature[];
  featuresFilterOptions: (options: NamedFeature[], state: FilterOptionsState<NamedFeature>) => NamedFeature[];
  setSelectedFeatures: (features: NamedFeature[]) => void;
  loading: boolean;
  disabled: boolean;
  label: string;
  placeholder: string;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const NO_FEATURES: NamedFeature[] = [];

const FeaturesDropdown: FunctionComponent<React.PropsWithChildren<FeaturesDropdownProps>> = ({
  features,
  selectedFeatures,
  featuresFilterOptions,
  setSelectedFeatures,
  loading,
  disabled,
  label,
  placeholder,
}) => {
  // autocomplete with checkboxes
  return (
    <Autocomplete
      filterOptions={featuresFilterOptions}
      multiple
      options={features || NO_FEATURES}
      value={selectedFeatures}
      disableCloseOnSelect
      disabled={disabled}
      onChange={(event, newValue) => {
        setSelectedFeatures(newValue);
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option?.nameOverride || option.displayName}
        </li>
      )}
      style={{ width: 500 }}
      getOptionLabel={(feature) => feature?.nameOverride || feature?.displayName}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <Loader size="small" /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default FeaturesDropdown;
