import {
  biopsyDateField,
  cancerSubtypeField,
  cancerTypeField,
  caseIdField,
  caseNameField,
  studyField,
} from './caseFields';
import {
  biopsySiteField,
  formatField,
  negativeControlField,
  originalFileNameField,
  positiveControlField,
  slideIdField,
  stainTypeField,
} from './slideFields';

export default [
  caseIdField,
  caseNameField,
  originalFileNameField,
  formatField,
  slideIdField,
  cancerTypeField,
  cancerSubtypeField,
  biopsySiteField,
  stainTypeField,
  negativeControlField,
  positiveControlField,
  biopsyDateField,
  studyField,
];
