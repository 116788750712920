import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Features } from 'interfaces/experimentResults';
import { Dictionary, forEach, map, reduce } from 'lodash';
import numeral from 'numeral';
import * as React from 'react';
import { max, mean, median, min, sampleKurtosis, sampleSkewness, standardDeviation } from 'simple-statistics';
import { useGetNameOverrideOrDisplayNameWithContext } from 'utils/features/contextHooks';
import TableCellTextContent from '../atoms/TableCellTextContent';

const formatNumber = (value: number) => {
  if (value < 1e-3) {
    return numeral(value).format('0.00e+0');
  } else if (value > 1e3) {
    return numeral(value).format('0,0[.]0');
  } else {
    return numeral(value).format('0,0[.]000');
  }
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Feature', width: 190 },
  {
    field: 'Number of Records',
    headerName: '# Records',
    width: 80,
    align: 'center',
    valueGetter: (params: GridValueGetterParams) => Number(params.row.values?.length),
  },
  {
    field: 'Mean',
    headerName: 'Mean',
    width: 160,
    valueGetter: (params: GridValueGetterParams) => formatNumber(mean(params.row.values)),
  },
  {
    field: 'Min. Value',
    headerName: 'Min. Value',
    width: 160,
    valueGetter: (params: GridValueGetterParams) => formatNumber(min(params.row.values)),
  },

  {
    field: 'Max. Value',
    headerName: 'Max. Value',
    width: 160,
    valueGetter: (params: GridValueGetterParams) => formatNumber(max(params.row.values)),
  },

  {
    field: 'std',
    headerName: 'STD',
    width: 160,
    valueGetter: (params: GridValueGetterParams) => formatNumber(standardDeviation(params.row.values)),
  },
  {
    field: 'variation',
    headerName: 'Variation',
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
      formatNumber(standardDeviation(params.row.values) / mean(params.row.values)),
  },
  {
    field: 'Median',
    headerName: 'Median',
    width: 160,
    valueGetter: (params: GridValueGetterParams) => formatNumber(median(params.row.values)),
  },

  {
    field: 'Skewness',
    headerName: 'Skewness',
    width: 160,
    valueGetter: (params: GridValueGetterParams) => formatNumber(sampleSkewness(params.row.values)),
  },

  {
    field: 'Kurtosis',
    headerName: 'Kurtosis',
    width: 160,
    valueGetter: (params: GridValueGetterParams) => formatNumber(sampleKurtosis(params.row.values)),
  },
];

const truncatedColumns: GridColDef[] = map(columns, (column) => ({
  ...column,
  renderCell: (params: GridValueGetterParams) => params && <TableCellTextContent text={params?.value?.toString()} />,
}));

interface Props {
  data: Features[];
  loading: boolean;
}

const FeaturesTable: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ data, loading }) => {
  const featureStats = reduce(
    data,
    (acc: Dictionary<Number[]>, features) => {
      forEach(features, (value, key) => {
        acc[key] = [...(acc[key] || []), Number(value)];
      });
      return acc;
    },
    {}
  );

  const { getNameOverrideOrDisplayNameWithContext, isLoadingFormatterData } =
    useGetNameOverrideOrDisplayNameWithContext(false);
  const featureRows = !isLoadingFormatterData
    ? map(featureStats, (values, key) => {
        return {
          id: getNameOverrideOrDisplayNameWithContext(key),
          values,
        };
      })
    : [];

  return (
    <Box sx={{ height: 650, width: '100%' }}>
      <DataGrid
        loading={loading || isLoadingFormatterData}
        rows={featureRows}
        columns={truncatedColumns}
        pageSizeOptions={[10]}
      />
    </Box>
  );
};

export default FeaturesTable;
