import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, CircularProgress, IconButton, styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useMutation } from '@tanstack/react-query';
import { downloadCaseManifestTemplateFile } from 'api/procedures';
import classnames from 'classnames';
import formats from 'constants/manifestFormats';
import { Permission } from 'interfaces/permissionOption';
import React, { ReactElement, useState } from 'react';
import { Controller } from 'react-hook-form';
import { usePermissions } from 'utils/usePermissions';
import './UploadManifest.scss';

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '1.5em',
  textTransform: 'none',
}));

interface Props {
  getValues: (field: string) => any;
  setError: (field: string, error: any) => void;
  clearErrors: (field: any) => any;
  resetField: (field: any) => any;
  manifestFileErrors: any;
  control: any;
  manifestFile: any;
}

const UploadManifest = (props: Props): ReactElement => {
  const { getValues, setError, clearErrors, resetField, manifestFileErrors, control, manifestFile } = props;
  const { hasPermission } = usePermissions();
  const [loadingButton, setLoadingButton] = useState<string | null>(null);

  const downloadTemplateMutation = useMutation(downloadCaseManifestTemplateFile, {
    onMutate: (variables) => {
      setLoadingButton(variables.slideIdTemplate ? 'slideIdTemplate' : 'defaultTemplate');
    },
    onError: (error) => {
      console.error(error);
    },
    onSettled: () => {
      setLoadingButton(null);
    },
  });

  // if user don't have permission applyPermissionsAcrossLabs then the slide id format is not required
  const canDownloadSlideIdTemplate = hasPermission(Permission.ApplyPermissionsAcrossLabs);

  return (
    <>
      <Box display="flex" flexDirection="column">
        <div className={classnames('section upload-slides-csv')}>
          <Typography variant="h4">Upload Study Cohort Manifest</Typography>
          <div className={classnames('manifest')}>
            <div className={classnames('upload-csv-button-container')}>
              <label htmlFor="fileupload" className={classnames('modal-button upload')}>
                Upload CSV/XLSX
              </label>

              <Controller
                name="manifestFile"
                control={control}
                render={({ field }) => (
                  <input
                    id="fileupload"
                    style={{ display: 'none' }}
                    type="file"
                    onChange={(e) => {
                      if (formats.includes(e.target.files[0].type)) {
                        clearErrors('manifestFile');
                        field.onChange(e.target.files[0]);
                      } else {
                        setError('manifestFile', {
                          type: 'manual',
                          message: 'File must be a csv/xlsx file',
                        });
                      }
                    }}
                  />
                )}
              />
              {manifestFile && !manifestFileErrors && (
                <div className={classnames('uploaded-file-name')}>
                  <span>{getValues('manifestFile').name}</span>
                  <IconButton
                    onClick={() => {
                      resetField('manifestFile');
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              )}
              {manifestFileErrors && (
                <div className={classnames('validation-error filetype')}> {manifestFileErrors.message}</div>
              )}
            </div>
            <div className={classnames('upload-csv-text-container')}>
              <span className={classnames('download-text')}>
                Upload the patients&#39; metadata csv according to the following template.
              </span>
              <StyledButton
                color="primary"
                onClick={() => downloadTemplateMutation.mutate({ slideIdTemplate: false })}
                startIcon={<DownloadIcon />}
              >
                Download XLSX Template
                {downloadTemplateMutation.isLoading && loadingButton === 'defaultTemplate' && (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress sx={{ marginLeft: 2 }} color="inherit" size={20} />
                  </Box>
                )}
              </StyledButton>
              {canDownloadSlideIdTemplate && (
                <StyledButton
                  color="primary"
                  onClick={() => downloadTemplateMutation.mutate({ slideIdTemplate: true })}
                  startIcon={<DownloadIcon />}
                >
                  Download Slide ID XLSX Template
                  {downloadTemplateMutation.isLoading && loadingButton === 'slideIdTemplate' && (
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress sx={{ marginLeft: 2 }} color="inherit" size={20} />
                    </Box>
                  )}
                </StyledButton>
              )}
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default UploadManifest;
