import { CircularProgress, Grid } from '@mui/material';
import { ClassificationModel } from 'interfaces/classificationModel';
import { VisualizationCreated } from 'interfaces/visualization';
import { forEach, groupBy, isArray, map, mergeWith, replace, uniq } from 'lodash';
import React from 'react';
import { useAreaTypeIdToDisplayName } from 'utils/useAreaTypeIdToDisplayName';
import { ClassConfigs } from '..';
import VisualizationStain, { ClassOptionsByType } from './VisualizationStain';

interface VisualizationStepProps {
  classConfig: ClassConfigs;
  postProcessingClasses: ClassConfigs;
  visualizations: VisualizationCreated[];
  setVisualizations: React.Dispatch<React.SetStateAction<VisualizationCreated[]>>;
  stains: string[];
  classificationModelOptions: ClassificationModel[];
}

export interface ClassOptionByStainByType {
  [stain: string]: ClassOptionsByType;
}

export const VisualizationStep: React.FC<React.PropsWithChildren<VisualizationStepProps>> = ({
  classConfig,
  postProcessingClasses,
  visualizations,
  setVisualizations,
  stains,
  classificationModelOptions,
}) => {
  const { areaTypeIdToDisplayName, isLoadingAreaTypes } = useAreaTypeIdToDisplayName();
  const inputToDisplayNameFunction: Record<string, (input: string | number) => string> = {
    area: areaTypeIdToDisplayName,
    cell: (cellType: string) => {
      return replace(cellType, /[_]/g, ' ');
    },
  };

  const visualizationsByStain = groupBy(visualizations, 'stain');
  const selectedStainsAndStainsFromExistsVisualizations = uniq([...stains, ...map(visualizations, 'stain')]);

  const getClassOptions = (classToRunOver: ClassConfigs, type: string) => {
    const classOptions: ClassOptionByStainByType = {};
    forEach(classToRunOver, (classConfigByStain, stain) => {
      classOptions[stain] = {};
      forEach(classConfigByStain, (classConfigByInputType, InputType) => {
        classOptions[stain][InputType] = [];
        forEach(classConfigByInputType, (classId: string | number) => {
          classOptions[stain][InputType].push({
            label: inputToDisplayNameFunction[InputType]?.(classId) || classId,
            value: classId,
            type,
          });
        });
      });
    });

    return classOptions;
  };

  const allClassOptions: ClassOptionByStainByType = mergeWith(
    getClassOptions(classConfig, 'Model Classes'),
    getClassOptions(postProcessingClasses, 'Post Processing Classes'),
    (objValue: string | any[], srcValue: any) => {
      if (isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    }
  );

  return (
    <Grid container spacing={1} direction="column">
      {isLoadingAreaTypes ? (
        <CircularProgress />
      ) : (
        map(selectedStainsAndStainsFromExistsVisualizations, (stain) => {
          return (
            <Grid item key={stain}>
              <VisualizationStain
                visualizationsCreated={visualizationsByStain[stain]}
                setVisualizations={setVisualizations}
                key={stain}
                stain={stain}
                classes={allClassOptions[stain]}
                classificationModelOptions={classificationModelOptions}
              />
            </Grid>
          );
        })
      )}
    </Grid>
  );
};
