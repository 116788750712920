import filterFieldsConfig from 'components/SearchFilters/ProcedureFilterFields';
import { CaseQueryFeature } from 'interfaces/caseQuery';
import {
  BasicFiltersQueryParams,
  FilterKey,
  GeneralFilterKey,
  MainPageFilterKey,
  QueryFilter,
} from 'interfaces/cohort';
import { Field, FieldConfigItem } from 'interfaces/genericFields';
import {
  castArray,
  every,
  find,
  flatten,
  forEach,
  includes,
  isArray,
  isFinite,
  isString,
  join,
  map,
  pick,
  split,
  uniq,
} from 'lodash';
import qs from 'qs';
import { enumValuesList } from 'utils/enumValues';

export const getFeatureObjectFromFeatureFiltersQueryParams = (
  featuresFilters: Record<string, string>
): CaseQueryFeature[] => {
  const mappedFeatures = map(featuresFilters, (value, key) => {
    if (!value) {
      return;
    }
    const [start, end] = split(value, '~');

    return {
      featureKey: key,
      value: {
        start: start.trim() || '',
        end: end.trim() || '',
      },
    };
  });

  return mappedFeatures;
};
export const applyFilterToQueryParams = (baseQs: string, filters: any) => {
  const baseQueryParams = baseQs ? qs.parse(baseQs.toString()) : {};

  map(filters, ({ filterType, value }, key) => {
    if (filterType === 'multiSelect' || filterType === 'checkbox-group') {
      baseQueryParams[key] = join(castArray(value), '|');
    }
    if (filterType === 'range' || filterType === 'date-range') {
      const { start, end } = value;
      const paramsArray = [start, end];

      baseQueryParams[key] = join(
        map(paramsArray, (param) => (isFinite(parseInt(param)) ? param : '_')),
        '~'
      );
    }
  });

  const querystring = qs.stringify(baseQueryParams);

  return querystring;
};

export const isBooleanValuesArray = (arr: unknown[]) => every(uniq(flatten(arr)), (item) => typeof item === 'boolean');

const sortBooleans = (arr: boolean[]) => {
  return arr.sort((a, b) => (a === b ? 0 : a ? -1 : 1));
};

const isNumberArray = (arr: unknown[]) => every(arr, (item) => !isNaN(Number(item)));

const parseMultiselectValue = (muliselectValue: string | boolean[]) => {
  if (isString(muliselectValue)) {
    if (includes(muliselectValue, '|')) {
      const splitValues = split(muliselectValue, '|');
      return isNumberArray(splitValues) ? splitValues.map(Number) : splitValues;
    }
  } else if (isArray(muliselectValue)) {
    return isBooleanValuesArray(muliselectValue) ? sortBooleans(muliselectValue) : muliselectValue;
  }
  return isNumberArray([muliselectValue]) ? [Number(muliselectValue)] : [muliselectValue];
};

export const getFiltersFromQueryObject = (
  queryParamsObject: Record<string, string>,
  includeMainFilters: boolean = true
) => {
  const mainInputs = pick(queryParamsObject, enumValuesList(MainPageFilterKey));

  const uncategorizedInputs = pick(queryParamsObject, enumValuesList(GeneralFilterKey));

  const flatFieldConfig = flattenConfigObject(filterFieldsConfig);
  const searchFiltersFromQueryParams: Partial<Record<FilterKey, QueryFilter | boolean>> = {};

  map(queryParamsObject, (value, key) => {
    const originalField = find(flatFieldConfig, (field) => field.dataKey === key);
    if (originalField) {
      const fieldCategory = originalField.dataCategory;
      if (originalField.filterType === 'multiSelect' || originalField.filterType === 'checkbox-group') {
        const selected = parseMultiselectValue(value);

        searchFiltersFromQueryParams[key as FilterKey] = {
          category: fieldCategory,
          value: selected as any,
          filterType: originalField.filterType,
        };
      }
      if (originalField.filterType === 'range' || originalField.filterType === 'date-range') {
        if (typeof value === 'string') {
          const [start, end] = split(value, '~');

          searchFiltersFromQueryParams[key as FilterKey] = {
            category: fieldCategory,
            value:
              originalField.filterType === 'range' && originalField.conversionFactor
                ? {
                    start: !isNaN(Number(start))
                      ? Number(Number(start) / originalField.conversionFactor).toFixed(0)
                      : undefined,
                    end: !isNaN(Number(end))
                      ? Number(Number(end) / originalField.conversionFactor).toFixed(0)
                      : undefined,
                  }
                : {
                    start,
                    end,
                  },
            filterType: originalField.filterType,
          };
        } else {
          const field = value as QueryFilter;
          searchFiltersFromQueryParams[key as FilterKey] = {
            category: fieldCategory,
            value: field.value as any,
            filterType: originalField.filterType,
          };
        }
      }
    }
  });

  return includeMainFilters
    ? { ...mainInputs, ...uncategorizedInputs, ...searchFiltersFromQueryParams }
    : { ...uncategorizedInputs, ...searchFiltersFromQueryParams };
};

export const parseRangeObjectToStringParam = (range: { start: string; end: string }) => {
  const { start, end } = range;
  const paramsArray = [start, end];
  return join(
    map(paramsArray, (param) => (isFinite(parseInt(param)) ? param : '_')),
    '~'
  );
};

export const getQueryParamsObjectFromFilters = (filters: any) => {
  const queryParamsObject: Record<string, string | number> = {};
  map(filters, (filter, key) => {
    if (filter?.filterType === 'multiSelect' || filter?.filterType === 'checkbox-group') {
      // if the values of the multiselect are booleans, we want to pass the values as they are and not join them
      if (isBooleanValuesArray(filter.value)) {
        queryParamsObject[key] = filter.value;
      } else {
        queryParamsObject[key] = join(castArray(filter.value), '|');
      }
    } else if (filter?.filterType === 'range' || filter?.filterType === 'date-range') {
      if (!filter.value) return;

      queryParamsObject[key] = parseRangeObjectToStringParam(filter.value);
    } else {
      if (key in [key as keyof BasicFiltersQueryParams]) {
        queryParamsObject[key] = filters[key];
      }
    }
  });
  return queryParamsObject;
};

export const flattenConfigObject = (config: FieldConfigItem[]) => {
  const flatFieldConfig: Field[] = [];

  forEach(config, (section) => {
    forEach(section.fieldGroups, (fieldGroup) => {
      forEach(fieldGroup.fields, (field) => {
        flatFieldConfig.push(field);
      });
    });
  });

  return flatFieldConfig;
};

export const isRange = (filterType: string) => {
  return filterType === 'range' || filterType === 'date-range';
};
