import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, Grid, List, ListItemButton, ListItemText, Popover } from '@mui/material';
import { ActionInput, PostProcessingActionCreated } from 'interfaces/postProcessingAction';
import { Dictionary, filter, isEmpty, map, pullAt } from 'lodash';
import React, { useState } from 'react';
import { NewCell } from '..';
import { PostProcessingAction } from '../PostProcessingActions/PostProcessingAction';
import { useActionsQuery } from '../PostProcessingActions/usePostProcessingActions';
import { createNewAction } from '../PostProcessingActions/utils';
import { NewCellsModal } from './NewCellsModal';

interface FeatureActionsProps {
  actions: PostProcessingActionCreated[];
  setActions: React.Dispatch<React.SetStateAction<PostProcessingActionCreated[]>>;
  stains: string[];
  getOptions: (
    optionSource: string | string[],
    selectedStains: string[],
    index: number,
    actionInput: string,
    inputSourceDependentOn?: string
  ) => any[];
  getOptionsByValue: (
    optionSource: string | string[],
    selectedStains: string[],
    index: number,
    actionInput: string,
    inputSourceDependentOn?: string
  ) => Dictionary<{ id: string | number; label: string }>;
  getMappingFiltersMetadataForLogicalQuery: (stain: string, actionInput: string, index?: number) => any;
  newCells: Record<string, NewCell>;
  setNewCells: React.Dispatch<React.SetStateAction<Record<string, NewCell>>>;
}

export const FeatureActions: React.FC<React.PropsWithChildren<FeatureActionsProps>> = ({
  actions,
  setActions,
  stains,
  getOptions,
  getOptionsByValue,
  getMappingFiltersMetadataForLogicalQuery,
  newCells,
  setNewCells,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isNewCellsModalOpen, setIsNewCellsModalOpen] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { postProcessingActionsById, postProcessingActionsFeatures } = useActionsQuery();

  const addNewAction = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, postProcessingActionId: string) => {
    setAnchorEl(null);
    const newAction = createNewAction(postProcessingActionId, postProcessingActionsById);

    setActions((prevActions) => [...prevActions, newAction]);
  };

  const removeAction = (index: number) => {
    setActions((prevActions) => {
      const newActions = [...prevActions];
      pullAt(newActions, index);
      return newActions;
    });
  };

  const setAction = (action: any, index: number) => {
    setActions((prevActions) => {
      let newActions = [...prevActions];
      newActions[index] = action;

      return newActions;
    });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      setActions((prev) => {
        const activeIndex = prev.findIndex((item) => item.id === active?.id);
        const overIndex = prev.findIndex((item) => item.id === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  return (
    <>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={actions} strategy={verticalListSortingStrategy}>
          <Grid container direction="column">
            {map(actions, (action, index) => {
              return (
                <Grid item mb={1} key={action.id}>
                  <PostProcessingAction
                    key={action.id}
                    id={action.id}
                    index={index}
                    postProcessingActionsById={postProcessingActionsById}
                    action={action}
                    setAction={setAction}
                    removeAction={removeAction}
                    getOptions={getOptions}
                    getOptionsByValue={getOptionsByValue}
                    getMappingFiltersMetadataForLogicalQuery={getMappingFiltersMetadataForLogicalQuery}
                  />
                </Grid>
              );
            })}
          </Grid>
        </SortableContext>
      </DndContext>
      <Button
        aria-describedby="simple-popover"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
      >
        Add New Feature
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          {map(
            filter(postProcessingActionsFeatures, (postProcessingAction) => isEmpty(postProcessingAction.deletedAt)),
            (postProcessingAction) => (
              <ListItemButton
                key={postProcessingAction.id}
                onClick={(event) => addNewAction(event, postProcessingAction.id)}
              >
                <ListItemText primary={postProcessingAction.actionDisplayName ?? postProcessingAction.actionName} />
              </ListItemButton>
            )
          )}
        </List>
      </Popover>
      <Button aria-describedby="simple-popover" onClick={() => setIsNewCellsModalOpen(true)}>
        New Cell Filters
      </Button>
      {isNewCellsModalOpen && (
        <NewCellsModal
          stains={stains}
          newCells={newCells}
          setNewCells={setNewCells}
          onClose={() => setIsNewCellsModalOpen(false)}
          getMappingFiltersMetadataForLogicalQuery={(stain: string) =>
            getMappingFiltersMetadataForLogicalQuery(stain, ActionInput.FEATURE_FAMILY)
          }
        />
      )}
    </>
  );
};
