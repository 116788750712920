import { Grid, Skeleton, Typography } from '@mui/material';
import { AnnotationAssignment } from 'interfaces/annotation';
import {
  ClassificationBinningParams,
  InferenceModelData,
  ModelInference,
  OrchestrationInference,
  SlideInferenceResults,
} from 'interfaces/calculateFeatures';
import {
  filter,
  findIndex,
  first,
  flatMap,
  forEach,
  get,
  isEmpty,
  keyBy,
  keys,
  map,
  omit,
  pickBy,
  set,
  size,
  values,
} from 'lodash';
import React from 'react';
import {
  AssignmentByStain,
  ModelClassificationById,
  OrchestrationBySlideByFlowClassName,
  OrchestrationBySlideByType,
  SelectedAssignmentDetails,
} from '..';
import AssignmentStain from './AssignmentStain';
import InferenceStain from './InferenceStain';
import Summary from './Summary';

export interface ModelsByStainByType {
  [stain: string]: {
    [modelType: string]: {
      [modelUrl: string]: ModelInference;
    };
  };
}

export interface OrchestrationByStainByFlowClassName {
  [stain: string]: {
    [flowClassName: string]: OrchestrationInference[];
  };
}

export interface InferenceModelsForSlidesProps {
  studyId: string;
  isLoading: boolean;
  slides: SlideInferenceResults[];
  inferenceModels: ModelInference[];
  postprocessedArtifacts?: OrchestrationInference[];
  displayAssignmentAnnotations?: boolean;
  assignmentAnnotationsByStainType?: { [stainType: string]: AnnotationAssignment[] };
  selectedAssignments?: AssignmentByStain;
  setSelectedAssignments?: React.Dispatch<React.SetStateAction<AssignmentByStain>>;
  modelsType: string[];
  selectedOrchestrations: OrchestrationBySlideByType;
  setSelectedOrchestrations: React.Dispatch<React.SetStateAction<OrchestrationBySlideByType>>;
  selectedPostprocessedOrchestrations?: OrchestrationBySlideByFlowClassName;
  setSelectedPostprocessedOrchestrations?: React.Dispatch<React.SetStateAction<OrchestrationBySlideByFlowClassName>>;
  setModelClassificationByModelId?: (modelId: string, classification: string) => void;
  modelClassificationByModelId?: ModelClassificationById;
  setIntensityClassificationBinning?: (modelId: string, binning: ClassificationBinningParams) => void;
}

const InferenceModelsForSlides: React.FC<React.PropsWithChildren<InferenceModelsForSlidesProps>> = ({
  studyId,
  isLoading,
  slides,
  inferenceModels,
  postprocessedArtifacts,
  modelsType,
  displayAssignmentAnnotations = false,
  assignmentAnnotationsByStainType,
  selectedAssignments,
  setSelectedAssignments,
  selectedOrchestrations,
  setSelectedOrchestrations,
  selectedPostprocessedOrchestrations,
  setSelectedPostprocessedOrchestrations,
  modelClassificationByModelId,
  setModelClassificationByModelId,
  setIntensityClassificationBinning,
}) => {
  const slidesById = keyBy(slides, 'slideId');

  const setSelectedOrchestrationsByClassificationType = (modelType: string, previousModelType?: string) => {
    const slideIdsWithPreviousModelType = filter(
      keys(selectedOrchestrations),
      (slideId) => !isEmpty(get(selectedOrchestrations[slideId], previousModelType))
    );

    forEach(slideIdsWithPreviousModelType, (slideId) => {
      setSelectedOrchestrations((prev) => {
        const newOrchestrations = { ...prev };

        if (!isEmpty(modelType)) {
          newOrchestrations[slideId] = {
            ...newOrchestrations[slideId],
            [modelType]: get(newOrchestrations[slideId], previousModelType),
          };
        }

        newOrchestrations[slideId] = omit(newOrchestrations[slideId], previousModelType);

        return newOrchestrations;
      });
    });
  };

  const setSelectedOrchestrationsByTypeByStain = (
    slideIds: string[],
    model?: InferenceModelData,
    modelType?: string,
    orchestration?: OrchestrationInference
  ) => {
    setSelectedOrchestrations((prev) => {
      const newOrchestrations = { ...prev };

      if (isEmpty(model)) {
        forEach(slideIds, (slideId) => {
          newOrchestrations[slideId] = {};
        });
      } else if (isEmpty(orchestration)) {
        forEach(slideIds, (slideId) => {
          newOrchestrations[slideId] = omit(newOrchestrations[slideId], modelType);
        });
      } else {
        forEach(slideIds, (slideId) => {
          newOrchestrations[slideId] = {
            ...newOrchestrations[slideId],
            [modelType]: {
              orchestration,
              model,
            },
          };
        });
      }

      return newOrchestrations;
    });
  };

  const setSelectedPostprocessedOrchestrationsByFlowClassNameByStain = (
    slideIds: string[],
    flowClassName?: string,
    orchestration?: OrchestrationInference
  ) => {
    setSelectedPostprocessedOrchestrations((prev) => {
      const newOrchestrations = { ...prev };

      if (isEmpty(flowClassName)) {
        forEach(slideIds, (slideId) => {
          newOrchestrations[slideId] = {};
        });
      } else if (isEmpty(orchestration)) {
        forEach(slideIds, (slideId) => {
          newOrchestrations[slideId] = omit(newOrchestrations[slideId], flowClassName);
        });
      } else {
        forEach(slideIds, (slideId) => {
          newOrchestrations[slideId] = {
            ...newOrchestrations[slideId],
            [flowClassName]: {
              orchestration,
            },
          };
        });
      }

      return newOrchestrations;
    });
  };
  const addNewOverrideAssignment = (stain: string, newAssignment: SelectedAssignmentDetails) => {
    setSelectedAssignments((prev) => {
      const newAssignments = { ...prev };
      newAssignments[stain] = [...(newAssignments[stain] ?? []), newAssignment];

      return newAssignments;
    });
  };

  const updateSelectedAssignment = (stain: string, updatedAssignment: SelectedAssignmentDetails) => {
    setSelectedAssignments((prev) => {
      const newAssignments = { ...prev };
      const updatedAssignmentIndex = findIndex(newAssignments[stain], { id: updatedAssignment.id });
      newAssignments[stain][updatedAssignmentIndex] = updatedAssignment;
      return newAssignments;
    });
  };

  const removeSelectedAssignment = (stain: string, assignmentOverrideId: string) => {
    setSelectedAssignments((prev) => {
      const newAssignments = { ...prev };
      newAssignments[stain] = filter(newAssignments[stain], (assignment) => assignment.id !== assignmentOverrideId);
      return newAssignments;
    });
  };

  const getStainOfOrchestration = (slide_ids: string[]) => {
    return slidesById[first(slide_ids)]?.stainingType;
  };

  const modelsByStain: ModelsByStainByType = {};

  forEach(inferenceModels, (inferenceModel) => {
    forEach(inferenceModel.orchestrations, (orchestration) => {
      const stain = getStainOfOrchestration(orchestration.slideIds);
      const modelType = inferenceModel.modelType;
      const modelUrl = inferenceModel.modelUrl;

      // Initialize nested structure if not already present
      set(modelsByStain, [stain], get(modelsByStain, [stain], {}));
      set(modelsByStain, [stain, modelType], get(modelsByStain, [stain, modelType], {}));
      set(
        modelsByStain,
        [stain, modelType, modelUrl],
        get(modelsByStain, [stain, modelType, modelUrl], {
          ...omit(inferenceModel, 'orchestrations'),
          orchestrations: [],
        })
      );

      modelsByStain[stain][modelType][modelUrl].orchestrations.push(orchestration);
    });
  });

  const postprocessedArtifactsByStain: OrchestrationByStainByFlowClassName = {};

  forEach(postprocessedArtifacts, (postprocessedArtifact) => {
    const stain = getStainOfOrchestration(postprocessedArtifact.slideIds);
    const flowClassName = postprocessedArtifact.params.flowClassName;

    // Initialize nested structure if not already present
    set(postprocessedArtifactsByStain, [stain], get(postprocessedArtifactsByStain, [stain], {}));
    set(
      postprocessedArtifactsByStain,
      [stain, flowClassName],
      get(postprocessedArtifactsByStain, [stain, flowClassName], [])
    );

    postprocessedArtifactsByStain[stain][flowClassName].push(postprocessedArtifact);
  });

  if (isLoading) {
    return <Skeleton variant="rectangular" height={150} />;
  } else if (!displayAssignmentAnnotations && isEmpty(inferenceModels)) {
    return <Typography>No inference models found for this study</Typography>;
  } else if (
    displayAssignmentAnnotations &&
    isEmpty(inferenceModels) &&
    isEmpty(flatMap(values(assignmentAnnotationsByStainType)))
  ) {
    return <Typography>No inference models or annotation assignments found for this study</Typography>;
  } else {
    return (
      <Grid container spacing={2} mb={1}>
        <Grid item container xs={6} spacing={5} direction="column" overflow="auto">
          <Grid item container spacing={1}>
            <Grid item>
              <Typography>Inference Results:</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} direction="column">
                {map(modelsByStain, (stainModelInferences, stain) => {
                  return (
                    <Grid item key={stain}>
                      <InferenceStain
                        studyId={studyId}
                        key={stain}
                        stain={stain}
                        stainModelInferences={stainModelInferences}
                        slides={keyBy(slides, 'slideId')}
                        selectedOrchestrations={selectedOrchestrations}
                        setSelectedOrchestrations={setSelectedOrchestrationsByTypeByStain}
                        modelClassificationByModelId={modelClassificationByModelId}
                        setModelClassificationByModelId={setModelClassificationByModelId}
                        setSelectedOrchestrationsByClassificationType={setSelectedOrchestrationsByClassificationType}
                        setIntensityClassificationBinning={setIntensityClassificationBinning}
                        defaultExpanded={size(modelsByStain) === 1}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          {displayAssignmentAnnotations && (
            <Grid item container spacing={1}>
              <Grid item>
                <Typography>Assignments Results:</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1} direction="column">
                  {map(
                    pickBy(
                      assignmentAnnotationsByStainType,
                      (annotationAssignments) => !isEmpty(annotationAssignments)
                    ),
                    (annotationAssignment, stain) => {
                      return (
                        <Grid item key={stain}>
                          <AssignmentStain
                            stain={stain}
                            annotationAssignments={annotationAssignment}
                            selectedAssignments={selectedAssignments[stain]}
                            addNewOverrideAssignment={(newAssignment) => addNewOverrideAssignment(stain, newAssignment)}
                            updateSelectedAssignment={(updatedAssignment) =>
                              updateSelectedAssignment(stain, updatedAssignment)
                            }
                            removeSelectedAssignment={(assignmentOverrideId) =>
                              removeSelectedAssignment(stain, assignmentOverrideId)
                            }
                            defaultExpanded={size(assignmentAnnotationsByStainType) === 1}
                          />
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item container spacing={1}>
            <Grid item>
              <Typography>Post Processed Results:</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} direction="column">
                {map(postprocessedArtifactsByStain, (orchestrationByFlowClassName, stain) => {
                  return (
                    <Grid item key={stain}>
                      <InferenceStain
                        studyId={studyId}
                        key={stain}
                        stain={stain}
                        orchestrationByFlowClassName={orchestrationByFlowClassName}
                        slides={keyBy(slides, 'slideId')}
                        selectedPostprocessedOrchestrations={selectedPostprocessedOrchestrations}
                        setSelectedPostprocessedOrchestrations={
                          setSelectedPostprocessedOrchestrationsByFlowClassNameByStain
                        }
                        defaultExpanded={size(postprocessedArtifactsByStain) === 1}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Summary
            modelsType={modelsType}
            slides={keyBy(slides, 'slideId')}
            selectedOrchestrations={selectedOrchestrations}
            selectedPostprocessedOrchestrations={selectedPostprocessedOrchestrations}
            selectedAssignments={selectedAssignments}
            removeSelectedModels={(slideIds) => {
              setSelectedOrchestrationsByTypeByStain(slideIds);
              setSelectedPostprocessedOrchestrationsByFlowClassNameByStain(slideIds);
            }}
            displayAssignmentAnnotations={displayAssignmentAnnotations}
          />
        </Grid>
      </Grid>
    );
  }
};

export default InferenceModelsForSlides;
