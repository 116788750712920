import { styled, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import { Procedure } from 'interfaces/procedure';
import { first } from 'lodash';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { BooleanParam, JsonParam, NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';
import { CaseCardContents } from './CaseCardContents';

export const StyledCard = styled(Card)(() => ({
  borderRadius: '15px',

  '&:hover': {
    boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.2)',
  },

  'a:hover': {
    color: 'inherit',
  },
}));

export type CardMode = 'minimal' | 'full';
interface Props {
  procedure: Procedure;
  galleryView?: boolean;
  mode?: CardMode;
  isSelected?: boolean;
  selectable?: boolean;
  forceDisplayCheckbox?: boolean;
  pendingSlidesMode?: boolean;
}

const ProcedureCard: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  procedure,
  galleryView,
  mode,
  isSelected,
  selectable,
  forceDisplayCheckbox,
  pendingSlidesMode,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [queryParams, setQueryParams] = useQueryParams({
    page: NumberParam,
    showGallery: BooleanParam,
    galleryPage: NumberParam,
    galleryWindowCaseId: NumberParam,
    galleryWindowSlideId: StringParam,
    slidesMode: BooleanParam,
    filters: JsonParam,
  });

  const [currentVisibleSlide, setCurrentVisibleSlide] = useState(first(procedure.slides));

  const theme = useTheme();

  const contents = (
    <CaseCardContents
      currentVisibleSlide={currentVisibleSlide}
      setCurrentVisibleSlide={setCurrentVisibleSlide}
      procedure={procedure}
      galleryView={galleryView}
      mode={mode}
      selectable={selectable}
      forceDisplayCheckbox={forceDisplayCheckbox}
    >
      {children}
    </CaseCardContents>
  );

  const { getLinkToCasePage, getLinkToSlidePage } = useNavigationToViewerPage();

  return (
    <StyledCard
      sx={{
        cursor: 'pointer',
        borderWidth: isSelected ? '2px' : '1px',
        borderColor: isSelected ? theme.palette.primary.main : theme.palette.secondary.light,
      }}
      variant="outlined"
      data-test-class={`procedure-card-${procedure?.id}`}
      data-cy={`case-card-${procedure?.id}`}
      onClick={
        galleryView
          ? () =>
              setQueryParams(
                {
                  showGallery: true,
                  page: queryParams?.page,
                  galleryPage: queryParams?.page ?? 1,
                  galleryWindowCaseId: procedure.id,
                  galleryWindowSlideId: currentVisibleSlide?.id,
                  slidesMode: queryParams?.slidesMode,
                },
                'pushIn'
              )
          : undefined
      }
    >
      {galleryView ? (
        contents
      ) : (
        <Link
          component={RouterLink}
          color="secondary"
          underline="none"
          to={
            queryParams.slidesMode
              ? getLinkToSlidePage({
                  slideId: first(procedure.slides)?.id,
                  caseId: pendingSlidesMode ? undefined : procedure.id,
                  caseStudyId: pendingSlidesMode ? undefined : procedure.studyId,
                })
              : getLinkToCasePage({
                  caseId: procedure.id,
                  caseStudyId: procedure.studyId,
                })
          }
        >
          {contents}
        </Link>
      )}
    </StyledCard>
  );
};

export default ProcedureCard;
