import { Grid, Typography } from '@mui/material';
import React from 'react';

import { TableEditingContextProvider } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { RowSelectionContextProvider } from 'components/atoms/RowSelectionContext';
import wrapPage from 'components/atoms/wrapPage/wrapPage';
import { Permission } from 'interfaces/permissionOption';
import useTaxonomy from 'utils/queryHooks/taxonomy/useTaxonomy';
import { useQueryableTypes } from 'utils/queryHooks/uiConstantsHooks';
import { usePermissions } from 'utils/usePermissions';
import { AreaTypesDataGrid } from './AreaTypesDataGrid';
import { getAreaTypeId } from './AreaTypesDataGrid/utils';
import { GlobalCellRulesPanel } from './CellRulesDataGrid/GlobalCellRules';
import { ClusterTypesDataGrid } from './ClusterTypesDataGrid';
import { ExternalLabelsDataGrid } from './ExternalLabelsDataGrid';
import { ModelTypesDataGrid } from './ModelTypesDataGrid';
import { getModelTypeId } from './ModelTypesDataGrid/utils';
import { SlideTagsDataGrid } from './SlideTagsDataGrid';
import { StainTypesDataGrid } from './StainTypesDataGrid';
import { getStainTypeId } from './StainTypesDataGrid/utils';
import TaxonomyEditor from './Taxonomy';
import { TaxonomyTagsDataGrid } from './TaxonomyTagsDataGrid';

export const OperationsSettings = wrapPage(() => {
  const { hasPermission } = usePermissions();

  const canEditStainTypes = hasPermission(Permission.EditStainTypeOptions);
  const canEditSlideTags = hasPermission(Permission.EditSlideTagOptions);
  const canEditExternalLabels = hasPermission(Permission.EditExternalLabelOptions);
  const canEditAreaTypes = hasPermission(Permission.EditAreaTypeOptions);
  const canEditClusterTypes = hasPermission(Permission.EditClusterTypeOptions);
  const canEditTaxonomy = hasPermission(Permission.EditTaxonomyOptions);
  const canEditCellRules = hasPermission(Permission.EditCellRules);
  const canEditModelTypes = hasPermission(Permission.EditModelTypeOptions);

  const { queryableTypes, isLoadingQueryableTypes } = useQueryableTypes();

  const taxonomy = useTaxonomy();

  return (
    <>
      <Grid container direction="column" spacing={3} alignItems="flex-start">
        {canEditStainTypes && (
          <Grid item container direction="column">
            <Grid item my={4}>
              <Typography variant="h2">Stain Types Management</Typography>
            </Grid>
            <Grid item>
              <RowSelectionContextProvider>
                <TableEditingContextProvider idGetter={getStainTypeId}>
                  <StainTypesDataGrid />
                </TableEditingContextProvider>
              </RowSelectionContextProvider>
            </Grid>
          </Grid>
        )}
        {canEditCellRules && (
          <Grid item container direction="column" width="100%">
            <Grid item my={4}>
              <Typography variant="h2">Cell Rules Management</Typography>
            </Grid>
            <Grid item width="100%">
              <GlobalCellRulesPanel />
            </Grid>
          </Grid>
        )}
        {canEditSlideTags && (
          <Grid item container direction="column" width="100%">
            <Grid item my={4}>
              <Typography variant="h2">Slide Tags Management</Typography>
            </Grid>
            <Grid item width="100%">
              <RowSelectionContextProvider>
                <TableEditingContextProvider>
                  <SlideTagsDataGrid />
                </TableEditingContextProvider>
              </RowSelectionContextProvider>
            </Grid>
          </Grid>
        )}
        {canEditExternalLabels && (
          <Grid item container direction="column" width="100%">
            <Grid item my={4}>
              <Typography variant="h2">External Labels Management</Typography>
            </Grid>
            <Grid item width="100%">
              <RowSelectionContextProvider>
                <TableEditingContextProvider>
                  <ExternalLabelsDataGrid />
                </TableEditingContextProvider>
              </RowSelectionContextProvider>
            </Grid>
          </Grid>
        )}

        {canEditAreaTypes && (
          <Grid item container direction="column" width="100%">
            <Grid item my={4}>
              <Typography variant="h2">Area Types Management</Typography>
            </Grid>
            <Grid item width="100%">
              <RowSelectionContextProvider>
                <TableEditingContextProvider
                  idGetter={getAreaTypeId}
                  fieldsContext={{ sourceTypes: queryableTypes.modelType }}
                >
                  <AreaTypesDataGrid isLoading={isLoadingQueryableTypes} />
                </TableEditingContextProvider>
              </RowSelectionContextProvider>
            </Grid>
          </Grid>
        )}
        {canEditClusterTypes && (
          <Grid item container direction="column" width="100%">
            <Grid item my={4}>
              <Typography variant="h2">Cluster Types Management</Typography>
            </Grid>
            <Grid item width="100%">
              <RowSelectionContextProvider>
                <TableEditingContextProvider>
                  <ClusterTypesDataGrid />
                </TableEditingContextProvider>
              </RowSelectionContextProvider>
            </Grid>
          </Grid>
        )}
        {canEditTaxonomy && (
          <Grid item container direction="column" width="100%">
            <Grid item my={4}>
              <Typography variant="h2">Taxonomy Editor</Typography>
            </Grid>
            <Grid item width="100%">
              <TaxonomyEditor />
            </Grid>
            <Grid item my={4}>
              <Typography variant="h2">Taxonomy Tag Management</Typography>
            </Grid>
            <Grid item width="100%">
              <RowSelectionContextProvider>
                <TableEditingContextProvider
                  fieldsContext={{
                    taxonomies: taxonomy.data,
                  }}
                >
                  <TaxonomyTagsDataGrid />
                </TableEditingContextProvider>
              </RowSelectionContextProvider>
            </Grid>
          </Grid>
        )}
        {canEditModelTypes && (
          <Grid item container direction="column" width="100%">
            <Grid item my={4}>
              <Typography variant="h2">Model Types Management</Typography>
            </Grid>
            <Grid item width="100%">
              <RowSelectionContextProvider>
                <TableEditingContextProvider
                  idGetter={getModelTypeId}
                  fieldsContext={{
                    trainingTypes: queryableTypes.trainingType,
                  }}
                >
                  <ModelTypesDataGrid />
                </TableEditingContextProvider>
              </RowSelectionContextProvider>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
});

export default OperationsSettings;
