import { SearchIndex, SlideSearchData } from 'interfaces/searchIndex';
import { forEach } from 'lodash';

export interface CaseSearchItem {
  caseId?: string | number;
  caseLabel?: string;
  studyId?: string;
  studyName?: string;
  slides: SlideSearchData[];
  isSlideOnly?: boolean;
}

export const flattenSearchIndex = (searchIndex: SearchIndex) => {
  const flattenedSearchIndex: CaseSearchItem[] = [];

  forEach(searchIndex.studies, (study) => {
    forEach(study.cases, (caseData) => {
      flattenedSearchIndex.push({
        studyId: study.id,
        studyName: study.name,
        caseId: caseData.id,
        caseLabel: caseData.label,
        slides: caseData.slides,
      });
    });
  });

  return flattenedSearchIndex;
};
