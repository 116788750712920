import { MenuItem, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { NON_SPECIFIC_STUDY_VALUES } from 'components/SearchFilters/hooks/useMainFilters';
import { includes, toString } from 'lodash';
import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCasesParams } from 'utils/useCasesParams';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { encodeQueryParamsUsingSchema, useEncodedFilters } from 'utils/useEncodedFilters';

export interface CalculateFeaturesMenuItemProps {
  disabled: boolean;
}

const CalculateFeaturesMenuItem: FunctionComponent<React.PropsWithChildren<CalculateFeaturesMenuItemProps>> = ({
  disabled,
}) => {
  const navigate = useNavigate();
  const { labSearch } = useCurrentLabId();
  const {
    queryParams: { filters },
  } = useEncodedFilters();
  const { casesParams, schema, options } = useCasesParams();

  const isSelectedStudy = filters?.studyId && !includes(NON_SPECIFIC_STUDY_VALUES, toString(filters.studyId));

  const navigateToCalculateFeatures = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    const encodedQuery = encodeQueryParamsUsingSchema(casesParams, schema, options);

    navigate(
      { pathname: `/calculate-features`, search: `${labSearch}` },
      { state: { encodedQuery, studyId: filters?.studyId } }
    );
  };

  return (
    <MenuItem disabled={!isSelectedStudy || disabled} onClick={navigateToCalculateFeatures}>
      <Tooltip title={!isSelectedStudy ? 'Select Study' : disabled && 'Select procedures/slides'} placement="top" arrow>
        <Box>Calculate Features</Box>
      </Tooltip>
    </MenuItem>
  );
};

export default CalculateFeaturesMenuItem;
