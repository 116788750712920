import colorLib from '@kurkle/color';
import { ChartDataset } from 'chart.js';
import { Context } from 'chartjs-plugin-datalabels';
import { Font } from 'chartjs-plugin-datalabels/types/options';
import ColorHash from 'color-hash';
import { ChartType } from 'interfaces/chart';
import { ClinicalData } from 'interfaces/clinical';
import { CohortWithSelectedFeatures, PartialCohort } from 'interfaces/cohort_old';
import { Features } from 'interfaces/experimentResults';
import { InferredFeatureConfig } from 'interfaces/inferredFeatures';
import { Procedure } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import {
  compact,
  every,
  filter,
  find,
  flatten,
  isArray,
  isEmpty,
  isInteger,
  isNumber,
  join,
  keys,
  countBy as lodashCountBy,
  map,
  mapValues,
  max,
  min,
  some,
  sortBy,
  uniq,
  values,
} from 'lodash';
import { compose, curry, flatMap, map as fmap, get, isNaN, pipe } from 'lodash/fp';
import numeral from 'numeral';
import { firstSlideWithResults, getGroupingIteratee, getSlideMapFromProcedures } from 'utils/cohort.util';
import { isDistanceBasedFeatureKey } from 'utils/features';
import { ControlledChartOptions } from './ControlledChart';
import { borderColorPalette, colorPalette } from './chartsColorPallete';

interface ChartPoint {
  x: number;
  y: number;
  xUnit: string;
  yUnit: string;
}

export interface Dataset {
  type: string;
  label: string | string[];
  data: ChartDataset['data'];
  borderColor: string;
  backgroundColor: string;
}

export type CategoricalKey = keyof Slide | keyof ClinicalData | keyof Procedure;

export enum ChartKeyType {
  Categorical = 'categorical',
  Numerical = 'numerical',
  DistanceBased = 'distance based',
}

export interface ChartKey {
  name: string;
  type: ChartKeyType;
}

export interface CategoricalChartKey extends ChartKey {
  type: ChartKeyType.Categorical;
}

export const isCategoricalChartKey = (key: ChartKey): key is CategoricalChartKey =>
  key?.type === ChartKeyType.Categorical;

export interface NumericalChartKey extends ChartKey {
  type: ChartKeyType.Numerical;
}

export const isNumericalChartKey = (key: ChartKey): key is NumericalChartKey => key?.type === ChartKeyType.Numerical;

export interface DistanceBasedChartKey extends ChartKey {
  type: ChartKeyType.DistanceBased;
}

export const isDistanceBasedChartKey = (key: ChartKey): key is DistanceBasedChartKey =>
  key?.type === ChartKeyType.DistanceBased;

export type CountBy = 'Slides' | 'Cases';

export const convertToChartKeys = (stringKeys: string[], type: ChartKeyType = ChartKeyType.Categorical): ChartKey[] => {
  return stringKeys.map((key) => convertToChartKey(key, type));
};

export const convertToChartKey = (name: string, type: ChartKeyType = ChartKeyType.Categorical): ChartKey => {
  return { name: name, type: type };
};

// cohortToProcedures :: cohort -> procedure[]
export const cohortToProcedures: (cohort: PartialCohort) => Procedure[] = get('procedures');

// procedureToSlides :: procedure -> slide[]
export const procedureToSlides: (procedure: Procedure) => Slide[] = get('slides');

// cohortToSlides :: cohort -> slide[]
export const cohortToSlides: (cohort: PartialCohort) => Slide[] = pipe(cohortToProcedures, flatMap(procedureToSlides));

// slideToFeatures :: slide -> feature[]
export const slideToFeatures = (slide: Slide): Features => {
  return (
    find(slide?.experimentResults, (experimentResult) => {
      return !isEmpty(experimentResult?.features);
    })?.features || {}
  );
};

const hasPointValue = (value: Features[keyof Features] | undefined): value is string | number =>
  value !== undefined && !isArray(value);

// procedureToFeatureTable :: procedure -> features[]
export const procedureToFeatureTable = pipe(procedureToSlides, fmap(slideToFeatures));

// procedure -> features[]
export const flattenProcedure = (procedure: Procedure): Features[] => {
  return procedureToFeatureTable(procedure);
};

// procedure[] -> features[]
export const flattenProcedures: (procedures: Procedure[]) => Features[] = flatMap(flattenProcedure);

// cohort -> features[]
export const flattenCohort: (cohort: PartialCohort) => Features[] = compose(flattenProcedures, cohortToProcedures);

export const cleanFeature = (featureValue: string | number): number => {
  const [value] = cleanValue(featureValue);
  return value;
};

// returns an array of feature values for a given key
export const valuesFromKey = curry((key: string, features: Features[]): Array<number | number[]> => {
  return map(filter(features, key), (feature) => {
    const featureValue = feature[key];
    return isArray(featureValue) ? map(featureValue, (value) => cleanFeature(value)) : cleanFeature(featureValue);
  });
});

export const cohortToValues = (key: string) => {
  return compose(valuesFromKey(key), flattenCohort);
};

// transforms a value into a number and a unit, unit is optional
export const cleanValue = (value: string | number): [number, string?] => {
  if (isNumber(value)) {
    return [value];
  } else if (!value) {
    return [0];
  }
  const [numericalPart, ...unitParts] = value.split(' ');
  const numericalValue = parseFloat(numericalPart);
  const unit = join(unitParts, ' ');
  return [isNaN(numericalValue) ? 0 : numericalValue, unit];
};

// slideToPoint :: (key, key) -> Slide -> Point
export const slideTo2DPoint = curry((xKey: string, yKey: string, slide: Slide): ChartPoint => {
  const features = slideToFeatures(slide);
  const xValue = features[xKey];
  const yValue = features[yKey];

  if (!hasPointValue(xValue) || !hasPointValue(yValue)) {
    return null;
  }

  const [x, xUnit] = cleanValue(xValue);
  const [y, yUnit] = cleanValue(yValue);

  return {
    x,
    y,
    xUnit,
    yUnit,
  };
});

// (cohort, feature name) -> list of values for that feature in that cohort
export const cohortToFeatureData = (cohort: CohortWithSelectedFeatures, feature: string): number[] => {
  return pipe(cohortToProcedures, fmap(caseToFeatureValue(feature)))(cohort);
};

export const slideTo1DPoint = curry((key: string, slide: Slide): [number, string?] => {
  const features = slideToFeatures(slide);

  const value = features[key];

  if (!hasPointValue(value)) {
    return [0];
  }

  return cleanValue(value);
});

// procedureToPoints :: (key, key) -> Procedure -> Point[]
export const procedureToPoints = (xKey: string, yKey: string) =>
  pipe(procedureToSlides, fmap(slideTo2DPoint(xKey, yKey)), compact, uniq);

// cohortToPoints :: (key, key) -> Cohort -> Point[]
export const cohortToPoints = (xKey: string, yKey: string) =>
  pipe(cohortToProcedures, fmap(procedureToPoints(xKey, yKey)), flatten);

const colorHash = new ColorHash({ saturation: 1, lightness: 0.5 });

export const hashIdToColor = (key: string) => colorHash.hex(key || '0');

export const formatNumber = (value: number) => {
  if (value < 1e-3 || value > 1e3) {
    return numeral(value).format('0.00e+0');
  } else {
    if (isInteger(value)) {
      return numeral(value).format('0');
    }
    return numeral(value).format('0.000');
  }
};

export const fillAndBorderColor = (cohortId: string) => {
  const borderColor = hashIdToColor(cohortId);
  const backgroundColor = colorLib(borderColor).alpha(0.5).rgbString();

  return {
    borderColor,
    backgroundColor,
  };
};

export const cohortToClinicalData = (cohort: PartialCohort): ClinicalData[] => {
  const procedures = cohortToProcedures(cohort);

  return map(procedures, 'clinicalData');
};

export const slideToFeatureValue = curry((featureKey: string, slide: Slide) => {
  const [num] = slideTo1DPoint(featureKey, slide);
  return num;
});

export const caseToFeatureValue = curry((featureKey: string, procedure: Procedure) => {
  const slide = firstSlideWithResults(procedure);
  return slideToFeatureValue(featureKey, slide);
});

export const cohortToDataset = curry(
  (
    type: string,
    dataGenerator: (cohort: PartialCohort) => ChartDataset['data'],
    cohort: PartialCohort,
    index: number
  ): Dataset => {
    return {
      type,
      label: cohort.name,
      data: dataGenerator(cohort),
      backgroundColor: colorPalette[index % colorPalette.length],
      borderColor: borderColorPalette[index % borderColorPalette.length],
    };
  }
);

export const cohortsToDatasets = curry(
  (type: string, dataGenerator: (cohort: PartialCohort) => ChartDataset['data'], cohorts: PartialCohort[]) => {
    return map(cohorts, (cohort, index) => cohortToDataset(type, dataGenerator, cohort, index));
  }
);

export const slideCategoricalKeys: (keyof Slide)[] = [
  'biopsyType',
  'stainingType',
  'biopsySiteId',
  'qcFailed',
  'negativeControl',
  'positiveControl',
];
export const clinicalCategoricalKeys: (keyof ClinicalData)[] = [
  'overallSurvivalEvent',
  'sex',
  'smokingStatus',
  'ecogScore',
  // 'yearOfBirth',
];
export const procedureCategoricalKeys: (keyof Procedure)[] = ['cancerTypeId', 'cancerSubtypes'];

export const allCategoricalKeys: string[] = [
  ...slideCategoricalKeys,
  ...clinicalCategoricalKeys,
  ...procedureCategoricalKeys,
];

export function isSlideCategoricalKey(key: string): key is keyof Slide {
  return slideCategoricalKeys.includes(key as keyof Slide);
}

export function isSlideCategoricalChartKey(key: ChartKey): key is CategoricalChartKey & { name: keyof Slide } {
  return isSlideCategoricalKey(key?.name);
}

export function isClinicalCategoricalKey(key: string): key is keyof ClinicalData {
  return clinicalCategoricalKeys.includes(key as keyof ClinicalData);
}

export function isClinicalCategoricalChartKey(
  key: ChartKey
): key is CategoricalChartKey & { name: keyof ClinicalData } {
  return isClinicalCategoricalKey(key?.name);
}

export function isProcedureCategoricalKey(key: string): key is keyof Procedure {
  return procedureCategoricalKeys.includes(key as keyof Procedure);
}

export function isProcedureCategoricalChartKey(key: ChartKey): key is CategoricalChartKey & { name: keyof Procedure } {
  return isProcedureCategoricalKey(key?.name);
}

export function isValidCategoricalKey(key: string): key is keyof Slide | keyof ClinicalData | keyof Procedure {
  return allCategoricalKeys.includes(key as keyof Slide | keyof ClinicalData | keyof Procedure);
}

export const getGroupByCount = (
  groupByKey: CategoricalChartKey,
  procedures: Procedure[],
  countBy: CountBy = 'Cases'
): Record<string, number> => {
  if (!procedures) {
    return {};
  }

  const groupingIteratee = getGroupingIteratee(groupByKey);

  if (countBy === 'Cases') {
    return lodashCountBy(procedures, groupingIteratee);
  } else if (countBy === 'Slides') {
    const slideMap = getSlideMapFromProcedures(groupByKey, procedures);
    const slidesCountsMap = mapValues(slideMap, (value) => value.length);
    return slidesCountsMap;
  }
};

export const slidesToFeatureValues = (key: string, slides: Slide[]): number[] => {
  return compact(map(slides, slideToFeatureValue(key)));
};

export const casesToFeatureValues = (key: string, cases: Procedure[]): number[] => {
  return compact(map(cases, caseToFeatureValue(key)));
};

type ChartWithGroupByCount = {
  groupByCount: Record<string, number>;
  originalChartOptions: ControlledChartOptions;
};

export const orderChartsByVariety = (
  charts: Record<number, ControlledChartOptions>,
  procedures: Procedure[]
): Record<number, ControlledChartOptions> => {
  const groupByCounts: Record<number, ChartWithGroupByCount> = mapValues(charts, (chartOptions) => {
    return {
      groupByCount: getGroupByCount(
        (chartOptions.categoricalKey as CategoricalChartKey) || (chartOptions.horizontalKey as CategoricalChartKey),
        procedures
      ),
      originalChartOptions: chartOptions,
    };
  });

  const sortedGroupByCounts: Record<number, ChartWithGroupByCount> = sortBy(
    values(groupByCounts),
    (value) => -keys(value.groupByCount).length
  );

  return mapValues(sortedGroupByCounts, (value) => value.originalChartOptions);
};

export const filterEmptyCharts = (
  charts: Record<number, ControlledChartOptions>,
  procedures: Procedure[]
): Record<number, ControlledChartOptions> => {
  const groupByCounts: Record<number, ChartWithGroupByCount> = mapValues(charts, (chartOptions) => {
    return {
      groupByCount: getGroupByCount(
        (chartOptions.categoricalKey as CategoricalChartKey) || (chartOptions.horizontalKey as CategoricalChartKey),
        procedures
      ),
      originalChartOptions: chartOptions,
    };
  });

  const filteredGroupByCounts: Record<number, ChartWithGroupByCount> = filter(groupByCounts, (chart) => {
    return !every(keys(chart.groupByCount), (label) => ['null', 'undefined', null, undefined].includes(label));
  });

  return mapValues(filteredGroupByCounts, (value) => value.originalChartOptions);
};

export const getScaleOptions = curry(
  (
    position: 'left' | 'right' | 'bottom' | 'top' | 'center',
    name: string
  ): {
    type: 'linear';
    position: 'left' | 'right' | 'bottom' | 'top' | 'center';
    title: {
      display: boolean;
      text: string;
      align: 'center';
      font: {
        family: 'Ubuntu';
      };
    };
  } => ({
    type: 'linear',
    position,
    title: {
      display: Boolean(name),
      text: name,
      align: 'center',
      font: {
        family: 'Ubuntu',
      },
    },
  })
);

export const getLegendOptions = (position: 'top' | 'bottom' | 'left' | 'right') => ({
  position,
  labels: {
    font: {
      size: 12,
    },
    boxWidth: 10,
  },
});

export const getDataLabelsOptions = (display: boolean) => ({
  display: display,
  labels: {
    title: {
      font: {
        weight: 'bold',
      } as Font,
    },
  },
  formatter: (value: number, context: Context) => {
    let sum: number = 0;
    let dataArr = context.chart.data.datasets[context.datasetIndex].data;
    dataArr.map((data: number) => {
      sum = sum + data;
    });
    let percentage = ((value * 100) / sum).toFixed(0) + '%';
    if (value === 0) {
      percentage = '';
    }
    return percentage;
  },
});

export const getHorizontalScaleOptions = getScaleOptions('bottom');
export const getVerticalScaleOptions = getScaleOptions('left');

export const extent = (numberValues: number[]): [number, number] => [min(numberValues), max(numberValues)];

export const highlightedFeaturesToCharts = (
  highlightedFeatures: string[],
  inferredFeaturesConfig?: InferredFeatureConfig[]
) => {
  return map(highlightedFeatures, (feature) => {
    if (some(inferredFeaturesConfig, { featureName: feature })) {
      return {
        type: ChartType.Pie,
        categoricalKey: { name: feature, type: ChartKeyType.Categorical },
      } as const;
    }
    if (isDistanceBasedFeatureKey(feature)) {
      return {
        type: ChartType.DistanceBased,
        horizontalKey: { name: feature, type: ChartKeyType.DistanceBased },
      } as const;
    }
    return {
      type: ChartType.Histogram,
      horizontalKey: { name: feature, type: ChartKeyType.Numerical },
    } as const;
  });
};
