import ErrorIcon from '@mui/icons-material/Error';
import { Box } from '@mui/system';
import {
  DataGrid,
  DataGridProps,
  GridEventListener,
  GridFilterModel,
  GridOverlay,
  GridPaginationModel,
  GridRowParams,
  MuiEvent,
} from '@mui/x-data-grid';
import { useCommonDataGridStyles } from 'components/atoms/BaseDataGrid/commonStyles';
import React, { useMemo } from 'react';
import { useDebounce } from 'use-debounce';
import { DataGridPagination } from '../DataGridPagination';
import { GridActionToolbar } from './GridActionToolbar';

const pageSizeOptions = [10, 25, 50, 100];

const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
  event.defaultMuiPrevented = true;
};

const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
  event.defaultMuiPrevented = true;
};

interface SettingsDataGridProps extends DataGridProps {
  handleAdd: () => void;
  addText: string;
  height?: CSSStyleDeclaration['height'];
  error?: any;
}

const NoRowsErrorOverlay: React.FC<{ error: any }> = ({ error }) => {
  return (
    <GridOverlay
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}
    >
      <ErrorIcon fontSize="large" color="error" />
      <Box sx={{ mt: 2 }}>{error?.message || error || 'Error'}</Box>
    </GridOverlay>
  );
};

export const SettingsDataGrid: React.FC<React.PropsWithChildren<SettingsDataGridProps>> = ({
  handleAdd,
  error,
  rows,
  addText,
  height = '80vh',
  ...props
}) => {
  const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });

  const [localSearch, setLocalSearch] = React.useState<string>('');

  const commonDataGridStyles = useCommonDataGridStyles();

  const sx = React.useMemo(
    () => ({ ...commonDataGridStyles, ...((props.sx || {}) as any) }),
    [commonDataGridStyles, props.sx]
  );

  const slots = React.useMemo(
    () => ({
      pagination: DataGridPagination,
      toolbar: GridActionToolbar,
      ...(error ? { noRowsOverlay: () => <NoRowsErrorOverlay error={error} /> } : {}),
      ...props?.slots,
    }),
    [props?.slots, error]
  );

  const slotProps: SettingsDataGridProps['slotProps'] = useMemo(
    () => ({
      ...(props.slotProps || {}),
      toolbar: {
        addText,
        handleAdd,
        localSearch,
        setLocalSearch,
        ...(props.slotProps?.toolbar || {}),
      },
      pagination: {
        SelectProps: {
          sx: { width: 'auto' },
        },
        ...(props.slotProps?.pagination || {}),
      },
    }),
    [addText, handleAdd, localSearch, setLocalSearch, props.slotProps]
  );

  const [debouncedLocalSearch, { isPending }] = useDebounce(localSearch, 100);

  const [columnFilter, setColumnFilters] = React.useState<GridFilterModel>({
    items: [],
  });
  const filterModel = React.useMemo(
    () => ({
      items: [],
      ...columnFilter,
      quickFilterValues: [debouncedLocalSearch],
    }),
    [debouncedLocalSearch, columnFilter]
  );

  return (
    <Box height={height}>
      <DataGrid
        pagination
        editMode="row"
        paginationMode="client"
        pageSizeOptions={pageSizeOptions}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        filterModel={filterModel}
        onFilterModelChange={setColumnFilters}
        loading={isPending()}
        getRowClassName={({ row }) => (row?.deletedAt ? 'deleted-row' : '')}
        {...props}
        sx={sx}
        slots={slots}
        slotProps={slotProps}
        rows={!error ? rows : []}
      />
    </Box>
  );
};
