import { Button, List, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { defaultRangeCondition } from 'components/atoms/ConditionBuilder/AutomaticConditionGroup';
import { InferredFeatureConfig, InferredFeatureRule } from 'interfaces/inferredFeatures';
import { Study } from 'interfaces/study';
import { map, pullAt, reject } from 'lodash';
import React, { FunctionComponent } from 'react';
import { DragDropContext, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { uuidv4 } from 'utils/helpers';
import InferredFeatureRuleSettings from './InferredFeatureRuleSettings';

interface InferredFeatureRulesSettingsProps {
  study: Study;
  updateConfig: (newConfig: InferredFeatureConfig) => void;
  config: InferredFeatureConfig;
  orchestrationId: string;
}

const InferredFeatureRulesSettings: FunctionComponent<React.PropsWithChildren<InferredFeatureRulesSettingsProps>> = ({
  study,
  updateConfig,
  config,
  orchestrationId,
}) => {
  const onChange = (newRule: InferredFeatureRule) => {
    updateConfig({
      ...config,
      rules: map(config.rules, (rule) => (rule.id === newRule.id ? newRule : rule)),
    });
  };

  const onDragEnd: OnDragEndResponder = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.index === destination.index) {
      return;
    }

    const newRules = [...config.rules];
    const [removed] = pullAt(newRules, [source.index]);
    newRules.splice(destination.index, 0, removed);

    updateConfig({
      ...config,
      rules: newRules,
    });
  };

  return (
    <Box
      sx={{
        width: '100%',
        m: 1,
        bgcolor: 'background.paper',
      }}
    >
      <Typography variant="h3">Rules</Typography>
      <Typography variant="subtitle2">
        Inferred features are automatically generated based on the values of other features. The first rule that matches
        will be used.
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <List {...provided.droppableProps} ref={provided.innerRef}>
              {map(config?.rules, (rule, index) => (
                <InferredFeatureRuleSettings
                  key={rule.id}
                  index={index}
                  rule={rule}
                  onChange={onChange}
                  study={study}
                  orchestrationId={orchestrationId}
                  onRemove={() =>
                    updateConfig({
                      ...config,
                      rules: reject(config.rules, { id: rule.id }),
                    })
                  }
                ></InferredFeatureRuleSettings>
              ))}
              {provided.placeholder}
              {!config?.rules?.length && (
                <Typography variant="subtitle2">
                  No rules found, add a new one by clicking the button below. The first rule that matches will be used.
                </Typography>
              )}
              <TextField
                label="Default Value"
                value={config?.defaultValue?.name}
                onChange={(e) =>
                  updateConfig({ ...config, defaultValue: { ...config.defaultValue, name: e.target.value } })
                }
                helperText="If no rules match, the default value will be used."
              />
            </List>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        onClick={() => {
          updateConfig({
            ...config,
            rules: [...config.rules, { id: uuidv4().slice(0, 8), name: '', condition: defaultRangeCondition }],
          });
        }}
      >
        Add Rule
      </Button>
    </Box>
  );
};

export default InferredFeatureRulesSettings;
