import { Grid } from '@mui/material';
import { castArray, cloneDeep, find, findIndex, includes, join, map, omit } from 'lodash';
import React, { useCallback } from 'react';

import { useSlideLabels } from 'components/StudiesDashboard/StudySettings/StudyLabel/useSlideLabels';
import { CaseQueryFeature, RangeValue } from 'interfaces/caseQuery';
import { isQueryFilter, QueryFilter } from 'interfaces/cohort';
import { DataCategory } from 'interfaces/genericFields';
import { formatFieldValue } from 'interfaces/genericFields/formatFieldValues';
import { Procedure } from 'interfaces/procedure';
import { getOrderedFields } from 'interfaces/procedure/fields';
import { ProceduresFieldsContext, useProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { slideTagsField } from 'interfaces/procedure/fields/qcLabelsFields';
import { QualityControlLabel, QualityControlLabelParam } from 'interfaces/qualityControlLabels';
import { Slide } from 'interfaces/slide';
import { useGetNameOverrideOrDisplayNameWithContext } from 'utils/features/contextHooks';
import { formatRangeObjectToText, humanize } from 'utils/helpers';
import { getLabelValue } from 'utils/qcLabels';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import FilterChip from './FilterChip';
import useControlledFields from './hooks/useControlledFields';

type Filters = Record<string, QueryFilter | string | string[] | number | number[] | boolean | boolean[]>;

const filterKeyToDataKey: Record<string, keyof Procedure | keyof Slide> = {
  cancerTypes: 'cancerTypeId',
  stains: 'stainingType',
};

const caseFields = [...getOrderedFields(), slideTagsField];

const qcFilterKeys = ['slideTags', 'qcLabels', 'excludedSlides'];

interface Props {
  allFilters?: Filters;
  allFeatures?: CaseQueryFeature[];
  qcLabelConfig: QualityControlLabel[];
}
const dataCategoryToFilterCategoryKeys: Record<DataCategory | 'filters', string> = {
  clinical: 'clinicalData',
  metadata: 'filters',
  features: 'features',
  filters: 'filters',
};

const FilterChips: React.FC<{
  filters: Filters;
  qcLabelConfig: QualityControlLabel[];
  advancedInputs: Record<string, any>;
  onMainFilterChange: (filterKey: string, value: any) => void;
  onFieldChange: (
    filterType: 'range' | 'date-range' | 'multiSelect' | 'checkbox-group' | 'select',
    key: string,
    value:
      | string
      | string[]
      | {
          start: string;
          end: string;
        },
    category: DataCategory
  ) => void;
  onDeleteChip: (key: string, category: DataCategory) => void;
  onSubmit: () => void;
}> = ({ filters, qcLabelConfig, advancedInputs, onMainFilterChange, onFieldChange, onDeleteChip, onSubmit }) => {
  const { getLabelDisplayName } = useSlideLabels();

  const caseFieldsContext = useProceduresFieldsContext({
    enabled: true,
  });

  return (
    <>
      {map(filters, (filter, key) => {
        const filterField = find(caseFields, { dataKey: filterKeyToDataKey[key] ?? key });

        const isQcFilter = includes(qcFilterKeys, key);

        const fieldName = filterField?.label || humanize(key);

        const rawValue = isQueryFilter(filter) && filter?.value ? filter.value : filter;

        const fieldValue =
          key === 'qcLabels'
            ? map(filter as unknown as QualityControlLabelParam[], (filterValue: QualityControlLabelParam) => {
                const labelValue = getLabelValue(filterValue, qcLabelConfig);
                return getLabelDisplayName(labelValue);
              })
            : isQueryFilter(filter) &&
              ((filter?.category && filter?.filterType === 'range') || filter?.filterType === 'date-range')
            ? formatRangeObjectToText(filter.value)
            : filterField
            ? join(
                map(castArray(rawValue), (value) =>
                  formatFieldValue<Procedure, any, ProceduresFieldsContext>(
                    value,
                    filterField,
                    caseFieldsContext,
                    'slides'
                  )
                ),
                ', '
              )
            : rawValue;

        const chipLabel = `${fieldName}: ${fieldValue ?? rawValue}`;
        const category = isQueryFilter(filter) ? filter?.category || 'filters' : 'filters';
        return (
          <Grid item key={chipLabel}>
            <FilterChip
              onDelete={() => onDeleteChip(key, category as DataCategory)}
              label={chipLabel}
              category={category}
              field={filterField}
              filterValue={isQueryFilter(filter) ? advancedInputs[key]?.value : advancedInputs[key]}
              onFieldChange={onFieldChange}
              onMainFilterChange={onMainFilterChange}
              submitChange={onSubmit}
              isMainFilter={!isQueryFilter(filter) && !isQcFilter}
              filterKey={key}
              isQcFilter={isQcFilter}
            />
          </Grid>
        );
      })}
    </>
  );
};

const FeatureChips: React.FC<{
  features: CaseQueryFeature[];
  onFeatureFilterChange: (
    index: number,
    value: RangeValue,
    filterType: 'select' | 'range',
    selectedFeatureKey: string
  ) => void;
  featuresFilters: any[];
  onDeleteChip: (key: string, category: DataCategory) => void;
  onSubmit: () => void;
}> = ({ features, featuresFilters, onDeleteChip, onSubmit, onFeatureFilterChange }) => {
  const { getNameOverrideOrDisplayNameWithContext } = useGetNameOverrideOrDisplayNameWithContext(true);

  return (
    <>
      {map(features, (feature) => {
        const chipLabel = `${getNameOverrideOrDisplayNameWithContext(feature.featureKey)}: ${formatRangeObjectToText(
          feature.value
        )}`;

        const featureIndex = findIndex(featuresFilters, { featureKey: feature.featureKey });

        return (
          <Grid item key={feature.featureKey}>
            <FilterChip
              onDelete={() => onDeleteChip(feature.featureKey, 'features')}
              label={chipLabel}
              field={feature}
              isFeatureField
              onFeatureFilterChange={(_, value) =>
                onFeatureFilterChange(featureIndex, value as RangeValue, 'range', feature.featureKey)
              }
              featureValue={featuresFilters && featuresFilters[featureIndex]?.value}
              submitChange={onSubmit}
            />
          </Grid>
        );
      })}
    </>
  );
};

const FilterChipList: React.FC<React.PropsWithChildren<Props>> = ({ allFilters, allFeatures, qcLabelConfig }) => {
  const { queryParams, setQueryParams } = useEncodedFilters();
  const { labId } = useCurrentLabId();

  const {
    onMainFilterChange,
    onFieldChange,
    advancedInputs,
    featuresFilters,
    onFeatureFilterChange,
    getFilterObjects,
  } = useControlledFields();

  const onSubmit = useCallback(() => {
    const { filters, clinicalData, features } = getFilterObjects();
    setQueryParams({
      ...queryParams,
      filters,
      clinicalData,
      features,
      labId,
    });
  }, [getFilterObjects, labId, queryParams, setQueryParams]);

  const onDeleteChip = useCallback(
    (key: string, category: DataCategory) => {
      const categoryKey = dataCategoryToFilterCategoryKeys[category];

      const queryParamsCopy = cloneDeep(queryParams);
      const newParams = {
        ...queryParamsCopy,
        [categoryKey]: omit(queryParamsCopy[categoryKey as keyof typeof queryParamsCopy], [key]),
      };

      setQueryParams(newParams);
    },
    [queryParams, setQueryParams]
  );

  return (
    <Grid container item spacing={2}>
      <FilterChips
        filters={allFilters}
        onDeleteChip={onDeleteChip}
        qcLabelConfig={qcLabelConfig}
        advancedInputs={advancedInputs}
        onMainFilterChange={onMainFilterChange}
        onFieldChange={onFieldChange}
        onSubmit={onSubmit}
      />
      <FeatureChips
        features={allFeatures}
        featuresFilters={featuresFilters}
        onFeatureFilterChange={onFeatureFilterChange}
        onDeleteChip={onDeleteChip}
        onSubmit={onSubmit}
      />
    </Grid>
  );
};

export default FilterChipList;
