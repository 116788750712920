import { CellRuleStudyId } from 'interfaces/cellRule';
import React from 'react';
import useCellRulePanels from 'utils/queryHooks/cellRule/useCellRulePanels';

import { List, Typography } from '@mui/material';
import Loader from 'components/Loader';
import { isEmpty, map } from 'lodash';
import AddCellPanelDialog from './CellRulePanel/AddCellPanelDialog';
import { CellRulesPanelAccordion } from './CellRulesPanel';

interface CellRulesPanelsContainerProps {
  studyId: CellRuleStudyId;
}

const CellRulePanelsContainer: React.FC<CellRulesPanelsContainerProps> = ({ studyId }) => {
  const { data: panels, isLoading: isLoadingPanels } = useCellRulePanels(studyId);

  return (
    <>
      <List
        sx={{
          '& .MuiListItem-root': {
            px: 0,
          },
        }}
      >
        {isLoadingPanels ? (
          <Loader />
        ) : isEmpty(panels) ? (
          <Typography>No panels found, create a new one?</Typography>
        ) : (
          map(panels, (panel) => <CellRulesPanelAccordion key={panel.id} panel={panel} studyId={studyId} />)
        )}
      </List>

      <AddCellPanelDialog studyId={studyId} />
    </>
  );
};

export default CellRulePanelsContainer;
