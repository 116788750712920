import React from 'react';

import { RowsChangesSummary } from 'components/atoms/EditableDataGrid/TableEditingContext/ChangesSummary';
import { AreaType } from 'interfaces/areaType';
import { areaTypeFields } from 'interfaces/areaType/areaTypeFields';
import { find } from 'lodash';

export const AreaTypeRowChangesSummary: React.FC<
  React.PropsWithChildren<{
    areaTypeDisplayString?: string;
    changes: Partial<AreaType>;
  }>
> = ({ changes, areaTypeDisplayString }) => {
  return (
    <RowsChangesSummary
      rowsChanges={{ changes }}
      rowToDisplayString={() => areaTypeDisplayString || `Area type "${changes?.displayName}"`}
      fieldToDisplayString={(fieldId) => find(areaTypeFields, (f) => f.dataKey === fieldId)?.label || fieldId}
      valueToDisplayString={(value, fieldId) => {
        const field = find(areaTypeFields, (f) => f.dataKey === fieldId);
        return field?.valueFormatter?.({ value }) ?? `${value}`;
      }}
    />
  );
};
