import { logout } from 'redux/actions/auth';
import { endNetwork, startNetwork } from 'redux/actions/network';
import * as actionTypes from 'redux/actionTypes/index';
import apiUtils from 'utils/api';
// eslint-disable-next-line import/no-cycle
import store from '../store';

const apiMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== actionTypes.API_REQUEST) {
      return next(action);
    }

    const { payload } = action;
    const {
      url,
      onSuccess,
      onFailure,
      label,
      data,
      method = 'GET',
      accessToken = store.getState().auth.accessToken,
    } = payload || {};

    dispatch(startNetwork(label));

    apiUtils
      .request({
        method,
        url,
        data,
        accessToken,
      })
      .then(({ body }) => {
        dispatch(endNetwork(label));

        if (onSuccess) {
          onSuccess(body, dispatch);
        }
      })
      .catch((error) => {
        if (error.status === 500 || error.status === undefined) {
          console.error('API error', error, action);
        }
        if (error.status === 401) {
          console.warn('user unauthorized');
          dispatch(logout());
        } else if (onFailure) {
          onFailure(error, dispatch);
        }

        dispatch(endNetwork(label));
      });

    return next(action);
  };

export default apiMiddleware;
