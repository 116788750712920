const NETWORK_LABELS = {
  FETCH_PROCEDURES: 'FETCH_PROCEDURES',
  FETCH_PROCEDURES_IDS: 'FETCH_PROCEDURES_IDS',
  FETCH_LABS: 'FETCH_LABS',
  FETCH_PROCEDURE: 'FETCH_PROCEDURE',
  FETCH_STUDY: 'FETCH_STUDY',
  POST_STUDY: 'POST_STUDY',
  SAVE_ANALYSIS_DATA: 'SAVE_ANALYSIS_DATA',
  SIGNOUT_PROCEDURE: 'SIGNOUT_PROCEDURE',
  APPROVE_PROCEDURE: 'APPROVE_PROCEDURE',
  UNAPPROVE_PROCEDURE: 'UNAPPROVE_PROCEDURE',
  UPDATE_SLIDE_RESULTS: 'UPDATE_SLIDE_RESULTS',
  FETCH_PERFORMANCE_METRICS: 'FETCH_PERFORMANCE_METRICS',
  FETCH_STAINS: 'FETCH_STAINS',
};

export default NETWORK_LABELS;
