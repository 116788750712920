import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Tooltip, Typography } from '@mui/material';
import { compact, filter, first, includes, isEmpty, map, some, uniq } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useClipboard } from 'use-clipboard-copy';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { useSignals } from '@preact/signals-react/runtime';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { ExperimentResultsResultType, FlowClassName } from 'interfaces/experimentResults';
import { Permission } from 'interfaces/permissionOption';
import { usePermissions } from 'utils/usePermissions';
import { slidesLayerVisualizationSettings } from '../../slidesVisualizationAndConfiguration';
import {
  ActiveSecondaryAnalysisResultsSelect,
  flattenSecondaryResults,
  useActiveResultForPrimaryOrchestrationId,
} from './ActiveSecondaryAnalysisResultsSelect';
import Features from './Features/Features';
import Heatmaps from './Heatmaps';
import OrchestrationApproval from './OrchestrationApproval';
import OrchestrationArchival from './OrchestrationArchival';
import SecondaryAnalysisTrigger from './SecondaryAnalysisTrigger';

interface SlideOrchIdProps {
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  title: string;
  orchestrationId: string;
  heatmapSettings: FeatureMetadata[];
  featuresSettings: FeatureMetadata[];
  filterText: string;
  doesHaveApprovedResultType: boolean;
  flowClassNames: FlowClassName[];
  resultTypes: ExperimentResultsResultType[];
  highlightedFeatures?: string[];
  hiddenFeatures?: string[];
  studyId: string;
  doesCaseHaveMultipleStainResults: boolean;
  slideStainTypeId: string;
}

const SlideOrchIdResults: React.FunctionComponent<React.PropsWithChildren<SlideOrchIdProps>> = ({
  slideId,
  viewerIndex,
  stainTypeId,
  orchestrationId: primaryRunOrchestrationId,
  heatmapSettings: allHeatmapSettings,
  featuresSettings: allFeaturesSettings,
  filterText,
  doesHaveApprovedResultType,
  flowClassNames,
  resultTypes,
  highlightedFeatures,
  hiddenFeatures,
  studyId,
  doesCaseHaveMultipleStainResults,
  slideStainTypeId,
}) => {
  useSignals();
  const { hasPermission } = usePermissions();
  const canHideSecondaryResults = hasPermission(
    Permission.CanArchiveViewRestoreOwnResults || Permission.CanViewRestoreResultsArchivedByAny
  );

  const [isPublishMode] = useQueryParam(`isPublishModeViewer${viewerIndex}`, BooleanParam);
  const [expand, setExpand] = useState(false);

  // some cases has features and no heatmaps and some cases has the opposite
  const allResults = [...(allHeatmapSettings || []), ...(allFeaturesSettings || [])];

  const { orchestrationId, createdAt, isInternallyApproved, isSecondaryAnalysis, primaryRunResults, deletedAt } =
    useActiveResultForPrimaryOrchestrationId({
      allResults,
      primaryRunOrchestrationId,
      slideId,
      viewerIndex,
    });

  const relevantHeatmaps = useMemo(() => {
    const heatmapsAndSecondaryHeatmaps = flattenSecondaryResults(allHeatmapSettings);
    return filter(heatmapsAndSecondaryHeatmaps, { orchestrationId });
  }, [allHeatmapSettings, orchestrationId]);

  // On first render, set the default expanded state based on selected heatmaps
  useEffect(() => {
    if (expand) {
      return;
    }
    const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
    const slideLayerVisualizationSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId];

    const hasCurrentlySelectedHeatmaps = some(
      relevantHeatmaps,
      ({ id }) => slideLayerVisualizationSettings?.[id]?.value?.selected
    );
    setExpand(hasCurrentlySelectedHeatmaps);
  }, [relevantHeatmaps, viewerIndex, slideId]);

  const secondaryAnalysisMissingPrimaryIds = uniq(compact(map(allResults, 'primaryRunOrchestrationId')));
  const clipboard = useClipboard();
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard: React.MouseEventHandler<HTMLDivElement> = (e: any) => {
    e.stopPropagation();
    clipboard.copy(orchestrationId);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const allFeatureSettingsForOrchestrationId = filter(flattenSecondaryResults(allFeaturesSettings), {
    orchestrationId,
  });
  if (allFeatureSettingsForOrchestrationId.length > 1) {
    console.warn(`Slide ${slideId}, orchestrationId ${orchestrationId} has multiple different feature results`, {
      allFeatureSettingsForOrchestrationId,
      slideId,
      orchestrationId,
      viewerIndex,
    });
  }
  const featureSettingsForOrchestrationId = first(allFeatureSettingsForOrchestrationId);
  const showFeatures = Boolean(featureSettingsForOrchestrationId);

  const showHeatmaps = !isEmpty(relevantHeatmaps);
  const hasContentsToShow = showFeatures || showHeatmaps;

  return (
    hasContentsToShow && (
      <Accordion expanded={expand} onChange={() => setExpand((prev) => !prev)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid
              item
              xs={isSecondaryAnalysis ? 12 : isPublishMode ? 6 : isInternallyApproved ? 10 : 12}
              container
              direction="column"
            >
              <Grid item container alignItems="end">
                <Typography variant={'subtitle2'}>{`Orch Id:`}</Typography>{' '}
                <Tooltip title={isCopied ? 'copied!' : 'click to copy'} onClick={handleCopyToClipboard}>
                  <Typography variant="subtitle2" ml={1}>
                    {`${orchestrationId?.slice(0, 8)}...`}
                  </Typography>
                </Tooltip>
              </Grid>
              {isSecondaryAnalysis ? (
                <Grid item container xs={12} direction="row">
                  <Grid item xs={canHideSecondaryResults ? 11 : 12}>
                    <ActiveSecondaryAnalysisResultsSelect
                      internalFeatures
                      primaryRunResults={primaryRunResults}
                      slideId={slideId}
                      viewerIndex={viewerIndex}
                      stainTypeId={slideStainTypeId}
                    />
                  </Grid>
                  {canHideSecondaryResults && (
                    <Grid item xs={1}>
                      <OrchestrationArchival
                        orchestrationId={orchestrationId}
                        studyId={studyId}
                        isDeleted={Boolean(deletedAt)}
                      />
                    </Grid>
                  )}
                </Grid>
              ) : (
                createdAt && (
                  <Grid item>
                    <Typography variant="caption">{moment(createdAt).format('ll')}</Typography>
                  </Grid>
                )
              )}
            </Grid>
            {!isPublishMode && isInternallyApproved && (
              <Grid item xs={2} justifyContent="flex-end">
                <CheckCircleRoundedIcon color={'primary'} />
              </Grid>
            )}

            {!isEmpty(secondaryAnalysisMissingPrimaryIds) && (
              <Grid item xs={2} justifyContent="flex-end">
                <Tooltip title={`Secondary analysis without their primary run: ${secondaryAnalysisMissingPrimaryIds}`}>
                  <WarningIcon color={'warning'} />
                </Tooltip>
              </Grid>
            )}

            <OrchestrationApproval
              slideId={slideId}
              viewerIndex={viewerIndex}
              orchestrationId={orchestrationId}
              primaryRunOrchestrationId={primaryRunOrchestrationId}
              isInternallyApproved={isInternallyApproved}
              studyId={studyId}
              doesSlideHaveApprovedResults={doesHaveApprovedResultType}
              flowClassNames={flowClassNames}
              resultTypes={resultTypes}
            />
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 1 }}>
          {includes(flowClassNames, FlowClassName.CalculateFeatures) && (
            <SecondaryAnalysisTrigger
              slideId={slideId}
              viewerIndex={viewerIndex}
              autoInternallyApprove={featureSettingsForOrchestrationId?.internallyApproved}
              orchestrationId={orchestrationId}
              studyId={studyId}
            />
          )}
          {showHeatmaps && (
            <Heatmaps
              internalHeatmaps
              key={`heatmaps-${orchestrationId}`}
              slideId={slideId}
              studyId={studyId}
              stainTypeId={stainTypeId}
              viewerIndex={viewerIndex}
              title="Overlays"
              heatmaps={relevantHeatmaps}
              filterText={filterText}
            />
          )}
          {showFeatures && (
            <Features
              highlightedFeatures={highlightedFeatures}
              hiddenFeatures={hiddenFeatures}
              title={featureSettingsForOrchestrationId.displayName}
              experimentResultIds={
                featureSettingsForOrchestrationId?.experimentResultId !== undefined
                  ? [featureSettingsForOrchestrationId.experimentResultId]
                  : []
              }
              internalFeatures={true}
              doesCaseHaveMultipleStainResults={doesCaseHaveMultipleStainResults}
              slideStainTypeId={slideStainTypeId}
            />
          )}
        </AccordionDetails>
      </Accordion>
    )
  );
};

export default SlideOrchIdResults;
