import RemoveIcon from '@mui/icons-material/Remove';
import { FormControl, Grid, IconButton, MenuItem, Paper, Select } from '@mui/material';
import { AutomaticConditionLogical, ConditionOperator, ConditionType } from 'interfaces/automaticCondition';
import { filter, isNumber, map } from 'lodash';
import React from 'react';
import { AutomaticConditionBuilderProps } from './AutomaticConditionBuilder';
import AutomaticConditionGroup from './AutomaticConditionGroup';

type LogicConditionBuilderProps = AutomaticConditionBuilderProps<AutomaticConditionLogical>;

const LogicConditionBuilder: React.FC<React.PropsWithChildren<LogicConditionBuilderProps>> = ({
  condition: logicalCondition,
  onConditionChange,
  onRemove,
  study,
  orchestrationId,
  editable,
  shouldShowErrors,
  featuresOnly,
  conditionOptions,
  mappingFiltersMetadata,
  conditionOperators = [ConditionOperator.AND, ConditionOperator.OR],
  logicalConditionRecursivelyNumLevels = null,
  categoricalActions,
  OrchestrationDataTypes,
}) => {
  const handleOperatorChange = (value: ConditionOperator) => {
    onConditionChange({ ...logicalCondition, operator: value });
  };

  const { operator, operands } = logicalCondition;

  return (
    <Paper elevation={3} sx={{ p: 1 }}>
      <Grid container direction="column">
        <Grid item container alignItems="center">
          <Grid item>
            <IconButton disabled={!editable} onClick={() => onRemove()}>
              <RemoveIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Select
                size="small"
                disabled={!editable}
                value={operator}
                onChange={(e) => handleOperatorChange(e.target.value as ConditionOperator)}
              >
                {map(conditionOperators, (conditionOperator) => {
                  return <MenuItem value={conditionOperator}>{conditionOperator}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <AutomaticConditionGroup
          editable={editable}
          onOperandsChange={(newOperands) => onConditionChange({ ...logicalCondition, operands: newOperands })}
          operands={operands}
          study={study}
          orchestrationId={orchestrationId}
          shouldShowErrors={shouldShowErrors}
          featuresOnly={featuresOnly}
          singleOperand={false}
          mappingFiltersMetadata={mappingFiltersMetadata}
          conditionOperators={conditionOperators}
          logicalConditionRecursivelyNumLevels={
            isNumber(logicalConditionRecursivelyNumLevels) ? logicalConditionRecursivelyNumLevels - 1 : null
          }
          conditionOptions={
            logicalConditionRecursivelyNumLevels === null || logicalConditionRecursivelyNumLevels > 0
              ? conditionOptions
              : filter(conditionOptions, (conditionOption) => conditionOption !== ConditionType.LOGICAL)
          }
          categoricalActions={categoricalActions}
          OrchestrationDataTypes={OrchestrationDataTypes}
        />
      </Grid>
    </Paper>
  );
};

export default LogicConditionBuilder;
