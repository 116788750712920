import { Feature, Position } from '@turf/helpers';
import { isArray, map } from 'lodash';

/**
 * GeoJSON sees a polygon as an array of arrays of coordinates, while in the db we also use a normal array of coordinates
 * this function allows us to support both in case we decide to change from one format to the other.
 * @param points polygon coordinates
 * @returns line string of polygon coordinates as Position[]
 */
export function normalizePointsFormat(points: Position[][] | Position[]): Position[] {
  if (points.length === 0 || points[0].length === 0) {
    return [];
  }
  if (isArray(points[0]) && isArray(points[0][0])) {
    return points[0] as Position[];
  } else {
    return points as Position[];
  }
}

/**
     * Calculate polygon size using the Shoelace Formula, returning value in px^2
     * @param points - array of points 
     * 
     * ![Shoelace Formula](https://latex.artofproblemsolving.com/b/1/1/b11826eb0c7c97097fd3ffef5c2f95bd035800f0.png)
  
     * @see [Shoelace Formula Wikipedia Page](https://en.wikipedia.org/wiki/Shoelace_formula)
     *
     */
export function shoelacePixels(points: { x: number; y: number }[]): number {
  const n = points.length;

  let sum = 0;
  for (let i = 0; i < n - 1; i += 1) {
    sum += points[i].x * points[i + 1].y;
    sum -= points[i + 1].x * points[i].y;
  }
  sum += points[n - 1].x * points[0].y - points[0].x * points[n - 1].y;

  return 0.5 * Math.abs(sum);
}

// term coined by Roman
export function aggressiveSizeRounding(num: number): string {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
  ];

  const suffix = lookup.reverse().find((item) => num >= item.value);

  if (suffix) {
    return (num / suffix.value).toFixed(2) + suffix.symbol;
  }
  return '0';
}

export function getWithUnitArea(value: number, unitSuffix: string) {
  // Very small units are rounded

  if (value < 1e-12) {
    return `${(value * 1e18).toFixed(0)} n${unitSuffix}`;
  }
  if (value < 1e-6) {
    return `${(value * 1e12).toFixed(0)} μ${unitSuffix}`;
  }
  if (value < 1) {
    return `${(value * 1e6).toFixed(2)} m${unitSuffix}`;
  }
  if (value >= 1e6) {
    return `${(value / 1e6).toFixed(2)} k${unitSuffix}`;
  }
  return `${value} ${unitSuffix}`;
}

export function log10(x: number) {
  return Math.log(x) / Math.log(10);
}

export function roundSignificand(x: number, decimalPlaces: number) {
  const exponent = -Math.ceil(-log10(x));
  const power = decimalPlaces - exponent;
  const significand = x * Math.pow(10, power);
  // To avoid rounding problems, always work with integers
  if (power < 0) {
    return Math.round(significand) * Math.pow(10, -power);
  }
  return Math.round(significand) / Math.pow(10, power);
}

export function sizeAsMetric({ size, originalPPM }: { size: number; originalPPM: number }): string {
  return getWithUnitArea(roundSignificand(size / (originalPPM * originalPPM), 3), 'm');
}

export function calculateFeatureSize(feature: Feature): number {
  if (feature?.geometry?.type !== 'Polygon') return -1;

  return shoelacePixels(map(normalizePointsFormat(feature.geometry.coordinates as Position[]), ([x, y]) => ({ x, y })));
}
