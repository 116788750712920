import { CircularProgress, FormControl, FormLabel, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { map } from 'lodash';
import React, { FunctionComponent } from 'react';
import { useFormSource } from 'utils/queryHooks/form/useFormSource';
import { FormSelectFieldProps } from './FormSelectField';

const FormToggleButtonSelectField: FunctionComponent<FormSelectFieldProps> = ({ field, value, onChange }) => {
  const { data: source, isLoading } = useFormSource(field.options.source);

  return (
    <FormControl>
      <FormLabel
        sx={{
          marginBottom: 1,
        }}
      >
        {field.label}
      </FormLabel>
      <ToggleButtonGroup size="small" exclusive value={value} onChange={(_, newValue) => onChange(newValue)}>
        {isLoading && <CircularProgress color="inherit" size={20} />}
        {source &&
          map(source?.options, (option) => (
            <ToggleButton value={option.id} key={option.id}>
              {option.label}
            </ToggleButton>
          ))}
      </ToggleButtonGroup>
    </FormControl>
  );
};

export default FormToggleButtonSelectField;
