import CircleIcon from '@mui/icons-material/FiberManualRecord';
import CircleOutlineIcon from '@mui/icons-material/FiberManualRecordOutlined';
import EraserIcon from '@mui/icons-material/HighlightOff';
import React, { FC } from 'react';

interface ContextMenuIconProps {
  positive: boolean;
  displayPosNeg: boolean;
  color: string;
  hole: boolean;
}

export const ContextMenuIcon: FC<ContextMenuIconProps> = ({ positive, displayPosNeg, color, hole }) => {
  if (hole) {
    return <EraserIcon />;
  }
  if (!displayPosNeg || positive) {
    return <CircleIcon htmlColor={color} />;
  }

  return <CircleOutlineIcon htmlColor={color} />;
};

export default ContextMenuIcon;
