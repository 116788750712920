import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getTodoTypes } from 'api/annotationAssignments';
import { TodoDefinition, TodoOption } from 'interfaces/annotation';
import { compact, includes, map } from 'lodash';
import React, { useMemo } from 'react';
import ClassesToAnnotateList from '../ClassesToAnnotateList';

interface PresetsTodoTypesProps {
  selectedClassesToAnnotate: TodoOption[];
  onClassesSelected: (classes: TodoOption[]) => void;
  selectedTodoType: TodoDefinition | null;
  setSelectedTodoType: (todoType: TodoDefinition | null) => void;
  selectedTodoTypeOptions: TodoOption[];
  setSelectedTodoTypeOptions: (todoOptions: TodoOption[]) => void;
}

const PresetsTodoTypes: React.FC<PresetsTodoTypesProps> = ({
  selectedClassesToAnnotate,
  onClassesSelected,
  selectedTodoType,
  setSelectedTodoType,
  selectedTodoTypeOptions,
  setSelectedTodoTypeOptions,
}) => {
  const selectedClassesToAnnotateNames = useMemo(
    () => compact(map(selectedClassesToAnnotate, 'name')) ?? [],
    [selectedClassesToAnnotate]
  );

  const {
    data: todoTypes,
    isLoading: isLoadingTodoTypes,
    error: todoTypesError,
  } = useQuery(['todoTypes'], getTodoTypes);

  return (
    <Grid item container direction="column" mt={2}>
      <Grid item px={2}>
        {todoTypesError && <Typography color="error">Failed to fetch presets</Typography>}
        <Autocomplete
          options={todoTypes || []}
          getOptionLabel={(option) => option?.todo}
          value={selectedTodoType}
          onChange={(_, newValue) => {
            setSelectedTodoType(newValue);
            setSelectedTodoTypeOptions(newValue?.options);
          }}
          renderInput={(params) => <TextField {...params} label="Select Preset" />}
          loading={isLoadingTodoTypes}
        />
      </Grid>
      <Grid item xs={true} sx={{ overflow: 'auto' }}>
        <ClassesToAnnotateList
          classesToAnnotate={selectedTodoTypeOptions}
          isSelectable
          selectProps={{
            isTodoSelected: (id) => includes(selectedClassesToAnnotateNames, id),
            selectedClassesToAnnotate,
            onClassesSelected,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PresetsTodoTypes;
