import Snackbar from '@mui/material/Snackbar';
import React from 'react';

interface Props {
  message: string;
  action?: React.ReactNode;
}

const ErrorSnackbar: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ message, action }: Props) => {
  const [errorOpen, setErrorOpen] = React.useState(true);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorOpen(false);
  };

  return (
    <Snackbar
      open={errorOpen}
      onClose={handleClose}
      autoHideDuration={10000}
      message={message}
      action={action}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    />
  );
};

export default ErrorSnackbar;
