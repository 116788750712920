import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, ButtonGroup, MenuItem, MenuList, Popover } from '@mui/material';
import { BasePreset } from 'interfaces/basePreset';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';

export interface PresetPermissions {
  canEditDefaultPreset?: boolean;
  canEditOthersPresets?: boolean;
  canDeleteOthersPreset?: boolean;
}
interface Props {
  defaultPreset?: Partial<BasePreset>;
  permissions?: PresetPermissions;
  selectedPreset: Partial<BasePreset>;
  isLoading?: boolean;
  onUpdatePreset?: () => void;
  onDeletePreset?: () => void;
  onUpdateDefaultPreset?: () => void;
  setIsAddingPreset: (isAddingPreset: boolean) => void;
  updateLabel?: string;
  createLabel?: string;
}

const PresetActionButtons: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  defaultPreset,
  permissions = { canDeleteOthersPreset: false, canEditDefaultPreset: false, canEditOthersPresets: false },
  selectedPreset,
  isLoading,
  onUpdatePreset,
  onDeletePreset,
  onUpdateDefaultPreset,
  setIsAddingPreset,
  updateLabel = 'Update',
  createLabel = 'Save as...',
}) => {
  const { name: userName, userId } = useAppSelector((state) => state.auth.profile);
  const [anchorActionListElement, setAnchorActionListElement] = useState<HTMLButtonElement | null>(null);
  const shouldPresetBeInUpdateMode = (presetCreatedBy: string) => {
    return userName === presetCreatedBy || userId === presetCreatedBy || permissions.canEditOthersPresets;
  };

  const [isUpdateMode, setIsUpdateMode] = useState(shouldPresetBeInUpdateMode(selectedPreset?.createdBy));
  useEffect(() => {
    setIsUpdateMode(Boolean(onUpdatePreset) && shouldPresetBeInUpdateMode(selectedPreset?.createdBy));
  }, [Boolean(onUpdatePreset) && selectedPreset?.createdBy]);

  const userHavePermissionToDeletePreset = () => {
    return (
      selectedPreset?.id &&
      selectedPreset?.id !== defaultPreset?.id &&
      (selectedPreset?.createdBy === userName ||
        selectedPreset?.createdBy === userId ||
        permissions?.canDeleteOthersPreset)
    );
  };

  const updatePreset = onUpdatePreset
    ? () => {
        onUpdatePreset();
      }
    : undefined;

  return (
    <ButtonGroup aria-label="split button" size="small" variant="outlined" color="secondary">
      <Button
        disabled={isLoading}
        onClick={() => {
          if (isUpdateMode) {
            if (!updatePreset) {
              console.error('No updatePreset function provided');
              return;
            }
            updatePreset();
          } else {
            setIsAddingPreset(true);
          }
        }}
      >
        {isUpdateMode ? updateLabel : createLabel}
      </Button>
      {isUpdateMode && (
        <Button
          aria-haspopup="menu"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorActionListElement(event.currentTarget);
          }}
        >
          <ArrowDropDownIcon />
        </Button>
      )}
      <Popover
        open={Boolean(anchorActionListElement) && !isLoading}
        anchorEl={anchorActionListElement}
        onClose={() => {
          setAnchorActionListElement(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList id="split-button-menu" autoFocusItem>
          <MenuItem
            key={'Create new preset'}
            onClick={() => {
              setIsAddingPreset(true);
              setAnchorActionListElement(null);
            }}
          >
            Create new preset
          </MenuItem>
          {onDeletePreset && (
            <MenuItem
              key={'Delete preset'}
              onClick={() => {
                onDeletePreset();
                setAnchorActionListElement(null);
              }}
              disabled={!userHavePermissionToDeletePreset()}
            >
              Delete preset
            </MenuItem>
          )}
          {onUpdateDefaultPreset && permissions?.canEditDefaultPreset && (
            <MenuItem
              key={'Set as default'}
              onClick={() => {
                onUpdateDefaultPreset();
                setAnchorActionListElement(null);
              }}
            >
              Set as default
            </MenuItem>
          )}
        </MenuList>
      </Popover>
    </ButtonGroup>
  );
};

export default PresetActionButtons;
