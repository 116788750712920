// eslint-disable-next-line import/order
import { CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import { filter, find, findIndex, first, map } from 'lodash';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

import HeaderIconButton from 'components/HeaderIconButton/HeaderIconButton';
import { Procedure } from 'interfaces/procedure';
import { getStainStrWithNegPosControl } from 'utils/helpers';
import { useSetSelectedSlideIds } from 'utils/useCurrentSlideIds';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import Dropdown from '../../atoms/Dropdown/Dropdown';
import { useFilteredCaseSlideThumbnailsData } from '../useFilteredCaseIds';

import { ReactComponent as SplitIcon } from './split.svg';

interface Props {
  procedure: Procedure;
  isPlaceholderData?: boolean;
}

const SlidesViewerSelector: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  procedure,
  isPlaceholderData,
}) => {
  const { selectedSlideIds, setSelectedSlideIds } = useSetSelectedSlideIds(procedure);
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  const theme = useTheme();
  const navigate = useNavigate();

  const [slideIdForReviewing] = useQueryParam('slideIdForReviewing', StringParam);
  const [slidesMode] = useQueryParam('slidesMode', BooleanParam);
  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);
  const [reviewTab] = useQueryParam<string>('reviewTab', StringParam);

  const { getUrlToSlidePage } = useNavigationToViewerPage();

  const { data: thumbnailsData } = useFilteredCaseSlideThumbnailsData({ studyId: procedure.studyId });
  const numberOfSlidesAfterFilter = pendingSlidesMode
    ? 1
    : filter(thumbnailsData, { caseId: procedure?.id })?.length ?? 1;
  const numSlides = procedure?.slides?.length ?? 0;
  const isPlaceholderDataMissingSlides = numberOfSlidesAfterFilter !== numSlides;

  const isReviewingSlide = reviewTab !== undefined && slideIdForReviewing !== undefined;

  const slideIndexForReviewing = findIndex(procedure.slides, { id: slideIdForReviewing });

  const prevRightSlideId = useRef<string>();

  const splitView = (selectedSlideIds?.length ?? 0) > 1;

  const toSplitView = () => {
    let rightSlideId = null;

    if (prevRightSlideId.current) {
      rightSlideId = prevRightSlideId.current;
    } else {
      const leftSelectedSlideId: string = first(selectedSlideIds);
      if (numSlides > 1) {
        rightSlideId = find(procedure.slides, (slide) => slide.id !== leftSelectedSlideId)?.id;
      } else {
        rightSlideId = leftSelectedSlideId;
      }
      prevRightSlideId.current = rightSlideId;
    }

    const selectedSlideIdsNew = [...selectedSlideIds, rightSlideId];
    setSelectedSlideIds(selectedSlideIdsNew);
  };

  const toSingleView = () => {
    const selectedSlideIdsNew = [first(selectedSlideIds)];
    setSelectedSlideIds(selectedSlideIdsNew);
  };

  const handleSplit = splitView ? toSingleView : toSplitView;

  const handleSlideClicked = (slideId: string, index: number) => {
    const selectedSlideIdsNew = selectedSlideIds;
    selectedSlideIdsNew[index] = slideId;
    setSelectedSlideIds(selectedSlideIdsNew);

    if (index === 1) prevRightSlideId.current = slideId;
    else if (slidesMode) {
      // navigate only if the left slide is clicked
      navigate(getUrlToSlidePage({ slideId, caseId: !pendingSlidesMode ? procedure.id : null }));
    }
  };

  const selectedSlides = map(selectedSlideIds, (slideId) => find(procedure.slides, { id: slideId }));
  const dropdownOptions = map(procedure.slides, (slide, index) => ({
    text:
      isLoadingStainTypeOptions || !slide?.stainingType
        ? `Loading... (${index + 1})`
        : `${getStainStrWithNegPosControl(
            stainTypeIdToDisplayName(slide.stainingType),
            slide.negativeControl,
            slide.positiveControl
          )} (${index + 1})`,
    value: slide.id,
  }));

  const isMultiSlide = numSlides > 1;

  const defaultSlide = first(selectedSlides) || first(procedure.slides);

  const defaultSlideText =
    isLoadingStainTypeOptions || !defaultSlide?.stainingType
      ? 'Loading...'
      : getStainStrWithNegPosControl(
          stainTypeIdToDisplayName(defaultSlide.stainingType),
          defaultSlide.negativeControl,
          defaultSlide.positiveControl
        );

  return (
    <Grid
      item
      container
      alignItems="center"
      sx={
        theme.palette.mode === 'dark'
          ? {
              '& .MuiSelect-select': {
                // dark mode theme enforces filled variant
                py: 1,
              },
            }
          : undefined
      }
    >
      <Grid item container alignItems="center">
        <Grid item>
          <HeaderIconButton
            key="splitView"
            icon={<SplitIcon />}
            selected={splitView}
            label=""
            onClick={handleSplit}
            title="Multi Slide View"
          />
        </Grid>
        <Grid item>
          {isMultiSlide && !isReviewingSlide ? (
            <Dropdown
              size="small"
              options={dropdownOptions}
              onOptionSelected={(optionValue) => handleSlideClicked(optionValue, 0)}
              value={selectedSlides?.[0]?.id || ''}
            />
          ) : (
            defaultSlide && (
              <Typography fontWeight="bold">
                {defaultSlideText}
                {isReviewingSlide && isMultiSlide ? `(${slideIndexForReviewing + 1})` : ''}
              </Typography>
            )
          )}
        </Grid>
        {splitView && (
          <Grid item>
            {isMultiSlide ? (
              <Dropdown
                size="small"
                options={dropdownOptions}
                onOptionSelected={(optionValue) => handleSlideClicked(optionValue, 1)}
                value={selectedSlides?.[1]?.id || ''}
              />
            ) : (
              defaultSlide && (
                <Typography pl={2} fontWeight="bold">
                  {defaultSlideText}
                </Typography>
              )
            )}
          </Grid>
        )}
        {isPlaceholderData && slidesMode && isPlaceholderDataMissingSlides && (
          <Grid item>
            <CircularProgress size={16} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SlidesViewerSelector;
