import { CohortWithSelectedFeatures } from 'interfaces/cohort_old';
import { curry } from 'lodash/fp';
import { stringify } from 'qs';
import { encodeQueryParams, JsonParam, StringParam } from 'use-query-params';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';
import { ExperimentResultsSelection } from 'utils/useEncodedFilters';

export const fetchProcedures = curry(async (studyId: string, labId: string) => {
  const encodedFilters = encodeQueryParams(
    {
      filters: JsonParam,
      includeDendrogram: StringParam,
      includeSurvivalAnalysis: StringParam,
      labId: StringParam,
      experimentResultsSelection: StringParam,
    },
    {
      filters: { studyId },
      includeDendrogram: 'true', // temp workaround until server update
      includeSurvivalAnalysis: 'true', // temp workaround until server update
      labId,
      experimentResultsSelection: ExperimentResultsSelection.All,
    }
  );

  const search = stringify(encodedFilters);

  const procedures = await apiRequestHandlerPromise<{
    procedures: CohortWithSelectedFeatures['procedures'];
    dendrogram: CohortWithSelectedFeatures['dendrogram'];
    survivalAnalysis: CohortWithSelectedFeatures['survivalAnalysis'];
  }>({
    url: `procedures?${search}`,
    method: 'GET',
  });
  return procedures;
});
