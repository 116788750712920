import { useQuery } from '@tanstack/react-query';
import { useMatch } from 'react-router-dom';
import { NumberParam, useQueryParam } from 'use-query-params';

import { getStudyProcedure, getStudyProcedureQueryKey } from 'api/study';
import { useEncodedFilters } from './useEncodedFilters';

export const useCaseIdToLabId = () => {
  const { queryParams, encodedFilters } = useEncodedFilters();
  const [caseIdFromParam] = useQueryParam('caseId', NumberParam);

  const match = useMatch('/procedures/:id');
  const caseId = queryParams.slidesMode ? Number(caseIdFromParam) : Number(match?.params?.id);

  const studyId = queryParams.filters?.studyId;

  // 'Boolean(caseId)' is for scenarios that caseId is null (isNaN(null) is false)
  const hasCaseId = Boolean(caseId) && !isNaN(caseId);
  const { data: { procedure } = {}, isLoading } = useQuery(
    // this key is without the studyId because for now the backend fetches the case's studyId by itself.
    // and we can also be in a case page with studyId = ALL / NONE, so we don't want to refetch the case
    // this should be changed in the future
    getStudyProcedureQueryKey(studyId, caseId, queryParams),
    () => getStudyProcedure(studyId, caseId, encodedFilters),
    {
      enabled: hasCaseId,
    }
  );

  return {
    procedureLabId: hasCaseId && !isLoading ? procedure?.labId : undefined,
    caseId,
    isLoading: isLoading && hasCaseId,
  };
};
