import PeopleIcon from '@mui/icons-material/People';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Grid, Icon, IconButton, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { Permission } from 'interfaces/permissionOption';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentLabId } from 'utils/useCurrentLab';
import { usePermissions } from 'utils/usePermissions';

export const UserMenu: React.FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const { labSearch } = useCurrentLabId();
  const [anchorActionListElement, setAnchorActionListElement] = React.useState<HTMLElement | null>(null);

  const { hasSomePermissions } = usePermissions();
  const canManagePermissions = hasSomePermissions([Permission.DefineRolesToLabUsers, Permission.ManageNucleaiUsers]);

  return (
    <>
      <Grid
        item
        sx={{ cursor: 'pointer' }}
        onClick={
          canManagePermissions
            ? (event: React.MouseEvent<HTMLElement>) => {
                setAnchorActionListElement(event.currentTarget);
              }
            : undefined
        }
      >
        <IconButton color="inherit">
          <PersonOutlineOutlinedIcon />
        </IconButton>
      </Grid>

      <Popover
        open={Boolean(anchorActionListElement)}
        anchorEl={anchorActionListElement}
        onClose={() => {
          setAnchorActionListElement(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList autoFocusItem>
          <MenuItem
            key={'Create new preset'}
            onClick={() => {
              navigate({
                search: labSearch,
                pathname: '/admin/user-credentials',
              });
              setAnchorActionListElement(null);
            }}
          >
            <Grid container columnGap={1}>
              <Grid item>
                <Icon fontSize="medium">
                  <PeopleIcon />
                </Icon>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {canManagePermissions ? 'Manage' : 'View'} Roles and Permissions
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
};
