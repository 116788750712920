import ExpandIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, List, Typography } from '@mui/material';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { InferredFeatureConfig } from 'interfaces/inferredFeatures';
import { Study } from 'interfaces/study';
import { map, reject } from 'lodash';
import React, { FunctionComponent } from 'react';
import { uuidv4 } from 'utils/helpers';
import InferredFeatureConfigSettings from './InferredFeatureConfigSettings';

interface InferredFeaturesSettingsProps {
  study: Study;
  hasUnsavedInferredFeatures: boolean;
  setInferredFeaturesConfig: (config: InferredFeatureConfig[]) => void;
  inferredFeaturesConfig: InferredFeatureConfig[];
}

const InferredFeaturesSettings: FunctionComponent<React.PropsWithChildren<InferredFeaturesSettingsProps>> = ({
  study,
  hasUnsavedInferredFeatures,
  setInferredFeaturesConfig,
  inferredFeaturesConfig,
}) => {
  const confirmationModal = useConfirmation();

  const updateConfig = (newConfig: InferredFeatureConfig) => {
    setInferredFeaturesConfig(
      map(inferredFeaturesConfig, (config) => (config.id === newConfig.id ? newConfig : config))
    );
  };

  const addConfig = () => {
    setInferredFeaturesConfig([
      ...inferredFeaturesConfig,
      {
        id: uuidv4().slice(0, 8),
        featureName: '',
        rules: [],
        defaultValue: {
          id: uuidv4().slice(0, 8),
          name: 'Default',
        },
      },
    ]);
  };

  const removeConfig = async (id: string) => {
    const result = await confirmationModal({
      title: 'Delete Inferred Feature?',
    });
    if (result) {
      setInferredFeaturesConfig(reject(inferredFeaturesConfig, { id }));
    }
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <Typography variant="h3">Inferred Features</Typography>
        {hasUnsavedInferredFeatures && <Chip size="small" sx={{ ml: 1 }} color="primary" label="Unsaved Changes" />}
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {map(inferredFeaturesConfig, (config) => (
            <InferredFeatureConfigSettings
              key={config.id}
              config={config}
              study={study}
              updateConfig={updateConfig}
              removeFeatureConfig={() => removeConfig(config.id)}
            />
          ))}
        </List>
        <Button onClick={addConfig}>Add inferred feature</Button>
      </AccordionDetails>
    </Accordion>
  );
};

export default InferredFeaturesSettings;
