import { filter, includes, uniq } from 'lodash';
import { useCallback } from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';

export interface SlideResultsExpandedState {
  [viewerIndex: number]: {
    [slideStainTypeId: string]: string[];
  };
}

/**
 * Store the expanded state of the accordion in the URL to allow showing heatmaps when loading the page
 */
export const useSlideResultsExpandedState = ({
  title,
  viewerIndex,
  slideStainTypeId,
}: {
  title: string;
  viewerIndex: number;
  slideStainTypeId: string;
}) => {
  const [expandedSlideResults, setExpandedSlideResults] = useQueryParam<SlideResultsExpandedState>(
    'expandedSlideResults',
    JsonParam
  );

  const isExpanded = includes(expandedSlideResults?.[viewerIndex]?.[slideStainTypeId], title);

  const setIsExpanded = useCallback(
    (newExpanded: boolean) => {
      setExpandedSlideResults((prev) => {
        const prevExpandedResultsForSlide = prev?.[viewerIndex]?.[slideStainTypeId] || [];
        return {
          ...prev,
          [viewerIndex]: {
            ...prev?.[viewerIndex],
            [slideStainTypeId]: newExpanded
              ? uniq([...prevExpandedResultsForSlide, title])
              : filter(prevExpandedResultsForSlide, (expandedTitle) => expandedTitle !== title),
          },
        };
      }, 'replaceIn');
    },
    [setExpandedSlideResults, viewerIndex, slideStainTypeId, title]
  );

  return { isExpanded, setIsExpanded };
};
