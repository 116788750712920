import { FormFieldBasic, formToFields } from 'components/ReviewPanel/form.util';
import dayjs from 'dayjs';
import { DisplayedField } from 'interfaces/genericFields';
import { FormResponseWithAuthor, FormType, ReviewForm } from 'interfaces/reviewForm';
import { filter, flatMap, fromPairs, groupBy, isEmpty, map, max, nth, range, some } from 'lodash';
import { Procedure } from '..';
import { ProceduresFieldsContext } from './helpers';

export const getFormFieldGroups = (formResponses: FormResponseWithAuthor[], forms: ReviewForm[]) => {
  const responsesGroupedByFormId = groupBy(formResponses, (response) => response.formId);

  const formResponsesFieldGroupsPairs = map(forms, (form) => {
    const relevantResponses = responsesGroupedByFormId[form.id] ?? [];

    return [form.type, getFormFieldsForForm(form, relevantResponses)];
  });

  return fromPairs(formResponsesFieldGroupsPairs) as Record<
    FormType,
    DisplayedField<Procedure, any, ProceduresFieldsContext>[]
  >;
};

const getResponseForCase = (relevantResponses: FormResponseWithAuthor[], procedure: Procedure, authorIndex: number) => {
  const caseResponses = filter(relevantResponses, (response) =>
    some(procedure?.slides, (slide) => response.context.slideId === slide.id)
  );

  return nth(caseResponses, authorIndex - 1);
};

const fieldTypeToFilterType = (
  fieldType: FormFieldBasic['type']
): DisplayedField<Procedure, any, ProceduresFieldsContext>['filterType'] => {
  switch (fieldType) {
    case 'multiselect':
      return 'multiSelect';
    case 'checkbox':
      return 'checkbox';
    default:
      return 'text';
  }
};

const formFieldToTableField = (
  formField: FormFieldBasic,
  formType: FormType,
  authorIndex: number
): DisplayedField<Procedure, any, ProceduresFieldsContext> => {
  const label = `${formField.label} ${authorIndex}`;
  return {
    dataCategory: 'metadata',
    dataKey: `${formType}-${formField.id}-${authorIndex}`,
    filterType: fieldTypeToFilterType(formField.type),
    label,
    columnWidth: { width: 200 },
    valueGetter: (procedure: Procedure, context) => {
      const response = getResponseForCase(context.formResponses, procedure, authorIndex);
      return response?.filledFields[formField.id];
    },
  } as DisplayedField<Procedure, any, ProceduresFieldsContext>;
};

const getFormFieldsForForm = (
  form: ReviewForm,
  relevantResponses: FormResponseWithAuthor[]
): DisplayedField<Procedure, any, ProceduresFieldsContext>[] => {
  const maximumAuthorsPerSlide =
    max(
      map(
        groupBy(relevantResponses, (response) => response.context.slideId),
        'length'
      )
    ) ?? 0;

  const formType = form.type;

  if (isEmpty(relevantResponses)) {
    return [];
  }

  const formFields = formToFields(form);

  return flatMap(range(1, maximumAuthorsPerSlide + 1), (authorIndex) => {
    const responseFields = map<FormFieldBasic, DisplayedField<Procedure, any, ProceduresFieldsContext>>(
      formFields,
      (formField) => formFieldToTableField(formField, formType, authorIndex)
    );

    const authorField: DisplayedField<Procedure, any, ProceduresFieldsContext> = {
      dataCategory: 'metadata',
      dataKey: `${formType}-author-${authorIndex}`,
      filterType: 'text',
      label: `Reviewer ${authorIndex}`,
      columnWidth: { width: 200 },
      valueGetter: (procedure, context) => {
        const response = getResponseForCase(context.formResponses, procedure, authorIndex);
        return response?.author?.name;
      },
    };

    const createdAtField: DisplayedField<Procedure, any, ProceduresFieldsContext> = {
      dataCategory: 'metadata',
      dataKey: `${formType}-submissionDate-${authorIndex}`,
      filterType: 'date-range',
      label: `Submission date ${authorIndex}`,
      columnWidth: { width: 200 },
      valueGetter: (procedure, context) => {
        const response = getResponseForCase(context.formResponses, procedure, authorIndex);

        const submissionDate = response?.submissionDate;

        if (!submissionDate) {
          return null;
        }
        return dayjs(submissionDate).format('llll');
      },
    };

    return [authorField, createdAtField, ...responseFields];
  });
};
