import { CardMedia, Tooltip } from '@mui/material';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { StyledCard } from 'components/StudyDashboard/ProceduresPage/ProcedureCard';
import SlideCarousel from 'components/StudyDashboard/ProceduresPage/ProcedureCard/Carousel/SlideCarousel';
import {
  Section,
  SectionContentTypography,
  SectionTitleTypography,
  StyledCardContent,
} from 'components/StudyDashboard/ProceduresPage/ProcedureCard/CaseCardContents';
import { Accession } from 'interfaces/accession';
import { Slide } from 'interfaces/slide';
import { map } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { getAccessionIdentifier } from 'utils/helpers';
interface Props {
  accession: Accession;
}

export const NOT_AVAILABLE = 'N/A';

const AccessionCard: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ accession }) => {
  const theme = useTheme();

  const { patient, accessionId, procedureId } = accession.accessionData;

  const minScanDate = moment.max(map(accession.slides, (slide: Slide) => moment(slide.scanDate)));

  return (
    <StyledCard variant="outlined" data-test-class={`accession-card-${accession.id}`}>
      <Link
        component={RouterLink}
        color="secondary"
        underline="none"
        to={{
          pathname: `/procedures/${procedureId}`,
          search: location.search,
        }}
      >
        <CardMedia>
          <SlideCarousel slides={accession.slides} />
        </CardMedia>

        <StyledCardContent>
          <Tooltip title={accessionId}>
            <Typography variant="h6" sx={{ fontWeight: theme.typography.fontWeightBold }}>
              {getAccessionIdentifier(accession)}
            </Typography>
          </Tooltip>

          <Section>
            <SectionTitleTypography>Patient Medical Record Number</SectionTitleTypography>

            <SectionContentTypography>{patient.mrn || NOT_AVAILABLE}</SectionContentTypography>
          </Section>

          <Section>
            <SectionTitleTypography>Scan Date</SectionTitleTypography>

            <SectionContentTypography>{minScanDate.format('LL')}</SectionContentTypography>
          </Section>

          <Section>
            <SectionTitleTypography>Date of Birth</SectionTitleTypography>

            <SectionContentTypography>
              {moment(patient.dateOfBirth).format('LL') || NOT_AVAILABLE}
            </SectionContentTypography>
          </Section>
        </StyledCardContent>
      </Link>
    </StyledCard>
  );
};

export default AccessionCard;
