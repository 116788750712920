import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { DropdownOption } from 'components/atoms/Dropdown/Dropdown';
import { find, map } from 'lodash';
import React from 'react';

interface Props {
  value: string;
  options: DropdownOption[];
  onChange: (value: string) => void;
  loading?: boolean;
  disabled?: boolean;
  helperText?: string;
}

const OrchestrationIdAutoComplete = ({ value, options, onChange, loading, disabled, helperText }: Props) => {
  return (
    <Autocomplete<string, false, false, false>
      size="small"
      disabled={disabled}
      value={value || null}
      options={map(options, 'value')}
      onChange={(e, val) => onChange(val)}
      getOptionLabel={(valueStr) => find(options, { value: valueStr })?.text || ''}
      renderInput={(params) => (
        <TextField
          helperText={helperText || null}
          {...params}
          label="Orchestration ID"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default OrchestrationIdAutoComplete;
