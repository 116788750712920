import { useQuery } from '@tanstack/react-query';
import { getCellPanels } from 'api/cellRules';
import { CellRuleStudyId } from 'interfaces/cellRule';

const useCellRulePanels = (
  studyId: CellRuleStudyId,
  {
    enabled = true,
  }: {
    enabled?: boolean;
  } = {}
) => {
  const query = useQuery({
    queryKey: studyId ? ['cellPanels', studyId] : ['cellPanels'],
    queryFn: () => getCellPanels(studyId),
    enabled,
  });
  return query;
};

export default useCellRulePanels;
