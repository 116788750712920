import AbcIcon from '@mui/icons-material/Abc';
import ClearIcon from '@mui/icons-material/Clear';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import ReplayIcon from '@mui/icons-material/Replay';
import SearchIcon from '@mui/icons-material/Search';
import SpeedIcon from '@mui/icons-material/Speed';
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import SearchBar from 'components/atoms/SearchBar';
import { Permission } from 'interfaces/permissionOption';
import React from 'react';
import { usePermissions } from 'utils/usePermissions';

export const ChannelsAccordionSummary: React.FC<
  React.PropsWithChildren<{
    expand: boolean;
    hasMultipleNormalizationChoices: boolean;
    editChannelColorPresetsMode: boolean;
    editChannelNormalizationMode: boolean;
    editChannelMarkerMode: boolean;
    isSavingChannelMarkers: boolean;
    isMissingChannelNames: boolean;
    hasMarkerChanges: boolean;
    numChannels: number;
    setFilterText?: React.Dispatch<React.SetStateAction<string>>;
    setEditChannelColorPresetsMode: React.Dispatch<React.SetStateAction<boolean>>;
    setEditChannelNormalizationMode: React.Dispatch<React.SetStateAction<boolean>>;
    setEditChannelMarkerMode: React.Dispatch<React.SetStateAction<boolean>>;
  }>
> = ({
  hasMultipleNormalizationChoices,
  expand,
  editChannelColorPresetsMode,
  editChannelNormalizationMode,
  editChannelMarkerMode,
  isSavingChannelMarkers,
  isMissingChannelNames,
  hasMarkerChanges,
  numChannels,
  setFilterText,
  setEditChannelColorPresetsMode,
  setEditChannelNormalizationMode,
  setEditChannelMarkerMode,
}) => {
  const { hasPermission } = usePermissions();
  const canViewUnpublishedResults = hasPermission(Permission.ViewUnpublishedResults);
  const [showFilter, setShowFilter] = React.useState(false);
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item md="auto">
        <Typography variant="h3">Channels ({numChannels})</Typography>
      </Grid>
      {showFilter && setFilterText ? (
        <Grid item md="auto">
          <SearchBar
            helperText="Filter channels"
            onChange={setFilterText}
            closeSearchMode={() => {
              setShowFilter(false);
            }}
            size="small"
            variant="outlined"
          />
        </Grid>
      ) : (
        <Grid item md="auto" textAlign="end">
          {expand && (
            <>
              <IconButton
                title={editChannelColorPresetsMode ? 'Hide Color Presets' : 'Color Presets'}
                disabled={editChannelMarkerMode || editChannelNormalizationMode}
                onClick={(e) => {
                  setEditChannelColorPresetsMode((prevEditChannelColorPresetsMode) => !prevEditChannelColorPresetsMode);
                  e.stopPropagation();
                }}
              >
                {editChannelColorPresetsMode ? <ClearIcon fontSize="small" /> : <ColorLensIcon fontSize="small" />}
              </IconButton>
              {(canViewUnpublishedResults || hasMultipleNormalizationChoices) && (
                <IconButton
                  title={editChannelNormalizationMode ? 'Hide channel normalizations' : 'Channel normalizations'}
                  disabled={editChannelMarkerMode || editChannelColorPresetsMode}
                  onClick={(e) => {
                    setEditChannelNormalizationMode(
                      (prevEditChannelNormalizationMode) => !prevEditChannelNormalizationMode
                    );
                    e.stopPropagation();
                  }}
                >
                  {editChannelNormalizationMode ? <ClearIcon fontSize="small" /> : <SpeedIcon fontSize="small" />}
                </IconButton>
              )}
              <IconButton
                disabled={editChannelColorPresetsMode || editChannelNormalizationMode || isSavingChannelMarkers}
                onClick={(e) => {
                  setEditChannelMarkerMode(!editChannelMarkerMode);
                  e.stopPropagation();
                }}
                title={
                  isMissingChannelNames
                    ? 'Must assign markers to all channels to Save'
                    : isSavingChannelMarkers
                    ? 'Saving...'
                    : editChannelMarkerMode
                    ? hasMarkerChanges
                      ? 'Revert changes'
                      : 'Cancel edits'
                    : 'Edit channel markers'
                }
              >
                {editChannelMarkerMode ? (
                  isSavingChannelMarkers ? (
                    <CircularProgress size={18.5} />
                  ) : hasMarkerChanges && !isMissingChannelNames ? (
                    <ReplayIcon fontSize="small" />
                  ) : (
                    <ClearIcon fontSize="small" />
                  )
                ) : (
                  <AbcIcon fontSize="medium" />
                )}
              </IconButton>
            </>
          )}
          {setFilterText && !showFilter && (
            <IconButton
              title="Filter channels"
              onClick={(e) => {
                setShowFilter(!showFilter);
                e.stopPropagation();
              }}
            >
              <SearchIcon fontSize="small" />
            </IconButton>
          )}
        </Grid>
      )}
    </Grid>
  );
};
