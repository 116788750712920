import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Chip,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { Orchestration } from 'api/experimentResults';
import { map } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent } from 'react';

interface OrchestrationViewProps {
  orchestration: Orchestration;
  expanded: boolean;
  onOpen: (orchestrationId: string) => void;
  onClose: () => void;
}

const OrchestrationView: FunctionComponent<React.PropsWithChildren<OrchestrationViewProps>> = ({
  orchestration,
  expanded,
  onOpen,
  onClose,
}) => {
  // A notice - published results mention here are saved in the database in the 'approved' column
  // and the approved results mention here are saved in the database in the 'internally_approved' column
  const { orchestrationId, slidesData, createdAtMinimum } = orchestration;

  return (
    <>
      <ListItemButton
        onClick={() => {
          if (expanded) {
            onClose();
          } else {
            onOpen(orchestrationId);
          }
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={0.5}>
            <ListItemIcon>{expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}</ListItemIcon>
          </Grid>
          <Grid item xs={8}>
            <ListItemText
              primary={`Orchestration ${orchestrationId}`}
              secondary={`Created at: ${moment(createdAtMinimum).format('llll')}`}
            />
          </Grid>
        </Grid>
      </ListItemButton>
      <Collapse in={expanded}>
        <List
          component="div"
          sx={{
            pl: 2,
          }}
          subheader={<ListSubheader>Slide IDs</ListSubheader>}
        >
          <Divider />
          {map(slidesData, (slideData) => (
            <ListItem key={slideData.slideId}>
              <ListItemText>
                {slideData.slideId}{' '}
                {(slideData.internallyApproved || slideData.approved) && (
                  <Chip
                    size="small"
                    variant="outlined"
                    color={slideData.approved ? 'primary' : 'secondary'}
                    label={slideData.approved ? 'published' : 'approved'}
                  />
                )}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default OrchestrationView;
