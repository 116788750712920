import { FormResponse, FormResponseWithAuthor, ResponseContext, ReviewFormNewResponse } from 'interfaces/reviewForm';
import { isEmpty } from 'lodash';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const createFormResponse = (
  formId: string,
  response: ReviewFormNewResponse,
  context: ResponseContext,
  signal?: AbortSignal
) => {
  return apiRequestHandlerPromise<{
    response: FormResponse;
  }>({
    url: `forms/${formId}/responses?${stringify(context)}`,
    method: 'POST',
    signal,
    data: JSON.stringify(response),
  });
};

export const getExistingFormResponse = (formId: string, context: ResponseContext, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<{
    response: FormResponseWithAuthor;
  }>({
    url: `forms/${formId}/responses/latest_for_user?${stringify(context)}`,
    method: 'GET',
    signal,
  });
};

export const getFormResponses = (formId: string, context: Partial<ResponseContext>, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<{
    responses: FormResponseWithAuthor[];
  }>({
    url: `forms/${formId}/responses${isEmpty(context) ? '' : '?'}${stringify(context)}`,
    method: 'GET',
    signal,
  });
};

export const getFormResponsesTable = (formId: string, context: Partial<ResponseContext>, signal?: AbortSignal) => {
  return apiRequestHandlerPromise<{
    data: any;
  }>({
    url: `forms/${formId}/responses/aggregated_table?${stringify(context)}`,
    method: 'GET',
    signal,
  });
};
