import { Autocomplete, FormControl, FormControlProps, MenuItem, TextField, Tooltip } from '@mui/material';
import React from 'react';
import { UrlUpdateType } from 'use-query-params';

import { useGetFeatureDisplayNameWithContext } from 'utils/features/contextHooks';

interface Props<ValueType extends number | string = string, Multiple extends boolean = false> extends FormControlProps {
  keys: ValueType[];
  selectedKey: Multiple extends true ? ValueType[] : ValueType;
  updateSelectedKey: Multiple extends true
    ? (newValue: ValueType[], updateType?: UrlUpdateType) => void
    : (newValue: ValueType, updateType?: UrlUpdateType) => void;
  name: string;
  multiple?: Multiple;
  getOptionDisplayName?: (key: ValueType) => string;
}

const KeySelect = <ValueType extends number | string = string, Multiple extends boolean = false>({
  keys,
  selectedKey,
  multiple,
  updateSelectedKey,
  name,
  getOptionDisplayName: getOptionDisplayNameFromProps,
  ...formControlProps
}: Props<ValueType, Multiple>) => {
  const { getFeatureDisplayNameWithContext, isLoadingFormatterData } = useGetFeatureDisplayNameWithContext(false);

  const getOptionDisplayName =
    getOptionDisplayNameFromProps || (getFeatureDisplayNameWithContext as (key: ValueType) => string);

  const fontSize = formControlProps?.size === 'small' ? 12 : 14;

  return (
    <FormControl {...formControlProps} fullWidth>
      <Autocomplete<ValueType, Multiple>
        fullWidth
        options={keys}
        multiple={multiple}
        value={selectedKey}
        disabled={!getOptionDisplayNameFromProps && isLoadingFormatterData}
        onChange={(event: any, newValue: Multiple extends true ? ValueType[] : ValueType | null) => {
          if (newValue) {
            updateSelectedKey(newValue as any);
          }
        }}
        autoHighlight
        getOptionLabel={(option) => getOptionDisplayName(option)}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{ ...params.InputProps, style: { fontSize: fontSize } }}
            variant={formControlProps?.variant}
            size={formControlProps?.size}
            fullWidth={formControlProps?.fullWidth}
            label={name}
          />
        )}
        renderOption={(props, key) => {
          const displayName = getOptionDisplayName(key);
          return (
            <Tooltip
              key={key}
              title={displayName}
              placement="left"
              enterDelay={500}
              enterNextDelay={500}
              leaveDelay={0}
              arrow
            >
              <MenuItem key={key} value={key} {...props} style={{ fontSize: fontSize }}>
                {displayName}
              </MenuItem>
            </Tooltip>
          );
        }}
      />
    </FormControl>
  );
};

export default KeySelect;
