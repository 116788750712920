import CloseIcon from '@mui/icons-material/Close';
import { Button, Collapse, FormControl, FormHelperText, Grid, IconButton, Input } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';

export interface PresetPermissions {
  canEditDefaultPreset?: boolean;
  canEditOthersPresets?: boolean;
  canDeleteOthersPreset?: boolean;
}
interface Props {
  isAddingPreset: boolean;
  setIsAddingPreset: (value: boolean) => void;
  isLoading?: boolean;
  savePreset: (name: string) => void;
}

const SavePreset: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  isAddingPreset,
  setIsAddingPreset,
  isLoading,
  savePreset,
}) => {
  const [valueName, setValueName] = useState('');

  return (
    <Collapse in={isAddingPreset}>
      <Grid container item justifyContent="space-between" spacing={1} alignItems="baseline">
        <Grid item md={6}>
          <FormControl fullWidth variant="standard">
            <Input
              id="standard-adornment-weight"
              aria-describedby="standard-weight-helper-text"
              inputProps={{
                'aria-label': 'weight',
              }}
              value={valueName}
              onChange={(event) => {
                setValueName(event.target.value);
              }}
            />
            <FormHelperText id="standard-weight-helper-text">Name</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item container md={6} textAlign="end" spacing={1} alignItems="center">
          <Grid item>
            <Button
              disabled={isEmpty(valueName) || isLoading}
              variant="outlined"
              size="small"
              onClick={() => {
                savePreset(valueName);
                setValueName('');
              }}
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              disabled={isLoading}
              onClick={() => {
                setIsAddingPreset(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Collapse>
  );
};

export default SavePreset;
