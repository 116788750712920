import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const createCustomerResults = ({
  studyId,
  resultTypesToExport,
  jobId,
}: {
  studyId: string;
  resultTypesToExport: string[];
  jobId?: string;
}) => {
  return apiRequestHandlerPromise({
    url: `upload_customer_results/${studyId}?${stringify(
      { resultTypesToExport, jobId },
      {
        arrayFormat: 'repeat',
      }
    )}`,
    method: 'POST',
  });
};
