import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

import { Box, DialogTitle, Grid, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getRoles } from 'api/roles';
import ModalWrapper from 'components/ModalWrapper';
import { TableEditingContextProvider } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { Role } from 'interfaces/roles';
import { RolesFieldsContext, useRolesFieldsContext } from 'interfaces/roles/rolesFieldsContext';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { RolesCreationDataGrid } from './RolesCreationDataGrid';

export interface RolesManagementProps {
  onClose: () => void;
  isOpen: boolean;
}

export const RolesManagement: React.FC<React.PropsWithChildren<RolesManagementProps>> = ({ onClose, isOpen }) => {
  const rolesFieldsContext = useRolesFieldsContext();
  const { labId } = useCurrentLabId();
  const { isLoading: isLoadingRoles } = useQuery(['roles', labId], () => getRoles(labId));

  return (
    !isLoadingRoles && (
      <ModalWrapper onClose={onClose} isOpen={isOpen} maxWidth={false}>
        <Grid container justifyContent="space-between" sx={{ alignItems: 'center' }}>
          <DialogTitle variant="h2" sx={{ px: 0, py: 1 }}>
            Roles Management
          </DialogTitle>
          <IconButton onClick={onClose} sx={{ padding: 0 }}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Box mt={4}>
          <Grid container>
            <Grid item xs={12} height="60vh">
              <TableEditingContextProvider<Role, RolesFieldsContext> fieldsContext={rolesFieldsContext}>
                <RolesCreationDataGrid />
              </TableEditingContextProvider>
            </Grid>
          </Grid>
        </Box>
      </ModalWrapper>
    )
  );
};
