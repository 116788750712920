import CloseIcon from '@mui/icons-material/Close';
import { IconButton, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import classnames from 'classnames';
import { first } from 'lodash';
import React, { ReactElement, useRef, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { getFilesPromiseFromDataTransferItemList } from 'utils/fileSystem';
import { IFormValues } from './UploadSlidesForm';

interface Props {
  formProps: UseFormReturn<IFormValues, any> | any;
}

const UploadFiles = ({ formProps }: Props): ReactElement => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const theme = useTheme();

  const {
    control,
    register,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = formProps;

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = async (e: React.DragEvent<HTMLDivElement>, field: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const items: DataTransferItemList = e.dataTransfer.items;
    getFilesPromiseFromDataTransferItemList(items).then((files: File[]) => {
      if (files) {
        const newFiles: File[] = [];
        for (const curFile of files) {
          if (!field.value.some((file: File) => file.name === curFile.name)) {
            newFiles.push(curFile);
          }
        }
        field.onChange([...field.value, ...newFiles]);
      }
    });
  };

  // triggers when file is selected with click
  const handleChange = (e: any, field: any) => {
    e.preventDefault();
    if (e.target.files && first(e.target.files)) {
      field.onChange([...field.value, ...e.target.files]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <div className={classnames('upload', 'upload-files')}>
        <Controller
          name="slides"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <div
              id="form-file-upload"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={(e) => {
                handleDrop(e, field);
              }}
            >
              <input
                {...register('slides')}
                ref={inputRef}
                type="file"
                id="input-file-upload"
                multiple
                onChange={(e) => {
                  handleChange(e, field);
                }}
              />
              <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? 'drag-active' : ''}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  sx={{ color: theme.palette.grey[600], 'a:hover': { color: theme.palette.primary.dark } }}
                >
                  <span>Drag and drop your slides </span>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={onButtonClick}
                    sx={{ padding: 0, textTransform: 'lowercase', fontSize: '14px', marginLeft: '5px' }}
                  >
                    or browse
                  </Button>
                </Grid>
              </label>
            </div>
          )}
        />
        {errors.slides && <div className={classnames('validation-error slidetype')}> {errors.slides.message}</div>}

        {watch('slides') && !errors.slides && (
          <div className={classnames('uploaded-slides-name')}>
            {getValues('slides').map((slide: File, index: number) => (
              <div key={slide.name} className={classnames('uploaded-file-name')}>
                <span>{slide.name}</span>

                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => {
                    const newSlides = [...getValues('slides')];
                    newSlides.splice(index, 1);
                    setValue('slides', newSlides);
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default UploadFiles;
