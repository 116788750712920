import { Box, Typography } from '@mui/material';
import { filter, find, includes, isEmpty, keys, map, omit, pick } from 'lodash';
import React from 'react';
import { useCasesParams } from 'utils/useCasesParams';

import { useMutation } from '@tanstack/react-query';
import { bulkUpdateProcedures, bulkUpdateProcedureSlides } from 'api/procedures';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { Procedure, ProcedureResponse } from 'interfaces/procedure';
import { ProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { Slide, slidesEditableKeys } from 'interfaces/slide';
import { useSnackbar } from 'notistack';
import { DeepPartial } from 'redux';
import { BooleanParam, useQueryParam } from 'use-query-params';
import queryClient from 'utils/queryClient';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { usePendingSlides } from '../usePendingSlides';
import { ProcedureSlideBulkChangesSummary } from './ChangeSummaries';
import { useCasesAndSlidesUpdateMutationsParams } from './useCasesAndSlidesUpdateMutationParams';

export const getSlideChanges = (changes: Record<string, any>) => {
  const slideChangedField = filter(keys(changes), (key) => includes(slidesEditableKeys, key));
  return { ...pick(changes, slideChangedField) };
};

export const getProcedureChanges = (changes: Record<string, any>) => {
  const slideChangedField = filter(keys(changes), (key) => includes(slidesEditableKeys, key));
  return { ...omit(changes, slideChangedField) };
};

export const useBulkEditControl = (onSave?: (changes: { [field: string]: any }) => void) => {
  const { enqueueSnackbar } = useSnackbar();
  const confirmWithModal = useConfirmation();
  const { bulkEditMode, setBulkEditMode, bulkChanges, clearChanges, fieldsContext, hasChanges } =
    useTableEditingContext<Procedure, ProceduresFieldsContext>();

  const { encodedFilters } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });
  const { onMutateForCaseBulkUpdate, onMutateForSlideBulkUpdate, onError } = useCasesAndSlidesUpdateMutationsParams({
    action: 'bulk update',
  });
  const procedureUpdateBulkMutation = useMutation({
    mutationFn: bulkUpdateProcedures,
    onMutate: onMutateForCaseBulkUpdate,
    onError,
    onSuccess: (data, { procedureUpdates }) => {
      queryClient.invalidateQueries({ queryKey: ['procedures'], exact: false });

      queryClient.setQueriesData<ProcedureResponse>(['procedures', encodedFilters], (oldData) => {
        const newProcedures = map(oldData?.procedures, (p) => find(data, { id: p.id }) || p);
        // if we have this query in the cache, we need to update it to the new result
        return oldData ? { ...oldData, procedures: newProcedures } : oldData;
      });
      enqueueSnackbar({
        message: (
          <Box>
            <Typography variant="body1">Changes saved</Typography>
            <ProcedureSlideBulkChangesSummary bulkChanges={procedureUpdates} fieldsContext={fieldsContext} />
          </Box>
        ),
        variant: 'success',
      });
    },
  });

  const { casesParams } = useCasesParams();

  const applyProcedureBulkChanges = () => {
    const procedureChanges = getProcedureChanges(bulkChanges);

    procedureUpdateBulkMutation.mutate({
      procedureUpdates: procedureChanges as DeepPartial<Procedure>,
      ...casesParams,
    });
  };
  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);
  const { pendingSlidesQueryKey } = usePendingSlides();

  const procedureSlideUpdateBulkMutation = useMutation({
    mutationFn: bulkUpdateProcedureSlides,
    onMutate: onMutateForSlideBulkUpdate,
    onError,
    onSuccess: (data, { slideUpdates }) => {
      if (pendingSlidesMode) {
        queryClient.invalidateQueries({ queryKey: pendingSlidesQueryKey });
        queryClient.setQueryData<Slide[]>(pendingSlidesQueryKey, (oldData) =>
          map(oldData, (slide) => find(data, { id: slide.id }) || slide)
        );
      } else {
        queryClient.invalidateQueries({ queryKey: ['procedures'], exact: false });

        queryClient.setQueriesData<ProcedureResponse>(['procedures', encodedFilters], (oldData) => {
          const newProcedures: Procedure[] = map(oldData?.procedures, (procedure) => ({
            ...procedure,
            slides: map(procedure.slides, (slide) => find(data, { id: slide.id }) || slide),
          }));
          return oldData ? { ...oldData, procedures: newProcedures } : oldData;
        });
      }
      enqueueSnackbar({
        message: (
          <Box>
            <Typography variant="body1">Changes saved</Typography>
            <ProcedureSlideBulkChangesSummary bulkChanges={slideUpdates} fieldsContext={fieldsContext} />
          </Box>
        ),
        variant: 'success',
      });
    },
  });
  const applySlideBulkChanges = () =>
    procedureSlideUpdateBulkMutation.mutate({
      slideUpdates: getSlideChanges(bulkChanges) as DeepPartial<Slide>,
      ...casesParams,
    });

  const saveBulkEdits = async () => {
    if (
      hasChanges &&
      (await confirmWithModal({
        title: 'Commit Bulk Edits',
        text: (
          <Box>
            <p>Are you sure you want to commit the following changes:</p>
            <ProcedureSlideBulkChangesSummary bulkChanges={bulkChanges} fieldsContext={fieldsContext} />
          </Box>
        ),
      }))
    ) {
      if (onSave) {
        onSave(bulkChanges);
      } else {
        const slidesChanges = getSlideChanges(bulkChanges);
        if (!isEmpty(keys(slidesChanges))) {
          applySlideBulkChanges();
        }

        const procedureChanges = getProcedureChanges(bulkChanges);
        if (!isEmpty(keys(procedureChanges))) {
          applyProcedureBulkChanges();
        }
      }

      setBulkEditMode(false);
      clearChanges();
    }
  };

  const undoBulkEdits = async () => {
    if (
      !hasChanges || // no changes to undo
      (await confirmWithModal({
        title: 'Undo Bulk Edits',
        text: (
          <Box>
            <p>You have unsaved changes. Are you sure you want to undo them?</p>
            <ProcedureSlideBulkChangesSummary bulkChanges={bulkChanges} fieldsContext={fieldsContext} />
          </Box>
        ),
      }))
    ) {
      clearChanges();
      setBulkEditMode(false);
    }
  };

  return {
    bulkEditMode,
    setBulkEditMode,
    hasChanges,
    saveBulkEdits,
    undoBulkEdits,
    applyProcedureBulkChanges,
    applySlideBulkChanges,
  };
};
