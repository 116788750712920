import { stringify } from 'qs';

import { Features, ResultsMode } from 'interfaces/experimentResults';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getFeaturesByExperimentResultIdQueryKey = ({
  experimentResultId,
  labId,
}: {
  experimentResultId: number;
  labId: string;
}) => ['featureById', { experimentResultId, labId }];

export const getFeaturesByExperimentResultId = <FeatureType = Features>(experimentResultId: number, labId: string) => {
  const stringifiedLabId = stringify({ labId });
  return apiRequestHandlerPromise<FeatureType>({
    url: `features/${experimentResultId}${stringifiedLabId ? `?${stringifiedLabId}` : ''}`,
    method: 'GET',
  });
};

export const getFeatureKeysByStudyAndOrchestration = (
  studyId: string,
  orchestrationId?: string,
  includeInferredFeatures?: boolean,
  includeHiddenFeatures?: boolean,
  resultsMode: ResultsMode = ResultsMode.Published
) => {
  return apiRequestHandlerPromise<{ featuresKeys: string[] }>({
    url: `featuresKeys?${stringify({
      studyId,
      orchestrationId: orchestrationId || undefined,
      includeInferredFeatures,
      includeHiddenFeatures,
      resultsMode,
    })}`,
    method: 'GET',
  });
};
