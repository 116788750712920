import {
  AutomaticCondition,
  AutomaticConditionCell,
  AutomaticConditionLogical,
  AutomaticConditionRange,
  AutomaticConditionTag,
  CategoricalActions,
  FilterActionOption,
} from 'interfaces/automaticCondition';
import { NumberOption, OrchestrationDataType, orchestrationDataTypeNumbers } from 'interfaces/calculateFeatures';
import { CategoricalOption } from 'interfaces/postProcessingAction';
import { Dictionary, every, includes, isEmpty, isNumber, keyBy, reduce } from 'lodash';
import {
  MappingFilterOption,
  getFilterActionOptions,
  getFilterOptions,
  getMappingFilterOptions,
} from './CellConditionBuilder';

export function isRange(condition: AutomaticCondition): condition is AutomaticConditionRange {
  return condition && (condition as AutomaticConditionRange).field !== undefined;
}

export function isLogical(condition: AutomaticCondition): condition is AutomaticConditionLogical {
  return condition && (condition as AutomaticConditionLogical).operator !== undefined;
}

export function isTag(condition: AutomaticCondition): condition is AutomaticConditionTag {
  return condition && (condition as AutomaticConditionTag).tagId !== undefined;
}

export function isCell(condition: AutomaticCondition): condition is AutomaticConditionCell {
  return condition && (condition as AutomaticConditionCell).conditionType === 'cell';
}

export const isToFilterNumberTypeInvalid = (toFilter: number, filterOptions: NumberOption) => {
  if (!isEmpty(filterOptions)) {
    const { min, max } = filterOptions;
    return (isNumber(min) && toFilter < min) || (isNumber(max) && toFilter > max);
  }

  return false;
};

export const isConditionCellParamsInvalid = (
  condition: AutomaticConditionCell,
  mappingFilterOptionsById: Dictionary<MappingFilterOption>,
  filterActionOptions: FilterActionOption[],
  filterOptions: NumberOption | CategoricalOption[]
) => {
  const { mappingFilter, filterAction, toFilter } = condition;
  const filterOptionsById = keyBy(filterOptions, 'id');

  const isInvalidMappingFilter = isEmpty(mappingFilter) || isEmpty(mappingFilterOptionsById[mappingFilter]);
  const isInvalidFilterAction = isEmpty(filterAction) || isEmpty(filterActionOptions);
  const isInvalidToFilter =
    mappingFilterOptionsById[mappingFilter]?.type === OrchestrationDataType.Categorical
      ? isEmpty(toFilter) || !every(toFilter as string[] | number[], (val: string | number) => filterOptionsById?.[val])
      : includes(orchestrationDataTypeNumbers, mappingFilterOptionsById[mappingFilter]?.type)
      ? !isNumber(toFilter) ||
        isNaN(toFilter) ||
        isToFilterNumberTypeInvalid(toFilter as number, filterOptions as NumberOption)
      : false;

  return {
    isInvalidMappingFilter,
    isInvalidFilterAction,
    isInvalidToFilter,
  };
};

export const validateCondition = (
  condition: AutomaticCondition,
  cellOptions?: any,
  categoricalActions?: CategoricalActions[]
): [boolean, string] => {
  if (isRange(condition)) {
    const { field, source, min, max } = condition;
    const invalidRange =
      (min === undefined && max === undefined) || (min !== undefined && max !== undefined && min > max);
    const invalidSource = source === undefined;
    const invalidField = field === undefined || field === '';

    if (invalidRange) {
      return [false, 'Invalid range'];
    }
    if (invalidSource) {
      return [false, 'Invalid source'];
    }
    if (invalidField) {
      return [false, 'Invalid field'];
    }
  } else if (isCell(condition)) {
    const mappingFilterOptions = getMappingFilterOptions(cellOptions);
    const mappingFilterOptionsById = keyBy(mappingFilterOptions, 'id');

    const filterActionOptions = getFilterActionOptions(
      condition?.mappingFilter,
      cellOptions,
      mappingFilterOptions,
      categoricalActions
    );

    const filterOptions = getFilterOptions(condition?.mappingFilter, cellOptions, mappingFilterOptions);

    const { isInvalidMappingFilter, isInvalidFilterAction, isInvalidToFilter } = isConditionCellParamsInvalid(
      condition,
      mappingFilterOptionsById,
      filterActionOptions,
      filterOptions
    );

    if (isInvalidMappingFilter) {
      return [false, 'Invalid mapping filter'];
    }

    if (isInvalidFilterAction) {
      return [false, 'Invalid filter action'];
    }

    if (isInvalidToFilter) {
      return [false, 'Invalid to filter'];
    }
  } else if (isLogical(condition)) {
    const { operands } = condition;

    if (isEmpty(operands)) {
      return [false, 'No conditions'];
    }

    const result = reduce<AutomaticCondition, [boolean, string]>(
      operands,
      (acc, operand) => {
        const [isValid, message] = validateCondition(operand, cellOptions, categoricalActions);
        if (!isValid) {
          return [false, message];
        }
        return acc;
      },
      [true, '']
    );

    return result;
  }
  return [true, ''];
};
