import { useQuery } from '@tanstack/react-query';
import { getAllTaxonomies, getAllTaxonomiesForPath } from 'api/taxonomy';
import { Taxonomy } from 'interfaces/taxonomy';

export const taxonomyQueryKey = ['taxonomy'];
export const cellTaxonomy = 'tissue.cell';

const useTaxonomy = (parent: string = '') => {
  const query = useQuery<Taxonomy[]>({
    queryKey: parent === '' ? taxonomyQueryKey : [...taxonomyQueryKey, parent],
    queryFn: () => (parent === '' ? getAllTaxonomies() : getAllTaxonomiesForPath(parent)),
  });

  return query;
};

export default useTaxonomy;
