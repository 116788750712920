import TuneIcon from '@mui/icons-material/Tune';
import { IconButton, MenuItem, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { Study } from 'interfaces/study';
import React, { FunctionComponent, useState } from 'react';
import StudySettingsModal from './StudySettingsModal';

interface StudySettingsControlProps {
  study: Study;
  menuItem?: boolean;
}

const StudySettingsControl: FunctionComponent<React.PropsWithChildren<StudySettingsControlProps>> = ({
  study,
  menuItem,
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    e.stopPropagation();
  };

  return (
    <>
      {menuItem ? (
        <MenuItem onClick={handleClick}>
          <Box>
            <IconButton>
              <TuneIcon />
            </IconButton>
          </Box>
          Settings
        </MenuItem>
      ) : (
        <Tooltip title="Settings" placement="top">
          <Box>
            <IconButton onClick={handleClick}>
              <TuneIcon />
            </IconButton>
          </Box>
        </Tooltip>
      )}
      <StudySettingsModal
        open={open}
        onClose={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
        study={study}
      />
    </>
  );
};

export default StudySettingsControl;
