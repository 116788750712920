import { Color } from '@deck.gl/core/typed';
import { join, keys, last, map, slice } from 'lodash';

import {
  DTYPE_VALUES,
  DTYPE_VALUES_RED_ONLY,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/constants';
import { PhotometricInterpretation } from '../types';

/*
 * Helper method to determine whether pixel data is interleaved or not.
 * > isInterleaved([1, 24, 24]) === false;
 * > isInterleaved([1, 24, 24, 3]) === true;
 */
export function isInterleaved(shape: number[]) {
  const lastDimSize = last(shape);
  return lastDimSize === 3 || lastDimSize === 4;
}

/**
 * (Safely) get GL values for associated dtype.
 */
export function getDtypeValues(dtype: keyof typeof DTYPE_VALUES, redOnlyOnCPU?: boolean) {
  const source = redOnlyOnCPU ? DTYPE_VALUES_RED_ONLY : DTYPE_VALUES;
  const values = source[dtype];
  if (!values) {
    const valid = keys(source);
    throw Error(`Dtype not supported, got ${dtype}. Must be one of ${valid}.`);
  }
  return values;
}

export const getPhotometricInterpretationShader = (
  photometricInterpretation: PhotometricInterpretation,
  transparentColorInHook: Color | undefined
) => {
  const useTransparentColor = transparentColorInHook ? 'true' : 'false';
  const transparentColorVector = `vec3(${join(
    // Splice transparent color to 3 elements to match the vector size.
    map(slice(transparentColorInHook || [0, 0, 0], 0, 3), (i) => String(i / 255)),
    ','
  )})`;
  switch (photometricInterpretation) {
    case PhotometricInterpretation.RGB:
      return `color[3] = (${useTransparentColor} && (color.rgb == ${transparentColorVector})) ? 0.0 : color.a;`;
    case PhotometricInterpretation.WhiteIsZero:
      return `\
          float value = 1.0 - (color.r / 256.0);
          color = vec4(value, value, value, (${useTransparentColor} && vec3(value, value, value) == ${transparentColorVector}) ? 0.0 : color.a);
        `;
    case PhotometricInterpretation.BlackIsZero:
      return `\
          float value = (color.r / 256.0);
          color = vec4(value, value, value, (${useTransparentColor} && vec3(value, value, value) == ${transparentColorVector}) ? 0.0 : color.a);
        `;
    case PhotometricInterpretation.YCbCr:
      // We need to use an epsilon because the conversion to RGB is not perfect.
      return `\
          float y = color[0];
          float cb = color[1];
          float cr = color[2];
          color[0] = (y + (1.40200 * (cr - .5)));
          color[1] = (y - (0.34414 * (cb - .5)) - (0.71414 * (cr - .5)));
          color[2] = (y + (1.77200 * (cb - .5)));
          color[3] = (${useTransparentColor} && distance(color.rgb, ${transparentColorVector}) < 0.01) ? 0.0 : color.a;
        `;
    default:
      console.error(
        'Unsupported photometric interpretation or none provided.  No transformation will be done to image data'
      );
      return '';
  }
};

export const getTransparentColor = (photometricInterpretation: PhotometricInterpretation): Color => {
  switch (photometricInterpretation) {
    case PhotometricInterpretation.RGB:
      return [0, 0, 0];
    case PhotometricInterpretation.WhiteIsZero:
      return [255, 255, 255];
    case PhotometricInterpretation.BlackIsZero:
      return [0, 0, 0];
    case PhotometricInterpretation.YCbCr:
      return [16, 128, 128];
    default:
      console.error(
        'Unsupported photometric interpretation or none provided.  No transformation will be done to image data'
      );
      return [0, 0, 0];
  }
};
