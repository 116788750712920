import {
  differenceBy,
  filter,
  find,
  findIndex,
  forEach,
  isEmpty,
  isEqual,
  map,
  merge,
  range,
  replace,
  reverse,
  some,
} from 'lodash';
import { TiledImageOptions, Viewer as osdViewerInterface } from 'openseadragon';

import { onFailedAddTiledImage } from '../SlidesViewer/Viewer';
import { OSDOverlayProps } from './types';

const addOverlaysToViewer = (
  newOverlays: { overlay: OSDOverlayProps; index?: number }[],
  viewer: osdViewerInterface,
  additionalOptions?: any,
  onSuccess?: () => void
) => {
  if (isEmpty(newOverlays)) {
    if (onSuccess) onSuccess();
    return;
  }
  let countSuccess = 0;
  forEach(newOverlays, ({ overlay, index }) => {
    const options: TiledImageOptions = {
      // If the index is for a layer that already exists, replace it
      replace: viewer.world.getItemCount() > index,
      index,
      tileSource: replace(overlay.url, 'imageapi.nucleaimd.com', 'image.nucleai.cloud'),
      crossOriginPolicy: 'Anonymous',
      opacity: overlay.show ? overlay.opacity / 100 : 0,
      error: (event) => onFailedAddTiledImage(event, viewer),
      success() {
        countSuccess += 1;
        if (onSuccess && countSuccess === newOverlays.length) {
          onSuccess();
        }
      },
    };

    if (additionalOptions) merge(options, additionalOptions);

    if (options?.tileSource) {
      viewer.addTiledImage(options);
    } else {
      console.warn('Missing tileSource', { overlay, options });
    }
  });
};

const checkHeatmapsLayersInSync = (
  overlays: OSDOverlayProps[],
  viewer: osdViewerInterface,
  indentationIndex: number,
  prevIndentationIndex: number
) => {
  const layerCount = viewer.world.getItemCount();
  return (
    (prevIndentationIndex === indentationIndex &&
      (layerCount < overlays.length + indentationIndex ||
        (indentationIndex > 0 && layerCount !== overlays.length + indentationIndex))) ||
    some(
      map(overlays, (overlay) => {
        if (overlay.type === 'channel') {
          // Channel overlays are removed and added, which is expected
          return false;
        }
        const tiledImage = viewer.world.getItemAt(overlay.overlayIndex);
        if (!tiledImage) return true;
        const sourceUrl = replace((tiledImage.source as { tilesUrl?: string })?.tilesUrl, /.*\/\/.*nucleai.+?\//, '');
        const overlaySource = replace(overlay.url, /.*\/\/.*nucleai.+?\//, '');
        const overlayTiles = replace(overlaySource, /.dzi$/, '_files/');
        if (sourceUrl !== overlayTiles) {
          console.warn('Overlays out of sync, replacing all of them', {
            layerCount,
            overlaysNum: overlays.length,
            indentationIndex,
            sourceUrl,
            overlayTiles,
            tiledImage,
          });
        }
        return sourceUrl !== overlayTiles;
      })
    )
  );
};

// TODO: if this keeps being buggy, try to refactor to pass all overlays
// together rather than channels and heatmaps separately
export const handleOverlaysChanges = ({
  overlays,
  prevOverlays,
  viewer,
  indentationIndex,
  prevIndentationIndex,
  onDone,
  onDoneNothingChange,
  onColorMapChange,
  additionalOptions,
}: {
  overlays: OSDOverlayProps[];
  prevOverlays: OSDOverlayProps[];
  viewer: osdViewerInterface;
  indentationIndex: number;
  prevIndentationIndex: number;
  onDone: (overlays: OSDOverlayProps[], forceRedraw?: boolean) => void;
  onDoneNothingChange?: () => void;
  onColorMapChange?: (overlays: OSDOverlayProps[]) => void;
  additionalOptions?: Partial<TiledImageOptions>;
}) => {
  const isUpdatingHeatmaps = some(overlays, { type: 'heatmap' });
  const worldOutOfSync =
    isUpdatingHeatmaps && checkHeatmapsLayersInSync(overlays, viewer, indentationIndex, prevIndentationIndex);
  if (worldOutOfSync) {
    const newOverlays = map(overlays, (newOverlay) => {
      return {
        overlay: newOverlay,
        index: newOverlay.overlayIndex,
      };
    });

    addOverlaysToViewer(newOverlays, viewer, additionalOptions, () => {
      const layerCount = viewer.world.getItemCount();
      forEach(reverse(range(overlays.length + indentationIndex, layerCount + 1)), (layerIndex) => {
        const itemToRemove = viewer.world.getItemAt(layerIndex);
        if (itemToRemove) {
          viewer.world.removeItem(itemToRemove);
        }
      });
      viewer.forceRedraw();
      onDone(overlays);
    });
  } else if (!isEqual(overlays, prevOverlays)) {
    const added = differenceBy(overlays, prevOverlays, ({ id, overlayIndex }) => `${overlayIndex}-${id}`);
    const removed = differenceBy(prevOverlays, overlays, ({ id, overlayIndex }) => `${overlayIndex}-${id}`);

    if (!isEmpty(added)) {
      const newOverlays = map(added, (newOverlay) => {
        return {
          overlay: newOverlay,
          index: newOverlay.overlayIndex,
        };
      });

      addOverlaysToViewer(newOverlays, viewer, additionalOptions, () => {
        const indicesToRemove = filter(map(removed, 'overlayIndex'), (index) => !find(added, { overlayIndex: index }));
        forEach(
          indicesToRemove.sort((a, b) => b - a),
          (removeItemIndex) => {
            const itemToRemove = viewer.world.getItemAt(removeItemIndex);
            if (itemToRemove) {
              viewer.world.removeItem(itemToRemove);
            }
          }
        );
        onDone(overlays);
      });
    } else if (!isEmpty(removed)) {
      const indicesToRemove = map(removed, (remove) => findIndex(prevOverlays, remove) + prevIndentationIndex);
      forEach(
        indicesToRemove.sort((a, b) => b - a),
        (removeItemIndex) => {
          const itemToRemove = viewer.world.getItemAt(removeItemIndex);

          if (itemToRemove) {
            viewer.world.removeItem(itemToRemove);
          }
        }
      );
      onDone(overlays);
    } else {
      let isColorMapChanged = false;

      let forceRedraw = false;
      overlays?.forEach((overlay, index) => {
        const prevOverlay = find(prevOverlays, (currentPrevOverlay) => currentPrevOverlay.id === overlay.id);

        if (overlay.show !== prevOverlay.show || overlay.opacity !== prevOverlay.opacity) {
          let viewerItem = viewer.world.getItemAt(index + indentationIndex);

          if (viewerItem) {
            viewerItem.setOpacity(overlay.show ? overlay.opacity / 100 : 0);
            forceRedraw = true;
          } else {
            // this to prevent a crash during demo
            console.warn('Viewer item does not exist');
          }
        }

        if (
          overlay.range !== prevOverlay.range ||
          overlay.color !== prevOverlay.color ||
          overlay.gamma !== prevOverlay.gamma
        ) {
          isColorMapChanged = true;
        }
      });

      if (onColorMapChange && isColorMapChanged) {
        onColorMapChange.call(this, overlays);
      } else {
        onDone(overlays, forceRedraw);
      }
    }
  } else {
    if (onDoneNothingChange) onDoneNothingChange();
    else onDone(overlays);
  }
};
