import DoneIcon from '@mui/icons-material/Done';
import RuleIcon from '@mui/icons-material/Rule';
import VerifiedIcon from '@mui/icons-material/Verified';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ResultsMode } from 'interfaces/experimentResults';
import { map, omit } from 'lodash';
import React from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';

interface ResultsModeOption {
  display: string;
  icon: any;
  value: ResultsMode;
}

const resultsModeOptions: ResultsModeOption[] = [
  {
    display: 'Published',
    icon: VerifiedIcon,
    value: ResultsMode.Published,
  },
  {
    display: 'Approved',
    icon: DoneIcon,
    value: ResultsMode.InternallyApproved,
  },
  {
    display: 'Manual',
    icon: RuleIcon,
    value: ResultsMode.Manual,
  },
];

const ResultsModeDropdown: React.FunctionComponent = () => {
  const [resultsMode, setResultsMode] = useQueryParam('resultsMode');
  const [filterParam, setFilterParam] = useQueryParam('filters', JsonParam);

  const handleResultsModeChange = (event: SelectChangeEvent) => {
    const newResultsMode = event.target.value;
    if (newResultsMode !== ResultsMode.Manual) {
      setFilterParam(
        {
          ...omit(filterParam, 'orchestrationId'),
        } || null,
        'replaceIn'
      );
    }
    if (newResultsMode !== resultsMode) {
      setResultsMode(newResultsMode, 'replaceIn');
    }
  };

  return (
    <Select
      defaultValue={ResultsMode.Published}
      onChange={handleResultsModeChange}
      value={resultsMode}
      variant="standard"
      label="Result Mode"
      disableUnderline
      SelectDisplayProps={{
        style: { display: 'flex', alignItems: 'center' },
      }}
    >
      {map(resultsModeOptions, (mode) => (
        <MenuItem key={mode.value} value={mode.value} sx={{ paddingX: 2, paddingY: 1, width: 170 }}>
          <ListItem sx={{ padding: 0 }}>
            <ListItemIcon sx={{ minWidth: 30, color: 'inherit' }}>
              <mode.icon size="small" />
            </ListItemIcon>
            <ListItemText sx={{ paddingX: 0.5 }} primary={mode.display} />
          </ListItem>
        </MenuItem>
      ))}
    </Select>
  );
};

export default ResultsModeDropdown;
