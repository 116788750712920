import { ThemeProvider } from '@mui/material';
import React, { createContext, FC, ReactNode, useContext, useMemo } from 'react';
import useLocalStorage from 'use-local-storage';
import { darkTheme, lightTheme } from './theme/theme';

interface IColorModeContext {
  toggleColorMode: () => void;
  mode: 'light' | 'dark';
}

export const ColorModeContext = createContext<IColorModeContext>({
  toggleColorMode: () => {},
  mode: 'light',
});

interface Props {
  children?: ReactNode;
}

export const ColorModeContextProvider: FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const [mode, setMode] = useLocalStorage<'light' | 'dark'>('colorMode', 'light');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: string) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      mode,
    }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={colorMode.mode === 'light' ? lightTheme : darkTheme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export const useColorMode = () => useContext(ColorModeContext);
