import { Divider, Grid, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { useSignals } from '@preact/signals-react/runtime';
import { find, isEmpty, map, sortBy } from 'lodash';
import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import Loader from 'components/Loader';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { filterSlidesWithResults } from 'utils/features';
import { useUiSettings } from 'utils/queryHooks/uiConstantsHooks';
import { updateZoomFactorFromSlide } from '../zoomFactor';
import SlideInfobar from './SlideInfobar/SlideInfobar';

interface Props {
  slidesWithChannelsAndResults: SlideWithChannelAndResults[];
  openDrawer: boolean;
  isPlaceholderData: boolean;
  isLoadingCaseData: boolean;
  drawerWidth: string;
}

const Infobar: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  slidesWithChannelsAndResults,
  openDrawer,
  isPlaceholderData,
  isLoadingCaseData,
  drawerWidth,
}) => {
  useSignals();
  const { uiSettings } = useUiSettings();

  const primarySlide = find(slidesWithChannelsAndResults, (slide) => Boolean(slide));

  const [useDeckGL] = useQueryParam('useDeckGL', BooleanParam);

  const doesCaseHaveMultipleStainResults = filterSlidesWithResults(slidesWithChannelsAndResults)?.length > 1;

  const slidesNotYetLoaded = (isPlaceholderData || isLoadingCaseData) && isEmpty(slidesWithChannelsAndResults);

  return (
    <Drawer
      // Temporary drawer covers content, but doesn't take space when closed
      // Persistent drawer pushes content aside, but takes space when closed
      variant={openDrawer ? 'persistent' : 'temporary'}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        zIndex: 100,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          paddingTop: 9,
        },
      }}
      open={openDrawer}
      onClose={!useDeckGL ? () => updateZoomFactorFromSlide(primarySlide, useDeckGL) : undefined}
    >
      <Grid container direction="column" spacing={1}>
        {primarySlide?.biopsyType && (
          <Grid item container direction="row" justifyContent="space-between">
            <Grid item px={1}>
              <Typography variant="body1">
                {uiSettings.webappBiopsyConfig[primarySlide?.biopsyType]?.displayName || primarySlide?.biopsyType}
              </Typography>
            </Grid>
            {primarySlide?.stainingSubType && (
              <Grid item px={1}>
                <Typography variant="body1">Clone: {primarySlide?.stainingSubType}</Typography>
              </Grid>
            )}
          </Grid>
        )}

        <Grid item container direction="column" spacing={3}>
          {!openDrawer || !uiSettings || slidesNotYetLoaded ? (
            <Grid item>
              <Loader />
            </Grid>
          ) : (
            map(sortBy(slidesWithChannelsAndResults, 'viewerIndex'), (slide) => {
              return (
                <Grid item container direction="column" key={`${slide.id}-${slide.viewerIndex}`}>
                  <SlideInfobar
                    slide={slide}
                    isPlaceholderData={isPlaceholderData}
                    isLoadingCaseData={isLoadingCaseData}
                    doesCaseHaveMultipleStainResults={doesCaseHaveMultipleStainResults}
                  />
                  <Divider sx={{ borderBottomWidth: 3 }} />
                </Grid>
              );
            })
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default Infobar;
