import { addSlideTag, removeSlideTag } from 'api/slides';
import { getStudyProcedureQueryKey } from 'api/study';
import { Procedure, ProcedureResponse } from 'interfaces/procedure';
import { SlideTag } from 'interfaces/slideTag';
import { SlideTagAssignment } from 'interfaces/slideTagAssignment';
import { cloneDeep, find, findIndex, forEach, includes, map, reject, set } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import queryClient from 'utils/queryClient';
import useSlideTagOptions from 'utils/queryHooks/useSlideTagOptions';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { getSlideTagsQueryKey } from './useQuerySlideTagsById';

export const cloneCaseWithNewSlideTags = (
  caseData: Procedure,
  slideId: string,
  newSlideTags: Pick<SlideTag, 'id' | 'tagValue'>[]
) => {
  const slideIndex = findIndex(caseData.slides, { id: slideId });
  return set(cloneDeep(caseData), `slides[${slideIndex}].tags`, cloneDeep(newSlideTags));
};

export const cloneCaseWithAddedSlideTag = (
  caseData: Procedure,
  slideId: string,
  addedSlideTag: Pick<SlideTag, 'id' | 'tagValue'>
) => {
  const slideIndex = findIndex(caseData.slides, { id: slideId });
  const slideTags = map(caseData.slides[slideIndex].slideTagAssignments, 'slideMetadataTag') || [];
  return cloneCaseWithNewSlideTags(caseData, slideId, [...slideTags, addedSlideTag]);
};

export const cloneCaseWithRemovedSlideTag = (caseData: Procedure, slideId: string, tagIdToRemove: string) => {
  const slideIndex = findIndex(caseData.slides, { id: slideId });
  return cloneCaseWithNewSlideTags(
    caseData,
    slideId,
    reject(map(caseData.slides[slideIndex].slideTagAssignments, 'slideMetadataTag'), { id: tagIdToRemove })
  );
};

export const useToggleSlideTag = ({
  currentCase,
  orchestrationId,
  slideTagIds,
  refetchProcedure,
}: {
  currentCase: Procedure;
  orchestrationId?: string;
  slideTagIds: string[];
  refetchProcedure: () => void;
}) => {
  const { id: caseId, studyId } = currentCase || {};
  const { labId } = useCurrentLabId();
  const [slideIdForReviewing] = useQueryParam('slideIdForReviewing', StringParam);

  const { data: slideTagOptions } = useSlideTagOptions();

  const { encodedFilters: encodedFiltersNoQaSet, queryParams } = useEncodedFilters();
  const { encodedFilters: encodedFiltersQaOnly } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });
  const procedureQueryKey = getStudyProcedureQueryKey(queryParams?.filters?.studyId, currentCase.id, queryParams);

  const { mutate: addSlideTagMutation } = useMutationWithErrorSnackbar({
    mutationFn: ({ id: tagId, perStudy, perExperimentResult }: SlideTag) =>
      slideIdForReviewing &&
      addSlideTag(
        slideIdForReviewing,
        tagId,
        labId,
        perStudy ? studyId : undefined,
        perExperimentResult ? orchestrationId : undefined
      ),
    mutationDescription: 'Add slide tag',
    onMutate: ({ id: tagId, perStudy, perExperimentResult }) => {
      const specificQueryKey = getSlideTagsQueryKey({ slideId: slideIdForReviewing, labId });
      // Cancel any outgoing refetch requests (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(['slideTags', slideIdForReviewing]);
      queryClient.cancelQueries(specificQueryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(specificQueryKey, (oldTags: SlideTagAssignment[]) => {
        const newTag: SlideTagAssignment = {
          tagId,
          studyId: perStudy ? studyId : undefined,
          orchestrationId: perExperimentResult ? orchestrationId : undefined,
        };
        return find(oldTags, (tag) => {
          const isSameTag = tag.tagId === newTag.tagId;
          const isSameStudy = tag.studyId === newTag.studyId || (!tag.studyId && !newTag.studyId);
          const isSameOrchestration =
            tag.orchestrationId === newTag.orchestrationId || (!tag.orchestrationId && !newTag.orchestrationId);
          return isSameTag && isSameStudy && isSameOrchestration;
        })
          ? oldTags
          : [...(oldTags || []), newTag];
      });

      queryClient.setQueryData(procedureQueryKey, (res?: { procedure: Procedure }) => {
        if (!res) {
          return res;
        }
        const procedure = res.procedure;
        return {
          procedure: cloneCaseWithAddedSlideTag(procedure, slideIdForReviewing, find(slideTagOptions, { id: tagId })),
        };
      });

      forEach([encodedFiltersNoQaSet, encodedFiltersQaOnly], (encodedFilters) =>
        queryClient.setQueryData<ProcedureResponse>(['procedures', encodedFilters], (oldRes) => {
          const caseIndex = findIndex(oldRes?.procedures, { id: caseId });
          if (caseIndex === -1) {
            return oldRes;
          }
          return set(
            cloneDeep(oldRes),
            `procedures[${caseIndex}]`,
            cloneCaseWithAddedSlideTag(
              oldRes.procedures[caseIndex],
              slideIdForReviewing,
              find(slideTagOptions, { id: tagId })
            )
          );
        })
      );
    },
    onSuccess: () => {
      refetchProcedure();
      forEach([encodedFiltersNoQaSet, encodedFiltersQaOnly], (encodedFilters) =>
        queryClient.resetQueries(['procedures', encodedFilters])
      );
      enqueueSnackbar('Slide tag added', { variant: 'success' });
    },
    onError(_, slideTagToAdd) {
      // if the action failed, it means the tag was not added, so we need to remove it
      const { id: tagId, perStudy, perExperimentResult } = slideTagToAdd;
      const specificQueryKey = getSlideTagsQueryKey({ slideId: slideIdForReviewing, labId });
      queryClient.setQueriesData(specificQueryKey, (oldTags: SlideTagAssignment[]) => {
        return reject(oldTags, (tag) => {
          const isSameTag = tagId === tag.tagId;
          const isSameStudy = studyId === tag.studyId || (!tag.studyId && !perStudy);
          const isSameOrchestration =
            orchestrationId === tag.orchestrationId || (!tag.orchestrationId && !perExperimentResult);
          return isSameTag && isSameStudy && isSameOrchestration;
        });
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['slideTags', slideIdForReviewing]);
      queryClient.invalidateQueries({ queryKey: ['procedure', caseId], exact: false });
    },
  });

  const { mutate: removeSlideTagMutation } = useMutationWithErrorSnackbar({
    mutationFn: ({ id: tagId, perStudy, perExperimentResult }: SlideTag) =>
      slideIdForReviewing &&
      removeSlideTag(
        slideIdForReviewing,
        tagId,
        labId,
        perStudy ? studyId : undefined,
        perExperimentResult ? orchestrationId : undefined
      ),
    mutationDescription: 'Remove slide tag',
    onMutate: ({ id: tagId, perStudy, perExperimentResult }) => {
      const specificQueryKey = getSlideTagsQueryKey({ slideId: slideIdForReviewing, labId });

      // Cancel any outgoing refetch requests (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(['slideTags', slideIdForReviewing]);
      queryClient.cancelQueries(specificQueryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(specificQueryKey, (oldTags: SlideTagAssignment[]) => {
        const tagsAfterRemoval = reject(oldTags, (tag) => {
          const isSameTag = tagId === tag.tagId;
          const isSameStudy = studyId === tag.studyId || (!tag.studyId && !perStudy);
          const isSameOrchestration =
            orchestrationId === tag.orchestrationId || (!tag.orchestrationId && !perExperimentResult);
          return isSameTag && isSameStudy && isSameOrchestration;
        });
        return tagsAfterRemoval;
      });

      queryClient.setQueryData(procedureQueryKey, (res?: { procedure: Procedure }) => {
        if (!res) {
          return res;
        }
        const procedure = res.procedure;
        return {
          procedure: cloneCaseWithRemovedSlideTag(procedure, slideIdForReviewing, tagId),
        };
      });
      forEach([encodedFiltersNoQaSet, encodedFiltersQaOnly], (encodedFilters) =>
        queryClient.setQueryData<ProcedureResponse>(['procedures', encodedFilters], (oldRes) => {
          const caseIndex = findIndex(oldRes?.procedures, { id: caseId });
          if (caseIndex === -1) {
            return oldRes;
          }
          return set(
            cloneDeep(oldRes),
            `procedures[${caseIndex}]`,
            cloneCaseWithRemovedSlideTag(oldRes.procedures[caseIndex], slideIdForReviewing, tagId)
          );
        })
      );
    },
    onSuccess: () => {
      refetchProcedure();
      forEach([encodedFiltersNoQaSet, encodedFiltersQaOnly], (encodedFilters) =>
        queryClient.resetQueries(['procedures', encodedFilters])
      );
      enqueueSnackbar('Slide tag removed', { variant: 'success' });
    },
    onError(_, slideTagToRemove) {
      // if the action failed, it means the tag was not removed, so we need to add it back
      const { id: tagId, perStudy, perExperimentResult } = slideTagToRemove;
      const specificQueryKey = getSlideTagsQueryKey({ slideId: slideIdForReviewing, labId });
      queryClient.setQueriesData(specificQueryKey, (oldTags: SlideTagAssignment[]) => [
        ...oldTags,
        { tagId, studyId: perStudy ? studyId : null, orchestrationId: perExperimentResult ? orchestrationId : null },
      ]);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['slideTags', slideIdForReviewing]);
      queryClient.invalidateQueries({ queryKey: ['procedure', caseId], exact: false });
    },
  });

  return React.useCallback(
    (tag: SlideTag) => {
      const isSelected = includes(slideTagIds, tag.id);
      const slideTagOperation = isSelected ? removeSlideTagMutation : addSlideTagMutation;
      if (!orchestrationId && tag.perExperimentResult) {
        console.error(`Cannot tag slide with experiment result tag ${tag.tagValue} without an orchestrationId`);
        return;
      }
      if (!studyId && tag.perStudy) {
        console.error(`Cannot tag slide with study tag ${tag.tagValue} without a studyId`);
        return;
      }

      slideTagOperation(tag);
    },
    [slideTagIds, studyId, orchestrationId, addSlideTagMutation, removeSlideTagMutation]
  );
};
