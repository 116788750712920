import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteValue,
  CircularProgress,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getAnnotationAssignments } from 'api/annotationAssignments';
import { AnnotationAssignment } from 'interfaces/annotation';
import { find, map } from 'lodash';
import React, { ReactElement } from 'react';
import { StringParam } from 'use-query-params';
import { casesOptions, CasesParams, casesSchema } from 'utils/useCasesParams';
import { encodeQueryParamsUsingSchema } from 'utils/useEncodedFilters';

export interface AnnotationAssignmentAutocompleteProps<Multiple extends boolean | undefined = false>
  extends Omit<
    AutocompleteProps<AnnotationAssignment, Multiple, false, false>,
    'options' | 'renderInput' | 'isOptionEqualToValue' | 'loading' | 'value'
  > {
  casesParams: CasesParams;
  slideStainType: string;
  selectedValue: Multiple extends true ? Array<number> : number; // value is the id/ids of the annotation assignment(s)
  renderInput?: AutocompleteProps<AnnotationAssignment, Multiple, false, false>['renderInput'];
  textFieldProps?: TextFieldProps;
}

export function AnnotationAssignmentAutocomplete<Multiple extends boolean | undefined = false>(
  props: AnnotationAssignmentAutocompleteProps<Multiple>
): ReactElement {
  const {
    casesParams,
    selectedValue,
    onChange,
    multiple,
    renderInput,
    slideStainType,
    textFieldProps,
    disabled,
    ...autocompleteProps
  } = props;

  const encodedAnnotationAssignmentsParams = encodeQueryParamsUsingSchema(
    { ...casesParams, slideStainType },
    {
      ...casesSchema,
      slideStainType: StringParam,
    },
    casesOptions
  );

  const { data: annotationAssignments, isLoading: isAssignmentIdsLoading } = useQuery({
    queryKey: ['annotationAssignments', encodedAnnotationAssignmentsParams],
    queryFn: () => getAnnotationAssignments(encodedAnnotationAssignmentsParams),
    enabled: !(disabled ?? false),
  });

  function convertIdsToAssignmentOption(
    value: Multiple extends true ? number[] : number,
    assignments: AnnotationAssignment[]
  ): AutocompleteValue<AnnotationAssignment, Multiple, false, false> {
    return value
      ? ((Array.isArray(value)
          ? map(value, (assignmentId) => find(assignments, { annotationAssignmentId: assignmentId }))
          : find(assignments, { annotationAssignmentId: value })) as any)
      : multiple
      ? []
      : null;
  }

  const assignmentValue: AutocompleteValue<AnnotationAssignment, Multiple, false, false> = convertIdsToAssignmentOption(
    selectedValue,
    annotationAssignments
  );

  const options = annotationAssignments || [];

  return (
    <Autocomplete<AnnotationAssignment, Multiple, false, false>
      multiple={multiple}
      value={assignmentValue}
      loading={isAssignmentIdsLoading}
      disabled={disabled}
      disableCloseOnSelect
      options={options}
      isOptionEqualToValue={(option, val) => option.annotationAssignmentId === val?.annotationAssignmentId}
      getOptionLabel={(option: AnnotationAssignment) => `${option?.annotationAssignmentId} - ${option?.name}`}
      onChange={onChange}
      renderInput={
        renderInput
          ? renderInput
          : (params) => (
              <TextField
                {...params}
                label={`Annotation Assignment${multiple ? 's' : ''}`}
                InputProps={{
                  ...params.InputProps,

                  endAdornment: (
                    <React.Fragment>
                      {!disabled && isAssignmentIdsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                {...textFieldProps}
              />
            )
      }
      {...autocompleteProps}
    />
  );
}
