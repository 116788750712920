export const UPDATE_SLIDE_RESULTS = 'procedureList.UPDATE_SLIDE_RESULTS';
export const UPDATE_PROCEDURE = 'procedureList.UPDATE_PROCEDURE';
// networks
export const START_NETWORK = 'NETWORK_START';
export const END_NETWORK = 'NETWORK_END';

// api
export const API_REQUEST = 'API_REQUEST';

// backend
export const PROCEDURE_UPDATE_API_FAILED = 'procedureList.PROCEDURE_FAILED_ON_API_UPDATE';

// auth
export const TRY_AUTHENTICATE_FROM_LOCAL_STORAGE = 'auth.try_authenticate_from_local_storage';
export const FINISHED_TRY_AUTHENTICATE_FROM_LOCAL_STORAGE = 'auth.finished_try_authenticate_from_local_storage';
export const LOGIN_SUCCESS = 'auth.login_success';
export const LOGOUT = 'auth.logout';
export const RENEW_TOKEN = 'auth.renew_token';

// labs
export const SET_LABS = 'procedureList.SET_LABS';

// procedure
export const SET_PROCEDURE = 'procedureList.SET_PROCEDURE';
export const SET_PROCEDURE_DURING_LOAD = 'procedureList.SET_PROCEDURE_DURING_LOAD';
