import type { Layer } from '@deck.gl/core/typed';
import { LayerExtension } from '@deck.gl/core/typed';
import { ShaderModule } from '@deck.gl/core/typed/types/types';
import filterColor from './filterColor';
import fsModule from './fsModule';

export type CubicDeconvExtensionProps = {
  /**
   * Will load 7 * 3x3 matrices of deconvolution coefficients (representing the full 20x3 coefficients of cubic polynomial for rgb).
   * We use 3x3 matrices instead of 4x3 matrices to simplify the shader code (since the 4x3 matrices don't compile for some reason).
   * */
  coefficients: [Float32Array, Float32Array, Float32Array, Float32Array, Float32Array, Float32Array, Float32Array];
  intercept?: Float32Array;
};

const defaultIntercept = Float32Array.from([0, 0, 0]);

const shaders = {
  modules: [
    {
      name: 'stain-devonv-module',
      fs: fsModule,
    } as ShaderModule,
  ],
  inject: {
    // Test by discarding all pixels
    // 'fs:DECKGL_FILTER_COLOR': `if (rgba.a > 0.0) discard;`,
    'fs:DECKGL_FILTER_COLOR': filterColor,
  },
};

/** Perform staining deconv on a slide. */
export default class CubicDeconvExtension extends LayerExtension<CubicDeconvExtensionProps> {
  static extensionName = 'CubicDeconvExtension';

  getShaders(this: Layer<CubicDeconvExtensionProps>) {
    return shaders;
  }

  draw(this: Layer<Required<CubicDeconvExtensionProps>>, { uniforms }: any): void {
    uniforms.coefficients0 = this.props.coefficients[0];
    uniforms.coefficients1 = this.props.coefficients[1];
    uniforms.coefficients2 = this.props.coefficients[2];
    uniforms.coefficients3 = this.props.coefficients[3];
    uniforms.coefficients4 = this.props.coefficients[4];
    uniforms.coefficients5 = this.props.coefficients[5];
    uniforms.coefficients6 = this.props.coefficients[6];
    uniforms.intercept = this.props.intercept || defaultIntercept;
  }
}
