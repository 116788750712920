import React from 'react';

import { OrthographicMapViewState } from 'components/Procedure/SlidesViewer/DeckGLViewer/OrthographicMapview';
import { getScalebarSizeAndTextForMetric } from 'utils/OSD/OSDScalebarPlugin';

import { Tooltip } from '@mui/material';
import { useClipboard } from 'use-clipboard-copy';
import './DeckGLScaleIndicator.scss';

export interface DeckGLScaleIndicatorProps {
  viewState: OrthographicMapViewState;
  maxResolution: number;
}

export const DeckGLScaleIndicator: React.FC<DeckGLScaleIndicatorProps> = ({ viewState, maxResolution }) => {
  const deckGLZoomLevel = viewState.zoom as number;
  const zoom = 2 ** deckGLZoomLevel;
  const ppm = (1000000 / maxResolution) * zoom;
  const sizeAndText = getScalebarSizeAndTextForMetric(ppm, 50, 'm');
  const clipboard = useClipboard({ copiedTimeout: 1000 });

  return (
    <Tooltip title={clipboard.copied ? 'Copied!' : 'Click to copy'} placement="top">
      <div
        className="deckgl-scale-indicator"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          clipboard.copy(sizeAndText.text);
        }}
      >
        <div className="deckgl-scalebar-scale" style={{ width: `${sizeAndText.size}px` }} />
        <div className="deckgl-scalebar-name">{sizeAndText.text}</div>
      </div>
    </Tooltip>
  );
};
