import { PERMISSIONS, ROLE } from 'interfaces/user';
import { includes, some } from 'lodash';
import { RootState } from 'redux/store';
import { Auth0Profile } from './auth/AuthService';

// TODO: remove this function after all users are migrated to new auth
export const hasPermissionLegacy = (permissionName: PERMISSIONS, auth: RootState['auth']) => {
  if (!auth) {
    return false;
  }

  const roles = [ROLE.DEMO_USER];

  return some(
    roles,
    (role) => currentUserHasRole(role, auth.profile) && includes(roleToPermission.get(role) || [], permissionName)
  );
};

const currentUserHasRole = (role: ROLE, profile: Auth0Profile) => (profile ? profile.roles.includes(role) : false);

// TODO: remove this function after all demo user logic is migrated out of redux
const roleToPermission = new Map<ROLE, PERMISSIONS[]>([[ROLE.DEMO_USER, [PERMISSIONS.BLOCK_API_PERSISTANCE]]]);
