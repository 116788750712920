import { Box, Grid, Typography } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import TreeViewSkeleton from 'components/atoms/Skeletons/TreeViewSkeleton';
import TaxonomySearchPanel from 'components/atoms/Taxonomy/TaxonomySearchPanel';
import { TaxonomyTree } from 'interfaces/taxonomy';
import { isEmpty, map } from 'lodash';
import React, { useState } from 'react';
import useTaxonomy from 'utils/queryHooks/taxonomy/useTaxonomy';
import TaxonomyTreeViewItem from './TaxonomyTreeViewItem';

const TaxonomyEditor: React.FC = () => {
  const { data: taxonomies, isLoading, isError } = useTaxonomy();
  const [expandedItems, setExpandedItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [taxonomyTreesFiltered, setTaxonomyTreesFiltered] = useState<TaxonomyTree[]>([]);

  const applySearch = (value: string[]) => {
    setExpandedItems(value);
  };

  const handleExpandedItemsChange = (event: React.SyntheticEvent, itemIds: string[]) => {
    setExpandedItems(itemIds);
  };

  return (
    <Grid container spacing={1} direction="column">
      <TaxonomySearchPanel
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        taxonomies={taxonomies}
        onSearch={applySearch}
        expandedItems={expandedItems}
        setExpandedItems={setExpandedItems}
        setTaxonomyTreesFiltered={setTaxonomyTreesFiltered}
      />
      <Grid item>
        {isError ? (
          <Typography variant="body2" color="text.secondary">
            Failed to load taxonomy
          </Typography>
        ) : isLoading ? (
          <TreeViewSkeleton />
        ) : isEmpty(taxonomyTreesFiltered) && !isEmpty(searchValue) ? (
          <Box
            sx={{
              width: '100%',
              minWidth: 700,
              bgcolor: 'background.paper',
              p: 2,
            }}
          >
            <Typography variant="body1" color="text.secondary">
              No results found
            </Typography>
          </Box>
        ) : (
          <SimpleTreeView
            expandedItems={expandedItems}
            onExpandedItemsChange={handleExpandedItemsChange}
            sx={{
              width: '100%',
              minWidth: 700,
              bgcolor: 'background.paper',
            }}
          >
            {map(taxonomyTreesFiltered, (tree) => (
              <TaxonomyTreeViewItem key={tree.path} taxonomyTree={tree} searchValue={searchValue} isEditor />
            ))}
          </SimpleTreeView>
        )}
      </Grid>
    </Grid>
  );
};

export default TaxonomyEditor;
