import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, styled } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import React, { useState } from 'react';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  border: theme.palette.grey[100] + ' 1px solid',
}));

const CliInstructionTitle = styled(Typography)(() => ({
  fontSize: '14px',
  cursor: 'pointer',
}));

const MainTitle = styled(CliInstructionTitle)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginRight: '5px',
}));

const SubTitle = styled(CliInstructionTitle)(() => ({
  color: 'inherit',
}));

const StyledContent = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  padding: '0 20px',
  ol: {
    listStyleType: 'decimal',
  },
  a: {
    color: theme.palette.primary.main,
  },

  '.highlight': {
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.primary.main,
  },
}));

const CLIInstructions = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [showAccordions, setShowAccordions] = useState(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ m: 2, width: '600px' }}>
      <Grid container alignItems="center" onClick={() => setShowAccordions(!showAccordions)}>
        <MainTitle variant="h6">How to upload files using AWS CLI </MainTitle>
        <SubTitle variant="h6">(recommended for best performance)</SubTitle>
      </Grid>

      {showAccordions && (
        <Box sx={{ mt: 2 }}>
          <StyledAccordion disableGutters expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              <Typography sx={{ width: '33%', flexShrink: 0 }}>Mac OS</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <StyledContent variant="h6">
                <ol>
                  <li>
                    <a href="https://awscli.amazonaws.com/AWSCLIV2.pkg">Download AWS CLI</a>
                  </li>
                  <li>
                    Open Terminal by using Mac OS Spotlight Search. To launch Spotlight, click the small magnifying
                    glass icon in your menu bar (screen top-right, or press Command+Space).
                  </li>
                  <li>
                    Configure AWS by typing the command <span className={classnames('highlight')}>aws configure </span>
                    in the command prompt and filling the credentials given on &#34;Generate Credentials&#34;
                  </li>
                  <li>
                    To upload specific files use:{' '}
                    <span className={classnames('highlight')}>aws s3 cp slide_x.svs s3://bucket-name</span>
                  </li>
                  <li>
                    Then to upload a folder recursively use:{' '}
                    <span className={classnames('highlight')}>
                      aws s3 cp local_folder_name s3://s3_bucket_name/local_folder_name/ --recursive
                    </span>
                  </li>
                </ol>
              </StyledContent>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion disableGutters expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
              <Typography sx={{ width: '33%', flexShrink: 0 }}>Windows</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <StyledContent variant="h6">
                <ol>
                  <li>
                    <a href="https://awscli.amazonaws.com/AWSCLIV2.msi">Download AWS CLI</a>
                  </li>
                  <li>
                    Open the command propmt: Click start and search for &#34;command propmt&#34;. Alternatively, you can
                    also access it by pressing the Windows key + R and typing &#34;cmd&#34; in the box.
                  </li>
                  <li>
                    Configure AWS by running the command <span className={classnames('highlight')}>aws configure</span>{' '}
                    in the command prompt and filling the credentials given on &#34;generate credentials&#34;.
                  </li>
                  <li>
                    To upload specific files use:{' '}
                    <span className={classnames('highlight')}>aws s3 cp slide_x.svs s3://bucket-name</span>
                  </li>
                  <li>
                    Then to upload a folder recursively use:{' '}
                    <span className={classnames('highlight')}>
                      aws s3 cp local_folder_name s3://s3_bucket_name/local_folder_name/ --recursive
                    </span>
                  </li>
                </ol>
              </StyledContent>
            </AccordionDetails>
          </StyledAccordion>
        </Box>
      )}
    </Box>
  );
};

export default CLIInstructions;
