import { DataGridProps, GridRowModes, GridRowModesModel, useGridApiRef } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';

import { getAreaTypeOptions } from 'api/areaTypes';
import { SettingsDataGrid } from 'components/atoms/BaseDataGrid/SettingsDataGrid';
import {
  generateGetCellClassNames,
  handleRowModesModelChangeWithoutDraftIds,
} from 'components/atoms/EditableDataGrid/helpers';
import Loader from 'components/Loader';
import { AreaType } from 'interfaces/areaType';
import { areaTypeFields } from 'interfaces/areaType/areaTypeFields';
import { AreaTypeDraft } from './types';
import { useAreaTypesColumns } from './useAreaTypesColumns';
import { generateDraftId, getAreaTypeId } from './utils';

export const AreaTypesDataGrid: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const { data: dbAreaTypes, isLoading: isLoadingAreaTypes } = useQuery(['areaTypes'], () => getAreaTypeOptions());
  const [draftAreaTypes, setDraftAreaTypes] = React.useState<AreaTypeDraft[]>([]);
  const areaTypes = React.useMemo(() => [...draftAreaTypes, ...(dbAreaTypes || [])], [dbAreaTypes, draftAreaTypes]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const handleAddAreaType = useCallback(() => {
    // We use the draftId field to distinguish between draft rows and db rows
    // This is necessary because we need to be able to edit ids in new rows but not in existing rows
    const draftId = generateDraftId();
    const newAreaType: AreaTypeDraft = { displayName: '', id: '', draftId, postprocessed: false, sourceType: '' };
    setDraftAreaTypes((oldDraftAreaTypes) => [newAreaType, ...oldDraftAreaTypes]);
    setRowModesModel((oldRowModesModel) => ({
      ...oldRowModesModel,
      [newAreaType.draftId]: { mode: GridRowModes.Edit },
    }));
  }, [setDraftAreaTypes, setRowModesModel]);

  const apiRef = useGridApiRef();

  const handleRowModesModelChange: DataGridProps<AreaType>['onRowModesModelChange'] = React.useCallback(
    (newRowModesModel: GridRowModesModel) => {
      handleRowModesModelChangeWithoutDraftIds(
        newRowModesModel,
        setRowModesModel,
        draftAreaTypes,
        (row: AreaTypeDraft) => row.draftId
      );
    },
    [draftAreaTypes]
  );

  const columns = useAreaTypesColumns({
    noRows: isEmpty(areaTypes),
    apiRef,
    areaTypes,
    draftAreaTypes,
    rowModesModel,
    setDraftAreaTypes,
    setRowModesModel,
  });

  const getAreaTypeCellClassName: DataGridProps['getCellClassName'] = React.useMemo(
    () =>
      generateGetCellClassNames<AreaType | AreaTypeDraft>({
        apiRef,
        requiredFields: ['id', 'displayName', 'sourceType'],
        uniqueFieldGroups: [['id'], ['displayName']],
        draftRows: draftAreaTypes,
        fieldsToCheckForErrors: areaTypeFields,
        idGetter: getAreaTypeId,
      }),
    [apiRef, draftAreaTypes]
  );

  return !isLoading && !isLoadingAreaTypes ? (
    <SettingsDataGrid
      apiRef={apiRef}
      addText="Add Area Type"
      handleAdd={handleAddAreaType}
      getCellClassName={getAreaTypeCellClassName}
      rows={areaTypes}
      columns={columns}
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      getRowId={getAreaTypeId}
    />
  ) : (
    <Loader />
  );
};
