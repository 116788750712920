import { Viewport } from '@deck.gl/core/typed';
import { Matrix4, clamp } from '@math.gl/core';
import { altitudeToFovy, pixelsToWorld } from '@math.gl/web-mercator';
import { degreesToRadians } from '@turf/helpers';
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import * as vec2 from 'gl-matrix/vec2';

export type Padding = {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
};

function getViewMatrix({
  altitude,
  pitch,
  bearing,
  zoom,
  flipY,
  height,
}: {
  altitude: number;
  pitch: number;
  bearing: number;
  zoom: number;
  flipY: boolean;
  height: number;
}): Matrix4 {
  // @ts-ignore
  const viewMatrix = new Matrix4().lookAt({ eye: [0, 0, 1] });

  // Move camera to altitude (along the pitch & bearing direction)
  viewMatrix.translate([0, 0, -altitude]);

  // Rotate by bearing, and then by pitch (which tilts the view)
  viewMatrix.rotateX(-degreesToRadians(pitch));
  viewMatrix.rotateZ(degreesToRadians(bearing));

  const scale = Math.pow(2, zoom);

  viewMatrix.scale([scale, scale * (flipY ? -1 : 1), scale]);

  return viewMatrix;
}

function getProjectionMatrix({
  width,
  height,
  near,
  far,
  padding,
}: {
  width: number;
  height: number;
  near: number;
  far: number;
  padding: Padding | null;
}) {
  let left = -width / 2;
  let right = width / 2;
  let bottom = -height / 2;
  let top = height / 2;
  if (padding) {
    const { left: l = 0, right: r = 0, top: t = 0, bottom: b = 0 } = padding;
    const offsetX = clamp((l + width - r) / 2, 0, width) - width / 2;
    const offsetY = clamp((t + height - b) / 2, 0, height) - height / 2;
    left -= offsetX;
    right -= offsetX;
    bottom += offsetY;
    top += offsetY;
  }

  return new Matrix4().ortho({
    left,
    right,
    bottom,
    top,
    near,
    far,
  });
}

export type OrthographicMapViewportOptions = {
  /** Name of the viewport */
  id?: string;
  /** Left offset from the canvas edge, in pixels */
  x?: number;
  /** Top offset from the canvas edge, in pixels */
  y?: number;
  /** Viewport width in pixels */
  width?: number;
  /** Viewport height in pixels */
  height?: number;
  /** The world target at the center of the viewport. Default `[0, 0, 0]`. */
  target?: [number, number, number] | [number, number];
  /**  The zoom level of the viewport. `zoom: 0` maps one unit distance to one pixel on screen, and increasing `zoom` by `1` scales the same object to twice as large.
   *   To apply independent zoom levels to the X and Y axes, supply an array `[zoomX, zoomY]`. Default `0`. */
  zoom?: number;
  /** Padding around the viewport, in pixels. */
  padding?: Padding | null;
  /** Distance of near clipping plane. Default `0.1`. */
  near?: number;
  /** Distance of far clipping plane. Default `1000`. */
  far?: number;
  /** Whether to use top-left coordinates (`true`) or bottom-left coordinates (`false`). Default `true`. */
  flipY?: boolean;

  /** Tilt of the camera in degrees */
  pitch?: number;
  /** Heading of the camera in degrees */
  bearing?: number;
  /** Camera altitude relative to the viewport height, legacy property used to control the FOV. Default `1.5` */
  altitude?: number;
  /** Camera fovy in degrees. If provided, overrides `altitude` */
  fovy?: number;

  /** If true, viewport will behave like an orthographic viewport */
  forceOrthographic?: boolean;
  /** Whether to ignore the pitch when calculating the viewport matrix. Default `false`. */
  ignorePitch?: boolean;
};

export class OrthographicMapViewport extends Viewport {
  static displayName = 'OrthographicMapViewport';

  pitch: number;

  bearing: number;

  altitude: number;

  fovy: number;

  orthographic: true = true;

  isGeospatial: false = false;

  /* eslint-disable complexity, max-statements */
  constructor(opts: OrthographicMapViewportOptions = {}) {
    const {
      pitch = 0,
      bearing = 0,

      near = 0.1,
      far = 1000,
      zoom = 0,
      target = [0, 0, 0],
      padding = null,
      flipY = true,
      forceOrthographic = false,
      ignorePitch = false,
    } = opts;

    let { width, height } = opts;

    const projectionMatrix = getProjectionMatrix({
      width: width || 1,
      height: height || 1,
      padding,
      near,
      far,
    });

    // Silently allow apps to send in 0,0 to facilitate isomorphic render etc
    width = width || 1;
    height = height || 1;

    const altitude = projectionMatrix[5] / 2;
    const fovy = altitudeToFovy(altitude);

    const viewMatrix = forceOrthographic
      ? new Matrix4()
          // @ts-ignore
          .lookAt({ eye: [0, 0, 1] })
          .scale([Math.pow(2, zoom), Math.pow(2, zoom) * (flipY ? -1 : 1), Math.pow(2, zoom)])
      : getViewMatrix({
          height,
          pitch: ignorePitch ? 0 : pitch,
          bearing,
          zoom,
          altitude,
          flipY,
        });

    super({
      ...opts,
      // in case viewState contains longitude/latitude values,
      // make sure that the base Viewport class does not treat this as a geospatial viewport
      longitude: undefined,
      latitude: undefined,
      position: target,

      // view matrix
      viewMatrix: viewMatrix.clone(),
      zoom,

      projectionMatrix,
      fovy,
      focalDistance: altitude,
    });

    // Save parameters
    this.zoom = zoom;
    this.pitch = pitch;
    this.bearing = bearing;
    this.altitude = altitude;
    this.fovy = fovy;

    this.orthographic = true;
    this.isGeospatial = false;

    Object.freeze(this);
  }

  projectFlat([X, Y]: number[]): [number, number] {
    const { unitsPerMeter } = this.distanceScales;
    return [X * unitsPerMeter[0], Y * unitsPerMeter[1]];
  }

  unprojectFlat([x, y]: number[]): [number, number] {
    const { metersPerUnit } = this.distanceScales;
    return [x * metersPerUnit[0], y * metersPerUnit[1]];
  }

  /* Needed by LinearInterpolator */
  panByPosition(coords: number[], pixel: number[]): OrthographicMapViewportOptions {
    const fromLocation = pixelsToWorld(pixel, this.pixelUnprojectionMatrix);
    const toLocation = this.projectFlat(coords);

    const translate = vec2.add([], toLocation, vec2.negate([], fromLocation));
    const newCenter = vec2.add([], this.center, translate);

    return { target: this.unprojectFlat(newCenter) };
  }
}
