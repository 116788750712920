import { Checkbox } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { useRowSelectionContext } from '.';

export const renterGridCheckboxCell =
  (numRows?: number, disabled?: boolean): GridColDef['renderCell'] =>
  ({ id }) => {
    const { isRowSelected, toggleSelection } = useRowSelectionContext();

    return (
      <Checkbox disabled={disabled || numRows === 0} checked={isRowSelected(id)} onClick={() => toggleSelection(id)} />
    );
  };
