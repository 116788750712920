import React from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

declare const BACKOFFICE_URL: string;

interface Props {
  slide_id: string;
}

const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const BackofficeLink: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ slide_id }) => (
  <Tooltip title="View in Backoffice" placement="top-end">
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        openInNewTab(`${BACKOFFICE_URL}/annotations/0/slides/${slide_id}/`);
      }}
    >
      <ExitToAppIcon />
    </IconButton>
  </Tooltip>
);

export default BackofficeLink;
