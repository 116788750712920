import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Typography } from '@mui/material';
import AWS from 'aws-sdk';
import React from 'react';
import ProgressIndicator from '../atoms/Progress/ProgressIndicator';
import { Upload } from '../UploadFilesContext';

const UploadSummaryItem: React.FunctionComponent<React.PropsWithChildren<{ upload: Upload }>> = ({ upload }) => {
  const isAborted = (upload.error as AWS.AWSError)?.code === 'RequestAbortedError';
  const isError = Boolean(upload.error) && !isAborted;
  const isUploading = upload.managedUpload && !isAborted && upload.progress > 0 && upload.progress < 100;
  return (
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2">{upload.file?.name}</Typography>
      </Grid>

      <Grid item>
        <Grid container>
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <ProgressIndicator isAborted={isAborted} isError={isError} progress={upload.progress} />
          </Grid>

          {isUploading && (
            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={() => upload.managedUpload.abort()} sx={{ p: 0, ml: 1 }}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UploadSummaryItem;
