import { Grid, Tooltip, Typography } from '@mui/material';
import colormap from 'colormap';
import { eq, filter, flatMap, isEmpty, isNumber, isObject, map, reverse, uniqBy } from 'lodash';
import numeral from 'numeral';
import React from 'react';

import { ResultColorMap } from 'interfaces/experimentResults';
import { slidesLayerVisualizationSettings } from '../Infobar/slidesVisualizationAndConfiguration';
import { FeatureMetadata } from '../useSlideChannelsAndResults/featureMetadata';
import { SlideWithChannelAndResults } from '../useSlideChannelsAndResults/utils';

interface ColorMapRange {
  min: number;
  max: number;
}

interface ColorMapLegendProps {
  variant: string;
  colorMapRange: ColorMapRange;
  height?: number;
  width?: number;
  nshades?: number;
}

const ColorMapLegend = (props: ColorMapLegendProps) => {
  const { variant, colorMapRange, nshades = 100, height = 200, width = 26 } = props;
  const colorMap = colormap({
    colormap: variant,
    nshades: nshades,
    format: 'hex',
    alpha: 1,
  });

  return (
    <Grid container direction="column" alignItems="center" style={{ userSelect: 'none' }}>
      {isNumber(colorMapRange.max) && (
        <Grid item>
          <Typography variant="caption" textAlign="center">
            {numeral(colorMapRange.max).format('0.0a')}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <div style={{ width, height }}>
          {reverse(
            map(colorMap, (color: any, index: number) => (
              <Tooltip
                key={index}
                title={numeral(colorMapRange.min + ((colorMapRange.max - colorMapRange.min) * index) / nshades).format(
                  '0.00a'
                )}
              >
                <div
                  key={index}
                  style={{
                    backgroundColor: color,
                    height: 100 / nshades + '%',
                    width: '100%',
                  }}
                ></div>
              </Tooltip>
            ))
          )}
        </div>
      </Grid>
      {isNumber(colorMapRange.min) && (
        <Grid item>
          <Typography variant="caption" textAlign="center">
            {numeral(colorMapRange.min).format('0.0a')}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ColorMapLegend;

export const getHeatmapLegends = (slide: SlideWithChannelAndResults) => {
  if (!slide?.heatmapResults || !slide?.id || isNaN(Number(slide?.viewerIndex))) {
    return null;
  }
  const slideId = slide.id;
  const viewerIndex = slide.viewerIndex;
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
  const slideLayerVisualizationSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId];

  const { publishedResults, internalResults } = slide.heatmapResults;
  const heatmapLegends = map(
    uniqBy(
      map(
        filter(
          [
            ...flatMap([...flatMap(internalResults), ...publishedResults], 'nestedItems'),
            ...flatMap(internalResults),
            ...publishedResults,
          ],
          (item: FeatureMetadata): boolean => {
            if (!item?.id) {
              return false;
            }
            const itemSettings = slideLayerVisualizationSettings?.[item.id]?.value;
            return itemSettings?.show && isObject(item.color) && Boolean(item.color.color_map);
          }
        ),
        'color'
      ) as ResultColorMap[],
      eq
    ),
    (item: ResultColorMap) => ({
      colorMap: item.color_map,
      range: {
        min: item?.min_value,
        max: item?.max_value,
      },
    })
  );

  if (isEmpty(heatmapLegends)) {
    return null;
  }
  return heatmapLegends;
};
