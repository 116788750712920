import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { map } from 'lodash';
import React from 'react';

interface Props {
  flowClassName: string;
  slideIds: string[];
}

const MultiplexProcessSlideIds: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  flowClassName,
  slideIds,
}) => {
  return (
    <Accordion disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{flowClassName}</AccordionSummary>
      <AccordionDetails>
        <List
          component="div"
          sx={{
            pl: 2,
          }}
          subheader={<ListSubheader>Slide IDs</ListSubheader>}
        >
          <Divider />
          {map(slideIds, (slideId) => (
            <ListItem key={slideId}>
              <ListItemText>{slideId}</ListItemText>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default MultiplexProcessSlideIds;
