import ExpandIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from '@mui/material';
import { isEmpty, map, partition } from 'lodash';
import React, { FunctionComponent, useState } from 'react';

import { DistanceBasedChartForDatasets } from 'components/FeaturesDashboard/charts/DistanceBasedChart';
import { borderColorPalette, colorPalette } from 'components/FeaturesDashboard/chartsColorPallete';
import { FormattedFeature } from 'interfaces/features';
import { getOptionsFromFeatureName, isDistanceBasedFormattedFeature } from 'utils/features';
import FeatureList from './FeatureList';

interface FeatureCategoryProps {
  filteredFeatures: FormattedFeature[];
  name?: string;
  openByDefault?: boolean;
  skipVirtualization?: boolean;
  hideTopAccordion?: boolean;
  addStain: boolean;
}

const FeatureCategoryValuesAccordion: FunctionComponent<React.PropsWithChildren<FeatureCategoryProps>> = ({
  filteredFeatures,
  name,
  openByDefault,
  skipVirtualization,
  addStain,
}) => {
  // use FeatureList to render the features
  const [expanded, setExpanded] = useState(Boolean(openByDefault));

  return (
    <Accordion
      elevation={0}
      square
      expanded={expanded}
      disableGutters
      sx={{
        '&.MuiAccordion-root': {
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      }}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        sx={{
          '&.MuiAccordionSummary-root': {
            padding: '0px 8px',
          },
        }}
        expandIcon={<ExpandIcon />}
      >
        <Typography variant="subtitle2">{name}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          '&.MuiAccordionDetails-root': {
            borderLeft: 3,
            paddingY: 0,
            borderLeftColor: 'primary.main',
            marginLeft: 2,
          },
        }}
      >
        <Grid container spacing={0}>
          <FeatureList
            skipVirtualization={skipVirtualization}
            filteredFeatures={filteredFeatures}
            addStain={addStain}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const FeatureCategoryDistanceBasedAccordion: FunctionComponent<React.PropsWithChildren<FeatureCategoryProps>> = ({
  filteredFeatures,
  name,
  openByDefault,
}) => {
  // use FeatureList to render the features

  const [expanded, setExpanded] = useState(Boolean(openByDefault));

  return (
    <Accordion
      elevation={0}
      square
      expanded={expanded}
      disableGutters
      sx={{
        '&.MuiAccordion-root': {
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      }}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        sx={{
          '&.MuiAccordionSummary-root': {
            padding: '0px 8px',
          },
        }}
        expandIcon={<ExpandIcon />}
      >
        <Typography variant="subtitle2">{name}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          '&.MuiAccordionDetails-root': {
            paddingY: 0,
          },
        }}
      >
        <Grid container spacing={0}>
          {map(filteredFeatures, (feature, idx) => {
            const options = getOptionsFromFeatureName(feature.key);
            return (
              <Grid item key={feature.key} display="flex">
                <DistanceBasedChartForDatasets
                  datasets={[
                    {
                      type: 'bar',
                      label: feature.displayName,
                      data: feature.value as number[],
                      backgroundColor: colorPalette[idx % colorPalette.length],
                      borderColor: borderColorPalette[idx % colorPalette.length],
                    },
                  ]}
                  chartIndex={idx}
                  {...options}
                />
              </Grid>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const FeatureCategory: FunctionComponent<React.PropsWithChildren<FeatureCategoryProps>> = ({
  filteredFeatures,
  name,
  openByDefault,
  skipVirtualization,
  addStain,
}) => {
  const [distancedBasedFeatures, valueFeatures] = partition(filteredFeatures, (feature) =>
    isDistanceBasedFormattedFeature(feature)
  );

  const hasValueFeatures = !isEmpty(valueFeatures);
  const hasDistanceBasedFeatures = !isEmpty(distancedBasedFeatures);

  return hasValueFeatures || hasDistanceBasedFeatures ? (
    <Grid item xs={12}>
      {hasValueFeatures ? (
        <>
          <FeatureCategoryValuesAccordion
            filteredFeatures={valueFeatures}
            name={name || 'Results'}
            openByDefault={openByDefault}
            skipVirtualization={skipVirtualization}
            addStain={addStain}
          />
          <Divider />
        </>
      ) : null}
      {hasDistanceBasedFeatures ? (
        <>
          <FeatureCategoryDistanceBasedAccordion
            filteredFeatures={distancedBasedFeatures}
            // TODO: Get the name from the feature family
            name="Cells distribution by distance from tumor"
            openByDefault={openByDefault}
            addStain={addStain}
          />
          <Divider />
        </>
      ) : null}
    </Grid>
  ) : null;
};

export default FeatureCategory;
