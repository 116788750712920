import React, { ReactNode } from 'react';

import { Procedure } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import ControlledGalleryViewer from './ControlledGalleryViewer';
import { useGallerySlidesUnpaginated } from './useGallerySlides';

export const UnpaginatedGalleryViewer: React.FC<
  React.PropsWithChildren<{
    slideCasePairs: {
      slide: Slide;
      case: Procedure;
    }[];
    onClose: () => void;
    galleryLabel?: ReactNode;
  }>
> = ({ slideCasePairs, onClose, galleryLabel }) => {
  const [fullScreenActive, setFullScreenActive] = React.useState(false);

  const { changeImageIndex, currentImageIndex, currentCase, currentSlide, slideImages, currentSlideIndex } =
    useGallerySlidesUnpaginated({ fullScreenActive, slideCasePairs });

  return (
    <ControlledGalleryViewer
      slideImages={slideImages}
      currentImageIndex={currentImageIndex}
      currentCase={currentCase}
      currentSlide={currentSlide}
      currentSlideIndex={currentSlideIndex}
      changeImageIndex={changeImageIndex}
      handleClose={onClose}
      galleryLabel={galleryLabel}
      fullScreenActive={fullScreenActive}
      setFullScreenActive={setFullScreenActive}
      isLoading={false}
      totalSlides={slideCasePairs.length}
    />
  );
};
