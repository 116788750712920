import { useQuery } from '@tanstack/react-query';
import { getSearchIndex } from 'api/searchIndex';
import { useMemo } from 'react';
import { flattenSearchIndex } from 'services/searchIndex';
import { useCurrentLabId } from 'utils/useCurrentLab';

function useSearchIndexQuery({ enabled = true }: { enabled?: boolean } = {}) {
  const { labId, labSearch } = useCurrentLabId();

  return useQuery({
    queryKey: ['searchIndex', labId],
    queryFn: (context) => getSearchIndex(labSearch, context.signal),
    enabled,
  });
}

export function useSearchIndex({ enabled = true }: { enabled?: boolean } = {}) {
  const query = useSearchIndexQuery({ enabled });
  const { data: searchIndex, isSuccess } = query;

  const index = useMemo(() => {
    if (!isSuccess || !searchIndex) return null;
    return flattenSearchIndex(searchIndex);
  }, [searchIndex, isSuccess]);

  return { index, ...query };
}
