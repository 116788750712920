import { ChannelsColorPreset } from 'interfaces/channelsPreset';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const saveChannelColorPreset = (channelsPreset: Partial<ChannelsColorPreset>) => {
  return apiRequestHandlerPromise({
    url: 'slides/multiplex/color_presets',
    method: 'POST',
    data: JSON.stringify(channelsPreset),
  });
};

export const updateChannelColorPreset = (channelsPreset: Partial<ChannelsColorPreset>) => {
  return apiRequestHandlerPromise({
    url: `slides/multiplex/color_presets/${channelsPreset.id}`,
    method: 'PUT',
    data: JSON.stringify(channelsPreset),
  });
};

export const getChannelsColorPresets = (labId: string) => {
  return apiRequestHandlerPromise({
    url: `slides/multiplex/color_presets?${stringify({ labId })}`,
    method: 'GET',
  });
};
