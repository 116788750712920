import { Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';

import { LayerOpacityToggle } from './LayerOpacityToggle';

export const MainSettings: React.FC<{
  layerName: string;
  layerDisplayName?: string;
  parentKey: string;
  layerDescription?: string;
  selected: boolean;
  viewerIndex: number;
  slideId: string;
  stainTypeId: string;
  layerUrlKey: string;
}> = ({
  layerName,
  layerDisplayName,
  parentKey,
  layerDescription,
  selected,
  viewerIndex,
  slideId,
  layerUrlKey,
  stainTypeId,
}) => {
  return (
    <>
      <Grid
        item
        container
        direction="column"
        md={4}
        alignItems="start"
        sx={{
          '&>.MuiGrid-item': {
            maxWidth: '100%',
          },
        }}
      >
        <Grid item>
          <Tooltip title={layerDisplayName ? `${layerDisplayName} (${layerName})` : layerName} placement="top">
            <Typography variant="caption" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" component="div">
              {layerDisplayName ?? layerName}
            </Typography>
          </Tooltip>
        </Grid>
        {layerDescription && (
          <Grid
            item
            container
            justifyContent="start"
            sx={{
              '&>.MuiGrid-item': {
                maxWidth: '100%',
              },
            }}
          >
            <Grid item>
              <Tooltip title={layerDescription} placement="top">
                <Typography
                  variant="caption"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  component="div"
                  sx={{ color: '#555', fontSize: 9 }}
                >
                  {layerDescription}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        )}
      </Grid>
      <LayerOpacityToggle
        selected={selected}
        viewerIndex={viewerIndex}
        slideId={slideId}
        layerName={layerName}
        parentKey={parentKey}
        stainTypeId={stainTypeId}
        layerUrlKey={layerUrlKey}
      />
    </>
  );
};
