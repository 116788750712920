import React from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const CardTypography = ({
  children,
  color = 'text.secondary',
  bold = false,
}: {
  children: React.ReactNode;
  color?: string;
  bold?: boolean;
}) => {
  const theme = useTheme();
  const {
    typography: { fontSize, fontWeightBold, fontWeightRegular },
  } = theme;
  return (
    <Typography variant="h6" color={color} sx={{ fontSize, fontWeight: bold ? fontWeightBold : fontWeightRegular }}>
      {children}
    </Typography>
  );
};

export default CardTypography;
