import { find } from 'lodash';
import { useCallback } from 'react';

import { useStainTypeOptions } from 'utils/queryHooks/uiConstantsHooks';

export const useStainTypeIdToDisplayName = (...props: Parameters<typeof useStainTypeOptions>) => {
  const { stainTypeOptions, isLoadingStainTypeOptions } = useStainTypeOptions(...props);
  const stainTypeIdToDisplayName = useCallback(
    (stainTypeId: string) => find(stainTypeOptions, { id: stainTypeId })?.displayName || stainTypeId,
    [stainTypeOptions]
  );
  return {
    isLoadingStainTypeOptions,
    stainTypeIdToDisplayName,
  };
};
