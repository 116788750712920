import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { compact, concat, filter, first, fromPairs, isEmpty, join, keys, map, some, sumBy, uniq } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useClipboard } from 'use-clipboard-copy';
import { ArrayParam, BooleanParam, useQueryParam, withDefault } from 'use-query-params';

import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { useSlideLabels } from 'components/StudiesDashboard/StudySettings/StudyLabel/useSlideLabels';
import { FlowClassName } from 'interfaces/experimentResults';
import { Permission } from 'interfaces/permissionOption';
import { humanize } from 'utils/helpers';
import { getLabelsWithoutErrorText } from 'utils/qcLabels';
import { usePermissions } from 'utils/usePermissions';
import useStudy from 'utils/useStudy';
import {
  ActiveSecondaryAnalysisResultsSelect,
  flattenSecondaryResults,
  useActiveResultForPrimaryOrchestrationId,
} from './ActiveSecondaryAnalysisResultsSelect';
import Features from './Features/Features';
import Heatmaps from './Heatmaps';
import { GeoJSONTestControl } from './Heatmaps/GeoJSONTest';
import { ParquetTestControl } from './Heatmaps/ParquetTest';
import InternalHeatmaps from './InternalHeatmaps';
import OrchestrationArchival from './OrchestrationArchival';
import SecondaryAnalysisTrigger from './SecondaryAnalysisTrigger';
import SlideResults from './SlideResults';

interface Props {
  slide: SlideWithChannelAndResults;
  isPlaceholderData: boolean;
  isLoadingCaseData: boolean;
  doesCaseHaveMultipleStainResults: boolean;
  textSearch: string;
}

const Results: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  slide,
  isPlaceholderData,
  isLoadingCaseData,
  doesCaseHaveMultipleStainResults,
  textSearch,
}) => {
  useSignals();
  const { hasPermission } = usePermissions();
  const canHideSecondaryResults = hasPermission(
    Permission.CanArchiveViewRestoreOwnResults || Permission.CanViewRestoreResultsArchivedByAny
  );

  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);
  const [isPublishMode, setIsPublishMode] = useQueryParam(
    `isPublishModeViewer${slide?.viewerIndex}`,
    withDefault(BooleanParam, false)
  );
  // Pending slides mode doesn't allow publishing results since the endpoints currently require a caseId
  const canPublishResults = hasPermission(Permission.PublishResults) && !pendingSlidesMode;
  const canRunSecondaryAnalysis = hasPermission(Permission.RunSecondaryAnalysis);

  const canViewUnpublishedResults = hasPermission(Permission.ViewUnpublishedResults);
  const canSeeOrchestrationId = hasPermission(Permission.SeeOrchestrationId);

  const { study } = useStudy(slide?.studyId);

  const clipboard = useClipboard({ copiedTimeout: 1000 });

  const internalResultKeys = uniq(
    concat(keys(slide?.heatmapResults?.internalResults || {}), keys(slide?.featuresResults?.internalResults || {}))
  );

  const internalResultPublishStateByKey = fromPairs(
    map(internalResultKeys, (key) => {
      const publishedFeaturesMatchingKey = filter(
        slide?.featuresResults?.publishedResults,
        ({ resultType, flowClassName }) => resultType == key || (!resultType && flowClassName == key)
      );
      const publishedHeatmapsMatchingKey = filter(
        slide?.heatmapResults?.publishedResults,
        ({ resultType, flowClassName }) => resultType == key || (!resultType && flowClassName == key)
      );
      const publishedFeaturesNum = sumBy(filter(publishedFeaturesMatchingKey, 'approved'), 'numFeatures');
      const doesSlideHaveApprovedResults = some(publishedHeatmapsMatchingKey, 'approved') || publishedFeaturesNum > 0;

      const publishedOrchestrationIds = uniq(
        concat(
          map(filter(publishedHeatmapsMatchingKey, 'approved'), 'orchestrationId'),
          map(filter(publishedFeaturesMatchingKey, 'approved'), 'orchestrationId')
        )
      );

      if (publishedOrchestrationIds.length > 1) {
        console.warn(
          `Found multiple published ${key} results for slide ${slide?.id} study ID ${slide?.studyId}: ${publishedOrchestrationIds}`
        );
      }

      return [key, doesSlideHaveApprovedResults];
    })
  );

  const internalHeatmapsKeys = keys(slide?.internalHeatmaps || {});

  const [geoJSONTestUrls] = useQueryParam('geoJSONTestUrls', ArrayParam);
  const [parquetTestUrls] = useQueryParam('parquetTestUrls', ArrayParam);
  const [useDeckGL] = useQueryParam('useDeckGL', BooleanParam);

  const { getLabelDisplayName } = useSlideLabels();

  const deconvResults = slide?.heatmapResults?.internalResults?.[FlowClassName.DeconvSlideProcessor];

  const publishedOrchestrationIds = uniq(
    map(
      compact(
        concat(
          slide?.heatmapResults?.publishedResults,
          slide?.featuresResults?.publishedResults,
          // for now, DeconvSlideProcessor is considered published results although it is not marked as approved
          deconvResults
        )
      ),
      'orchestrationId'
    )
  );

  const publishedPrimaryRunOrchestrationId = first(publishedOrchestrationIds);

  const allResults = compact(concat(slide?.heatmapResults?.publishedResults, slide?.featuresResults?.publishedResults));

  const {
    orchestrationId: activePublishedOrchestrationId,
    primaryRunResults,
    deletedAt,
  } = useActiveResultForPrimaryOrchestrationId({
    slideId: slide?.id,
    viewerIndex: slide?.viewerIndex,
    allResults,
    primaryRunOrchestrationId: publishedPrimaryRunOrchestrationId,
  });

  const isSecondaryAnalysis = Boolean(primaryRunResults);

  const publishedHeatmaps = useMemo(
    () =>
      filter(
        flattenSecondaryResults(slide?.heatmapResults?.publishedResults),
        (result) =>
          Boolean(result.heatmapType) &&
          (activePublishedOrchestrationId
            ? result.orchestrationId === activePublishedOrchestrationId
            : !result?.primaryRunOrchestrationId)
      ),
    [slide?.heatmapResults?.publishedResults, activePublishedOrchestrationId]
  );
  const hasPublishedHeatmaps = !isEmpty(publishedHeatmaps);

  const publishedResultsWithFeaturesAndExperimentResultId = filter(
    flattenSecondaryResults(slide?.featuresResults?.publishedResults),
    ({ numFeatures, experimentResultId, orchestrationId, primaryRunOrchestrationId }) =>
      numFeatures > 0 &&
      experimentResultId !== undefined &&
      (activePublishedOrchestrationId ? orchestrationId === activePublishedOrchestrationId : !primaryRunOrchestrationId)
  );
  if (publishedResultsWithFeaturesAndExperimentResultId.length > 1) {
    console.warn(
      `Found multiple published features results with experiment result ID for slide ${slide?.id} study ID ${
        slide?.studyId
      }: ${map(
        publishedResultsWithFeaturesAndExperimentResultId,
        ({ experimentResultId, orchestrationId, createdAt }) =>
          JSON.stringify({ experimentResultId, orchestrationId, createdAt })
      )}`
    );
  }

  const publishedCalculateFeaturesOrchestrationIds = uniq(
    map(
      filter(publishedResultsWithFeaturesAndExperimentResultId, { flowClassName: FlowClassName.CalculateFeatures }),
      'orchestrationId'
    )
  );

  if (publishedCalculateFeaturesOrchestrationIds.length > 1) {
    console.warn(
      `Found multiple published CalculateFeatures results for slide ${slide?.id} study ID ${slide?.studyId}: ${publishedCalculateFeaturesOrchestrationIds}`
    );
  }

  const publishedFeatureResult = first(publishedResultsWithFeaturesAndExperimentResultId);
  const hasPublishedFeatures = Boolean(publishedFeatureResult);

  const hasDeconvResults = !isEmpty(deconvResults);
  const hasInternalResultKeys = !isEmpty(internalResultKeys);
  const hasInternalHeatmapsKeys = !isEmpty(internalHeatmapsKeys);

  const hasGeoJSONTestUrls = useDeckGL && !isEmpty(geoJSONTestUrls);

  const hasResults =
    hasPublishedHeatmaps ||
    hasPublishedFeatures ||
    hasDeconvResults ||
    hasInternalResultKeys ||
    hasInternalHeatmapsKeys ||
    hasGeoJSONTestUrls;

  const [expandAccordion, setExpandAccordion] = useState(true);

  return (
    <Accordion square expanded={expandAccordion} onChange={() => setExpandAccordion(!expandAccordion)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ paddingInline: 1 }}>
        <Grid item container>
          <Grid item container alignItems="center" justifyContent="space-between" pt={1}>
            <Grid item>
              <Typography variant="h3">Results</Typography>
            </Grid>
            {canPublishResults && (
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={isPublishMode}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        onChange={(event) => {
                          event.stopPropagation();
                          setIsPublishMode(event.target.checked);
                        }}
                      />
                    }
                    label="Publish Mode"
                  />
                  <Divider />
                </FormGroup>
              </Grid>
            )}
            {canRunSecondaryAnalysis && !isEmpty(publishedCalculateFeaturesOrchestrationIds) && (
              <SecondaryAnalysisTrigger
                autoInternallyApprove
                slideId={slide?.id}
                viewerIndex={slide?.viewerIndex}
                orchestrationId={first(publishedCalculateFeaturesOrchestrationIds)}
                hasMultiplePublishedOrchestrationIds={publishedCalculateFeaturesOrchestrationIds.length > 1}
                studyId={slide?.studyId}
              />
            )}
          </Grid>

          {isSecondaryAnalysis ? (
            <Grid item container xs={12} direction="row">
              <Grid item xs={canHideSecondaryResults ? 11 : 12}>
                <ActiveSecondaryAnalysisResultsSelect
                  primaryRunResults={primaryRunResults}
                  slideId={slide?.id}
                  viewerIndex={slide?.viewerIndex}
                  stainTypeId={slide?.stainingType}
                />
              </Grid>
              {canHideSecondaryResults && (
                <Grid item xs={1}>
                  <OrchestrationArchival
                    orchestrationId={activePublishedOrchestrationId}
                    studyId={slide?.studyId}
                    isDeleted={Boolean(deletedAt)}
                  />
                </Grid>
              )}
            </Grid>
          ) : (
            canPublishResults &&
            canSeeOrchestrationId &&
            !isEmpty(publishedOrchestrationIds) && (
              <Grid item>
                <Tooltip title={clipboard.copied ? 'Copied!' : 'Copy orch_id'} placement="top">
                  <Typography
                    variant="caption"
                    onClick={(event) => {
                      clipboard.copy(join(publishedOrchestrationIds, ', ') as string);
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    orch_id: {join(publishedOrchestrationIds, ', ')}
                  </Typography>
                </Tooltip>
              </Grid>
            )
          )}
        </Grid>
      </AccordionSummary>

      <AccordionDetails sx={{ padding: 1 }}>
        {!isEmpty(getLabelsWithoutErrorText(slide?.qcLabels || [])) && (
          <Grid item container key={`${slide?.id}-qc-labels`} direction="column" spacing={1}>
            <Grid item>
              <Typography variant="subtitle1">Quality Control</Typography>
            </Grid>
            <Grid item container spacing={1}>
              {map(getLabelsWithoutErrorText(slide?.qcLabels || []), (labelText) => (
                <Grid item key={`${slide?.id}-${labelText}`}>
                  <Chip label={getLabelDisplayName(labelText)} size="small" />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {/* if the slide is QC failed, we don't want to show the results, unless the user has permissions */}
        {(!slide?.qcFailed || canViewUnpublishedResults) &&
          (isPlaceholderData || isLoadingCaseData ? (
            <Grid item container alignItems="center" mx={1}>
              <Grid item mr={1}>
                <CircularProgress size={24} />
              </Grid>
              <Grid item>Loading results...</Grid>
            </Grid>
          ) : hasResults ? (
            <>
              {hasPublishedHeatmaps && (
                <Grid item>
                  <Heatmaps
                    hideOrchestrationId
                    viewerIndex={slide?.viewerIndex}
                    key="publishedResults"
                    title="Overlays"
                    heatmaps={publishedHeatmaps}
                    slideId={slide?.id}
                    studyId={slide?.studyId}
                    stainTypeId={slide?.stainingType}
                    filterText={textSearch}
                    expandByDefault
                  />
                </Grid>
              )}
              {hasPublishedFeatures && (
                <Grid item>
                  <Features
                    highlightedFeatures={study?.highlightedFeatures || []}
                    hiddenFeatures={study?.hiddenFeatures || []}
                    experimentResultIds={
                      publishedFeatureResult?.experimentResultId !== undefined
                        ? [publishedFeatureResult.experimentResultId]
                        : []
                    }
                    slideStainTypeId={slide?.stainingType}
                    doesCaseHaveMultipleStainResults={doesCaseHaveMultipleStainResults}
                  />
                </Grid>
              )}

              {/* // for now, DeconvSlideProcessor is considered published results although it is not marked as approved */}
              {hasDeconvResults && (
                <Grid>
                  <Heatmaps
                    viewerIndex={slide?.viewerIndex}
                    title={'Synthetic DAB'}
                    heatmaps={deconvResults}
                    slideId={slide?.id}
                    studyId={slide?.studyId}
                    stainTypeId={slide?.stainingType}
                    filterText={textSearch}
                    expandByDefault
                  />
                </Grid>
              )}

              {map(internalResultKeys, (key) => {
                const keyHeatmaps = slide?.heatmapResults?.internalResults?.[key];
                const keyFeatures = slide?.featuresResults?.internalResults?.[key];

                const matchingResultTypes = uniq(
                  compact(concat(map(keyHeatmaps, 'resultType'), map(keyFeatures, 'resultType')))
                );
                // Use flow class names when result type is not available
                const matchingFlowClassNames = uniq(
                  compact(
                    concat(
                      map(keyHeatmaps, ({ resultType, flowClassName }) => (!resultType ? flowClassName : null)),
                      map(keyFeatures, ({ resultType, flowClassName }) => (!resultType ? flowClassName : null))
                    )
                  )
                );

                if (matchingResultTypes.length + matchingFlowClassNames.length !== 1) {
                  console.warn(
                    `Expected all result types and flow class names to be the same (matching the group ${key}), but found matchingResultTypes=${matchingResultTypes} and matchingFlowClassNames=${matchingFlowClassNames}`
                  );
                }

                return (
                  (!isEmpty(keyHeatmaps) || !isEmpty(keyFeatures)) &&
                  // for now, DeconvSlideProcessor is considered published results although it is not marked as approved
                  key != FlowClassName.DeconvSlideProcessor && (
                    <Grid item key={key}>
                      <SlideResults
                        slideId={slide?.id}
                        viewerIndex={slide?.viewerIndex}
                        title={humanize(key)}
                        heatmaps={keyHeatmaps ?? []}
                        features={keyFeatures ?? []}
                        filterText={textSearch}
                        highlightedFeatures={study?.highlightedFeatures || []}
                        hiddenFeatures={study?.hiddenFeatures || []}
                        doesHaveApprovedResultType={internalResultPublishStateByKey[key]}
                        resultTypes={matchingResultTypes}
                        flowClassNames={matchingFlowClassNames}
                        studyId={slide?.studyId}
                        doesCaseHaveMultipleStainResults={doesCaseHaveMultipleStainResults}
                        slideStainTypeId={slide?.stainingType}
                      />
                    </Grid>
                  )
                );
              })}

              {map(internalHeatmapsKeys, (key) => {
                const internalHeatmaps = slide?.internalHeatmaps?.[key];
                return (
                  !isEmpty(internalHeatmaps) && (
                    <Grid item key={key}>
                      <InternalHeatmaps
                        heatmaps={internalHeatmaps}
                        slideId={slide?.id}
                        viewerIndex={slide?.viewerIndex}
                        stainTypeId={slide?.stainingType}
                        title={humanize(key)}
                        filterText={textSearch}
                      />
                    </Grid>
                  )
                );
              })}

              {useDeckGL && !isEmpty(geoJSONTestUrls) && (
                <Grid item>
                  <GeoJSONTestControl
                    geoJSONUrls={geoJSONTestUrls}
                    slideId={slide?.id}
                    viewerIndex={slide?.viewerIndex}
                    stainTypeId={slide?.stainingType}
                    textSearch={textSearch}
                  />
                </Grid>
              )}

              {useDeckGL && !isEmpty(parquetTestUrls) && (
                <Grid item>
                  <ParquetTestControl
                    parquetUrls={parquetTestUrls}
                    slideId={slide?.id}
                    viewerIndex={slide?.viewerIndex}
                    stainTypeId={slide?.stainingType}
                    textSearch={textSearch}
                  />
                </Grid>
              )}
            </>
          ) : (
            <Grid item container alignItems="center" mx={1}>
              <Grid item mr={1}>
                <Typography>No results</Typography>
              </Grid>
            </Grid>
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default Results;
