import { handleActions } from 'redux-actions';
import * as AT from 'redux/actionTypes';
import { Auth0Profile } from 'utils/auth/AuthService';

interface AuthState {
  profile?: Auth0Profile;
  scope?: string;
  accessToken?: string;
  triedAuthenticateFromLocalStorage: boolean;
  defaultLabId?: string;
}

// TODO: think on extending the token
const initialState: AuthState = {
  profile: null,
  scope: null,
  accessToken: null,
  triedAuthenticateFromLocalStorage: false,
  defaultLabId: null,
};

const authReducer = handleActions(
  {
    [AT.LOGIN_SUCCESS]: (state, action) => ({
      ...state,
      profile: action.payload.profile,
      scope: action.payload.scope,
      accessToken: action.payload.accessToken,
      triedAuthenticateFromLocalStorage: true,
      defaultLabId: action.payload.profile.labId,
    }),
    [AT.RENEW_TOKEN]: (state, action) => ({
      ...state,
      accessToken: action.payload.accessToken,
      scope: action.payload.scope,
    }),
    [AT.FINISHED_TRY_AUTHENTICATE_FROM_LOCAL_STORAGE]: (state) => ({
      ...state,
      triedAuthenticateFromLocalStorage: true,
    }),
    [AT.LOGOUT]: (state) => ({ ...state, profile: null, scope: null, accessToken: null }),
  },
  initialState
);

export default authReducer;
