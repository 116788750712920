import { Grid, useTheme } from '@mui/material';
import { useRowSelectionContext } from 'components/atoms/RowSelectionContext';
import Loader from 'components/Loader';
import { generateUnwoundRowIdFromMetadata } from 'interfaces/genericFields/unwindRowsWithInnerArrays';
import { Procedure } from 'interfaces/procedure';
import { flatMap, map } from 'lodash';
import React, { FunctionComponent } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import ProcedureCard from '../ProcedureCard';
import { useProcedurePageColumns } from '../useProcedurePageColumns';
import { useStableProcedureStatistics } from '../useStableProcedureStatistics';

interface Props {
  procedures: Procedure[];
  isLoading: boolean;
  galleryView?: boolean;
  pendingSlidesMode?: boolean;
}

const ProcedureCards: FunctionComponent<React.PropsWithChildren<Props>> = ({
  procedures,
  isLoading,
  galleryView,
  pendingSlidesMode,
}) => {
  const theme = useTheme();

  const { xs, sm, md, lg } = useProcedurePageColumns();

  const [slidesMode] = useQueryParam('slidesMode', BooleanParam);
  const { isRowSelected, someSelected } = useRowSelectionContext();

  const isCardSelected = (procedure: Procedure, slideId?: string) => {
    const rowId = slidesMode
      ? generateUnwoundRowIdFromMetadata({
          baseRowId: procedure?.id,
          innerRowId: slideId,
        })
      : procedure?.id;
    return isRowSelected(rowId);
  };

  const { totalCases, totalSlides } = useStableProcedureStatistics();

  const numRows = slidesMode ? totalSlides : totalCases;

  const forceDisplayCheckboxes = someSelected(numRows);

  return isLoading ? (
    <Grid
      container
      className="dashboard-page-body"
      justifyContent="center"
      sx={{ flex: '1 1 auto', backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[50] }}
    >
      <Grid item>
        <Loader />
      </Grid>
    </Grid>
  ) : (
    <Grid
      item
      container
      spacing={3}
      columns={12}
      sx={{
        '&.MuiGrid-item': {
          pt: 0,
        },
      }}
    >
      {slidesMode
        ? flatMap(procedures, (procedure) =>
            map(procedure?.slides, (slide) => (
              <Grid item xs={12 / xs} sm={12 / sm} md={12 / md} lg={12 / lg} key={`${procedure.id}_${slide.id}`}>
                <ProcedureCard
                  procedure={{ ...procedure, slides: [slide] }}
                  galleryView={galleryView}
                  isSelected={isCardSelected(procedure, slide.id)}
                  forceDisplayCheckbox={forceDisplayCheckboxes}
                  selectable
                  pendingSlidesMode={pendingSlidesMode}
                />
              </Grid>
            ))
          )
        : map(procedures, (procedure) => (
            <Grid item xs={12 / xs} sm={12 / sm} md={12 / md} lg={12 / lg} key={procedure.id}>
              <ProcedureCard
                procedure={procedure}
                galleryView={galleryView}
                isSelected={isCardSelected(procedure)}
                forceDisplayCheckbox={forceDisplayCheckboxes}
                selectable={!pendingSlidesMode}
                pendingSlidesMode={pendingSlidesMode}
              />
            </Grid>
          ))}
    </Grid>
  );
};

export default ProcedureCards;
