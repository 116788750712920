import { CancerType } from 'interfaces/cancerType';
import { concat, find, get, isEmpty, isNumber, map, values } from 'lodash';
import { Approval } from 'services/filters';

import { useProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { BooleanParam, JsonParam, NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { enumValuesList } from 'utils/enumValues';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import useCohortFilters from './useCohortFilters';

type StainingTypeQueryElement = string | 0;
type QueryOption = {
  value: StainingTypeQueryElement;
  text: string;
};

export const ALL_STUDIES_VALUE = '0';
export const NO_STUDY_VALUE = '-1';

export const NON_SPECIFIC_STUDY_VALUES = [ALL_STUDIES_VALUE, NO_STUDY_VALUE];

const additionalStudyOptions: any = [
  { value: NO_STUDY_VALUE, text: 'None' },
  { value: ALL_STUDIES_VALUE, text: 'All' },
];

const useMainFilters = ({ enableQueries = true }: { enableQueries?: boolean } = {}) => {
  const { queries } = useCohortFilters();
  const [queryParams, setQueryParams] = useQueryParams({
    cohortId: StringParam,
    queryId: StringParam,
    advancedSearchDrawerOpen: BooleanParam,
    filters: JsonParam,
    features: JsonParam,
    clinicalData: JsonParam,
    compositeFilters: JsonParam,
    page: NumberParam,
    searchTerm: StringParam,
  });

  // stainTypesInStudy
  const {
    cancerTypesLoading,
    allCancerTypesLoading,
    stainTypesInStudyLoading,
    studiesLoading,
    studies,
    allCancerTypes,
    cancerTypes,
    cancerSubtypes: subTypes,
    stainTypesInStudy,
  } = useProceduresFieldsContext({ enabled: enableQueries });

  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName({
    enabled: enableQueries,
  });

  const stainsOptions: QueryOption[] = map(stainTypesInStudy, (currStain) => ({
    value: currStain,
    text: stainTypeIdToDisplayName(currStain),
  }));

  const cancerSubtypes = get(subTypes, 'TISSUE')?.children;

  const cancerTypesList: CancerType[] = values(cancerTypes);
  const allCancerTypesList: CancerType[] = values(allCancerTypes);

  const cancerTypeViewOptions = map(cancerTypesList, (cancerTypeItem) => ({
    value: cancerTypeItem.id,
    text: cancerTypeItem.displayName,
  }));

  let studyOptions = null;
  let allStudyOptions = null;

  if (!isEmpty(studies)) {
    studyOptions = map(studies, (study) => ({
      value: study.id,
      text: study.name,
    }));

    allStudyOptions = concat(additionalStudyOptions, studyOptions);
  }

  const approvalOptions = map(enumValuesList(Approval), (approvalValue) => ({
    value: approvalValue,
    text: approvalValue,
  }));

  const handleFilterChange = (filterKey: string, value: any) => {
    if (filterKey === 'queryId') {
      const currentQuery = find(queries, { id: value });
      const { filters, features, clinicalData, searchTerm } = currentQuery?.queryObject || {};
      setQueryParams({
        queryId: value,
        filters,
        features,
        clinicalData,
        // Reset page when switching queries
        page: 1,
        searchTerm,
      });
    } else {
      setQueryParams(
        {
          filters: { ...queryParams.filters, [filterKey]: !isNumber(value) && isEmpty(value) ? undefined : value },
          // Reset page when switching queries
          page: 1,
        },
        'replaceIn'
      );
    }
  };

  const isStudyIdSpecific = (studyId: string | number) => {
    return find(studies, { id: studyId }) !== undefined;
  };

  return {
    allStudyOptions,
    studyOptions,
    approvalOptions,
    stainsOptions,
    cancerTypesList,
    allCancerTypesList,
    cancerTypeViewOptions,
    cancerSubtypes,
    handleFilterChange,
    cancerTypesLoading,
    allCancerTypesLoading,
    stainTypesInStudyLoading,
    isLoadingStainTypeOptions,
    studiesLoading,
    isStudyIdSpecific,
  };
};

export default useMainFilters;
