import { stringify } from 'qs';
import { useAppSelector } from 'redux/hooks';
import { StringParam, UrlUpdateType, useQueryParam } from 'use-query-params';

/**
 * hook for doing operations with a labId
 * @returns the current lab id from the query param or the default lab id from the store
 */
export const useCurrentLabId = (): {
  userLabId: string; // the default labId from the store
  labId: string; // the current labId from the query param or the default labId from the store
  queryLabId?: string; // the current labId from the query param;
  setLabId: (newLabId: string, updateType?: UrlUpdateType) => void; // set the labId to a new one in the query param
  stringifyWithLab: (search: Object) => string; // stringify a search object and add a labId to it, without the ?
  labSearch: string; // if already has labId in query: ?labId={labId} else ''
} => {
  const [labId, setLabId] = useQueryParam('labId', StringParam, { updateType: 'replaceIn' });

  const { defaultLabId: userLabId } = useAppSelector((state) => state.auth);
  const stringifyWithLab = (search: Object) => stringify({ ...search, labId });
  const labSearch = labId ? `?${stringify({ labId })}` : '';

  return { labId: labId || userLabId, setLabId, stringifyWithLab, labSearch, userLabId, queryLabId: labId };
};
