import { QueryFunctionContext, useQueries } from '@tanstack/react-query';
import { filter, includes, join, map, range, times } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const usePaginatedQueries = <PageResult = any, T = any, Args = any, Placeholder = PageResult>({
  pageQueryFn, // function to fetch page data
  getPageArgs, // function to get args for pageQueryFn, by page number
  getQueryKeyForPage = getPageArgs, // function to get query key for pageQueryFn, by page number
  totalPages, // total number of pages
  currentPage, // current page number, 0-indexed
  numSurroundingPagesToPrefetch, // number of pages to prefetch before and after current page
  numSurroundingPagesToPrefetchAfter = numSurroundingPagesToPrefetch, // number of pages to prefetch after current page
  numSurroundingPagesToPrefetchBefore = numSurroundingPagesToPrefetch, // number of pages to prefetch before current page
  getItemsFromPageData, // function to get items from page data after fetching page is complete
  getPlaceholderItems, // function to get placeholder items before and while fetching page
}: {
  pageQueryFn: (args: Args, context: QueryFunctionContext) => Promise<T>;
  getPageArgs: (page: number) => Args; // page is 0-indexed
  getQueryKeyForPage?: (page: number) => any; // page is 0-indexed
  currentPage: number; // 0-indexed
  totalPages: number;
  numSurroundingPagesToPrefetch?: number;
  numSurroundingPagesToPrefetchBefore?: number;
  numSurroundingPagesToPrefetchAfter?: number;
  getItemsFromPageData: (pageData: T) => PageResult[];
  getPlaceholderItems: (page: number) => Placeholder[];
}) => {
  // activePages is the set of pages that are currently being fetched or prefetched
  const activePages = useMemo(
    () =>
      map(
        range(-1 * numSurroundingPagesToPrefetchBefore, numSurroundingPagesToPrefetchAfter + 1),
        (offset) => (currentPage + offset + totalPages) % totalPages
      ),
    [currentPage, numSurroundingPagesToPrefetchAfter, numSurroundingPagesToPrefetchBefore, totalPages]
  );

  // manualPages is the set of pages that have been manually fetched
  const [manualPages, setManualPages] = useState(new Set<number>([]));

  // fetchPages is a function to manually fetch additional pages
  const fetchPages = useCallback((pages: number[]) => {
    const pagesInRange = filter(pages, (page) => page >= 0 && page < totalPages);
    setManualPages((prev) => new Set([...prev, ...pagesInRange]));
  }, []);

  // fetchNextPage and fetchPreviousPage are functions to manually fetch the next and previous pages
  const fetchNextPage = useCallback(
    () => fetchPages([(currentPage + 1 + totalPages) % totalPages]),
    [currentPage, totalPages, fetchPages]
  );

  const fetchPreviousPage = useCallback(
    () => fetchPages([(currentPage - 1 + totalPages) % totalPages]),
    [currentPage, totalPages, fetchPages]
  );

  const fetchNextMissingPage = useCallback(() => {
    let nextMissingPage = currentPage;
    for (const nextPage of range(currentPage + 1, totalPages)) {
      if (!manualPages.has(nextPage)) {
        nextMissingPage = nextPage;
        break;
      }
    }
    return fetchPages([nextMissingPage]);
  }, [currentPage, fetchPages, manualPages, totalPages]);

  const fetchPreviousMissingPage = useCallback(() => {
    let previousMissingPage = currentPage;
    for (const previousPage of range(currentPage - 1, -1, -1)) {
      if (!manualPages.has(previousPage)) {
        previousMissingPage = previousPage;
        break;
      }
    }
    return fetchPages([previousMissingPage]);
  }, [currentPage, fetchPages, manualPages, totalPages]);

  const [didLoadCurrentPage, setDidLoadCurrentPage] = useState(false);

  // queries is the set of queries for all active pages
  const queries = useQueries({
    queries: times(totalPages, (page) => ({
      keepPreviousData: true,
      // First query the current page, then query the active pages and manual pages
      enabled: !didLoadCurrentPage ? page === currentPage : includes([...activePages, ...manualPages], page),
      queryKey: getQueryKeyForPage(page),
      queryFn: (context: QueryFunctionContext) => pageQueryFn(getPageArgs(page), context),
    })),
  });

  // Needed since we use this to change the enabled state of the queries
  useEffect(() => {
    setDidLoadCurrentPage(Boolean(queries[currentPage]?.data));
  }, [Boolean(queries?.[currentPage]?.data)]);

  const queriedPages = useMemo(() => new Set([...activePages, ...manualPages]), [activePages, manualPages]);

  const items = useMemo(
    () =>
      filter(
        map(queries, ({ data }, page) => ({
          page, // 0-indexed page
          items: data ? getItemsFromPageData(data) : getPlaceholderItems(page),
        })),
        ({ page }) => includes([...activePages, ...manualPages], page)
      ),
    [activePages, getItemsFromPageData, getPlaceholderItems, manualPages, join(map(queries, 'updatedAt'), ', ')]
  );

  return {
    queries,
    // items is the set of items for all active pages
    items,
    queriedPages,
    fetchPages,
    fetchNextPage,
    fetchNextMissingPage,
    fetchPreviousPage,
    fetchPreviousMissingPage,
  };
};
