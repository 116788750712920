import { CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { map } from 'lodash';
import React from 'react';

import { Slide } from 'interfaces/slide';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import CaseDataEntry from './CaseDataEntry';

interface Props {
  procedureId?: number;
  studyId?: string;
  labId: string;
  slides: Slide[];
}

const IdentifiersSuffix: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  procedureId,
  studyId,
  labId,
  slides,
}) => {
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();
  return (
    <>
      <Grid item xs={5} paddingY={2}>
        <Divider />
      </Grid>

      <Grid item xs={5}>
        <Typography variant="body2" component="h2">
          Identifiers
        </Typography>
      </Grid>
      <CaseDataEntry label="Procedure" value={procedureId} typographyVariant="caption" />
      <CaseDataEntry label="Study" value={studyId} typographyVariant="caption" />
      <CaseDataEntry label="Lab" value={labId} typographyVariant="caption" />
      {isLoadingStainTypeOptions ? (
        <CircularProgress />
      ) : (
        map(slides, (slide) => (
          <CaseDataEntry
            key={slide.id}
            value={slide.id}
            label={`${stainTypeIdToDisplayName(slide.stainingType)} Slide`}
            typographyVariant="caption"
          />
        ))
      )}
    </>
  );
};

export default IdentifiersSuffix;
