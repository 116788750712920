import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import { ClassificationBinningParams } from 'interfaces/calculateFeatures';
import { findLast, includes, map, size, slice } from 'lodash';
import React, { useState } from 'react';
interface ModelDetailsProps {
  classes: string[];
  binning: ClassificationBinningParams;
  setBinning: (binning: ClassificationBinningParams) => void;
  onClosed?: () => void;
}

const NUM_BINS = 'num_bins';
const RANGE_BINS = 'range_bins';
const DEFAULT_NUM_BINS = 3;

export const IntensityBins: React.FC<React.PropsWithChildren<ModelDetailsProps>> = ({
  classes,
  binning,
  onClosed,
  setBinning,
}) => {
  const [binsOptions, setBinsOption] = useState<string>(binning.mapValuesToBins ? RANGE_BINS : NUM_BINS);
  const defaultNumBins = size(classes) > DEFAULT_NUM_BINS ? DEFAULT_NUM_BINS : null;
  const [numBins, setNumBins] = useState<number>(binning.numBins ?? defaultNumBins);
  const [sliderRange, setSliderRange] = useState(
    binning.mapValuesToBins ? map(slice(binning.mapValuesToBins, 0, -1), 'maxValue') : [size(classes) / 2]
  );

  const mapValuesToBins = map(sliderRange, (bin, index) => ({
    bin: index + 1,
    minValue: index === 0 ? 1 : sliderRange[index - 1],
    maxValue: bin,
  }));

  mapValuesToBins.push({
    bin: size(sliderRange) + 1,
    minValue: sliderRange[size(sliderRange) - 1],
    maxValue: size(classes),
  });

  const marks = map(classes, (intensityClass, index) => ({
    value: index + 1,
    label: index + 1,
  }));

  return (
    <Grid container p={2} direction="column" spacing={2} flexWrap="nowrap">
      <Grid container item alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h3">Intensity Bins</Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <IconButton onClick={onClosed}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid item>
        <FormControl>
          <RadioGroup
            value={binsOptions}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBinsOption(e.target.value)}
          >
            <FormControlLabel value={NUM_BINS} control={<Radio />} label="Num bins" />
            <FormControlLabel value={RANGE_BINS} control={<Radio />} label="Range bins" />
          </RadioGroup>
        </FormControl>
      </Grid>

      {binsOptions === NUM_BINS ? (
        <Grid item>
          <TextField
            label="Number of bins"
            required
            value={numBins}
            type="number"
            inputProps={{
              max: size(classes),
              min: 1,
            }}
            onChange={(event) => {
              let value = Number(event.target.value);
              if (value > size(classes)) {
                value = size(classes);
              }
              setNumBins(Number(value));
            }}
          />
        </Grid>
      ) : (
        <Grid item container direction="column">
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={8}>
              <Slider
                min={1}
                max={size(classes)}
                track={false}
                aria-labelledby="track-false-range-slider"
                marks={marks}
                value={sliderRange}
                onChange={(event, value) => setSliderRange(value as number[])}
              />
            </Grid>
            <Grid item xs={4}>
              <IconButton
                disabled={size(sliderRange) === size(classes)}
                onClick={() =>
                  setSliderRange((prev: number[]) => {
                    // find the biggest value that is not in the current range to add
                    const biggestValue = findLast(marks, (mark) => !includes(prev, mark.value))?.value;
                    if (biggestValue) {
                      return [...prev, biggestValue];
                    }
                  })
                }
              >
                <AddIcon />
              </IconButton>
              <IconButton
                disabled={size(sliderRange) === 1}
                onClick={() =>
                  setSliderRange((prev: number[]) => {
                    return slice(prev, 0, -1);
                  })
                }
              >
                <RemoveIcon />
              </IconButton>
            </Grid>
          </Grid>
          {map(mapValuesToBins, (bin, index) => (
            <Grid item container xs={12} spacing={1} key={index}>
              <Grid item>{bin.bin} :</Grid>
              <Grid item>
                {bin.minValue} {'<= intensity <'}
                {index === size(mapValuesToBins) - 1 ? '=' : ''} {bin.maxValue}
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (binsOptions === NUM_BINS) {
              setBinning({ numBins });
            } else {
              setBinning({ mapValuesToBins });
            }

            onClosed();
          }}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};
