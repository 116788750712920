import { AutomaticCondition } from './automaticCondition';

export interface QualityControlLabel {
  id: string;
  filterLabel: string;
  rejectLabel: string;
  attachFilterLabel: boolean;
  attachRejectLabel: boolean;
  filterLabelExternal: boolean;
  rejectLabelExternal: boolean;
  condition: AutomaticCondition;
  flaggedSlideTagIds: string[];
  excludeOption?: ExcludeOption;
}

export enum ExcludeOption {
  FILTER = 'FILTER',
  REJECT = 'REJECT',
}

export interface QualityControlLabelParam {
  id: string;
  isReject: boolean;
}
