import { DEFAULT_PAGE_SIZE } from 'components/StudyDashboard/ProceduresPage/ProcedurePagination';
import dayjs, { Dayjs } from 'dayjs';
import { CohortQueryObject } from 'interfaces/cohort';
import { ResultsMode } from 'interfaces/experimentResults';
import { keys, pick } from 'lodash';
import { stringify } from 'qs';
import React from 'react';
import {
  ArrayParam,
  BooleanParam,
  encodeQueryParams,
  JsonParam,
  NumberParam,
  QueryParamConfig,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { useCurrentLabId } from './useCurrentLab';

export const encodeQueryParamsUsingSchema = (
  params: { [key: string]: any },
  schema: Record<string, QueryParamConfig<any, any>>,
  options: Record<string, string> = {}
) => {
  return stringify(encodeQueryParams(pick(schema, keys(params)), params), options);
};

export enum ExperimentResultsSelection {
  All = 'all',
  OnlyQAFailed = 'only_qa_failed',
  WithoutFeatures = 'without_feature_values',
  FeatureValues = 'only_feature_values',
}

export interface EncodedFiltersProps {
  experimentResultsSelection?: ExperimentResultsSelection;
}

export const resultsModeQueryParam: QueryParamConfig<ResultsMode> = withDefault(
  StringParam,
  ResultsMode.Published
) as QueryParamConfig<ResultsMode>;

export const filtersQueryParamsSchema = {
  labId: StringParam,
  cohortId: StringParam,
  cohortName: StringParam,
  page: withDefault(NumberParam, 1),
  pageSize: withDefault(NumberParam, DEFAULT_PAGE_SIZE),
  sortBy: JsonParam, // should not be here, we can make a different schema for this
  filters: JsonParam,
  features: JsonParam,
  clinicalData: JsonParam,
  compositeFilters: JsonParam,
  experimentResultsSelection: StringParam, // should not be here, we can make a different schema for this
  resultsMode: resultsModeQueryParam,
  featuresSelection: JsonParam, // should not be here, we can make a different schema for this
  queryId: StringParam,
  slidesMode: withDefault(BooleanParam, false),
  searchTerm: StringParam,
  caseIdsToInclude: ArrayParam,
  slideIdsToInclude: ArrayParam,
};

export const useEncodedFilters = (
  { experimentResultsSelection }: EncodedFiltersProps = { experimentResultsSelection: undefined }
) => {
  const { labId } = useCurrentLabId();

  const filtersQueryParamsSchemaWithDefaultLab = {
    ...filtersQueryParamsSchema,
    labId: withDefault(StringParam, labId),
  };

  const [queryParams, setQueryParams] = useQueryParams(filtersQueryParamsSchemaWithDefaultLab);

  // Can be used to generate optimistic responses to new encoded filters queries
  const generateEncodedParams = React.useCallback(
    (
      newParams: Partial<typeof queryParams>,
      additionalParams: Record<string, any> = {},
      additionalSchemaDefinitions: Record<string, QueryParamConfig<any, any>> = {}
    ) =>
      encodeQueryParamsUsingSchema(
        {
          filters: newParams?.filters ?? queryParams.filters,
          features: newParams?.features ?? queryParams.features,
          clinicalData: newParams?.clinicalData ?? queryParams.clinicalData,
          labId: newParams?.labId ?? queryParams.labId ?? labId,
          page: newParams?.page ?? queryParams.page ?? 1,
          pageSize: newParams?.pageSize ?? queryParams.pageSize ?? DEFAULT_PAGE_SIZE,
          sortBy: newParams?.sortBy ?? queryParams.sortBy,
          resultsMode: newParams?.resultsMode ?? queryParams.resultsMode,
          experimentResultsSelection: experimentResultsSelection,
          slidesMode: newParams?.slidesMode ?? queryParams.slidesMode ?? false,
          ...additionalParams,
          ...(newParams?.caseIdsToInclude || queryParams.caseIdsToInclude
            ? { caseIdsToInclude: newParams?.caseIdsToInclude ?? queryParams.caseIdsToInclude }
            : {}),
          ...(newParams?.slideIdsToInclude || queryParams.slideIdsToInclude
            ? { slideIdsToInclude: newParams?.slideIdsToInclude ?? queryParams.slideIdsToInclude }
            : {}),
        },
        { ...filtersQueryParamsSchema, ...additionalSchemaDefinitions },
        {
          arrayFormat: 'repeat',
        }
      ),
    [labId, queryParams, experimentResultsSelection]
  );
  const encodedFilters = generateEncodedParams(queryParams);

  const resetAllFilters = () => {
    setQueryParams({
      filters: null,
      features: null,
      clinicalData: null,
      queryId: null,
      searchTerm: null,
    });
  };

  const resetAllFiltersExceptStudy = () => {
    setQueryParams({
      filters: { studyId: queryParams.filters?.studyId },
      features: null,
      clinicalData: null,
      queryId: null,
      searchTerm: null,
    });
  };

  const convertToQueryObject = (params: Partial<typeof queryParams>): CohortQueryObject => {
    return {
      filters: params?.filters,
      clinicalData: params?.clinicalData,
      features: params?.features,
      searchTerm: params?.searchTerm,
    };
  };

  return {
    queryParams,
    setQueryParams,
    encodedFilters,
    generateEncodedParams,
    queryParamsSchema: filtersQueryParamsSchemaWithDefaultLab,
    resetAllFiltersExceptStudy,
    resetAllFilters,
    convertToQueryObject,
  };
};

const DayJSDateParam = {
  encode: (date: Dayjs) => {
    if (!date?.isValid()) {
      return undefined;
    }
    return date.format('YYYY-MM-DD');
  },
  decode: (dateString: string) => {
    const date = new Date(dateString);
    return date ? dayjs(date) : undefined;
  },
};

const accessionQueryParamsSchema = {
  page: NumberParam,
  dateOfScanFrom: DayJSDateParam,
  dateOfScanTo: DayJSDateParam,
  accessionId: withDefault(StringParam, ''),
  medicalRecordNumber: withDefault(StringParam, ''),
  labId: StringParam,
};

export const useFiltersForAccessions = () => {
  const { labId } = useCurrentLabId();

  const [queryParams, setQueryParams] = useQueryParams(accessionQueryParamsSchema);

  const encodedFilters = encodeQueryParamsUsingSchema(
    {
      dateOfScanFrom: queryParams.dateOfScanFrom,
      dateOfScanTo: queryParams.dateOfScanTo,
      accessionId: queryParams.accessionId,
      medicalRecordNumber: queryParams.medicalRecordNumber,
      labId,
      page: queryParams.page,
    },
    accessionQueryParamsSchema
  );

  return { queryParams, setQueryParams, encodedFilters };
};
