export enum ChartType {
  Histogram = 'histogram',
  Scatter = 'scatter',
  KaplanMeier = 'kaplan-meier',
  Box = 'box',
  Pie = 'pie',
  DistanceBased = 'distance-based',
}

export interface CleanFeature {
  key: string;
  value: number;
  unit?: string;
}
