import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton } from '@mui/material';
import React from 'react';

interface Props {
  assignmentId: number;
  faded: boolean;
}

const AssignmentEditButton: React.FunctionComponent<Props> = ({ assignmentId, faded }) => {
  return (
    <IconButton
      // TODO: Add support for editing assignments
      disabled
      // disabled={faded}
      onClick={(event) => {
        event.stopPropagation();
        // dispatch(setIsEditingAssignment(assignmentId));
      }}
    >
      <SettingsIcon />
    </IconButton>
  );
};

export default AssignmentEditButton;
