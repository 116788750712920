// Is there a user credential entry in our system or only in auth0?
// If DB - the id is the Database ID
// If auth0 - the id is the auth0 ID
export enum UserSource {
  DB = 'DB',
  Auth0 = 'auth0',
}

export interface UserCredentials {
  id: string;
  primaryEmail: string;
  name?: string;
  roles: string[];
  permissions: string[];
  userSource: UserSource;
}

export type InternalUser = Pick<UserCredentials, 'id' | 'primaryEmail' | 'name'>;

export interface UserCredentialsPaginatedResponse {
  users: UserCredentials[];
  total: number;
  start: number;
  limit: number;
  length: number;
}
