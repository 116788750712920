import { useNavigate } from 'react-router-dom';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

import { useEncodedFilters } from 'utils/useEncodedFilters';

export const useNavigateBackToDashboard = () => {
  const navigate = useNavigate();
  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);

  const { queryParams, generateEncodedParams } = useEncodedFilters();
  const [fromStudyFilter] = useQueryParam('fromStudyFilter', StringParam);
  const queryParamsForBackButton = { ...queryParams };
  if (fromStudyFilter) {
    queryParamsForBackButton.filters = { ...queryParams?.filters, studyId: fromStudyFilter };
  }
  const encodedFiltersForBackButton = generateEncodedParams(
    queryParamsForBackButton,
    { pendingSlidesMode: pendingSlidesMode },
    { pendingSlidesMode: BooleanParam }
  );

  return () => navigate(`/procedures?${encodedFiltersForBackButton}`);
};
