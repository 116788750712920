import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { map } from 'lodash';
import React from 'react';

interface DashboardTabsProps {
  tabsDisplayTexts: string[];
  tabs: string[];
  changeTab: (val: string) => void;
  defaultValueIndex?: number;
}

function a11yProps(index: number) {
  return {
    id: `dashboard-tab-${index}`,
    'aria-controls': `dashboard-tabpanel-${index}`,
  };
}

const DashboardTabs = ({ tabsDisplayTexts, changeTab, tabs, defaultValueIndex }: DashboardTabsProps) => {
  const [value, setValue] = React.useState(!isNaN(defaultValueIndex) && defaultValueIndex > 0 ? defaultValueIndex : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    changeTab(tabs[newValue]);
  };

  return (
    <Tabs value={value} onChange={handleChange} aria-label="dashboard tabs">
      {map(tabsDisplayTexts, (title, i) => {
        return <Tab label={title} key={title} {...a11yProps(i)} />;
      })}
    </Tabs>
  );
};

export default DashboardTabs;
