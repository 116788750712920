import { useQuery } from '@tanstack/react-query';
import { getPermissionOptions } from 'api/permissionOptions';
import { PermissionOption } from 'interfaces/permissionOption';

export interface RolesFieldsContext {
  permissions: PermissionOption[];
  isLoadingPermissions: boolean;
}

export const useRolesFieldsContext = () => {
  const { data: permissions, isLoading: isLoadingPermissions } = useQuery(['permissions'], getPermissionOptions);
  return {
    permissions,
    isLoadingPermissions,
  };
};
