import React from 'react';

import { Grid, MenuItem, Select, Tooltip, Typography, useTheme } from '@mui/material';
import QueryFieldInput from 'components/SearchFilters/QueryFieldInput';
import { ConditionOperator } from 'interfaces/automaticCondition';
import { FilterKey } from 'interfaces/cohort';
import { Permission } from 'interfaces/permissionOption';
import { slideTagsField } from 'interfaces/procedure/fields/qcLabelsFields';
import { QualityControlLabelParam } from 'interfaces/qualityControlLabels';
import { Study } from 'interfaces/study';
import { usePermissions } from 'utils/usePermissions';
import QcLabelsFilterSelection from './QcLabelsFilterSelection';

interface Props {
  study?: Study;
  onMainFilterChange: (filterKey: FilterKey, value: any) => void;
  advancedInputs: Record<string, any>;
}

const QcFilters: React.FC<React.PropsWithChildren<Props>> = ({ study, onMainFilterChange, advancedInputs }) => {
  const { hasPermission } = usePermissions();

  const canViewInternalLabels = hasPermission(Permission.ViewInternalSlideLabels);

  const theme = useTheme();
  return (
    <Grid container spacing={2}>
      <Grid container item direction="column" key="qcLabelsOperator">
        <Grid item>
          <Typography variant="caption" style={{ color: !study ? theme.palette.grey[500] : 'initial' }}>
            Filter behavior
          </Typography>
        </Grid>
        <Grid item sx={{ maxWidth: '100%' }}>
          <Select
            size="small"
            variant="outlined"
            disabled={!study}
            value={advancedInputs['qcLabelsOperator'] || ConditionOperator.OR}
            onChange={(event) => onMainFilterChange('qcLabelsOperator', event.target.value)}
          >
            <MenuItem value={ConditionOperator.AND}>
              <Tooltip
                arrow
                placement="right"
                enterDelay={500}
                enterNextDelay={500}
                title="filter slides/cases that have all selected labels"
              >
                <Typography width={'100%'}>All</Typography>
              </Tooltip>
            </MenuItem>

            <MenuItem value={ConditionOperator.OR}>
              <Tooltip
                arrow
                placement="right"
                enterDelay={500}
                enterNextDelay={500}
                title="filter slides/cases that have at least one of the selected labels"
              >
                <Typography width={'100%'}>At least one</Typography>
              </Tooltip>
            </MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid item>
        <QcLabelsFilterSelection
          study={study}
          onChange={onMainFilterChange}
          controlledValues={advancedInputs['qcLabels'] as unknown as QualityControlLabelParam[]}
        />
      </Grid>
      {canViewInternalLabels && (
        <Grid container item direction="column" key={slideTagsField.dataKey}>
          <Grid item>
            <Typography variant="caption">{slideTagsField?.label}</Typography>
          </Grid>
          <Grid item sx={{ maxWidth: '100%' }}>
            <QueryFieldInput
              field={slideTagsField}
              onChange={(type, key: string, data: string[]) => onMainFilterChange(key, data)}
              value={advancedInputs[slideTagsField.dataKey]}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default QcFilters;
