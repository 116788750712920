import ResetFiltersIcon from '@mui/icons-material/RestartAltRounded';
import { Button, Grid, IconButton, Tooltip } from '@mui/material';
import LabelledDateRange from 'components/atoms/LabelledDateRange/LabelledDateRange';

import LabelledInput from 'components/atoms/LabelledInput/LabelledInput';
import React, { FunctionComponent, useState } from 'react';
import { useFiltersForAccessions } from 'utils/useEncodedFilters';

interface Props {
  onRefetch: () => void;
}

const AccessionFilters: FunctionComponent<React.PropsWithChildren<Props>> = ({ onRefetch }) => {
  const { queryParams, setQueryParams } = useFiltersForAccessions();

  const [accessionId, setAccessionId] = useState(queryParams.accessionId);
  const [medicalRecordNumber, setMedicalRecordNumber] = useState(queryParams.medicalRecordNumber);
  const [dateOfScanFrom, setDateOfScanFrom] = useState(queryParams.dateOfScanFrom);
  const [dateOfScanTo, setDateOfScanTo] = useState(queryParams.dateOfScanTo);

  const applyFilters = () => {
    // Update the query parameters using the local state values
    setQueryParams({
      accessionId,
      medicalRecordNumber,
      dateOfScanFrom,
      dateOfScanTo,
    });

    onRefetch();
  };

  const clearFilters = () => {
    setAccessionId('');
    setMedicalRecordNumber('');
    setDateOfScanFrom(null);
    setDateOfScanTo(null);
    setQueryParams({});
  };

  return (
    <Grid container spacing={2} wrap="wrap">
      <Grid item xs={0.5}>
        <Tooltip title="Reset filters">
          <IconButton onClick={clearFilters}>
            <ResetFiltersIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={5}>
        <LabelledDateRange
          label="Date of Scan"
          startDate={dateOfScanFrom}
          endDate={dateOfScanTo}
          setStartDate={setDateOfScanFrom}
          setEndDate={setDateOfScanTo}
        />
      </Grid>
      <Grid item xs={2}>
        <LabelledInput
          size="small"
          label="Accession ID"
          value={accessionId}
          onChange={(event) => setAccessionId(event.target.value)}
          onReset={() => {
            setAccessionId('');
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <LabelledInput
          size="small"
          label="Medical Record Number"
          value={`${medicalRecordNumber}`}
          onChange={(event) => setMedicalRecordNumber(event.target.value)}
          onReset={() => {
            setMedicalRecordNumber('');
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Button variant="contained" color="primary" onClick={applyFilters}>
          Search
        </Button>
      </Grid>
    </Grid>
  );
};

export default AccessionFilters;
