import FilterListIcon from '@mui/icons-material/FilterList';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';

interface Props {
  itemKey: string | number;
  value: string | number;
  showIcon?: boolean;
}

const CohortQueryListItem = ({ itemKey, value, showIcon = true }: Props) => {
  return (
    <Grid container item key={itemKey} alignItems="center">
      {showIcon && (
        <Grid item xs={1} container>
          <FilterListIcon />
        </Grid>
      )}
      <Grid item xs={11}>
        <Typography variant="body2">{value}</Typography>
      </Grid>
    </Grid>
  );
};

export default CohortQueryListItem;
