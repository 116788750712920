import classNames from 'classnames';
import { EventHandler, MouseTracker, MouseTrackerEvent, Point, Viewer } from 'openseadragon';
import React, { useMemo, useState } from 'react';
import './MousePositionOverlay.scss';

export enum MouseOverlayLocation {
  top = 'top',
  bottom = 'bottom',
}

interface Props {
  viewer: Viewer;
  location: MouseOverlayLocation;
}

const MousePositionOverlay: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ viewer, location }) => {
  const [imagePoint, setImagePoint] = useState(new Point(0, 0));
  const [showOverlay, setShowOverlay] = useState(false);

  const handleMove: EventHandler<MouseTrackerEvent<Event>> = (event) => {
    // @ts-ignore
    const viewportPoint = viewer.viewport.pointFromPixel(event.position);
    const viewerFirstTile = viewer.world.getItemCount() > 1 ? viewer.world.getItemAt(0) : viewer.viewport;
    setImagePoint(viewerFirstTile.viewportToImageCoordinates(viewportPoint));
  };

  useMemo(
    () =>
      new MouseTracker({
        element: viewer.container,
        moveHandler: handleMove,
        enterHandler: () => setShowOverlay(true),
        leaveHandler: () => setShowOverlay(false),
      }),
    [viewer.container]
  );

  return (
    <div
      className={classNames('mouse-position-overlay', {
        [location]: true,
        invisible: !showOverlay,
      })}
    >
      <div className="mouse-position-coordinate">
        <span>X:</span>
        <span className="text-align-end">{imagePoint.x.toFixed(0)}</span>
      </div>
      <div className="mouse-position-coordinate">
        <span>Y:</span>
        <span className="text-align-end">{imagePoint.y.toFixed(0)}</span>
      </div>
    </div>
  );
};

export default MousePositionOverlay;
