import { useQuery } from '@tanstack/react-query';
import { getTaxonomyTags } from 'api/taxonomyTags';
import { TaxonomyTag } from 'interfaces/taxonomyTag';

const useTaxonomyTags = (label?: string) => {
  const query = useQuery<TaxonomyTag[]>({
    queryKey: ['taxonomyTags', label],
    queryFn: ({ signal }) => getTaxonomyTags(label, signal),
  });

  return query;
};

export default useTaxonomyTags;
