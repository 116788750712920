import type { Layer } from '@deck.gl/core/typed';
import { LayerExtension } from '@deck.gl/core/typed';
import { ShaderModule } from '@deck.gl/core/typed/types/types';

export interface ImageControlsProps {
  gamma: number;
  brightness: number;
  contrast: number;
}

const controlDefaults: ImageControlsProps = {
  gamma: 1,
  brightness: 0,
  contrast: 1,
};

const shaders = {
  modules: [
    {
      name: 'image-controls-module',
      fs: `\
    uniform float gamma;
    uniform float brightness;
    uniform float contrast;`,
    } as ShaderModule,
  ],
  // Test this works by discarding all pixels
  // 'fs:DECKGL_FILTER_COLOR': `if (rgba.a > 0.0) discard;`,
  // Apply gamma, brightness, and contrast to the color, clamping the values to [0, 1]
  inject: {
    'fs:DECKGL_FILTER_COLOR': `\
    float clampedGamma = min(10.0, max(0.00005, gamma));
    float clampedContrast = min(2.0, max(0.0, contrast));
    float clampedBrightness = min(1.0, max(0.0, brightness));
    color.r = min((pow(color.r, 1.0 / clampedGamma) - 0.5) * clampedContrast + clampedBrightness + 0.5, 1.0);
    color.g = min((pow(color.g, 1.0 / clampedGamma) - 0.5) * clampedContrast + clampedBrightness + 0.5, 1.0);
    color.b = min((pow(color.b, 1.0 / clampedGamma) - 0.5) * clampedContrast + clampedBrightness + 0.5, 1.0);`,
  },
};

/** Perform staining deconv on a slide. */
export default class ImageControlsExtension extends LayerExtension<ImageControlsProps> {
  static extensionName = 'ImageControls';

  static defaultProps = controlDefaults;

  getShaders(this: Layer<ImageControlsProps>) {
    return shaders;
  }

  draw(this: Layer<Required<ImageControlsProps>>, { uniforms }: any): void {
    uniforms.gamma = this.props.gamma ?? controlDefaults.gamma;
    uniforms.brightness = this.props.brightness ?? controlDefaults.brightness;
    uniforms.contrast = this.props.contrast ?? controlDefaults.contrast;
  }
}
