import { Grid, Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const TreeViewSkeleton: React.FC = () => {
  const renderSkeletonItem = (level: number) => (
    <Box display="flex" alignItems="center" ml={level * 4} mb={1}>
      <Skeleton variant="rectangular" width={20} height={20} style={{ marginRight: 8 }} />
      <Skeleton variant="text" width={200} />
    </Box>
  );

  return (
    <Grid container direction="column">
      {renderSkeletonItem(0)}
      {renderSkeletonItem(1)}
      {renderSkeletonItem(1)}
      {renderSkeletonItem(2)}
      {renderSkeletonItem(2)}
      {renderSkeletonItem(0)}
      {renderSkeletonItem(1)}
      {renderSkeletonItem(2)}
    </Grid>
  );
};

export default TreeViewSkeleton;
