import Grid from '@mui/material/Grid';
import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import { MainPageFilterKey } from 'interfaces/cohort';
import { Permission } from 'interfaces/permissionOption';
import { filter, keys, map } from 'lodash';
import React from 'react';
import { Approval } from 'services/filters';
import { BooleanParam, JsonParam, StringParam, useQueryParams } from 'use-query-params';
import { usePermissions } from 'utils/usePermissions';
import useMainFilters from '../hooks/useMainFilters';
import MainFilter from './MainFilter';

// all main filters except for approvement/status because this one is shown in its own grid according to permission
const mainFilterKeys = keys(MainPageFilterKey);

const labelMap = {
  approvement: 'Status',
  studyId: 'Study',
  cancerTypes: 'Cancer Type',
  stains: 'Stain',
  cancerSubtypes: 'Cancer Subtype',
  orchestrationId: 'Orchestration ID',
};

export interface ProcedureFilterProps {
  showApprovedFilter: boolean;
  onChangeFromAdvancedSearch?: (key: string, value: any) => void;
  isDrawer?: boolean;
  controlledValues?: Record<string, any>;
  shouldAllowSaveCohort?: boolean;
  enableQueries?: boolean;
}

const MainFilters: React.FunctionComponent<React.PropsWithChildren<ProcedureFilterProps>> = ({
  showApprovedFilter,
  isDrawer,
  onChangeFromAdvancedSearch = null,
  controlledValues = {},
  enableQueries = true,
}) => {
  const { approvalOptions, handleFilterChange } = useMainFilters({
    enableQueries,
  });

  const handleChange = isDrawer && onChangeFromAdvancedSearch ? onChangeFromAdvancedSearch : handleFilterChange;

  const [queryParams, setQueryParams] = useQueryParams({
    cohortId: StringParam,
    queryId: StringParam,
    advancedSearchDrawerOpen: BooleanParam,
    filters: JsonParam,
    features: JsonParam,
    clinicalData: JsonParam,
    compositeFilters: JsonParam,
    resultsMode: StringParam,
    searchTerm: StringParam,
  });

  const { hasPermission } = usePermissions();
  const showOrchestrationFilter = hasPermission(Permission.ViewUnpublishedResults);
  const mainFilterKeysToShow = showOrchestrationFilter
    ? mainFilterKeys
    : filter(mainFilterKeys, (k) => k !== 'orchestrationId');

  const numberOfColumns = isDrawer ? 12 : mainFilterKeysToShow.length;
  const xs = isDrawer ? 12 : 12 / (numberOfColumns + 1); // the search term field is twice as wide as the others
  const spacing = isDrawer ? 2 : 1;

  const approval = queryParams.filters?.approvement || Approval.All;

  const handleApprovalFieldChange = (value: Approval) => {
    // TODO: fix 'Unapproved' to 'Disapproved' in backend and use handleChange instead of setQueryParams
    if (isDrawer && onChangeFromAdvancedSearch) {
      onChangeFromAdvancedSearch('approvement', value);
    } else {
      switch (value) {
        case Approval.Approved:
          setQueryParams({ filters: { ...queryParams.filters, approvement: 'Approved' } });
          break;
        case Approval.Disapproved:
          setQueryParams({ filters: { ...queryParams.filters, approvement: 'Unapproved' } });
          break;
        default:
          setQueryParams({ filters: { ...queryParams.filters, approvement: null } });
      }
    }
  };

  return (
    <Grid container spacing={spacing} wrap="wrap" flexDirection="column">
      <Grid container item justifyContent="space-between" spacing={spacing}>
        {showApprovedFilter && isDrawer && (
          <Grid item xs={xs}>
            <LabelledDropdown
              size="small"
              key="approvement"
              label="Status"
              value={controlledValues['approvement'] ?? approval}
              options={approvalOptions}
              onOptionSelected={handleApprovalFieldChange}
            />
          </Grid>
        )}
      </Grid>

      <Grid container item spacing={spacing} wrap="wrap">
        {map(mainFilterKeysToShow, (filterKey) => {
          const filterValue = controlledValues[filterKey] ?? queryParams?.filters?.[filterKey];
          return (
            <Grid item xs={filterKey == MainPageFilterKey.searchTerm ? xs * 2 : xs} key={filterKey}>
              <MainFilter
                label={labelMap[filterKey as keyof typeof labelMap]}
                filterKey={filterKey}
                filterValue={filterValue}
                onChange={handleChange}
                controlledValues={controlledValues}
                handleApprovalFieldChange={handleApprovalFieldChange}
                enableQueries={enableQueries}
                isDrawer={isDrawer}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default MainFilters;
