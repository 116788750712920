import { CircularProgress, Typography } from '@mui/material';
import { useJob } from 'components/Pages/Jobs/useJob';
import { Job } from 'interfaces/job';
import { isEmpty } from 'lodash';
import React, { FunctionComponent, ReactElement, useState } from 'react';

export interface JobWithRebuildProps {
  jobId: string;
  onSelectedJobParamChange?: (newValue: Job) => void;
}

export const JobWithRebuild: FunctionComponent<React.PropsWithChildren<JobWithRebuildProps>> = ({
  jobId,
  onSelectedJobParamChange,
  children,
}): ReactElement => {
  const [isRebuildJob, setIsRebuildJob] = useState(false);

  const { data: jobData, isLoading: isLoadingJobData, isError: isErrorJobData } = useJob(jobId);

  if (!isRebuildJob && !isEmpty(jobId) && !isLoadingJobData && !isEmpty(jobData)) {
    setIsRebuildJob(true);
    onSelectedJobParamChange(jobData);
  }

  return (
    <>
      {isErrorJobData ||
        (!isEmpty(jobId) && !isLoadingJobData && isEmpty(jobData) && (
          <Typography>Error loading job data. you can select old job or enter params by yourself. </Typography>
        ))}
      {!isEmpty(jobId) && isLoadingJobData ? <CircularProgress /> : children}
    </>
  );
};
