import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { TreeItem } from '@mui/lab';
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { ClassificationModel } from 'interfaces/classificationModel';
import { Layer, VisualizationCreated } from 'interfaces/visualization';
import { includes, isEmpty, isNumber, map, some } from 'lodash';
import React from 'react';
import { VisualizationLayer } from './VisualizationLayer';
import { ClassOptionsByType } from './VisualizationStain';

interface NewVisualizationProps {
  classes: ClassOptionsByType;
  visualization: VisualizationCreated;
  isDuplicate: boolean;
  updateVisualization: (visualization: VisualizationCreated) => void;
  deleteVisualization: (newVisualizationId: string) => void;
  classificationModelOptions: ClassificationModel[];
}

export const NewVisualization: React.FC<React.PropsWithChildren<NewVisualizationProps>> = ({
  classes,
  visualization,
  isDuplicate,
  updateVisualization,
  deleteVisualization,
  classificationModelOptions,
}) => {
  const updateLayer = (layerIndex: number, layerToUpdate: Layer) => {
    const newLayers = [...visualization.layers];
    newLayers[layerIndex] = layerToUpdate;
    updateVisualization({ ...visualization, layers: newLayers });
  };

  const addNewLayer = () => {
    updateVisualization({
      ...visualization,
      layers: [...visualization.layers, { classKey: '', color: '#000000' }],
    });
  };

  const deleteLayer = (layerIndex: number) => {
    const newLayers = [...visualization.layers];
    newLayers.splice(layerIndex, 1);
    updateVisualization({ ...visualization, layers: newLayers });
  };

  const setClassificationModel = (classificationModelId: string) => {
    updateVisualization({ ...visualization, readAs: classificationModelId });
  };

  const isClassification = visualization.id === 'classification';
  const layersError = some(
    visualization.layers,
    (layer) =>
      isEmpty(layer.classKey) ||
      (isClassification && !isNumber(layer.classificationClassKey) && isEmpty(layer.classificationClassKey)) ||
      !includes(map(classes?.[visualization.input], 'value'), layer.classKey) ||
      (isClassification && !includes(map(classes?.[visualization.readAs], 'value'), layer.classificationClassKey))
  );

  return (
    <FormControl
      fullWidth
      variant="standard"
      error={isDuplicate || isEmpty(classes?.[visualization.input]) || layersError}
      className={isDuplicate || isEmpty(classes?.[visualization.input]) || layersError ? 'error' : undefined}
    >
      <TreeItem
        sx={{ pt: 1 }}
        key={visualization.newVisualizationId}
        nodeId={visualization.newVisualizationId}
        label={
          <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
            <Grid item container xs={7} alignItems="center" spacing={1}>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Grid item>{visualization.displayName}</Grid>
                  </Grid>
                  <Grid item mb={1}>
                    {isEmpty(classes?.[visualization.input]) ? (
                      <FormHelperText error id="class-does-not-exist-error-text">
                        You need to choose model first
                      </FormHelperText>
                    ) : isClassification && isEmpty(classes?.[visualization.readAs]) ? (
                      <FormHelperText error id="class-does-not-exist-error-text">
                        You need to choose classification model
                      </FormHelperText>
                    ) : isDuplicate ? (
                      <FormHelperText error id="class-does-not-exist-error-text">
                        Duplicate visualization
                      </FormHelperText>
                    ) : layersError ? (
                      <FormHelperText error id="class-does-not-exist-error-text">
                        classes missing
                      </FormHelperText>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                {isClassification && (
                  <FormControl required error={isEmpty(visualization.readAs)} fullWidth>
                    <InputLabel>Classification Model</InputLabel>
                    <Select
                      size="small"
                      value={visualization.readAs ?? null}
                      label="Classification Model"
                      onChange={(event) => {
                        setClassificationModel(event.target.value as string);
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    >
                      {map(classificationModelOptions, (option) => (
                        <MenuItem value={option.id}>{option.displayName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <Typography variant="body2">Composite: </Typography>
                    </Grid>
                    <Grid item>
                      <Checkbox disabled checked={visualization.withComposite} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => deleteVisualization(visualization.newVisualizationId)} size="small">
                    <DeleteOutlineIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      >
        {map(visualization.layers, (layer, index) => (
          <VisualizationLayer
            key={`${visualization.newVisualizationId}-${index}`}
            classes={classes?.[visualization.input] || []}
            layerKey={`${visualization.newVisualizationId}-${index}`}
            layer={layer}
            classExist={
              isEmpty(layer.classKey) || includes(map(classes?.[visualization.input], 'value'), layer.classKey)
            }
            updateLayer={(layerToUpdate: Layer) => {
              updateLayer(index, layerToUpdate);
            }}
            deleteLayer={() => {
              deleteLayer(index);
            }}
            isClassification={isClassification}
            classificationClasses={classes?.[visualization.readAs] || []}
            classificationClassExist={
              isEmpty(layer.classificationClassKey) ||
              includes(map(classes?.[visualization.readAs], 'value'), layer.classificationClassKey)
            }
          />
        ))}
        <TreeItem
          key={`${visualization.newVisualizationId}-new`}
          nodeId={`${visualization.newVisualizationId}-new`}
          label={
            <Button onClick={addNewLayer}>
              <Typography variant="body2">Add New Layer</Typography>
            </Button>
          }
        />
      </TreeItem>
    </FormControl>
  );
};
