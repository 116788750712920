import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { map } from 'lodash';
import React from 'react';

import { FieldOption, FieldWithSelectFilter } from 'interfaces/genericFields';
import { Procedure } from 'interfaces/procedure';
import { ProceduresFieldsContext, useProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { QueryFieldProps } from '../QueryFieldInput';

const SingleSelect: React.FC<
  React.PropsWithChildren<QueryFieldProps<FieldWithSelectFilter<Procedure, ProceduresFieldsContext>>>
> = ({ onChange, value, field }) => {
  const { enumType, options: manualOptions, filterType, dataKey, optionsGetter } = field;

  const proceduresFieldsContext = useProceduresFieldsContext();

  const options = enumType
    ? proceduresFieldsContext?.enumDisplayNames?.[enumType] || []
    : optionsGetter
    ? optionsGetter(proceduresFieldsContext)
    : manualOptions;

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const {
      target: { value: targetValue },
    } = event;

    onChange(filterType, dataKey, targetValue);
  };

  return (
    <Select value={value || ''} onChange={handleChange} displayEmpty size="small">
      {map(options, (option: FieldOption) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SingleSelect;
