import { Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getImages } from 'api/platform';
import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import { Study } from 'interfaces/study';
import { concat, map } from 'lodash';
import React from 'react';

const DEV_IMAGE_INTERNAL_NAME = '---dev---';

export const PlatformSettings: React.FC<
  React.PropsWithChildren<{
    updatedSettings: Study['platformSettings'];
    setUpdatedSettings: React.Dispatch<React.SetStateAction<Study['platformSettings']>>;
  }>
> = ({ updatedSettings, setUpdatedSettings }) => {
  const { data: platformImages, isLoading: isLoadingPlatformImages } = useQuery({
    queryKey: ['platformImages'],
    queryFn: ({ signal }) => getImages(signal),
  });

  return (
    <Grid
      sx={{
        p: 2,
      }}
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant="subtitle2">Platform Images</Typography>
      </Grid>
      <Grid item xs={12}>
        <LabelledDropdown
          label="Docker Image Selection"
          options={concat(
            map(platformImages, (image) => ({ value: image, text: image })),
            { value: DEV_IMAGE_INTERNAL_NAME, text: 'dev' }
          )}
          value={updatedSettings?.dockerImage || DEV_IMAGE_INTERNAL_NAME}
          onOptionSelected={(optionValue) => {
            setUpdatedSettings((prevSettings) => ({
              ...prevSettings,
              dockerImage: optionValue !== DEV_IMAGE_INTERNAL_NAME ? optionValue : undefined,
            }));
          }}
          loading={isLoadingPlatformImages}
        />
      </Grid>
    </Grid>
  );
};
