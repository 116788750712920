import { OrchestrationDataType } from './calculateFeatures';

export type QualityControlSource = 'slide' | 'experimentResult';

interface AutomaticConditionBase {
  conditionType: string;
}

export interface AutomaticConditionRange {
  field: string;
  min?: number;
  max?: number;
  source: QualityControlSource;
}

export interface AutomaticConditionLogical {
  operator: ConditionOperator;
  operands: AutomaticCondition[];
}

export interface AutomaticConditionTag {
  tagId: string;
  isAssigned: boolean; // true to assert that the tag is assigned, false to assert that the tag is not assigned
}

export interface AutomaticConditionCell extends AutomaticConditionBase {
  mappingFilter: string;
  filterAction: string;
  toFilter: string[] | number[] | number;
  displayValue?: string;
}

export type AutomaticCondition =
  | AutomaticConditionRange
  | AutomaticConditionLogical
  | AutomaticConditionTag
  | AutomaticConditionCell;

export enum ConditionType {
  CELL = 'CellCondition',
  RANGE = 'RangeCondition',
  LOGICAL = 'LogicalCondition',
  TAG = 'TagCondition',
}

export enum ConditionOperator {
  AND = 'AND',
  OR = 'OR',
}

export const conditionOperatorFilterAction = {
  AND: '$and',
  OR: '$or',
};

export const conditionOperatorFilterActionOptions: {
  label: ConditionOperator;
  value: string;
}[] = [
  {
    label: ConditionOperator.AND,
    value: '$and',
  },
  {
    label: ConditionOperator.OR,
    value: '$or',
  },
];

export enum CategoricalActions {
  IS = 'is',
  IS_NOT = 'is not',
}

export enum NumberActions {
  GT = '>',
  GTE = '>=',
  LT = '<',
  LTE = '<=',
  EQ = '=',
  NEQ = '!=',
}

export const allCategoricalActions = [CategoricalActions.IS, CategoricalActions.IS_NOT];
export const allNumberActions = [
  NumberActions.GT,
  NumberActions.GTE,
  NumberActions.LT,
  NumberActions.LTE,
  NumberActions.EQ,
  NumberActions.NEQ,
];

export enum CellFiltersTypes {
  filter_in = '$in',
  filter_nin = '$nin',
  filter_eq = '$eq',
  filter_neq = '$neq',
  filter_gt = '$gt',
  filter_gte = '$gte',
  filter_lt = '$lt',
  filter_lte = '$lte',
  filter_and = '$and',
  filter_or = '$or',
}

export interface FilterActionOption {
  label: string;
  id: string;
}

export const FilterActions: {
  [key in OrchestrationDataType]: FilterActionOption[];
} = {
  [OrchestrationDataType.Categorical]: [
    { label: CategoricalActions.IS, id: CellFiltersTypes.filter_in },
    { label: CategoricalActions.IS_NOT, id: CellFiltersTypes.filter_nin },
  ],
  [OrchestrationDataType.Float]: [
    { label: '>', id: CellFiltersTypes.filter_gt },
    { label: '>=', id: CellFiltersTypes.filter_gte },
    { label: '<', id: CellFiltersTypes.filter_lt },
    { label: '<=', id: CellFiltersTypes.filter_lte },
    { label: '=', id: CellFiltersTypes.filter_eq },
    { label: '!=', id: CellFiltersTypes.filter_neq },
  ],
  [OrchestrationDataType.Int]: [
    { label: '>', id: CellFiltersTypes.filter_gt },
    { label: '>=', id: CellFiltersTypes.filter_gte },
    { label: '<', id: CellFiltersTypes.filter_lt },
    { label: '<=', id: CellFiltersTypes.filter_lte },
    { label: '=', id: CellFiltersTypes.filter_eq },
    { label: '!=', id: CellFiltersTypes.filter_neq },
  ],
  [OrchestrationDataType.Boolean]: [
    { label: '=', id: CellFiltersTypes.filter_eq },
    { label: '!=', id: CellFiltersTypes.filter_neq },
  ],
  [OrchestrationDataType.CategoricalArray]: [],
  [OrchestrationDataType.BooleanArray]: [],
  [OrchestrationDataType.Point]: [],
  [OrchestrationDataType.Multipolygon]: [],
};
