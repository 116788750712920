import { handleActions } from 'redux-actions';
import { DateActionType, SET_DATE, SET_TEXT, StudyFiltersState, TextActionType } from 'redux/actionTypes/studyFilters';

const initialState: StudyFiltersState = {
  startDate: null,
  endDate: null,
  text: null,
};

const studyFiltersReducer = handleActions(
  {
    [SET_DATE]: (state: StudyFiltersState, action: DateActionType) => ({
      ...state,
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    }),
    [SET_TEXT]: (state: StudyFiltersState, action: TextActionType) => ({
      ...state,
      text: action.payload.text,
    }),
  },
  initialState
);

export default studyFiltersReducer;
