import { isCell, isLogical, isRange, isTag } from 'components/atoms/ConditionBuilder/utils';
import {
  AutomaticCondition,
  CategoricalActions,
  ConditionOperator,
  ConditionType,
} from 'interfaces/automaticCondition';
import { OrchestrationDataType } from 'interfaces/calculateFeatures';
import { MappingFilterMetadata } from 'interfaces/postProcessingAction';
import { Study } from 'interfaces/study';
import React, { FunctionComponent } from 'react';
import CellConditionBuilder from './CellConditionBuilder';
import LogicConditionBuilder from './LogicalConditionBuilder';
import RangeConditionBuilder from './RangeConditionBuilder';
import TagConditionBuilder from './TagConditionBuilder';

export interface AutomaticConditionBuilderProps<T extends AutomaticCondition = AutomaticCondition> {
  condition: T;
  onConditionChange: (condition: T) => void;
  onRemove: () => void;
  study?: Study;
  orchestrationId?: string;
  editable: boolean;
  shouldShowErrors?: boolean;
  featuresOnly?: boolean; // this is used to make 'experimentResult' the default and only source, thereby hiding the source selection
  conditionOptions?: ConditionType[];
  mappingFiltersMetadata?: MappingFilterMetadata[];
  conditionOperators?: ConditionOperator[];
  logicalConditionRecursivelyNumLevels?: number;
  categoricalActions?: CategoricalActions[];
  OrchestrationDataTypes?: OrchestrationDataType[];
}

const AutomaticConditionBuilder: FunctionComponent<React.PropsWithChildren<AutomaticConditionBuilderProps>> = ({
  condition,
  ...props
}) => {
  if (isRange(condition)) {
    return <RangeConditionBuilder {...props} condition={condition} />;
  }
  if (isLogical(condition)) {
    return <LogicConditionBuilder {...props} condition={condition} />;
  }
  if (isTag(condition)) {
    return <TagConditionBuilder {...props} condition={condition} />;
  }
  if (isCell(condition)) {
    return <CellConditionBuilder {...props} condition={condition} />;
  }
  return <></>;
};

export default AutomaticConditionBuilder;
