import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem, TreeView } from '@mui/lab';
import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Placement } from '@popperjs/core';
import { CancerSubtype, CancerSubtypeTreeItem } from 'interfaces/cancerType';
import {
  capitalize,
  castArray,
  difference,
  every,
  first,
  flatMap,
  groupBy,
  includes,
  intersection,
  isEmpty,
  map,
  omit,
  some,
  union,
} from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ProcedureFilterProps } from '../MainFilters';
import {
  getAllTissueCodes,
  getExpandedItems,
  getOptionCodes,
  getTreeDataAsArray,
  searchAndFilterTree,
} from './cancerSubtypeTreeHelpers';

type CancerSubtypesFilterProps<Multiple extends boolean | undefined = true> = Partial<
  ProcedureFilterProps & Omit<AutocompleteProps<string, Multiple, boolean, false>, 'value' | 'multiple'>
>;

export interface OptionalCancerSubtypesAutocompleteProps<Multiple extends boolean | undefined = true> {
  multiple?: Multiple;
  labelMaxWidth?: string | number;
  popperPlacement?: Placement;
  allowSelectAll?: boolean;
  isEdit?: boolean;
  openOnRender?: boolean;
}
export interface CancerSubtypesAutocompleteProps<Multiple extends boolean | undefined = true>
  extends CancerSubtypesFilterProps,
    OptionalCancerSubtypesAutocompleteProps<Multiple> {
  subTree: CancerSubtype[];
  mainCancers?: string[];
  value: Multiple extends true ? string[] : string;
  handleChange: Multiple extends true ? (value: string[]) => void : (value?: string) => void;
}

const CancerSubtypesAutocomplete = <Multiple extends boolean | undefined = true>({
  subTree,
  mainCancers,
  handleChange,
  value,
  multiple,
  isEdit,
  labelMaxWidth = '160px',
  popperPlacement = 'bottom-start',
  allowSelectAll = false,
  openOnRender = false,
  ...autocompleteProps
}: CancerSubtypesAutocompleteProps<Multiple>) => {
  const onChange = (newValue: string[]) => handleChange((multiple ? newValue : first(newValue)) as any);
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [filteredOptions, setFilteredOptions] = React.useState<string[] | undefined>();
  const [inputValue, setInputValue] = React.useState('');
  const [filteredSubtree, setFilteredSubtree] = React.useState<CancerSubtypeTreeItem[] | undefined>(undefined);
  const theme = useTheme();
  const tissues = getTreeDataAsArray(subTree);
  const subTissues = useMemo(() => flatMap(map(tissues, (item) => item.children)), [tissues]);

  const relevantCancerSubtypes = intersection(castArray(value), getAllTissueCodes(subTissues));

  useEffect(() => {
    if (isEmpty(mainCancers)) setInputValue('');
  }, [mainCancers]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const isIndeterminate = (item: CancerSubtypeTreeItem) => {
    return (
      !isEmpty(item.children) &&
      some(item.children, (child) => includes(relevantCancerSubtypes, child.code)) &&
      !every(item.children, (child) => includes(relevantCancerSubtypes, child.code))
    );
  };

  const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>, item: CancerSubtypeTreeItem) => {
    // check/unckeck item + if it has children - expand and check children
    const allCodes = getAllTissueCodes([item]);
    if (e.target.checked) {
      onChange(union(relevantCancerSubtypes, allCodes));
      setExpanded((prevExpanded) => {
        return union(prevExpanded, [item.code]);
      });
    } else {
      onChange(difference(relevantCancerSubtypes, allCodes));
      setExpanded((prevExpanded) => {
        return difference(prevExpanded, [item.code]);
      });
    }
  };

  const getTreeItemsFromData = (treeItems: CancerSubtypeTreeItem[]) => {
    const itemsByParent = groupBy(treeItems, 'parent');

    return map(itemsByParent, (group, parent) => {
      return (
        <div key={parent}>
          {includes(mainCancers, parent) && (
            <Grid container sx={{ py: 2, px: 1 }}>
              <Grid item>
                <Typography variant="h4">{capitalize(parent.toLowerCase())}</Typography>
              </Grid>
            </Grid>
          )}

          {map(group, (item) => {
            const children = getTreeItemsFromData(item.children);
            const isExpanded = !isEmpty(children) && includes(expanded, item.code);
            return (
              <Grid
                container
                key={item.code}
                sx={{
                  '&:first-of-type".': { mt: 1 },
                  whiteSpace: 'nowrap',
                  '& .MuiTreeItem-label': {
                    maxWidth: labelMaxWidth,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
              >
                <Grid item>
                  <Checkbox
                    data-cy={`cancer-subtype-checkbox-${item.code}`}
                    size="small"
                    indeterminate={isIndeterminate(item)}
                    checked={includes(relevantCancerSubtypes, item.code)}
                    onChange={(e) => onChangeCheckbox(e, item)}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    mt: isExpanded ? '9px' : 'initial',
                  }}
                >
                  <TreeItem key={item.code} nodeId={item.code} label={`${item.name} (${item.code})`}>
                    {children}
                  </TreeItem>
                </Grid>
              </Grid>
            );
          })}
        </div>
      );
    });
  };

  const allCodes = getAllTissueCodes(subTissues);

  const toggleExpandAll = () => {
    setExpanded((oldExpanded) => (isEmpty(oldExpanded) ? allCodes : []));
  };

  const toggleCheckAll = () => {
    onChange(every(allCodes, (item) => includes(relevantCancerSubtypes, item)) ? [] : allCodes);
  };

  const options = getOptionCodes(subTissues);

  const childrenExist = some(subTissues, (item) => !isEmpty(item.children));

  const [open, setOpen] = useState(openOnRender);

  return (
    <Autocomplete<string, boolean, boolean, false>
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      clearOnBlur={false}
      sx={{
        width: '100%',
        '&.MuiAutocomplete-root': {
          '& .MuiButtonBase-root': { maxWidth: labelMaxWidth },
        },
        ...autocompleteProps?.sx,
      }}
      size="small"
      value={relevantCancerSubtypes}
      disableCloseOnSelect
      blurOnSelect={false}
      options={filteredOptions || options}
      onChange={(event: any, newValue: any | null) => {
        onChange(newValue);
      }}
      inputValue={inputValue}
      openOnFocus={true}
      getOptionLabel={(option) => {
        const searchResult = searchAndFilterTree(tissues, option.toLowerCase(), true);
        const displayName = first(searchResult.matchingNodes)?.name;
        return displayName ? `${displayName} (${option})` : option;
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'clear') {
          setFilteredOptions(undefined);
          setFilteredSubtree(undefined);
        } else {
          setInputValue(newInputValue);
          const matchingNodes = searchAndFilterTree(subTissues, newInputValue.toLowerCase())?.matchingNodes;
          const expandedNodes = getExpandedItems(matchingNodes, subTissues);

          setExpanded(expandedNodes);
          if (newInputValue === '') {
            setFilteredOptions(undefined);
            setFilteredSubtree(undefined);
          } else {
            setFilteredOptions(map(matchingNodes, (item) => item.code));
            const sub = searchAndFilterTree(subTissues, newInputValue.toLowerCase())?.processedItems;
            setFilteredSubtree(sub);
          }
        }
      }}
      id="cancer-subtype-field"
      filterOptions={() => filteredOptions || options}
      componentsProps={{
        popper: {
          style: {
            zIndex: 2000,
            overflowY: 'auto',
            maxHeight: '500px',
            border: `1px solid ${theme.palette.grey[300]}`,
          },
          placement: popperPlacement,
        },
      }}
      renderInput={(params) => <TextField {...params} label={isEdit ? null : 'Cancer Subtype'} />}
      ListboxComponent={React.forwardRef((props, ref) => {
        const isAllChecked = every(options, (item) => includes(relevantCancerSubtypes, item));
        return (
          <Box sx={{ flexGrow: 1, overflowY: 'auto' }} onMouseDown={props.onMouseDown} ref={ref}>
            <Grid container direction="column">
              {isEmpty(inputValue) && !isEmpty(subTissues) && (
                <Grid
                  container
                  justifyContent="space-between"
                  sx={{ mt: 1, mb: 0, pr: 3, borderBottom: `1px solid ${theme.palette.grey[200]}` }}
                >
                  {allowSelectAll && (
                    <Grid item>
                      <FormControlLabel
                        onClick={(e) => {
                          e.preventDefault();
                          toggleCheckAll();
                        }}
                        label="Select all"
                        control={<Checkbox id="select-all-checkbox" size="small" checked={isAllChecked} />}
                        sx={{ px: 2, width: '100%', mx: 0 }}
                      />
                    </Grid>
                  )}
                  {childrenExist && (
                    <Grid item sx={{ px: 2 }}>
                      <Button data-cy="expand_all_cancer_subtypes" onClick={toggleExpandAll}>
                        {isEmpty(expanded) ? 'Expand all' : 'Collapse all'}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item>
              {!isEmpty(subTissues) ? (
                <TreeView
                  sx={{ mb: 2, p: 2 }}
                  data-cy="cancer-subtypes-tree-options"
                  aria-label="controlled"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  expanded={expanded}
                  selected={relevantCancerSubtypes}
                  onNodeToggle={handleToggle}
                  multiSelect
                >
                  {getTreeItemsFromData(filteredSubtree || subTissues)}
                </TreeView>
              ) : (
                <Typography variant="body1" sx={{ my: 4, p: 2 }}>
                  No matches found
                </Typography>
              )}
            </Grid>
          </Box>
        );
      })}
      {...omit(autocompleteProps, 'sx')}
      // We implement both the single and multiple select ourselves, abstracting over the Autocomplete component's multiple behaviour
      multiple={true}
    />
  );
};

export default CancerSubtypesAutocomplete;
