import { Grid, Typography, useTheme } from '@mui/material';
import parse from 'html-react-parser';
import { isArray } from 'lodash';
import React, { FC } from 'react';

import { FeatureFormatter } from 'interfaces/featureFormatter';
import { FormattedFeature } from 'interfaces/features';
import { getFeatureFormattedValue } from 'utils/features';

interface FeatureItemProps {
  feature: FormattedFeature;
  formatter: FeatureFormatter & { nonFormattedUnits?: string };
  isLoadingFormatterData: boolean;
}

const FeatureItem: FC<React.PropsWithChildren<FeatureItemProps>> = ({ feature, formatter, isLoadingFormatterData }) => {
  const theme = useTheme();

  return !isLoadingFormatterData && !isArray(feature.value) ? (
    <Grid
      key={feature.key}
      container
      justifyContent="space-between"
      sx={{ borderBottom: `1px solid ${theme.palette.grey[100]}`, p: 1 }}
    >
      <Grid container item xl={8} xs={9} alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="subtitle2">{feature?.nameOverride || feature.displayName}</Typography>
        </Grid>
        {formatter && formatter.units && (
          <Grid item>
            <Typography variant="caption">[{parse(formatter.units)}]</Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item xl={4} xs={3} spacing={1} justifyContent="end">
        <Grid item>
          <Typography variant="subtitle2">
            {getFeatureFormattedValue(feature.value, formatter?.valueFormat, formatter?.smallerThan)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

export default FeatureItem;
