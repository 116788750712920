import { JobPreset } from 'interfaces/jobPreset';
import { stringify } from 'qs';
import { ArrayParam, encodeQueryParams } from 'use-query-params';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getJobPresets = (steps: string[], signal?: AbortSignal) => {
  const stringParams = stringify(encodeQueryParams({ steps: ArrayParam }, { steps }), {
    arrayFormat: 'repeat',
  });

  return apiRequestHandlerPromise<JobPreset[]>({
    url: `job-preset?${stringParams}`,
    method: 'GET',
    signal,
  });
};

export const createJobPreset = (data: {
  name: string;
  stains: string[];
  steps: string[];
  presetJson: any;
  sourceStudyId: string;
}) => {
  return apiRequestHandlerPromise<JobPreset>({
    url: `job-preset`,
    method: 'POST',
    data: JSON.stringify(data),
  });
};

export const updateJobPreset = (data: {
  id: string;
  stains: string[];
  steps: string[];
  presetJson: any;
  sourceStudyId: string;
}) => {
  return apiRequestHandlerPromise<JobPreset>({
    url: `job-preset/${data.id}`,
    method: 'PUT',
    data: JSON.stringify(data),
  });
};

export const deleteJobPreset = (id: string) => {
  return apiRequestHandlerPromise({
    url: `job-preset/${id}`,
    method: 'DELETE',
  });
};
