import { QueryKey } from '@tanstack/react-query';
import { stringify } from 'qs';

import { Annotation, AnnotationExtension } from 'interfaces/annotation';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getSlideAnnotations = ({
  slideId,
  includeEmpty,
  signal,
}: {
  slideId: string;
  includeEmpty?: boolean;
  signal?: AbortSignal;
}) => {
  const stringifiedParams = stringify({ includeEmpty });
  return apiRequestHandlerPromise<Annotation[]>({
    url: `slides/${slideId}/annotation${stringifiedParams ? `?${stringifiedParams}` : ''}`,
    method: 'GET',
    signal,
  });
};

export const getSlideAnnotationsQueryKey = ({
  slideId,
  includeEmpty,
}: {
  slideId: string;
  includeEmpty?: boolean;
}): QueryKey => ['annotations', { slideId, includeEmpty }];

export const saveAnnotationDraft = ({
  annotationAssignmentId,
  slideId,
  annotationsData,
}: {
  annotationAssignmentId: number;
  slideId: string;
  annotationsData: AnnotationExtension['extensionAnnotationsData'];
}) => {
  return apiRequestHandlerPromise<void>({
    url: `annotation_assignments/${annotationAssignmentId}/slides/${slideId}/draft`,
    method: 'POST',
    data: JSON.stringify({ extensionAnnotationsData: annotationsData }),
  });
};

export const publishAnnotationVersion = ({
  annotationAssignmentId,
  slideId,
}: {
  annotationAssignmentId: number;
  slideId: string;
}) => {
  return apiRequestHandlerPromise<void>({
    url: `annotation_assignments/${annotationAssignmentId}/slides/${slideId}/publish`,
    method: 'POST',
  });
};
