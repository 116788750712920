import CloseIcon from '@mui/icons-material/Close';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { DialogTitle, IconButton, TextField, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useMutation } from '@tanstack/react-query';
import { saveCohort } from 'api/cohorts';
import Loader from 'components/Loader';
import ModalWrapper from 'components/ModalWrapper';
import { Cohort, CohortInputData, CohortQueryObject, cohortQuerySelectionKeys } from 'interfaces/cohort';
import { isEmpty, pick } from 'lodash';
import React, { ReactElement } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { getFiltersFromQueryObject } from 'utils/advancedFilterAdapter';
import { humanize } from 'utils/helpers';
import queryClient from 'utils/queryClient';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { useProceduresWithQAExperimentResultsOnly } from 'utils/useProceduresWithQAExperimentResultsOnly';
import CohortQueryList from './CohortQueryList';

type SaveType = 'cohort' | 'query';

interface SaveCohortModalProps {
  onClose: () => void;
  isOpen: boolean;
  queryObject?: CohortQueryObject;
  onlyCohort?: boolean; // and not search query
}

const SaveCohortModal = ({ onClose, isOpen, queryObject, onlyCohort }: SaveCohortModalProps): ReactElement => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CohortInputData>();

  const { labId } = useCurrentLabId();

  const theme = useTheme();

  const [, setAdvancedSearchDrawerOpen] = useQueryParam('advancedSearchDrawerOpen', BooleanParam);

  const { generateEncodedParams, setQueryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const { data: currentProcedureResponse } = useProceduresWithQAExperimentResultsOnly();

  const cohortQueryObjectForList = {
    ...pick(queryObject, ['features', 'searchTerm'], cohortQuerySelectionKeys),
    filters: getFiltersFromQueryObject(queryObject?.filters),
    clinicalData: getFiltersFromQueryObject(queryObject?.clinicalData),
  };

  const mutation = useMutation(saveCohort, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(['cohorts', labId]);
      queryClient.setQueriesData(['cohorts', labId], (oldData: Cohort[]): Cohort[] => {
        return [...oldData, response];
      });
      const cohortParams = {
        cohortId: response?.id,
        cohortName: response?.name,
        ...(response?.casesSnapshot
          ? { filters: { cohortIds: [response.id] }, features: null, clinicalData: null, searchTerm: null }
          : pick(queryObject, ['filters', 'features', 'clinicalData', 'searchTerm'])),
      };
      if (response?.casesSnapshot) {
        setQueryParams(cohortParams);

        // if the cohort is created from the 'save search' button (and not from selection) then we don't know the response
        if (
          currentProcedureResponse &&
          isEmpty(queryObject.caseIdsToExclude) &&
          isEmpty(queryObject.caseIdsToInclude) &&
          isEmpty(queryObject.slideIdsToExclude) &&
          isEmpty(queryObject.slideIdsToInclude)
        ) {
          queryClient.setQueryData(['procedures', generateEncodedParams(cohortParams)], currentProcedureResponse);
        }
      }

      setAdvancedSearchDrawerOpen(false);
      onClose();
    },
  });

  const { isLoading } = mutation;

  const onChangeClick = () => {
    onClose();
    setAdvancedSearchDrawerOpen(true);
  };

  const saveCohortOrQuery = (data: Partial<CohortInputData>, saveType: SaveType) => {
    const name = isEmpty(data.name) ? `Untitled ${saveType}` : data.name;
    const isCohort = saveType === 'cohort';
    const cohort = { ...data, name, queryObject, labId, saveSnapshot: isCohort };
    mutation.mutate(cohort);
  };

  const onSaveAsCohort: SubmitHandler<Partial<CohortInputData>> = (data) => {
    saveCohortOrQuery(data, 'cohort');
  };

  const onSaveAsQuery: SubmitHandler<Partial<CohortInputData>> = (data) => {
    saveCohortOrQuery(data, 'query');
  };

  return (
    <ModalWrapper onClose={onClose} isOpen={isOpen}>
      <form data-testid="save-cohort-form">
        <div>
          {!isLoading && (
            <Grid container justifyContent="space-between">
              <Grid item>
                <DialogTitle variant="h2" sx={{ textTransform: 'uppercase', p: 0 }}>
                  {`Create New Cohort ${!onlyCohort ? 'or Query' : ''}`}
                </DialogTitle>
              </Grid>
              <Grid item>
                <IconButton onClick={onClose} sx={{ p: 0 }}>
                  <CloseIcon fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
          )}

          {isLoading ? (
            <Loader />
          ) : (
            <Grid container direction="column" sx={{ mt: 1 }} spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="name"
                  render={({ field: { onChange } }) => (
                    <TextField
                      label="Name"
                      {...register('name')}
                      onChange={onChange}
                      placeholder="Name"
                      error={Boolean(errors['name'])}
                      helperText={humanize(errors['name']?.message)}
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { onChange } }) => (
                    <TextField
                      multiline
                      {...register('description')}
                      onChange={onChange}
                      placeholder="Description"
                      minRows={3}
                      label="Description"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    p: 2,
                    border: `1px solid ${
                      theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700]
                    }`,
                  }}
                >
                  <Grid container item direction="column" spacing={2}>
                    <Grid item container>
                      <Grid item>
                        <Typography variant="h6" component="h2">
                          Filters
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button onClick={onChangeClick} color="primary">
                          (Change)
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Box
                        sx={{
                          maxHeight: '120px',
                          overflowY: 'scroll',
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <CohortQueryList queryObject={cohortQueryObjectForList} showSelection={onlyCohort} />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}

          {!isLoading && (
            <Grid container justifyContent="space-between" mt={2}>
              <Grid item>
                <Button onClick={onClose} variant="contained" color="secondary" size="medium">
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  {!onlyCohort && (
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={handleSubmit(onSaveAsQuery)}
                        endIcon={<FilterAltIcon />}
                      >
                        Save as query
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="medium"
                      onClick={handleSubmit(onSaveAsCohort)}
                      endIcon={<CollectionsBookmarkIcon />}
                    >
                      Save as cohort
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      </form>
    </ModalWrapper>
  );
};

export default SaveCohortModal;
