import React, { ReactElement } from 'react';
import ModalWrapper from 'components/ModalWrapper';
import UploadStudyForm, { IFormProps } from './UploadStudyForm';

const UploadStudyModal = (props: IFormProps): ReactElement => {
  const { onClose } = props;
  return (
    <ModalWrapper onClose={onClose} isOpen>
      <UploadStudyForm {...props} />
    </ModalWrapper>
  );
};

export default UploadStudyModal;
