import { humanize } from 'utils/helpers';
import { DisplayedField } from '../../genericFields';
import { getUpdatePathForProcedureClinicalDataField } from './helpers';
import { Procedure } from 'interfaces/procedure';

export const sexField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'select',` if/when we allow clinical data editing
  filterType: 'checkbox-group',
  dataCategory: 'clinical',
  dataKey: 'sex',
  label: 'Sex',
  enumType: 'sex',
  columnWidth: { width: 80 },
  valueGetter: (procedure) => procedure?.clinicalData?.sex,
  updatePath: getUpdatePathForProcedureClinicalDataField('sex'),
};

export const yearOfBirthField: DisplayedField<Procedure, number> = {
  // TODO: set `cellEditType: 'date',` if/when we allow clinical data editing
  filterType: 'date-range',
  dataCategory: 'clinical',
  dataKey: 'year_of_birth',
  label: 'Year of Birth',
  views: ['year'],
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.yearOfBirth,
  updatePath: getUpdatePathForProcedureClinicalDataField('yearOfBirth'),
  // TODO: set to year only in individual cell editor
};

export const bmiField: DisplayedField<Procedure, number> = {
  // TODO: set `cellEditType: 'number',` if/when we allow clinical data editing
  filterType: 'range',
  dataCategory: 'clinical',
  dataKey: 'bmi',
  label: 'BMI',
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.bmi,
  updatePath: getUpdatePathForProcedureClinicalDataField('bmi'),
};

export const raceField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'select',` if/when we allow clinical data editing
  filterType: 'multiSelect',
  dataCategory: 'clinical',
  dataKey: 'race',
  label: 'Race',
  enumType: 'race',
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.race,
  valueFormatter: ({ value }) => humanize(value),
  updatePath: getUpdatePathForProcedureClinicalDataField('race'),
};

export const ethnicityField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'select',` if/when we allow clinical data editing
  filterType: 'multiSelect',
  dataCategory: 'clinical',
  dataKey: 'ethnicity',
  label: 'Ethnicity',
  enumType: 'ethnicity',
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.ethnicity,
  valueFormatter: ({ value }) => humanize(value),
  updatePath: getUpdatePathForProcedureClinicalDataField('ethnicity'),
};

export const smokingStatusField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'select',` if/when we allow clinical data editing
  filterType: 'checkbox-group',
  dataCategory: 'clinical',
  dataKey: 'smoking_status',
  label: 'Smoking Status',
  enumType: 'smokingStatus',
  columnWidth: { width: 80 },
  valueGetter: (procedure) => procedure?.clinicalData?.smokingStatus,
  updatePath: getUpdatePathForProcedureClinicalDataField('smokingStatus'),
};

export const smokingHistoryField: DisplayedField<Procedure, string> = {
  // TODO: set `cellEditType: 'number',` if/when we allow clinical data editing
  filterType: 'range',
  dataCategory: 'clinical',
  dataKey: 'smoking_history_packs_per_year',
  label: 'Smoking History (Pack/Year)',
  min: 0,
  columnWidth: { width: 120 },
  valueGetter: (procedure) => procedure?.clinicalData?.smokingHistory,
  updatePath: getUpdatePathForProcedureClinicalDataField('smokingHistory'),
};

export default [
  sexField,
  yearOfBirthField,
  ethnicityField,
  raceField,
  bmiField,
  smokingStatusField,
  smokingHistoryField,
];
