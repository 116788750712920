import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteOverviewPreset, getOverviewPresets, saveOverviewPreset, updateOverviewPreset } from 'api/overviewPreset';
import { ChartKeyType, highlightedFeaturesToCharts } from 'components/FeaturesDashboard/chart.util';
import { ControlledChartOptions } from 'components/FeaturesDashboard/ControlledChart';
import useMainFilters from 'components/SearchFilters/hooks/useMainFilters';
import { ChartType } from 'interfaces/chart';
import { OverviewPreset } from 'interfaces/overviewPreset';
import { Study } from 'interfaces/study';
import { concat, find, findIndex, forEach, omitBy, values } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useMemo } from 'react';
import useLocalStorage from 'use-local-storage';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';

export const useSaveOverviewPresetMutations = (
  currentStudy: Study,
  {
    onSuccess: additionalOnSuccess,
    onError: additionalOnError,
  }: {
    onSuccess?: (updatedStudy: Partial<OverviewPreset>) => void;
    onError?: (error: any, previousValue: Partial<OverviewPreset>) => void;
  } = {}
) => {
  const queryClient = useQueryClient();
  const { labId } = useCurrentLabId();

  const savePresetMutation = useMutation(saveOverviewPreset, {
    onMutate: async (preset) => {
      await queryClient.cancelQueries(['overviewPresets', labId, currentStudy?.id]);
      const previousValue = queryClient.getQueryData(['overviewPresets', labId, currentStudy?.id]);
      queryClient.setQueryData(['overviewPresets', labId, currentStudy?.id], (presets: OverviewPreset[]) => [
        ...presets,
        preset,
      ]);

      return previousValue;
    },
    onError: (error: any, previousValue) => {
      if (error.status === 403) {
        enqueueSnackbar('You are not allowed to create presets for this lab', { variant: 'error' });
      } else {
        enqueueSnackbar('Failed to save preset', { variant: 'error' });
      }
      additionalOnError?.(error, previousValue);
      queryClient.setQueryData(['overviewPresets', labId, currentStudy?.id], previousValue);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['overviewPresets', labId, currentStudy?.id]);
    },
    onSuccess: (newPreset: Partial<OverviewPreset>) => {
      additionalOnSuccess?.(newPreset);
      enqueueSnackbar('Preset saved successfully', { variant: 'success' });
    },
  });

  return {
    savePresetMutation,
  };
};

export const useDeleteOverviewPresetMutations = (
  study: Study,
  {
    onMutate: additionalOnMutate,
  }: {
    onMutate?: () => void;
  } = {}
) => {
  const queryClient = useQueryClient();
  const { labId } = useCurrentLabId();

  const deletePresetMutation = useMutation(deleteOverviewPreset, {
    onMutate: async (presetIdToDelete) => {
      additionalOnMutate();
      await queryClient.cancelQueries(['overviewPresets', labId, study?.id]);
      const previousValue = queryClient.getQueryData(['overviewPresets', labId, study?.id]);
      queryClient.setQueryData(['overviewPresets', labId, study?.id], (presets: OverviewPreset[]) => {
        const indexOfPreset = findIndex(presets, (preset) => preset.id === presetIdToDelete);
        return [...presets.slice(0, indexOfPreset), ...presets.slice(indexOfPreset + 1)];
      });
      return previousValue;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['overviewPresets', labId, study?.id]);
      enqueueSnackbar('Preset deleted', { variant: 'success' });
    },
    onError: (error: any, previousValue) => {
      if (error.status === 403) {
        enqueueSnackbar('You are not allowed to delete this preset', { variant: 'error' });
      } else {
        enqueueSnackbar('Preset deletion failed', { variant: 'error' });
      }
      queryClient.setQueryData(['overviewPresets', labId, study?.id], previousValue);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['overviewPresets', labId, study?.id]);
    },
  });

  return { deletePresetMutation };
};

export const useUpdateOverviewPresetMutations = (study: Study) => {
  const queryClient = useQueryClient();
  const { labId } = useCurrentLabId();

  const updatePresetMutation = useMutation(updateOverviewPreset, {
    onSuccess: () => {
      queryClient.invalidateQueries(['overviewPresets', labId, study?.id]);
      enqueueSnackbar('Preset updated successfully', { variant: 'success' });
    },
    onError: (error: any) => {
      if (error.status === 403) {
        enqueueSnackbar('You are not allowed to update this preset', { variant: 'error' });
      } else {
        enqueueSnackbar('Failed to update preset', { variant: 'error' });
      }
    },
  });

  return { updatePresetMutation };
};

export const useOverviewPresets = (
  study: Study,
  {
    enabled = true,
  }: {
    enabled?: boolean;
  } = {}
) => {
  const { labId } = useCurrentLabId();
  const { queryParams } = useEncodedFilters();
  const { isStudyIdSpecific } = useMainFilters();

  const studyId = queryParams?.filters?.studyId || study?.id;

  const { data: overviewPresets, isLoading: isPresetsLoading } = useQuery<OverviewPreset[]>(
    ['overviewPresets', labId, studyId],
    () => getOverviewPresets(labId, studyId),
    {
      enabled: isStudyIdSpecific(studyId) && enabled,
    }
  );

  const [userLocalCasesOverviewPresets, setUserLocalCasesOverviewPresets] = useLocalStorage<Record<string, string>>(
    'userSelectedCasesOverviewPresets',
    {}
  );

  const highlightedFeaturesCharts: Record<number, ControlledChartOptions> = useMemo(() => {
    const charts = highlightedFeaturesToCharts(study?.highlightedFeatures, study?.inferredFeaturesConfig);
    // once we add support for distance based charts in the overview, we need to remove this omission
    return omitBy(charts, (chart) => chart.type === ChartType.DistanceBased);
  }, [study?.highlightedFeatures]);

  const defaultPresetCharts: Record<number, ControlledChartOptions> = useMemo(() => {
    const charts: Record<number, ControlledChartOptions> = {};
    forEach(
      concat(values(highlightedFeaturesCharts), values(defaultMetadataCharts)),
      (chart: ControlledChartOptions, index: number) => {
        charts[index] = chart;
      }
    );
    return charts;
  }, [highlightedFeaturesCharts]);

  const defaultPreset: Partial<OverviewPreset> = useMemo(
    () => ({
      id: defaultPresetId,
      name: defaultPresetName,
      createdBy: defaultPresetCreatedBy,
      preset: defaultPresetCharts,
    }),
    [defaultPresetCharts]
  );

  const getPresetById = (id: string | null): Partial<OverviewPreset> => {
    return find(overviewPresets, (preset) => preset.id === id) || defaultPreset;
  };

  const getUserDefaultPreset = () => {
    if (!study || !overviewPresets) {
      return undefined;
    } else {
      if (userLocalCasesOverviewPresets[study.id]) {
        return getPresetById(userLocalCasesOverviewPresets[study.id]);
      } else if (study.defaultOverviewPresetId) {
        return getPresetById(study.defaultOverviewPresetId);
      } else {
        return defaultPreset;
      }
    }
  };

  return {
    overviewPresets,
    isPresetsLoading,
    getPresetById,
    getUserDefaultPreset,
    defaultPreset,
    userLocalCasesOverviewPresets,
    setUserLocalCasesOverviewPresets,
  };
};

export const defaultMetadataCharts: Record<number, ControlledChartOptions> = {
  1: { type: ChartType.Pie, categoricalKey: { name: 'cancerTypeId', type: ChartKeyType.Categorical } },
  2: { type: ChartType.Pie, categoricalKey: { name: 'biopsySiteId', type: ChartKeyType.Categorical } },
  3: { type: ChartType.Pie, categoricalKey: { name: 'stainingType', type: ChartKeyType.Categorical } },
  4: { type: ChartType.Pie, categoricalKey: { name: 'cancerSubtypes', type: ChartKeyType.Categorical } },
  5: { type: ChartType.Pie, categoricalKey: { name: 'biopsyType', type: ChartKeyType.Categorical } },
};

const defaultPresetName = 'Default';
const defaultPresetId = 'Default';
const defaultPresetCreatedBy = 'Default';
