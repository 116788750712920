import { Permission } from 'interfaces/permissionOption';
import { InternalUser, UserCredentials, UserCredentialsPaginatedResponse } from 'interfaces/userCredentials';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const setUserCredentialsRolesAndPermissions = ({
  id,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  name,
  applyToAllLabs = false,
  ...userCredentialsParams
}: UserCredentials & { labId: string; applyToAllLabs?: boolean }) => {
  if (!id) {
    throw new Error('User ID is required');
  }
  return apiRequestHandlerPromise<string>({
    url: `users/credentials/${encodeURI(id)}`,
    method: 'POST',
    data: JSON.stringify({ ...userCredentialsParams, applyToAllLabs: Boolean(applyToAllLabs) }),
  });
};

export const getInternalUsers = () => {
  return apiRequestHandlerPromise<InternalUser[]>({
    url: `users/internal`,
    method: 'GET',
  });
};

export const getUserCredentialsPaginatedResponse = (
  labId: string,
  {
    page = 0,
    perPage = 100,
    ascending = true,
    sortField = 'email',
    listNucleaiUsers = false,
    searchQuery = '',
  }: {
    page?: number;
    perPage?: number;
    ascending?: boolean;
    sortField?: string;
    listNucleaiUsers?: boolean;
    searchQuery?: string;
  } = {
    page: 0,
    perPage: 100,
    ascending: true,
    sortField: 'email',
    listNucleaiUsers: false,
    searchQuery: '',
  }
) => {
  return apiRequestHandlerPromise<UserCredentialsPaginatedResponse>({
    url: `users?${stringify({ labId, page, perPage, ascending, sortField, listNucleaiUsers, searchQuery })}`,
    method: 'GET',
  });
};

export const getUserLabPermissions = (labId: string) => {
  return apiRequestHandlerPromise<Permission[]>({
    url: `users/credentials?${stringify({ labId })}`,
    method: 'GET',
  });
};
