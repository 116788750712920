import { Grid } from '@mui/material';
import { map } from 'lodash';
import React from 'react';
import { Virtuoso } from 'react-virtuoso';

import { FormattedFeature } from 'interfaces/features';
import { useFindFormatterByKeyWithContext } from 'utils/features/contextHooks';
import FeatureItem from './FeatureItem';

interface FeatureListProps {
  filteredFeatures: FormattedFeature[];
  skipVirtualization?: boolean;
  addStain: boolean;
}

export const featureListHeight = 300;

const FeatureListInner: React.FunctionComponent<
  React.PropsWithChildren<{
    skipVirtualization: boolean;
    filteredFeatures: FormattedFeature[];
    isLoadingFormatterData: boolean;
    findFormatterByKeyWithContext: ReturnType<typeof useFindFormatterByKeyWithContext>['findFormatterByKeyWithContext'];
  }>
> = React.memo(({ skipVirtualization, filteredFeatures, isLoadingFormatterData, findFormatterByKeyWithContext }) => {
  if (skipVirtualization) {
    return (
      <Grid item xs={12}>
        {map(filteredFeatures, (feature) => (
          <FeatureItem
            key={feature.key}
            feature={feature}
            isLoadingFormatterData={isLoadingFormatterData}
            formatter={findFormatterByKeyWithContext(feature.key)}
          />
        ))}
      </Grid>
    );
  }
  return (
    <Grid item xs={12} height={featureListHeight}>
      <Virtuoso
        data={filteredFeatures}
        itemContent={(index, feature) => (
          <FeatureItem
            key={feature.key}
            feature={feature}
            isLoadingFormatterData={isLoadingFormatterData}
            formatter={findFormatterByKeyWithContext(feature.key)}
          />
        )}
      />
    </Grid>
  );
});

const FeatureList: React.FunctionComponent<React.PropsWithChildren<FeatureListProps>> = ({
  filteredFeatures,
  skipVirtualization,
  addStain,
}) => {
  const { findFormatterByKeyWithContext, isLoadingFormatterData } = useFindFormatterByKeyWithContext(addStain);

  return (
    <FeatureListInner
      skipVirtualization={skipVirtualization}
      filteredFeatures={filteredFeatures}
      isLoadingFormatterData={isLoadingFormatterData}
      findFormatterByKeyWithContext={findFormatterByKeyWithContext}
    />
  );
};

export default FeatureList;
