import { GridRowParams } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import React from 'react';

import { doesUpdatingRowHaveRequiredFields, isUpdatingRowUnique } from 'components/atoms/EditableDataGrid/helpers';
import { AreaType } from 'interfaces/areaType';
import { idField } from 'interfaces/areaType/areaTypeFields';
import { uuidv4 } from 'utils/helpers';
import { AreaTypeDraft } from './types';

export const generateDraftId = () => `draft-${uuidv4()}`;
export const getAreaTypeId = (row: AreaType | AreaTypeDraft) =>
  (row as AreaTypeDraft).draftId ?? row.id ?? generateDraftId();

export const getAreaTypeError = ({
  id,
  apiRef,
}: GridRowParams<AreaType> & {
  apiRef: React.MutableRefObject<GridApiCommunity>;
}) => {
  const hasDuplicateId = !isUpdatingRowUnique({
    apiRef,
    rowId: id,
    uniqueFields: ['id'],
    getId: getAreaTypeId,
  });
  const idError = idField.getError?.({ value: apiRef?.current?.getRowWithUpdatedValues?.(id, 'id').id });

  const hasDuplicateDisplayName = !isUpdatingRowUnique({
    apiRef,
    rowId: id,
    uniqueFields: ['displayName'],
    getId: getAreaTypeId,
  });

  const missingId = !doesUpdatingRowHaveRequiredFields({
    apiRef,
    rowId: id,
    requiredFields: ['id'],
  });

  const missingDisplayName = !doesUpdatingRowHaveRequiredFields({
    apiRef,
    rowId: id,
    requiredFields: ['displayName'],
  });

  const missingSourceType = !doesUpdatingRowHaveRequiredFields({
    apiRef,
    rowId: id,
    requiredFields: ['sourceType'],
  });

  return hasDuplicateId
    ? 'Duplicate ID'
    : missingId
    ? 'Missing ID'
    : idError
    ? idError
    : hasDuplicateDisplayName
    ? 'Duplicate display Name'
    : missingDisplayName
    ? 'Missing display Name'
    : missingSourceType
    ? 'Missing source Type'
    : '';
};
