import Pin from 'interfaces/pin';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getProcedureSlidePinCommentsUrl = (procedureId: number, slideId: string) =>
  `procedures/${procedureId}/${slideId}/pins`;

export const procedureSlidePinCommentsQueryKey = (procedureId: number, slideId: string) => [
  'pins',
  procedureId,
  slideId,
];

export const getProcedureSlidePinComments = async ({
  procedureId,
  slideId,
  signal,
}: {
  procedureId: number;
  slideId: string;
  signal?: AbortSignal;
}) => {
  const pinUrl = getProcedureSlidePinCommentsUrl(procedureId, slideId);
  return apiRequestHandlerPromise<{ pins: Pin[] }>({ url: pinUrl, method: 'GET', signal });
};

export const createProcedureSlidePinComment = ({
  pin,
  slideId,
  procedureId,
}: {
  procedureId: number;
  slideId: string;
  pin: Pin;
}) => {
  const data = JSON.parse(JSON.stringify(pin));
  const pinUrl = getProcedureSlidePinCommentsUrl(procedureId, slideId);
  return apiRequestHandlerPromise({ url: pinUrl, data, method: 'POST' });
};

export const deleteProcedureSlidePinComment = ({
  pin,
  slideId,
  procedureId,
}: {
  procedureId: number;
  slideId: string;
  pin: Pick<Pin, 'id'>;
}) => {
  const pinUrl = getProcedureSlidePinCommentsUrl(procedureId, slideId);
  return apiRequestHandlerPromise({ url: `${pinUrl}/${pin.id}`, method: 'DELETE' });
};

export const updateProcedureSlidePinComment = ({
  pin,
  slideId,
  procedureId,
}: {
  procedureId: number;
  slideId: string;
  pin: Pin;
}) => {
  const data = JSON.parse(JSON.stringify(pin));
  const pinUrl = getProcedureSlidePinCommentsUrl(procedureId, slideId);
  return apiRequestHandlerPromise({ url: `${pinUrl}/${pin.id}`, data, method: 'PUT' });
};
