import { filter, first, flatMap, isEmpty, map, uniq } from 'lodash';
import React, { useState } from 'react';
import { filterSearchOptions } from 'services/matchSort';
import { CaseSearchItem } from 'services/searchIndex';
import { ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { useSearchIndex } from 'utils/queryHooks/useSearchIndex';

export const fullSearchKeys = ['caseLabel', 'caseId', 'slides.*.id', 'slides.*.fileName'];

interface FullSearchProps {
  enabled?: boolean;
  studyId?: string;
  controlledValue?: string;
  isDrawer?: boolean;
  handleChange?: (event: React.SyntheticEvent<Element, Event>, value: string | CaseSearchItem) => void;
}

// stateful hook for full search
export const useFullSearch = ({ enabled = true, studyId, isDrawer, handleChange }: FullSearchProps = {}) => {
  const [searchQuery, setSearchQuery] = useQueryParam('searchTerm', StringParam);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<CaseSearchItem[]>([]);
  const [, setCaseIdsToInclude] = useQueryParam('caseIdsToInclude', ArrayParam);
  const [, setSlideIdsToInclude] = useQueryParam('slideIdsToInclude', ArrayParam);
  const [, setPage] = useQueryParam('page', NumberParam);
  const [slidesMode] = useQueryParam('slidesMode', BooleanParam);
  const [searchHasNoResults, setSearchHasNoResults] = useState<{ [key: string]: boolean }>({});

  const {
    index,
    isSuccess: searchIndexSuccess,
    isLoading: isSearchIndexLoading,
    isError: isErrorSearchIndex,
  } = useSearchIndex({ enabled });
  const allCaseItems: CaseSearchItem[] = index ?? [];
  // if slidesMode is on, we need to flatten the search index to have a single slide per item
  const allSearchItems = slidesMode
    ? flatMap(allCaseItems, (caseItem) => map(caseItem?.slides, (slide) => ({ ...caseItem, slides: [slide] })))
    : allCaseItems;

  const handleSearch = (searchTerm: string) => {
    const filteredOptionsOfSearch = filterSearchOptions<CaseSearchItem>(fullSearchKeys, allSearchItems, searchTerm);
    setFilteredOptions(filteredOptionsOfSearch);
    setSearchHasNoResults((prevValue) => ({ ...prevValue, [searchTerm]: isEmpty(filteredOptionsOfSearch) }));
  };

  const clearSearch = () => {
    setSearchInputValue('');
    setSearchQuery(undefined);
    setFilteredOptions([]);
    setCaseIdsToInclude(undefined);
    setSlideIdsToInclude(undefined);
  };

  const handleApplyFreeSoloSearch = (search: string) => {
    if (isDrawer) {
      // should update advanced input value
      handleChange(null, search);
    } else if (search === '') {
      clearSearch();
    } else {
      setPage(1, 'replaceIn');
      const filteredOptionsOfSearch = filterSearchOptions<CaseSearchItem>(fullSearchKeys, allSearchItems, search);
      setSearchQuery(search);

      const studyFilteredOptions = filter(filteredOptionsOfSearch, (item) => item.studyId === studyId);
      const finalFilteredOptions =
        studyId && !isEmpty(studyFilteredOptions) ? studyFilteredOptions : filteredOptionsOfSearch;

      if (slidesMode) {
        setCaseIdsToInclude(undefined);
        const slideIdsToFilter = map(finalFilteredOptions, (option) => first(option.slides)?.id);
        if (isEmpty(slideIdsToFilter)) {
          setSlideIdsToInclude(undefined);
        } else {
          setSlideIdsToInclude(slideIdsToFilter);
        }
      } else {
        setSlideIdsToInclude(undefined);
        const caseIdsToFilter = uniq(map(finalFilteredOptions, (option) => option.caseId.toString()));
        if (isEmpty(caseIdsToFilter)) {
          setCaseIdsToInclude(undefined);
        } else {
          setCaseIdsToInclude(caseIdsToFilter);
        }
      }
    }
  };

  const handleApplyOptionDrawer = (event: React.SyntheticEvent<Element, Event>, value: CaseSearchItem | string) => {
    if (!value) {
      handleChange(event, '');
    } else if (typeof value === 'string') {
      handleChange(event, value);
    } else if (slidesMode) {
      handleChange(event, first(value.slides)?.id);
    } else {
      handleChange(event, value?.caseLabel);
    }
  };

  const handleApplyOption = (event: React.SyntheticEvent<Element, Event>, value: CaseSearchItem | string) => {
    if (isDrawer) {
      handleApplyOptionDrawer(event, value);
    }
    setPage(1, 'replaceIn');
    if (!value) {
      clearSearch();
    } else if (typeof value === 'string') {
      handleApplyFreeSoloSearch(value);
    } else if (slidesMode) {
      setCaseIdsToInclude(undefined);
      setSlideIdsToInclude([first(value.slides)?.id]);
      setSearchQuery(first(value.slides)?.id);
    } else {
      setCaseIdsToInclude([value.caseId.toString()]);
      setSearchQuery(value.caseLabel);
    }
  };

  return {
    searchIndexSuccess,
    isSearchIndexLoading,
    isErrorSearchIndex,
    searchInputValue,
    setSearchInputValue,
    searchQuery,
    setSearchQuery,
    filteredOptions,
    setFilteredOptions,
    searchHasNoResults,
    setSearchHasNoResults,
    clearSearch,
    handleSearch,
    handleApplyFreeSoloSearch,
    handleApplyOption,
  };
};
