import React from 'react';
import ConfirmationModal, { ConfirmationModalProps } from './ConfirmationModal';

export interface ConfirmOptions extends Omit<ConfirmationModalProps, 'onConfirm' | 'onClose' | 'onCancel'> {
  rejectOnCancel?: boolean;
}

export type ConfirmationModalFunction = (options?: ConfirmOptions) => Promise<boolean>;

export const ConfirmationServiceContext =
  // we will pass the opening dialog function directly to consumers
  React.createContext<ConfirmationModalFunction>(Promise.reject);

export interface ConfirmationServiceProviderProps {
  children: React.ReactNode;
  defaultsModalProps?: Partial<ConfirmOptions>;
}

export const ConfirmationServiceProvider: React.FC<React.PropsWithChildren<ConfirmationServiceProviderProps>> = ({
  children,
}) => {
  const [confirmationState, setConfirmationState] = React.useState<ConfirmOptions | null>(null);

  const awaitingPromiseRef = React.useRef<{
    resolve: (res: boolean) => void;
    reject?: () => void;
  }>();

  const openConfirmation = React.useCallback(
    ({ rejectOnCancel, ...options }: ConfirmOptions) => {
      setConfirmationState(options);
      return new Promise<boolean>((resolve, reject) => {
        awaitingPromiseRef.current = rejectOnCancel ? { resolve, reject } : { resolve };
      });
    },
    [setConfirmationState]
  );

  const handleCancel = () => {
    if (awaitingPromiseRef.current) {
      if (awaitingPromiseRef.current.reject) {
        awaitingPromiseRef.current.reject();
      } else {
        awaitingPromiseRef.current.resolve(false);
      }
    }

    setConfirmationState(null);
  };

  const handleConfirm = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(true);
    }

    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>{children}</ConfirmationServiceContext.Provider>

      {Boolean(confirmationState) && (
        <ConfirmationModal
          onConfirm={handleConfirm}
          onClose={handleCancel}
          onCancel={handleCancel}
          {...confirmationState}
        />
      )}
    </>
  );
};

export const useConfirmation = () => React.useContext(ConfirmationServiceContext);
