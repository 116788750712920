import { map } from 'lodash';
import { invertHexColor } from 'utils/helpers';

const color1 = '#e5c0db';
const color1border = '#be62a5';

const color2 = '#a6dfe0';
const color2border = '#3fa5a7';

const color3 = '#84d8ec';
const color3border = '#4dabe0';

const color4 = '#f9cead';
const color4border = '#ef8533';

const color5 = '#edf0c7';
const color5border = '#d2d974';

const color6 = '#eff1f3';
const color6border = '#d8dce2';

export const colorPalette = [color1, color2, color3, color4, color5, color6];
export const borderColorPalette = [color1border, color2border, color3border, color4border, color5border, color6border];
export const invertedColorPalette = map(borderColorPalette, invertHexColor);
export const invertedBorderColorPalette = map(colorPalette, invertHexColor);
