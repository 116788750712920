import superagent from 'superagent';

declare const API_HOST: string;

const apiUtils = {
  request({
    method,
    url,
    data = null,
    accessToken = null,
    signal = null,
    mediaType = 'application/json',
    responseType = null,
  }: {
    method: string;
    url: string;
    data: any;
    accessToken: string;
    signal: AbortSignal;
    mediaType?: string;
    responseType?: string;
  }) {
    const request = superagent(method, `${API_HOST}/${url}`).set('Accept', mediaType);

    if (data) {
      if (method.toUpperCase() === 'GET') {
        request.query(data);
      } else {
        request.send(data).set('Content-Type', 'application/json');
      }
    }
    const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
    request.set(headers);

    if (signal) {
      signal?.addEventListener('abort', () => {
        request.abort();
      });
    }

    if (responseType) {
      return request.responseType(responseType).then((responseData) => responseData);
    }

    return request.then((responseData) => responseData); // force request to be sent
  },
};

export const getOneIndexedPageWithOffset = (page: number, offset: number, totalPages: number) => {
  const zeroIndexedPage = page - 1;
  const zeroIndexedPageWithOffset = (zeroIndexedPage + offset + totalPages) % totalPages;
  return zeroIndexedPageWithOffset + 1;
};

export default apiUtils;
