import { Grid, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

const DashboardFooter = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      alignItems="center"
      sx={{
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900],
        color: theme.palette.mode === 'light' ? theme.palette.grey[900] : theme.palette.grey[50],
        px: 3,
        py: 1,
      }}
    >
      <Grid item>
        <Typography variant="body2" color="secondary">
          Nucleai system is for research use only and has not been validated for use in any diagnostic procedures.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DashboardFooter;
