import { Checkbox } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { useRowSelectionContext } from '.';

export const renderGridCheckboxHeader =
  (numRows: number, disabled?: boolean): GridColDef['renderHeader'] =>
  () => {
    const { allSelected, someSelected, selectAll, clearSelection } = useRowSelectionContext();

    return (
      <Checkbox
        data-cy="select-all-rows-checkbox"
        sx={{ marginInlineStart: '5px' }}
        disabled={disabled || numRows === 0}
        indeterminate={!allSelected(numRows) && someSelected(numRows)}
        checked={allSelected(numRows)}
        onClick={() => {
          if (allSelected(numRows)) {
            clearSelection();
          } else {
            selectAll();
          }
        }}
      />
    );
  };
